const cloneDeep = require('lodash/cloneDeep');
const sortBy = require('lodash/sortBy');
const get = require('lodash/get');

import moment from 'moment';
import commafy from 'commafy';

const schedulerHelpers = require('../../../../../shared/scheduler_helpers');

interface PlainEvent { from: number; to: number; _id: string; note: string; }

interface EventTuple {
  date: string;
  matchingTimeSlot: boolean;
  event: PlainEvent;
}

interface PlainSlot { from: number; to: number; _id: string; terms: { foodBeverageMin: number, roomFeeCents: number, extraHourFee: number }; }

interface SlotTuple {
  date: string;
  timeSlot: PlainSlot;
}

export class MonthViewService {

  selectedSlots: SlotTuple[] = [];

  selectedEvents: EventTuple[] = [];

  blockedChange: 'blocked' | 'available';

  model: {
    fbMinDisplay?: { min: number, max: number };
    fbMinValue?: number;
    roomFeeDisplay?: { min: number, max: number };
    roomFeeValue?: number;
    extraHourFeeValue?: number;
    extraHourFeeDisplay?: { min: number, max: number };
    note?: string;
    dateRange?: string;
  };

  constructor(private now) {
    'ngInject';
  }

  public mergeEventsAndTimeSlots = ({ events, timeSlots }) => {
    const timeSlotIds: string[] = [];
    const displayedEvents = schedulerHelpers.mergeSlotsAndEvents(0, 2400, 1, [...timeSlots, ...events])
      .filter(event => !event.data.isNA)
      .map((event) => {
        const slot = this.cloneTimeSlot(event.data);
        if (timeSlotIds.includes(slot._id.toString())) {
          slot.name = `${slot.name} cont'd`;
        } else {
          timeSlotIds.push(slot._id.toString());
        }
        slot.from = event.from;
        slot.to = event.to;
        return slot;
      });
    return displayedEvents;
  }

  private cloneTimeSlot = (timeSlot) => {
    const slot = cloneDeep(timeSlot);
    slot.originalFrom = timeSlot.from;
    slot.originalTo = timeSlot.to;
    return slot;
  }

  public isEditing = () => {
    return this.selectedSlots.length || this.selectedEvents.length;
  }

  public handleTimeslotClick = ({ date, timeSlot }: { date: string, timeSlot: PlainSlot }) => {
    const isAlreadySelected = this.isSlotSelected({ date, timeSlot });
    isAlreadySelected ? this.deselectSlot({ date, timeSlot }) : this.selectSlot({ date, timeSlot });
    this.resetChanges();
    return this;
  }

  public isSlotSelected = ({ date, timeSlot }: { date: string, timeSlot: PlainSlot }): boolean => {
    return !!this.selectedSlots.find((slotTuple) => {
      return slotTuple.date === date && slotTuple.timeSlot._id.toString() === timeSlot._id.toString();
    });
  }

  private selectSlot = ({ date, timeSlot }: { date: string, timeSlot: PlainSlot }) => {
    this.selectedSlots.push({ date, timeSlot });
    return this;
  }

  private deselectSlot = ({ date, timeSlot }: { date: string, timeSlot: PlainSlot }) => {
    this.selectedSlots = this.selectedSlots.filter((slotTuple) => {
      return slotTuple.date !== date || slotTuple.timeSlot._id.toString() !== timeSlot._id.toString();
    });
    return this;
  }

  // All Saved Events are blocked events

  public handleEventClick = ({ event, matchingTimeSlot, date }: EventTuple) => {
    if (this.isEventSelected({ event })) {
      this.deselectEvent({ event });
    } else {
      this.selectEvent({ event, matchingTimeSlot, date });
    }
    this.resetChanges();
  }

  public isEventSelected = ({ event }: { event: PlainEvent }) => {
    if (!this.selectedEvents.length) {
      return false;
    }
    return !!this.selectedEvents.find(eventTuple => eventTuple.event._id.toString() === event._id.toString());
  }

  private selectEvent = ({ event, matchingTimeSlot, date }: EventTuple) => {
    this.selectedEvents.push({ event, matchingTimeSlot, date });
    return this;
  }

  private deselectEvent = ({ event }: { event: PlainEvent }) => {
    this.selectedEvents = this.selectedEvents.filter(eventTuple => eventTuple.event._id.toString() !== event._id.toString());
    return this;
  }

  allEventsMatchTimeSlots = (): boolean => {
    return this.selectedEvents.every(eventTuple => eventTuple.matchingTimeSlot);
  }

  allSlotsMatchEvents = (): boolean => {
    return this.selectedSlots.every(slotTuple => !!this.selectedEvents.find(eventTuple => (eventTuple.date === slotTuple.date) && (eventTuple.event.from === slotTuple.timeSlot.from) && (eventTuple.event.to === slotTuple.timeSlot.to)));
  }

  isValidAndInMonth = (date: string, selectedMonth) => {
    const monthIndex = moment.months().indexOf(selectedMonth);
    return moment(date, 'YYYY-MM-DD').month() === monthIndex && date >= this.now().format('YYYY-MM-DD');
  }

  isToday = (date: string) => {
    return date === this.now().format('YYYY-MM-DD');
  }

  preventTimeSlotEditing = (): boolean => {
    if (!this.selectedSlots.length) {
      return true;
    }

    if (this.blockedChange === 'blocked') {
      return true;
    }

    if (!this.selectedEvents.length) {
      return false;
    }

    if (this.blockedChange === 'available' && this.allEventsMatchTimeSlots()) {
      return false;
    }

    return true;
  }

  allowNoteEditing = (): boolean => {
    if (this.blockedChange === 'blocked') {
      return true;
    }

    if (this.blockedChange === 'available') {
      return false;
    }

    if (!this.selectedSlots.length) {
      return true;
    }

    return this.allSlotsMatchEvents();
  }

  makeAvailable = () => {
    this.blockedChange = 'available';
    this.generateModel();
    return this;
  }

  blockEvents = () => {
    this.blockedChange = 'blocked';
    this.generateModel();
    return this;
  }

  resetChanges = () => {
    this.model = {};
    this.blockedChange = null;
    this.generateModel();
    return this;
  }

  private minMaxCalculator = ({ roomFee, fbMin, extraHourFee }, slotTuple) => {

    const _fbMin = get(slotTuple, 'timeSlot.terms.foodBeverageMin');

    if (!Number.isFinite(_fbMin)) {
      fbMin.min = null;
    }

    if (fbMin.min === null && Number.isFinite(_fbMin)) {
      fbMin.max = Math.max(fbMin.max, _fbMin);
    } else if (Number.isFinite(_fbMin)) {
      fbMin.max = Math.max(fbMin.max, _fbMin);
      fbMin.min = Math.min(fbMin.min, _fbMin);
    }

    const _roomFee = get(slotTuple, 'timeSlot.terms.roomFeeCents');
    if (!Number.isFinite(_roomFee)) {
      roomFee.min = null;
    }

    if (roomFee.min === null && Number.isFinite(_roomFee)) {
      roomFee.max = Math.max(roomFee.max, _roomFee);
    } else if (Number.isFinite(_roomFee)) {
      roomFee.max = Math.max(roomFee.max, _roomFee);
      roomFee.min = Math.min(roomFee.min, _roomFee);
    }

    const _extraHourFee = get(slotTuple, 'timeSlot.terms.extraHourFee');

    if (!Number.isFinite(_extraHourFee)) {
      extraHourFee.min = null;
    }

    if (extraHourFee.min === null && Number.isFinite(_extraHourFee)) {
      extraHourFee.max = Math.max(extraHourFee.max, _extraHourFee);
    } else if (Number.isFinite(_extraHourFee)) {
      extraHourFee.max = Math.max(extraHourFee.max, _extraHourFee);
      extraHourFee.min = Math.min(extraHourFee.min, _extraHourFee);
    }

    return { roomFee, fbMin, extraHourFee };
  }

  returnNumberOrNull = (num: number) => Number.isFinite(num) ? num : null;

  generateModel = () => {
    const dateRange = this.getSeletedDateRange();
    if (this.allowNoteEditing()) {
      this.model = { note : '', dateRange };
      if (this.selectedEvents.length) {
        this.model.note = this.selectedEvents[0].event.note || '';
        this.model.note = this.selectedEvents.every(eventTuple =>  eventTuple.event.note === this.model.note) ? this.model.note : '';
      }
    } else if (!this.preventTimeSlotEditing()) {
      const defaultFbMin = this.returnNumberOrNull(get(this.selectedSlots[0], 'timeSlot.terms.foodBeverageMin'));
      const defaultRoomFee = this.returnNumberOrNull(get(this.selectedSlots[0], 'timeSlot.terms.roomFeeCents'));
      const defaultExtraHourFee = this.returnNumberOrNull(get(this.selectedSlots[0], 'timeSlot.terms.extraHourFee'));

      const { roomFee, fbMin, extraHourFee } = this.selectedSlots.reduce(this.minMaxCalculator, {
        roomFee: { min: defaultRoomFee, max: defaultRoomFee },
        fbMin: { min: defaultFbMin, max: defaultFbMin },
        extraHourFee: { min: defaultExtraHourFee, max: defaultExtraHourFee }
      });

      let fbMinDisplay;
      let fbMinValue = null;

      if (fbMin.min !== fbMin.max) {
        fbMinDisplay = fbMin;
      } else {
        fbMinValue = fbMin.min;
      }

      let roomFeeDisplay;
      let roomFeeValue = null;
      if (roomFee.min !== roomFee.max) {
        roomFeeDisplay = roomFee;
      } else {
        roomFeeValue = Number.isFinite(roomFee.min) ? roomFee.min / 100 : undefined;
      }

      let extraHourFeeDisplay;
      let extraHourFeeValue = null;

      if (extraHourFee.min !== extraHourFee.max) {
        extraHourFeeDisplay = extraHourFee;
      } else {
        extraHourFeeValue = extraHourFee.min;
      }
      this.model = { roomFeeDisplay, roomFeeValue, fbMinDisplay, fbMinValue, dateRange, extraHourFeeValue, extraHourFeeDisplay };
      return this;
    }
  }

  getSeletedDateRange = () => {
    const allDays = [...this.selectedEvents, ...this.selectedSlots].map(event => moment(event.date, 'YYYY-MM-DD').format('D'));
    return Array.from(new Set(allDays)).sort().join(', ');
  }

  public stopEditing = () => {
    this.selectedEvents = [];
    this.selectedSlots = [];
    this.resetChanges();
    return this;
  }

  createSpaceClassMap = (spaces) => {
    return spaces
      .reduce((accumulator, space, index) => {
        accumulator[space._id.toString()] = `space-${index}`;
        return accumulator;
      }, {});
  }
}
