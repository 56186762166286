// Models
import { HandleRegistrationRequestBody } from 'server/api/auth/models';

export class AuthRouter {
  constructor(private API_ROOT, private $http) {
    'ngInject';
  }

  findUser = (email) => {
    const options = { params: { email: email } };
    return this.$http.get(`${this.API_ROOT}/auth/`, options)
      .then(response => response.data);
  }

  me = (query?) => {
    return this.$http.get(this.API_ROOT + '/auth/me' + (query ? '?=' + query : ''));
  }

  facebookLogin = ({ code, referrer }) => {
    return this.$http.post(this.API_ROOT + '/auth/facebook', { code, referrer });
  }

  linkedinLogin = ({ code, referrer }) => {
    return this.$http.post(this.API_ROOT + '/auth/linkedin', { code, referrer });
  }

  login = (email, password) => {
    return this.$http.post(this.API_ROOT + '/auth/login', {
      email: email,
      password: password
    });
  }

  change = (data) => {
    return this.$http.put(this.API_ROOT + '/auth/me', data);
  }

  register = (data: HandleRegistrationRequestBody) => {
    return this.$http.post(this.API_ROOT + '/auth/register', data);
  }

  registerBaseUser = (data: HandleRegistrationRequestBody) => {
    return this.$http.post(this.API_ROOT + '/auth/register-base-user', data);
  }

  getBaseUser = (id) => {
    return this.$http.get(`${this.API_ROOT}/auth/register/${id}`);
  }

  verifyEmail = (email) => {
    const options = { params: { email: email } };
    return this.$http.get(this.API_ROOT + '/auth/email/verify', options);
  }

  getUUID = (uuid) => {
    return this.$http.get(this.API_ROOT + '/auth/resetPassword/' + uuid);
  }

  getUserFromUUID = (uuid) => {
    return this.$http.get(`${this.API_ROOT}/auth/resetPassword/${uuid}/user`);
  }

  resetPassword = (email) => {
    return this.$http.post(this.API_ROOT + '/auth/resetPassword', { email: email });
  }

  submitReset = (data, uuid) => {
    return this.$http.post(this.API_ROOT + '/auth/resetPassword/' + uuid, data);
  }

  unsubscribe = (encryptedId, newsletter) => {
    return this.$http.post(this.API_ROOT + '/auth/unsubscribe-newsletter', { encryptedId: encryptedId, newsletter: newsletter });
  }
}
