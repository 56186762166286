import { get, last, debounce } from 'lodash';
// constant
import { ANALYTICS_EVENTS } from '../../constants/ENUMS/analyticsEvents';

export default ['$scope', '$routeParams', '$timeout', '$api', '$locationSpy', '$location', 'ngDialog', 'ENUMS', '$cloudinary', '$analytics', 'toast', '$rootScope', 'unwrapError', function($scope, $routeParams, $timeout, $api, $locationSpy, $location, ngDialog, ENUMS, $cloudinary, $analytics, toast, $rootScope, unwrapError) {
  $scope.$routeParams = $routeParams;
  $scope.$cloudinary = $cloudinary;
  $scope.ENUMS = ENUMS;

  ///// Functions
  // View-accessible functions
  $scope.addNewCourse = addNewCourse;
  $scope.cancelEdits = cancelEdits;
  $scope.enableValidation = enableValidation;
  $scope.last = last;
  $scope.openPhotos = openPhotos;
  $scope.removeCourse = removeCourse;
  $scope.changePrice = changePrice;
  $scope.saveDoc = saveDoc;
  $scope.validate = debounce(validate, 300);

  init();
  ///// Public Functions

  /**
   * Initialization logic
   */
  function init() {
    $scope.uiModel = {};
    $scope.errorState = { $count: 0 };
    $scope.doc = $scope.Record;
    $scope.uiModel.price = $scope.doc.data.price;
    $locationSpy.register($scope.doc);

    if (!$scope.doc.data.courses.length) {
      $scope.addNewCourse();
    }
    // $rootScope.$emit('$viewReady', 'HIDE_FOOTER');


    if (!$scope.doc.data.courses.length) {
      $scope.addNewCourse();
    }
  }

  /**
   * Validate a document at a given path, with the option to override whether or not the field can be validated
   *
   * @param {String} path
   * @param {Boolean} force, whether or not to override the `shouldValidate` property
   * @return {Void}
   */
  function validate(path) {
    $scope.errorState[path] = $scope.errorState[path] || {};
    $api.Menus.validate(Object.assign($scope.doc, { isVisible: true }))
      .then((data) => {
        const errors = get(data, 'errors.errors', {});
        const error = errors[path];

        if (error) {
          if (!$scope.errorState[path].error) {
            ++$scope.errorState.$count;
          }
          $scope.errorState[path].error = error;
        } else {
          if ($scope.errorState[path].error) {
            --$scope.errorState.$count;
          }

          $scope.errorState[path].error = null;
        }
      })
      .catch(error => unwrapError(error));
  }

  /**
   * Enable validation of `$scope.doc` at a given path
   *
   * @param {String} path
   * @param {Boolean} val
   * @return {Void}
   */
  function enableValidation(path, val) {
    if (val == null) {
      val = true;
    }

    $scope.errorState[path] = $scope.errorState[path] || {};
    $scope.errorState[path].shouldValidate = val;

    $scope.validate(path);
  }

  /**
   * Add a new empty object course to a menu's array of courses
   *
   * @return {Void}
   */
  function addNewCourse() {
    $scope.doc.data.courses.push({ ___id: Date.now() });
  }

  function removeCourse(index) {
    $scope.doc.data.courses.splice(index, 1);
  }

  function changePrice (price) {
    $scope.doc.data.price = price;
    $scope.doc.data.priceInCents = price * 100;
    $scope.validate('data.priceInCents');
  }

  function saveDoc() {
    $scope.doc.isVisible = true;
    return $api.Menus.
      save($scope.doc).
      then(function(data) {
        const menu = data.data.data;
        $locationSpy.unregister();
        $scope.doc = menu;
        return $api.Venues.get($scope.Slug);
      }).
      then(function(data) {
        const theVenue = get(data, 'data.data.venue');
        const eventName = ANALYTICS_EVENTS.venueDashboard.savedMenu;
        const eventParams = new $analytics.$Menu($scope.doc, theVenue);
        $analytics.$trackEvent(eventName, eventParams);

        const url = `/user/venues?venue=${ theVenue.slug }`;
        $location.url(url);
      }).
      catch(unwrapError);
  }


  /**
   * Cancel edits and return to venue dashboard
   *
   * @param {String}
   * @return {Void}
   */
  function cancelEdits(url) {
    $locationSpy.unregister();
    $location.url(url);
  }

  /**
   * Opens up photo modal to add/delete photos from a menu's array of photos
   *
   * @return {Void}
   */
  function openPhotos() {
   ngDialog.
     open({
       scope: $scope,
       template: require('./photo-modal.jade'),
       className: 'ngdialog-theme-plain',
       overlay: false,
       plain: true,
       controller: 'UserMenusPhotos',
       resolve: {
          Record: function() {
           return { data: { data: $scope.doc } };
         },
          // this is not a shadowed variable, this is a dependency injection
          // tslint:disable-next-line: no-shadowed-variable
          Model: ['$api', function($api) {
           return $api.Menus;
         }]
       }
     }).
     closePromise.
     then(function() {
        return $api.Menus.get($scope.doc._id);
     }).
     then(function(data) {
        $scope.doc.data.photos = data.data.data.data.photos;
        $scope.doc.data.coverIndex = data.data.data.data.coverIndex;
     }).
     catch(unwrapError);
  }
}];
