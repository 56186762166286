import { Attachment } from 'spc/lib/server/api/conversations/models';
import { RawConversation } from 'spc/lib/database/types/conversation';
import { ApiService } from 'spc/shared/api/api.service';
import cloneDeep from 'lodash/cloneDeep';
import { Users } from 'spc/shared/api/admin/users';


interface SignedContractAttachments {
  url: string;
  title: string;
  isDeleted?: boolean;
}

class ContractAttachmentsController {
  close: (attachments: SignedContractAttachments[]) => void;
  ui: {
    canSubmit?: boolean;
    displayedAttachments?: SignedContractAttachments[];
    buttonText?: string;
  } = {};
  attachments: any;
  request: any;
  constructor (private $api: ApiService,
    private unwrapError,
    private $scope: ng.IScope,
    private $cloudinary,
    private attachmentModalServices) {
    'ngInject';
  }

  $onInit = () => {
    ({ attachments: this.attachments, ui: this.ui } = this.attachmentModalServices.setAttachments(this.attachments, this.$scope));
  }

  public handleUpload = (url: string, title: string) => {
    const attachment = {
      url,
      title,
      isNew: true
    };
    this.attachments.push(attachment);
    this.ui.displayedAttachments = this.attachmentModalServices.setDisplayedAttachments(this.attachments);
  }

  public removeAttachment = (attachment) => {
    attachment.isDeleted = true;
    this.ui.displayedAttachments = this.attachmentModalServices.setDisplayedAttachments(this.attachments);
  }

  public finish = () => {
    if (this.ui.canSubmit) {
      const attachments = this.ui.displayedAttachments;
      return this.close(attachments);
    } else {
      return Promise.resolve();
    }
  }
}

export const contractAttachmentsComponent = {
  controller: ContractAttachmentsController,
  template: require('./contract-attachments.component.jade'),
  bindings: {
    attachments: '<',
    request: '<',
    close: '<'
  }
};
