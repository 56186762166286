// Interfaces
import {
  MarkUnavailableRequestBody,
  ModifyEventRequestBody,
  ModifyManyRequestBody
} from '../../../../../server/api/availability-calendar/models';

export class AvailabilityCalendar {
  BASE_URL: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    'ngInject';
    this.BASE_URL = `${API_ROOT}/admin/availability-calendar`;
  }

  public getDateEvents = ({ date }: { date: string }) => {
    return this.$http.get(`${this.BASE_URL}/day/${date}`)
      .then(response => response.data);
  }

  public getMonthEvents = ({ year, month }: { year: string, month: string }) => {
    return this.$http.get(`${this.BASE_URL}/year/${year}/month/${month}`)
      .then(response => response.data);
  }
}
