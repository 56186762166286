import { fullPriceBreakdownCents, fullPriceBreakdownDollars } from 'common/dist/price';

// Lodash
import { get, map, reduce } from 'lodash';

export class AdminRequestsService {
  constructor(private ENUMS, private $api) {
    'ngInject'
  }

  // functions

  /**
   * Get count based on search params
   * 
   * @api public
   * @params {Params} params
   * @return {Promise}
   */
  public getCount(params): Promise<any> {
    return this.$api.Admin.Requests.getCount(params)
      .then(function(response) {
        return get(response, 'data.data');
      });
  }

  /**
   * For performance reasons, we only want to run these calculations on demand instead
   * of using a virtual. We will map the cost breakdown as a 1 to 1 relationship with 
   * `$scope.requests` so we can use the `$index`
   * 
   * @api private
   * @params {Array} requests
   * @return {Array}
   */
  public getPriceCalculations(requests: any[]): { cents: any[], dollars: string[] } {
    return {
      cents: map(requests, fullPriceBreakdownCents),
      dollars: map(requests, (r) => fullPriceBreakdownDollars(r, true))
    };
  }

  /**
   * Fetch requests from server based on query and page number (to skip)
   * 
   */
  public getRequests(params, pageNum?: number): Promise<any> {
    const self = this;
    return self.$api.Admin.Requests.getAll(pageNum, params)
      .then(function(response) {
        return get(response, 'data.data');
      });
  }

  /**
   * Get the request states for searching on the admin dashboards. Relates a user-friendly
   * category to an object containing each state as its key. Useful so we can JSON Stringify
   * it and send over the wire as a query
   * 
   * @api public
   * @return {Object}
   */
  public getSearchRequestStates() {
    const self = this;
    return [
      {
        group: 'Cancelled',
        states: self.getCancelledStates()
      },
      {
        group: 'Pending',
        states: self.getPendingStates()
      },
      {
        group: 'Closed',
        states: self.getClosedStates()
      }
    ];
  }

  // Private functions

  private getClosedStates() {
    return { CLOSED: true };
  }

  private getCancelledStates() {
    return {
      CANCELLED_REQUEST_GUEST: true,
      DECLINED_HOST: true,
      DECLINED_GUEST: true
    };
  }

  private getPendingStates() {
    const self = this;
    const idxOfDeposit = self.ENUMS.bookingRequestState.indexOf('DEPOSIT');
    const idxOfClosed = self.ENUMS.bookingRequestState.indexOf('CLOSED');
    return reduce(self.ENUMS.bookingRequestState.slice(idxOfDeposit, idxOfClosed), (acc, curr: any) => {
      acc[curr] = true; 
      return acc;
    }, {});
  }
}
