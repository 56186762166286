import { ApiService } from 'spc/shared/api/api.service';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import AuthorizedClientHelpers from 'common/dist/virtuals/AuthorizedClient';
import UserHelpers from 'common/dist/virtuals/User';
import price from 'common/dist/price';
import BookingRequestHelpers from 'common/dist/virtuals/BookingRequest';
import { determineRecipient, determinePaymentMethod } from 'common/dist/virtuals/Payment';

class AdminCalendarDayViewController {
  events: any[];
  requests: any[];
  date: string;
  determinePaymentMethod: (payment) => string;
  determineRecipient: (payment) => string;

  constructor(private $api: ApiService, private unwrapError) {
    'ngInject';
    this.determineRecipient = determineRecipient;
    this.determinePaymentMethod = determinePaymentMethod;
  }

  $onInit = () => {
    if (this.events) {
      const requestIds = this.events.map(event => event.bookingRequest);
      this.$api.Admin.Requests.getAll(0, { ids: requestIds })
        .then((response: any) => {
          this.requests = sortBy(get(response, 'data.data'), 'data.time');
          this.requests.forEach((request) => {
            request.paymentSchedule = BookingRequestHelpers.paymentSchedule(request);
            request.costBreakdownCents = price.fullPriceBreakdownCents(request);
          });
        })
        .catch(error => this.unwrapError(error));
    }
  }

  selectedSpaceName = (request) => {
    return BookingRequestHelpers.selectedSpace(request).data.name;
  }

  getEventTotal = () => {
    if (!this.requests) {
      return;
    }

    return this.requests.reduce((totals, request) => {
      Object.keys(totals).forEach(key => totals[key] += request.costBreakdownCents[key]);
      return totals;
    }, { total: 0, amountPaid: 0, bookingFee: 0, cardFee: 0 });
  }

  getPrimaryClient = (request) => {
    const client = AuthorizedClientHelpers.primaryEventContact(request);
    return UserHelpers.fullName(client.user);
  }
}

export const AdminCalendarDayViewComponent = {
  controller: AdminCalendarDayViewController,
  template: require('./admin-calendar-day-view.component.jade'),
  bindings: {
    events: '<',
    date: '<'
  }
};
