import { queryHelpers } from '../../utils/query';
export class MenuRouter {
  BASE_URL: string;
  constructor(private API_ROOT, private $http: ng.IHttpService) {
    this.BASE_URL = `${this.API_ROOT}/menus`;
  }

  public create = (doc) => {
    return this.$http.post(this.BASE_URL, doc);
  }

  public get = (id, params) => {
    return this.$http.get(`${this.BASE_URL}/${id}`, { params });
  }

  public validate = (menu) => {
    return this.$http.put(`${this.BASE_URL}/validate`, { menu })
      .then(response => response.data);
  }

  public getVenueMenus = (venue, searchParams:any = null) => {
     const query = queryHelpers.makeQueryString(searchParams);
    const venueId = venue._id ? venue._id.toString() : venue.toString();
    return this.$http.get(`${this.BASE_URL}/venue/${venueId}${ query ? query : '' }`)
      .then(res => res.data);
  }

  public save = (doc) => {
    return this.$http.put(`${this.BASE_URL}/${doc._id}`, doc);
  }

  public setVisibility = (venue, doc, isVisible) => {
    return this.$http.put(`${ this.BASE_URL }/${ doc._id }/visibility`, { isVisible, venueId: venue._id });
  }

  public delete = (menu) => {
    return this.$http.delete(`${ this.BASE_URL }/${ menu._id }`);
  }

  public saveMenuOrder = ({ menus, venueId }) => {
    return this.$http.put(`${this.BASE_URL}/venue/${venueId}/order`, { menus });
  }
}
