// External Deps

// Sp Deps

// SP Interfaces
import { HTTPResponse } from '../models';
import { GetAdminConversationsQuery, GetAdminConversationsCountsResponse } from 'server/api/admin/conversations/models';

export class Conversations {
  BASE_URL: string;

  constructor(API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${ API_ROOT }/admin/conversations`;
  }

  getConversations(queryParams?: GetAdminConversationsQuery) {
    return this.$http.get(this.BASE_URL, { params: queryParams })
    .then((response: HTTPResponse<any>) => response.data);
  }

  getCounts(queryParams?: GetAdminConversationsQuery) {
    const url = `${ this.BASE_URL }/count`;

    return this.$http
      .get(url, { params: queryParams })
      .then((response: HTTPResponse<GetAdminConversationsCountsResponse>) => response.data);
  }

  updateConversation = ({ conversation, update }) => {
    const conversationId = conversation._id ? conversation._id.toString() : conversation.toString();
    const url = `${this.BASE_URL}/${conversationId}`;
    return this.$http
      .put(url, { update })
      .then(response => response.data);
  }
}
