// Sixplus Dependencies
import { ApiService } from 'spc/shared/api/api.service';
import Vimeo from '@vimeo/player';
import ENUMS from 'common/dist/enums';

// constants
import { NEIGHBORHOOD_SPACES } from 'spc/constants/neighborhoodSpaces';
import { PRICE_FILTERS } from 'spc/constants/priceFilter';
import { get } from 'lodash';

const CITY_LOCATION = `New York`;

interface LandingPageVenue {
  slug: string;
  url: string;
  name: string;
}
interface NeighborhoodSpaces {
  neighborhood: string;
  imgUrl?: string;
  link: string;
}
interface PriceFilters {
  priceInCents: string;
  imgUrl?: string;
  link: string;
}

export default {
  template: require('./city-home-landing-page.jade'),
  controller: class CityHomeLandingPageComponent {
    location: string;
    guests: number;
    displayedVenues: LandingPageVenue[] = [];
    displayNeighbors: NeighborhoodSpaces[] = NEIGHBORHOOD_SPACES;
    displayPrice: PriceFilters[] = PRICE_FILTERS;

    constructor(
      private $element: ng.IRootElementService,
      private $cloudinary,
      private $api: ApiService,
      private unwrapError,
      private $httpParamSerializer,
      private $location
    ) {
      'ngInject';

    }

    $onInit = () => {
      // this.playVideoWithJWPlayer();
      this.playVideoWithVimeoPlayer();
      this.location = CITY_LOCATION;
      this.displayVenues();
    }

    // public playVideoWithJWPlayer = () => {
    //     jwplayer('jwPlayerDiv').setup({
    //         image: 'https://res.cloudinary.com/dbwijvnzn/image/upload/a_exif,c_fill,g_center,q_auto,w_2000,f_auto/vlcsnap-2022-02-21-10h09m08s108_vvzjny.png',
    //         file: 'https://cdn.jwplayer.com/manifests/7RUuNRVG.m3u8',
    //         controls: false,
    //         autostart: true,
    //         mute: true,
    //         repeat: true,
    //         stretching: 'fill'
    //         });
    //       }

    public playVideoWithVimeoPlayer = () => {
      const options = {
          id: 803070871,
          width: 720,
          loop: true,
          controls: false,
          autoplay: true,
          muted: true,
          title: false
      };
      setTimeout(() => {
          new Vimeo('vimeoPlayerNycDiv', options);
      }, 500);
    }

    displayVenues = () => {
      const locationCity = get(ENUMS.acceptableUserCities.find(loc => loc.value === this.location), 'city');
      if (!locationCity) {
        return;
      }

      this.$api.FeaturedVenues.get(locationCity).then(
        data => (this.displayedVenues = data.featuredVenues)
      );
    }

    search = () => {
      const searchParams = {
        guests: this.guests,
      };
      const query = {
        guestCount: this.guests,
      };
      const qs = `?${this.$httpParamSerializer(searchParams)}`;
      const url = `/search/${this.location.replace(' ', '-')}${qs}`;
      this.$location.url(url);
    }
    imagePath = (searchParams) => {
      const qs = `?${this.$httpParamSerializer({
        'data.address.neighborhood': searchParams.neighborhood,
      })}`;
      const url = `/search/${this.location.replace(' ', '-')}${qs}`;
      this.$location.url(url);
    }
    pricePath = (searchParams) => {
      const qs = `?${this.$httpParamSerializer({
        'menus.data.priceInCents': searchParams.priceInCents,
      })}`;
      const url = `/search/${this.location.replace(' ', '-')}${qs}`;
      this.$location.url(url);
    }
    searchCards = () => {
      const url = `/search/${this.location.replace(' ', '-')}`;
      window.location.href = url;
    }

    $onDestroy() {
      $('meta[name="robots"]').remove();
    }

    fetchAndConvertUrl(url, type) {
      return this.$cloudinary.fetchAndConvertUrl(url, type);
    }

    srcSet(url) {
      return this.$cloudinary.srcSet(url);
    }

    scrollCards(type, scrollDirection) {
      if (scrollDirection === 'left') {
        document.querySelector(`div.hl-booked-spaces-${type}`).scrollLeft -= 700;
      }
      else if (scrollDirection === 'right') {
        document.querySelector(`div.hl-booked-spaces-${type}`).scrollLeft += 700;
      }
    }
  },
};
