export const PRICE_FILTERS = [
  {
    priceInCents: '$51-$75',
    imgUrl:
      '//res.cloudinary.com/dbwijvnzn/image/upload/v1512057717/HOME_FINAL0011_gxcpyc.jpg',
    link: '/search/New-York?filters%5Bguests%5D%5Bmax%5D=&filters%5Bmenus%5D%5Bdata.priceInCents%5D%5B0%5D%5B$gte%5D=5001&filters%5Bmenus%5D%5Bdata.priceInCents%5D%5B0%5D%5B$lte%5D=7500&viewType=VENUE&bounds%5BallowMapSearch%5D=true',
  },
  {
    priceInCents: '$76-$100',
    imgUrl:
      '//res.cloudinary.com/dbwijvnzn/image/upload/v1572384260/1565029684934__LMW0195_hrsopl.jpg',
    link: '/search/New-York?filters%5Bguests%5D%5Bmax%5D=&filters%5Bmenus%5D%5Bdata.priceInCents%5D%5B0%5D%5B$gte%5D=7501&filters%5Bmenus%5D%5Bdata.priceInCents%5D%5B0%5D%5B$lte%5D=10000&viewType=VENUE&bounds%5BallowMapSearch%5D=true',
  },
  {
    priceInCents: '$100-$150',
    imgUrl:
      '//res.cloudinary.com/dbwijvnzn/image/upload/v1637541072/BP_Front_Dining_Room_bqq0md.jpg',
    link: '/search/New-York?filters%5Bguests%5D%5Bmax%5D=&filters%5Bmenus%5D%5Bdata.priceInCents%5D%5B0%5D%5B$gte%5D=10001&filters%5Bmenus%5D%5Bdata.priceInCents%5D%5B0%5D%5B$lte%5D=15000&viewType=VENUE&bounds%5BallowMapSearch%5D=true',
  },
  {
    priceInCents: '$150+',
    imgUrl:
      '//res.cloudinary.com/dbwijvnzn/image/upload/v1635291111/ResidentWineLibrary2_wkgkn7.jpg',
    link: '/search/New-York?filters%5Bguests%5D%5Bmax%5D=&filters%5Bmenus%5D%5Bdata.priceInCents%5D%5B0%5D%5B$gte%5D=15001&viewType=VENUE&bounds%5BallowMapSearch%5D=true',
  },
];
