import { each, get, last, unset } from 'lodash';
import isEmpty from 'lodash/isEmpty';

/**
 * Component for selecting add-ons for the given booking request
 *
 * @property {Array} addOns Array of add-ons
 * @property {BookingRequest} bookingRequest
 * @property {Function} persistRequest Persist the request to server
 * @fires SELECTED_ADD_ONS Fired when user completes this step
 * @fires SKIPPED_SELECT_ADD_ONS Fired when user skips this step
 */

module.exports = function() {
  return {
    template: require('./select-add-ons.jade'),
    scope: {
      addOns: '=',
      bookingRequest: '=',
      persistRequest: '&'
    },
    controller: ['$scope', function($scope) {
      $scope.selectedAddOns = {};
      $scope.numSelectedAddOns = get($scope, 'bookingRequest.data.addOns.length', 0);

      function initData() {
        if (!$scope.bookingRequest || isEmpty($scope.bookingRequest)) {
          return;
        }

        $scope.bookingRequest.data.addOns.forEach(addOn => $scope.selectedAddOns[addOn._id.toString()] = addOn);
        $scope.uiData = {
          addOns: each($scope.addOns, function(addOn) {
            if (addOn.priceType === 'PER_PERSON') {
              addOn.numGuests = get($scope, 'bookingRequest.data.groupSize');
            }
            return addOn;
          })
        };
      }

      /**
       * If addon has priceType of `PER_PERSON`, make sure `numGuests` <= `bookingRequest.data.groupSize`
       * @param {AddOn} addOn the add-on to add
       * @return {Boolean}
       */

      $scope.canAdd = function(addOn) {
        if (!addOn) {
          return;
        }

        if (addOn.priceType !== 'FREE' && addOn.priceType !== 'FLAT' && addOn.numGuests == null) {
          return false;
        }

        const perPersonConditionsSatisfied = addOn.priceType === 'PER_PERSON' ?
          addOn.numGuests <= $scope.bookingRequest.data.groupSize :
          true;
        const perBottleConditionsSatisfied = addOn.priceType === 'PER_BOTTLE' ? addOn.numGuests : true;

        return perPersonConditionsSatisfied && perBottleConditionsSatisfied;
      };

      /**
       * Toggle whether the given add-on object is selected
       *
       * @param {AddOn} addOn The add-on to toggle
       */

      $scope.select = function(addOn, index) {
        const addOnsInRequest = $scope.bookingRequest.data.addOns;
        const id = addOn._id.toString();
        if ($scope.selectedAddOns[id]) {
          $scope.bookingRequest.data.addOns = addOnsInRequest.filter(_addOn => _addOn._id.toString() !== id);
          unset($scope.selectedAddOns, id);
          --$scope.numSelectedAddOns;
        } else {
          addOnsInRequest.push(addOn);
          $scope.selectedAddOns[id] = last($scope.bookingRequest.data.addOns);
          ++$scope.numSelectedAddOns;
        }
        $scope.persistRequest({ goNext: false });
      };

      /**
       * Persist this request to the server, and emit the correct event on
       * success.
       */

      $scope.continue = function() {
        return $scope.persistRequest({ goNext: true }).then(function() {
          if ($scope.numSelectedAddOns > 0) {
            $scope.$emit('SELECTED_ADD_ONS', $scope.bookingRequest);
          } else {
            $scope.$emit('SKIPPED_SELECT_ADD_ONS', $scope.bookingRequest);
          }
        });
      };

      /*
       * Initialize uiData for addOns when a user arrives at this step or
       * when the request is created
       */

      initData();
    }]
  };
};
