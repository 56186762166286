// NPM Deps
import { getEndTime } from 'common/dist/virtuals/BookingRequest';
import { fullName, isActualUser } from 'common/dist/virtuals/User';
import { primaryEventContact } from 'common/dist/virtuals/AuthorizedClient';
import { determineRecipient, determinePaymentMethod } from 'common/dist/virtuals/Payment';

import get from 'lodash/get';
import last from 'lodash/last';
import without from 'lodash/without';
import debounce from 'lodash/debounce';

// SixPlus Deps
import { ApiService } from 'spc/shared/api/api.service';
import { ToastService } from 'spc/shared/toast.service';

// Interfaces
import { UpdatePathOp } from 'shared/interfaces/admin/events/update-request.model';
import { DistinctCity } from 'server/api/admin/search/models';
import { AdminEventsService } from 'spc/admin/proposals/admin-events.service';
import { Search } from './models';
import { RawBookingRequest } from 'spc/lib/database/types/booking-request';
import fetchCitiesAndSubcities from 'spc/utils/fetchCitiesAndSubcities';
import { getCityNameFromSubcity } from 'spc/utils/getCityDisplayName';

interface CityHierarchyInterface {
  [key: string]: {
    neighborhoods: string[],
    state: string
  };
}

class AdminProposalsDashboard {
  cities: string[];
  states: string[];
  assignees: any[];
  requests: any[];
  searchParams: Search = {};
  counts: {
    booked?: number;
    requested?: number;
    total?: number;
  } = {};
  ui: {
    displayDataSidebar: boolean,
    displayFilterSidebar: boolean,
    selectedEvent?: any
  } = { displayDataSidebar: false, displayFilterSidebar: false };
  loading: boolean;
  currentPage: number = 1;
  displayCitySelector: boolean = false;
  cityHierarchyObj: CityHierarchyInterface;
  getCityFromVenueCity = getCityNameFromSubcity;
  // Constants
  NUM_REQUESTS_PER_PAGE = 50;
  determinePaymentMethod: (payment) => string;
  determineRecipient: (payment) => string;

  constructor(
    private $api: ApiService,
    private ENUMS,
    private paymentHistoryModal,
    private unwrapError,
    private toast: ToastService,
    private paymentHelpers,
    private $clickOk,
    private AdminRequestsService,
    private adminEventsService: AdminEventsService,
    private $element,
    private $rootScope,
    private $seo) {
    'ngInject';
    const ctrl = this;
    ctrl.states = without(ENUMS.bookingRequestState, ...ENUMS.bookingRequestState.cancelledPostDepositStates);
    ctrl.cities = Object.keys(ENUMS.cities);
    ctrl.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
    ctrl.determineRecipient = determineRecipient;
    ctrl.determinePaymentMethod = determinePaymentMethod;
  }

  $onInit() {
    const ctrl = this;
    this.$seo.set('Proposals (Admin) | SixPlus');
    ctrl.AdminRequestsService.getCount(ctrl.searchParams)
      .then((response) => {
        ctrl.counts.total = response;
      })
      .catch(error => ctrl.handleError(error));
    ctrl.displayRequests(0);
    ctrl.getCounts();
    ctrl.getAssignees();
    fetchCitiesAndSubcities(ctrl.$api)
      .then(res => ctrl.cityHierarchyObj = res);
  }

  /**
   * Gets the fullname of a user doc
   */
  fullName(user) {
    return fullName(user);
  }

  /**
   * Gets the ending time of a request
   */
  getEndTime(request) {
    return getEndTime({ data: { duration: this.getDelta(request, 'data.duration'), time: this.getDelta(request, 'data.time') } });
  }

  /**
   * Gets the last element in an array
   */
  last(arr) {
    return last(arr);
  }

  /**
   * Fetches the counts from the backend and populates `this.counts`
   */
  getCounts() {
    const ctrl = this;
    return ctrl.$api.Admin.Requests.getCounts()
      .then((response: any) => {
        ctrl.counts.booked = response.data.data.booked;
        ctrl.counts.requested = response.data.data.requested;
      })
      .catch(error => ctrl.handleError(error));
  }

  /**
   * Gets all the assignees from the db
   */
  getAssignees() {
    const ctrl = this;
    return ctrl.$api.Admin.Users.getAssignees({ team: 'Concierge' })
      .then(function (response) {
        ctrl.assignees = response.data.assignees;
      })
      .catch(error => ctrl.handleError(error));
  }

  /**
   * Initializes all the tooltip elements on the page
   */
  setTooltips() {
    const ctrl = this;
    ctrl.$element
      .find('[data-toggle="tooltip"]')
      .tooltip({
        container: 'body'
      });
  }

  /**
   * Takes response data and populates the necessary fields
   */
  showRequests(requestData, idx: number) {
    const ctrl = this;
    ctrl.requests = requestData.data.data;
    ctrl.currentPage = idx + 1;
    ctrl.setTooltips();
  }

  /**
   * Get the delta for certain paths if needed
   */
  getDelta(request: any, path: string) {
    const lastCounteroffer = last<{ delta: any[] }>(request.counteroffers);
    if (lastCounteroffer) {
      const deltas = lastCounteroffer.delta;
      const relevantDelta = deltas.find(delta => delta.path === path);
      if (!relevantDelta) {
        return get(request, path);
      } else {
        return relevantDelta.newValue;
      }
    } else {
      return get(request, path);
    }
  }


  /*
   *displayRequests() retrieves the count of all
    requests needed and displays a paginated list of them.
   *if no requests are retrieved, no results found page is
    displayed
   *@param {Number} pageNum
  */
  displayRequests(idx: number = 0) {
    const ctrl = this;
    ctrl.startSearch();
    ctrl.searchParams = {};
    ctrl.AdminRequestsService
      .getCount()
      .catch(error => ctrl.handleError(error));
    ctrl.$api.Admin.Requests
      .getAll(idx)
      .then((response) => {
        ctrl.showRequests(response, idx);
        ctrl.endSearch();
      })
      .catch(error => ctrl.handleError(error));
  }

  handleError(error) {
    this.endSearch();
    this.unwrapError(error);
  }

  startSearch() {
    this.loading = true;
  }

  endSearch() {
    this.loading = false;
  }

  toggleCityDisplay = () => {
    this.displayCitySelector = !this.displayCitySelector;
  }

  closeCitySelector = (selectedCities: string[]) => {
    this.searchParams.cities = selectedCities;
    this.displayCitySelector = false;
  }

  isAdmin(user) {
    return user.roles.includes('Admin');
  }

  displayClients(request) {
    const client = primaryEventContact(request);
    if (client) {
      return fullName(client.user);
    }
  }

  displayCompany(request) {
    const client = primaryEventContact(request);
    if (client) {
      return get(client, 'user.company.name');
    }
  }

  isPrimaryClientNew(request) {
    const client = primaryEventContact(request);
    return !isActualUser(client.user);
  }

  displayPaymentState(request) {
    return this.paymentHelpers.displayPaymentState(request);
  }

  public toggleDataSidebarView = (request) => {
    if (request) {
      this.ui.selectedEvent = request;
      this.ui.displayDataSidebar = true;
    } else {
      this.ui.displayDataSidebar = false;
    }
  }

  public toggleFilterSidebar = () => {
    this.ui.displayFilterSidebar = !this.ui.displayFilterSidebar;
  }

  savePaths(request: RawBookingRequest, paths: UpdatePathOp[] | UpdatePathOp) {
    return this.$api.Admin.Requests
      .savePaths({ request, pathsAndValues: paths })
      .then((response) => {
         this.toast.goodNews(`Request Information Changed`, `Request info successfully changed`);
          return response.request; })
      .catch((error) => {
        this.toast.badNews('Error saving your request', error.data.error.readableError);
        this.handleError(error);
      });
  }

  saveState(request: any, state: string) {
    const ctrl = this;
    return ctrl.adminEventsService.handleStateChange(request, state)
      .catch(function (error) {
        this.toast.badNews(`Request State Change Error`, error.data.error.readableError);
        this.unwrapError(error);
      });
  }

  debouncedSearchRequests = debounce(this.searchRequests, 500);

  searchRequests(searchParams) {
    const ctrl = this;
    ctrl.searchParams = Object.assign(ctrl.searchParams, searchParams);
    ctrl.startSearch();
    if (ctrl.searchParams.client) {
      ctrl.searchParams.client = ctrl.searchParams.client.split('@')[0];
    }
    ctrl.AdminRequestsService.getCount(ctrl.searchParams)
      .then((response) => {
        ctrl.counts.total = response;
      })
      .catch(error => ctrl.handleError(error));

    ctrl.$api.Admin.Requests
      .getAll(null, ctrl.searchParams)
      .then((response) => {
        ctrl.showRequests(response, 0);
        ctrl.endSearch();
      })
      .catch(error => ctrl.handleError(error));
  }

  loadPage(idx: number) {
    const ctrl = this;
    return ctrl.$api.Admin.Requests
      .getAll(idx, ctrl.searchParams)
      .then((response) => {
        ctrl.showRequests(response, idx);
      });
  }

  paymentModal(request) {
    const ctrl = this;

    return ctrl.paymentHistoryModal(request)
      .catch(error => ctrl.handleError(error));
  }

  saveAssignee(request, assignee) {
    const ctrl = this;
    if (get(request, 'admin.assignee._id', '').toString() === assignee._id.toString()) {
      return;
    }

    const state = get(request, 'venue.data.address.state');
    if (!assignee.roles.includes(state)) {
      return ctrl.$clickOk('This user isn\'t in the same city as this venue. Click cancel if you want to assign someone else', true)
        .then(function (response) {
          if (get(response, 'value.cancel')) {
            return;
          }
          return ctrl.savePaths(request, { setPath: `admin.assignee`, value: assignee._id })
            .then(res =>  request.admin.assignee = assignee);
        })
        .catch((error) => {
          ctrl.unwrapError(error);
         });
    } else {
      return ctrl.savePaths(request, { setPath: `admin.assignee`, value: assignee._id })
        .then(res =>  request.admin.assignee = assignee);
    }
  }

  hasActiveFilters = () => {
    return Object.keys(this.searchParams)
      .some((filter) => {
        if (Array.isArray(this.searchParams[filter])) {
          return this.searchParams[filter].length;
        }
        return this.searchParams[filter];
      });
  }

  clearClientText = () => {
    this.searchParams.client = '';
    this.searchRequests(this.searchParams);
  }

  clearVenueText = () => {
    this.searchParams.text = '';
    this.searchRequests(this.searchParams);
  }

  clearStateFilter = () => {
    this.searchParams.requestStates = [];
    this.searchRequests(this.searchParams);
  }

  clearPayoutStatusFilter = () => {
    this.searchParams.payoutStatus = '';
    this.searchRequests(this.searchParams);
  }

  clearCitiesFilter = () => {
    this.searchParams.cities = [];
    this.searchRequests(this.searchParams);
  }

  clearAllFilters = () => {
    this.searchParams = { requestStates: [], cities: [] };
    this.searchRequests(this.searchParams);
  }
}

export const AdminProposalsDashboardComponent = {
  template: require('./admin-proposals-dashboard.component.jade'),
  controller: AdminProposalsDashboard
};
