import { ApiService } from 'spc/shared/api/api.service';

export default ['ngDialog', function(ngDialog) {
  return function({ lead }) {
    return ngDialog.open({
      template: require('./lead-details-modal.jade'),
      className: 'ngdialog-theme-small lead-modal',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', '$api', function($scope, $api: ApiService, unwrapError) {
        this.init = () => {
          this.activityIndicator = lead.activityIndicator;
          $api.Recos.getRecos(lead._id.toString())
            .then((response) => {
              this.recommendations = response.recommendations;
            })
            .catch(error => unwrapError(error));
        };
        this.init();
        this.getAvailabilityDeltas = (reco) => {
          let availability;
            if (reco.availabilityDeltas[reco.availabilityDeltas.length - 1].value === 'Inquired' && reco.conversation.requestState === 'REQUEST' ) {
              availability = 'Inquired(Client)';
            } else if (reco.availabilityDeltas[reco.availabilityDeltas.length - 1].value === 'Inquired' && reco.conversation.requestState === 'PROPOSAL') {
              availability = 'Inquired';
            } else {
              availability = reco.availabilityDeltas[reco.availabilityDeltas.length - 1].value;
            }
          return availability;
        };
      }]
    }).closePromise;
  };
}];
