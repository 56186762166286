// NPM Dependencies
import { cloneDeep, merge } from 'lodash';
import mongoose from 'mongoose';

// Interfaces
import { SearchQuery, SearchCountResponse, VenueAvailabilityResponse, UpdateVenuePathOp, UpdateVenuePathResponse, ReOrganiseSpacesResponse } from 'server/api/admin/venues/models';


export class Venues {
  BASE_URL: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${this.API_ROOT}/admin/venues`;
  }

  // This should be changed to send a request body
  public addUserToVenue(venueId: string, userId: string) {
    const self = this;
    const url = `${self.BASE_URL}/${venueId}/addUser/${userId}`;
    return self.$http.put(url, null);
  }

  public get(pageNum: number, queryParams: any) {
    const self = this;
    const url = `${self.BASE_URL}/all/${pageNum || 0}`;
    return self.$http.get(url, { params: queryParams });
  }

  // This should be changed to send a req body
  public changeStatus(venue: any, status: string) {
    const self = this;
    const url = `${self.BASE_URL}/${venue._id.toString()}/status/${status}`;
    return self.$http.put(url, null);
  }

  public updateAdminFields({ venue, paths }: { venue: string, paths: UpdateVenuePathOp }) {
    const self = this;
    const url = `${self.BASE_URL}/${venue}/admin`;
    return self.$http.put(url, paths).then((response: { data: UpdateVenuePathResponse }) => response.data);
  }

  public updateReview = ({ update }) => {
    const url = `${this.BASE_URL}/review/${update._id}`;
    return this.$http.put(url, { update })
      .then(response => response.data);
  }

  getVenueAvailability = (venueId: string, date: string): ng.IPromise<VenueAvailabilityResponse> => {
    const url = `${this.BASE_URL}/${venueId}/availability/date/${date}`;
    return this.$http.get(url)
      .then(({ data }: { data: VenueAvailabilityResponse }) => data);
  }

  public search(searchQuery: any, pageNum: number = 0) {
    const self = this;
    const query = cloneDeep(searchQuery);
    query.pageNum = pageNum;
    const url = `${self.BASE_URL}/search/query`;
    return self.$http.get(url, { params: query });
  }

  public download(searchQuery: SearchQuery) {
    const query = searchQuery;
    const url = `${this.BASE_URL}/download`;
    return this.$http.get(url, { params: query })
      .then(response => response.data);
  }

  public getSearchCount(searchQuery?: SearchQuery): ng.IPromise<SearchCountResponse> {
    const self = this;
    const url = `${self.BASE_URL}/search/count/query`;
    return self.$http.get(url, { params: searchQuery })
      .then((response: { data: SearchCountResponse }) => {
        return response.data;
      });
  }

  public getVenueRecoData = (venueId) => {
    const url = `${this.BASE_URL}/${venueId}/reco-data`;
    return this.$http.get(url)
      .then(res => res.data);
  }

  public create(userId: string) {
    const self = this;
    return self.$http.post(self.BASE_URL, { id: userId });
  }

  public findOneByName(text: string, query: any) {
    const self = this;
    const params = {
      text: text
    };
    if (query) {
      merge(params, query);
    }

    return self.$http.get(self.BASE_URL, { params });
  }
  public findManyByName(text: string, query?: any) {
    const self = this;
    const params = {
      text: text,
      multi: true,
      skipVisibility: true
    };
    if (query) {
      merge(params, query);
    }

    return self.$http.get(self.BASE_URL, { params })
      .then(response => response.data);
  }

  public updateSpacesOrder({ update, venueId }: { update: any, venueId: mongoose.Types.ObjectId | string }): ng.IPromise<ReOrganiseSpacesResponse> {
    const self = this;
    return self.$http.put(`${self.BASE_URL}/${venueId}`, { update })
      .then((res: { data: ReOrganiseSpacesResponse}) => res.data);
  }

  public updateFreshYelpReview({ venueId }) {
    const self = this;
    return self.$http.post(`${self.BASE_URL}/${venueId}/yelpUpdate`, {})
      .then(res => res);
  }
}

