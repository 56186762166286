const addSpaceForShellModal = ['ngDialog', function(ngDialog) {
  return function (lead) {
    return ngDialog.open({
      template: '<add-space-for-shell close="$ctrl.close()" lead="$ctrl.lead"></add-space-for-shell>',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal addSpaceForShellModal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.lead = lead;
        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];

export default addSpaceForShellModal;
