class SpaceAmenityIconsComponent {
  
}

export default {
  template: require('./space-amenity-icons.jade'),
  bindToController: true,
  bindings: {
    addon: '<'
  },
  controller: SpaceAmenityIconsComponent
}