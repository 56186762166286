import get from 'lodash/get';
export class SpPardotService {
  constructor(private $config, private $user, private $location) {
    'ngInject';
  }

  public trackUser = ({ user, event }: { user?: any, event: string }) => {
    if (this.$user.isAdmin()) {
      return;
    }

    const formUrl = this.getPardotFormData({ user, event });
    const iframe = this.createIframe();
    iframe.src = formUrl;
    document.body.appendChild(iframe);
  }

  public trackEmailInput( user: { email: string, event: string }): void {
    const params = this.getParams(user);
    const formUrl = `${this.$config.pardot.emailFormHandler}?${params.join('&')}`;

    const iframe = this.createIframe();
    iframe.src = formUrl;
    document.body.appendChild(iframe);
  }

  private createIframe = () => {
    return document.createElement('iframe');
  }

  public determineLocation = () => {
    const path = this.$location.url();
    if (path.includes('/venue/')) {
      return 'request (venue profile) ';
    }

    if (path.includes('/client/conversation/')) {
      return 'proposal page';
    }
  }

  private getParams = (pardotUser) => {
    return Object.keys(pardotUser).map(field => `${field}=${pardotUser[field]}`);
  }

  private getPardotFormData = ({ user, event }: { user?: any, event: string }) => {
    const spUser = this.$user.$ || user;
    const pardotUser = {
      first: get(spUser, 'profile.name.first'),
      last: get(spUser, 'profile.name.last'),
      email: get(spUser, 'profile.email'),
      organization: get(spUser, 'company.name'),
      phone: get(spUser, 'profile.phone'),
      newsletters: get(spUser, 'profile.newsletters', []).length ? true : false,
      event
    };

    const params = this.getParams(pardotUser);

    return `${this.$config.pardot.userFormHandler}?${params.join('&')}`;
  }
}
