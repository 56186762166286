import * as angular from 'angular';
import { TopLevelAdminCalendarComponent } from 'spc/admin/calendar/top-level-admin-calendar.component';
import { AdminCalendarComponent } from 'spc/admin/calendar/admin-calendar.component';
import adminCalendarDayViewModal from 'spc/admin/calendar/admin-calendar-day-view.modal';
import { AdminCalendarDayViewComponent } from 'spc/admin/calendar/admin-calendar-day-view.component';

export const AdminCalendarModule = angular.module('adminCalendarModule', [])
  .component('topLevelAdminCalendar', TopLevelAdminCalendarComponent)
  .component('adminCalendar', AdminCalendarComponent)
  .component('adminCalendarDayView', AdminCalendarDayViewComponent)
  .service('adminCalendarDayViewModal', adminCalendarDayViewModal)
  .name;
