// NPM Dependencies
import * as angular from 'angular';

// SixPlus Dependencies (Modules)
import AnalyticsService from './analytics.service';
import { FullstoryService } from './fullstory.service';
/**
 * VWO service is notimported atm because we are waiting on the SPA implementation
 */
export default angular.module('shared.analytics', [])
  .service('$analytics', AnalyticsService)
  .service('fullstoryService', FullstoryService)
  .name;
