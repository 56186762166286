import * as angular from 'angular';
import { adminCompaniesDashboardComponent } from './admin-companies-dashboard.component';
import { topLevelAdminCompaniesComponent } from './top-level-admin-companies.component';
import { adminCompaniesSearchComponent } from './admin-companies-search.component';
import { adminCompaniesDataComponent } from './admin-companies-data.component';
import { userTransferModal } from './user-transfer.modal';
import { userTransferComponent } from './user-transfer.component';

export const AdminCompaniesModule = angular.module('adminCompaniesModule', [])
  .component('topLevelAdminCompanies', topLevelAdminCompaniesComponent)
  .component('adminCompaniesDashboard', adminCompaniesDashboardComponent)
  .component('adminCompaniesSearch', adminCompaniesSearchComponent)
  .component('adminCompaniesData', adminCompaniesDataComponent)
  .service('userTransferModal', userTransferModal)
  .component('userTransfer', userTransferComponent)
  .name;
