import get from 'lodash/get';
import { RawBookingRequest, BookingRequestClient } from 'database/types/booking-request';
import { RawConversation } from 'database/types/conversation';

class RequestCancelController {
  request: RawBookingRequest;
  conversation: RawConversation;
  close: () => void;
  constructor(private $api, private toast) {
    'ngInject';
  }

  requestCancel = () => {
    this.$api.Requests.cancelRequest(this.request._id)
      .then((res) => {
        this.toast.goodNews('Cancel Request Sent Successfully');
        this.close();
      })
      .catch(() => this.toast.badNews('Uh oh!', 'Something went wrong.'));
  }

}

export const RequestCancelComponent = {
  template: require('./request-cancel.component.jade'),
  controller: RequestCancelController,
  bindings: {
    request: '<',
    conversation: '<',
    venue: '<',
    close: '&'
  }
};
