import mongoose from 'mongoose';

module.exports = function(API_ROOT, $http) {
  return {
    getAvailability: function(spaceId, day) {
      const url = `${API_ROOT}/scheduler/space/${spaceId}/availability/${day}`;
      return $http.get(url);
    },

    setFees: function(spaceId, date, slot) {
      const url = `${API_ROOT}/scheduler/space/${spaceId}/fees/${date}`;
      return $http.put(url, { slot });
    },

    getMonthAvailability: ({ year, month, spaceId }: { year: string, month: string, spaceId: mongoose.Schema.Types.ObjectId | string }) => {
      const url = `${API_ROOT}/scheduler/space/${spaceId}/availability/month`;
      return $http.get(url, { params: { year, month, spaceId }})
        .then(response => response.data);
    }
  };
};
