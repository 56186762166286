// Sixplus Types
import { LocationResponse } from 'server/api/landing/models';
export class LandingRouter {
  BASE_URL: string;

  constructor (private API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${ this.API_ROOT }/landing`;
  }

  public getLocation = (): ng.IPromise<LocationResponse> => {
    const url = `${ this.BASE_URL }/location`;
    return this.$http.get(url)
      .then(response => response.data);
  }
}
