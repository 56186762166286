module.exports = function(API_ROOT, $http) {
  const ret: any = {
  };

  ret.new = function(slug) {
    return $http.post(API_ROOT + '/venues/' + slug + '/spaces', { isVisible: false });
  };

  ret.get = function(slug, id, searchParams) {
    return $http.get(API_ROOT + '/venues/' + slug + '/spaces/' + (id ? id : ''), { params: searchParams });
  };

  ret.save = function(slug, doc) {
    return $http.put(API_ROOT + '/venues/' + slug + '/spaces/' + doc._id, doc);
  };

  ret.setVisibility = function(slug, doc, isVisible) {
    return ret.save(slug, { _id: doc._id, isVisible: isVisible });
  };

  ret.putVisibility = function(slug, doc, isVisible) {
    return $http.put(`${ API_ROOT }/venues/${ slug }/spaces/${ doc._id }/visibility`, { isVisible: isVisible });
  };

  ret.delete = function(slug, doc) {
    return $http.delete(`${ API_ROOT }/venues/${ slug }/spaces/${ doc._id }`);
  };

  ret.validate = function(space) {
    return $http.put(`${ API_ROOT }/venues/spaces/validate`, { space });
  };

  return ret;
};
