
import UserSchema from 'common/dist/schemas/User';
import UserHelpers from 'common/dist/virtuals/User';

export default function() {
  return {
    template: require('./request-assignee-contact-details.jade'),
    scope: {
      assignee: '=',
      role: '@'
    },
    controller: ['$scope', function($scope) {
      $scope.getFullName = UserHelpers.fullName;
    }]
  };
}
