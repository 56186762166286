import UserSpacesNew from './UserSpacesNew';
import UserVenueNew from './user-venue-new.controller';
import UserMenusNew from './UserMenusNew';
import UserDrinksNew from './UserDrinksNew';

const UserVenuesPhotos = require('./UserVenuesPhotos');
const UserMenusPhotos = require('./UserMenusPhotos');
const UserSpacesPhotos = require('./UserSpacesPhotos');
export default {
  UserSpacesNew,
  UserVenueNew,
  UserMenusPhotos,
  UserMenusNew,
  UserDrinksNew,
  UserSpacesPhotos,
  UserVenuesPhotos
};
