import { ApiService } from 'spc/shared/api/api.service';
import { ToastService } from 'spc/shared/toast.service';
import { UserService } from 'spc/services/user.service';

import unset from 'lodash/unset';

// Models
import { RawBookingRequest } from 'lib/database/types/booking-request';

class ReplaceCardController {
  paymentMethods: any[];
  selectedCard: any;
  showForm: boolean = true;
  close: ({ method }?: { method: any }) => any;
  cardParams: {
    cardholderName: string;
  };
  request: RawBookingRequest;
  isLoading: boolean;
  constructor(
    private $api: ApiService,
    private braintreeHelpers,
    private $braintree,
    private toast: ToastService,
    private unwrapError,
    private $user: UserService
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.getUserMethods();
  }

  getUserMethods = () => {
    this.isLoading = true;
    this.$api.Payment.initialize({
      getMethods: true,
      conversation: this.request.conversation.toString()
    })
      .then(this.handlePaymentInitialization);
  }

  handlePaymentInitialization = ({ data }: { data: any }) => {
    const { request, token, paymentMethods } = data;
    const hostedFields = this.braintreeHelpers.generateHostedFieldsOptions(this);
    const extraMethods = {
      onPaymentMethodReceived: this.handlePaymentMethodReceipt,
      onError: this.braintreeHelpers.onError(this)
    };
    this.$braintree.init(
      'payment-form',
      token,
      hostedFields,
      extraMethods
    );

    this.paymentMethods = paymentMethods;
    this.selectedCard = this.paymentMethods && this.paymentMethods.find(method => method.token === this.request.data.payment.token);
    this.isLoading = false;
  }

  submitExistingCard = (method) => {
    return this.handlePaymentMethodReceipt(method);
  }

  showPaymentForm = ({ show }) => {
    this.showForm = show;
  }

  handlePaymentMethodReceipt = (payload) => {
    this.isLoading = true;
    const identifier = payload.nonce ? { nonce: payload.nonce } : { token: payload.token };
    if (this.$user.isAdmin()) {
      this.$api.Admin.Requests.putPayment(this.request, identifier, { cardParams: this.cardParams })
      .then((response) => {
        this.isLoading = false;
        this.toast.goodNews('Card Successfully Replaced');
        this.$braintree.destroy(this.cleanUp);
        this.close({ method: response.data.newMethod });
      })
      .catch((error) => {
        this.toast.badNews('uh oh', 'there was an error');
        this.unwrapError(error);
      });
    } else if (!this.$user.isHostOrAdmin()) {
      this.$api.Requests.putPayment({ request: this.request, identifier, cardParams: this.cardParams })
        .then((response) => {
          this.toast.goodNews('Card Successfully Replaced');
          this.$braintree.destroy(this.cleanUp);
          this.close({ method: response.data.newMethod });
        })
        .catch((error) => {
          this.toast.badNews('uh oh', 'there was an error');
          this.unwrapError(error);
        });
      }
    }

  cleanUp = () => {
    unset(this, 'cardParams');
    unset(this, 'selectedToken');
  }

  cancel = () => {
    this.cleanUp();
    this.close();
  }
}

export const replaceCardComponent = {
  template: require('./replace-card.component.jade'),
  controller: ReplaceCardController,
  bindings: {
    request: '<',
    close: '&'
  }
};
