// External Deps
import last from 'lodash/last';

// Our Deps
import { TabulationService, TabManager } from 'spc/shared/tabulation/tabulation.service';
import { GetConversationsQuery, ConversationsCounts, ConversationQueryState } from 'server/api/conversations/models';
import { ApiService } from 'spc/shared/api/api.service';

const MINIMUM_SEARCH_INTERVAL = 500;
const SCROLL_INTERVAL = 500;

class PersonalInboxController {
  setSeo: (obj: { numRequests: number }) => void;
  componentIsReady: () => void;
  role: 'venue' | 'client';
  tabManager: TabManager;
  search: GetConversationsQuery;
  CONVERSATION_TABS = ['PENDING', 'INQUIRIES'];
  INTERVAL_LENGTH = 1000 * 60;
  NUM_PER_PAGE = 10;
  data: any[];
  counts: ConversationsCounts;
  intervals: { refreshConversations?: any; } = {};
  stateManager: { loading: boolean } = { loading: true };
  $body: JQuery;

  constructor(
    private $api: ApiService,
    private unwrapError,
    private $interval: ng.IIntervalService,
    private ENUMS,
    private tabulationService: TabulationService,
    private $timeout: ng.ITimeoutService,
    private $window: ng.IWindowService,
    private STATES,
    private STATE_COPY_MAPPER
  ) {
    'ngInject';
    this.search = { lastMessage: true, state: 'all', page: 0 };
    const states =
    this.tabManager = this.tabulationService.createTabManager(this.STATES, 'all');
  }

  $onInit() {
    this.$body = $('html, body');
    this.fetchOnDemand()
      .then(() => this.componentIsReady());

    this.intervals
      .refreshConversations = this.$interval(() => {
       this.fetchOnInterval();
      }, this.INTERVAL_LENGTH);
  }

  mapStateToCopy = (state) => {
    return this.STATE_COPY_MAPPER[state];
  }

  scrollToTop = () => {
    this.$body.animate({ scrollTop: 0 }, SCROLL_INTERVAL);
  }

  startSearch = () => {
    this.stateManager.loading = true;
    this.scrollToTop();
  }

  endSearch = () => {
    this.$timeout(() => {
      this.stateManager.loading = false;
    }, MINIMUM_SEARCH_INTERVAL);
  }

  setState = (state: ConversationQueryState) => {
    this.tabManager.switchTab(state);
    this.search.state = state;
    this.search.page = 0;
    return this.getConversations()
      .catch(error => this.handleHttpError(error));
  }

  loadPage = (idx: number) => {
    this.search.page = idx;
    return this.fetchOnDemand()
      .catch(error => this.handleHttpError(error));
  }

  fetchCounts = () => {
    return this.$api.Conversations
      .getConversationsCounts({ from: this.role }, this.search)
      .then(data => this.counts = data.counts)
      .catch(error => this.handleHttpError(error));
  }

  fetchOnDemand = () => {
    this.startSearch();
    return this.fetchCounts()
      .then(() => this.getConversations())
      .then(() => this.endSearch())
      .catch(error => this.handleHttpError(error));
  }

  fetchOnInterval = () => {
    return this.fetchCounts()
      .then(() => this.getConversations())
      .catch(error => this.handleHttpError(error));
  }

  $onDestroy() {
    this.$interval.cancel(this.intervals.refreshConversations);
  }

  getConversations = () => {
    return this.$api.Conversations.getConversations({ from: this.role }, this.search)
      .then((data) => {
        this.data = data.results;
      });
  }

  numUnseen = (tuples: any[]) => {
    if (!tuples) {
      return;
    }

    return tuples
      .filter(tuple => tuple.conversation[this.role === 'venue' ? 'unreadForVenue' : 'unreadForClient'] > 0)
      .length;
  }

  handleHttpError = (error: Error) => {
    this.unwrapError(error);
  }
}

export const PersonalInboxComponent = {
  template: require('./personal-inbox.component.jade'),
  controller: PersonalInboxController,
  bindings: {
    componentIsReady: '&',
    setSeo: '&',
    role: '@'
  }
};
