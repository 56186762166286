import moment from 'moment';
import { get, findIndex, each, filter, isBoolean, round, set } from 'lodash';

module.exports = function () {
  return {
    template: require('./user-scheduler.jade'),
    scope: {
      user: '='
    },
    controller: ['$scope', '$q', '$timeout', '$api', '$schedulerHelpers', '$location', 'ngDialog', '$cloudinary', 'unwrapError', function ($scope, $q, $timeout, $api, $schedulerHelpers, $location, ngDialog, $cloudinary, unwrapError) {
      $scope.$cloudinary = $cloudinary;
      $scope.editSlot = editSlot;
      $scope.getAllAvailability = getAllAvailability;
      $scope.getAvailability = getAvailability;
      $scope.getOpeningHours = getOpeningHours;
      $scope.setDate = setDate;
      $scope.setSpace = setSpace;
      $scope.setVenue = setVenue;
      $scope.updateScheduler = updateScheduler;
      $scope.adminOps = {
        getVenue,
        setVenueFromSearch
      };

      init();

      ///// Listeners
      $scope.$on('INIT', function (ev, params) {
        $scope.venues = params.venues;
        initWithVenues();
      });

      $scope.$emit('READY_TO_INIT');

      ///// Functions

      /**
       * Initialization logic
       */
      function init(slug?: string) {
        $scope.input = {};
        $scope.selectedDate = moment();
        $scope.selectedVenue = null;
        $scope.selectedSpaceIdx = -1;
        $scope.selectedSpaces = null;
        $scope.scheduler = { from: 600, to: 800, spaces: {} };
      }

      function initWithVenues() {
        if ($scope.venues && $scope.venues.length) {
          let vidx = -1;
          let sidx = -1;

          if ($location.search().venue) {
            vidx = findIndex($scope.venues, { slug: $location.search().venue });
            $scope.setVenue(vidx !== -1 ? vidx : 0);

            if (vidx !== -1 && $location.search().space) {
              sidx = findIndex($scope.selectedVenue.data.spaces, { _id: $location.search().space });
              if (sidx !== -1) {
                $scope.setSpace(sidx);
              }
            }
          } else {
            $scope.setVenue(0);
          }
        }
      }

      /**
       * Sets selected venue for the page to retrieve availability
       *
       * @param {Number} idx
       */
      function setVenue(idx) {
        $scope.selectedVenue = $scope.venues[idx];
        $scope.selectedSpaces = getSpaces($scope.selectedVenue);

        $scope.updateScheduler();
        $scope.input._venueName = '';
      }

      /**
       * ADMIN OP: select venue from search
       *
       * @param {Venue}
       */
      function setVenueFromSearch(venue) {
        $scope.selectedVenue = venue;
        $scope.selectedSpaces = getSpaces($scope.selectedVenue);
        $scope.updateScheduler();
        $scope.adminVenues = [];
      }

      function getSpaces(venue) {
        return get(venue, 'data.spaces', []).filter(s => !s.isDeleted);
      }
      /**
       * Set space and retrieve availability for it
       *
       * @param {Number} idx
       */
      function setSpace(idx) {
        $scope.selectedSpaceIdx = idx;
        // -1   =   All Spaces
        // idx > 0  Specific space
        if (idx === -1) {
          $scope.selectedSpaces = getSpaces($scope.selectedVenue);
          $scope.updateScheduler();
          return;
        }

        $scope.selectedSpaces = [$scope.selectedVenue.data.spaces[idx]];
        $scope.updateScheduler();
      }

      /**
       * Sets date parameter for availability
       *
       * @param {String} date
       */
      function setDate(date) {
        $scope.selectedDate = date;
        $scope.updateScheduler();
      }

      /**
       * Gets hours for available spaces so we can set `from` and `to` hours of the scheduler
       *
       * @return {Object}
       */
      function getOpeningHours() {
        let slots = [];
        each($scope.selectedSpaces, function (space) {
          if (!space.data.timeSlots) {
            return;
          }

          slots = slots.concat(
            filter(space.data.timeSlots, i => i.days[$scope.selectedDate.day()])
          );
        });

        return $schedulerHelpers.findOpenHours(slots);
      }

      /**
       * Get bookings for a single space
       *
       * @param {String|Object} spaceId
       * @return {Object}
       */
      function getAvailability(spaceId) {
        return $api.Scheduler.getAvailability(
          spaceId,
          $scope.selectedDate.toISOString().substr(0, 10)
        );
      }

      /**
       * Get bookings for all selected spaces
       *
       * @return {Promise}
       */
      function getAllAvailability() {
        const allSpaces = {};


        each($scope.selectedSpaces, (space) => {
          const id = space._id;
          allSpaces[id.toString()] = $scope.getAvailability(id);
        });

        return $q.all(allSpaces);
      }

      /**
       * Update scheduler with various parameters
       *
       */
      function updateScheduler() {
        const hours = $scope.getOpeningHours();

        const spaces = {};
        $scope.scheduler.from = hours.from;
        $scope.scheduler.to = hours.to;

        $scope.getAllAvailability().
          then(function (availabilities) {
            each($scope.selectedSpaces, function (space) {
              const slots = filter(availabilities[space._id].data.availability.timeSlots, function (i: any) {
                return i.days[$scope.selectedDate.day()];
              });

              spaces[space._id] = {
                meta: space,
                slots: slots
              };
            });

            $timeout(function () {
              $scope.scheduler.spaces = spaces;
            }, 0);
          });
      }

      /**
       * Edits a given slot for a given space
       *
       * @param {Space}
       * @param {Slot}
       * @return {Promise}
       */
      function editSlot(space, slot) {
        if (slot.data.isNA) {
          return;
        }

        return ngDialog.
          open({
            template: require('./edit-slot-timeslot-modal.jade'),
            className: 'ngdialog-theme-small',
            overlay: true,
            plain: true,
            controller: ['$scope', '$rootScope', '$api', 'SelectedVenue', 'Space', 'SelectedDate', 'Slot', 'Scheduler', 
            function ($scope: any, $rootScope, $api, SelectedVenue, Space, SelectedDate, Slot, Scheduler) {
              $scope.selectedVenue = SelectedVenue;
              $scope.space = Space;
              $scope.selectedDate = SelectedDate;
              $scope.slot = Slot;
              $scope.scheduler = Scheduler;

              const roomFeeCents: any = get($scope.slot, 'data.terms.roomFeeCents');
              $scope.uiModel = {
                foodBeverageMin: get($scope.slot, 'data.terms.foodBeverageMin'),
                extraHourFee: get($scope.slot, 'data.terms.extraHourFee'),
                roomFee: round(roomFeeCents / 100, 2)
              };

              $scope.tab = $scope.slot.data.isBlocked ?
                'block' :
                'available';

              $rootScope.$on('$locationChangeStart', function () {
                $scope.closeThisDialog();
              });

              $scope.setRoomFeeCents = function (dollars) {
                const cents = (parseFloat(dollars) * 100).toFixed(0);
                set($scope, 'slot.data.terms.roomFeeCents', cents);
              };

              $scope.saveSlot = function (isBlocked) {
                $scope.slot.data.isBlocked = isBoolean(isBlocked) ?
                  isBlocked :
                  $scope.slot.data.isBlocked;
                const times = { startTime: slot.data.from, endTime: slot.data.to };
                $scope.slot.times = times;
                $api.Scheduler.setAvailability(
                  $scope.space._id,
                  $scope.selectedDate.toISOString().substr(0, 10),
                  $scope.slot)
                  .then(function (data) {
                    const _space = data.data.data;
                    const slots = filter(_space.timeSlots, function (i: any) {
                      return i.days[$scope.selectedDate.day()];
                    });

                    $timeout(function () {
                      $scope.scheduler.spaces[_space._id] = {
                        meta: _space,
                        slots: slots
                      };
                      $scope.closeThisDialog();
                    }, 0);
                  })
                  .catch(unwrapError);
              };
            }],
            resolve: {
              Slot: function () {
                return slot;
              },
              Space: function () {
                return space;
              },
              SelectedVenue: function () {
                return $scope.selectedVenue;
              },
              SelectedDate: function () {
                return $scope.selectedDate;
              },
              Scheduler: function () {
                return $scope.scheduler;
              }
            }
          }).
          closePromise;
      }

      function getVenue(name) {
        if (name && name.length < 3) {
          return;
        }
        $api.Admin.Venues.findManyByName(name, { skipVisibility: true }).
          then(function (response) {
            const venues: any = get(response, 'data.venues');
            if (!venues.length) {
              return;
            }
            $scope.adminVenues = venues;
            // $scope.setVenue(0);
          }).
          catch(unwrapError);
      }

    }]
  };
};
