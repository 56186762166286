import groupBy from 'lodash/groupBy';

export class ReviewsService {
  constructor() {
    'ngInject';
  }

  public calculateRatings = (reviews: any[]) => {
    const reviewsMap = groupBy(reviews, review => review.proposal ? review.proposal._id : null);
    const blendedReviews = Object.values(reviewsMap).map(groupedReviews => this.blend(groupedReviews));
    const finalScores = this.blend(blendedReviews);
    return finalScores;
  }

  public calculateStars = (score: number) => {
    let fullStars = 0;
    let halfStars = 0;
    let emptyStars = 0;

    for (let i = 1; i <= 5; i++) {
      if (i <= score || (i - score > 0 && i - score <= 0.2)) {
        fullStars += 1;
      } else if (i > score && i - score < 0.8 && i - score > 0.2) {
        halfStars += 1;
      } else {
        emptyStars += 1;
      }
    }

    return {
      fullStars,
      halfStars,
      emptyStars
    };
  }

  private blend = (reviews: any[]) => {
    const defaults = {
      ratingScore: 0,
      ratingCount: 0,
      ambienceScore: 0,
      ambienceCount: 0,
      communicationScore: 0,
      communicationCount: 0,
      foodScore: 0,
      foodCount: 0,
      serviceScore: 0,
      serviceCount: 0,
      legacyReviews: 0,
      newReviews: 0
    };

    const totals = reviews.reduce((map, review) => {
      if (review.ambience) {
        map.ambienceCount += review.ambienceCount || 1;
        map.ambienceScore += review.ambience;
      }

      if (review.communication) {
        map.communicationCount += review.communicationCount || 1;
        map.communicationScore += review.communication;
      }

      if (review.food) {
        map.foodCount += review.foodCount || 1;
        map.foodScore += review.food;
      }

      if (review.service) {
        map.serviceCount += review.serviceCount || 1;
        map.serviceScore += review.service;
      }

      if (review.isLegacy || review.legacyReviews) {
        map.legacyReviews += review.legacyReviews || 1;
      } else if (review.newReviews || !review.isLegacy) {
        map.newReviews += review.newReviews || 1;
      }

      map.ratingCount += review.ratingCount || 1;
      map.ratingScore += review.venueRecommendation;

      return map;
    }, defaults);

    return {
      venueRecommendation: totals.ratingScore / totals.ratingCount,
      ratingCount: totals.ratingCount,
      food: totals.foodScore / totals.foodCount,
      foodCount: totals.foodCount,
      service: totals.serviceScore / totals.serviceCount,
      serviceCount: totals.serviceCount,
      ambience: totals.ambienceScore / totals.ambienceCount,
      ambienceCount: totals.ambienceCount,
      communication: totals.communicationScore / totals.communicationCount,
      communicationCount: totals.communicationCount,
      newReviews: totals.newReviews,
      legacyReviews: totals.legacyReviews
    };
  }
}
