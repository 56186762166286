import moment from 'moment';

export interface VenueViewUiData {
  date?: moment.Moment;
  time?: string;
  groupSize?: number;
  showButton?: boolean;
  isAvailable?: boolean;
  availabilityBySpaceId?: {
    [id: string]: {
      isAvailable: boolean,
      groupSizeAvailable: { isAvailable: boolean, reason: string }
    }
  };
}

export class VenueViewUIService {
  public isPublished = venue => venue && venue.status === 'Published';
  public getValidTimes = () => {
    const times = [];
    for (let i = 0; i < 24; i++) {
      const m = moment().hours(i);
      times.push(m.minutes(0).format('h:mma'));
    }
    return times;
  }
}
