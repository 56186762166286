import { get, includes, find, last, some, filter } from 'lodash';
import BookingRequestHelpers from 'common/dist/virtuals/BookingRequest';

const PaymentHelpers = ['$injector', 'ENUMS', '$api', function($injector, ENUMS, $api) {
  const ret = {
    canShowSchedule,
    checkState,
    displayPaymentState,
    getDisplayableSchedule,
    isFailedState
  };
  return ret;

  /**
   * Determine if a schedule has any non-errored payments
   *
   * @param {Array} schedule
   * @return {Boolean}
   */
  function canShowSchedule(schedule) {
    return some(schedule, p => p.state !== 'ERRORED');
  }

  /**
   * Take a request's payment schedule and filter out payments that clients don't need to see (i.e. errored payments)
   *
   * @param {Request} request
   * @return {Array}
   */
  function getDisplayableSchedule(request) {
    const schedule = BookingRequestHelpers.paymentSchedule(request);
    return filter(schedule, p => p.state !== 'ERRORED');
  }
  /**
   * checkState - make sure request is in a given state or redirect
   * @param {Request} request, a given bookingRequest
   * @param {String} state, the state the request must be in
   * @param {String} redirectUrl if request is not in the given state
   * @void
   */
  function checkState(request, state, redirectUrl) {
    const requestState = get(request, 'state');
    if (requestState !== state) {
      $injector.get('$location').path(redirectUrl || '/client/conversation/' + request.conversation);
    }
    return;
  }

  function isFailedState(state) {
    return includes(['ERRORED', 'CANCELLED'], state);
  }

  /**
   * Takes a payment and displays the state of it depending on certain
   * conditions present in the request and payment object
   *
   * @param {Request}
   * @return {String}
   */
  function displayPaymentState(request) {

    const acceptedButNotPaidStates = ['ACCEPTED_GUEST'];
    // Case 1: The request has been accepted by the guest and is pending deposit or the deposit failed
    if (includes(acceptedButNotPaidStates, request.state)) {
      const schedule = get(request, 'data.payment.schedule', []);
      const lastPayment = last(schedule);
      if (get(lastPayment, 'state') === 'ERRORED') {
        return 'Deposit Failed';
      }
      return 'Deposit Pending';
    }

    const payment = get(request, 'data.payment');
    const schedule = get(payment, 'schedule');
    const lastPayment = last(schedule);

    // Case 2: balance payment
    const depositPaidButNotBalanceYetStates = [
      'DEPOSIT',
      'POST_DEPOSIT_ALTERATION',
      'ALTERATION_ACCEPTED_HOST',
      'ALTERATION_ACCEPTED_GUEST'];

    if (includes(depositPaidButNotBalanceYetStates, request.state)) {
      if (get(lastPayment, 'state') === 'ERRORED') {
        return 'Balance Failed';
      }
      return 'Balance Pending';
    }

    // Case 3: Cancelled Post Deposit

    if (ENUMS.bookingRequestState.cancelledPostDepositStates.includes(request.state)) {
      return 'Cancelled';
    }

    // Case 4: Balance completed
    if (request.state === 'PAID') {
      if (get(lastPayment, 'state') === 'COMPLETED') {
        return 'Balance Completed';
      }
    }

    // Case 5: Overage
    if (request.state === 'CONCLUDED') {
      if (get(lastPayment, 'state') === 'ERRORED') {
        return 'Reconcile Failed';
      }
      return 'Reconciliation Pending';
    }

    // Case 6: Pending closure
    if (request.state === 'RECONCILED') {
      return 'Reconciled';
    }

    // Case 7: Closed
    if (request.state === 'CLOSED') {
      return 'Closed';
    }
  }
}];

export default PaymentHelpers;
