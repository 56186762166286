interface SearchData {
  date?: string;
  time?: number;
  duration?: number;
  guestCount?: number;
}
export class RecoRequestService {
  lead: any;
  recommendation: any;
  request: any;
  conversation: any;
  origin: string;
  space: any;

  searchData: SearchData = {};

  constructor() {
    'ngInject';
  }

  setData = ({ lead, request, conversation, recommendation, origin, space }) => {
    this.lead = lead;
    this.request = request;
    this.conversation = conversation;
    this.recommendation = recommendation;
    this.origin = origin;
    this.space = space;
  }

  getData = () => {
    const { lead, recommendation, conversation, request, origin, space } = this;
    return { lead, recommendation, conversation, request, origin, space };
  }

  setSearchData = (searchData: SearchData) => {
    this.searchData = searchData;
  }

  getSearchData = (): SearchData => {
    return this.searchData;
  }
}
