import * as angular from 'angular';
import { AdminLeadsDashboardComponent } from './admin-leads-dashboard.component';
import { DownloadLeadsComponent } from './download-leads.component';
import { adminLeadFormModal } from './admin-lead-form.modal';
import { LeadFilterSidebarComponent } from './lead-filter-sidebar.component';
import { AdminLeadFormComponent } from './admin-lead-form.component';

export default angular.module('admin.leads', [])
  .component('adminLeadsDashboard', AdminLeadsDashboardComponent)
  .component('downloadLeads', DownloadLeadsComponent)
  .component('leadFilterSidebar', LeadFilterSidebarComponent)
  .component('adminLeadForm', AdminLeadFormComponent)
  .service('adminLeadFormModal', adminLeadFormModal)
  .name;
