// External Deps
import { fullName } from 'common/dist/virtuals/User';
import AuthClientsHelpers from 'common/dist/virtuals/AuthorizedClient';
import { fullPriceBreakdownDollars } from 'common/dist/price';
import { integerToCivilianTime } from 'common/dist/time';
import find from 'lodash/find';
import includes from 'lodash/includes';
// SP Deps
import { IContact } from 'spc/shared/contact.constant';

// SP Interfaces
import { QueryRole } from 'server/api/requests/models';
import { RawBookingRequest } from 'spc/lib/database/types/booking-request';

interface StatusMap {
  [index: string]: string[];
}

class PersonalEventsTableController {
  role: QueryRole;
  actionItems: RawBookingRequest[];
  idleItems: RawBookingRequest[];
  tab: string;
  mobile: boolean;

  constructor(
    private TEXT_MAP,
    private STATUS_MAP,
    private CONTACT: IContact
  ) {
    'ngInject';
  }

  getClass = (event) => {
    return find(Object.keys(this.STATUS_MAP), (key: string) => {
      if (includes(this.STATUS_MAP[key], event.state)) {
        return this.mobile ? `${ key }-outline` : key;
      }
    });
  }

  getText = event => this.TEXT_MAP[event.state] || 'Inquiry (No Proposal)';

  public fullName = fullName;
  public integerToCivilianTime = integerToCivilianTime;

  tabIs = (tab: string): boolean => this.tab === tab;

  cityLine = (venue) => {
    return `${venue.data.address.city}, ${venue.data.address.state} ${venue.data.address.zipcode}`;
  }

  conciergePhone = (event) => {
    return this.CONTACT.phone[event.venue.data.address.state];
  }

  public primaryContact = event => AuthClientsHelpers.primaryEventContact(event);

  public getUser = (event) => {
    if (!AuthClientsHelpers.primaryEventContact(event)) {
      return;
    }
    const client = AuthClientsHelpers.primaryEventContact(event);
    return client.user;
  }

  public displayClientName = (event) => {
    const user = this.getUser(event);
    return fullName(user);
  }

  fullPriceBreakdownDollars = event => fullPriceBreakdownDollars(event, true).total;

  requestUrl = request => `/${this.role === 'client' ? 'guest' : 'host'}/conversation/${request.conversation}`;
}

export const PersonalEventsTableComponent = {
  controller: PersonalEventsTableController,
  template: require('./personal-events-table.component.jade'),
  bindings: {
    idleItems: '<',
    actionItems: '<',
    role: '@',
    tab: '@'
  }
};
