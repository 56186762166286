import includes from 'lodash/includes';
export class AdminUsersService {
  user: any;
  constructor() {
    'ngInject';
  }

  public selectUser = (user) => {
    this.user = user;
  }

  public getAssociatedVenues = ({ user, venues }) => {
    return venues
      .filter(venue => includes(venue.authorizedUsers.map(authUser => authUser.user), user._id.toString()));
  }

  public userExistsInVenue = ({ user, venue }) => {
    return includes(venue.authorizedUsers.map(authUser => authUser.user), user._id.toString());
  }
}
