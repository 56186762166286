const Dropzone = require('dropzone');

/**
 * This directive needs to be given a on-upload function
 * */

export const attachmentUploadDirective = () => {

  interface DropzoneScope extends ng.IScope {
    dropzone: any;
    onUpload: ({ url, title }: { url: string, title: string }) => any;
  }

  return {
    template: require('../photos/file-upload.jade'),
    scope: {
      onUpload: '&'
    },
    link: (scope: DropzoneScope, element: ng.IRootElementService, attrs) => {
      const el = element.find('.upload-container')[0];
      const CLICKABLE_SELECTOR = '.upload-container h3';
      scope.dropzone = new Dropzone(el, {
        uploadMultiple: false,
        clickable: element.find(CLICKABLE_SELECTOR)[0],
        acceptedFiles: '.jpg,.png,.jpeg,.pdf,.txt,.tiff,.tif,.rtf',
        parallelUploads: 6,
        url: 'https://api.cloudinary.com/v1_1/dbwijvnzn/auto/upload?folder=attachments',
        previewTemplate: '<div class="dz-progress">' +
          '<span class="dz-upload" data-dz-uploadprogress></span>' +
          '</div>'
      });

      scope.dropzone.on('sending', (file, xhr, formData) => {
        formData.append('upload_preset', 'afliugec');
        scope.$emit('FILE_UPLOADING');
        scope.$apply();
      });

      scope.dropzone.on('success', (file, response) => {
        $(element).find('.dz-progress').fadeOut();
        scope.onUpload({ url: response.secure_url, title: response.original_filename });
        scope.$emit('FILE_UPLOADED');
        scope.$apply();
      });
    }
  };
};
