import { balanceOutstandingCents } from 'common/dist/price';
import { get } from 'lodash';
export default function() {
  return {
    template: require('./payment-schedule.jade'),
    scope: {
      request: '='
    },
    controller: ['$scope', function($scope) {
      $scope.schedule = get($scope, 'request.data.payment.schedule');
      $scope.requestBalanceOutstandingCents = balanceOutstandingCents($scope.request);
    }]
  };
}
