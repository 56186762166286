const facebook = require('facebook-oauth-agent');
const linkedin = require('linkedin-oauth-agent');
import { StateEmitter } from '../utils/StateEmitter';
// constant
import { ANALYTICS_EVENTS } from '../constants/ENUMS/analyticsEvents';

module.exports = function() {
  return {
    template: require('./login.jade'),
    scope: {
      isEmbedded: '='
    },
    controller: ['$scope', '$user', 'ENUMS', '$timeout', '$analytics', 'spPardot', '$location', function($scope, $user, ENUMS, $timeout, $analytics, spPardot, $location) {
      $scope.user = $user;
      $scope.email = '';
      $scope.password = '';
      const $ = $scope.$ =
        new StateEmitter(['INIT', 'SUCCESS', 'FAIL', 'LOGGED_IN', 'BASE_USER'], '');

      const userStates = $user.stream();
      userStates.on('LOGGED_IN', function() {
        if ($.$state() !== 'SUCCESS') {
          $.$state('LOGGED_IN');
          $scope.$apply();
        }
      });
      userStates.on('NOT_LOGGED_IN', function() {
        $.$state('INIT');
        $scope.$apply();
      });

      $scope.emitEvent = emitEvent;

      function emitEvent(eventName, params) {
        $scope.$emit(eventName, params);
      }

      function determineEvent () {
        const location = this.spPardot.determineLocation();
        return location ? `${ location } login` : 'login page';
      }

      $scope.submit = function() {
        if ($.$state() === 'LOGGED_IN' ||
            $.$state() === 'SUCCESS') {
          return;
        }
        // Analytics - clicked login
        const eventName = ANALYTICS_EVENTS.auth.clicked.login;
        $analytics.$trackEvent(eventName);

        $scope.handlePasswordManagers();
        $user.passwordLogin($scope.email, $scope.password)
          .then(function(res) {
            $.$state('SUCCESS');
            $scope.$emit('LOGIN_SUCCESS', res);
            spPardot.trackUser({ event: determineEvent() });
          }).
          catch(function(error) {
            $scope.error = error.data.error;
            if (error.data.user) {
              $.$state('BASE_USER');
              $scope.user = error.data.user;
            } else {
              $.$state('FAIL');
            }
          });
      };

      $scope.linkedinLogin = function() {
        $user.linkedinLogin(function(error, res) {
          if (!error) {
            spPardot.trackUser({ event: determineEvent() });
            $scope.$emit(res.isNew ? 'REGISTER_SUCCESS' : 'LOGIN_SUCCESS',
              res);
          }
        });
      };

      $scope.facebookLogin = function() {
        $user.facebookLogin(function(error, res) {
          if (!error) {
            spPardot.trackUser({ event: determineEvent() });
            $scope.$emit(res.isNew ? 'REGISTER_SUCCESS' : 'LOGIN_SUCCESS',
              res);
          }
        });
      };

      $timeout(function() {
        $scope.$emit('LoginController');
      }, 0);
    }],
    link: function(scope, element, attrs) {
      scope.handlePasswordManagers = function() {
        scope.email =  element.find('#loginEmail').val();
        scope.password = element.find('#loginPassword').val();
      };
    }
  };
};
