export const spaceCounterofferModal = ['ngDialog', function (ngDialog) {
  return (scope) => {
    return ngDialog.open({
      template: `<space-counteroffer></scope-counteroffer>`,
      className: 'ngdialog-theme-small modal-venue-conversation',
      overlay: true,
      plain: true,
      scope: scope
    }).closePromise;
  };
}];
