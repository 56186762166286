// NPM Dependencies
import moment from 'moment';
import UserHelpers from 'common/dist/virtuals/User';
import BookingRequestHelpers from 'common/dist/virtuals/BookingRequest';
import { fullPriceBreakdownCents } from 'common/dist/price';
import { payment } from 'common/dist/enums';

import get from 'lodash/get';
import isFinite from 'lodash/isFinite';

import { ApiService } from 'spc/shared/api/api.service';

export default ['ngDialog', function (ngDialog) {
  return function ({ request, currentUser, conversation, modal }) {
    return ngDialog.open({
      template: require('./manual-payment-modal.jade'),
      className: 'ngdialog-theme-small info-modal',
      overlay: true,
      plain: true,
      controller: ['$scope', '$api', 'unwrapError', 'ManualPaymentControlFactory', '$clickOk', function ($scope, $api: ApiService, unwrapError, ManualPaymentControlFactory, $clickOk) {
        $scope.cancel = cancel;
        $scope.setPaymentCents = setPaymentCents;
        $scope.methods = payment.methods;
        $scope.canSendEmailsToClient = canSendEmailsToClient;
        $scope.canSendEmailsToVenue = canSendEmailsToVenue;
        init();

        // Initialization function
        function init() {
          $scope.request = request;
          $scope.modal = modal;

          const hasCompletedPayment = !!BookingRequestHelpers.nthCompletedPayment($scope.request, 1);

          $scope.payment = detminePayment();
          $scope.depositDollars = ManualPaymentControlFactory.getDepositDollars($scope.payment.amountCents);
          $scope.canToggleDirectPayment = canToggleDirectPayment;
          $scope.handleDirectPaymentToggle = handleDirectPaymentToggle;
          $scope.canSubmitPayment = canSubmitPayment;
        }

        function canSubmitPayment() {
          const hasDate = $scope.payment.date;
          const hasMethod = $scope.payment.method;
          const hasAmount = isFinite($scope.payment.amountCents);
          return hasDate && hasMethod && hasAmount;
        }

        function detminePayment() {
          const costBreakdown = fullPriceBreakdownCents($scope.request);

          const hasDepositPayment = !!BookingRequestHelpers.depositPayment($scope.request);

          const hasBalancePayment = !!BookingRequestHelpers.balancePayment($scope.request);

          const hasDepositRefund = !!BookingRequestHelpers.depositRefund($scope.request);

          const skipBalance = get($scope.request, 'data.payment.skipBalance');

          let title;
          let amountCents;
          if (($scope.modal === 'Intermediate') && hasDepositPayment) {
            title = 'INTERMEDIATE';
            $scope.submit = submitIntermediate;
          } else if ($scope.modal === 'Balance' && hasDepositPayment) {
            title = 'BALANCE';
            amountCents = costBreakdown.balanceOutstanding;
            $scope.submit = submitBalance;
          } else {
            title = 'DEPOSIT';
            amountCents = costBreakdown.deposit;
            $scope.submit = submitDeposit;
          }

          return {
            title,
            isDirectToVenue: !get(conversation, 'admin.visibility.venue'),
            isManual: true,
            date: moment().format('YYYY-MM-DD'),
            enteredBy: UserHelpers.fullName(currentUser),
            amountCents,
            emails: {
              client: canSendEmailsToClient(),
              venue: canSendEmailsToVenue()
            }
          };
        }

        // Public functions

        /**
         * Sets `amountCents` on `$scope.payment` based on dollars that user enters
         *
         * @api public
         * @params {String|Number} dollars
         * @mutates `$scope.payment`
         */
        function setPaymentCents(dollars) {
          ManualPaymentControlFactory.setDepositCents($scope.payment, dollars);
        }

        function cancel() {
          $scope.closeThisDialog({ cancel: true });
        }

        function canSendEmailsToClient() {
          const hasBalancePayment = !!BookingRequestHelpers.nthCompletedPayment($scope.request, 2);

          const skipBalance = get($scope.request, 'data.payment.skipBalance');

          if ($scope.modal === 'Intermediate') {
            return get(conversation, 'admin.visibility.client', false);
          } else {
            if (hasBalancePayment || skipBalance) {
              return false;
            }
            return get(conversation, 'admin.visibility.client', false);
          }
        }

        function canSendEmailsToVenue() {
          const hasBalancePayment = !!BookingRequestHelpers.nthCompletedPayment($scope.request, 2);

          const skipBalance = get($scope.request, 'data.payment.skipBalance');

          if ($scope.modal === 'Intermediate') {
            return get(conversation, 'admin.visibility.venue', false);
          } else {
            if (hasBalancePayment || skipBalance) {
              return false;
            }
            return get(conversation, 'admin.visibility.venue', false);
          }
        }

        function handleDirectPaymentToggle() {
          if ($scope.payment.isDirectToVenue) {
            $scope.payment.emails = { client: false, venue: false };
          }
        }

        function canToggleDirectPayment() {
          return get(conversation, 'admin.visibility.client');
        }

        function submitDeposit() {
          let message;

          if ($scope.payment.isDirectToVenue) {
            message = `This deposit payment is will show that the client paid the venue directly. An entry will automatically be created in the Venue Payments module.`;
          } else {
            message = `Please make sure you have manually charged this payment through our payment processor prior to submitting this payment to avoid issues with potential failed payments.`;
          }

          if ($scope.payment.emails.client) {
            message = `${message} A confirmation email will be sent to the client.`;
          } else {
            message = `${message} A confirmation email will NOT be sent to the client.`;
          }


          if ($scope.payment.emails.venue) {
            message = `${message} A confirmation email will be sent to venue contacts.`;
          } else {
            message = `${message} A confirmation email will NOT be sent to venue contacts.`;
          }

          return $clickOk(message, true)
            .then(function (result) {
              if (get(result, 'value.cancel')) {
                return;
              }

              return $api.Payment.submit($scope.request._id, $scope.payment)
                .then(function (response) {
                  $scope.closeThisDialog({ request: get(response, 'data.request') });
                });
            })
            .catch(unwrapError);
        }

        function submitBalance() {
          let message;

          if ($scope.payment.isDirectToVenue) {
            message = `This balance payment will show that the client paid the venue directly. An entry will automatically be created in the Venue Payments module.`;
          } else {
            message = `Please make sure you have manually charged this payment through our payment processor prior to submitting this payment to avoid issues with potential failed payments.`;
          }

          if ($scope.payment.emails.client) {
            message = `${message} A confirmation email will be sent to the client.`;
          } else {
            message = `${message} A confirmation email will NOT be sent to the client.`;
          }


          if ($scope.payment.emails.venue) {
            message = `${message} A confirmation email will be sent to venue contacts.`;
          } else {
            message = `${message} A confirmation email will NOT be sent to venue contacts.`;
          }

          return $clickOk(message, true)
            .then(function (result) {
              if (get(result, 'value.cancel')) {
                return;
              }
              const currentPayment = $scope.request.data.payment.schedule.find(s => (s.title === 'BALANCE' && s.state === 'PENDING') || (s.title === 'BALANCE' && s.state === 'ERRORED'));

              return $api.Payment.balance({
                requestId: $scope.request._id,
                payment: Object.assign(currentPayment, $scope.payment),
                manual: true,
                amountCents: $scope.payment.amountCents,
                clone: null,
                nonce: null,
                token: null,
                extra: null,
              }).then(response => $scope.closeThisDialog({ request: get(response, 'request') }));
            })
            .catch(unwrapError);
        }

        function submitIntermediate() {
          let message;

          if ($scope.payment.isDirectToVenue) {
            message = `This intermediate payment is will show that the client paid the venue directly. An entry will automatically be created in the Venue Payments module.`;
          } else {
            message = `Please make sure you have manually charged this payment through our payment processor prior to submitting this payment to avoid issues with potential failed payments.`;
          }

          if ($scope.payment.emails.client) {
            message = `${message} A confirmation email will be sent to the client.`;
          } else {
            message = `${message} A confirmation email will NOT be sent to the client.`;
          }


          if ($scope.payment.emails.venue) {
            message = `${message} A confirmation email will be sent to venue contacts.`;
          } else {
            message = `${message} A confirmation email will NOT be sent to venue contacts.`;
          }

          return $clickOk(message, true)
            .then(function (result) {
              if (get(result, 'value.cancel')) {
                return;
              }

              return $api.Payment.intermediate({
                request: $scope.request,
                payment: $scope.payment,
                amountCents: $scope.payment.amountCents
              }).then(response => $scope.closeThisDialog({ request: get(response, 'request') }))
                ;
            })
            .catch(unwrapError);
        }
      }]
    }).closePromise;
  };
}];
