export const VenueNotesModal = ['ngDialog', function (ngDialog) {
  return function (venue) {
    return ngDialog.open({
      template: `<venue-notes ng-if="$ctrl.venue" venue="$ctrl.venue" allow-editing="false">`,
      plain: true,
      className: 'ngdialog-theme-small',
      controllerAs: '$ctrl',
      controller: function() {
        this.venue = venue;
      }
    }).closePromise;
  };
}];
