import spaceAvailability from 'common/dist/spaceAvailability';
import { isAvailable } from 'common/dist/virtuals/AvailabilityCalendar';
import TimeHelpers from 'common/dist/time';
import _ from 'lodash';


import { ApiService } from 'spc/shared/api/api.service';
import { AvailabilityCalendar } from '../../shared/api/admin/availability-calendar';

interface GetSpaceAvailabilityReturn {
  timeAvailability: boolean;
  groupAvailability: { isAvailable: boolean, reason: string };
  matchesTimeSlot: boolean;
}

export class ConciergeDashboardService {
  constructor (private $api: ApiService) {
    'ngInject';
  }

  getSpaceAvailability = ({ space, lead, calendarMap, spaceCalendar }: { space: any, lead: any, calendarMap?: any[], spaceCalendar?: AvailabilityCalendar }): GetSpaceAvailabilityReturn => {

    if (!space || !lead) {
      return;
    }

    const request = lead.request;
    const calendar = spaceCalendar || calendarMap[space._id.toString()][0];
    const endTime = TimeHelpers.getEndTimeFromStartTimeAndDuration(lead.request.time, lead.request.duration);

    const timeAvailability = isAvailable({ calendar, date: lead.request.date, times: { startTime: lead.request.time, endTime } });
    const groupAvailability = spaceAvailability
      .groupSizeAvailability(_.get(request, 'numGuests.min'), space);

      const matchesTimeSlot = spaceAvailability
      .timeAvailability(space, lead.request.date, lead.request.time, lead.request.duration)
      .isAvailable;

    return { timeAvailability, groupAvailability, matchesTimeSlot };
  }

  getTimeSlotTerms = (space, lead) => {
    const request = lead.request;
    const flexible = false;
    const timeSlotIndex = spaceAvailability.getTimeSlot(space, request.date, request.time, request.duration, flexible);
    if (timeSlotIndex !== -1) {
      return space.data.timeSlots[timeSlotIndex].terms;
    }
  }
}
