import { cityNumbers } from 'common/dist/enums';

export interface IContact {
  phone: {
    [key: string]: string;
  };
}

const NY_PHONE = cityNumbers['New York'];
const DC_PHONE = cityNumbers['DC Metro Area'];
const MA_PHONE = cityNumbers['Boston Area'];

export const CONTACT: IContact = {
  phone: {
    NY: NY_PHONE,
    NJ: NY_PHONE,
    MA: MA_PHONE,
    DC: DC_PHONE,
    VA: DC_PHONE,
    MD: DC_PHONE,
    CA: NY_PHONE,
    TN: NY_PHONE
  }
};
