export default ['ngDialog', function (ngDialog) {
  return function (spaces, request) {
    return ngDialog.open({
      template: `<all-spaces spaces="$ctrl.spaces" request="$ctrl.request"></all-spaces>`,
      className: 'ngdialog-theme-plain info-modal',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function($scope) {
        this.spaces = spaces;
        this.request = request;
        this.close = (data) => {
          $scope.closeThisDialog(data);
        };
      }]
    }).closePromise;
  };
}];
