import { ApiService } from 'spc/shared/api/api.service';

export const feeApplicationModal = ['ngDialog', function(ngDialog) {
  return function(request) {
    return ngDialog.open({
      template: `<fee-application source="request" venue="venue" save="save(venue)" cancel="cancel()"></fee-application>` ,
      plain: true,
      className: 'ngdialog-theme-plain',
      overlay: true,
      controllerAs: '$ctrl',
      controller: ['$scope', '$api', 'unwrapError', function($scope, $api: ApiService, unwrapError) {
        $scope.venue = request.venue;

        $scope.save = (venue) => {
          return $api.Requests.updateDirectlyAsHost({ _id: request._id, venue: venue })
            .then((data) => {
              $scope.closeThisDialog({ request: data.request });
            })
            .catch(error => unwrapError(error));
        };

        $scope.cancel = () => {
          return $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];
