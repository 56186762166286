import { integerToCivilianTime } from 'common/dist/time';
// constant
import { ANALYTICS_EVENTS } from '../../constants/ENUMS/analyticsEvents';

class TopLevelConciergeFormController {

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $seo,
    private $routeParams,
    private $user,
    private ENUMS,
    private $analytics,
    private conciergeService) {
    'ngInject';
    const title = 'Concierge';
    const description = ENUMS.metaDescriptions.concierge;
    this.$seo.set(title, description);
  }

  viewReady = () => {
    this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
  }

  redirectTo = (url: string) => {
    return this.$location.path(url);
  }

  trackEvent = (lead, client) => {
    const eventName = ANALYTICS_EVENTS.concierge.submittedPrivateEventLeadForm;
    const eventParams = this.conciergeService.generateFormSubmissionAnalyticsObject({ lead: lead, user: client });
    eventParams.time = integerToCivilianTime(eventParams.time);
    const eventAction = 'Submit';
    this.$analytics.$trackEvent(eventName, eventParams, eventAction);
  }
}

export const TopLevelConciergeForm = {
  controller: TopLevelConciergeFormController,
  template: require('./top-level-concierge-form.component.jade')
};
