import get from 'lodash/get';

class SelectTypeController {
  eventTypes: [{ label: string, visible: boolean }];
  bookingRequest: any;
  persistRequest: ({ eventType, customEventType }: { eventType: string, customEventType: string }) => any;
  eventType: string;
  customEventType: string;
  ui: {
    displayCustomForm: boolean;
  } = { displayCustomForm: false };

  constructor(private ENUMS, private $scope) {
    'ngInject';
  }

  $onInit = () => {
    this.eventTypes = this.ENUMS.eventType.filter(type => type.visible);
    if (!this.bookingRequest) {
      this.bookingRequest = {};
    }
    this.eventType = get<string>(this.bookingRequest, 'data.eventType');
    if (this.eventType === 'Other') {
      this.ui.displayCustomForm = true;
      this.customEventType = get<string>(this.bookingRequest, 'data.customEventType');
    }
  }

  canSubmit = (): boolean => {
    if (this.eventType && this.eventType !== 'Other') {
      return true;
    } else {
      return this.eventType && !!this.customEventType;
    }
  }

  cancel = () => {
    this.ui.displayCustomForm = false;
    this.eventType = null;
    this.customEventType = null;
  }

  selectType = (eventType) => {
    this.eventType = eventType;
    if (this.eventType === 'Other') {
      this.ui.displayCustomForm = true;
    } else {
      this.customEventType = null;
      this.continueOn();
    }
  }

  continueOn = () => {
    if (!this.canSubmit()) {
      return;
    }

    return this.persistRequest({
      eventType: this.eventType,
      customEventType: this.customEventType
    }).then(() => this.$scope.$emit('SELECTED_TYPE', this.bookingRequest));
  }
}

export const SelectTypeComponent = {
  template: require('./select-type.component.jade'),
  controller: SelectTypeController,
  bindings: {
    persistRequest: '&',
    bookingRequest: '='
  }
};
