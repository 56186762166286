// NPM Dependencies
import { fbMinRangeDollars, roomFeeRange } from 'common/dist/price';
import moment from 'moment';
import { Types as MongooseTypes } from 'mongoose';
import spaceAvailability from 'common/dist/spaceAvailability';
import { isAvailable as _isAvailable } from 'common/dist/virtuals/AvailabilityCalendar';
import { timeAsInteger, getEndTimeFromStartTimeAndDuration } from 'common/dist/time';
import { VenueInfoForMaps } from '../../../shared/interfaces/maps';
import Vimeo from '@vimeo/player';

import each from 'lodash/each';
import find from 'lodash/find';
import get from 'lodash/get';
import some from 'lodash/some';
import { debounce } from 'lodash';
import groupBy from 'lodash/groupBy';

import commafy from 'commafy';

// SixPlus Dependencies (Modules)
import match from '../utils/match';
import { GoogleMapsService } from 'spc/shared/google-maps.service';
import { ApiService } from 'spc/shared/api/api.service';
import { VenueViewUIService, VenueViewUiData } from 'spc/venue-view/venue-view-ui.service';
import { StartConversationController } from './start-conversation.controller';
import { IContact } from 'spc/shared/contact.constant';
import { RecoRequestService } from 'spc/recos/reco-request/reco-request.service';

import { StateEmitter } from '../utils/StateEmitter';
import { RawVenue } from 'spc/lib/database/types/venue/venue';
import { RawSpace } from 'spc/lib/database/types/venue/space';
import { UserService } from 'spc/services/user.service';

import { ReviewsService } from '../reviews/reviews.service';

// constant
import { ANALYTICS_EVENTS } from '../constants/ENUMS/analyticsEvents';
import { getYelpRatingColor } from 'spc/shared/yelp-star-color';

// SixPlus Dependencies (CommonJS)
const cancellationPolicyHelpers = require('../../../shared/venues/cancellationPolicy');

const HIDE_NAVBAR = 'HIDE_NAVBAR';
const SHOW_NAVBAR = 'SHOW_NAVBAR';
const DEFAULT_DURATION = 120; // minutes
const DEFAULT_TIME = '7:00pm';

// Interfaces
export interface Accolade {
  year: string;
  accolade: string;
  date: string;
  numberOfAccolades: number;
  imgUrl: string;
}
class VenueView {
  redirect: (obj: { url: string }) => void;
  CRITERIA = ['venueRecommendation'];
  starsMap: {
    [key: string]: {
      fullStars: number;
      halfStars: number;
      emptyStars: number;
    }
  } = {}  ;

  uiData: VenueViewUiData;
  data: any;
  state: any;
  spaces: any[];
  isBot: boolean;
  similarVenues: RawVenue[];
  VALID_TIMES: string[];
  selectedSpace: any;
  selected2: number;
  calendarMap: any;
  uniqueMenuTypes: any = {};
  displayCourses: boolean[] = [];
  displayedMenus: any[] = [];
  selectedMenuType: string = null;
  spaceOption: { [key: string]: string } = { };
  about: string = null;
  allAvailabilities: any;
  flickityOptions: any;
  flickityOptionsNav: any;
  searchParams?: { groupSize?: string, time?: string, space?: string };
  google: any;
  map: any;
  setInfoWindow: any;
  infoWindow: any;
  latLng: string[];
  venueInfo: VenueInfoForMaps;
  marker: any;
  user: UserService;
  isUser: boolean = this.$user.isLoggedIn();
  venuePrice;
  venuePriceDim;
  allAmenities;
  venueVideo: {
    videoPlayerType: string;
    image: string;
    images: [{
      src: string,
      type: string
    }],
    sources: [{
      file: string;
    }]
  };
  isPremiumMember: boolean;
  videoTitle: string;
  showVideoPlaceholder: boolean = true;
  hasFullVideo: boolean = false;
  hasTeaserVideo: boolean = false;
  videoType: string;
  myVimeoPlayerInstance: any;
  myJwPlayerInstance: any;
  currentVideoType: string;
  hasBothPlayerTypeEqual: boolean;
  accoladeUrl: string;

  // filler props
  _date: moment.Moment;
  _groupSize: number;
  timeout;

  constructor(private $cloudinary,
    private $analytics,
    private photoDialog,
    private menuDetailModal,
    private drinksDetailModal,
    private spaceDetailModal,
    private $scrollService,
    private ENUMS,
    private $api: ApiService,
    private $q: ng.IQService,
    private $filter: ng.IFilterService,
    private $injector,
    private unwrapError,
    private $timeout: ng.ITimeoutService,
    private googleMaps: GoogleMapsService,
    private $config,
    private venueViewUIService: VenueViewUIService,
    private obscuredLoginModal,
    private $element,
    private $window,
    private $location,
    private ngDialog,
    private $user: UserService,
    private $scope,
    private CONTACT: IContact,
    private FlickityService,
    private AvailabilityFactory,
    private googleStreetViewModal,
    private recoRequestModal,
    private requestConfirmationModal,
    private addVenueToListModal,
    private recoRequestService: RecoRequestService,
    private reviewsService: ReviewsService) {
    'ngInject';
    this.google = this.$window.google;
    this.searchParams = this.searchParams || {};
  }

  // TODO Get rid of this._date;
  $onInit = () => {
    this.checkVideoPlayerType();
    this.symbolizeVenuePrice({ venue: this.data.venue });
    this.data.venue.data.ratingAndReviews.forEach((review) => {
      review.stars = this.reviewsService.calculateStars(review.rating);
      if (review.name === 'Yelp') {
        review.starColor = getYelpRatingColor(review.rating);
      }
    });
    this.displaySixPlusStars();
    const getCalendars = this.$api.Venues.getCalendars({ venueId: this.data.venue._id })
      .then(({ calendars }: { calendars: any[] }) => this.calendarMap = groupBy(calendars, calendar => calendar.space.toString()))
      .catch(error => this.unwrapError(error));

    this.uiData = {};
    this.spaces = get(this, 'data.venue.data.spaces', [])
      .filter(space => space.isVisible);
    this.user = this.$user;
    this.setFullOrTeaser();

    this.setVenueVideo()
      .then(() => {
        if (this.currentVideoType === 'JWPlayer') {
          this.playVideoWithJWPlayer();
        } else if (this.currentVideoType === 'Vimeo') {
          this.playVideoWithVimeoPlayer();
        }
      })
      .catch(error => this.unwrapError(error));
    this.VALID_TIMES = this.venueViewUIService.getValidTimes();

    this.flickityOptions = {
      wrapAround: true,
      contain: true
    };
    this.flickityOptionsNav = {
      initialIndex: 0,
      cellAlign: 'left',
      freeScroll: true,
      contain: true,
      pageDots: false,
      groupCells: true
    };

    this.displayedMenus = this.data.menus;
    this.getUniqueMenuTypes();
    this.selectMenu(0);
    this.spaces.forEach(space => this.spaceOption[space._id] = 'DESCRIPTION');
    this.about = 'ADMIN';

    if (this.searchParams.time) {
      const theDate = moment(parseInt(this.searchParams.time, 10));
      this.uiData.date = theDate.clone();
      this._date = this.uiData.date;
      this.uiData.time = theDate.format('h:mma');
    }

    if (this.searchParams.groupSize) {
      this.uiData.groupSize = parseInt(this.searchParams.groupSize, 10);
    }

    if (this._date) {
      getCalendars
        .then(() => this.updateFbMinAndAvailability())
        .then(() => this.selectSpaceFromQuery(this.spaces, this.searchParams.space))
        .catch(error => this.unwrapError(error));
    } else {
      this.selectSpaceFromQuery(this.spaces, this.searchParams.space);
    }

    this.state = new StateEmitter([HIDE_NAVBAR, SHOW_NAVBAR], SHOW_NAVBAR);

    this.state.on(HIDE_NAVBAR, () => {
      this.uiData.showButton = true;
      this.$scope.$apply();
    });

    this.state.on(SHOW_NAVBAR, () => {
      this.uiData.showButton = false;
      this.$scope.$apply();
    });
    this.renderMap();
  }

  public checkVideoPlayerType = () => {
    const videos = get(this.data.venue.data, 'videos');
    if (!videos.length) {
      return;
    }
    videos.forEach((vid) => {
      if (vid.title === 'Teaser') {
        this.currentVideoType = vid.videoPlayerType;
      }
    });
    if (videos.length === 2) {
      this.hasBothPlayerTypeEqual = videos.every( (val, i, arr) => val.videoPlayerType === arr[0].videoPlayerType );
    }
  }
  $onDestroy = () => {
    this.$timeout.cancel(this.timeout);
  }

  public setVideoType = (type) => {
    this.videoType = type;
    this.setVenueVideo()
      .then(() => {
        if (!this.hasBothPlayerTypeEqual) {
          if (this.venueVideo.videoPlayerType === 'JWPlayer') {
            this.myVimeoPlayerInstance.destroy();
            this.playVideoWithJWPlayer();
          } else if (this.venueVideo.videoPlayerType === 'Vimeo') {
            this.myJwPlayerInstance.remove();
            this.playVideoWithVimeoPlayer();
          }
        } else if (this.hasBothPlayerTypeEqual) {
          if (this.venueVideo.videoPlayerType === 'JWPlayer') {
            this.playVideoWithJWPlayer();
          }
          if (this.venueVideo.videoPlayerType === 'Vimeo') {
            this.myVimeoPlayerInstance.destroy();
            this.playVideoWithVimeoPlayer();
          }
        }
      })
      .catch(error => this.unwrapError(error));
  }

  public showVenueVideoContainer = () => {
    const videos = get(this.data.venue, 'data.videos');
    const videoTitles = videos.map(video => video.title);
    if (this.$location.host() === 'localhost') {
      return false;
    }
    if (this.isPremiumMember && videos.length) {
      return true;
    }
    else if (!this.isPremiumMember && videoTitles.includes('Teaser')) {
      return true;
    }
    else {
      return false;
    }
  }

  // opens when the user is not logged In
  openLoginModal = () => {
    if (this.user.isLoggedIn() || this.isBot) {
      return;
    }
    return this.obscuredLoginModal({
      primaryUser: '',
      lead: '',
      allowClose: this.$user.isLoggedIn()
    }).then(() => {
      this.user = this.$user;
      this.isUser = this.$user.isLoggedIn();
      this.setVenueVideo()
        .then(() => {
        if (this.venueVideo.videoPlayerType === 'Vimeo') {
          this.playVideoWithVimeoPlayer();
        } else if (this.venueVideo.videoPlayerType === 'JWPlayer') {
          this.playVideoWithJWPlayer();
        }
      })
        .catch(error => this.unwrapError(error));
    }).catch((error) => {
      this.unwrapError(error);
    });
  }

  /**
   * Determines if a venue is published
   *
   */
  public isPublished = (): boolean => {
    return this.data.venue.status === 'Published';
  }

  public isPrivate = (): boolean => {
    return this.data.venue.status === 'Private';
  }

  public isLite = (): boolean => {
    return this.data.venue.status === 'Lite';
  }

  /**
   * Sets a space as the selected space to look at
   */
  public selectSpace = (space): ng.IPromise<VenueView> => {
    if (!space) {
      return;
    }

    this.selectedSpace = space;
    return this
      .$timeout(() => {
        // Initialize our Flickity instance
        const id = `carousel-space-${space._id.toString()}`;
        return this.FlickityService
          .get(id)
          .then(carousel => this.FlickityService.resize(id))
          .catch(error => null);
      })
      .then(() => this)
      .catch(error => this.unwrapError(error));
  }

  openRecoRequestModal = debounce((params) => {
    this.$user.load()
      .then((res) => {
      this.recoRequestService.setSearchData({
      date: this.uiData.date ? moment(this.uiData.date).format('YYYY-MM-DD') : '',
      guestCount: this.uiData.groupSize,
      time: timeAsInteger(moment(this.uiData.time, 'h:mma')),
      duration: DEFAULT_DURATION
    });
    this.recoRequestModal(params)
      .then((data) => {
        const { request, status, lead } = get(data, 'value', {});
        if (request.state !== 'INCOMPLETE') {
          return this.requestConfirmationModal(request, status, lead);
        }
      });
    });
  }, 300);

  public selectMenu = (index: number): ng.IPromise<VenueView> => {
    this.selected2 = index;

    const menu = this.displayedMenus[this.selected2];

    if (!menu) {
      return;
    }

    this.displayCourses = menu.data.courses.map(course => false);
    this.displayCourses[0] = true;

    return this
      .$timeout(() => {
        const ids = [`carousel-menu-${menu._id.toString()}`, 'flick-carousel'];
        // Initialize our Flickity instance
        ids.forEach((id) => {
          return this.FlickityService
            .get(id)
            .then(() => this.FlickityService.resize(id))
            .catch(error => null);
        });
      })
      .then(() => this)
      .catch(error => this.unwrapError(error));
  }

  /**
   * Opens photo dialog to view an array of photos
   *
   */
  public openPhotoDialog = ({ photos, initialPhoto }): Promise<any> => {
    return this.photoDialog({ photos: photos, initialPhoto: initialPhoto });
  }

  /**
   * Generates a url for venue book based on availability query parameters
   *
   */
  public getBookingUrl = (venue, uiData: VenueViewUiData) => {
    let url = `/venue/${get(venue, 'slug')}/book`;
    const queryParams = [];
    each(uiData, (value, key) => {
      if (value && ['date', 'groupSize', 'time'].indexOf(key) !== -1) {
        if (key === 'date') {
          value = value.format('YYYY-MM-DD');
        } else if (key === 'time') {
          value = timeAsInteger(moment(value, 'h:mma'));
        }
        queryParams.push(key + '=' + encodeURIComponent(value));
      }
    });
    const queryString = queryParams.join('&');
    if (queryString) {
      url += '?' + queryString;
    }
    return url;
  }

  /**
 * Converts moment to `YYYY-MM-DD` string
 *
 * @api public
 * @param {Moment}
 * @return {String}
 */
  public setDateFormat = (date) => {
    if (date && moment.isMoment(date)) {
      return date.format('YYYY-MM-DD');
    }
  }

  /**
    * Checks availability based on date and groupSize parameters
    * Algo logic:
    * If there is a date and no time, default the time to 7:00pm
    * If there is no date, this function shouldn't run
    * Calculate availability based on date and time
    **/

  public updateFbMinAndAvailability = () => {

    this.uiData.date = this._date;

    if (!this.uiData.time) {
      this.uiData.time = DEFAULT_TIME;
    }

    this.trackCheckAvailability();

    const date = this.uiData.date.clone();
    const groupSize = this.uiData.groupSize;

    const startTime = timeAsInteger(moment(this.uiData.time, 'h:mma'));
    const endTime = getEndTimeFromStartTimeAndDuration(startTime, DEFAULT_DURATION);

    this.uiData.availabilityBySpaceId = this.spaces.reduce((accumulator, space) => {
      const calendar = this.calendarMap[space._id.toString()][0];
      const isTimeAvailable = _isAvailable({ calendar, date, times: { startTime, endTime } });
      let groupSizeAvailable = spaceAvailability.groupSizeAvailability(groupSize, space);
      if (!groupSize) {
        groupSizeAvailable = { isAvailable: true };
      }
      accumulator[space._id] = { isAvailable: isTimeAvailable, groupSizeAvailable };
      return accumulator;
    }, {});

    this.spaces.sort((a, b) => {
      const aAvailable = this.uiData.availabilityBySpaceId[a._id].isAvailable;
      const bAvailable = this.uiData.availabilityBySpaceId[a._id].isAvailable;
      if (aAvailable && !bAvailable) {
        return -1;
      } else if (!aAvailable && !bAvailable) {
        return 0;
      } else if (bAvailable && !aAvailable) {
        return 1;
      } else {
        // if both are available, compare group sizes
        const aGroupAvailable = this.uiData.availabilityBySpaceId[a._id].groupSizeAvailable.isAvailable;
        const bGroupAvailable = this.uiData.availabilityBySpaceId[b._id].groupSizeAvailable.isAvailable;
        if (aGroupAvailable && !bGroupAvailable) {
          return -1;
        } else if (bGroupAvailable && !aGroupAvailable) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    this.uiData.isAvailable = some(this.uiData.availabilityBySpaceId,
      (availabilityIndicator: { isAvailable: boolean }) => availabilityIndicator.isAvailable);

    if (this.shouldFetchFbMins()) {
      return this.getAndSetFbMins(this.spaces);
    }
  }

  public hasUnavailableSpaces = () => {
    return this.spaces.find(space => !this.isSpaceBookable(space._id));
  }

  public isSpaceBookable = (id) => {
    if (!(this.uiData.time && this.uiData.date)) {
      return true;
    }

    if (this.isSpaceAvailable(id) && !this.uiData.groupSize) {
      return true;
    }

    return this.isSpaceAvailable(id) && this.uiData.availabilityBySpaceId[id].groupSizeAvailable.isAvailable;
  }

  public isSpaceAvailable = (id) => {
    if (this.uiData.time && this.uiData.date) {
      if (!this.uiData.availabilityBySpaceId[id]) {
        return;
      }
      const { isAvailable } = this.uiData.availabilityBySpaceId[id];
      return isAvailable;
    } else {
      return true;
    }
  }

  private getAndSetFbMins = (spaces) => {
    return this.getSpaceAvailabilities(this.spaces)
      .then((availabilities) => {
        this.allAvailabilities = availabilities;
        this.spaces.map(space => spaceAvailability.overridePrice(space, this.allAvailabilities));
      })
      .catch(error => this.unwrapError(error));
  }

  private shouldFetchFbMins = () => {
    if (!this.allAvailabilities) {
      return true;
    }

    return this.uiData.date && (this.allAvailabilities[this.spaces[0]._id.toString()].data.availability.date !== this.uiData.date);
  }


  /**
   * Display Food and beverage minimum, excluding group size when calculating availability
   * calculate fbmin:
   * if there is a date and time
   * find appropriate timeslot and display fbmin of that timeslot
   * if there is no matching timeslot display range for the day
   * if there is no date, display range for default timeslots
   * @api public
   * @param {Space} space
   * @return {String}
   */
  public displayMin = (space) => {
    const inquireMessage = 'Inquire';
    if (get(space, 'data.hidden.fbMin')) {
      return inquireMessage;
    }

    if (this.uiData.date) {
      const time = timeAsInteger(moment(this.uiData.time, 'h:mma'));
      const slot = this.AvailabilityFactory.findSlotInSpace({ space, duration: DEFAULT_DURATION, time });
      return get(slot, 'terms.foodBeverageMin') ? `$ ${commafy(slot.terms.foodBeverageMin)}` : inquireMessage;
    } else {
      return fbMinRangeDollars(space);
    }
  }

  public displayRoomFee = (space) => {
    const inquireMessage = 'Inquire';
    if (this.uiData.date) {
      const time = timeAsInteger(moment(this.uiData.time, 'h:mma'));
      const slot = this.AvailabilityFactory.findSlotInSpace({ space, duration: DEFAULT_DURATION, time });
      return get(slot, 'terms.roomFeeCents') ? `$ ${commafy(slot.terms.roomFeeCents / 100)}` : inquireMessage;
    } else {
      return roomFeeRange(space);
    }
  }

  public dateChange = (date) => {
    this._date = date;
    this.updateFbMinAndAvailability();
  }

  public ambianceClass = (venue: any) => {
    const ambiance = get(venue, 'admin.ambiance');
    return {
      'success-bg': ambiance === 'Casual',
      'primary-alternate-bg': ambiance === 'Charming',
      'info2-bg': ambiance === 'Local Spot',
      'primary-dark-bg': ambiance === 'Historic',
      'info-bg': ambiance === 'Rustic',
      'alternate-bg': ambiance === 'Elegant',
      'primary-bg': ambiance === 'Ritzy',
      'highlight-bg': ambiance === 'Trendy',
      'cozy-bg': ambiance === 'Cozy',
      'eclectic-bg': ambiance === 'Eclectic',
      'hidden-gem-bg': ambiance === 'Hidden Gem',
      'modern-bg': ambiance === 'Modern',
      'stylish-bg': ambiance === 'Stylish',
      'glamorous-bg': ambiance === 'Glamorous',
      'traditional-bg': ambiance === 'Traditional',
      'bright-bg': ambiance === 'Bright',
      'retro-bg': ambiance === 'Retro',
      'old-school-bg': ambiance === 'Old School',
      'functional-bg': ambiance === 'Functional'
    };
  }

  public openStartConversationModal = () => {
    this.$analytics.$trackEvent('VENUE VIEW :: Opened Conversation Modal');
    this.ngDialog.open({
      plain: true,
      className: 'ngdialog-theme-small start-convo',
      overlay: true,
      template: require('./start-conversation.component.jade'),
      controllerAs: '$ctrl',
      resolve: {
        venue: () => this.data.venue,
        userService: () => this.$user
      },
      controller: StartConversationController
    })
      .closePromise
      .then((data: { value?: { conversationId?: string } }) => {
        if (get(data, 'value.conversationId')) {
          this.redirect({ url: `/conversation/${data.value.conversationId}/messages` });
        } else if (get(data, 'value.unauthenticated')) {
          this.$scope.$broadcast(`MESSAGE_SENT`, { message: `Your message was sent to ${this.data.venue.data.name}` });
        }

        // TODO handle case where its a new user: show slider?
      })
      .catch(error => this.unwrapError(error));
  }

  public searchCards = () => {
    const url = `/search/${ this.data.venue.data.address.city.replace(' ', '-') }`;
    window.location.href = url;
  }

  public filterByMenuType = (type) => {

    if (!type) {
      this.displayedMenus = this.data.menus.filter(menu => menu.isVisible);
      return this.selectMenu(0);
    }
    this.displayedMenus = this.data.menus.filter(menu => menu.isVisible && menu.data.type === type);
    this.selectMenu(0);
  }

  public openGoogleStreetViewModal = () => {
    return this.googleStreetViewModal(this.data.venue);
  }
  /**
   * Selects a space from the array of spaces based on the query params
   *
   * @param {QueryParams} queryParams
   */
  private selectSpaceFromQuery = (spaces: any[], spaceId: string | MongooseTypes.ObjectId) => {
    const theSpace = find(spaces, s => s._id.toString() === spaceId);
    return this.selectSpace(theSpace || spaces.filter(space => !space.isDeleted && space.isVisible).shift());
  }

  /**
   * Gets availability for all spaces in an array
   *
   * @api private
   * @param {Array} spaces
   * @return {Promise}
   */
  private getSpaceAvailabilities = (spaces: any[]): any => {
    const date = this.uiData.date.clone();
    return this.AvailabilityFactory.getAvailabilityForDate(spaces, date);
  }

  /**
    * Tracks through analytics that user checked availability for a venue
    */
  private trackCheckAvailability = (): void => {
    this.$injector.get('$analytics')
      .$trackEvent(ANALYTICS_EVENTS.venueView.checkedAvailability, {
        venue: get(this, 'data.venue.data.name'),
        date: this.uiData.date ? this.uiData.date.format('ddd, MMM D, YYYY') : null,
        groupSize: this.uiData.groupSize
      }, 'checked availability');
  }

  private getUniqueMenuTypes = () => {
    this.uniqueMenuTypes = this.ENUMS.menuTypes.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = this.displayedMenus
        .filter(menu => menu.isVisible)
        .some(menu => menu.data.type === currentValue);
      return accumulator;
    }, {});
  }

  private openModal = (type: string, data: any): Promise<any> => {
    return match(type, {
      menu: () => this.menuDetailModal(data),
      drink: () => this.drinksDetailModal(data),
      $default: null
    });
  }

  private displayWarnings = () => {
    return this.uiData.time && this.uiData.date;
  }

  private checkTimeslot = (space) => {
    const time = timeAsInteger(moment(this.uiData.time, 'h:mma'));
    const slot = this.AvailabilityFactory.findSlotInSpace({ space, duration: DEFAULT_DURATION, time });
    return !!slot;
  }

  // Google Maps Helpers
  private renderMap = () => {
    this.latLng = get<any[]>(this, 'data.venue.data.address.coordinates', []);

    this.map = new this.google.maps.Map(document.getElementById('map'), {
      center: { lat: this.latLng[1], lng: this.latLng[0] },
      zoom: 15,
      mapControl: false,
      mapTypeControl: false,
      zoomControl: false,
      scaleControl: false,
      fullscreenControl: false,
      streetViewControl: false,
    });

    this.map.setOptions({
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }]
        }
      ]
    });

    this.venueInfo = {
      name: this.data.venue.data.name,
      address: `${this.data.venue.data.address.line1}, ${this.data.venue.data.address.city}, ${this.data.venue.data.address.state} ${this.data.venue.data.address.zipcode}`,
      lat: this.latLng[1],
      lng: this.latLng[0],
    };

    this.setMarker();
    this.addGoogleInfo(this.data.venue);
  }

  private setMarker(): void {
    const contentStr = `<div class='map-pin-content'><h6>${this.venueInfo.name}</h6><p>${this.venueInfo.address}</p></span>`;

    this.infoWindow = new this.google.maps.InfoWindow({ content: contentStr });

    this.marker = new this.google.maps.Marker({
      map: this.map,
      position: new this.google.maps.LatLng(this.latLng[1], this.latLng[0])
    });

    this.marker.addListener('click', (ev) => {
      this.infoWindow.open(this.map, this.marker);
    });

    this.infoWindow.open(this.map, this.marker);
  }

  public addGoogleInfo(venue: RawVenue): void {
    let starClass = '';
    const googleReview = venue.data.ratingAndReviews.find(r => r.name === 'Google');

    if (googleReview) {
      const rounded = Math.round(+googleReview.rating * 2) / 2;

      for (let i = 1; i <= 5; i++) {
        if (i <= rounded || (i - rounded > 0 && i - rounded <= 0.2)) {
          starClass += `<i class='fa fa-star'></i>`;
        } else if (i > rounded && i - rounded < 0.8 && i - rounded > 0.2) {
          starClass += `<i class='fa fa-star-half-o'></i>`;
        } else {
          starClass += `<i class='fa fa-star-o'></i>`;
        }
      }
    const googleMapsUrl = googleReview.url ? googleReview.url : venue.admin.googleMapsUrl;
    this.infoWindow.setContent(`
    <div class='map-pin-content'><h6>${venue.data.name}</h6><p>${venue.data.address.line1}</p>
    <span>${googleReview.rating}<span2><span3>${starClass}</span3></span2><span1>${googleReview.numberOfReviews} reviews</span1></span><a href=${googleMapsUrl} target='blank'>View in Google Maps</a></div>
    `);
    } else {
      this.infoWindow.setContent(`
        <div class='map-pin-content'><h6>${venue.data.name}</h6><p>${venue.data.address.line1}<br /> ${venue.data.address.city}, ${venue.data.address.state} ${venue.data.address.zipcode} </p>
      `);
    }
  }

  private getGoogleMapsUrl = (): string => {
    const coordinates = get<number[]>(this, 'data.venue.data.address.coordinates');
    if (!coordinates) {
      return null;
    }

    return this.googleMaps.getStaticGoogleMapsUrl(coordinates);
  }

  private getGoogleMapsLink = (): string => {
    const name = get(this, 'data.venue.data.name');
    const str = `${name}`;
    const coordinates = get(this, 'data.venue.data.address.coordinates');
    if (!coordinates) {
      return null;
    }
    const coordinatePair = coordinates[1] + ',' + coordinates[0];
    return 'http://maps.google.com/?q=' + encodeURIComponent(str) + '&' + coordinatePair;
  }

  public showReview = ({ review }) => {
    if (!review) {
      return false;
    }

    if (review.name === 'Google' ) {
      return !this.data.venue.hideReview.google;
    } else if (review.name === 'Yelp') {
      return !this.data.venue.hideReview.yelp;
    }
  }

  public displaySixPlusStars = () => {
    const reviewData = this.reviewsService.calculateRatings(this.data.reviews);
    if (this.data.reviews && this.data.reviews.length) {
      this.CRITERIA.forEach(criteria => this.starsMap[criteria] = this.reviewsService.calculateStars(reviewData[criteria]));
    }
  }

  private addVenueToList () {
    if (!this.data.venue.isVisible) {
      return;
    }
    return this.addVenueToListModal(this.data.venue);
  }

  public symbolizeVenuePrice = ({ venue }) => {
    const price = venue.data.price;
    const city = venue.data.address.city;
    const currency = city === 'London' ? '£' : '$';
    this.venuePrice = currency;
    if (price > 0) {
        for (let i = 1; i < price; i++) {
            this.venuePrice += currency;
        }
        this.venuePriceDim = currency.repeat(4 - this.venuePrice.length);
    }
  }

  public setVenueVideo = async() => {
    this.isPremiumMember = await this.user.isPremiumMember();

    const videos = get(this.data.venue.data, 'videos');
    if (!videos.length) {
      return;
    }

    videos.forEach((video) => {
      video.title === 'Full' ? this.hasFullVideo = true : this.hasTeaserVideo = true;
      if (this.isPremiumMember && this.videoType === 'Full' && video.title === 'Full') {
        this.videoTitle = video.title;
        this.venueVideo = {
          videoPlayerType: video.videoPlayerType,
          image: video.thumbnail,
          images: [{
            src: video.videoThumbnail,
            type: 'video/mp4',
          }],
          sources: [{
            file: video.url
          }]
        };
      }
      else if (this.isPremiumMember && this.videoType === 'Teaser' && video.title === 'Teaser') {
        this.videoTitle = video.title;
        this.venueVideo = {
          videoPlayerType: video.videoPlayerType,
          image: video.thumbnail,
          images: [{
            src: video.videoThumbnail,
            type: 'video/mp4',
          }],
          sources: [{
            file: video.url
          }]
        };
      } else if (!this.isPremiumMember && video.title === 'Teaser') {
        this.videoTitle = video.title;
        this.venueVideo = {
          videoPlayerType: video.videoPlayerType,
          image: video.thumbnail,
          images: [{
            src: video.videoThumbnail,
            type: 'video/mp4',
          }],
          sources: [{
            file: video.url
          }]
        };
      }
    });
  }
  public clickAction = () => {
    return this.isPremiumMember ? this.setVideoType('Full') : this.$window.open('/upgrade');
  }

  public setFullOrTeaser = async() => {
    this.isPremiumMember = await this.user.isPremiumMember();
    const videos = get(this.data.venue.data, 'videos');
    this.hasFullVideo = some(videos, { title : 'Full' });
    this.hasTeaserVideo = some(videos, { title : 'Teaser' });
    if (videos.length === 1) {
      this.videoType = videos[0].title;
      this.currentVideoType = videos[0].videoPlayerType;
    } else {
      this.videoType = 'Teaser';
    }
  }

  public hasVenueVideo = () => {
    if (this.venueVideo) {
      return true;
    }
    return false;
  }

  public playVideoWithJWPlayer = () => {
    if (this.$location.host() === 'localhost' || !this.venueVideo || this.venueVideo.videoPlayerType !== 'JWPlayer') {
      return;
    }
    this.showVideoPlaceholder = false;
    this.myJwPlayerInstance = jwplayer('jwPlayerDiv').setup({
      playlist: [this.venueVideo],
      controls: true,
      autostart: 'viewable',
      mute: true,
    });

    this.myJwPlayerInstance.on('beforeComplete', () => {
      if (this.videoTitle && this.videoTitle === 'Teaser' && !this.isPremiumMember) {
        const overlay = document.getElementById('video-overlay');
        overlay.classList.add('show-overlay');
      }
    });
  }

  public playVideoWithVimeoPlayer = () => {
    if (this.$location.host() === 'localhost' || !this.venueVideo || this.venueVideo.videoPlayerType !== 'Vimeo') {
      return;
    }

    let data;
    const videoUrl = this.venueVideo.sources[0].file;
    if (videoUrl.includes('https://vimeo.com')) {
      const videoKey = videoUrl.split('/').pop().split('?')[0];
      data = Number(videoKey);
    }
    const options = {
      id: data,
      width: 642,
      controls: true,
      autoplay: true,
      muted: true,
      title: false
    };
    this.showVideoPlaceholder = false;
    setTimeout(() => {
      this.myVimeoPlayerInstance = new Vimeo('vimeoPlayerDiv', options);
      this.myVimeoPlayerInstance.on('ended', () => {
        if (this.videoTitle && this.videoTitle === 'Teaser' && !this.isPremiumMember) {
          const overlay = document.getElementById('video-overlay');
          overlay.classList.add('show-overlay');
        }
      });
    }, 500);
  }
}

export const VenueViewComponent = {
  template: require('./venue-view.component.jade'),
  bindings: {
    data: '<',
    similarVenues: '<',
    searchParams: '<',
    isBot: '<',
    redirect: '&'
  },
  controller: VenueView
};
