import { convertToDollarsAndRound } from 'common/dist/price';

// Lodash
import { isFinite, isNumber } from 'lodash';

export default function() {
  const ret = {
    getDepositDollars,
    setDepositCents
  };
  return ret;

  /**
   * Sets `amountCents` on `$scope.payment` based on dollars that user enters
   *
   * @api public
   * @params {String|Number} dollars
   * @mutates `$scope.payment`
   */
  function setDepositCents(payment, dollars) {
    payment.amountCents = isFinite(dollars) ?
      parseFloat(dollars) * 100
      : null;
  }

  /**
   * Gets dollar amount from cents passed in
   *
   * @api public
   * @params {Number} cents
   * @return {String}
   */
  function getDepositDollars(cents) {
    return isFinite(cents)
      ? convertToDollarsAndRound(cents)
      : null;
  }
}
