
export const fadeOutOn = function() {
  return function(scope, element, attrs) {
    attrs.fadeOutOn.split(',').forEach(function(ev) {
      scope.$on(ev, function() {
        element.fadeOut(attrs.fadeSpeed).removeClass('test-hidden');
      });
    });
  };
};
