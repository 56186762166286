
import { RawList } from 'spc/lib/database/types/list';
import { RawListClient } from 'spc/lib/database/types/list-client';
import { RawBaseUser } from 'spc/lib/database/types/base-user';

export default ['ngDialog', function(ngDialog) {

  return function({ user, list }: { user: RawBaseUser, list: RawList}) {
    return ngDialog.open({

      template: '<remove-list-collaborators user="$ctrl.user" list="$ctrl.list" close="$ctrl.close()"></remove-list-collaborators>',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.user = user;
        this.list = list;

        this.close = () => {
          $scope.closeThisDialog();
        };

      }],
    }).closePromise;
  };
}];
