// NPM Dependencies
import moment from 'moment-timezone';

import ENUMS from 'common/dist/enums';
import { get } from 'lodash';


export class CityTzMapperService {

  public getCityTimezone = (city) => {
    const leadCity = ENUMS.acceptableUserCities.find(_city => _city.value === city);
    return get(leadCity, 'timezone');
  }

  public getCityTZmappedDate = ({ city, date }: { city: string, date: Date | string }) => {
    const tz = this.getCityTimezone(city);
    return tz ? moment.tz(date, tz) : '';
  }
}
