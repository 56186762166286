import { EventResult } from 'shared/interfaces/metrics/event-result.model';
import { TotalLeadConversionResponse, NPS } from 'server/api/admin/metrics/models';
interface GetEventsResponse {
  data: {
    results: EventResult[],
    nps: NPS;
  };
}

export class Metrics {
  metricUrl: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    this.metricUrl = `${ this.API_ROOT }/admin/metrics`;
  }
  public getEvents(params): ng.IPromise<{ nps, results }> {
    const url = `${ this.metricUrl }/events`;

    return this.$http.get(url, { params })
      .then((response: GetEventsResponse) => {
        return response.data;
      });
  }
  public getTotalLeadConversion = (params): ng.IPromise<TotalLeadConversionResponse> => {
    return this.$http.get(`${this.metricUrl}/conversion/leads`, { params })
      .then((response: { data: TotalLeadConversionResponse }) => response.data);
  }
}
