import * as angular from 'angular';
import { each } from 'lodash';
import directives from './directives';
import { CostBreakdownComponent } from './components/cost-breakdown.component';
import { paymentSnapshotComponent } from './components/payment-snapshot.component';

const services = require('./services');

const m = angular.module('payment', []);

m
  .component('paymentSnapshot', paymentSnapshotComponent)
  .component('costBreakdown', CostBreakdownComponent);

each(directives, function(directive, name) {
  m.directive(name, directive);
});

each(services, function(factory, name) {
  m.factory(name, factory);
});

module.exports = 'payment';
