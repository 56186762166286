// Sixplus Deps
import { ApiService } from 'spc/shared/api/api.service';

// External Deps
import ENUMS from 'common/dist/enums';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';

// Sixplus Types

import { ConciergeService } from './concierge.service';
import { FeaturedVenue } from 'spc/lib/server/api/featured-venue/models';
import { SpPardotService } from 'spc/shared/sp-pardot/sp-pardot.service';

// constant
import { ANALYTICS_EVENTS } from '../../constants/ENUMS/analyticsEvents';

const DEFAULT_CITY = ENUMS.concierge.defaultCity;

class ConciergeSubmittedComponent {
  adminPhone: string;
  city: string;
  lead: any;
  client: any;
  featuredVenues: FeaturedVenue[];
  inputs: { password?: string } = {};
  error: {
    message: string;
  } = null;
  ui: {
    registrationSuccess: boolean;
    allowRegistration: boolean
  } = {
    registrationSuccess: false,
    allowRegistration: false
  };
  searchCity;
  isBaseUser: boolean;
  isConciergeSubmittedPage: boolean;

  constructor(
    private $api: ApiService,
    private $user,
    private $analytics,
    private $rootScope,
    private unwrapError,
    private $window: ng.IWindowService,
    private $cloudinary,
    private conciergeService: ConciergeService,
    private ENUMS,
    private spPardot: SpPardotService,
    private $location,
    private welcomePromoCodeModel
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.isConciergeSubmittedPage = this.$location.path() === '/concierge-submitted';
    this.$analytics.$waitForInit(() => {
      this.$analytics.adwordsConciergeCampaign();

      // track fb lead
      this.$window.fbq('track', 'Lead', {
        value: 0.00,
        currency: 'USD'
      });

      // track bing
      this.$window.uetq = this.$window.uetq || [];
      this.$window.uetq.push({ ec: `concierge`, ea: `submitted` });
    });

    this.lead = this.conciergeService.getLeadAndClient().lead;
    this.client = this.conciergeService.getLeadAndClient().client;
    this.findUser(get(this.client, 'profile.email'));

    if (!this.$user.$ && this.client) {
      this.spPardot.trackUser({ user: this.client, event: 'concierge form' });
    }

    this.city = get(this, 'lead.request.city');

    this.adminPhone = this.ENUMS.cityNumbers[this.city] || this.ENUMS.cityNumbers[DEFAULT_CITY];

    this.searchCity = this.ENUMS.acceptableUserCities.find(city => city.name === this.city);

    if (this.searchCity) {
      this.$api.FeaturedVenues.get(this.searchCity.city)
        .then(data => this.featuredVenues = data.featuredVenues);
    }

    this.ui.allowRegistration = this.$user.isNotLoggedIn();
    this.$rootScope.$emit('$viewReady', 'SHOW_FOOTER');
  }

  findUser = (email) => {
    if (!email) {
      return;
    }
    return this.$api.Auth.findUser(email)
      .then((data) => {
        this.isBaseUser = get(data, 'isBaseUser');
      });
  }

  getCityUrl = () => {
    if (this.searchCity) {
      return this.searchCity.slug.replaceAll(' ', '-');
    }
  }

  register = () => {
    if (!this.inputs.password) {
      return;
    }
    this.error = null;
    this.client.profile.city = this.lead.request.city;
    const data = {
      guest: this.client,
      password: this.inputs.password
    };

    this.$api.Auth.register(data)
      .then((response) => {
        this.handleRegisterSuccess(response.data);
        this.welcomePromoCodeModel();
      })
      .catch((error) => {
        this.error = error.data.error;
        this.unwrapError(error);
      });
  }

  login = async () => {
    const clientEmail = get(this.client, 'profile.email');
    if (!this.inputs.password || !clientEmail) {
      return;
    }
    this.error = null;
    return this.$user.passwordLogin(clientEmail, this.inputs.password)
    .then(() => {
      this.ui.registrationSuccess = true;
      return this.ui.allowRegistration = false;
    })
    .catch((error) => {
      this.error = {
        message: get(error, 'data.error')
      };
      this.unwrapError(error);
    });
  }

  handleRegisterSuccess = ({ user, token, provider }) => {
    this.ui.registrationSuccess = true;
    this.ui.allowRegistration = false;
    this.$user.setUser(user, token, provider);

    // Analytics - register (alias) user, then identify
    this.$analytics.$register(this.$user.$);
    const eventName = provider ?
      'registered' + capitalize(provider) :
      'registered';
    const eventAction = 'Submit';
    this.$analytics.$trackEvent(ANALYTICS_EVENTS.auth[eventName], '', eventAction);
  }

}

export default {
  template: require('./concierge-submitted.jade'),
  bindToController: true,
  controller: ConciergeSubmittedComponent
};
