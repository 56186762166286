import { capitalize } from 'lodash';
// constant
import { ANALYTICS_EVENTS } from '../../constants/ENUMS/analyticsEvents';

const LoginRegisterModal = ['ngDialog', function(ngDialog) {
  return function({ request, userService }) {
    return ngDialog.open({
      template: require('./login-register-modal.jade'),
      className: 'ngdialog-theme-plain info-modal',
      overlay: false,
      plain: true,
      controller: ['$scope', 'ENUMS', '$analytics', '$rootScope', '$timeout', 'spPardot', 'unwrapError', function($scope, ENUMS, $analytics, $rootScope, $timeout, spPardot, unwrapError) {
        ///// Listeners

        $scope.$on('CHANGE_TAB', function(ev, params) {
          ev.stopPropagation();
          $scope.tab = params.tab;
          $timeout(() => $scope.$broadcast('IS_MODAL'));
        });

        $scope.$on('LOGIN_SUCCESS', function(ev, res) {
          $analytics.$identify(userService.$);

          // Analytics - logged in with email, facebook, or linkedin
          const eventName = res && res.provider ?
            'loggedIn' + capitalize(res.provider) :
            'loggedIn';
          const eventAction = 'login sucessfully';
          $analytics.$trackEvent(ANALYTICS_EVENTS.auth[eventName], '', eventAction);
          $scope.closeThisDialog({ login: true });
        });

        $scope.$on('REGISTER_SUCCESS', function(ev, res) {
          // log them in
          userService.setUser(res.user, res.token, res.provider);

          // Analytics - register (alias) user, then identify
          $analytics.$register(userService.$);
          const eventName = res.provider ?
            'registered' + capitalize(res.provider) :
            'registered';
            const eventAction = 'registered sucessfully';
            $analytics.$trackEvent(ANALYTICS_EVENTS.auth[eventName], '', eventAction);
          $scope.closeThisDialog({ register: true });
        });

        ///// Functions
        function init() {
          $scope.tab = 'REGISTER';
        }

        init();
      }]
    }).closePromise;
  };
}];

export default LoginRegisterModal;
