import { ApiService } from 'spc/shared/api/api.service';
import AnalyticsService from 'spc/shared/analytics/analytics.service';

export class SubscriptionCardServices {
    user: any;
    isLoggedIn: boolean;
    premiumRegistrationRequest: boolean;
    premiumAccountRequested: boolean = false;
    requestModal: boolean = false;
    isUser: boolean;
    pro: boolean;
    premium: boolean;
    isDowngraded: boolean;
    constructor(
        private $user,
        private obscuredLoginModal,
        private unwrapError,
        private $api: ApiService,
        private $analytics: AnalyticsService,
    ) {
        'ngInject';
    }

    setUpUserData = async () => {
        try {
            const { data } = await this.$api.Auth.me();
            this.isDowngraded = data.isDowngraded;
            this.user = data.user;
            if (!this.user) {
                this.isLoggedIn = false;
            }
            else {
                this.isLoggedIn = true;
                this.pro = this.user.accountTier ? this.user.accountTier.includes('Pro') : false;
                this.premium = this.user.accountTier ? this.user.accountTier.includes('Premium') : false;
                this.premiumAccountRequested = this.user.premiumAccountRequested;
            }
            return {
                isDowngraded: this.isDowngraded,
                user: this.user,
                isLoggedIn: this.isLoggedIn,
                pro: this.pro,
                premium: this.premium,
                premiumAccountRequested: this.premiumAccountRequested
            };
        }
        catch (error) {
            this.unwrapError(error);
        }
    }

    private requestPremium = async () => {
        this.requestModal = true;
        this.$api.MembershipAccount.requestPremiumAccount()
            .then((res) => {
                this.premiumAccountRequested = true;
                this.$analytics.adwordsUpgradeInquiry();
            }).catch(err => this.unwrapError(err));
        return this.requestModal;
    }

    closeModal = () => {
        location.reload();
        return false;
    }

    openObscuredLoginModal = async () => {
        try {
            await this.obscuredLoginModal({
                primaryUser: '',
                allowClose: this.$user.isLoggedIn(),
                premiumRegistrationRequest: this.premiumRegistrationRequest
            });
            this.user = this.$user;
            this.isUser = this.$user.isLoggedIn();
            if (this.isUser) {
                return this.requestPremium();
            }
        } catch (error) {
            this.unwrapError(error);
        }
    }

    async requestAccess() {
        this.isUser = this.$user.isLoggedIn();
        this.premiumRegistrationRequest = true;
        if (this.isUser) {
            return this.requestPremium();
        }
        return await this.openObscuredLoginModal();
    }
}
