/**
 * Determines the background color associated with a given Yelp rating.
 *
 * @param {Number} rating - The Yelp rating, a number between 0.0 and 5.0.
 *
 * @returns {String} The Yelp branded stars color code based on rating
 */
export const getYelpRatingColor = (rating) => {
  if (rating >= 4.9) {
    return '#fb433c';
  } else if (rating > 3.8) {
    return '#ff643d';
  } else if (rating > 2.8) {
    return '#ff8742';
  } else if (rating > 1.7) {
    return '#ffad48';
  } else if (rating > 0.2) {
    return '#ffcc4b';
  } else {
    return '#e5e5e6';
  }
};
