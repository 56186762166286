import { ApiService } from 'spc/shared/api/api.service';
import { UserService } from 'spc/services/user.service';
import { every, get, set } from 'lodash';
import ENUMS from 'common/dist/enums';

class CreateBaseUser {
    close: () => void;
    $user: UserService;
    types = ENUMS.userTypes;

  constructor(
    private $scope,
    private unwrapError,
    private toast,
    private $timeout,
    private $api: ApiService,

  ) {
        'ngInject';
    }

  private baseUserInput: {
    profile: {
        name: {
            first: string,
            last: string
        },
        email: string,
        city: string,
    },
    company: {name: string},
    roles: string[]
  } = {
    profile: {
        name: {
            first: '',
            last: ''
        },
        email: '',
        city: '',
    },
    company: { name: '' } ,
    roles: []
  };
  private options: { venueUser: boolean } = { venueUser: false };

  $onInit = () => {
    this.$scope.roles = ['Client', 'Venue'];
    this.$scope.cities = ENUMS.acceptableUserCities;
    this.$scope.user = this.$user;
    this.$scope.baseUser = this.baseUserInput;
    this.$scope.role = '';
    this.$scope.company = '';
    this.$scope.wantsNewsletter = true;
    set(this.$scope.baseUser, 'profile.newsletters', ['Main']);
    this.$scope.toggleNewsletter = () => {
      this.$scope.wantsNewsletter = !this.$scope.wantsNewsletter;
      const newsletters = this.$scope.wantsNewsletter ? ['Main'] : [];
      set(this.$scope.baseUser, 'profile.newsletters', newsletters);
    };
  }

  register() {
    this.$scope.disableButton = true;
    this.addRole();
    const user = this.$scope.baseUser;
    return this.$api.BaseUsers.create(user, this.options)
        .then(() => {
            this.close();
            this.toast.goodNews('Success!', 'Base User Created');
        }).catch((error) => {
          this.$scope.error = get(error, 'data.error.message');
          this.$scope.disableButton = false;
          this.unwrapError(error);
        });
  }

  findPrimaryCity (cityValue)  {
    const cityObj = this.$scope.cities.find(city => city.value === cityValue);
    if (cityObj) {
      return cityObj.name;
    }
  }

  addRole() {
    if (this.$scope.role.trim() === 'Venue') {
      this.$scope.baseUser.roles = ['Host'];
    }
    else {
      this.$scope.baseUser.roles = [];
    }
  }

  selectPrimaryCity (city) {
    set(this.$scope.baseUser, 'profile.city', city);
    this.$scope.primaryCity = this.$scope.cities[city];
  }

  selectCompany (company) {
    this.$scope.baseUser.company.name = company;
  }

  verifyEmail () {
    this.$api.Auth.verifyEmail(this.$scope.baseUser.profile.email)
      .then((res) => {
        this.$scope.email = {
          address: res.data.address,
          isValid: res.data.valid
        };
      }).catch((error) => {
          this.$scope.email = {
            address: error.data.address,
            isValid: error.data.valid
          };
      });
  }

  hasEnteredAllFields  () {
    const requiredFields = [
      get(this.$scope.baseUser, 'profile.name.first'),
      get(this.$scope.baseUser, 'profile.name.last'),
      get(this.$scope.baseUser, 'profile.email'),
      get(this.$scope.baseUser, 'profile.city'),

      this.$scope.email && this.$scope.email.isValid,
    ];
    return every(requiredFields);
  }
  getEmail () {
    return this.$scope.email;
  }
}
export const CreateBaseUserComponent = {
  controller: CreateBaseUser,
  template: require('./create-base-user.component.jade'),
  bindings: {
      close: '<'
   }
};
