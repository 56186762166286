/**
 *                                                      Aydin "/dev/mach" Ulfer
 * A directive to enable/disable an element or change its content depending on
 * a `promise`. At bookalokal, a classic use case would be form submit buttons.
 *
 *  promise               function
 *    A function which run when a directive clicked. It must return a promise
 *
 *  disable-in-progress   [true|false]
 *    Determines if the element will be disables untill promise resolved or not
 *    by default it's "true"
 *
 *
 *  on-success            function
 *    Function to run if promise succeed
 *
 *  on-error              function
 *    Function to rin if promise fails
 *
 *
 *  *BTN_STATE* vairable available to use within the scope of the directive and
 *  it's exposed to the template.
 *
 *
 *  Sample:
 *
 *
 *   Controller:
 *
 *  ...
 *    $scope.promise = function() {
 *      var defer = $q.defer();
 *
 *      $timeout(function() {
 *        defer.resolve();
 *        // defer.reject();
 *      }, 3000);
 *      return  defer.promise;
 *    };
 *  ...
 *
 *   Template:
 *
 *   <button promise-button="true" promise="promise()">
 *    <i class="fa fa-bolt"></i>
 *    <span ng-show="BTN_STATE === 'INIT'"> Go head! </span>
 *    <span ng-show="BTN_STATE === 'PROGRESS'"> Sending... </span>
 *    <span ng-show="BTN_STATE === 'SUCCESS'"> Yay! </span>
 *    <span ng-show="BTN_STATE === 'FAILED'"> Oh noes! </span>
 *  </button>
 *
 */

module.exports = function() {
    return {
      restrict: 'A',
      transclude: true,
      scope: {
        promise: '&',
        onSuccess:'&',
        onError: '&',
        disableInProgress: '='
      },
      link: function(scope, iElement, iAttrs, controller, transcludeFn) {

        scope.disableInProgress = scope.disableInProgress || true;

        scope.BTN_STATE = 'INIT';
        iElement.on('click', function() {
          scope.$apply(function(){
            scope.BTN_STATE = 'PROGRESS';
            if (scope.disableInProgress) {
              iElement.attr('disabled', true);
            }

            scope
              .promise()
              .then(function(){
                scope.BTN_STATE = 'SUCCESS';
                if (scope.disableInProgress) {
                  iElement.attr('disabled', false);
                }

                if (scope.onSuccess) {
                  scope.onSuccess();
                }
              })
              .catch(function() {
                scope.BTN_STATE = 'FAILED';
                if (scope.disableInProgress) {
                  iElement.attr('disabled', false);
                }

                if(scope.onError) {
                  scope.onError();
                }
              });
          });
        });
        // See http://angular-tips.com/blog/2014/03/transclusion-and-scopes/
        transcludeFn(scope, function(element, scope){
          iElement.append(element);
        });
      }
    };
  }