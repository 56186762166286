import pluralize from 'pluralize';
import ENUMS from 'common/dist/enums';

import match from '../../../utils/match';
import { get } from 'lodash';

let ctrl;
class VenueSearchCountsComponent {
  constructor() {
    'ngInject';
    ctrl = this;
  }

  getDisplayLocation = (location) => {
    const searchedLoc = ENUMS.acceptableUserCities.find(city => city.value === location);
    return get(searchedLoc, 'name') || location;
  }

  pluralize(val) {
    return pluralize(val);
  }
}

export default {
  template: require('./venue-search-counts.jade'),
  bindToController: true,
  bindings: {
    search: '<'
  },
  controller: VenueSearchCountsComponent
};
