export const ANALYTICS_EVENTS = {
  auth: {
    clicked: {
      login: 'Login :: Clicked login'
    },
    loggedIn: 'Login :: Logged in with email',
    loggedInFacebook: 'Login :: Logged in with Facebook',
    loggedInLinkedin: 'Login :: Logged in with LinkedIn',
    registered: 'Register :: Signed up with email',
    registeredFacebook: 'Register :: Signed up with Facebook',
    registeredLinkedin: 'Register :: Signed up with Linkedin'
  },
  concierge: {
    submittedPrivateEventLeadForm: 'Concierge :: Submitted private event lead form'
  },
  payment: {
    paidDepositWithNewCard: 'Payment :: Guest paid event deposit with new card',
    paidDepositWithStoredCard: 'Payment :: Guest paid event deposit with stored card',
    addedNewCreditCard: 'Payment :: Guest added new credit card on user account page',
    deletedCreditCard: 'Payment :: Guest deleted credit card'
  },
  requests: {
    guestAcceptedAlterations: 'Requests :: Guest accepted alterations',
    guestAcceptedRequest: 'Requests :: Guest accepted booking request',
    guestDeclinedRequest: 'Requests :: Guest declined booking request',
    hostCancelledRequest: 'Requests :: Host cancelled request',
    hostCounteroffered: 'Requests :: Host made counteroffer',
    hostMadePostDepositAlteration: 'Requests :: Host made post-deposit alteration',
    hostMadePreDepositAlteration: 'Requests :: Host made pre-deposit alteration'
  },
  recoRequest: {
    openedFromReco: 'Reco Request :: Opened From Reco',
    openedFromClientAddVenue: 'Reco Request :: Opened From Client Add Venue',
    openedFromClientAddLiteVenue: 'Reco Request :: Opened From Client Add Lite Venue',
    openedFromVenueView: 'Reco Request :: Opened From Venue View',
    openedFromLiteVenueView: 'Reco Request :: Opened From Lite Venue View',
    openedFromInquiry: 'Reco Request :: Opened From Inquiry',
    selectedType: 'Venue Book :: Selected event type',
    selectedSpace: 'Reco Request :: Selected space',
    selectedLiteSpace: 'Reco Request :: Selected Lite space',
    selectedMenu: 'Reco Request :: Selected menu',
    skippedMenuSelection: 'Reco Request :: Skipped menu selection',
    selectedDrink: 'Reco Request :: Selected drink package',
    skippedDrinkSelection: 'Reco Request :: Skipped drink selection',
    selectedAddOns: 'Reco Request :: Selected addons',
    skippedAddOnSelection: 'Reco Request :: Skipped addon selection',
    submittedRequest: 'Reco Request :: Submitted booking request'
  },
  venueDashboard: {
    savedDrink: 'Venue Dashboard :: Saved drink data',
    savedMenu: 'Venue Dashboard :: Saved menu data',
    savedSpace: 'Venue Dashboard :: Saved space data',
    savedVenue: 'Venue Dashboard :: Saved venue data',
    submittedVenueForReview: 'Venue Dashboard :: Submitted venue for review'
  },
  venueView: {
    checkedAvailability: 'Venue View :: Checked availability'
  },
  Virtual: {
    search: 'Visited Virtual Experience search Page'
  },
  list: {
    createNewList: 'Lists :: new list created'
  },
  /**
   * Visited routes handled by specific controllers
   *
   * Search Page /search
   * Venue Book /venue/:slug/book
   * Venue View /venue/:slug
  */
  visited: {
    '/': 'Visited Landing page',
    '/about': 'Visited About page',
    '/faq': 'Visited FAQ page',
    '/get-started': 'Visited Get Started page',
    '/client/requests': 'Visited their Requests page',
    '/venue/requests': 'Visited their Requests page',
    '/privacy': 'Visited Privacy page',
    '/reset-password': 'Visited Reset Password page',
    '/terms': 'Visited Terms of Use page',
    '/user/account': 'Visited their Account page',
    '/user/scheduler': 'Visited their Scheduler page',
    '/user/venues': 'Visited their Venue Dashboard page',
    '/user/venues/new': 'Venue Dashboard :: Created new venue',
    custom: {
      concierge: 'Visited Private Concierge page',
      venueView: 'Visited Venue page',
      search: 'Visited Search page',
      venueBook: 'Visited Venue Book page'
    }
  }
};
