import { get, findIndex } from 'lodash';
import { DBookingRequest } from 'spc/lib/database/types/booking-request';
import { ApiService } from 'spc/shared/api/api.service';
import { RawUser } from 'spc/lib/database/types/user';
import { convertToDollarsAndRound, fullPriceBreakdownCents } from 'common/dist/price';

interface Payment {
    type: string;
    details: any;
    token?: string;
    nonce?: string;
    createPaymentMethod?: any;
    cardParams?: any;
  }


class AdminPaymentController {
    close: (request) => void;
    loading: boolean;
    request: DBookingRequest;
    paymentMethods: any[];
    conversation: string;
    guest: RawUser;
    amountCents: number;
    cards: any[];
    selectedCard;
    createPaymentMethod: boolean;
    paymentState: string;
    formContainer;
    isValid: boolean = true;
    cardParams: {
        cardholderName?: string;
    };

    constructor(
        private $api: ApiService,
        private $braintree,
        private braintreeHelpers,
        private unwrapError,
        private ENUMS,
        private $timeout
    ) {
        'ngInject';
    }

    $onInit = () => {
        this.getPaymentMethods();
        this.validateAmount(0);
    }

    getPaymentMethods = () => {
        this.loading = true;

        this.$api.Payment.initialize({ getMethods: true, conversation: this.conversation }).
          then(this.initData).
          then(() => this.loading = false).
          catch(this.unwrapError);
    }

    initData = (res) => {
        const hostedFields = this.braintreeHelpers.generateHostedFieldsOptions(this);

        const extraMethods = {
            onPaymentMethodReceived: this._submit,
            onError: this.braintreeHelpers.onError(this)
        };

        this.$timeout(() => {
            this.$braintree.init(
            this.formContainer = document.getElementById('payment-form'),
            get(res, 'data.token'),
            hostedFields,
            extraMethods
            );
        });

        this.guest = get(res, 'data.guest');
        this.cards = this.ENUMS.payment.cards;
        this.cardParams = {};

        const paymentMethods = get(res, 'data.paymentMethods', []);
        this.paymentMethods = paymentMethods;

        const indexOfDefault = findIndex(paymentMethods, function(method: { default: any }) {
            return method.default;
        });

        if (indexOfDefault > -1) {
            this.selectedCard = paymentMethods[indexOfDefault];
        } else {
            this.createPaymentMethod = true;
        }
        this.paymentState = 'READY';
    }

    addNewCard = () => {
        this.selectedCard = null;
        this.createPaymentMethod = true;
        this.paymentState = 'READY';
    }

    hideNewCardForm(method) {
        this.createPaymentMethod = false;
        this.selectedCard = method;
    }

    submitSelectedCard = () => {
        this._submit({ token: this.selectedCard.token });
    }

    disableButton () {
        this.paymentState = 'LOADING';
    }

    toggleCreatePaymentMethod = (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.createPaymentMethod = !this.createPaymentMethod;
    }

    validateAmount(dollars) {
        const balanceOutstanding = fullPriceBreakdownCents(this.request).balanceOutstanding;

        this.isValid = parseInt(convertToDollarsAndRound(balanceOutstanding)) > parseInt(dollars);
        return;
    }

    setPaymentCents(dollars) {
        this.validateAmount(dollars);
        this.amountCents = isFinite(dollars) ? parseFloat(dollars) * 100 : null;
    }

    canSubmitForm = () => {
        return this.paymentState === 'READY' && this.amountCents;
    }

    _submit = (payload) => {
        const payment: Payment = {
            type: payload.type,
            details: payload.details,
        };

        if (payload.token) {
            payment.token = payload.token;
        } else if (payload.nonce) {
            payment.nonce = payload.nonce;
            payment.createPaymentMethod = this.createPaymentMethod;
            payment.cardParams = this.cardParams;
        }

        this.$api.Payment.intermediate({ payment, request: this.request, amountCents: this.amountCents })
            .then(res => this.close(res.request))
            .catch(this.unwrapError);
    }
}

export const adminPaymentComponent = {
    controller: AdminPaymentController,
    template: require('./admin-payment.component.jade'),
    bindings: {
        request: '<',
        conversation: '<',
        close: '<'
    }
};
