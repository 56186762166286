import { get, every, filter, cloneDeep, debounce, isEqual } from 'lodash';
import { RawVendor, ShippingDetails } from 'spc/lib/database/types/virtual-events';
import * as CONSTANTS from '../../../../../database/constants/Experience';
import { ApiService } from '../../../shared/api/api.service';
const newShippingDetail: ShippingDetails = {
  description: '',
  isRequired: false,
  price: {
    priceType: 'per person',
    priceValue: null,
  },
};
class ShippingDetailsController {
  currentShippingDetailIndex: number;
  isFormDisplayed: boolean = false;
  shippingDetail: ShippingDetails = cloneDeep(newShippingDetail);
  shipmentDetails: ShippingDetails[] = [];
  isRequiredOptions: any = [
    {
      name: 'Optional',
      value: false,
    },
    {
      name: 'Required',
      value: true,
    },
  ];
  validate: any;
  vendor: RawVendor;
  errors: {
    [key: string]: {
      message: string
    }
  } | null = {};
  isModeEdit: boolean = false;
  object: any;
  type: string;
  priceTypes = CONSTANTS.EXPERIENCE_SHIPPING_PRICE;
  isShipmentEdit: boolean = false;

  constructor(private unwrapError, private $api: ApiService) {
    'ngInject';
  }

  $onInit = () => {
    if (this.object) {
      if (this.object.shipmentDetails && this.object.shipmentDetails.length) {
        this.shipmentDetails = cloneDeep(this.object.shipmentDetails);
      }
    }
  }

  validateShippingDetail = (path) => {
    const currentItemPath = `shipmentDetails.${this.currentShippingDetailIndex}.${path}`;
    this.$api.Admin.Experience.validate({
      experience: { shipmentDetails: this.shipmentDetails },
      vendorId: this.vendor['_id'],
    })
      .then((response) => {
        const errors = get(response, 'errors.experience');
        if (errors) {
          const pathErrors = get(errors, currentItemPath);
          this.errors[currentItemPath] = pathErrors ? pathErrors.message : null;
        } else {
          this.errors = {};
        }
      })
      .catch((error) => {
        this.unwrapError(error);
      });
  }

  checkForErrors = debounce(this.validateShippingDetail, 300);

  createShippingDetail = () => {
    this.displayForm(true);
    const length = this.shipmentDetails.push(this.shippingDetail);
    this.currentShippingDetailIndex = length - 1;
  }

  resetShippingDetail = () => {
    this.shippingDetail = cloneDeep(newShippingDetail);
  }

  displayForm(value) {
    this.isFormDisplayed = value;
  }

  cancel() {
    if (!this.isModeEdit) {
      this.shipmentDetails = filter(
        this.shipmentDetails,
        (_, index) => index !== this.currentShippingDetailIndex
      );
    } else {
      this.shipmentDetails[this.currentShippingDetailIndex] = JSON.parse(
        JSON.stringify(
          this.object.shipmentDetails[this.currentShippingDetailIndex]
        )
      );
      this.isModeEdit = false;
    }
    this.resetShippingDetail();
    this.displayForm(false);
  }

  isFieldInvalid = (path) => {
    const fieldPath = `shipmentDetails.${this.currentShippingDetailIndex}.${path}`;
    return this.errors[fieldPath] ? true : false;
  }

  getErrorMessage = (path) => {
    const fieldPath = `shipmentDetails.${this.currentShippingDetailIndex}.${path}`;
    return this.errors[fieldPath];
  }

  submit = () => {
    this.updateExperience();
  }

  updateUi = () => {
    this.object.shipmentDetails = cloneDeep(this.shipmentDetails);
    this.displayForm(false);
    this.resetShippingDetail();
    this.isShipmentEdit = false;
    this.validate('shipmentDetails');
  }

  updateExperience = () => {
    const updates = { shipmentDetails: this.shipmentDetails };
    this.$api.Admin.Experience.updateExperienceById({
      updates,
      experienceId: this.object['id'],
    })
      .then((response) => {
        this.shipmentDetails = response.experience.shipmentDetails;
        this.object.status = response.experience.status;
        this.updateUi();
      })
      .catch((err) => {
        this.unwrapError(err);
      });
  }

  checkIfFormDataInvalid = () => {
    const errorsPath = Object.keys(this.errors);
    let result = null;
    errorsPath.forEach((path) => {
      result = this.errors[path] || result;
    });
    return result;
  }

  hasAllTheRequiredFields = () => {
    const requiredFields = [get(this.shippingDetail, 'description')];
    return every(requiredFields);
  }

  edit = (index) => {
    this.shippingDetail = this.shipmentDetails[index];
    this.displayForm(true);
    this.currentShippingDetailIndex = index;
    this.isModeEdit = true;
  }

  delete = (itemIndex) => {
    const shipmentDetailToDelete = this.shipmentDetails[itemIndex];
    if (isEqual(this.shippingDetail, shipmentDetailToDelete)) {
      this.shippingDetail = cloneDeep(newShippingDetail);
      this.displayForm(false);
    }
    this.shipmentDetails = filter(
      this.shipmentDetails,
      (_, index) => index !== itemIndex
    );
    this.updateExperience();
  }
}

export const VirtualEventsShippingDetailsComponent = {
  controller: ShippingDetailsController,
  template: require('./virtual-events-shippingDetails.component.jade'),
  bindings: {
    validate: '<',
    vendor: '<',
    object: '<',
    type: '<',
  },
};
