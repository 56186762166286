/**
 * Generic button for sending HTTP requests.
 *
 * @property {Function} submit function to call to get back a promise
 * @property {Function} [disabled] function to call to determine if button is disabled
 * @property {String} [readyMessage] string to display when ready to submit
 * @property {String} [loadingMessage] string to display when submitting
 * @property {String} [errorMessage] string to display when an error occurred
 * @property {String} [successMessage] string to display when request succeeded
 * @fires HTTP_BUTTON_SUCCESS fired when succeeded
 * @fires HTTP_BUTTON_ERROR fired when promise errored.
 */

module.exports = function() {
  return {
    scope: {
      submit: '&',
      disabled: '&'
    },
    template: require('./http-button.jade'),
    restrict: 'E',
    link: function(scope, element, attrs) {
      scope.readyMessage = attrs.readyMessage || 'Submit';
      scope.loadingMessage = attrs.loadingMessage || 'Loading...';
      scope.errorMessage = attrs.errorMessage || 'Oops an Error Occurred';
      scope.successMessage = attrs.successMessage;
    },
    controller: ['$scope', function($scope) {
      $scope.state = 'READY';
      $scope._submit = function() {
        $scope.state = 'LOADING';
        var promise = $scope.submit();
        if (!promise) {
          $scope.state = 'READY';
          return;
        }

        promise.
          then(function(res) {
            $scope.state = 'READY';
            $scope.$emit('HTTP_BUTTON_SUCCESS', res);
          }).
          catch(function(error) {
            $scope.state = 'READY';
            $scope.$emit('HTTP_BUTTON_ERROR', error);
          });
      };

      $scope._disabled = function() {
        return $scope.state === 'LOADING' ||
          ($scope.disabled && $scope.disabled());
      };
    }]
  }
};
