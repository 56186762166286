// NPM Dependencies
import { last, includes, get, isFinite, set, findIndex, debounce } from 'lodash';

import { DVenue } from 'spc/lib/database/types/venue/venue';
import { ApiService } from 'spc/shared/api/api.service';
// constant
import { ANALYTICS_EVENTS } from '../../constants/ENUMS/analyticsEvents';
import { LITE_SPACE_STATUSES } from '../../../../database/constants/LiteSpaceStatuses';

export default ['$scope', '$user', '$rootScope', '$timeout', '$api',
  '$location', '$locationSpy', 'ngDialog', 'ENUMS', '$cloudinary', '$injector', 'unwrapError', '$routeParams', 'DocFactory', function ($scope, $user, $rootScope, $timeout, $api,
  $location, $locationSpy, ngDialog, ENUMS, $cloudinary, $injector, unwrapError, $routeParams, DocFactory) {
  $scope.ENUMS = ENUMS;
  $scope.$cloudinary = $cloudinary;
  $scope.$routeParams = $routeParams;
  $scope.venueStatus = $routeParams.status;
  init();

  $scope.validate = validate;
  $scope.addOnIndexByName = addOnIndexByName;
  $scope.addTimeSlot = addTimeSlot;
  $scope.amenityIndexByName = amenityIndexByName;
  $scope.cancelEdits = cancelEdits;
  $scope.checkEndTime = checkEndTime;
  $scope.customAddOnIndexByName = customAddOnIndexByName;
  $scope.customAmenityIndexByName = customAmenityIndexByName;
  $scope.findOverlappingSlots = findOverlappingSlots;
  $scope.isFinite = isFinite;
  $scope.isInvalidEndTime = isInvalidEndTime;
  $scope.last = last;
  $scope.openPhotos = openPhotos;
  $scope.removeTimeSlot = removeTimeSlot;
  $scope.saveDoc = saveDoc;
  $scope.setAddOnUIModel = setAddOnUIModel;
  $scope.setCustomAddOnUIModel = setCustomAddOnUIModel;
  $scope.setEndTime = setEndTime;
  $scope.setRoomFeeCents = DocFactory.Spaces.setRoomFeeCents;
  $scope.toggleAddOn = toggleAddOn;
  $scope.toggleAmenity = toggleAmenity;
  $scope.toggleCustomAddOn = toggleCustomAddOn;
  $scope.toggleSeatingStyle = toggleSeatingStyle;
  $scope.toggleSlotDay = toggleSlotDay;
  $scope.toggleSelectCapacity = toggleSelectCapacity;
  $scope.errors = {};
  $scope.debouncedValidate = debounce(path => validate(path), 300);
  $scope.validateDescription = validateDescription;
  $scope.setFeeType = setFeeType;
  $scope.setPrice = setPrice;
  $scope.toggleShowAllFields = toggleShowAllFields;
  $scope.isLite = $location.search().isActive === 'true' ? true : false;

  ///// Functions

  /**
   * Initialization logic
   */
  function init() {
    $scope.slotOverlaps = {};
    $scope.uiModel = { addOns: {}, customAddOns: {} };
    $scope.isAdmin = $user.$ && includes($user.$.roles, 'Admin');
    $scope.doc = $scope.Record;
    $locationSpy.register($scope.doc);

    $rootScope.$emit('$viewReady', 'HIDE_FOOTER');
  }

  function isInvalidEndTime(idx, time) {
    const startTime = get($scope.doc, `data.timeSlots.${idx}.from`);
    if (isFinite(startTime)) {
      const endTime = time >= 0 && time <= 400 ? time + 2400 : time;
      return isFinite(endTime) && endTime <= startTime;
    }
    return false;
  }

  function setEndTime(idx, time) {
    const invalid = $scope.isInvalidEndTime(idx, time);
    if (invalid) {
      return;
    }


    set($scope.doc, `data.timeSlots.${idx}.to`, time);
  }

  function checkEndTime(idx) {
    const endTime = get($scope.doc, `data.timeSlots.${idx}.to`);
    if (isFinite(endTime)) {
      const invalid = $scope.isInvalidEndTime(idx, endTime);
      if (invalid) {
        set($scope.doc, `data.timeSlots.${idx}.to`, null);
      }
    }
  }

  function amenityIndexByName(amenity) {
    return $scope.doc.data.amenities.indexOf(amenity);
  }

  function setFeeType(addOn) {
    $scope.doc.data.addOns[addOnIndexByName(addOn)].priceType = $scope.uiModel.addOns[addOn].priceType;

    if ($scope.uiModel.addOns[addOn].priceType === 'FREE') {
      delete $scope.doc.data.addOns[addOnIndexByName(addOn)].price;
      delete $scope.doc.data.addOns[addOnIndexByName(addOn)].priceInCents;
    }
  }

  function setPrice(addOn) {
    const price = $scope.uiModel.addOns[addOn].price;
    $scope.doc.data.addOns[addOnIndexByName(addOn)].price = price;
    $scope.doc.data.addOns[addOnIndexByName(addOn)].priceInCents = +price * 100;
  }

  function toggleAmenity(item) {
    const i = $scope.amenityIndexByName(item);
    if (i !== -1) {
      $scope.doc.data.amenities.splice(i, 1);
    } else {
      $scope.doc.data.amenities.push(item);
    }
  }

  function customAmenityIndexByName(amenity) {
    return $scope.doc.data.custom.amenities.indexOf(amenity);
  }

  function toggleShowAllFields() {
    return $scope.isLite = !$scope.isLite;
  }


  function toggleCustomAmenity(item) {
    const i = $scope.customAmenityIndexByName(item);
    if (i !== -1) {
      $scope.doc.data.custom.amenities.splice(i, 1);
    } else {
      item = (`${item}`).trim;
      item = item.length ?
        item :
        `New Custom Amenity # ${$scope.doc.data.custom.amenities.length + 1}`;
      $scope.doc.data.custom.amenities.push(item);
    }
  }

  function customAddOnIndexByName(addOn) {
    return findIndex($scope.doc.data.custom.addOns, { item: addOn });
  }

  function setCustomAddOnUIModel(addOn) {
    const index = $scope.customAddOnIndexByName(addOn);
    $scope.uiModel.customAddOns[addOn] = index !== -1
      ? {
        isChecked: true,
        isCustom: true,
        item: $scope.doc.data.custom.addOns[index].item,
        price: $scope.doc.data.custom.addOns[index].price,
        priceType: $scope.doc.data.custom.addOns[index].priceType
      }
      : { isChecked: false };
  }

  function toggleCustomAddOn(addOn) {
    const index = $scope.customAddOnIndexByName(addOn);

    if (index === -1) {
      $scope.doc.data.custom.addOns = $scope.doc.data.custom.addOns || [];
      addOn = (`${addOn}`).trim;
      addOn = addOn.length ?
        addOn :
        `New Add-On # ${$scope.doc.data.custom.addOns.length + 1}`;
      $scope.doc.data.custom.addOns.push({ item: addOn, price: 0, isCustom: true });
    } else {
      $scope.doc.data.custom.addOns.splice(index, 1);
    }

    $scope.setCustomAddOnUIModel(addOn);
  }

  function setAddOnUIModel(addOn) {
    const index = $scope.addOnIndexByName(addOn);

    $scope.uiModel.addOns[addOn] = index !== -1
      ? {
        isChecked: true,
        item: $scope.doc.data.addOns[index].item,
        price: $scope.doc.data.addOns[index].price,
        priceType: $scope.doc.data.addOns[index].priceType
      }
      : { isChecked: false };
  }

  function addOnIndexByName(addOn) {
    return findIndex($scope.doc.data.addOns, { item: addOn });
  }

  function toggleAddOn(addOn) {
    const index = $scope.addOnIndexByName(addOn);

    if (index === -1) {
      $scope.doc.data.addOns = $scope.doc.data.addOns || [];
      $scope.doc.data.addOns.push({ item: addOn, price: 0 });
    } else {
      $scope.doc.data.addOns.splice(index, 1);
    }

    $scope.setAddOnUIModel(addOn);
  }

  function toggleSeatingStyle(type, item) {
    const i = get($scope, `doc.data.capacity.${type}.style`, []).indexOf(item);

    if (i !== -1) {
      $scope.doc.data.capacity[type].style.splice(i, 1);
    } else {
      if (!get($scope, `doc.data.capacity.${type}.style`)) {
        $scope.doc.data.capacity[type].style = [];
      }
      $scope.doc.data.capacity[type].style.push(item);
    }
  }

  function validateDescription(path) {
    if (get($scope, 'doc.data.description', []).length < 10) {
      return $scope.errors[path] = true;
    } else {
      return $scope.debouncedValidate(path);
    }
  }


  function validate(paths) {
    return $api.Venues.Spaces.validate($scope.doc)
      .then((res) => {
        const pathList = Array.isArray(paths) ? paths : [paths];
        const errors = get(res, 'data.errors', {});
        pathList.forEach((path) => {
          if (errors[path]) {
            $scope.errors[path] = errors[path];
          } else if ($scope.errors[path]) {
            $scope.errors[path] = false;
          }
        });
      })
      .catch(error => unwrapError(error));
  }
  /**
   * Save doc on backend and redirect to venue dashboard
   * @return {Promise}
   */

  function saveDoc({ isLite }: { isLite?: boolean }) {
    $scope.doc.isLite = isLite ? true : false;
    return $api.Venues.Spaces.
      save($routeParams.slug, $scope.doc).
      then(function (data) {
        $locationSpy.unregister();

        return $api.Venues.get($routeParams.slug, { menus: true, drinks: true });
      }).
      then(function (data) {
        const theVenue = get(data, 'data.data.venue') as DVenue;
        const $analytics = $injector.get('$analytics');
        const eventName = ANALYTICS_EVENTS.venueDashboard.savedSpace;
        const eventParams = new $analytics.$Space($scope.doc, theVenue);
        $analytics.$trackEvent(eventName, eventParams);

        const url = `/user/venues?venue=${theVenue.slug}&isActive=${$scope.isLite}`;
        $location.url(url);
      }).catch((error) => {
        $scope.errors = get(error, 'data.error');
        unwrapError(error);
      });
  }

  function cancelEdits(url) {
    $locationSpy.unregister();
    $location.url(url);
  }

  function openPhotos() {
    ngDialog.
      open({
        scope: $scope,
        template: require('./photo-modal.jade'),
        className: 'ngdialog-theme-plain',
        overlay: false,
        plain: true,
        controller: 'UserSpacesPhotos',
        resolve: {
          Record: function () {
            return { data: { data: $scope.doc } };
          },
          //  $api is a DI, not a shadowed variable
          // tslint:disable-next-line: no-shadowed-variable
          Model: ['$api', function ($api: ApiService) {
            return $api.Venues.Spaces;
          }]
        }
      }).
      closePromise.
      then(function () {
        return $api.Venues.Spaces.get($routeParams.slug, $routeParams.spaceId);
      }).
      then(function (data) {
        $scope.doc.data.photos = data.data.data.data.photos;
        $scope.doc.data.coverIndex = data.data.data.data.coverIndex;
      }).
      catch(unwrapError);
  }

  function addTimeSlot() {
    $scope.doc.data.timeSlots.push({});
  }

  function removeTimeSlot(idx) {
    $scope.doc.data.timeSlots.splice(idx, 1);
    $scope.findOverlappingSlots();
  }

  function toggleSlotDay(slot, day) {
    $scope.doc.data.timeSlots[slot].days[day] = !$scope.doc.data.timeSlots[slot].days[day];
    $scope.validate('data.timeSlots.' + slot + '.days');
    $scope.findOverlappingSlots();
  }

  function findOverlappingSlots() {
    // For more fun, read
    //
    //  http://stackoverflow.com/questions/13513932/algorithm%C2%ADto%C2%ADdetect%C2%ADoverlapping%C2%ADperiods
    //  https://en.wikipedia.org/wiki/Allen%27s_interval_algebra
    //  https://code.google.com/p/allenintervalrelationships/
    //
    // - Aydin
    const overlaps = {};
    const areSlotsOverlapping = function (a, b) {
      let onSameDay = false;

      for (let i = 0; i < 7; ++i) {
        if (a.days[i] && a.days[i] === b.days[i]) {
          onSameDay = true;
          break;
        }
      }
      if (!onSameDay) {
        return false;
      }

      const endOfFirstSlot = a.to < a.from ? a.to + 2400 : a.to;
      const endOfSecondSlot = b.to < b.from ? b.to + 2400 : b.to;
      return a.from < endOfSecondSlot && b.from < endOfFirstSlot;
    };

    $scope.errorState['doc.data.timeSlots'] = $scope.errorState['doc.data.timeSlots'] || {};
    if (Object.keys($scope.slotOverlaps).length) {
      $scope.errorState['doc.data.timeSlots'].error = null;
      --$scope.errorState.$count;
    }

    for (let i = 0; i < $scope.doc.data.timeSlots.length; ++i) {
      const slot1 = $scope.doc.data.timeSlots[i];
      if (!slot1.from || !slot1.to) {
        continue;
      }
      for (let j = 0; j < $scope.doc.data.timeSlots.length; ++j) {
        if (i === j) {
          continue;
        }
        const slot2 = $scope.doc.data.timeSlots[j];
        if (!slot2.from || !slot2.to) {
          continue;
        }
        if (areSlotsOverlapping(slot1, slot2)) {
          overlaps[i] = overlaps[i] || [];
          overlaps[i].push(j);
        }
      }
    }

    $scope.slotOverlaps = overlaps;

    if (Object.keys($scope.slotOverlaps).length) {
      $scope.errorState['doc.data.timeSlots'].error = 'Time Slots Overlap';
      ++$scope.errorState.$count;
    }

    return $scope.slotOverlaps;
  }

  function toggleSelectCapacity(capacity: 'seated' | 'standing') {
    const path = `data.capacity.${capacity}`;
    const fields = ['style', 'min', 'max'];

    if (!$scope.doc[`${path}.selected`]) {
      fields.forEach(field => set($scope.doc, `${path}.${field}`, field === 'style' ? [] : null));
    }

  }
}];
