import * as angular from 'angular';
import { topLevelReceiptsComponent } from './top-level-receipts.component';
import { allReceiptsComponent } from './receipts.component';
import { refundReceiptComponent } from './refund-receipt.component';

export default angular
  .module('receipts', [])
  .component('topLevelReceipts', topLevelReceiptsComponent)
  .component('allReceipts', allReceiptsComponent)
  .component('refundReceipt', refundReceiptComponent)
  .name;
