export default {
  'data.drinks': 'the drink packages',
  'data.menu': 'the menu',
  'data.date': 'the event date',
  'data.time': 'the event time',
  'data.eventType': 'the event type',
  'host.foodBeverageMinCents': 'the food and beverage minimum',
  'data.groupSize': 'the number of guests',
  'data.space': 'the event space',
  'data.addOns': 'the event add-ons',
  'data.allergies': 'the list of allergies',
  'data.customMessage': 'the menu\'s customized message',
  'data.finalSelections': 'the final selections',
  'venue.data.spaces': 'the room setup',
  'data.eventFormat': 'the event schedule',
  'host.notes': 'the additional notes section'
};
