import { ApiService } from '../../shared/api/api.service';
import { RawVendor } from '../../../../database/types/virtual-events';


class AdminVendorsDashboardController {
  ready: () => void;
  ui: any = {
    loading : false
  };
  vendors: RawVendor[];
  page: number  = 0;
  numVendors: number = 0;
  documentPerPage: number = 50;
  currentPage: number  = 0;
  constructor(private $api: ApiService, private $clickOk, private unwrapError ) {
    'ngInject';
  }

  $onInit = () => {
    this.getVendors();
  }

  getVendors = (page = 0) => {
    this.ui.loading = true;
    return this.$api.Admin.Vendors.getAll({ page : page, documentsPerPage: this.documentPerPage })
      .then((response) => {
        this.vendors = response.vendors;
        this.ui.loading = false;
        this.numVendors = response.numVendors;
        this.currentPage = page + 1;
        this.ready();
      })
      .catch((error) => {
        this.ui.loading = false;
        this.unwrapError(error);
        this.ready();
      });
  }

  delete = (vendorId) => {
    this.$api.Admin.Vendors.deleteVendorById({ vendorId })
    .then((res) => {
      this.getVendors();
    })
    .catch((error) => {
      this.unwrapError(error);
    });
  }
}

export const adminVendorsDashboardComponent = {
  template: require('./admin-vendors-dashboard.component.jade'),
  controller: AdminVendorsDashboardController,
  bindings: {
    ready: '<'
  }
};
