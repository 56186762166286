import * as angular from 'angular';
import { DayViewComponent } from './day-view.component';
import { horizontalSlot } from './horizontal-slot.directive';
import { blockTimeModal } from './block-time-modal.component';
import { setFbMinModal } from './set-fbmin-modal.component';
import { DayViewService } from './day-view.service';

export const DayViewModule = angular.module('DayViewModule', [ ])
  .component('dayView', DayViewComponent)
  .directive('horizontalSlot', horizontalSlot)
  .service('blockTimeModal', blockTimeModal)
  .service('setFbMinModal', setFbMinModal)
  .service('dayViewService', DayViewService)
  .name;
