import * as angular from 'angular';

import { MonthViewComponent } from './month-view.component';
import { MonthViewService } from './month-view.service';
import { DayEventsComponent } from './day-events.component';
import { MonthViewSidebarComponent } from './month-view-sidebar.component';


export const MonthViewModule = angular.module('MonthViewModule', [ ])
  .component('monthView', MonthViewComponent)
  .component('dayEvents', DayEventsComponent)
  .component('monthViewSidebar', MonthViewSidebarComponent)
  .service('monthViewService', MonthViewService)
  .name;
