// NPM Dependencies
import get from 'lodash/get';
import set from 'lodash/set';
import groupBy from 'lodash/groupBy';
import findIndex from 'lodash/findIndex';
// Sixplus Dependencies
import availabilityCalendarHelpers from 'common/dist/virtuals/AvailabilityCalendar';
import { getEndTimeFromStartTimeAndDuration } from 'common/dist/time';
/**
 * takes a venue or a request and shows the
 * availability and capacities of its spaces
 */
export function spaceAvailabilityBreakdown() {
  return {
    scope: {
      request: '<',
      calendars: '<',
      times: '<',
      spaces: '<'
    },
    template: require('./space-availability-breakdown.jade'),
    controller: ['$scope', 'AvailabilityFactory', '$clickOk', '$attrs', '$cloudinary', '$timeout', 'unwrapError', function($scope, AvailabilityFactory, $clickOk, $attrs, $cloudinary, $timeout, unwrapError) {
      $scope.$cloudinary = $cloudinary;
      $scope.isAvailable = isAvailable;
      $scope.selectSpace = selectSpace;
      $scope.displayAvailable = displayAvailable;
      $scope.remindedOfOverride = false;
      let calendarMap = {};
      init();


      ///// Listeners

      ///// Functions

      /**
       * Initializes directive with a venue
       */
      function init() {
        $scope.request = $scope.request.toObject ? $scope.request.toObject() : $scope.request;
        $scope.hideCapacity = $attrs.hideCapacity;
        $scope.hideAvailability = $attrs.hideAvailability;
        calendarMap = groupBy($scope.calendars, (calendar: any) => calendar.space.toString());
        $scope.getCalendar = getCalendar;
      }

      /**
       Check availability map to see if space is available given
       * a space, date, time, groupsize, and duration
       *
       * @param {Space} space
       * @return {Object}
       */
      function isAvailable(space) {
        if (!space) {
          return;
        }
        const calendar = calendarMap[space._id.toString()][0];

        if (!calendar) {
          return;
        }
        const startTime = $scope.request.data.time;
        const duration = $scope.request.data.duration;
        const endTime = getEndTimeFromStartTimeAndDuration($scope.request.data.time, duration);
        return availabilityCalendarHelpers.isAvailable({
          calendar,
          date: $scope.request.data.date,
          times: {
            startTime,
            endTime
          }
        });
      }

      function displayAvailable(space) {
        return isAvailable(space) && AvailabilityFactory.matchesTimeslot($scope.request, space);
      }

      function getCalendar (space) {
        if (!space) {
          return;
        }
        return calendarMap[space._id.toString()][0];
      }
      /**
       * Emits an event on click to select a space
       *
       * @param {Space} space
       * @fires `SELECTED_SPACE`
       */
      function selectSpace(space) {
        if (!space || $attrs.hideAvailability) {
          return;
        }
        const id = space._id.toString();
        const selectedSpaceId = $scope.request.selectedSpace ? $scope.request.selectedSpace._id.toString() : null;

        if (id !== selectedSpaceId) {
          $scope.request.data.space = 0;
          $scope.request.selectedSpace = space;

          $scope.request.venue = $scope.request.venue.toObject ? $scope.request.venue.toObject() : $scope.request.venue;
          $scope.request.venue.data.spaces = [space];

          // find timeslot
          const slot = AvailabilityFactory.findSlotInSpace({ space, time: $scope.request.data.time, duration: $scope.request.data.duration });
          // update fbmin
          if (slot) {
            $scope.request.host.foodBeverageMinCents = slot.terms && isFinite(slot.terms.foodBeverageMin) ? slot.terms.foodBeverageMin * 100 : undefined;

            $scope.request.host.roomFeeCents = slot.terms.roomFeeCents;
          } else {
            $scope.request.host.foodBeverageMinCents = undefined;
            $scope.request.host.roomFeeCents = undefined;
          }
        }


      }

    }]
  };
}
