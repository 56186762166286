
// Our Dependencies
import { ApiService } from 'spc/shared/api/api.service';
import { IContact } from 'spc/shared/contact.constant';
import { SpPardotService } from 'spc/shared/sp-pardot/sp-pardot.service';
import get from 'lodash/get';

export class StartConversationController {
  message: string;
  userDetails: { profile?: { name: { first: string, last: string }, email: string } } = {};
  stateManager: { loading: boolean } = { loading: true };

  constructor(
    private venue,
    private userService,
    private $api: ApiService,
    private unwrapError,
    private $scope,
    private CONTACT: IContact,
    private $analytics,
    private spPardot: SpPardotService) {
    'ngInject';
  }

  $onInit = () => {
    this.userService.$waitFor('LOGGED_IN', () => this.stateManager.loading = false);
    this.userService.$waitFor('NOT_LOGGED_IN', () => this.stateManager.loading = false);
  }

  public isLoggedIn = () => {
    return this.userService.isLoggedIn();
  }

  public canSubmit = () => {
    const firstName = get(this.userDetails, 'profile.name.first');
    const lastName = get(this.userDetails, 'profile.name.last');
    const email = get(this.userDetails, 'profile.email');
    const message = this.message;

    if (this.isLoggedIn()) {
      return message && message.length;
    } else {
      return firstName && lastName && email && message && message.length;
    }
  }

  public submit = () => {
    const { venue, message, userDetails } = this;
    return this.$api.Conversations
      .startConversationWithVenue({ venue, data: { message }, user: userDetails })
      .then((data) => {
        this.$analytics.$trackEvent('VENUE VIEW :: Sent Inquiry Message', 'sent inquiry message to venue', 'Submit');
        this.$analytics.adwordsSentInquiry();
        this.$scope.closeThisDialog(this.userService.isLoggedIn() ? { conversationId: data.conversationId } : { unauthenticated: true });
        this.spPardot.trackUser({ user: userDetails, event: 'inquiry' });
      })
      .catch(error => this.unwrapError(error));
  }
}
