'use strict';

const _each = require('lodash/each');
const _get = require('lodash/get');
const _minBy = require('lodash/minBy');
const _maxBy = require('lodash/maxBy');

var schedulerEnums = {
  "STYLES": {
    "blocked": "blocked-slot",
    "booking": "booking-slot",
    "available": "available-slot",
    "timeslot": "time-slot",
    "default": "available-slot",
    "pending": "pending-slot"
  }
};

var m = {};

m.numericTimeToString = function(num) {
  return Math.floor(num / 100) + ':' + (num % 100);
};

m.timeToTicks = function(start, end, minutePerTick) {
  var diffMins = 0;
  var startMin = (start % 100);
  var endMin = (end % 100);
  minutePerTick = minutePerTick || 30;

  start = (start - startMin) / 100;
  end = (end - endMin) / 100;
  end = end < start ? end + 24 : end;

  diffMins = ((end - start) * 60);
  diffMins += (endMin - startMin);

  return Math.ceil(diffMins / minutePerTick);
};

m.addTime = function(time, minutes) {
  minutes += (time % 100);
  time = time - (time % 100);

  var mins = (minutes % 60);
  var hours = (minutes - mins) / 60;

  return time + (hours * 100) + mins;
};

m.subTime = function(time, minutes) {
  var sMinutes, sHours;
  sMinutes = minutes % 60;
  sHours = ((minutes - sMinutes) / 60);

  minutes = (time % 100);
  time = time - (time % 100);
  time -= (sHours * 100);

  minutes -= sMinutes;

  if (minutes > -1) {
    return time + minutes;
  } else {
    return (time - 100) + (60 + minutes)
  }
};

m.diffTime = function(t1, t2) {
  var t, t1min, t1hrs, t2min, t2hrs;

  t2 += (t1 > t2) ? (24 * 100) : 0;

  t1min = t1 % 100;
  t1hrs = ((t1 - t1min) / 100) * 60;
  t1 = t1hrs + t1min;

  t2min = t2 % 100;
  t2hrs = ((t2 - t2min) / 100) * 60;
  t2 = t2hrs + t2min;


  return t2 - t1;
};


m.getStyle = function(slot) {
  var styles = [];
  if (slot.data.isNA) {
    styles.push(schedulerEnums.STYLES.available);
  } else if (slot.data.state === 'booked') {
    styles.push(schedulerEnums.STYLES.booking);
  } else if (slot.data.state === 'pending') {
    styles.push(schedulerEnums.STYLES.pending);
  } else if (slot.data.state === 'blocked') {
    styles.push(schedulerEnums.STYLES.blocked);
  } else {
    styles.push(schedulerEnums.STYLES.timeslot);
  }
  return styles;
};

m.padEvents = function(from, to, minutesPerTick, slots) {

  // See
  // http://stackoverflow.com/questions/43044/algorithm-to-randomly-generate-an-aesthetically-pleasing-color-palette
  // http://blog.functionalfun.net/2008/07/random-pastel-colour-generator.html


  // Final result of events with start and end times
  var row = [];
  // Map wich contains the events ids to help build final events
  var map = [];
  // Available time slots
  var times = [];
  // Event entries whih linked in map
  var entries = [{ name: '', isNA: true }];

  for (var i = 0; i <= m.timeToTicks(from, to, minutesPerTick); ++i) {
    map.push(0);
    times.push(m.addTime(from, i * minutesPerTick));
  };

  _each(slots, function(slot) {
    var totalTicksFromStart = m.timeToTicks(from, slot.from, minutesPerTick);
    var slotWidth = m.timeToTicks(slot.from, slot.to > 2400 ? 2400 : slot.to, minutesPerTick);
    entries.push(slot);
    while (slotWidth > 0) {
      map[totalTicksFromStart++] = entries.length - 1;
      slotWidth--;
    }
  });

  var i = 0;
  var lastItem = map[i];
  var item = {
    data: entries[lastItem],
    from: times[i],
  }

  for (; i < map.length - 1; ++i) {
    if (lastItem === map[i] || !item.data) {
      continue;
    }

    item.to = times[i];
    row.push(item);

    lastItem = map[i];
    item = {
      data: entries[map[i]],
      from: times[i]
    };
  }

  item.to = times[i];
  if (item.data && item.from != item.to) {
    row.push(item);
  }

  return row;
};

m.findOpenHours = function(slots) {
  var hours = {};
  var _min = _minBy(slots, function(i) {
    return i.from;
  });
  hours.from = _get(_min, 'from');
  var _max = _maxBy(slots, function(i) {
    return i.to < 400 ? i.to + 2400 : i.to;
  });
  hours.to = _get(_max, 'to');
  if (hours.to < 400) {
    hours.to += 2400;
  }

  return hours;
};

m.mergeSlotsAndEvents = function(from, to, minutesPerTick, slots) {

  // See
  // http://stackoverflow.com/questions/43044/algorithm-to-randomly-generate-an-aesthetically-pleasing-color-palette
  // http://blog.functionalfun.net/2008/07/random-pastel-colour-generator.html


  // Final result of events with start and end times
  var row = [];
  // Map wich contains the events ids to help build final events
  var map = [];
  // Available time slots
  var times = [];
  // Event entries whih linked in map
  var entries = [{ name: '', isNA: true }];

  for (var i = 0; i <= m.timeToTicks(from, to, minutesPerTick); ++i) {
    map.push(0);
    times.push(m.addTime(from, i * minutesPerTick));
  };

  _each(slots, function(slot) {
    var totalTicksFromStart = m.timeToTicks(from, slot.from, minutesPerTick);
    var slotWidth = m.timeToTicks(slot.from, slot.to > 2400 ? 2400 : slot.to, minutesPerTick);
    entries.push(slot);
    while (slotWidth > 0) {
      map[totalTicksFromStart++] = entries.length - 1;
      slotWidth--;
    }
  });

  var i = 0;
  var lastItem = map[i];
  var item = {
    data: entries[lastItem],
    from: times[i],
  }

  for (; i < map.length - 1; ++i) {
    if (lastItem === map[i] || !item.data) {
      continue;
    }

    item.to = times[i];
    row.push(item);

    lastItem = map[i];
    item = {
      data: entries[map[i]],
      from: times[i]
    };
  }

  item.to = times[i];
  if (item.data && item.from != item.to) {
    row.push(item);
  }

  return row;
};

module.exports = m;
