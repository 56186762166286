import { RawLead } from 'spc/lib/database/types/lead';
import { RawBaseUser } from 'spc/lib/database/types/base-user';

const AddCollaboratorsModal = ['ngDialog', function(ngDialog) {
  let returnLead;
  let returnProposals;
  return function({ user, lead }: { user: RawBaseUser, lead: RawLead }) {
    return ngDialog.open({
      preCloseCallback: function () {
        if (!returnLead) {
          returnLead = lead;
        }
      },
      template: '<add-collaborators user="$ctrl.user" lead="$ctrl.lead" close="$ctrl.close({updatedLead, updatedProposals })" update="$ctrl.update({updatedLead, updatedProposals})"> </add-collaborators>',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small micro-modal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.user = user;
        this.lead = lead;
        this.close = () => {
          $scope.closeThisDialog();
        };
        this.update = ({ updatedLead, updatedProposals }) => {
          returnLead = updatedLead;
          returnProposals = updatedProposals;
        };
      }]
    }).closePromise.then(() => {
      return Promise.resolve({ lead: returnLead, proposals: returnProposals });
    });
  };
}];

export default AddCollaboratorsModal;
