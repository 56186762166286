import { find, findIndex, get, last, includes, isNil, some, cloneDeep, isFinite } from 'lodash';

import BookingRequestHelpers from 'common/dist/virtuals/BookingRequest';
import DrinkRequestHelpers from 'common/dist/virtuals/DrinkRequest';
import { Counteroffer, Delta, DrinkRequest, RawBookingRequest, } from 'spc/lib/database/types/booking-request';

export default function() {
  const ret = {
    addOns: {
      initCustomAddOn: initCustomAddOn,
      getAll: getAllAddOns
    },
    drinks: {
      getAll: getAllDrinks,
      initCustomHourlyPackage
    },
    findDrink,
    findDrinkIdx,
    hasTimeDelta,
    isNew,
    removeDelta,
    createClone,
    displayTime,
    hadEvent,
    getDisabledDetails
  };

  return ret;

  ///// Functions

  function createClone(request: RawBookingRequest, guest: boolean): RawBookingRequest {
    if (!request) {
      return;
    }

    const clone = cloneDeep(request);
    const lastCounteroffer = last(clone.counteroffers) as Counteroffer;
    if (!lastCounteroffer) {
      return clone;
    }

    const applicableStates = ['READY'];
    if (!guest) {
      applicableStates.push('INIT');
    }

    if (includes(applicableStates, lastCounteroffer.state)) {
      BookingRequestHelpers.applyDelta(clone, lastCounteroffer.delta);
    }

    return clone;
  }

  function hadEvent(request: RawBookingRequest, action: string, admin: boolean): boolean {
    const history = admin ?
      request.history.concat(request.admin.history) :
      request.history;
    return some(history, h => h.action === action);
  }

  function displayTime(request: RawBookingRequest, delta: Delta): string {
    return BookingRequestHelpers.timeSpan(request, delta);
  }

  function hasTimeDelta(delta: Delta): boolean {
    if (!delta) {
      return false;
    }
    return (delta['data.time'] && delta['data.time'].newValue) ||
      (delta['data.duration'] && delta['data.duration'].newValue);
  }

  function getAllAddOns(request: RawBookingRequest) {
    let res = [];
    const venueAddOns = get(request, 'venue.data.addOns');
    const spaceAddOns = get(request, 'selectedSpace.data.addOns');

    if (venueAddOns) {
      res = res.concat(venueAddOns);
    }
    if (spaceAddOns) {
      res = res.concat(spaceAddOns);
    }

    return res.concat({
      isCustom: true,
      item: 'Add Custom',
      $fake: true
    });
  }


  function initCustomAddOn(request: RawBookingRequest, previousAddOn) {
     return {
      isCustom: true,
      isForBooking: true,
      numGuests: undefined,
      taxType: get(previousAddOn, 'taxType'),
      priceType: get(previousAddOn, 'priceType')
    };
  }

  function getAllDrinks(drinks, request: RawBookingRequest) {
    if (!drinks) {
     drinks = [];
    }

    // add fake drink request so hosts can add custom
    return drinks.concat({
      $fake: true,
      isCustom: true,
      data: {
        name: 'Add Custom'
      }
    });
  }

  /**
   * @mutates `$scope.deltaArr`
   */

  function removeDelta(deltaArr: Delta[], path: string) {
    if (!get(deltaArr, 'length')) {
      return;
    }

    const idx = findIndex(deltaArr, p => p.path === path);

    if (idx !== -1) {
      const oldVal = deltaArr[idx].newValue;
      deltaArr.splice(idx, 1);
      return oldVal;
    }
  }

  function isNew(delta: Delta, path: string): boolean {
    if (isNil(delta[path])) {
      return false;
    }

    return !isNil(delta[path].newValue);
  }

  function findDrink(request: RawBookingRequest, id: string) {
    if (!request) {
      return;
    }

    const drink = find(request.data.drinks,
      (_drink: { _id: string }) => _drink._id.toString() === id.toString());
    return drink;
  }

  function findDrinkIdx(request: RawBookingRequest, id: string): Number {
    if (!request) {
      return;
    }
    return findIndex(request.data.drinks,
      drink => drink._id.toString() === id.toString());
  }

  function getDisabledDetails(request): string {
    const pathsAndErrors = [{ path: 'data.eventType', error: 'Please select an event type' }, { path: 'selectedSpace', error: 'Please select a space' }, { path: 'data.groupSize', error: 'Please enter a group size' }, { path: 'data.duration', error: 'Please select both start and end time' }];

    for (const requirement of pathsAndErrors) {
      if (!get(request, requirement.path)) {
        return requirement.error;
      }
    }

    if (!isFinite(BookingRequestHelpers.fbMinCents(request))) {
      return 'Please enter an F&B Minimum';
    }
  }

  function initCustomHourlyPackage(request: RawBookingRequest, prevPackage) {
   const hourlyPackage: DrinkRequest = {};
    hourlyPackage.isCustom = true;
    hourlyPackage.duration = 60;
    hourlyPackage.selectedDurationMinutes =
      +get(prevPackage, 'selectedDurationMinutes') || +get(request, 'data.duration');
    hourlyPackage.numGuests = +get(prevPackage, 'numGuests') || +get(request, 'data.groupSize');
    hourlyPackage.costPerGuestCents = DrinkRequestHelpers.computeCostPerGuestCents(request);
    return hourlyPackage;
  }
}
