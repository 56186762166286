// Sixplus Dependencies
import { ApiService } from 'spc/shared/api/api.service';
import { ConciergeDashboardService } from './concierge-dashboard.service';
import VenueHelpers from 'common/dist/virtuals/Venue';

// External Dependencies
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import moment from 'moment';

// SixPlus Types
import { Recommendation, Lead, Suggestion } from 'server/api/admin/leads/models';
import { RawRecommendation } from '../../../../database/types/recommendation';
import { RawLead } from 'spc/lib/database/types/lead';

import { getCityNameFromValue } from 'spc/utils/getCityDisplayName';

/**
 * @desc Modal to search for venues based on name.
 * Makes API call on submit to add spaces to recommendedSpaces
 * @param { [] } recommendedSpaces,
 * @return { Promise }
 */

export interface OnDialogClose {
  value: {
    cancel?: boolean;
    lead: Lead
  } & string;
}

class ConciergeVenueSearchController {
  history: any;
  recommendations: RawRecommendation[];
  ui: {
    persistingSpaceReco?: string;
  } = { persistingSpaceReco: null };
  suggestions: {
    loading: boolean;
    coldStart?: Suggestion[];
    hotStart?: Suggestion[]
  } = { loading: true };
  displayedRecommendations: any[];
  openSidebarEdit: boolean = false;
  selectedVenue: any;
  selectedVenueSpaces: any[];
  lead: any;
  venues: any;
  getCityNameFromValue = getCityNameFromValue;
  close: ({ data }: { data: any }) => any;
  constructor(private $scope, private $cloudinary, private unwrapError, private $api: ApiService, private conciergeDashboardService: ConciergeDashboardService) {
    'ngInject';
  }

  isBookingPriority = VenueHelpers.isBookingPriority;

  debouncedVenueSearch = debounce((name) => {
    return this.$api.Admin.Venues.findManyByName(name, { targetCity: this.lead.request.city })
      .then((response: { data: { venues: any[] }}) => {
        this.venues = response.data.venues;
        if (this.venues.length === 1) {
          this.selectVenue(this.venues[0]);
        }
      })
      .catch(error => this.unwrapError(error));
    }, 300);

  selectVenue = (venue) => {
    if (!venue.isVisible) {
      return;
    }
    this.venues = null;
    this.getClientHistory();
    return this.getVenueAvailability(venue);
  }

  getClientHistory = () => {
    if (!this.selectedVenue) {
      return;
    }
    const client = this.lead.primaryClient;
    const id = client._id ? client._id.toString() : client.toString();
    this.$api.Admin.Users.history(id, this.selectedVenue._id)
      .then(response => this.history = response.recoFeedback)
      .catch(error => this.unwrapError(error));
  }

  getSpaceHistory = (spaceId) => {
    return this.history[spaceId];
  }

  getAndSetSuggestions = () => {
    this.$api.Admin.Leads.getSuggestions(this.lead._id)
      .then((data) => {
        this.suggestions.hotStart = data.hotStart;
        this.suggestions.coldStart = data.coldStart;
        this.suggestions.hotStart.forEach((suggestion) => {
          suggestion.space.availability = this.conciergeDashboardService.getSpaceAvailability({
            space: suggestion.space,
            lead: this.lead,
            spaceCalendar: suggestion.calendar
          });
          suggestion.space.timeSlot = this.conciergeDashboardService.getTimeSlotTerms(suggestion.space, this.lead);
        });
        this.suggestions.loading = false;
      }).catch((error) => {
        this.suggestions.loading = false;
        this.unwrapError(error);
      });
  }

  debouncedGetAndSetSuggestions = debounce(this.getAndSetSuggestions, 3000);

  getVenueAvailability = (venue) => {
    const date = moment(this.lead.request.date).utc()
      .format('YYYY-MM-DD');
    return this.$api.Admin.Venues.getVenueAvailability(venue._id.toString(), date)
      .then(({ spaces, calendars }) => {
        this.selectedVenue = venue;
        const calendarMap: any = groupBy(calendars, calendar => calendar.space);
        this.selectedVenueSpaces = spaces
          .map((space: any) => {
            space.availability = this.conciergeDashboardService.getSpaceAvailability({ space, lead: this.lead, calendarMap });
            space.timeSlot = this.conciergeDashboardService.getTimeSlotTerms(space, this.lead);
            return space;
          });
        return this.selectedVenueSpaces;
      })
      .catch(error => this.unwrapError(error));
  }

  allowAddSpace = (space) => {
    if (this.ui.persistingSpaceReco === space._id.toString()) {
      return false;
    }
    return !this.recommendations.some(recommendation => recommendation.space._id === space._id && !recommendation.isDeleted);
  }

  venueSearch = (name) => {
    this.selectedVenue = '';
    return this.debouncedVenueSearch(name);
  }

  openSidebar = () => {
    this.openSidebarEdit = !this.openSidebarEdit;
  }

  addSpace = (space) => {
    const visibleRecommendations = this.recommendations.filter(recommendation => !recommendation.isDeleted);
    const order = visibleRecommendations.length + 1;
    const recommendation = this.makeRecommendation({ space, venue: this.selectedVenue, order });
    return this.persistNewRecommendation(recommendation);
  }

  addSuggestion = (suggestion) => {
    const visibleRecommendations = this.recommendations.filter(recommendation => !recommendation.isDeleted);
    const order = visibleRecommendations.length + 1;
    const recommendation = this.makeRecommendation({ space: suggestion.space, venue: suggestion.venue, order });
    this.persistNewRecommendation(recommendation);
  }

  deleteRecommendation = (recommendation) => {
    this.$api.Recos.updateRecommendation(recommendation._id, { isDeleted: true, isVisible: false })
      .then(() => {
        this.recommendations = this.recommendations.filter(reco => reco._id.toString() !== recommendation._id.toString());
        this.debouncedGetAndSetSuggestions();
        this.displayRecommendations();
      })
      .catch(error => this.unwrapError(error));
  }

  displayRecommendations = () => {
    this.displayedRecommendations = this.recommendations.filter(recommendation => !recommendation.isRecommended && !recommendation.isDeleted);
  }

  persistNewRecommendation = (reco) => {
    if (this.ui.persistingSpaceReco === reco.space._id.toString()) {
      return;
    }
    this.ui.persistingSpaceReco = reco.space._id.toString();
    const leanReco = Object.assign({}, reco, { space: reco.space._id, venue: reco.venue._id });
    return this.$api.Admin.Leads
      .addRecommendation(this.lead, { recommendation: leanReco })
      .then((response) => {
        this.lead.recommendations.push(response.recommendation);
        this.recommendations.push(response.recommendation);
        this.displayRecommendations();
      })
      .then(() => this.debouncedGetAndSetSuggestions())
      .catch((error) => {
        this.ui.persistingSpaceReco = null;
        this.unwrapError(error);
      });
  }

  finish = () => {
    this.lead.recommendations = this.recommendations;
    const data = { lead: this.lead };
    this.close({ data });
  }

  $onInit = () => {
    this.getAndSetSuggestions();
    this.displayRecommendations();
    this.getClientHistory();
  }

  makeRecommendation = ({ space, venue, order }) => {
    const wasHotSuggestion = this.suggestions.hotStart && this.suggestions.hotStart.find(suggestion => suggestion.space._id.toString() === space._id.toString());
    const wasColdSuggestion = this.suggestions.coldStart && this.suggestions.coldStart.find(suggestion => suggestion.space._id.toString() === space._id.toString());

    const suggestionType = [];
    if (wasHotSuggestion) {
      suggestionType.push('hot');
    }
    if (wasColdSuggestion) {
      suggestionType.push('cold');
    }

    return {
      admin: {
        wasSuggestion: wasHotSuggestion || wasColdSuggestion,
        suggestionType
      },
      order,
      space,
      venue,
      lead: this.lead._id,
      isNew: true,
      isVisible: true
    };
  }
}

export type ConciergeVenueSearch = (recommendations: RawRecommendation[], lead: RawLead) => Promise<OnDialogClose>;

export const ConciergeVenueSearchComponent = {
  template: require('./concierge-venue-search.component.jade'),
  controller: ConciergeVenueSearchController,
  bindings: {
    recommendations: '<',
    lead: '<',
    close: '&'
  }
};

export const conciergeVenueSearchModal = ['ngDialog', function(ngDialog) {
  return function (recommendations: Recommendation[], lead: Lead): Promise<OnDialogClose> {
    return ngDialog.open({
      template: '<concierge-venue-search lead="$ctrl.lead" recommendations="$ctrl.recommendations" close="$ctrl.close(data)"></concierge-venue-search>',
      className: 'ngdialog-theme-plain',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function($scope) {
        this.recommendations = Array.from(recommendations, reco => Object.assign({}, reco));
        this.lead = lead;
        this.close = function(data) {
          return $scope.closeThisDialog(data);
        };
      }]
    }).closePromise;
  };
}];
