import { EventResult } from 'shared/interfaces/metrics/event-result.model';
import { get, round, sumBy } from 'lodash';

export class ProposalMetricsHelpers {

  public static sumByProperty(bucket: EventResult[], property: string): number {
    const revenueGeneratingEvents = bucket.filter(event => event.breakdown.totalCents);
    return sumBy(revenueGeneratingEvents, event => round(get<number>(event, property) / 100, 2));
  }
}
