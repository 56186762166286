export const ClientNotesModal = ['ngDialog', function(ngDialog) {
  return function (client) {
    return ngDialog.open({
      template: `<client-notes ng-if="$ctrl.client" client="$ctrl.client" allow-editing="false">`,
      plain: true,
      className: 'ngdialog-theme-small',
      controllerAs: '$ctrl',
      controller: function() {
        this.client = client;
      }
    }).closePromise;
  };
}];
