import { ApiService } from 'spc/shared/api/api.service';
import { VenueData, SpaceData, AllLeadsData } from 'server/api/admin/metrics/models';
import { StateEmitter } from 'spc/utils/StateEmitter';

class ConciergeMetricsDashboardController {
  componentIsReady: () => any;
  ownerNames: string[];
  conciergeTeam: any[];

  searched: {
    totalConversionPercentage?: string;
    totalLeads?: number;
    totalBooked?: number;
  } = {};

  spliced: {
    totalConversionPercentage?: string;
    totalLeads?: number;
    totalBooked?: number;
  } = {};

  cityOptions: string[];
  spaceData: SpaceData;
  venueData: VenueData;
  allLeadsData: AllLeadsData;
  search: {
    occasions?: string[];
    cities?: string[];
    owners?: string[];
    privacyTypes?: string[];
    heardFrom?: string[];
    origins?: string[];
    removeKilled?: boolean
  } = { removeKilled: true };
  ui: {
    state?: any;
    displayResults: boolean;
    showOccasionSelect: boolean;
    showCitySelect: boolean;
    showOwnerSelect: boolean;
    showPrivacySelect: boolean;
    showHeardFromSelect: boolean;
    showOriginSelect: boolean;
  } = {
    displayResults: false,
    showOccasionSelect: false,
    showCitySelect: false,
    showOwnerSelect: false,
    showPrivacySelect: false,
    showHeardFromSelect: false,
    showOriginSelect: false
  };
  eventTypes: string[];
  constructor(private $api: ApiService, private unwrapError, private ENUMS) {
    'ngInject';
    this.cityOptions = this.ENUMS.acceptableUserCities.map(city => city.name).concat('All Other Cities');
    this.eventTypes = ENUMS.eventType.filter(type => type.visible).map(type => type.label);
  }

  $onInit = () => {
    this.ui.state = new StateEmitter(['LOADING', 'LOADED'], 'LOADED');
    this.$api.Admin.Leads.getAllOwners()
      .then((result) => {
        this.conciergeTeam = result.conciergeTeam;
        this.ownerNames = this.conciergeTeam.map(member => member.fullName);
      })
      .catch(error => this.unwrapError(error));
  }

  toggleDisplay = (path) => {
    this.ui[path] = !this.ui[path];
  }

  closeOccasionSelect = (selectedOptions) => {
    this.ui.showOccasionSelect = false;
    this.search.occasions = selectedOptions;
  }

  closeCitySelect = (selectedOptions) => {
    this.ui.showCitySelect = false;
    this.search.cities = selectedOptions;
  }

  closeOwnerSelect = (selectedOptions) => {
    this.ui.showOwnerSelect = false;
    this.search.owners = this.conciergeTeam
      .filter(member => selectedOptions.includes(member.fullName))
      .map(member => member._id);
  }

  closeHeardFromSelect = (selectedOptions) => {
    this.ui.showHeardFromSelect = false;
    this.search.heardFrom = selectedOptions;
  }

  closePrivacySelect = (selectedOptions) => {
    this.ui.showPrivacySelect = false;
    this.search.privacyTypes = selectedOptions;
  }

  closeOriginSelect = (selectedOptions) => {
    this.ui.showOriginSelect = false;
    this.search.origins = selectedOptions;
  }

  getLeadConversion = () => {
    this.ui.state.$state('LOADING');
    this.$api.Admin.Metrics.getTotalLeadConversion(this.search)
      .then((response) => {
        const { venueData, spaceData, leads: { conversion, totalLeads, totalBooked, allLeadsData }, splicedLeads } = response;
        this.ui.displayResults = true;
        this.searched.totalConversionPercentage = `${(conversion * 100).toFixed(2)} %`;
        this.searched.totalLeads = totalLeads;
        this.searched.totalBooked = totalBooked;
        if (splicedLeads) {
          this.spliced.totalConversionPercentage = `${(splicedLeads.conversion * 100).toFixed(2)} %`;
          this.spliced.totalLeads = splicedLeads.totalLeads;
          this.spliced.totalBooked = splicedLeads.totalBooked;
        }
        this.ui.state.$state('LOADED');
        this.spaceData = spaceData;
        this.venueData = venueData;
        this.allLeadsData = allLeadsData;
      })
      .catch((error) => {
        this.ui.state.$state('LOADED');
        this.unwrapError(error);
      });
  }
}

export const ConciergeMetricsDashboardComponent = {
  controller: ConciergeMetricsDashboardController,
  template: require('./concierge-metrics-dashboard.component.jade'),
  bindings: {
    componentIsReady: '&',
  }
};
