import { ApiService } from 'spc/shared/api/api.service';
import { RawConversation } from '../../../../../database/types/conversation';

import get from 'lodash/get';

class ProposalVisibilityControlController {
  conversation: RawConversation;
  constructor(private $api: ApiService, private $user, private unwrapError) {
    'ngInject';
  }

  makeVisibleToClients = () => {
    if (!this.conversation) {
      return;
    }

    if (get(this.conversation, 'admin.visibility.client')) {
      return;
    }

    const update = { 'admin.visibility.client': true };
    return this.$api.Admin.Conversations.updateConversation({ conversation: this.conversation, update })
    .then(() => {
      this.conversation.admin.visibility.client = true;
    })
    .catch(error => this.unwrapError(error));
  }

  makeVisibleToVenue = () => {
    if (!this.conversation) {
      return;
    }

    if (get(this.conversation, 'admin.visibility.venue')) {
      return;
    }

    const update = { 'admin.visibility.venue': true };
    return this.$api.Admin.Conversations.updateConversation({ conversation: this.conversation, update })
      .then(() => {
        this.conversation.admin.visibility.venue = true;
      })
      .catch(error => this.unwrapError(error));
  }

  canMakeVisibleToVenues = () => {
    if (!this.conversation) {
      return;
    }
    return !get(this.conversation, 'admin.visibility.venue');
  }

  canMakeVisibleToClients = () => {
    if (!this.conversation) {
      return;
    }
    return !get(this.conversation, 'admin.visibility.client');
  }
}

export const proposalVisibilityControlComponent = {
  controller: ProposalVisibilityControlController,
  template: require('./proposal-visibility-control.component.jade'),
  bindings: {
    conversation: '<'
  }
};
