import moment from 'moment';
import groupBy from 'lodash/groupBy';

// SP Interfaces
import { ApiService } from 'spc/shared/api/api.service';
import { months } from 'moment-timezone';
import { MonthViewService } from 'spc/components/availability-calendar/month-view/month-view.service';

class MonthViewController {
  venue: any;
  bookingRequestsMap: any;
  spaceAvailabilityMap: any;
  allCalendars: { space: string, availability: any }[];
  calendarAvailability: any;
  selectedSpace: any;
  eventsOnly: boolean;
  ui: {
    ready: boolean;
    spaceClassMap?: any;
    months: string[];
    selectedMonth?: string;
    selectedYear?: number;
    calendar: any[];
    weekdays: string[];
  } = {
    ready: false,
    months: moment.months(),
    calendar: [],
    weekdays: moment.weekdays(),
  };

  constructor(
    private $api: ApiService,
    private $q,
    private now,
    private monthViewService: MonthViewService,
    private unwrapError) {
    'ngInject';
  }
  $onChanges = (data) => {
    if (!this.ui.ready) {
      return;
    }
    if (data.venue) {
      this.ui.spaceClassMap = this.monthViewService.createSpaceClassMap(this.venue.data.spaces);
    }
    if (data.allCalendars) {
      const venueHasChanged = !this.venue.data.spaces.find(space => space._id.toString() === this.selectedSpace._id.toString());

      return this.handleSpaceChange(venueHasChanged ? this.venue.data.spaces[0] : this.selectedSpace);
    }

    if (data.selectedSpace) {
      this.handleSpaceChange(this.selectedSpace);
    }

    if (data.eventsOnly) {
      this.getEvents({ includePending: true });
    }
  }

  $onInit = () => {
    this.ui.selectedMonth = moment.months()[this.now().month()];
    this.ui.selectedYear = this.now().year();
    this.ui.spaceClassMap = this.monthViewService.createSpaceClassMap(this.venue.data.spaces);
    this.handleSpaceChange(this.selectedSpace || this.venue.data.spaces[0])
      .then(() => this.ui.ready = true);
  }

  createCalendar = () => {
    this.ui.calendar = [];
    const monthIndex = this.ui.months.indexOf(this.ui.selectedMonth);
    const startWeek = moment(`${monthIndex + 1}-${this.ui.selectedYear}`, 'M-YYYY').startOf('month').week();
    let endWeek = moment(`${monthIndex + 1}-${this.ui.selectedYear}`, 'M-YYYY').endOf('month').week();

    if (endWeek === 1) {
      endWeek = 53;
    }

    for (let week = startWeek; week <= endWeek; week++) {
      this.ui.calendar.push({
        week,
        days: Array.from({ length: 7 }, (n, i) => {
          const date = this.now().week(week).year(this.ui.selectedYear).startOf('week').add(i, 'day');
          return {
            date: date.format('D'),
            fullDate: date.format('YYYY-MM-DD')
          };
        })
      });
    }
  }

  selectNextYear = (event) => {
    event.stopPropagation();
    this.ui.selectedYear++;
    this.selectMonth(this.ui.selectedMonth);
  }

  selectPreviousYear = (event) => {
    event.stopPropagation();
    this.ui.selectedYear--;
    this.selectMonth(this.ui.selectedMonth);
  }

  selectNextMonth = (event) => {
    event.stopPropagation();
    let currentMonth = this.ui.months.indexOf(this.ui.selectedMonth);
    if (currentMonth === 11) {
      currentMonth = 0;
      this.ui.selectedYear++;
    } else {
      currentMonth++;
    }
    this.ui.selectedMonth = this.ui.months[currentMonth];
    return this.selectMonth(this.ui.selectedMonth);
  }

  selectPreviousMonth = () => {
    event.stopPropagation();
    let currentMonth = this.ui.months.indexOf(this.ui.selectedMonth);
    if (currentMonth === 0) {
      currentMonth = 11;
      this.ui.selectedYear--;
    } else {
      currentMonth--;
    }
    this.ui.selectedMonth = this.ui.months[currentMonth];
    return this.selectMonth(this.ui.selectedMonth);
  }

  handleSpaceChange = (space) => {
    this.monthViewService.stopEditing();
    this.selectedSpace = space;
    return this.$q.all([
      this.getEvents({ includePending: true }),
      this.getSpaceAvailabilities()
    ])
    .then(() => {
      this.setCalendar();
      this.createCalendar();
    })
    .catch(error => this.unwrapError(error));
  }

  selectMonth = (month) => {
    this.ui.selectedMonth = month;
    return this.$q.all([
      this.getEvents({ includePending: true }),
      this.getSpaceAvailabilities()
    ])
    .then(() => this.createCalendar())
    .catch(error => this.unwrapError(error));
  }

  setCalendar = () => {
    const calendar = this.allCalendars.find(_calendar => _calendar.space === this.selectedSpace._id.toString());
    if (calendar) {
      this.calendarAvailability = calendar.availability;
    }
  }

  isValidAndInMonth = (date) => {
    return this.monthViewService.isValidAndInMonth(date, this.ui.selectedMonth);
  }

  isToday = (date) => {
    return this.monthViewService.isToday(date);
  }

  getEvents = ({ includePending }) => {
    return this.eventsOnly ?
      this.getAllSpaceEvents({ includePending }) :
      this.getSelectedSpaceEvents({ includePending });
  }

  getSelectedSpaceEvents = ({ includePending }: { includePending: boolean }) => {
    const data = {
      spaceId: this.selectedSpace._id,
      venueId: this.venue._id,
      month: this.now().month(this.ui.selectedMonth).format('MM'),
      includePending,
      year: this.ui.selectedYear
    };

    return this.$api.AvailabilityCalendar.getSpaceEvents({ data })
      .then(({ bookingRequests, calendar }) => {
        this.bookingRequestsMap = groupBy(bookingRequests, (request: any) => request.data.date);
        this.calendarAvailability = calendar.availability;
      })
      .catch(error => this.unwrapError(error));
  }

  getAllSpaceEvents = ({ includePending }: { includePending: boolean }) => {

    const data = {
      venueId: this.venue._id,
      month: this.now().month(this.ui.selectedMonth).format('MM'),
      includePending,
      year: this.ui.selectedYear
    };

    return this.$api.AvailabilityCalendar.getVenueEvents({ data })
      .then(({ bookingRequests }) => this.bookingRequestsMap = groupBy(bookingRequests, (request: any) => request.data.date))
      .catch(error => this.unwrapError(error));
  }

  getSpaceAvailabilities = () => {
    const data = {
      spaceId: this.selectedSpace._id,
      month: this.now().month(this.ui.selectedMonth).format('MM'),
      year: this.ui.selectedYear
    };

    return this.$api.Scheduler.getMonthAvailability(data)
      .then(({ spaceAvailabilityMap }) => this.spaceAvailabilityMap = spaceAvailabilityMap)
      .catch(error => this.unwrapError(error));
  }
}

export const MonthViewComponent = {
  controller: MonthViewController,
  template: require('./month-view.component.jade'),
  bindings: {
    venue: '<',
    allCalendars: '<',
    selectedSpace: '<',
    eventsOnly: '<'
  }
};
