export const adminManageModal = ['ngDialog', function(ngDialog) {
  return ({ request, conversation, user }) => {
    return ngDialog.open({
      template: require('./admin-manage.modal.jade'),
      className: 'ngdialog-theme-small vp-management',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: function() {
        this.request = request;
        this.conversation = conversation;
        this.user = user;
      }
    }).closePromise;
  };
}];
