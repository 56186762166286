export const TEXT_MAP = {
  INCOMPLETE: 'Incomplete Proposal',
  PROPOSAL: 'Finalize Proposal',
  COUNTEROFFER: 'Send Proposal or Decline Request',
  PRE_DEPOSIT_ALTERATION : 'Pending Deposit',
  ACCEPTED_HOST: 'Pending Deposit',
  ACCEPTED_GUEST: 'Pending Deposit',
  DEPOSIT: 'Deposit Paid',
  POST_DEPOSIT_ALTERATION : 'Deposit Paid',
  ALTERATION_ACCEPTED_GUEST: 'Deposit Paid',
  ALTERATION_ACCEPTED_HOST: 'Deposit Paid',
  PAID: 'Balance Paid',
  CONCLUDED: 'Concluded',
  RECONCILED: 'Concluded',
  CLOSED: 'Concluded',
  DECLINED_HOST: 'Declined by Venue',
  DECLINED_GUEST: 'Declined by Client',
  EXPIRED: 'Expired',
  CANCELLED_REQUEST_GUEST: 'Cancelled Proposal',
  CANCELLED_POST_DEPOSIT_CLIENT: 'Client Cancelled Post Deposit',
  CANCELLED_POST_DEPOSIT_VENUE: 'Venue Cancelled Post Deposit'
};

export const VENUE_GUIDANCE_MAP = {
  INCOMPLETE: 'The proposal is incomplete and waiting to be sent to the client.',
  PROPOSAL: 'Finalize the proposal and send to the client for review',
  REQUEST: 'Review the Event Request and approve or decline.',
  COUNTEROFFER: 'Finalize your changes so that the client can view them.',
  PRE_DEPOSIT_ALTERATION : 'Let the client know they should be able to see your latest changes and complete the deposit.',
  ACCEPTED_HOST: 'Make sure the client is aware that they must accept the proposal and pay the deposit for the space to be reserved.',
  ACCEPTED_GUEST: 'The client has accepted, but has not paid the deposit yet. Make sure they are aware that only a deposit secures the space.',
  DEPOSIT: 'Work with the client to make sure there’s nothing missing in the proposal (i.e. allergies, table configuration, and F&B items).',
  POST_DEPOSIT_ALTERATION : 'Finalize your changes so that the client can view them.',
  ALTERATION_ACCEPTED_GUEST: 'The client has accepted your latest changes. See if there are anymore.',
  ALTERATION_ACCEPTED_HOST: 'The client should be able to see your latest changes. You can make additional changes that are immediately viewable.',
  PAID: 'The clients automatic balance payment has gone through. Prepare for the event and good luck!',
  CONCLUDED: 'Email the completed event receipt to concierge@sixplus.com',
  RECONCILED: 'The event should be reconciled now. Your payout is coming soon.',
  CLOSED: 'Thanks for a great event!'
};

export const CLIENT_GUIDANCE_MAP = {
  REQUEST: 'The venue is reviewing your Event Request. Send them a message through the proposal if you have any questions.',
  COUNTEROFFER: 'The venue is making changes to your proposal. You should see them soon.',
  PRE_DEPOSIT_ALTERATION : 'You should be able to see the latest changes to the proposal. Please review and accept prior to paying the deposit to secure the space.',
  ACCEPTED_HOST: 'Please review the proposal and pay the deposit to secure the space.',
  ACCEPTED_GUEST: 'You have accepted the proposal, but you still have to pay the deposit to secure the space.',
  DEPOSIT: 'Now that the space is confirmed, message the venue to finalize the menu and any other items for the event.',
  POST_DEPOSIT_ALTERATION : 'The venue is making changes to your proposal. You should see them soon.',
  ALTERATION_ACCEPTED_GUEST: 'You have accepted the latest changes. Let the venue know if there are any other changes you would like.',
  ALTERATION_ACCEPTED_HOST: 'You should be able to see the latest changes to the proposal. Please review them and click accept if you agree.',
  PAID: 'Your automatic balance payment has gone through. Good luck with your event!',
  CONCLUDED: 'We hope you enjoyed your event! You should have received an email to leave a review. We appreciate your feedback.',
  RECONCILED: 'Your event has been fully charged. Please review the receipt, if necessary.',
  CLOSED: 'Thanks for a great event!'
};

export const STATUS_MAP = {
  'edc-status-pending': ['PROPOSAL', 'REQUEST', 'COUNTEROFFER', 'PRE_DEPOSIT_ALTERATION'],
  'edc-status-accepted': [, 'ACCEPTED_HOST', 'ACCEPTED_GUEST', 'DEPOSIT', 'POST_DEPOSIT_ALTERATION', 'PAID', 'ALTERATION_ACCEPTED_GUEST', 'ALTERATION_ACCEPTED_HOST', 'CONCLUDED', 'RECONCILED', 'CONCLUDED', 'CLOSED'],
  'edc-status-declined': ['DECLINED_HOST', 'DECLINED_GUEST', 'CANCELLED_REQUEST_GUEST', 'EXPIRED', 'CANCELLED_POST_DEPOSIT_CLIENT', 'CANCELLED_POST_DEPOSIT_VENUE']
};
