
export const coverImageSort = () => {
  return function (images, coverIndex) {
    if (images.length > 1) {
      const restOfImages = images.filter((image, index) => index !== coverIndex);
      const sortedImages = [images[coverIndex], ...restOfImages];
      return sortedImages;
    } else {
      return images;
    }
  };
};

