import * as angular from 'angular';
import { MENU_TIMES } from './menu-times.constant';
import { ANALYTICS_EVENTS } from './ENUMS/analyticsEvents';
import { SEARCH } from './search.constant';
import { FEATURED_VIRTUAL_EXPERIENCES } from './featuredVirtualExperiences';
import ENUMS from 'common/dist/enums';

export const ConstantsModule = angular.module('Constants', [])
  .constant('MENU_TIMES', MENU_TIMES)
  .constant('LANDING_PAGE_CITIES', ENUMS.acceptableUserCities)
  .constant('ANALYTICS_EVENTS', ANALYTICS_EVENTS)
  .constant('SEARCH', SEARCH)
  .name;
