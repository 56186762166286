const demoVideoModal = ['ngDialog', function(ngDialog) {
    return function (demoRequest) {
      return ngDialog.open({
        template: require('./premium-demo-video.jade'),
        controllerAs: '$ctrl',
        className: 'ngdialog-theme-small info-modal',
        plain: true,
        overlay: true,
        controller: ['$scope', function ($scope) {
          this.demoRequest = demoRequest;
          this.proDemoVideoUrl = `https://www.loom.com/embed/b896d572d87643459b1b88682392ae5e?sid=22bfabb1-5945-4205-9dcc-ea8c7c6bfcb0`;
          this.commonDemoVideoUrl = `https://www.loom.com/embed/f517c84961f641a1af8e362a9849856a?sid=32add4b9-1c06-4910-8288-a81153e5d63a`;

          this.playVideoRequest = (videoRequest) => {
            return videoRequest === 'Pro' ? this.proDemoVideoUrl : this.commonDemoVideoUrl;
          };

          this.close = () => {
            $scope.closeThisDialog();
          };
        }]
      }).closePromise;
    };
  }];
  export default demoVideoModal;
