import * as angular from 'angular';

import { each } from 'lodash';

// Migrated
import { spaceAvailabilityBreakdown } from './directives/spaceAvailabilityBreakdown';
import SelectTimeHostComponent from './select-time-host.component';
import SelectTimeHostOutsidePlatformComponent from './select-time-host-outside-platform.component';
import { SelectTimeGuestComponent } from './select-time-guest.component';
import { AvailabilityFactory } from './factories';
import { SelectTimeHostService } from './select-time-host.service';
const m = angular.module('availability', [])
  .component('selectTimeHost', SelectTimeHostComponent)
  .component('selectTimeHostOutsidePlatform', SelectTimeHostOutsidePlatformComponent)
  .component('selectTimeGuest', SelectTimeGuestComponent)
  .directive('spaceAvailabilityBreakdown', spaceAvailabilityBreakdown)
  .factory('AvailabilityFactory', AvailabilityFactory)
  .service('SelectTimeHostService', SelectTimeHostService);


module.exports = 'availability';