// NPM Dependencies
import { ApiService } from 'spc/shared/api/api.service';
import ENUMS from 'common/dist/enums';

import UserHelpers from 'common/dist/virtuals/User';

import { every, get, includes, pull, set } from 'lodash';
import { DBaseUser } from '../../../../database/types/base-user';
import { getCityNameFromValue } from 'spc/utils/getCityDisplayName';

const ADDITIONAL_HEARD_ABOUT_VALUES = ['Other'];

class EditUserController {
  user: any;

  types = ENUMS.userTypes;
  referrals = ENUMS.referral.types;
  statuses = ENUMS.userStatuses;
  cities = ENUMS.acceptableUserCities;
  heardAbout = ENUMS.concierge.adminHeardAbout.concat(...ADDITIONAL_HEARD_ABOUT_VALUES);

  primaryCity: string;
  editingService: boolean = false;
  triggeringCampaign: boolean = false;
  error: any;
  email: {
    address: string;
    isValid: boolean;
  };
  conciergeTeam: DBaseUser[];

  constructor(
    private $api: ApiService,
    private toast,
    private unwrapError,
    private $clickOk,
    private GooglePlacesFactory
  ) {
    'ngInject';
  }

  $onInit = () => {
    if (get(this.user, 'profile.city')) {
      this.primaryCity = getCityNameFromValue(this.user.profile.city);
    }

    this.setCustomHeardAbout();
    this.setDefaultStatus();
    this.verifyEmail();
  }

  // Actions

  triggerCampaign = (campaignName) => {
    this.triggeringCampaign = true;
    return this.$api.Admin.Users.startCampaign({ user: this.user, campaignName })
      .then((response: any) => {
        this.toast.goodNews('Campaign Started', `The ${campaignName} campaign has been started. Please check Vero to monitor progress.`);
        this.triggeringCampaign = false;
      })
      .catch(() => {
        this.toast.badNews('Failed', 'The campaign did not start.');
        this.triggeringCampaign = false;
      });
  }

  selectPrimaryCity = (city) => {
    set(this.user, 'profile.city', city);
    this.primaryCity = getCityNameFromValue(city);
  }

  update = (userData) => {
    this.handleCustomHeardAbout(userData);
    userData.account = userData.account ? get(userData, 'account._id', userData.account) : null;
    return this.$api.Admin.Users.update(userData).
      then((response) => {
        this.toast.goodNews('user updated', 'User successfully updated!');
      }).
      catch((error) => {
        this.error = get(error, 'data.error.message');
        this.toast.badNews('Error Updating User', this.error);
        this.unwrapError(error);
      });
  }

  addRole = (role) => {
    if (includes(this.user.roles, role)) {
      return;
    }
    this.user.roles.push(role);
  }

  removeRole = (role) => {
    if (role === 'Host') {
      return this.$clickOk(`By removing a user's "Host" role, you will also unassign them from any venues, and any previous Event Requests, they might have had access to. Are you okay with that?`, true)
        .then((data) => {
          if (!get(data, 'value.cancel')) {
            pull(this.user.roles, role);
          }
        })
        .catch(error => this.unwrapError(error));
    } else {
      pull(this.user.roles, role);
    }
  }

  verifyEmail = () => {
    this.$api.Auth.verifyEmail(this.user.profile.email)
      .then((res) => {
        this.email = {
          address: res.data.address,
          isValid: res.data.valid
        };
      })
      .catch(function (error) {
        this.email = {
          address: error.data.address,
          isValid: error.data.valid
        };
        this.unwrapError(error);
      });
  }

  // Helpers

  setDefaultStatus = () => {
    // set default status for dropdown
    const currentStatus = this.user.status;
    if (!currentStatus) {
      this.user.status = this.statuses[0];
    }
  }

  setCustomHeardAbout = () => {
    if (!get(this.user, 'admin.heardAbout')) {
      return;
    }
    if (ENUMS.concierge.heardAbout.includes(this.user.admin.heardAbout)) {
      return;
    }
    this.user.admin.customHeardAbout = this.user.admin.heardAbout;
    this.user.admin.heardAbout = 'Other';
  }

  isActualUser = () => {
    return UserHelpers.isActualUser(this.user);
  }

  handleCustomHeardAbout = (userData) => {
    if (get(userData, 'admin.heardAbout') === 'Other') {
      set(userData, 'admin.heardAbout', userData.admin.customHeardAbout);
    }
  }

  canSelectRole = (role) => {
    return this.user && !includes(this.user.roles, role);
  }

  hasEnteredAllFields = () => {
    return every([
      this.user.profile.name.first,
      this.user.profile.name.last,
      this.user.profile.email,
      this.email && this.email.isValid,
      this.user.__t ? this.user.profile.city : true
    ]);
  }

  showHeardAboutDetails =  () => {
    if (!get(this.user, 'admin.heardAbout')) {
      return false;
    }
    if (get(this.user, 'admin.heardAbout') === 'Other') {
      return false;
    }
    return true;
  }

  showCustomHeardAbout =  () => {
    return get(this.user, 'admin.heardAbout') === 'Other';
  }

  completeAddress = (details) => {
    this.GooglePlacesFactory.setAddress(this.user, details);
  }

  setOwner = (owner) => {
    this.user.owner = owner;
  }
}
export const EditUserComponent = {
  template: require('./edit-user.component.jade'),
  controller: EditUserController,
  bindings: {
    user: '<',
    conciergeTeam: '<',
  }
};
