import get from 'lodash/get';
import set from 'lodash/set';

export const getAddOns = ({ recommendation, request }: { recommendation?: any, request?: any }) => {
  return []
    .concat(get(recommendation, 'venue.data.addOns') || get(request, 'venue.data.addOns') || [])
    .concat(get(request, 'selectedSpace.data.addOns', []));
};

export const getVenue = ({ recommendation, venue, request }) => {
  let _venue;
  if (venue) {
    _venue = venue;
  } else {
    _venue = get(recommendation, 'venue') || get(request, 'venue');
  }

  if (!get(_venue, 'data.spaces')) {
    set(_venue, 'data.spaces', get(request, 'venue.data.spaces'));
  }
  return _venue;
};
