import { overagePriceCents } from 'common/dist/virtuals/Payment';
import { overageTotal } from 'common/dist/price';

import { every, get, isFinite, map, reduce, round, set } from 'lodash';

export default ['ngDialog', 'OverageFactory', function(ngDialog, OverageFactory) {
  return function(request, UserService) {
    return ngDialog.open({
      template: require('./overages.jade'),
      className: 'ngdialog-theme-plain',
      overlay: false,
      plain: true,
      controller: ['$scope', 'unwrapError', 'attachmentsModal', '$cloudinary', function($scope, unwrapError, attachmentsModal, $cloudinary) {
        $scope.canSubmit = canSubmit;
        $scope.overagePriceCents = overagePriceCents;
        $scope.overageTotal = overageTotal;
        $scope.save = save;
        $scope.setOverageAmountCents = OverageFactory.setOverageAmountCents;
        $scope.startAddNewOverage = startAddNewOverage;
        $scope.removeOverage = removeOverage;
        $scope.roundQuantity = roundQuantity;
        $scope.sumOverages = sumOverages;


        init();
        ////// Initialization Function
        function init() {
          UserService.$waitFor('LOGGED_IN', function(user) {
            $scope.canEdit = UserService.isAdmin();
            $scope.request = request;
            const overages = get($scope.request, 'data.payment.overages', []);
            $scope.overages = map(overages, o => o);
            $scope.stateManager = {};
          });
        }

        ////// Public Functions

        /**
         * Saves the array of overages on the backend.
         */
        function save() {
          if ($scope.overages.length === get($scope, 'request.data.payment.overages.length') &&
            !OverageFactory.hasNewOverages($scope.overages)) {
            return $scope.closeThisDialog();
          }

          const overages = $scope.overages;

          return OverageFactory.saveOverages($scope.request, overages).
            then(function(response) {
              return $scope.closeThisDialog({ request: get(response, 'data.request') });
            }).
            catch(unwrapError);
        }

        function startAddNewOverage() {
          $scope.stateManager.addingNewOverage = true;
          $scope.overages.push({
            new: true
          });
        }

        function roundQuantity(overage) {
          if (isFinite(overage.quantity)) {
            overage.quantity = round(overage.quantity);
          }
        }

        function removeOverage(idx) {
          $scope.overages.splice(idx, 1);
        }

        function canSubmit() {
          return every($scope.overages, function(overage) {
            return overage.amountCents && overage.quantity && overage.name;
          });
        }

        function sumOverages() {
          if (!get($scope, 'overages.length')) {
            return 0;
          }

          return reduce($scope.overages, function(acc, curr) {
            const subTotal = overagePriceCents(curr);
            return acc + (isFinite(subTotal) ? subTotal : 0);
          }, 0);
        }
      }]
    }).closePromise;
  };
}];
