// NPM Dependencies
import { get, findIndex, unset, last, cloneDeep } from 'lodash';
import { StateEmitter } from '../../utils/StateEmitter';
// SixPlus Dependencies (CommonJS)

export default ['$scope', 'request', 'paymentHelpers', '$braintree', '$api', 'unwrapError', 'braintreeHelpers', '$timeout', 'toast', function ($scope, request, paymentHelpers, $braintree, $api, unwrapError, braintreeHelpers, $timeout, toast) {
  $scope.addPreviousCard = addPreviousCard;
  $scope.beginAddNewCard = beginAddNewCard;
  $scope.beginAddPreviousCard = beginAddPreviousCard;
  $scope.cancelCardTab = cancelCardTab;
  $scope.changeSelectedToken = changeSelectedToken;
  $scope.getPendingCard = getPendingCard;
  $scope.isFailedState = paymentHelpers.isFailedState;
  $scope.resubmitPayment = resubmitPayment;
  $scope.showPaymentDetails = showPaymentDetails;
  $scope.submitPaymentMethod = submitPaymentMethod;
  $scope.showResolutionTransaction = showResolutionTransaction;
  $scope.uiManager = {
    canAddPreviousCard,
    canShowAddNewCardForm,
    canShowButtons,
    canShowSubmitPaymentButton,
    isResubmitting
  };

  init();

  ///// Initialization
  function init() {
    $scope.request = cloneDeep(request);

    $api.Payment.initialize({ getMethods: true, conversation: request.conversation._id.toString() }).
      then(function (response) {
        $scope.paymentMethods = get(response, 'data.paymentMethods', []);
      }).
      catch(unwrapError);

    $scope.payments = $scope.request.data.payment.schedule;

    $scope.stateManager = {
      cardLoader: new StateEmitter(['LOADING', 'LOADED'], 'LOADED')
    };
  }

  ///// Listeners

  /**
   * Teardown the braintree instance if it exists when this modal closes
   */
  $scope.$on('$destroy', function () {
    if ($braintree.$instance) {
      $braintree.destroy();
    }
  });

  ///// Public Functions

  /**
   * Show details pertaining to a given payment in a request's array of payments
   *
   * @param {Payment}
   */
  function showPaymentDetails(payment, idx) {
    $scope.showResolutionTransactionDetails = false;
    $scope.selectedPayment = payment;
    $scope.stateManager.tab = 'INFO';
  }

  function showResolutionTransaction() {
    $scope.selectedPayment = null;
    $scope.showResolutionTransactionDetails = true;
  }
  /**
   * Display card details for a pending payment, since no token or
   * cardDetails object lives on it
   *
   * @param {Payment}
   */
  function getPendingCard() {
    if (!get($scope, 'paymentMethods.length')) {
      return;
    }
    const token = get($scope, 'request.data.payment.token');
    const masterMethod = $scope.paymentMethods.find(method => method.token === token);
    return masterMethod;
  }

  /**
   * Determines if Add New Card and Submit Payment buttons can show up
   *
   * @return {Boolean}
   */
  function canShowButtons() {
    if (!$scope.selectedPayment || $scope.selectedPayment.state === 'COMPLETED') {
      return false;
    }

    if ($scope.selectedPayment.state === 'PENDING') {
      return true;
    }

    if ($scope.selectedPayment.state === 'ERRORED') {
      const id = $scope.selectedPayment._id.toString();
      const schedule = get<any>($scope, 'request.data.payment.schedule');
      const indexOfSelected = findIndex(schedule, (p: any) => p._id.toString() === id);

      const completedToRight = schedule
        .slice(indexOfSelected + 1)
        .findIndex(payment => payment.state === 'COMPLETED');

      if (completedToRight !== -1) {
        return false;
      }
    }

    return true;
  }

  // Braintree / add new card stuff

  /**
   * Initializes braintree so we can add new cards and submit payments
   *
   * @param {AjaxResponse} ajax response from $api.Payment.getMethods
   */
  function initBraintree(ajaxResponse) {
    $scope.paymentMethods = get(ajaxResponse, 'data.paymentMethods', []);
    const hostedFields = braintreeHelpers.generateHostedFieldsOptions($scope);
    const extraMethods = {
      onPaymentMethodReceived: $scope.submitPaymentMethod,
      onError: braintreeHelpers.onError($scope),
      onReady: function (integration) {
        $scope.cardParams = {};
        $scope.stateManager.cardLoader.$state('LOADED');
        $braintree.$instance = integration;
      }
    };

    $timeout(() => $braintree.init(
      'payment-form',
      get(ajaxResponse, 'data.token'),
      hostedFields,
      extraMethods
    ));
  }

  /**
   * Begins process of adding a new card. Retrieves a user's
   * payment methods and then creates the form. Switches
   * tab to `CARD`
   */
  function beginAddNewCard() {
    $scope.stateManager.cardLoader.$state('LOADING');
    $scope.stateManager.tab = 'CARD';
    $scope.stateManager.addingNewCard = true;
    $api.Payment.initialize({ getMethods: true, conversation: request.conversation._id }).
      then(initBraintree).
      catch(function (error) {
        $scope.stateManager.cardLoader.$state('LOADED');
        unwrapError(error);
      });
  }

  /**
   * Cancels the add new card process and changes tab to `INFO`.
   * Also tears down the braintree instance.
   */
  function cancelCardTab() {
    _cleanup();
    $braintree.destroy(function () {
      $scope.stateManager.tab = 'INFO';
    });
  }

  /**
   * Determines if form to add a new card can be shown
   *
   * @return {Boolean}
   */
  function canShowAddNewCardForm() {
    return $scope.stateManager.addingNewCard &&
      $scope.stateManager.tab === 'CARD';
  }

  /**
   * Determines if submit payment button can be shown. A payment
   * can only be immediately resubmitted if it errored
   *
   * @return {Boolean}
   */
  function canShowSubmitPaymentButton() {
    return $scope.selectedPayment &&
      get($scope, 'selectedPayment.state') === 'ERRORED';
  }

  /**
   * Receives the token payload from braintree and submits information to
   * backend to create the payment method on the backend.
   * Function is also used for submitting a previous card, but doesn't
   * technically use the payload because we dont need to wait for a
   * braintree response to send off that request.
   *
   * @param {Payload} payload
   * @return {Promise}
   */
  function submitPaymentMethod(payload) {
    braintreeHelpers.setProcessingState($scope, true);

    const extra = {
      cardParams: $scope.cardParams
    };

    const identifier = payload.nonce ?
      { nonce: payload.nonce } :
      { token: payload.token };

    // if the selected payment is errored, we're really just resubmitting it and not changing the card on file for a pending payment
    if ($scope.selectedPayment.state === 'ERRORED') {
      // the resubmit payment function handles error and response in this case
      return $scope.resubmitPayment($scope.request, identifier, extra);
    }

    $api.Admin.Requests.putPayment($scope.request, identifier, extra).
      then(function (response) {
        toast.goodNews('Card successfully changed');
        $braintree.destroy(() => {
          _cleanup();
          _handleSubmitPaymentResponse(response);
        });
      }).
      catch(function (error) {
        toast.badNews('There was an error changing the card');
        unwrapError(error);
        braintreeHelpers.setProcessingState($scope, false);
      });
  }

  /**
   * Resubmits a payment with the potential to add a new card if necessary
   *
   * @param {Request} request
   * @param {Identifier} identifier, OPTIONAL (if null, it clones)
   * @param {Extra} extra, OPTIONAL
   * @return {Promise}
   */
  function resubmitPayment(_request, identifier, extra) {

    const paymentId = get($scope, 'selectedPayment._id');

    const payload = {
      requestId: _request._id,
      payment: paymentId,
      clone: !identifier,
      nonce: get(identifier, 'nonce', null),
      token: get(identifier, 'token', null),
      extra
    };

    if ($scope.selectedPayment.title === 'BALANCE') {
      return $api.Payment.balance(payload)
        .then(function (response) {
          toast.goodNews('Payment successfully resubmitted');
          _handleSubmitPaymentResponse(response);
          $braintree.destroy(() => {
            _cleanup();
          });
        })
        .catch(function (error) {
          toast.badNews('There was an error changing the card');
          unwrapError(error);
          braintreeHelpers.setProcessingState($scope, false);
        });
    }

    if ($scope.selectedPayment.title === 'OVERAGE') {
      return $api.Admin.Payment.reconcile({ request: _request, paymentOptions: payload })
        .then(function (response) {
          toast.goodNews('Payment successfully resubmitted');
          _handleSubmitPaymentResponse(response);
          $braintree.destroy(() => {
            _cleanup();
          });
        })
        .catch(function (error) {
          toast.badNews('There was an error changing the card');
          unwrapError(error);
          braintreeHelpers.setProcessingState($scope, false);
        });
    }
  }

  function isResubmitting() {
    return get($scope, 'selectedPayment.state') === 'ERRORED';
  }

  function addPreviousCard() {
    $scope.submitPaymentMethod({ token: $scope.selectedToken });
  }

  function beginAddPreviousCard() {
    $scope.stateManager.usePreviousCard = true;
    $scope.selectedToken = get($scope, 'request.data.payment.token');
  }

  function changeSelectedToken(token) {
    if ($scope.selectedToken === token) {
      $scope.selectedToken = undefined;
      return;
    }
    $scope.selectedToken = token;
  }

  function canAddPreviousCard() {
    const currentToken = get($scope, 'request.data.payment.token');
    return $scope.selectedToken &&
      ($scope.selectedPayment.state === 'ERRORED' ? true : currentToken !== $scope.selectedToken);
  }

  function _handleSubmitPaymentResponse(response) {
    $scope.request = get(response, 'request');
    $scope.payments = get($scope, 'request.data.payment.schedule', []);
    if (get(response, 'data.newMethod')) {
      $scope.paymentMethods.push(response.data.newMethod);
    }
    $scope.showPaymentDetails(last($scope.payments));
  }

  function _cleanup() {
    $scope.stateManager.usePreviousCard = false;
    $scope.stateManager.addingNewCard = false;
    unset($scope, 'cardParams');
    unset($scope, 'selectedToken');
  }
}];
