
class SelectTimeGuest {

  constructor(private $scope: ng.IScope) {
    'ngInject';
  }
  
  public selectTime(time) {
    this.$scope.$emit('SELECTED_TIME', { time })
  }
}

export const SelectTimeGuestComponent = {
  bindings: {
    currentAvailability: '<',
    request: '<',
  },
  template: require('./select-time-guest.jade'),
  controller: SelectTimeGuest,
  bindToController: true
};