import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import price from 'common/dist/price';

import UserHelpers from 'common/dist/virtuals/User';
import moment from 'moment';

// Sixplus Types
import { ApiService } from 'spc/shared/api/api.service';
import { SpDownloadService } from 'spc/shared/sp-download.service';
import { RecosService } from 'spc/recos/recos.service';
import { makeRecommendation } from 'spc/utils/makeRecommendation';


class DownloadRecosController {
  lead: { _id: string, request: { date: Date } };
  fields: any[];
  DELIMITER: string = ', ';

  constructor(private $api: ApiService, private unwrapError, private SpDownloadService: SpDownloadService, private now, private recosService: RecosService) {
    'ngInject';
    this.fields = [
      { label: 'Venue', value: 'venue.data.name' },
      { label: 'Address', value: this.getAddress },
      { label: 'Neighborhood', value: 'venue.data.address.neighborhood' },
      { label: 'Cuisine', value: this.getCuisineTypes },
      { label: 'Space', value: 'space.data.name' },
      { label: 'Seated', value: 'space.data.capacity.seated.max' },
      { label: 'Standing', value: 'space.data.capacity.standing.max' },
      { label: 'F&B Minimum', value: this.getFbMin },
      { label: 'Room Fee', value: this.getRoomFee },
      { label: 'Estimated Total', value: this.getTotal },
      { label: 'Event Date', value: this.getProposalDate },
      { label: 'Status', value: reco => this.recosService.getStatus(reco, { considerClientResponse: false }) },
    ];
  }

  getClientName = (lead): string | undefined => {
    const client = get(lead, 'clients.0');
    if (client) {
     return UserHelpers.fullName(client);
    }
  }

  getCuisineTypes = (recommendation): string => {
    return recommendation.venue.data.cuisineTypes.join(this.DELIMITER);
  }

  getAddress = (recommendation): string => {
    const line1 = recommendation.venue.data.address.line1;
    const line2 = recommendation.venue.data.address.line2;
    const city = recommendation.venue.data.address.city;
    return [line1, line2, city]
      .filter(str => str)
      .join(this.DELIMITER);
  }

  getFbMin = (recommendation) => {
    return this.recosService.getFbMin({ recommendation, lead: this.lead }).message;
  }

  getRoomFee = (recommendation) => {
    const roomFeeCents = this.recosService.getRoomFee({ lead: this.lead, space: recommendation.space });
    return roomFeeCents ? (roomFeeCents / 100).toFixed(0) : null;
  }

  downloadRecos = () => {
    const eventDate = moment(this.lead.request.date).format('MM-DD-YYYY');
    return this.$api.Recos.getRecos(this.lead._id)
      .then(response => this.SpDownloadService.exportXLSXFile({ data: response.recommendations, fileTitle: `${eventDate} Event Recos`, fields: this.fields, sheetName: 'recos' }))
      .catch(error => this.unwrapError(error));
  }

  getLastAvailability = (recommendation): string => {
    return recommendation.lastAvailability.value;
  }

  getProposalDate = (recommendation): string => {
    const date = get(recommendation, 'conversation.request.data.date', null);
    if (!date) {
      return;
    }
    return moment(date, 'YYYY-MM-DD').format('MMM DD, YYYY');
  }

  getTotal = (recommendation) => {
    if (recommendation.conversation && recommendation.conversation.request) {
      return price.fullPriceBreakdownDollars(recommendation.conversation.request, true).total;
    }
  }
}

export const DownloadRecosComponent = {
  controller: DownloadRecosController,
  template: require('./download-recos.component.jade'),
  bindings: {
    lead: '<'
  }
};
