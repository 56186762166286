
/**
 * Converts URLs in the given text to HTML hyperlinks.
 *
 * @param {string} text - The text containing URLs to be converted.
 * @returns {string} The text with URLs replaced by HTML hyperlinks.
 */
export default function convertLinksToHyperlinks(text) {
  // Regular expression to match URLs in the text
  const urlPattern = /(((https?:\/\/)|(www\.))[^\s]+)/g;

  // Replace URLs with hyperlinks
  const newText = text.replace(urlPattern, function(url) {
    // Check if the URL starts with 'http' or 'https', if not, prepend 'https://'
    if (!url.startsWith('http')) {
      url = 'https://' + url;
    }
    // Return the hyperlink HTML
    return `<a style='color:#1765e3 !important' href="${url}" target="_blank">${url}</a>`;
  });

  return newText;
}
