export default ['ngDialog', function archiveListModal(ngDialog) {
  return function (archiveLists) {
    return ngDialog.open({
      template: '<archive-list archive-lists="$ctrl.archiveLists" close="$ctrl.close()"></archive-list>',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-plain info-modal',
      plain: true,
      overlay: false,
      closeByNavigation: true,
      controller: ['$scope', function ($scope) {
        this.archiveLists = archiveLists;
        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];
