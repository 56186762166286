// NPM Dependencies
import { size, isEmpty, cloneDeep } from 'lodash';
import { ApiService } from 'spc/shared/api/api.service';
import { ValidateVenueResponseBody, ValidationErrors } from 'server/api/venues/models';

export class VenueValidator {
  constructor(private $api: ApiService, private unwrapError) {
    'ngInject';
  }

  /**
   * Validate a venue with its spaces, menus and drinks
   *
   * @param   {Object}  venue   Venue document with or without spaces
   * @param   {Array}   spaces  Spaces that belongs to the venue
   * @param   {Array}   menus   Menus that belongs to the venue
   * @param   {Array}   drinks  Drinks that belongs to the venue
   * @return  {Object}
   * @api     public
   *
   */
  public validate(venue?, spaces?, menus?, drinks?) {

    const result: any =  {
      isAllValid: true,
      sections: {
        venue: { isAllValid: true },
        spaces: { isAllValid: true, isEmpty: true, valids: {}, invalids: {} },
        menus: { isAllValid: true, isEmpty: true, valids: {}, invalids: {} },
        drinks: { isAllValid: true, isEmpty: true, valids: {}, invalids: {} },
      }
    };

    return this.$api.Venues.validate({ venue, spaces, menus, drinks })
      .then((res: ValidateVenueResponseBody) => {
        if (res.venue) {
          result.sections.venue.isAllValid = isEmpty(res.venue);
        }

        if (res.spaces && !isEmpty(res.spaces)) {
          for (const [key, value] of Object.entries(res.spaces)) {
            if (value && value.errors) {
              result.sections.spaces.invalids[key] = value;
            } else {
              result.sections.spaces.valids[key] = { isVisible: value.isVisible };
            }
          }
          result.sections.spaces.isAllValid = isEmpty(result.sections.spaces.invalids);
          result.sections.spaces.isEmpty = false;
        } else {
          result.sections.spaces.isAllValid = false;
        }

        if (res.menus && !isEmpty(res.menus)) {
          for (const [key, value] of Object.entries(res.menus)) {
            if (value && value.errors) {
              result.sections.menus.invalids[key] = value;
            } else {
              result.sections.menus.valids[key] = { isVisible: value.isVisible };
            }
          }
          result.sections.menus.isEmpty = false;
          result.sections.menus.isAllValid = isEmpty(result.sections.menus.invalids);
        } else {
          result.sections.menus.isAllValid = false;
        }

        if (res.drinks && !isEmpty(res.drinks)) {
          for (const [key, value] of Object.entries(res.drinks)) {
            if (value && value.errors) {
              result.sections.drinks.invalids[key] = value;
            } else {
              result.sections.drinks.valids[key] = { isVisible: value.isVisible };
            }
          }
          result.sections.drinks.isEmpty = false;
          result.sections.drinks.isAllValid = isEmpty(result.sections.drinks.invalids);
        } else {
          result.sections.drinks.isAllValid = false;
        }


        const venueIsValid = result.sections.venue.isAllValid;
        const hasOneValidSpace = size(result.sections.spaces.valids);
        const hasOneValidHourlyPackage = size(result.sections.menus.valids) + size(result.sections.drinks.valids);
        const hasPreReqs = hasOneValidSpace && hasOneValidHourlyPackage;
        if (venue.status === 'Lite') {
          result.isAllValid = venueIsValid;
        } else {
          result.isAllValid = venueIsValid && hasPreReqs;
        }

        return result;
      })
    .catch(error => this.unwrapError(error));
  }

  // Display transparent error messages (for admin users) so it's more clear why validation is failing

  public displayInvalidFields(doc, schema, validateAdminFields) {
    const format = {
      Menu: 'menus',
      Space: 'spaces',
      Drink: 'drinks',
      Venue: 'venue'
    };

    const clone = cloneDeep(doc);

    // Even if venue is published, dont set clone status to Published when validating if user is not admin
    if (validateAdminFields) {
      clone.status = 'Published';
    } else {
      clone.status = 'Review';
    }

    const model = format[schema];

    let errorList;

    return this.$api.Venues.validate({ [model]: [clone] })
      .then((res: ValidateVenueResponseBody) => {
        if (res[model] && !isEmpty(res[model])) {
          errorList = [];

          const allErrors = res[model][clone._id.toString()].errors;

          if (allErrors) {
            errorList = Object.values(allErrors).map(error => error.message);
          }
          return errorList;
        }
      })
      .catch(error => this.unwrapError(error));
  }
}
