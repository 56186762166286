class TopLevelAdminController {
 constructor(private $rootScope, private $seo) {
   'ngInject';
 }

 componentIsReady = () => {
  this.$seo.set(`Companies`);
  this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
 }
}

export const topLevelAdminCompaniesComponent = {
  template: require('./top-level-admin-companies.component.jade'),
  controller: TopLevelAdminController
};
