import { includes } from 'lodash';
import { Manager } from './manager.model';

const mandatory = (param) => { throw new Error(`${ param } is a required field!`) };

export class TabManager implements Manager {
  tab: string;

  constructor(public tabs: string[] = mandatory(`tabs`), public defaultTab: string = mandatory('defaultTab')) { 
    this.checkTab(defaultTab);
    this.tab = defaultTab;
  }

  public switchTab(tab: string): string {
    const self = this;

    self.checkTab(tab);
    self.tab = tab;

    return tab;
  }

  public is(tab: string): boolean | string {
    return tab ? this.tab === tab : this.tab;
  }

  private checkTab(tab: string): void {
    const self = this;
    if (!includes(self.tabs, tab)) {
      throw new Error(`{ tab } is not a valid tab. Available tabs are ${ self.tabs.join(',') }`);
    }
  }


}
