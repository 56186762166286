import ENUMS from 'common/dist/enums';
import { get } from 'lodash';

export function getCityNameFromSubcity(venue) {
  const { city, state } = get(venue, 'data.address');
  const locData = get(venue, 'venueParentCity').find(_city => _city.city === city && _city.state === state);
  const cityValue = get(locData, 'parent.city') || get(locData, 'city');
  return getCityNameFromValue(cityValue);
}

export function getCityNameFromValue(CityValue) {
  const cityData = ENUMS.acceptableUserCities.find(city => city.value === CityValue);
  if (cityData) {
    return get(cityData, 'name');
  }
  return CityValue ? `Other ( ${CityValue} )` : '--';
}
