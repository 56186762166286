// External Deps
import * as angular from 'angular';

// Our Deps
import { TopLevelAdminConversationsDashboardComponent } from './top-level-admin-conversations-dashboard.component';
import { AdminConversationsDashboardComponent} from './admin-conversations-dashboard.component';
import { AdminConversationsService } from './admin-conversations.service';

export const AdminConversationsModule = angular.module('AdminConversationsModule', [])
  .component('topLevelAdminConversationsDashboard', TopLevelAdminConversationsDashboardComponent)
  .component('adminConversationsDashboard', AdminConversationsDashboardComponent)
  .service('adminConversationsService', AdminConversationsService)
  .name;
