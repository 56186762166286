// External Dependencies
import UserHelpers from 'common/dist/virtuals/User';

export default ['ngDialog', function(ngDialog) {
  return function ({ request, conversation }) {
    return ngDialog.open({
      template: `<add-clients request="$ctrl.request" conversation="$ctrl.conversation" close="$ctrl.close(data)"></add-clients>`,
      className: 'ngdialog-theme-small info-modal',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function ($scope) {
        this.request = request;
        this.conversation = conversation;
        this.close = (data) => {
          $scope.closeThisDialog(data);
        };
      }]
    }).closePromise;
  };
}];
