// NPM Dependencies
import * as DrinkRequestHelpers from 'common/dist/virtuals/DrinkRequest';
import each from 'lodash/each';
import get from 'lodash/get';
import last from 'lodash/last';
import unset from 'lodash/unset';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import round from 'lodash/round';

const fromCents = (amount) => {
  const dollars = amount / 100;
  return round(dollars, 2).toFixed(2);
};

/**
 * Component for selecting drinks for the given bookingRequest.
 *
 * @property {Array} drinks Array of drinks
 * @property {BookingRequest} bookingRequest
 * @property {Function} persistRequest Persist the request to server
 * @listens SELECT_DRINKS initializes view
 * @fires SELECTED_DRINKS Fired when user completes this step
 * @fires SKIPPED_SELECT_DRINKS Fired when user skips this step
 */

module.exports = function() {
  return {
    template: require('./select-drinks.jade'),
    scope: {
      venue: '<',
      drinks: '<',
      bookingRequest: '=',
      persistRequest: '&'
    },
    controller: ['$scope', 'drinksDetailModal', '$api', function($scope, drinksDetailModal, $api) {
      $scope.selectedDrinks = {};
      $scope.numSelectedDrinks = 0;
      $scope.durationOptions = [];
      $scope.drinksDetailModal = drinksDetailModal;
      $scope.loading = true;

      /**
       * Convert the Drinks into an array of DrinkRequests
       */

      const initData = function() {
        if (!$scope.bookingRequest || isEmpty($scope.bookingRequest)) {
          return;
        }
        $scope.durationOptions = [];
        $scope.drinks = $scope.drinks
          .filter(drink => drink.isVisible);

        $api.Venues.validate({ drinks: $scope.drinks })
          .then((res) => {
            const drinkErrors = res.drinks;
            $scope.drinks
              .forEach((drink, i) => {
                calculateDurationOptions(drink);
                $scope.drinks[i].$uiData = {
                  duration: $scope.drinks[i].data.duration,
                  numGuests: $scope.bookingRequest.data.groupSize
                };
                $scope.drinks[i].$validationError = get(drinkErrors, `${$scope.drinks[i]._id.toString()}.errors`, null) ? get(drinkErrors, `${$scope.drinks[i]._id.toString()}`)  : null ;
                updateDrinkPrice($scope.drinks[i]);
              });

            const alreadySelected = get($scope, 'bookingRequest.data.drinks');
            each(alreadySelected, function (drink, index) {
              ++$scope.numSelectedDrinks;
              $scope.selectedDrinks[drink._id.toString()] = drink;
            });
            $scope.loading = false;
          })
          .catch(error => unwrapError(error));
      };

      function getMaxDuration (drink) {
        const bookingRequestDuration = get($scope, 'bookingRequest.data.duration');
        const payAtTheVenue = drink.data.payAtTheVenue;
        const hasExtraHourFee = drink.data.extraHourFeeInCents;
        const additionalHourFees = drink.data.additionalHourFees;

        if (payAtTheVenue || hasExtraHourFee) {
          return bookingRequestDuration;
        } else if (additionalHourFees.length) {
          return Math.min((additionalHourFees.length * 60) + drink.data.duration, +bookingRequestDuration);
        } else {
          return drink.data.duration;
        }
      }
      /**
       * @desc Some drink packages have a minimum duration but no extra hour fee.
       * In this case, we can't allow the duration to be longer than the minimum duration
       * @param drink
       */
      function calculateDurationOptions(drink) {
        const maxDuration = getMaxDuration(drink);

        const durations = [];
        for (let i = drink.data.duration; i <= maxDuration; i += 60) {
          durations.push({ value: i, display: Math.floor(i / 60) + 'hr' });
        }
        $scope.durationOptions.push(durations);
      }

      /**
       * Toggle whether the given drink object is selected
       *
       * @param {Drink} drink The drink to toggle
       * @param {Number} duration If adding, the duration to use
       * @param {Number} numGuests If adding, the num guests to use
       */

      $scope.select = function(drink, duration, numGuests) {
        if (drink.$validationError) {
          return;
        }
        const id = drink._id.toString();
        const drinksInRequest = $scope.bookingRequest.data.drinks;
        if ($scope.selectedDrinks[id]) {
          $scope.bookingRequest.data.drinks = drinksInRequest.filter(_drink => _drink._id.toString() !== id);
          unset($scope.selectedDrinks, id);
          --$scope.numSelectedDrinks;
        } else {
          const drinkToPush = {};
          const _drink = cloneDeep(drink);
          DrinkRequestHelpers.initFromDrink(drinkToPush, _drink, duration, numGuests);
          const priceDollars = +fromCents(drinkToPush.costPerGuestCents);
          set(drinkToPush, '$uiData.priceInDollars', priceDollars);
          set(drinkToPush, '$uiData.totalCostDollars', priceDollars * +drinkToPush.numGuests);
          drinksInRequest.push(drinkToPush);
          $scope.selectedDrinks[id] = last($scope.bookingRequest.data.drinks);
          ++$scope.numSelectedDrinks;
        }
        return $scope.persistRequest({ goNext: false });
      };

      /**
       * Create a new drinkRequest and run `validateSync()` on it
       *
       * @param {Drink} drink The drink to toggle
       */

      function  createDrinkRequest (drink) {
        const duration = get(drink, '$uiData.duration');
        const numGuests = get(drink, '$uiData.numGuests');
        const _drink = cloneDeep(drink);
        const drinkRequest = {};
        DrinkRequestHelpers.initFromDrink(drinkRequest, _drink, duration, numGuests);
        drinkRequest.parent = function() {
          return $scope.bookingRequest;
        };
        return drinkRequest;
      }

      $scope.validateDrinkRequest = function(drink) {
        $api.Drinks.validate(drink)
          .then((res) => {
            if (isEmpty(res)) {
              return null;
            }
            return res;
          })
          .catch(error => unwrapError(error));
      };

      function updateDrinkPrice (drink) {
        const drinkRequest = createDrinkRequest(drink);
        const priceDollars: number = +fromCents(drinkRequest.costPerGuestCents);
        set(drink, '$uiData.priceInDollars', priceDollars);
        set(drink, '$uiData.totalCostDollars', priceDollars * +drinkRequest.numGuests);

        // drink.$uiData.priceInDollars = +(+drinkRequest.costPerGuestCents / 100).toFixed(2) * (drink.selectedDurationMinutes / 60);
        // drink.$uiData.totalCostDollars = ((drinkRequest.numGuests * drinkRequest.costPerGuestCents) / 100).toFixed(2) ;
      }

      $scope.$on('CHANGED_DRINK_DURATION', function(ev, drink) {
        drink.$validationError = $scope.validateDrinkRequest(drink);
        if (drink.$validationError) {
          return;
        }
        updateDrinkPrice(drink);
      });
      $scope.$on('CHANGED_DRINK_NUM_GUESTS', function(ev, drink) {
        drink.$validationError = $scope.validateDrinkRequest(drink);
        updateDrinkPrice(drink);
      });

      $scope.getTooltipMessage = function(error) {
        if (!error) {
          return;
        }
        if (error.errors['selectedDurationMinutes']) {
          return 'Please select a duration!';
        }
        if (error.errors['numGuests']) {
          return 'Please enter a number of guests!';
        }
      };

      /**
       * Persist this request to the server, and emit the correct event on
       * success.
       */

      $scope.continue = function() {
        return $scope.persistRequest({ goNext: true }).then(function() {
          if ($scope.numSelectedDrinks > 0) {
            $scope.$emit('SELECTED_DRINKS', $scope.bookingRequest);
          } else {
            $scope.$emit('SKIPPED_SELECT_DRINKS', $scope.bookingRequest);
          }
        });
      };

      initData();
      $scope.$on('SELECT_DRINKS', function() {
        initData();
      });
      $scope.$on('CREATED_REQUEST', function() {
        initData();
      });
    }]
  };
};
