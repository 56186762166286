import availabilityCalendarHelpers from 'common/dist/virtuals/AvailabilityCalendar';
import { getEndTimeFromStartTimeAndDuration } from 'common/dist/time';
import cloneDeep from 'lodash/cloneDeep';

import moment from 'moment';
/**
 * Select a start time and duration (by selecting an end time) for a request.
 * This directive presumes that a request has a selectedSpace and should not be
 * instantiated without one or else it will error.
 */

class SelectTimeHostController {
  calendar: any;
  space: any;
  date: string;
  events: any[];
  conflictingEvent: any;
  time: number;
  duration: number;

  constructor() {
    'ngInject';
  }

  $onChanges = (changes) => {
    this.setEvents();
  }

  setEvents = () => {
    const currentEvent = {
      startTime: this.time,
      endTime: getEndTimeFromStartTimeAndDuration(this.time, this.duration),
      currentEvent: true
    };

    const formattedTimeSlots = this.space.data.timeSlots
      .filter(slot => slot.days[moment.utc(this.date, 'YYYY-MM-DD').day()])
      .map((slot) => {
        return { startTime: slot.from, endTime: slot.to > 2400 ? slot.to - 2400 : slot.to, name: slot.name };
      });

    const events = cloneDeep(availabilityCalendarHelpers.getEvents({ calendar: this.calendar, date: this.date }));

    // find a conflict within the existing events
    this.conflictingEvent = availabilityCalendarHelpers.findConflict({ events, times: currentEvent });

    this.events = events.concat(formattedTimeSlots);
    // add the current event to the list
    this.events.push(currentEvent);

    this.events.sort((a, b) => {
      if (a.startTime > b.startTime) {
        return 1;
      } else if (a.startTime < b.startTime) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  isConflicting = (event) => {
    if (!this.conflictingEvent || event.name || event.currentEvent) {
      return false;
    }
    return event._id.toString() === this.conflictingEvent._id.toString();
  }
}

// TODO : Implement changeDate (from ctrl.$on('CHANGE_DATE'))
export default {
  controller: SelectTimeHostController,
  bindings: {
    calendar: '<',
    date: '<',
    space: '<',
    time: '<',
    duration: '<'
  },
  template: require('./select-time-host.jade'),
};
