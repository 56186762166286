import * as angular from 'angular';

import { ListsDashboardComponent } from './lists-dashboard.component';
import { CreateListComponent } from './create-list/create-list.component';
import { ArchiveListComponent } from './archive-list/archive-list.component';
import { TopLevelListsComponent } from './top-level-lists.component';
import { AddVenueComponent } from './add-venue/add-venue.component';
import { IndividualListComponent } from './individual-list/individual-list.component';
import { ListVenueSearchComponent } from './add-venue/venue-search/list-venue-search.component';
import { EditListComponent } from './edit-list/edit-list.component';
import { AddListCollaboratorsComponent } from './add-list-collaborators/add-list-collaborators.component';
import { RemoveListCollaboratorsComponent } from './remove-list-collaborators/remove-list-collaborators.component';
import { ListVenueViewComponent } from './venue-view/list-venue-view.component';
import { ListMapComponent } from './map/list-map.component';
import { ListMapService } from './map/list-map.service';
import { ListOrderComponent } from './list-order-modal/list-order-component';
import { ListCardComponent } from './list-card/list-card.component';
import { TeamListCardViewComponent } from './team-list-card-view/team-list-card-view.component';
import { listOrderModal } from './list-order-modal/list-order.modal';
import { CreateDuplicateListComponent } from './create-duplicate-list/create-duplicate-list.component';
import createDuplicateListModal from './create-duplicate-list/create-duplicate-list.modal';
import CreateListModal from './create-list/create-list.modal';
import ArchiveListModal from './archive-list/archive-list.modal';
import AddVenueToListModal from './add-venue/add-venue.modal';
import ListVenueSearchModal from './add-venue/venue-search/list-venue-search.modal';
import EditListModal from './edit-list/edit-list.modal';
import AddListCollaboratorsModal from './add-list-collaborators/add-list-collaborators.modal';
import RemoveListCollaboratorsModal from './remove-list-collaborators/remove-list-collaborators.modal';

import ListVenueViewModal from './venue-view/list-venue-view.modal';
import listShareModal from './list-share/list-share.modal';

export const ListsModule = angular.module('ListsModule', [])
  .component('topLevelLists', TopLevelListsComponent)
  .component('listsDashboard', ListsDashboardComponent)
  .component('createList', CreateListComponent)
  .component('archiveList', ArchiveListComponent)
  .component('addVenueToList', AddVenueComponent )
  .component('individualList', IndividualListComponent)
  .component('listVenueSearch', ListVenueSearchComponent)
  .component('editList', EditListComponent)
  .component('addListCollaborators', AddListCollaboratorsComponent)
  .component('removeListCollaborators', RemoveListCollaboratorsComponent)
  .component('listVenueView', ListVenueViewComponent)
  .component('listMap', ListMapComponent)
  .component('listOrder', ListOrderComponent)
  .component('createDuplicateList', CreateDuplicateListComponent)
  .component('listCard', ListCardComponent)
  .component('teamListCardView', TeamListCardViewComponent)
  .service('createListModal', CreateListModal)
  .service('createDuplicateListModal', createDuplicateListModal)
  .service('archiveListModal', ArchiveListModal)
  .service('listShareModal', listShareModal)
  .service('addVenueToListModal', AddVenueToListModal)
  .service('listVenueSearchModal', ListVenueSearchModal)
  .service('editListModal', EditListModal)
  .service('addListCollaboratorsModal', AddListCollaboratorsModal)
  .service('removeListCollaboratorsModal', RemoveListCollaboratorsModal)
  .service('listVenueViewModal', ListVenueViewModal)
  .service('listOrderModal', listOrderModal)
  .service('listMapService', ListMapService)
  .name;
