import { RawVenue } from 'spc/lib/database/types/venue/venue';

class DrinkPackagesController {
  venue: RawVenue;
  drinks: {
    data: {
      courses: any[]
    }
  };

  constructor() {
    'ngInject';
  }
}

export const drinkPackagesComponent = {
  controller: DrinkPackagesController,
  template: require('./drink-packages.component.jade'),
  bindings: {
    drinks: '<',
  }
};
