import { queryHelpers } from '../../utils/query';
import { get, merge } from 'lodash';
// SP Interfaces
import { HTTPResponse } from './models';
import {
  GetEventsQuery,
  QueryRole,
  VenueAcceptProposalRequestBody,
  PutRequestHostResponse,
  GetVenueEventsResponse,
  GetConversationResponse,
  GetConversationResponseData,
  ReviewData,
  GetRequestResponse
} from 'server/api/requests/models';
import { UpdateRequest, UpdateResponse, UpdatePathOp } from 'shared/interfaces/admin/events/update-request.model';
import { QuillInsert } from 'server/api/conversations/models';
import { ObjectID } from 'mongodb';
import { RawConversation } from 'spc/lib/database/types/conversation';
import { TransmittedAttachment } from 'spc/lib/database/types/attachments';
import { RawBookingRequest } from 'spc/lib/database/types/booking-request';

export class Requests {
  BASE_URL: string;
  VENUE_BASE_URL: string;

  constructor(API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${API_ROOT}/requests`;
    this.VENUE_BASE_URL = `${ this.BASE_URL }/venue`;
  }

  public accept = ({ id, message, attachments }: {
    id: string | ObjectID,
    message: { message: string, quillFormat: QuillInsert[] }, attachments
  }) => {
    const url = `${ this.VENUE_BASE_URL }/accept/${ id.toString() }`;
    const data: VenueAcceptProposalRequestBody = { data: message, attachments };
    return this.$http.put(url, data);
  }

  public acceptProposal = ({ id, message, attachments }: {
    id: string,
    message: {message: 'string', quillFormat: QuillInsert[]}, attachments: TransmittedAttachment[]
  }) => {
    return this.$http.put(`${this.VENUE_BASE_URL}/proposal/accept/${id}?submit=1`,
      { data: message, attachments });
  }
  public acceptProposalOffline = ( id, newOfflineData  ) => {
    return this.$http.put(`${this.BASE_URL}/proposal/accept/offline/${id}`,
      { data: newOfflineData, });
  }

  public checkCounteroffer = (requestId: string) => {
    return this.$http.get(this.BASE_URL + '/request/' + requestId + '/deltas');
  }

  public confirm = (requestId: string, gratuityPercentage: number, primaryClient: string) => {
    return this.$http.put(this.BASE_URL + '/client/confirm/' + requestId,
      { gratuityPercentage, primaryClient });
  }

  public conversation = (conversation: RawConversation | string): ng.IPromise<GetConversationResponseData> => {
    const id = get(conversation, '_id', conversation);
    const url = `${this.BASE_URL}/conversation/${id}`;
    return this.$http.
      get(url)
      .then((res: HTTPResponse<GetConversationResponse>)  => res.data as GetConversationResponseData);
  }

  public counteroffer = (id: string, delta: any, message?: string) => {
    return this.$http.put(this.VENUE_BASE_URL + '/counteroffer/' + id,
      { data: { message }, delta });
  }

  public create = (venueId: string, request: any) => {
    return this.$http.post(this.BASE_URL + '/request/' + venueId,
      { request });
  }

  public getReview = (conversation: string) => {
    return this.$http.get(`${this.BASE_URL}/request/${conversation}/review`)
      .then(res => res.data);
  }

  public submitReview = ({ conversation, review }: { conversation: string, review: ReviewData }) => {
    return this.$http.post(`${this.BASE_URL}/request/${conversation}/review`, { review })
      .then(res => res.data);
  }

  public deleteHostCounteroffer = (requestId: string) => {
    return this.$http.delete(this.VENUE_BASE_URL + '/counteroffer/' +
      requestId);
  }

  public getClients = () => this.$http.get(`${this.VENUE_BASE_URL}/clients`);

  public getEvents = (userOptions: { from: QueryRole }, queryParams: GetEventsQuery): ng.IPromise<GetVenueEventsResponse> => {
    return this.$http
      .get(`${this.BASE_URL}/${userOptions.from}`, { params: queryParams })
      .then((response: HTTPResponse<GetVenueEventsResponse>) => response.data);
  }

  public getPreview = ({ conversation, user }: { conversation: string, user: string }) => {
    return this.$http.get(`${this.BASE_URL}/conversation/${conversation}/preview`);
  }

  public getRequest = (requestId: string): ng.IPromise<{ data: GetRequestResponse }> => {
    return this.$http.get(this.BASE_URL + '/request/' + requestId) as ng.IPromise<{ data: GetRequestResponse }>;
  }

  public guestCancel = (requestId: string, message: string) => {
    const url = this.BASE_URL + '/client/cancel/' + requestId;
    return this.$http.put(url, { message });
  }

  public cancelRequest = (requestId: string) => {
    const url = this.BASE_URL + '/client/cancelRequest/' + requestId;
    return this.$http.put(url, {});
  }

  public venueDecline = ({
    terminationReason,
    requestId,
    data,
    attachments,
    requestTerminationState,
    blockCalendar }: {
      terminationReason: string;
      requestId: string;
      attachments: TransmittedAttachment[]
      data: { message: 'string', quillFormat: QuillInsert[] };
      requestTerminationState: string;
      blockCalendar: boolean;
    }) => {
    return this.$http.put(this.VENUE_BASE_URL + '/decline/' + requestId,
      { terminationReason, data, attachments, requestTerminationState, blockCalendar });
  }

  public postProposal = (request, client, venueId: string) => {
    return this.$http.post(`${this.VENUE_BASE_URL}/proposal/${venueId}`, { request, client });
  }

  public putGratuity = (requestId: string, gratuityPercentage: number) => {
    return this.$http.put(this.BASE_URL + '/client/gratuity/' + requestId,
      { gratuityPercentage });
  }

  public save = ({ request, message, submit, user, leadName }: { request: any, message?: string, submit?: boolean, user?: any, leadName?: string }) => {
    let url = this.BASE_URL + '/request/' + request._id;
    if (submit) {
      url += '?submit=1';
    }
    return this.$http.put(url, { request, message, user, leadName });
  }

  public updateDirectlyAsHost = (request) => {
    const update = request.toObject ? request.toObject({ virtuals: false }) : request;
    return this.$http
      .put(`${this.VENUE_BASE_URL}/request/${request._id}`, { request: update })
      .then((response: HTTPResponse<PutRequestHostResponse>) => response.data);
  }


  public emailClient = ({ request, recipients }) => {
    const url = `${this.BASE_URL}/conversation/${request.conversation}/email`;
    return this.$http
      .post(url, { request, recipients });
  }

  public saveReceipts = ({ request,  pathsAndValues }: { request: RawBookingRequest, pathsAndValues: UpdatePathOp[] | UpdatePathOp }): ng.IPromise<UpdateResponse> => {
    const paths = Array.isArray(pathsAndValues) ? pathsAndValues : [pathsAndValues];
    const url = `${this.BASE_URL}/${request._id}/receipt`;
    const reqBody: UpdateRequest = { paths };

    return this.$http.put(url, reqBody)
      .then((response: { data: UpdateResponse }) => {
        return response.data;
      });
  }

  public putPayment({ request, identifier, cardParams }: { request: RawBookingRequest, identifier: { nonce?: any, token?: any }, cardParams?: { cardholderName: string } }) {
    const self = this;
    const body = identifier;
    merge(body, cardParams);

    const url = `${self.BASE_URL}/${request._id}/payment`;
    return self.$http.put(url, body);
  }
}

