const ALLOWED_WINELIST_FILE_TYPES = ['.jpg', '.png', '.jpeg', '.gif', '.pdf', '.doc', '.docx'];
export default ['ngDialog', function (ngDialog) {
  return function (venue) {
    return ngDialog.open({
      template: require('./edit-winelists.modal.jade'),
      className: 'ngdialog-theme-plain',
      overlay: false,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', '$cloudinary', '$api', 'unwrapError', '$timeout', function ($scope, $cloudinary, $api, unwrapError, $timeout) {

        ///// Initialization function
        this.init = () => {
          this.venue = venue;
          this.allowedFileTypes = ALLOWED_WINELIST_FILE_TYPES;
          this.winelists = venue.data.winelists || [];
          this.buttonText = `I'm done`;
          this.determineCanSubmit();
        };

        ///// Listeners
        $scope.$on('PHOTO_UPLOADING', () => {
          this.buttonText = 'Uploading...';
          this.determineCanSubmit();
        });

        $scope.$on('PHOTO_UPLOADED', () => {
          this.buttonText = `I'm finished`;
          this.determineCanSubmit();
        });

        $scope.$on('PHOTO_REMOVED', (ev, params) => {
          ev.stopPropagation();
          const url = params.url;
          this.winelists = this.winelists.filter(winelist => winelist.url !== url);
          this.determineCanSubmit();
        });

        this.savePhotos = (winelists) => {
          this.venue.data.winelists = winelists;
          this.winelists = winelists;
          this.determineCanSubmit();
        };

        /**
         * Finishes and closes ngDialog
         *
         * @return {Promise}
         */
        this.finish = () => {
          this.determineCanSubmit();

          if (!this.canSubmit) {
            return;
          }

          return $api.Venues
            .save({ 'data.winelists': this.venue.data.winelists, slug: this.venue.slug })
            .then(() => $scope.closeThisDialog())
            .catch(error => unwrapError(error));
        };

        /**
         * Determines if user can click submit button
         *
         * @return {Boolean}
         */
        this.determineCanSubmit = () => {
          const invalid = this.venue.data.winelists.find(list => !list.caption);

          if (invalid || this.buttonText === 'Uploading...') {
            this.canSubmit = false;
            return;
          }

          this.canSubmit = true;
        };

        this.init();
      }]
    }).closePromise;
  };
}];
