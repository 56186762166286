import { each, get, isNil } from 'lodash';
import { RawLead } from 'spc/lib/database/types/lead';
import { RawBaseUser } from 'spc/lib/database/types/base-user';

const SP_DATE_FORMAT = 'ddd. MMM D, YYYY';

export class ConciergeService {
  lead: RawLead;
  client: RawBaseUser;
  /**
   * Generates the analytics object to be sent off to third-party analytics services
   *
   * @api public
   * @param {Object} lead object
   * @return {Object}
   */
  generateFormSubmissionAnalyticsObject({ lead, user }) {
    const analyticsRequestPaths = [
      'mealType',
      'occasion',
      'privacy',
      'budgetPerGuest',
      'totalEventBudgetDollars',
      'date',
      'time',
      'duration',
      'additionalInfo'
    ];
    const eventParams = {
      email: get(user, 'profile.email'),
      fullName: get(user, 'profile.name.first') + ' ' + get(user, 'profile.name.last'),
      numGuestsMin: get(lead, 'request.numGuests.min'),
      numGuestsMax: get(lead, 'request.numGuests.max'),
      audioVisualNecessary: get(lead, 'request.AV.selected'),
      audioVisualInformation: get(lead, 'request.AV.information'),
      heardAbout: lead.heardAbout,
      referrerService: get(lead, 'referrer.service'),
      referrerUrl: get(lead, 'referrer.url'),
      phone: get(user, 'profile.phone')
    };
    each(analyticsRequestPaths, function(path) {
      if (!isNil(lead.request[path])) {
        eventParams[path] = get(lead.request, path);
      }
    });

    return eventParams;
  }

  humanizeDate = (date) => {
    return date.format(SP_DATE_FORMAT);
  }

  setLeadAndClient = ({ lead, client }) => {
    this.lead = lead;
    this.client = client;
  }

  getLeadAndClient = (): { lead: any, client: any } => {
    return { lead: this.lead, client: this.client };
  }

  startsWith = (str, test) => {
    return str.slice(0, test.length) === test;
  }
}
