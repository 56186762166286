// NPM Dependencies
import * as angular from 'angular';
import { AdminSettingsComponent } from './admin-settings.component';
import { EditFeaturedVenuesComponent } from './edit-featured-venues.component';
import editFeaturedVenuesModal from './edit-featured-venues.modal';
import { editLocationsComponent } from './edit-locations.component';
import { editCitiesComponent } from './edit-cities.component';
import { organiseExperienceModal } from '../virtual-events/organiseExperience/organise-experience.modal';
import { OrganiseExperienceComponent } from '../virtual-events/organiseExperience/organise-experience.component';

export default angular.module('admin.settings', [])
  .component('adminSettings', AdminSettingsComponent)
  .component('editFeaturedVenues', EditFeaturedVenuesComponent)
  .component('editLocations', editLocationsComponent)
  .component('editCities', editCitiesComponent)
  .component('organiseExperienceComponent', OrganiseExperienceComponent)
  .service('organiseExperienceModal', organiseExperienceModal)
  .service('editFeaturedVenuesModal', editFeaturedVenuesModal)
  .name;
