class TopLevelPersonalInboxController {
  canInit: boolean;
  role: 'venue' | 'client';

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $seo,
    private $user) { 'ngInject'; }

  $onInit() {
    this.$user.$waitFor('LOGGED_IN', () => {
      this.canInit = true;
    });

    this.$user.$waitFor('NOT_LOGGED_IN', () => {
      this.$location.path(`/404`);
    });
  }
  componentIsReady = () => {
    this.$rootScope.$emit('$viewReady', 'SHOW_FOOTER');
  }

  setSeo = (numRequests) => {
    const title = `${ numRequests } Pending Requests`;
    this.$seo.set(title);
  }
}

export const TopLevelPersonalInboxComponent = {
  template: require('./top-level-personal-inbox.component.jade'),
  controller: TopLevelPersonalInboxController,
  bindings: {
    role: '@'
  }
};
