import TopLevelComponent from '../../utils/top-level-component';

let ctrl;

class HomeIndexComponent extends TopLevelComponent {
  constructor($seo, ENUMS, $rootScope, $location, $analytics) {
    'ngInject';
    super($rootScope, $location);
    ctrl = this;
    ctrl._seo = $seo;
    ctrl._ENUMS = ENUMS;
  }

  $onInit() {
    ctrl._seo.set(null, ctrl._ENUMS.metaDescriptions.home);
    ctrl.componentIsReady();
  }
}

export default {
  bindings: {
    version: '<'
  },
  template: require('./home-index.jade'),
  controller: HomeIndexComponent
};

