import { debounce } from 'lodash';
import { ApiService } from '../../shared/api/api.service';
import { DExperience } from '../../../../database/types/virtual-events';
import { EXPERIENCE_TYPE } from '../../../../database/constants/Experience';
import { ExperienceQuery } from '../../../../database/types/virtual-events';

// constants
import { SEARCH } from '../../constants/search.constant';

export interface SearchParams {
  $text?: { $search: string };
  domains?: string[];
}

interface UI {
  loading: boolean;
}

class AdminExperienceDashboardController {
  ready: () => void;
  ui: UI = {
    loading: false
  };
  experiences: DExperience[];
  numExperiences: number = 0;
  documentsPerPage: number = 50;
  currentPage: number = 0;
  experienceTypes = EXPERIENCE_TYPE;
  textParams: string = '';
  type: string = '';
  guestCount: number = null;
  displayFilterSidebar?: boolean;

  constructor(private $api: ApiService, private unwrapError) {
    'ngInject';
  }

  $onInit = () => {
    this.getExperiences();
  }

  getExperiences = (page = 0) => {
    this.ui.loading = true;
    const query = this.queryHandler(page);
    return this.$api.Admin.Experience.getAll({ query })
      .then((response) => {
        this.experiences = response.experiences;
        this.ui.loading = false;
        this.numExperiences = response.numExperiences;
        this.currentPage = page + 1;
        this.ready();
      }).catch ((error) => {
        this.ui.loading = false;
        this.unwrapError(error);
        this.ready();
      });
  }

  toggleFilterSidebar = () => {
    this.displayFilterSidebar = !this.displayFilterSidebar;
  }

  selectTypeHandler() {
    this.getExperiences();
  }

  onPageChange = (page) => {
    return this.getExperiences(page);
  }

  searchHandler = debounce(this.getExperiences, SEARCH.DEBOUNCE);

  queryHandler = (page) => {
    const query: ExperienceQuery = {
      page,
      documentsPerPage: this.documentsPerPage,
      showAll: true
    };
    if (this.textParams.length) {
      query.textParams = this.textParams;
    }
    if (this.guestCount) {
      query.guestCount = this.guestCount;
    }
    if (this.type.length) {
      query.type = this.type;
    }
    return query;
  }

  delete = (experienceId) => {
    this.$api.Admin.Experience.deleteExperienceById({ id: experienceId })
    .then((res) => {
      this.getExperiences();
    })
    .catch((error) => {
      this.unwrapError(error);
    });
  }
}

export const adminExperienceDashboardComponent = {
  template: require('./admin-experience-dashboard.component.jade'),
  controller: AdminExperienceDashboardController,
  bindings: {
    ready: '<'
  }
};
