import * as angular from 'angular';

// Components
import { AdminProposalsDashboardComponent } from './admin-proposals-dashboard.component';
import { DownloadEventsComponent } from './download-events.component';
import { EventDataSidebarComponent } from './event-data-sidebar.component';
import { FilterSidebarComponent } from './filter-sidebar.component';
import { AdminEventsService } from './admin-events.service';
import { EventCancelComponent } from './event-cancel/event-cancel.component';
import { replaceCardModal } from './replace-card/replace-card.modal';
import { replaceCardComponent } from './replace-card/replace-card.component';

export default angular.module('admin.events', [])
  .component('adminEventsDashboard', AdminProposalsDashboardComponent)
  .component('eventDataSidebar', EventDataSidebarComponent)
  .component('filterSidebar', FilterSidebarComponent)
  .component('downloadEvents', DownloadEventsComponent)
  .component('eventCancel', EventCancelComponent)
  .component('replaceCard', replaceCardComponent)
  .service('adminEventsService', AdminEventsService)
  .service('replaceCardModal', replaceCardModal)
  .name;
