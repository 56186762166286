import { get, merge } from 'lodash';

export interface TooltipItem {

  /**
   * X value of the tooltip as a string
   */
  xLabel?: string;

  /**
   * Y value of the tooltip as a string
   */
  yLabel?: string;
  
  /**
   * Index of the dataset the item comes from
   */
  datasetIndex?: number;

  /**
   * Index of this data item in the dataset
   */
  index?: number

  /**
   * X position of matching point
   */
  x?: number;

 /**
  * Y position of matching point
  */
  y?: number;
}

export interface ChartOptions {
  title: string;

  legend?: {
    position: string;
    display?: boolean;
    onClick?: (ev, legendItem) => void
  };

  elements?: any;

  scales?: {
    yAxes: any[]
  }
  maintainAspectRatio?: boolean
  responsive?: boolean

  tooltips?: {
    enabled: boolean;
    callbacks?: {
      label: (tooltipItem: TooltipItem, data: any) => string;
    }
  }
}

export class ComposeChartService {
  constructor() { }

  public createChartOptions(options: ChartOptions): any {
    return {
      title: {
        display: true,
        text: options.title
      },
      legend: merge({ display: true }, options.legend)
    }
  }
}