import ENUMS from 'common/dist/enums';
import { get } from 'lodash';
import unwrapError from 'spc/services/unwrapError';

export default function fetchCitiesAndSubcities($api) {
  return $api.Landing.getLocation()
    .then(({ neighborhoodsMap }) => {
      const citySubcityMap = {};
      for (const parentCity in neighborhoodsMap) {
        const parentCityName = get(ENUMS.acceptableUserCities.find(city => city.value === parentCity), 'name');
          if (parentCityName) {
            citySubcityMap[parentCityName] = neighborhoodsMap[parentCity];
          }
        }
      return citySubcityMap;
    })
    .catch(error => unwrapError(error));
}
