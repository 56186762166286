
const addAccountMemberModal = ['ngDialog', function(ngDialog) {

  return function({ user, account, memberLimit }) {
    return ngDialog.open({

      template: '<add-account-member user="$ctrl.user" account="$ctrl.account" member-Limit="$ctrl.memberLimit" close="$ctrl.close"></add-account-member>',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small micro-modal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.user = user;
        this.account = account;
        this.memberLimit = memberLimit;
        this.close = (response) => {
          $scope.closeThisDialog(response);
        };
      }],
    }).closePromise;
  };
}];
export default addAccountMemberModal;
