export const requestCancelModal = ['ngDialog', function(ngDialog) {
  return function({ request, conversation, venue })  {
    return ngDialog.open({
      template: `
      <request-cancel
        request="$ctrl.request"
        conversation="$ctrl.conversation"
        venue="$ctrl.venue"
        close="$ctrl.close()">
      </request-cancel>`,
      className: 'ngdialog-theme-small info-modal',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function($scope) {
        this.request = request;
        this.conversation = conversation;
        this.venue = venue;
        this.close = function() {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];
