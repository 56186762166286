import { ApiService } from 'spc/shared/api/api.service';
import _isEmpty from 'lodash/isEmpty';

class ClientHistoryController {
  client: any;
  history: any;

  constructor(private $api: ApiService, private unwrapError) {
    'ngInject';
  }

  isEmpty = _isEmpty;

  $onInit = () => {
    const id = this.client._id ? this.client._id.toString() : this.client.toString();
    this.$api.Admin.Users.history(id)
      .then(response => this.history = response.history)
      .catch(error => this.unwrapError(error));
  }

  cityToAbbreviation = (city) => {
    if (city === 'New York') {
      return 'NYC';
    } else if (city === 'Washington') {
      return 'DC';
    } else {
      return city;
    }
  }

  getSortedKeys = (obj) => {
    return Object.keys(obj)
      .sort();
  }
}

export const ClientHistoryComponent = {
  controller: ClientHistoryController,
  template: require('./client-history.component.jade'),
  bindings: {
    client: '<'
  }
};
