// NPM Dependencies
import { filter, each, get, groupBy, map, last, keys, round, sumBy, sortBy, transform, values, zipWith } from 'lodash';
import moment from 'moment';

// Types
import { MappedEventResult } from './mapped-event-result.model';
import { EventResult } from 'shared/interfaces/metrics/event-result.model';
import { TimeOptions } from './time-options.model';

type EmptyEvent = { [total: string]: number };

export type DataMap = { [date: string]: EventResult[]}[];

export type TimeLabels = { label: string, isoDate: string }[];

export interface DateGroupedEvents {
  [date: string]: EventResult[] | any;
}

/**
 * Stateless service that maps data for the various dashboard charts
 */
export class ProposalMetricsDataService {
  constructor() { 'ngInject'; }
  DATE_FORMAT = 'YYYY-MM-DD';

  /**
   * Essentially a constant, this maps time chunk strategies to a date parsing format
   */
  chunkStrategyToParseMap = {
    'Weekly': 'MMM Do',
    'Monthly': 'MMM',
    'Yearly': 'YYYY',
    'Quarterly': 'YYYY-MM-DD'
  };

  public transformDataForGraph(options: { data: MappedEventResult, timeOptions: TimeOptions }): { cities: string[], labels: string[], events: EventResult[][]} {
    const self = this;
    const results = self.unzipEventsMap(options.data);

    const cities = results.x;
    const labels = self.createTimeLabels(options.timeOptions);

    return { cities, labels, events: results.y }
  }

  public mapData(options: { data: EventResult[][], labels: string[], timeOptions: TimeOptions, summer: (result: EventResult[]) => number }) {
    const self = this;

    const timeMappedEvents = self.mapTimes(options.data, options.labels, options.summer);

    /**
     * Note: mutates timeMappedEvents.events
     */
    each(timeMappedEvents.events, function (bucket) {
      each(options.labels, label => {
        if (!bucket[label]) {
          bucket[label] = 0;
        }
      });
    });

    const eventsToArray = transform(timeMappedEvents.events, function (acc, dateMap, idx) {
      const totalsList = map(dateMap, (total, date) => {
        return { date, total };
      });

      acc.push(sortBy(totalsList, 'date'));
    }, []);

    const final = map(eventsToArray, results => {
      // does not use mapper
      return map(results, 'total');
    });

    return { events: final, labels: self.formatLabelsByTimeChunkStrategy(options.labels, options.timeOptions.chunkStrategy), dataMap: timeMappedEvents.dateMaps };
  }

  /**
   * Takes an object and unzips it, splitting keys and values into separate arrays
   */
  private unzipEventsMap(obj: MappedEventResult) {
    const bucket: { x: string[], y: EventResult[][] } = { x: [], y: [] };

    each(obj, (value, key: string) => {
      bucket.x.push(key);
      bucket.y.push(value);
    });

    return bucket;
  }

  private formatLabelsByTimeChunkStrategy(labels: string[], chunkStrategy: string): TimeLabels {
    const self = this;
    return map(labels, label => ({ label: moment(label).format(self.chunkStrategyToParseMap[chunkStrategy]), isoDate: label }));
  }

  /**
   * Given a set of configuration options, creates an array of time labels, formatting them based
   * on the time chunk strategy
   */
  private createTimeLabels(timeOptions: TimeOptions): string[] {
    const self = this;
    const strategy = timeOptions.chunkStrategy;
    switch (strategy) {
      case 'Weekly':
        return self.walkTimeInterval(timeOptions.startDate, timeOptions.endDate, 'weeks');
      case 'Monthly':
        return self.walkTimeInterval(timeOptions.startDate, timeOptions.endDate, 'months');
      case 'Yearly':
        return self.walkTimeInterval(timeOptions.startDate, timeOptions.endDate, 'years');
      case 'Quarterly':
        return self.walkTimeInterval(timeOptions.startDate, timeOptions.endDate, 'quarters');
    }
  }

  private mapTimes(ev2Array: EventResult[][], labels: string[], summer: (bucket: EventResult[]) => number): {events: DateGroupedEvents[], dateMaps: DataMap } {
    const self = this;

    const dateMaps = map(ev2Array, evResults => {
      return groupBy(evResults, key => {
        return self.findContainingTimeChunk(key.date, labels);
      });
    });

    const dateGroupedEvents = transform(ev2Array, (acc1, eventResults, idx) => {
      acc1.push(transform(dateMaps[idx], (acc2, bucket, date) => {
        acc2[date] = summer(bucket);
      }, {}));
    }, []);

    return { events: dateGroupedEvents, dateMaps }
  }

  /**
   * Recursive function to find the label to represent the time chunk within which
   * the event would fall based on the actual event's date. For instance, if the time chunks are
   * [`2016-01-01`, `2016-01-08`, `2016-01-15`] and an event's date is `2016-01-10`, it would return
   * `2016-01-08`.
   *
   * Note that it doesn't find the date to which it is closest but the closest one that it is
   * greater than or equal to
   */
  private findContainingTimeChunk(key: string, labels: string[], incrementer: number = 0): string {

    if (incrementer > 20) {
      throw new Error('This func is taking too long :: let me know when this happens frank and it means i forgot an edge case')
    }
    const length = labels.length;
    const middle = length % 2 === 0 ? length / 2 : Math.round(length / 2);
    const valAtMiddle = labels[middle];
    const valBeforeMiddle = labels[middle - 1];

    const noValBeforeMiddleOrKeyEqualsValAtMiddle: boolean = !valBeforeMiddle || key === valAtMiddle;

    const keyEqualsValBeforeMiddleOrKeyIsBetweenVals: boolean = key === valBeforeMiddle || (key > valBeforeMiddle && key < valAtMiddle);
    if (noValBeforeMiddleOrKeyEqualsValAtMiddle) {
      return valAtMiddle;
    } else if (keyEqualsValBeforeMiddleOrKeyIsBetweenVals) {
      return valBeforeMiddle;
    } else {
      return this.findContainingTimeChunk(key,
        (key > valAtMiddle ? labels.slice(middle) : labels.slice(0, middle)), ++incrementer);
    }
  }

  /**
   * Given a start and end date, walks up by a given interval, pushing dates at each interval
   * to an array. Useful to programatically create an array of labels for a graph's independent variable
   */
  private walkTimeInterval(start: string, end: string, intervalType: string): string[] {
    const self = this;
    const dates = [];
    const startDate = self.getIntervalStartDate(start, intervalType);
    const endDate = moment(end);
    // const endDate = self.getIntervalEndDate(startDate, end, intervalType);
    for (let date = startDate.clone(); date <= endDate; date.add(1, intervalType)) {
      dates.push(date.format(self.DATE_FORMAT));
    }

    /**
     * We need to add one more date to the array if the end of walking the time intervals ends before the end date
     */
    const lastDate = moment(last(dates));
    if (lastDate.clone() < endDate) {
      dates.push(lastDate.add(1, intervalType).format(self.DATE_FORMAT));
    }
    return dates;
  }

  private getIntervalStartDate(start: string, intervalType: string): moment.Moment {
    switch(intervalType) {
      case 'weeks':
        return moment(start).startOf('week');
      case 'months':
        return moment(start).startOf('month');
      case 'quarters':
        return moment(start).startOf('quarter');
      case 'years':
        return moment(start).startOf('year');
      default:
        throw new Error(`Invalid interval type ${ intervalType }`);
    }
  }

}
