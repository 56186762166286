// External Deps
import { fullName } from 'common/dist/virtuals/User';
import { getEndTime } from 'common/dist/virtuals/BookingRequest';
import get from 'lodash/get';

class ConversationMessagesTableController {
  data: { conversation: any, message: any };
  role: string;
  limitCount: boolean;
  constructor() {
    'ngInject';
  }

  fullName = (user): string => fullName(user);

  getEndTime = (request): string => getEndTime(request);

  getUrl = (conversation): string => {
    if (this.isInquiry(conversation)) {
      return `/conversation/${conversation._id}/messages`;
    } else {
      return `/${this.role === 'venue' ? 'host' : 'guest'}/conversation/${conversation._id}`;
    }
  }

  isInquiry = (conversation: any): boolean => !conversation.request;

  getName = (conversation): string => {
    return this.role === 'venue'
      ? this.fullName(
        (conversation.user))
      : conversation.venue.data.name;
  }

  getCompanyName = (conversation): string => {
    return get<string>(conversation, 'company.name');
  }

  hasUnseenMessage = (conversation: any): boolean => {
    return (this.role === 'venue' ? conversation.unreadForVenue : conversation.unreadForClient) > 0;
  }

}

export const ConversationMessagesTableComponent = {
  template: require('./conversation-messages-table.component.jade'),
  controller: ConversationMessagesTableController,
  bindings: {
    data: '<',
    role: '@',
    limitCount: '<'
  }
};
