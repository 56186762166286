export const  sortParameters = [
    {
      name: 'Alphabetically (A-Z)',
      path: 'venue.data.name'
    },
    {
      name: 'Alphabetically (Z-A)',
      path: '-venue.data.name'
    },
    {
      name: 'Price (Low - High)',
      path: 'venue.data.price'
    },
    {
      name: 'Price (High - Low)',
      path: '-venue.data.price'
    },
    {
      name: 'Venue Partners First',
      path: '-venue.status'
    }
  ];
