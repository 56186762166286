///<reference path="../../../typings.d.ts" />
import { get } from 'lodash';
import VenueHelpers from 'common/dist/virtuals/Venue';
import { RawVenue } from 'lib/database/types/venue/venue';

// Types
import { ResultCardService } from './result-card.service';
import VenueSearchMap from './venue-search-map.service';
import { RawUser } from 'spc/lib/database/types/user';
import { ReviewsService } from 'spc/reviews/reviews.service';
import { UserService } from '../../../services/user.service';
import { clearLine } from 'readline';
import _ from 'lodash';
/**
 * Presentational component to display a basic venue search result card in search results.
 * The HTML for this card is also used to generate an info window on the map
 */

let ctrl;
class VenueSearchResultCard {
  venue: RawVenue;
  isPremiumUser: boolean;
  liteCount: number;
  user;
  search: { successManagers: RawUser[] };
  constructor($cloudinary, $user: UserService, private resultCardService: ResultCardService, private googleStreetViewModal, private VenueSearchMapService: VenueSearchMap, private reviewsService: ReviewsService, private addVenueToListModal, private cspVideoPlayer, private cspQuickView) {
    'ngInject';
    ctrl = this;
    ctrl.$cloudinary = $cloudinary;
    ctrl.$user = $user;
  }

  showVideoIcon(venue) {
    const isPremiumUser = ctrl.isPremiumUser;
    return ctrl.resultCardService.showVideoIcon({ venue, isPremiumUser });
  }

  getVideoPlayer(videos, venueName, venueAddress) {
    if (!videos.length) {
      return;
    }
    const teaserVideo = videos.find(vid => vid.title === 'Teaser' ? vid : null);

    if (_.isEmpty(teaserVideo)) {
      return;
    }
    return ctrl.cspVideoPlayer({ video: teaserVideo, venueAddress: venueAddress, venueName: venueName, venueSlug: this.venue.slug });
  }

  getQuickView(slug) {
      return ctrl.cspQuickView({ slug });
  }

  /**
   * Gets the cloudinary url for a venue's cover index photo, and defaults the image type to ipad
   *
   * @public
   * @param {Object} venue
   * @param {String} imgType, defaults to `ipad`
   * @return {String}
   */
  fetchAndConvertURL(venue, imgType = 'ipad') {
    if (!get(venue, 'data.photos.length')) {
      return;
    }
    return ctrl.$cloudinary.fetchAndConvertURL(venue.data.photos[venue.data.coverIndex].url, imgType);
  }

  /**
   * Gets the src-set href for a venue's cover index photo
   *
   * @public
   * @param {Object} venue
   * @return {String}
   */
  srcSet(venue) {
    if (!get(venue, 'data.photos.length')) {
      return;
    }
    return ctrl.$cloudinary.srcSet(venue.data.photos[venue.data.coverIndex].url);
  }

  /**
   * Gets minimum menu price in a venue's array of internal menu prices
   *
   * @public
   * @param {Object} venue
   * @return {Number}
   */
  minimumMenuPriceCents(venue) {
    return VenueHelpers.minimumMenuPriceCents(venue);
  }

  isBookingPriority = VenueHelpers.isBookingPriority;

  isNewVenue = VenueHelpers.isNewVenue;

  /**
   * Get venue view url for a venue and preload a space with any availability
   * check queries based on the search filters
   *
   * @public
   * @param {Object} venue
   * @param {String} spaceId
   * @return {String}
   */
  getVenueViewUrl(venue, spaceId) {
    return ctrl.search.getVenueViewUrl(venue, spaceId);
  }

  /**
   * Gets a venue's range of capacities that it can accommodate
   *
   * @public
   * @param {Object} venue
   * @return {String}
   */
  capacityRange(venue) {
    return VenueHelpers.capacityRange(venue);
  }

  redirect = () => {
    const url = '/upgrade';
    window.open(url, '_blank');
  }

  openGoogleStreetViewModal = (venue) => {
    return this.googleStreetViewModal(venue);
  }

  handleHover = (venue) => {
    this.VenueSearchMapService.triggerMarker(venue._id);
  }

  handleHoverOut = (venue) => {
    this.VenueSearchMapService.resetIcon(venue._id);
  }

  getSuccessManager = (venue) => {
    const profileOwner = get(venue, 'admin.profileOwner');
    if (!profileOwner) {
      return;
    }

    return this.search.successManagers.find(manager => manager._id.toString() === profileOwner);
  }

  getRating = (venue) => {
    return get(ctrl.search, `ratings.${venue._id.toString()}.venueRecommendation`);
  }

  showRating = (venue) => {
    return get<number>(ctrl.search, `ratings.${venue._id.toString()}.ratingCount`, 0) > 5;
  }

  getButtonText = () => {
    if (!this.user || (!this.user.premiumAccountRequested && !('account' in this.user)) || (this.user.premiumAccountRequested && !('account' in this.user))) {
      return 'START A 30-DAY FREE TRIAL';
    }
    else {
      return 'Upgrade to pro';
    }
  }

  getCount = (venue) => {
    return get<number>(ctrl.search, `ratings.${venue._id.toString()}.ratingCount`, 0);
  }

  saveVenue = () => {
    return this.addVenueToListModal(this.venue);
  }
}

export const VenueSearchResultCardComponent = {
  bindToController: true,
  bindings: {
    venue: '<',
    search: '<',
    ambianceClass: '&',
    isAdmin: '<',
    isPremiumUser: '<',
    liteCount: '<',
    user: '<',
  },
  controller: VenueSearchResultCard,
  template: require('./venue-search-result-card.jade')
};
