import {
  GetByDomainResponse,
  FindOrCreateResponse,
  SearchResponse,
  ValidateCompanyResponse,
} from 'lib/server/api/companies/models';

export class CompaniesRouter {
  BASE_URL: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${this.API_ROOT}/companies`;
  }

  getByDomain = (domain: string): ng.IPromise<GetByDomainResponse> => {
    return this.$http.get(`${this.BASE_URL}/domain/${domain}`)
      .then((response: { data: GetByDomainResponse } ) => response.data);
  }

  createCompany = (company): ng.IPromise<FindOrCreateResponse> => {
    return this.$http.post(this.BASE_URL, { company })
      .then((response: { data: FindOrCreateResponse }) => response.data);
  }

  searchCompanies = (name: string): ng.IPromise<SearchResponse> => {
    return this.$http.get(this.BASE_URL, { params: { name } })
      .then((response: { data: SearchResponse }) => response.data);
  }

  validate = ({ companyName }: { companyName: string }): ng.IPromise<ValidateCompanyResponse> => {
    return this.$http.post(`${this.BASE_URL}/validate`, { companyName })
      .then((response: { data: ValidateCompanyResponse }) => response.data);
  }
}
