import * as angular from 'angular';
import { topLevelMembershipAccountComponent } from './top-level-membership-account.component';
import { membershipAccountComponent } from './membership-account.component';
import { AddAccountMemberComponent } from './add-account-member/add-account-member.component';
import { ManageAccountMembersComponent } from './manage-account-members/manage-account-members.component';
import addAccountMemberModal from './add-account-member/add-account-member.modal';
import manageAccountMembersModal from './manage-account-members/manage-account-members.modal';
import membersLimitMessageModal from './members-limit-message/limit-message.modal';
import { premiumPageSubscriptionCardComponent } from 'spc/premium-page/premium-page-subscription-cards.component';
import { SubscriptionCardServices } from '../../typescript/utils/subscriptionCardServices';
import { requstProAccountModalComponent } from 'spc/premium-page/request-pro-account-modal.component';


export const MembershipAccountModule = angular.module('membershipAccountModule', [])
  .component('topLevelMembershipAccount', topLevelMembershipAccountComponent )
  .component('membershipAccount', membershipAccountComponent )
  .component('addAccountMember', AddAccountMemberComponent)
  .component('manageAccountMembers', ManageAccountMembersComponent)
  .component('premiumPageSubscriptionCard', premiumPageSubscriptionCardComponent)
  .component('requstProAccountModal', requstProAccountModalComponent)
  .service('addAccountMemberModal', addAccountMemberModal)
  .service('manageAccountMembersModal', manageAccountMembersModal)
  .service('membersLimitMessageModal', membersLimitMessageModal)
  .service('subscriptionCardServices', SubscriptionCardServices)
  .name;
