import { ApiService } from 'spc/shared/api/api.service';
import { RawBaseUser } from '../../../database/types/base-user';
import unwrapError from 'spc/services/unwrapError';

class CompleteRegistrationController {
  id: string;
  user: RawBaseUser;

  constructor(
    private $api: ApiService,
    private $rootScope: ng.IRootScopeService,
    private $routeParams,
    private $location
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.id = this.$routeParams.id;
    this.getBaseUser()
      .then((res) => {
        this.user = res.data.user;
        this.$rootScope.$emit('$viewReady');
      })
      .catch((error) => {
        if (error.data.error === 'Full User') {
          this.redirect('/login');
        }
        if (error.data.error === 'No User') {
          this.redirect('/register');
        }
        unwrapError(error);
      });
  }

  getBaseUser() {
    return this.$api.Auth.getBaseUser(this.id);
  }

  close = () => {
    return this.redirect('/events');
  }

  redirect(path) {
    return this.$location.path(path);
  }
}

export const CompleteRegistration = {
  template: require('./complete-registration.jade'),
  controller: CompleteRegistrationController,
};
