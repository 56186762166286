export default function() {
  return {
    scope: {
      text: '@',
      limit: '='
    },
    template: require('./read-more.jade'),
    controller: ['$scope', function($scope) {

      init();

      function init() {
        $scope.state = 'TRUNCATE';
        $scope.limit = $scope.limit || 50;
      }
    }]
  };
}
