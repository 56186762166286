import { some, get } from 'lodash';

module.exports = ['ngDialog', function(ngDialog) {
  return function(user) {
    return ngDialog.open({
      template: require('./add-venue-modal.jade'),
      plain: true,
      className: 'ngdialog-theme-small info-modal',
      overlay: true,
      controller: ['$scope', '$api', 'unwrapError', 'toast', function($scope, $api, unwrapError, toast) {
        const id = user._id;
        $scope.addToNewVenue = addToNewVenue;
        $scope.addToPreExistingVenue = addToPreExistingVenue;
        $scope.addToSelectedVenue = addToSelectedVenue;
        $scope.alreadyHost = alreadyHost;
        $scope.searchVenues = searchVenues;
        $scope.selectRow = selectRow;


        ///// Functions

        /**
         * Create a new venue and add user to it as an authorized user
         *
         */
        function addToNewVenue() {
          $api.Admin.Venues.create(id).
            then(function(response) {
              const slug = get(response, 'data.slug');
              $scope.closeThisDialog({ slug: slug });
            }).
            catch(unwrapError);
        }

        /**
         * Indicate that this is a preexisting venue
         *
         */
        function addToPreExistingVenue() {
          $scope.preExisting = true;
        }

        /**
         * Make a text query to find venues
         *
         * @param {String} input
         */
        function searchVenues(input) {
          if (!input) {
            return;
          }
          $api.Admin.Venues.search({ text: input }).
            then(res => $scope.venues = res.data.data.venues).
            catch(unwrapError);
        }

        /**
         * Select row from list of venues, and sets `$scope.clickedRow` to that row
         *
         * @param {Number} idx
         */
        function selectRow(idx) {
          if ($scope.alreadyHost(idx)) {
            return;
          }

          $scope.clickedRow = $scope.clickedRow === idx ? null : idx;
        }

        /**
         * Determine if a user is already a host for a given venue
         *
         * @param {Number} idx
         * @return {Boolean}
         */
        function alreadyHost(idx) {
          return some($scope.venues[idx].authorizedUsers,
            (authUser: any) => authUser.user.toString() === id);
        }

        /**
         * Adds user to selected venue and persists data on the backend
         *
         */
        function addToSelectedVenue() {
          let venue = $scope.venues[$scope.clickedRow];
          venue.authorizedUsers.push(id);
          $api.Admin.Venues.addUserToVenue(venue._id, id).
            then(function(response) {
              venue = get(response, 'data.data');
              toast.goodNews('User successfully added',
                user.fullName + ' was successfully added to ' + venue.data.name);
              $scope.closeThisDialog({ stay: true });
            }).
            catch(function(error) {
              toast.badNews('Error adding user',
                'There was an error adding ' + user.fullName + ' to ' + venue.data.name);
              unwrapError(error);
            });
        }
      }]
    }).closePromise;
  };
}];
