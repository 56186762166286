import { RawBookingRequest } from 'spc/lib/database/types/booking-request';
import { RawLead } from 'spc/lib/database/types/lead';

const leadPermissionsModal = ['ngDialog', function(ngDialog) {
  return function ({ requests, lead, userId }: { requests: RawBookingRequest[], lead: RawLead, userId: string }) {
    return ngDialog.open({
      template: '<lead-permissions requests="$ctrl.requests" lead ="$ctrl.lead" user-id="$ctrl.userId" view="$ctrl.view" close="$ctrl.close({ updatedLeadClients, updatedProposals})"></lead-permissions>',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.requests = requests;
        this.lead = lead;
        this.userId = userId;
        this.view = null;
        this.close = ({ updatedLeadClients, updatedProposals }) => {
          $scope.closeThisDialog({ leadClients: updatedLeadClients, proposals: updatedProposals });
        };
      }]
    }).closePromise;
  };
}];

export default leadPermissionsModal;
