class PremiumPageSubscriptionCardController {
    pro: Boolean;
    premium: Boolean;
    isDowngraded: Boolean;
    premiumAccountRequested: Boolean;
    requestAccess: () => any;
    isReqForTrailPending: boolean;

    // Add $user and demoVideoModal to the constructor parameters
    constructor(
      private $user,
      private demoVideoModal
    ) {
      'ngInject';
    }

    openDemoVideoModal = ({ demoRequest }) => {
      return this.demoVideoModal(demoRequest);
    }
  }

  export const premiumPageSubscriptionCardComponent = {
    template: require('./premium-page-subscription-cards.component.jade'),
    controller: PremiumPageSubscriptionCardController,
    bindings: {
      pro: '<',
      premium: '<',
      isDowngraded: '<',
      premiumAccountRequested: '<',
      requestAccess: '&',
      isReqForTrailPending: '<'
    }
  };
