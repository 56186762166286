// NPM Dependencies

import CancellationPolicySchema from 'common/dist/schemas/CancellationPolicy';
import { get, isBoolean, each, isArray, includes, filter, isNumber, last, merge, clone, isString, omit } from 'lodash';

module.exports = function () {
  return {
    template: require('./cancellation-policy.jade'),
    controller: ['$scope', 'ENUMS', function($scope, ENUMS) {
      $scope.ENUMS = ENUMS;
      $scope.getEarliest = getEarliest;
      $scope.getLatest = getLatest;
      $scope.isBoolean = isBoolean;
      $scope.isPositive = isPositive;
      $scope.policyName = policyName;
      $scope.setCancellationPolicyUIModel = setCancellationPolicyUIModel;
      $scope.sortCurrentPolicies = sortCurrentPolicies;

      init();
      /////
      function init() {
        setScopePolicies();
        $scope.setCancellationPolicyUIModel(ENUMS.cancellationPolicy.strictnessOrder);
      }

      function setScopePolicies() {
        const strictnessOrder = ENUMS.cancellationPolicy.strictnessOrder;
        if (get($scope, 'doc.data.terms.refundIssued') === 'NO_REFUND') {
          const indexOfNoRefund = strictnessOrder.indexOf('No Refund');
          $scope.policies = strictnessOrder.slice(indexOfNoRefund + 1);
        } else {
          $scope.policies = strictnessOrder;
        }
      }


      function setCancellationPolicyUIModel(policies) {
        policies = isArray(policies) ? policies : [policies];
        each(policies, function (policyType) {
          const index = $scope.findCancellationPolicy(policyType);
          const thePolicy = $scope.doc.data.terms.cancellationPolicies[index];

          $scope.uiModel.cancellationPolicies[policyType] = thePolicy
            ? {
              isChecked: true,
              type: thePolicy.type,
              availableBefore: thePolicy.availableBefore,
              refund: {
                type: get(thePolicy, 'refund.type'),
                amount: get(thePolicy, 'refund.amount')
              },
              penalty: {
                type: get(thePolicy, 'penalty.type'),
                fee: get(thePolicy, 'penalty.fee'),
              }
            }
            : { isChecked: false };
        });
      }

      function isPositive(policyObj) {
        return includes(ENUMS.cancellationPolicy.positives, policyObj.type);
      }

      function hasFields(policyObj) {
        if (!policyObj || !get(policyObj, 'type') || isNaN(policyObj.availableBefore)) {
          return false;
        }
        if (needsRefundOrPenaltyDetails(policyObj)) {
          const hasType = get(policyObj, 'refund.type') || get(policyObj, 'penalty.type');
          if (!hasType) {
            return false;
          }
          const hasAmounts = get(policyObj, 'refund.amount') || get(policyObj, 'penalty.fee');
          if (!hasAmounts) {
            return false;
          }
        }
        return true;
      }

      function sortCurrentPolicies() {
        const ret = $scope.policies.map(function (policy) {
          if (isChecked(policy)) {
            return $scope.uiModel.cancellationPolicies[policy];
          }
        });

        return filter(ret, function (policyObj) {
          return hasFields(policyObj);
        });
      }

      function policyName(policy) {
        return policy === 'Penalty' ? 'Extra Payment Due' : policy;
      }

      function getEarliest(policy) {
        const maxEarliest = 90;

        // otherwise, get next more lenient policy and make the earliest that policy's availableBefore + 1
        const nextMoreLenient: any = getNextMoreLenient(policy);
        if (!nextMoreLenient) {
          return maxEarliest;
        } else {
          const earliestAvailability = get($scope.uiModel.cancellationPolicies[nextMoreLenient], 'availableBefore');
          return isNumber(earliestAvailability) ? earliestAvailability - 1 : maxEarliest;
        }
      }


      function getLatest(policy) {
        const nextMoreStrict: any = getNextMoreStrict(policy);
        if (!nextMoreStrict) {
          return 0;
        } else {
          const latest = get($scope.uiModel.cancellationPolicies[nextMoreStrict], 'availableBefore');
          return isNumber(latest) ? $scope.uiModel.cancellationPolicies[nextMoreStrict].availableBefore + 1 : 0;
        }
      }

      function getNextMoreLenient(policy) {
        const currentPolicies = getCheckedPolicies();
        const moreLenientPolicies = currentPolicies.slice(0, currentPolicies.indexOf(policy));
        return moreLenientPolicies.length
          ? last(moreLenientPolicies)
          : null;
      }

      function getNextMoreStrict(policy) {
        const currentPolicies = getCheckedPolicies();
        const indexOfPolicy = currentPolicies.indexOf(policy);
        if (indexOfPolicy === -1) {
          return null;
        }

        const moreStrictPolicies = currentPolicies.slice(indexOfPolicy + 1);
        return moreStrictPolicies.length
          ? moreStrictPolicies[0]
          : null;
      }

      function isLeastStrict(policy) {
        const policies = $scope.sortCurrentPolicies();
        return policies[0] === policy;
      }

      $scope.hasExtraInput = function hasExtraInput(policy) {
        return policy === 'Partial Refund' || policy === 'Penalty';
      };

      $scope.disableIfNotChecked = function disableIfNotChecked(policy) {
        return !isChecked(policy);
      };

      $scope.isPenalty = function (policy) {
        return policy === 'Penalty';
      };

      $scope.toggleCancellationPolicy = function toggleCancellationPolicy(policyType) {
        const index = $scope.findCancellationPolicy(policyType);

        if (index === -1) {
          $scope.doc.data.terms.cancellationPolicies = $scope.doc.data.terms.cancellationPolicies || [];
          const newPolicy = new mongoose.Document({ type: policyType }, CancellationPolicySchema);
          if (policyType === 'Penalty') {
            newPolicy.availableBefore = 0;
          }
          $scope.doc.data.terms.cancellationPolicies.push(newPolicy);
        } else {
          $scope.doc.data.terms.cancellationPolicies.splice(index, 1);
        }

        $scope.setCancellationPolicyUIModel(policyType);
      };

      $scope.validateCancellationPolicy = function validateCancellationPolicy(policy) {
        const index = $scope.findCancellationPolicy(policy);
        const thePolicy = $scope.doc.data.terms.cancellationPolicies[index];
        if ($scope.uiModel.cancellationPolicies[policy]) {

          const cloneOfUiModel = clone($scope.uiModel.cancellationPolicies[policy]);
          merge($scope.doc.data.terms.cancellationPolicies[index], cloneOfUiModel);
        }
        $scope.pathValidator.validatePath('data.terms.cancellationPolicies.' + index);
      };


      $scope.getSummaryMessage = function getSummaryMessage(policy) {
        if (!policy || !policy.type) {
          return;
        }
        const message = $scope.cancellationMessage(policy.type);

        if (isLeastStrict(policy.type)) {
          const policyObject = $scope.uiModel.cancellationPolicies[policy.type];
          return '<strong>up to ' + policyObject.availableBefore + ' days</strong> before the event, ' + message.summary;
        }
      };

      function needsRefundOrPenaltyDetails(policyObj) {
        return policyObj.type && (policyObj.type === 'Partial Refund' || policyObj.type === 'Penalty');
      }

      function isChecked(policyObj) {
        if (isString(policyObj)) {
          return get($scope.uiModel.cancellationPolicies[policyObj], 'isChecked');
        }
        return get(policyObj, 'isChecked');
      }

      /**
       * Helper function to generate amount of penalty or refund in units
       * @param {Object} policyObj
       * @param {Boolean} refund - if true, refund. else, penalty
      */
      function generateAmountInUnits(policyObj, refund?) {
        const units = refund ? get(policyObj, 'refund.type') : get(policyObj, 'penalty.type');
        const amount = refund ? get(policyObj, 'refund.amount') : get(policyObj, 'penalty.fee');
        let amountInUnits;
        if (units === 'DOLLARS') {
          amountInUnits = '$' + amount;
        } else {
          amountInUnits = amount;
        }
        return amountInUnits ? amountInUnits : '...';
      }
      function generatePartialRefundAmount(policyObj) {
        if (!isChecked(policyObj)) {
          return;
        }
        const theAmountInUnits = generateAmountInUnits(policyObj, true);
        if (theAmountInUnits[0] === '$') {
          return theAmountInUnits + '.';
        }
        const theRefundType: any = get(policyObj, 'refund.type');
        const restOfSentence = '% of ' + ENUMS.cancellationPolicy.multipliers[theRefundType] + '.';
        return theAmountInUnits + restOfSentence;
      }

      function generatePenaltyMessage(policyObj) {
        if (!isChecked(policyObj)) {
          return;
        }
        const theAmountInUnits = generateAmountInUnits(policyObj);
        if (theAmountInUnits[0] === '$') {
          return theAmountInUnits + '.';
        } else {
          const restOfSentence = '% of the balance of the event.';
          return theAmountInUnits + restOfSentence;
        }
      }

      $scope.cancellationMessage = function cancellationMessage(policy) {
        const potentialPolicies = ENUMS.cancellationPolicy.types;

        const thePolicy = $scope.uiModel.cancellationPolicies[policy];
        const availableBefore = thePolicy ? thePolicy.availableBefore : null;

        let singularOrPluralDays;
        if (get(thePolicy, 'availableBefore') >= 0) {
          singularOrPluralDays = availableBefore > 1 ? 'days' : 'day';
        } else {
          singularOrPluralDays = 'days';
        }
        const policies = {
          'Full Refund': {
            firstHalfMessage: 'The client can cancel up to ',
            secondHalfMessage: singularOrPluralDays + ' before the event and receive a full refund of their deposit.',
            summary: 'they will receive a full refund of their deposit.'
          },
          'Partial Refund': {
            firstHalfMessage: 'The client can cancel up to ',
            secondHalfMessage: singularOrPluralDays + ' before the event and receive a refund of ',
            refundTypes: omit($scope.ENUMS.cancellationPolicy.refundQuantities, 'PERCENT_OF_THE_BALANCE_OF_THE_EVENT'),
            summary: 'they will be refunded ' + generatePartialRefundAmount(thePolicy)
          },
          'No Refund': {
            firstHalfMessage: 'If the client cancels within ',
            secondHalfMessage: singularOrPluralDays + ' before the event, the client will forfeit their deposit.',
            summary: 'they will forfeit their deposit.'
          },
          Penalty: {
            firstHalfMessage: 'If the client cancels within ',
            secondHalfMessage: singularOrPluralDays + ' or less of the event, the client will forfeit their deposit and owe ',
            refundTypes: omit($scope.ENUMS.cancellationPolicy.refundQuantities, 'PERCENT_OF_DEPOSIT', 'PERCENT_OF_EVENT_TOTAL'),
            summary: 'they will forfeit their deposit and be charged ' + generatePenaltyMessage(thePolicy)
          }
        };
        return policies[policy];
      };

      $scope.toggleRefund = () => setScopePolicies();

      function getCheckedPolicies() {
        return filter($scope.policies, function (policy) {
          return isChecked(policy);
        });
      }
    }]
  };
};
