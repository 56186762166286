import { GetRecoResponse, UpdateNoteResponse, AdminNote } from 'spc/lib/server/api/admin/recos/models';

export class Recommendation {
    BASE_URL: string;

    constructor(private API_ROOT: string, private $http: ng.IHttpService) {
        this.BASE_URL = `${API_ROOT}/admin/reco`;
    }

    public getReco = ({ id }: { id: string }): ng.IPromise<GetRecoResponse> => {
        return this.$http.get(`${this.BASE_URL}/${id}`)
            .then((res: { data: GetRecoResponse }) => {
                return res.data;
            });
    }

    public updateAdminNotes = ({ id, adminNotes }: { id: string, adminNotes: AdminNote[] }): ng.IPromise<UpdateNoteResponse>  => {
        return this.$http.put(`${this.BASE_URL}/${id}/notes`, { adminNotes })
            .then((res: { data: UpdateNoteResponse }) => {
                return res.data;
            });
    }
}
