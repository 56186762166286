export const adminPaymentModal = ['ngDialog', function(ngDialog) {
    return ({ request, conversation }) => {
      return ngDialog.open({
        template: `<admin-payment request="$ctrl.request" conversation="$ctrl.conversation" close="$ctrl.close"></admin-payment>`,
        className: 'ngdialog-theme-small vp-management',
        overlay: true,
        plain: true,
        controllerAs: '$ctrl',
        controller: ['$scope', function($scope) {
            this.request = request;
            this.conversation = conversation._id;
            this.close = (data) => {
              $scope.closeThisDialog({ request: data });
            };
        }]
      }).closePromise;
    };
  }];
