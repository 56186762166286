import mongoose, { Query } from 'mongoose';
import { RawBookingRequest } from 'spc/lib/database/types/booking-request';
import { queryHelpers } from 'spc/utils/query';

module.exports = function(API_ROOT, $http) {
  const ret = {
    addPaymentMethodToUser,
    reconcile,
    processRefund,
    getPaymentStatus
  };
  return ret;

  function reconcile({ request, paymentOptions }) {
    const url = `${ API_ROOT }/admin/payment/reconcile/${ request._id }`;
    return $http.post(url, { request, paymentOptions })
      .then(res => res.data);
  }

  function addPaymentMethodToUser(userId, nonce, extra) {
    const requestBody = { nonce: nonce };
    if (extra) {
      requestBody.extra = extra;
    }
    return $http.post(`${ API_ROOT }/admin/payment/method/${ userId }`, requestBody);
  }

  function processRefund({ requestId, transactionInformation, refundedFrom, isManual }) {
    const url = `${ API_ROOT }/admin/payment/${requestId}/refund/${refundedFrom}`;
    return $http.put(url, { transactionInformation, isManual })
      .then(res => res.data);
  }

  function getPaymentStatus({ requestId, paymentType }: { requestId: string | mongoose.Types.ObjectId, paymentType: 'deposit' | 'balance' }) {
    const query = queryHelpers.makeQueryString({ paymentType });
    const url = `${ API_ROOT }/admin/payment/status/${requestId}${query}`;
    return $http.get(url)
      .then(res => res.data);
  }
};
