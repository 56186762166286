// External Dependencies

// SixPlus Dependencies

// SixPlus Types
import mongoose from 'mongoose';
import {
  CreateVendorRequest,
  CreateVendorResponse,
  ValidateVendorResponse,
  GetVendorsResponse,
  GetVendorResponse,
  UpdateVendorResponse
} from 'server/api/admin/vendors/models';
import { RawVendor } from 'spc/lib/database/types/virtual-events';

export class Vendors {
  BASE_URL: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${API_ROOT}/admin/vendor`;
  }

  public createVendor = ({ vendor }: { vendor: RawVendor }): ng.IPromise<CreateVendorResponse> => {
    return this.$http.post(`${ this.BASE_URL }`, { vendor })
      .then((res: { data: CreateVendorResponse }) => {
        return res.data;
    });
  }

  public validate = ({ vendor }: { vendor: RawVendor }): ng.IPromise<ValidateVendorResponse> => {
    return this.$http.post(`${ this.BASE_URL }/validate`, { vendor: vendor })
      .then((res: { data: ValidateVendorResponse }) => {
        return res.data;
      });
  }

  public getAll = ({ page, documentsPerPage }: {page: number, documentsPerPage: number}): ng.IPromise<GetVendorsResponse> => {
    return this.$http.get(`${ this.BASE_URL }`, { params: { page, documentsPerPage } })
      .then((res: { data: GetVendorsResponse }) => {
        return res.data;
    });
  }

  public getVendorById = ({ vendorId }: { vendorId: mongoose.Types.ObjectId | string }): ng.IPromise<GetVendorResponse> => {
    return this.$http.get(`${ this.BASE_URL }/${vendorId}`)
      .then((res: { data: GetVendorResponse }) => {
        return res.data;
    });
  }

  public updateVendorById = ({ updates, vendorId }: {updates: RawVendor, vendorId: mongoose.Types.ObjectId | string }): ng.IPromise<UpdateVendorResponse> => {
    return this.$http.put(`${ this.BASE_URL }/${vendorId}`, { updates })
      .then((res: { data: UpdateVendorResponse }) => {
        return res.data;
    });
  }

  public deleteVendorById = ({ vendorId }: { vendorId: mongoose.Types.ObjectId | string }) => {
    return this.$http.delete(`${ this.BASE_URL }/${vendorId}`)
      .then((res) => {
        return res;
    });
  }
}
