// External Dependencies
import get from 'lodash/get';
export default ['ngDialog', '$q', '$location', '$user', function(ngDialog, $q, $location, $user) {
  return function (request, status, lead) {
    let leadId;
    if (lead) {
      leadId = !lead._id ? lead : lead._id;
    }
    const associatedLead = get(request, 'admin.associations.lead') || leadId;
    const selectedSpace = get(request, 'selectedSpace');
    const isLite = status === 'Lite';

    // If there is no selected space or no associated lead, it means no reco, and in some cases - no lead, has been created. The client will not be able to see this reco on the recos page.
    if (!isLite && (!selectedSpace || !associatedLead)) {
      return $q.resolve();
    }
    if (this.$user.isNotLoggedIn()) {
      $location.path('/request-submitted');
      return $q.resolve();
    }

    return ngDialog.open({
      template: require('./request-confirmation.modal.jade'),
      className: 'ngdialog-theme-small info-modal',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function ($scope) {
        $scope.titleText = isLite ? 'Venue Added!' : 'Request submitted!';
        this.requestState = get(request, 'state');
        this.date = get(request, 'data.date');
        this.time = get(request, 'data.time');
        this.eventType = get(request, 'data.customEventType') || get(request, 'data.eventType');
        this.associatedLead = associatedLead;
        this.selectedSpace = selectedSpace;
        this.message;
        if (this.requestState === 'INCOMPLETE') {
          // If there is a selected space but the request state is incomplete that means the client started but didn't finish the BR. Since there is an associated lead and selected space, it will show up on the recos page.
          this.message = `Thanks for starting a request for your ${this.eventType} on ${this.date}. You can see the status of this request and all other requests for that event on your event dashboard. You can access the dashboard by clicking the menu and clicking events`;
        } else {
          this.message = `Thanks for submitting a request for your ${this.eventType} on ${this.date}. You can see the status of this request and all other requests for that event on your event dashboard. You can access the dashboard by clicking the menu and clicking events`;
          // If the reuqest state is not incomplete then it must have been submitted. This will also show up on the recos page.
        }

        this.navigate = (url) => {
          $location.path(url);
          return this.close();
        };

        this.close = (data) => {
          return $scope.closeThisDialog(data);
        };
      }]
    }).closePromise;
  };
}];
