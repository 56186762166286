import { cloneDeep, debounce, forEach, findIndex } from 'lodash';
import { ApiService } from './../../../shared/api/api.service';
import { OrganiseExperienceQuery, DExperience, ExperienceQuery, RawExperience } from './../../../../../database/types/virtual-events';

// constants
import { SEARCH } from '../../../constants/search.constant';

class OrganiseExperienceController {
  close: (revised: any) => void;
  searchedExperiences: DExperience[] = [];
  page: number = 0;
  documentsPerPage: number = 50;
  currentPage: number = 0;
  experiences: DExperience[];
  prioritized: DExperience[] = [];
  prioritizeExperiences: DExperience[] = [];
  unPrioritizeExperiences: DExperience[] = [];
  numExperiences: number;
  textParams: string = '';
  loading: boolean = false;

  constructor(
    private $scope,
    private $api: ApiService,
    private unwrapError
    ) {
    'ngInject';
  }

  $onInit = () => {
    this.$scope.removeFromPrioritize = this.removeFromPrioritize;
    this.$scope.loading = this.loading;
    this.getExperiences();
    this.getPriorityExperiences();
  }

  onPageChange = (page) => {
    this.page = page;
    return this.getExperiences(page = this.page);
  }

  getExperiences = (page = 0) => {
    const query: ExperienceQuery = this.queryHandler(page);
    return this.$api.Admin.Experience.getAll({ query })
      .then((response) => {
        if (query.textParams && query.textParams.length) {
          this.unPrioritizeExperiences = cloneDeep(response.experiences);
          this.searchedExperiences = cloneDeep(response.experiences);
        } else if (query.showUnprioritized) {
          this.unPrioritizeExperiences = cloneDeep(response.experiences);
        }
        this.numExperiences = response.numExperiences;
        this.currentPage = page + 1;
      }).catch((error) => {
        this.unwrapError(error);
      });
  }

  getPriorityExperiences = () => {
    return this.$api.Admin.Experience.getAllPriorityExperience()
      .then((response) => {
        this.prioritizeExperiences = cloneDeep(response.prioritizedExperiences);
        this.$scope.prioritizeExperiences = this.prioritizeExperiences;
      });
  }

  searchHandler = debounce(this.getExperiences, SEARCH.DEBOUNCE);

  queryHandler = (page) => {
    const query: ExperienceQuery = {
      page,
      documentsPerPage: this.documentsPerPage
    };
    if (this.textParams.length) {
      query.textParams = this.textParams;
    } else {
      query.showUnprioritized = true;
    }
    return query;
  }


  setPriority = (experience, action) => {
    if (this.loading) {
      return;
    }
    const updates = {
      order: this.prioritizeExperiences.length + 1 || 1,
      prioritize: true
    };
    this.loading = true;
    this.$api.Admin.Experience.updateExperienceById({ updates, experienceId: experience._id })
      .then((response) => {
        this.updatedExperienceHandler(response, action);
        this.getExperiences();
        this.loading = false;
      });
  }

  removeFromPrioritize = (experience, action) => {
    if (this.loading) {
      return;
    }
    const updates = {
      order: null,
      prioritize: false
    };
    this.loading = true;
    this.$api.Admin.Experience.updateExperienceById({ updates, experienceId: experience._id })
      .then((response) => {
        this.updatedExperienceHandler(response, action);
        this.getExperiences();
        this.loading = false;
      });
  }

  updatedExperienceHandler = (response, action) => {
    const { experience } = response;
    if (action === 'add') {
      if (!this.prioritizeExperiences.some(exp => exp._id === experience._id)) {
        this.prioritizeExperiences.push(experience);
        const prioritizeExperienceIndex = this.unPrioritizeExperiences.findIndex(exp => exp._id === experience._id);
        const prioritySearchedIndex = this.searchedExperiences.findIndex(exp => exp._id === experience._id);
        this.unPrioritizeExperiences.splice(prioritizeExperienceIndex, 1);
        this.searchedExperiences.splice(prioritySearchedIndex, 1);
      }
    } else if (action === 'remove') {
      if (!this.unPrioritizeExperiences.some(exp => exp._id === experience._id)) {
        this.unPrioritizeExperiences.push(experience);
      }
      const unPrioritizedExperienceIndex = this.prioritizeExperiences.findIndex(exp => exp._id === experience._id);
      this.prioritizeExperiences.splice(unPrioritizedExperienceIndex, 1);
    }
  }

  saveExperiences = () => {
    const experiencesToUpdate: OrganiseExperienceQuery [] = this.$scope.prioritizeExperiences.map((experience, index) => {
      const updateInfo = {
        experienceId: experience._id,
        order: index + 1
      };
      return updateInfo;
    });
    return this.$api.Admin.Experience.updateExperienceOrder({ updates: experiencesToUpdate })
      .then(res => this.close(res))
      .catch(err => this.unwrapError(err));
  }
}

export const OrganiseExperienceComponent = {
  controller: OrganiseExperienceController,
  controllerAs: '$ctrl',
  template: require('./organise-experience-component.jade'),
  bindings: {
    close: '<'
  }
};
