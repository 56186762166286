// External Dependencies

// SixPlus Dependencies

// SixPlus Types
import {
  Lead,
  UpdateRequestBody,
  UpdateLeadResponse,
  GetOneResponse,
  GetSuggestionsResponse,
  SubmitRecommendationsRequestBody,
  SubmitRecommendationsResponse,
  ChangeClientRequestBody,
  ChangeClientResponse,
  CreateProposalRequestBody,
  CreateProposalResponse,
  UpdateAvailabilityRequestBody,
  UpdateAvailabilityResponse,
  GetAllOwnersResponse,
  AssignOwnerRequestBody,
  UpdateStatusRequestBody,
  UpdateStatusResponse,
  UpdateClientResponse,
  UpdateClientProfile,
  UpdateClientRequestBody,
  UpdateAdminRequestBody,
  UpdateAdminResponse,
  DownloadRequest,
  DownloadResponse,
  SearchParams
} from 'server/api/admin/leads/models';
import { AddRecommendationRequestBody, AddRecommendationResponse } from '../../../../../server/api/admin/leads/models';
import { RawLead } from 'spc/lib/database/types/lead';

export class Leads {
  BASE_URL: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${API_ROOT}/admin/leads`;
  }

  public get = (pageNum, searchParams) => {
    const page = pageNum || 0;
    const url = `${this.BASE_URL}/all/${page}`;
    return this.$http.get(url, { params: searchParams });
  }

  public download = (searchParams: SearchParams): ng.IPromise<DownloadResponse> => {
    const url = `${this.BASE_URL}/download`;
    return this.$http.get(url, { params: searchParams })
      .then((response: { data: DownloadResponse }) => response.data);
  }

  public count = (searchParams) => {
    const url = `${this.BASE_URL}/${searchParams ? 'searchCount' : 'count'}`;
    return this.$http.get(url, { params: searchParams });
  }

  public update = (lead: UpdateRequestBody): ng.IPromise<UpdateLeadResponse> => {
    return this.$http.put(`${this.BASE_URL}/${lead._id}`, lead)
      .then((response: { data: UpdateLeadResponse }) => response.data);
  }

  public updateAdmin = ({ lead, update }): ng.IPromise<UpdateAdminResponse> => {
    const data: UpdateAdminRequestBody = { update };
    return this.$http.put(`${this.BASE_URL}/admin/${lead}`, data)
      .then((response: { data: UpdateAdminResponse }) => response.data);
  }

  public updateStatus = (lead: RawLead, status: string): ng.IPromise<UpdateStatusResponse> => {
    const data: UpdateStatusRequestBody = { status };
    return this.$http.put(`${this.BASE_URL}/${lead._id}/status`, data)
      .then((response: { data: UpdateStatusResponse }) => response.data);
  }

  public getOne = (id: string): ng.IPromise<GetOneResponse> => {
    return this.$http.get(`${this.BASE_URL}/${id}`)
      .then((response: { data: GetOneResponse }) => response.data);
  }

  public getSuggestions = (id: string): ng.IPromise<GetSuggestionsResponse> => {
    return this.$http.get(`${this.BASE_URL}/${id}/suggestions`)
      .then((response: { data: GetSuggestionsResponse }) => response.data);
  }

  public getAllOwners = (): ng.IPromise<GetAllOwnersResponse> => {
    return this.$http.get(`${ this.BASE_URL }/owner`)
      .then((response: { data: GetAllOwnersResponse }) => response.data);
  }

  public assignOwner = (leadId: string, owner: string): ng.IPromise<any> => {
    const data: AssignOwnerRequestBody = { owner };
    return this.$http.put(`${ this.BASE_URL }/${ leadId }/owner`, data)
      .then(response => response.data);
  }

  public adminLastTouch = (leadId: string): ng.IPromise<any> => {
    return this.$http.put(`${ this.BASE_URL }/${ leadId }/lastTouch`, {})
      .then(response => response.data);
  }

  public addRecommendation = (lead: Lead, data: AddRecommendationRequestBody): ng.IPromise<AddRecommendationResponse> => {
    return this.$http.post(`${this.BASE_URL}/${lead._id}/recommendations`, data)
      .then((response: { data: AddRecommendationResponse }) => response.data);
  }

  public submitRecommendations = (id: string, data: SubmitRecommendationsRequestBody): ng.IPromise<SubmitRecommendationsResponse> => {
    return this.$http.put(`${ this.BASE_URL }/${ id }/submit`, data)
      .then((response: { data: SubmitRecommendationsResponse }) => response.data);
  }

  public createProposal = ({ lead, recommendation, proposalInfo }: { lead: string, recommendation: string, proposalInfo: CreateProposalRequestBody }): ng.IPromise<CreateProposalResponse> => {
    return this.$http.post(`${ this.BASE_URL }/${ lead }/${ recommendation }`, proposalInfo)
      .then((response: { data: CreateProposalResponse }) => response.data);
  }

  public changeClient = (data: ChangeClientRequestBody): ng.IPromise<ChangeClientResponse> => {
    const { leadId, oldUserId, newUserId } = data;
    return this.$http.put(`${ this.BASE_URL }/${ leadId }/client`, data)
      .then((response: { data: ChangeClientResponse }) => response.data);
  }

  public updateAvailability = ({ leadId, recommendationId, availabilityState }: { leadId: string, recommendationId: string, availabilityState: string }): ng.IPromise<UpdateAvailabilityResponse> => {
    return this.$http.put(`${ this.BASE_URL }/${ leadId }/${ recommendationId }/availability`, { availabilityState })
      .then((response: { data: UpdateAvailabilityResponse }) => response.data);
  }

  public updateClient = ({ user, data }: { user: string, data: { profile?: UpdateClientProfile; owner?: string } }): ng.IPromise<UpdateClientResponse> => {
    return this.$http.put(`${this.BASE_URL}/${user}/client/update`, data)
      .then((response: { data: UpdateClientResponse }) => response.data);
  }
}
