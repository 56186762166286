class TopLevelConciergeRequestsDashboardController {
  canInit: boolean;
  constructor(
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $seo,
    private $user) { 'ngInject'; }

  $onInit() {
    this.$user.$waitFor('LOGGED_IN', () => {
      this.canInit = true;
    });

    this.$user.$waitFor('NOT_LOGGED_IN', () => {
      this.$location.path(`/login`);
    });
  }

  componentIsReady = () => {
    this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
  }

  setSeo = (numRequests) => {
    const title = `${ numRequests } Pending Requests`;
    this.$seo.set(title);
  }
}

export const TopLevelConciergeRequestsDashboardComponent = {
  template: require('./top-level-concierge-requests-dashboard.component.jade'),
  controller: TopLevelConciergeRequestsDashboardController
};
