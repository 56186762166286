interface BarColorConfig {
  backgroundColor: string;
}

interface LineColorConfig {
  backgroundColor: string;
  borderColor: string;
  pointRadius: number;
  pointHoverBorderColor: string;
  pointBackgroundColor: string;
  borderWidth: number;
}

export const BASE_COLORS: string[] = ['#85259C', '#06958a', '#d53a58', '#d4b322', '#00a7e1', '#b5b682', '#f5853f'];

export function defaultBarColorConfig(idx: number): BarColorConfig {
  return { backgroundColor: BASE_COLORS[idx] };
}

export function defaultLineColorConfig(idx: number): LineColorConfig {
  return {
        backgroundColor: 'rgba(1, 1, 1, 0)',
        borderColor: BASE_COLORS[idx],
        pointRadius: 3,
        pointHoverBorderColor: BASE_COLORS[idx],
        pointBackgroundColor: BASE_COLORS[idx],
        borderWidth: 0
      };
}
