import { SearchParams } from './admin-companies-dashboard.component';

class AdminCompaniesSearchController {
  constructor() {
    'ngInject';
  }

  search: () => void;
  params: SearchParams;
  ui: {
    domainInput?: string
  };

  toggleDisplay: () => any;

  addDomain = (domain) => {
    if (domain) {
      this.params.domains = this.params.domains || [];
      this.params.domains.push(domain);
      this.ui.domainInput = null;
    }
  }

  hideSearchSidebar = () => {
    this.ui.displaySearchSidebar = true;
  }

  removeDomain = (domain) => {
    this.params.domains = this.params.domains.filter(_domain => _domain !== domain);
  }
}

export const adminCompaniesSearchComponent = {
  template: require('./admin-companies-search.component.jade'),
  controller: AdminCompaniesSearchController,
  bindings: {
    search: '<',
    params: '<',
    toggleDisplay: '&'
  }
};

