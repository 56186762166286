

/**
 * Open a ngDialog that shows detailed information about a venue.
 *
 * @param {Venue} venue
 * @return {Promise} resolved when this modal is closed
 */

module.exports = ['ngDialog', function(ngDialog) {
  return function(venue) {
    return ngDialog.
      open({
        template: require('./venue-detail.jade'),
        className: 'ngdialog-theme-small info-modal',
        overlay: true,
        plain: true,
        controller: ['$scope', function($scope) {
          $scope.venue = venue;
        }]
      }).
      closePromise;
  };
}];
