import debounce from 'lodash/debounce';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { ApiService } from 'spc/shared/api/api.service';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import groupBy from 'lodash/groupBy';

class AddClientsController {
  request: any;
  conversation: any;
  clients: any[];
  enteredEmail: string;
  user: any;
  newUser: {
    profile: {
      name: {
        first: string;
        last: string;
      }
      email: string;
    }
    company?: {
      name: string
    };
  };
  previousClients: any[];
  close: ({ data }: { data: any }) => any;
  ui: {
    findingClient?: boolean;
    errorMessage?: string
    isAdmin?: boolean;
    isVenue?: boolean;
    isClient?: boolean;
    displayEmails?: boolean;
    copyClick?: boolean
  } = { displayEmails: false };
  connections: RawBaseUser[];
  displayConnectionsData: { [key: string]: RawBaseUser[] };
  constructor(private $api: ApiService, private unwrapError, private $user, private ENUMS, private $timeout: ng.ITimeoutService, private $injector) {
    'ngInject';
  }

  $onInit = () => {
    this.conversation = {};
    this.clients = [...this.request.clients];
    this.ui.isAdmin = this.$user.isAdmin();
    this.ui.isVenue = this.$user.isHost();
    this.ui.isClient = this.$user.isGuest();
    this.getConnectedUsers(this.$user.$);

    if (this.ui.isVenue) {
      return this.$api.Requests.getClients()
        .then(({ data }: { data: { clients: any[] }}) => this.previousClients = data.clients)
        .catch(error => this.unwrapError(error));
    }
    this.setDisplayEmails();
  }

  getConnectedUsers(user) {
    return this.$api.BaseUsers.getConnections(user._id.toString())
      .then((res) => {
        this.connections = res.users.filter(u => u._id.toString() !== user._id.toString());
        this.displayConnectionsData = groupBy(this.connections, 'connectionSource');
      })
      .catch(error => this.unwrapError(error));
  }

  setDisplayEmails = () => {
    if (this.request.state.includes(this.ENUMS.bookingRequestState.bookedStates)) {
      this.ui.displayEmails = true;
    } else if (!this.ui.isVenue) {
      this.ui.displayEmails = true;
    }
  }

  verifyEmail = (email: string) => {
    return this.$api.Auth.verifyEmail(email);
  }

  clientExists = (email: string): boolean => {
    return !!this.clients.find(client => client.user.profile.email === email);
  }

  handleCopyClick = () => {
    this.ui.copyClick = true;
    this.$timeout(() => this.ui.copyClick = false, 1000);
  }

  getPreviewLink = () =>  {
    const location = this.$injector.get('$location');
    let domain = `${location.protocol()}://${location.host()}`;
    if (location.port() !== 443) {
      domain = `${domain}:${location.port()}`;
    }
    return `${domain}/client/conversation/${this.request.conversation}/preview`;
  }

  findClient = (email: string): Promise<void> => {
    this.ui.errorMessage = null;
    if (!email) {
      return;
    }
    email = email.trim().toLowerCase();

    this.user = null;
    this.newUser = null;
    this.ui.findingClient = true;

    return this.verifyEmail(email)
      .then(() => this.$api.Search.findByEmail(email))
      .then((data) => {
        this.ui.findingClient = false;
        if (data.user) {
          this.user = data.user;
          return;
        }
        this.newUser = {
          profile: {
            email: email.trim().toLowerCase(),
            name: {
              first: '',
              last: ''
            }
          },
          company: {
            name: ''
          }
        };
      })
      .catch((error) => {
        this.ui.findingClient = false;
        if (!error.data.valid) {
          this.ui.errorMessage = `The entered email is invalid.`;
        }
        this.unwrapError(error);
      });
  }

  addExistingClient = (user) => {
    this.ui.errorMessage = null;
    if (!user) {
      return;
    }
    if (this.clientExists(get(user, 'profile.email'))) {
      this.ui.errorMessage = 'A client with this email already exists in this request';
      return;
    }
    this.clients.push({ user, eventRoles: [] });
    this.user = null;
    this.enteredEmail = null;
  }

  clientIsValid = () => {
    return this.newUser &&
      this.newUser.profile.name.first.length &&
      this.newUser.profile.name.last.length;
  }

  addNewClient = () => {
    if (!this.clientIsValid()) {
      return;
    }
    const client = this.newUser;
    this.verifyEmail(client.profile.email)
      .then(() => this.$api.BaseUsers.create(client))
      .then((response) => {
        this.newUser = null;
        this.enteredEmail = null;
        this.clients.push({ user: response.user, eventRoles: [] });
      })
      .catch(error => this.unwrapError(error));
  }

  cancel = () => {
    this.close({ data: { cancel: true } });
  }

  isSelected = ({ client, role }) => {
    return client.eventRoles.includes(role);
  }

  addRole = ({ client, role }) => {
    if (this.isSelected({ client, role })) {
      return;
    }
    client.eventRoles.push(role);
  }

  removeRole = ({ client, role }) => {
    client.eventRoles = client.eventRoles.filter(existingRole => existingRole !== role);
  }

  allowUserRemoval = (client) => {
    if (!this.ui.isAdmin && !this.isUserPrimaryClient()) {
      return false;
    }

    if (client.isPrimary) {
      return false;
    }

    if (this.clients.length === 1) {
      return false;
    }

    return true;
  }

  isUserPrimaryClient = () => {
    if (this.request.creator.userId.id === this.$user.$.id) {
      return true;
    }
    return false;
  }

  removeClient = (client) => {
    if (!this.allowUserRemoval(client)) {
      return;
    }
    const clientIdToRemove = client.user._id.toString();
    this.clients = this.clients.filter(_client => _client.user._id !== clientIdToRemove);
  }

  allowReplacePrimaryUser = (client) => {
    return this.ui.isAdmin && !client.isPrimary;
  }

  replacePrimaryUser = (newPrimaryClient) => {
    if (!this.allowReplacePrimaryUser(newPrimaryClient)) {
      return;
    }
    this.clients.forEach((client) => {
      if (client.isPrimary) {
        client.isPrimary = false;
      }
      if (client.user._id.toString() === newPrimaryClient.user._id.toString()) {
        client.isPrimary = true;
      }
    });
  }

  save = () => {
    return this.$api.AuthorizedClients.modifyClients({ request: this.request, clients: this.clients })
      .then(response => this.close({ data: response }))
      .catch(error => this.unwrapError(error));
  }
}

export const AddClientsComponent = {
  template: require('./add-clients.component.jade'),
  controller: AddClientsController,
  bindings: {
    request: '<',
    conversation: '<',
    close: '&'
  }
};
