
import { CreateVenueQueryRequestBody, CreateVenueQueryResponse } from 'server/api/venue-queries/models';

export class VenueQueryRouter {
  BASE_URL: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${API_ROOT}/venue-queries`;
  }

  create = ({ venueQuery }: CreateVenueQueryRequestBody): ng.IPromise<CreateVenueQueryResponse> => {
    return this.$http.post(this.BASE_URL, { venueQuery }).then((response: { data: CreateVenueQueryResponse }) => response.data);
  }
}
