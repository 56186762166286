import { ApiService } from 'spc/shared/api/api.service';

class RequestAttachmentsController {
  conversation: any;
  attachments: any;
  request: any;
  user: any;
  setConversation: any;

  constructor(private $api: ApiService, private $cloudinary,
  private unwrapError, private organizeAttachmentsModal, private $user) {
    'ngInject';
  }

  $onInit() {
    this.setDisplayedAttachments();
  }

  setDisplayedAttachments = () => {
    const fileAttachment = [];
    if (this.conversation && this.conversation.attachments.length) {
      const conversationfilesAttachments = this.conversation.attachments.filter(attachment => !attachment.isDeleted && attachment.title !== 'Link');
      conversationfilesAttachments.map(( file ) => {
        fileAttachment.push(file);
      });
    }
    this.attachments = fileAttachment;
  }

  openUploadFilesModal = () => {
    return this.organizeAttachmentsModal (this.conversation, this.conversation)
      .then((data) => {
      if (data.value.conversation) {
        this.conversation = data.value.conversation;
        this.attachments = this.conversation.attachments;
        this.setConversation({ conversation: this.conversation });
      }
      this.setDisplayedAttachments();
    });
  }
}

export const RequestAttachmentsComponent = {
  template: require('./request-attachments.component.jade'),
  bindings: {
    conversation: '<',
    setConversation: '&',
    request: '<'
  },
  controller: RequestAttachmentsController,
};

