import BookingRequestHelpers from 'common/dist/virtuals/BookingRequest';
import { cloneDeep, find, findIndex, get, isNumber, unset, set, each } from 'lodash';

export default function() {
  const ret = {
    checkStillValidTime,
    isBetweenTimes,
    isEndTime,
    mergeSlots
  };
  return ret;

  /**
   * NOTE: has side effects
   * Checks if request's data.time is still valid within 
   * an array of slots
   *
   * @api public
   * @param {Array} slots
   * @param {Request} request
   * @mutates `request` if doesn't fit
   */
  function checkStillValidTime(slots, request) {
    if (!isNumber(request.data.time)) {
      return;
    }

    const selectedSlot = BookingRequestHelpers.selectedTimeSlot(request);
    if (!selectedSlot) {
      return;
    }

    const newVersionOfSelectedSlot = find(slots, 
      s => s._id.toString() === selectedSlot._id.toString());

      // SHORT-TERM SOLUTION
      if (!newVersionOfSelectedSlot) {
        return;
      }
    const shouldUnsetTime = 
      newVersionOfSelectedSlot.from > request.data.time || 
      newVersionOfSelectedSlot.to < BookingRequestHelpers.getEndTime(request) ||
      _isBooked(newVersionOfSelectedSlot, request);

    if (shouldUnsetTime) {

      unset(request, 'data.time');
      unset(request, 'data.duration');
      unset(request, 'data.timeSlot');
    }
  }

  /**
   * Performs a custom merge on 2 slot arrays, deleting any 
   * slots from `originalSlots` that don''t exist in `newSlots`,
   * modifying any slots in `originalSlots` that have different
   * `slot.to` and `slot.from` values in `newSlots`, and adding
   * any new slots that don't exist in `originalSlots`
   *
   * @api public
   * @param {Array} originalSlots
   * @param {Array} newSlots
   * @return {Array}
   */
  function mergeSlots(originalSlots, newSlots) {
    if (originalSlots && !newSlots) {
      return originalSlots;
    } else if (newSlots && !originalSlots) {
      return newSlots;
    } else if (!newSlots && !originalSlots) {
      return [];
    }

    const finalSlots = [];
    each(newSlots, function(newSlot) {
      const idx = findSlotIdx(originalSlots, newSlot);
      // If the slot doesn't exist in the new array, dont push it to new array.
      if (idx === -1) {
        return;
      }

      // TEMPORARY SOLUTION: manually clone and replace `fbmin` and `isBlocked` with old values
      if (idx > -1) {
        const clone = cloneDeep(newSlot);
        set(clone, 'terms.foodBeverageMin', get(originalSlots[idx], 'terms.foodBeverageMin'));
        clone.isBlocked = originalSlots[idx].isBlocked;
        finalSlots.push(clone);
      }
    });

    return finalSlots;
  }

  /**
   * Determine if a time is between the start and end time in a given slot
   *
   * @api public
   * @param {Request} request
   * @param {Time} time
   * @param {Slot} slot
   * @return {Boolean}
   */
  function isBetweenTimes(request, time, slot) {
    if (!slot.isSelected) {
      return false;
    }
    
    const startTime = get(request, 'data.time');
    if (!isNumber(startTime)) {
      return false;
    }

    const endTime = BookingRequestHelpers.getEndTime(request);

    if (!isNumber(endTime)) {
      return false;
    }

    return time.time > startTime && time.time < endTime;
  }

  function isEndTime(request, time, slot) {
    if (!slot.isSelected) {
      return false;
    }

    let startTime = get(request, 'data.time');
    if (!isNumber(startTime)) {
      return false;
    }

    let duration = get(request, 'data.duration');
    if (!duration) {
      return false;
    }

    const expectedEndTime = BookingRequestHelpers.getEndTime(request);
    return expectedEndTime === time;
  }

  ///// Private functions
  function findSlotIdx(slots, slot) {
    return findIndex(slots, 
      s => s._id.toString() === slot._id.toString());
  }

  /**
   * Determine if a given slot is booked for another request besides
   * the request in question
   */
  function _isBooked(slot, request) {
    const bookingRequestId = get(slot, 'booking.requestId');
    return bookingRequestId && bookingRequestId !== request._id.toString();
  }
};