export const replaceCardModal = ['ngDialog', function(ngDialog) {
  return function (request) {
    return ngDialog.open({
      template: `<replace-card request="$ctrl.request" close="$ctrl.close({ method })"></replace-card>`,
      className: 'ngdialog-theme-small',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function($scope) {
        this.request = request.toObject ? request.toObject() : request;
        this.close = function(data) {
          return $scope.closeThisDialog(data);
        };
      }]
    }).closePromise;
  };
}];
