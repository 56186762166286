// NPM Dependencies
import { fullPriceBreakdownCents } from 'common/dist/price';
import BookingRequestHelpers from 'common/dist/virtuals/BookingRequest';
import AuthorizedClientHelpers from 'common/dist/virtuals/AuthorizedClient';
import moment from 'moment';
import { get, includes } from 'lodash';

export default function() {
  return {
    template: require('./payment-confirmation.jade'),
    scope: {
      conversation: '=',
      user: '='
    },
    controller: ['$scope', '$api', 'paymentHelpers', 'unwrapError', function($scope, $api, paymentHelpers, unwrapError) {
      $scope.signUpForNewsletter = signUpForNewsletter;

      init();

      ///// Initialization function
      function init() {
        const conversationId = $scope.conversation._id ? $scope.conversation._id : $scope.conversation;
        $api.Requests.conversation(conversationId).
          then(function(response) {
            $scope.conversation = get(response, 'conversation');
            $scope.bookingRequest = get(response, 'request');

            $scope.drinks = ($scope.bookingRequest.data.drinks || [])
              .filter(drink => !drink.isDeleted);
            paymentHelpers.checkState($scope.bookingRequest, 'DEPOSIT');

            $scope.costBreakdownCents = fullPriceBreakdownCents($scope.bookingRequest);

            const depositPayment = BookingRequestHelpers.nthCompletedPayment($scope.bookingRequest, 1);
            $scope.paymentIndex = $scope.bookingRequest.paymentSchedule.findIndex(payment => payment._id.toString() === depositPayment._id.toString());
            $scope.guest = AuthorizedClientHelpers.primaryEventContact($scope.bookingRequest).user;
            $scope.assignee = get($scope.bookingRequest, 'admin.assignee');
            $scope.user.$waitFor('LOGGED_IN', function() {
              const user = $scope.user.$;
              const current = get<string>(user, 'profile.newsletters');
              if (includes(current, 'Main') && includes(current, user.profile.city)) {
                $scope.signedUp = true;
              }
            });
          }).catch(unwrapError);
      }

      function signUpForNewsletter() {
        if ($scope.signedUp) {
          return;
        }
        $scope.user.$waitFor('LOGGED_IN', function() {
          const newsletters = ['Main'];
          const userCity = get<string>($scope, 'user.$.profile.city');
          if (userCity) {
            newsletters.push(userCity);
          }
          $api.Auth.newsletters({ newsletters }).
            then(function(response) {
              $scope.signedUp = true;
            }).catch(unwrapError);

        });
      }
    }]
  };
}
