import { DBookingRequest } from 'spc/lib/database/types/booking-request';

import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import AuthorizedClientHelpers from 'common/dist/virtuals/AuthorizedClient';

// constant
import { REFUND_TITLES } from '../../receipts/refundTitles';
import { convertURL } from '../../services/convertUrl';
class PaymentSnapshotController {
  convertUrl: (url) => string;
  request: DBookingRequest;
  selectedPayment: any;
  client: any;
  finalReceipt: boolean;
  assignee: any;
  paymentIdx: number;
  pdf: any;
  numPages: number;
  pages: string[];
  attachments: any;
  constructor(
    private $window, private $cloudinary, private unwrapError, private $scope
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.convertUrl = convertURL;
    this.attachments = get(this.request, 'data.payment.attachments') as string[];
    if (this.attachments) {
      Promise.all(this.attachments.map((attachment) => {
        if (attachment.type !== 'Direct' && this.$cloudinary.isPDF(attachment)) {
          attachment = this.getPageCount(attachment);
        }
        return attachment;
      }))
      .then((res) => {
        this.attachments = res;
        this.$scope.$apply();
      });
    }
  }

  hasAttachments = () => {
    return this.attachments && this.attachments.length;
  }

  $onChanges = () => {
    this.assignee = get(this.request, 'admin.assignee');

    if (!this.finalReceipt) {
      this.paymentIdx = findIndex(this.request.paymentSchedule, (p: any) => {
        return p._id.toString() === this.selectedPayment._id.toString();
      });
    }

    this.client = AuthorizedClientHelpers.primaryEventContact(this.request).user;
  }

  getTitle = () => {
    const selectedPayment = this.selectedPayment;

    if (selectedPayment.state !== 'REFUNDED') {
      return;
    }

    const title = REFUND_TITLES[selectedPayment.descriptor] ? `${REFUND_TITLES[selectedPayment.descriptor]} Receipt` : 'Intermediate Refund Receipt';
    return title;
  }

  getPageCount = (attachment) => {
    return this.$cloudinary.getPageCount(attachment)
      .then(res => res)
      .catch(error => this.unwrapError(error));
  }

  print = () => {
    return this.$window.print();
  }

  displayScheduleBreakdown = () => {
    return this.request && !this.finalReceipt && Number.isFinite(this.paymentIdx);
  }

}

export const paymentSnapshotComponent = {
  template: require('./payment-snapshot.component.jade'),
  controller: PaymentSnapshotController,
  bindings: {
    selectedPayment: '<',
    finalReceipt: '<',
    request: '<',
  }
};
