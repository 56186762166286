/**
 * Open a ngDialog that shows detailed information about a drinks package
 *
 * @param {Drink} drink
 * @return {Promise} resolved when this modal is closed
 */

module.exports = ['ngDialog', function(ngDialog) {
  return function(drink) {
    return ngDialog.
      open({
        template: require('./drink-detail.jade'),
        className: 'ngdialog-theme-small info-modal',
        overlay: true,
        plain: true,
        controller: ['$scope', 'Data', function($scope, Data) {
          $scope.drink = Data;
        }],
        resolve: {
          Data: function() { return drink; }
        }
      }).
      closePromise;
  };
}];
