import { ApiService } from 'spc/shared/api/api.service';
import { RawLocation } from 'database/types/location';

import get from 'lodash/get';

class EditCitiesController {
  locations: RawLocation[];
  selectedLocation: RawLocation;
  ui: {
    neighborhood?: string;
    cityName?: string;
  };

  constructor(private $api: ApiService, private $rootScope, private unwrapError, private $clickOk) {
    'ngInject';
  }

  $onInit = () => {
    return this.$api.Admin.Locations.get()
      .then((data) => {
        this.locations = data.locations;
        this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
      });
  }

  backButton = () => '<';

  addNeighborhood = () => {
    this.selectedLocation.neighborhoods.push(this.ui.neighborhood);
    this.ui.neighborhood = '';
  }

  removeNeighborhood = (hood) => {
    this.selectedLocation.neighborhoods = this.selectedLocation.neighborhoods.filter(neighborhood => neighborhood !== hood);
  }

  finishEditing = () => {
    return this.$api.Admin.Locations.update({
      location: this.selectedLocation,
      changes: { neighborhoods: this.selectedLocation.neighborhoods }
    })
    .then(() => this.selectedLocation = null)
    .catch(error => this.unwrapError(error));
  }

  addLocation = (details) => {
    const location = {
      isTopLevelLocation: false,
      city: details.city,
      state: details.state,
      country: details.country,
      area: details.vicinity,
      map: {
        center: {
          lat: details.latitude,
          lng: details.longitude
        }
      }
    };

    return this.$api.Admin.Locations.create(location)
      .then(() => this.$api.Admin.Locations.get())
      .then((data) => {
        this.locations = data.locations;
        this.ui.cityName = '';
      })
      .catch(error => this.unwrapError(error));
  }

  executeDelete = (location) => {
    return this.$api.Admin.Locations.delete(location)
      .then(() => this.locations = this.locations.filter(_location => _location._id !== location._id))
      .catch(error => this.unwrapError(error));
  }

  deleteLocation = (location: RawLocation) => {
    if (!location.parent && !location.isTopLevelLocation) {
      return this.executeDelete(location);
    }
    const showCancel = true;
    return this.$clickOk('Deleting a city is permanent. If this city is a search page or a sub city of a search page, it will be removed.', showCancel)
      .then((response) => {
        if (get(response, 'value.cancel')) {
          return;
        }
        return this.executeDelete(location);
      })
      .catch(error => this.unwrapError(error));
  }
}

export const editCitiesComponent = {
  template: require('./edit-cities.component.jade'),
  controller: EditCitiesController
};
