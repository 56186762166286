
import { get } from 'lodash';
import moment from 'moment';
import { DBaseUser } from 'spc/lib/database/types/base-user';
import { RawLead } from 'spc/lib/database/types/lead';

interface MembersLeads extends RawLead {
  bookedReco: any;
  eventDate: moment.Moment;
}
interface MembersArray {
  member: DBaseUser;
  checked: boolean;
}
export class AccountMemberLeadsDetailsService {
  selectionArray?: any[] = [];
  numLeads: number;
  leads: MembersLeads[];
  confirmedLeads: RawLead[] = [];
  activeLeads: RawLead[] = [];
  concludedLeads: RawLead[] = [];
  inactiveLeads: RawLead[] = [];
  cities: string[] = [];
  constructor(private ENUMS) {
    'ngInject';
  }

  updateSelection({ member, check, members }: { member: string, check: boolean, members: MembersArray[] }) {
    const data = !check ? check = true : check = false;
    const userId = member.toString();
    members.forEach((m) => {
      if (m.member._id.toString() === userId && data && this.selectionArray.includes(userId)) {
        return;
      } else if (m.member._id.toString() === userId && data && !this.selectionArray.includes(userId)) {
        this.selectionArray.push(member);
      } else if (m.member._id.toString() === userId && !data && this.selectionArray.includes(userId)) {
        this.selectionArray.splice(this.selectionArray.indexOf(userId), 1);
      }
    });
    return this.selectionArray;
  }

  setLeads = (res) => {
    this.leads = res.totalAccountMemberLeads ? res.totalAccountMemberLeads : res.accountMembersLeads;
    this.cities = res.cities;

    // here we have to clear arrays.
    this.activeLeads.length = 0;
    this.confirmedLeads.length = 0;
    this.inactiveLeads.length = 0;
    this.concludedLeads.length = 0;

    this.numLeads = res.numLeads;
    this.leads.forEach((lead) => {
      const bookedReco = lead.recommendations.find(reco => this.isBookedEvent(get(reco, 'conversation.request')));
      const cancelledReco = lead.recommendations.length ? lead.recommendations.every(reco => this.isCancelledEvent(get(reco, 'conversation.request' ))) : false;
      if (bookedReco) {
        lead.eventDate = moment(bookedReco.conversation.request.data.date).local();
      } else if (lead.request.date) {
        lead.eventDate = moment(lead.request.date).local();
      }
      const yesterday = moment().subtract(1, 'day');
      if (['Lost', 'Killed'].includes(lead.currentStatus)) {
        this.inactiveLeads.push(lead);
      }
      else if (lead.eventDate > yesterday && bookedReco) {
        this.confirmedLeads.push(lead);
        lead.bookedReco = bookedReco;
      } else if (lead.eventDate > yesterday && !bookedReco && !cancelledReco) {
        this.activeLeads.push(lead);
      } else if (lead.eventDate < yesterday && bookedReco) {
        this.concludedLeads.push(lead);
      } else if (!lead.eventDate) {
        this.activeLeads.push(lead);
      } else {
        this.inactiveLeads.push(lead);
      }
    });

    const orderdLeads = {
      leads: this.leads,
      activeLeads : this.activeLeads,
      inactiveLeads: this.inactiveLeads,
      concludedLeads: this.concludedLeads,
      confirmedLeads: this.confirmedLeads,
      numLeads: this.numLeads,
      cities: this.cities
    };
    return orderdLeads;
 }

 getPrimaryClient = ({ searchParams, members }) => {
  const primaryClients = [];
  members.forEach((member) => {
    searchParams.owner.forEach((owner) => {
      if (member.fullName === owner) {
        primaryClients.push(member._id.toString());
      } else if (member._id === owner && !primaryClients.includes(owner)) {
        primaryClients.push(owner);
      }
    });
  });
  return searchParams.primaryClients = primaryClients;
 }

  isCancelledEvent = (event) => {
    if (!event) {
      return;
    }
    return this.ENUMS.bookingRequestState.cancelledStates.includes(event.state);
  }

  isBookedEvent = (event) => {
    if (!event) {
      return;
    }
    return this.ENUMS.bookingRequestState.bookedStates.includes(event.state);
  }
}
