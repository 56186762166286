import map from 'lodash/map';
import each from 'lodash/each';

const DEFAULT_STYLES = [
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }]
  }
];

const RED_ICON = '//s3.amazonaws.com/sixplus-assets/images/map-marker-luxury-18.png';
const BLACK_ICON = '//s3.amazonaws.com/sixplus-assets/images/map-marker-black-18.png';


export class ListMapService {
  google: any;
  map: any;
  markers: any;
  infoWindow: any;
  selectedVenueId: string;
  clicked: boolean;

  constructor(private $window, private $scrollService, private listVenueViewModal) {
    'ngInject';
    this.google = this.$window.google;
  }

  init(element, config) {
    this.map = new this.google.maps.Map(element, config);
    this.map.setOptions({ styles: DEFAULT_STYLES });
  }

  triggerMarker = (id: string) => {
    if (this.markers) {
      const selectedMarker = this.markers.find(marker => marker._id === id);
      this.google.maps.event.trigger(selectedMarker, 'card_hover');
    }
  }

  resetIcon = (id: string) => {
    if (this.markers) {
      const selectedMarker = this.markers.find(marker => marker._id === id);
      selectedMarker && selectedMarker.setIcon(BLACK_ICON);
    }
  }

  drawVenueMarkers = ({ venues, scope }) => {
    each(this.markers, m => m.setMap(null));
    const bounds = new this.google.maps.LatLngBounds();
    const squashedVenues =  venues.map((currentVenue, currentIndex) => {
      currentVenue.info = `<span4><span2><bubble>${currentIndex + 1}</bubble></span2><span3>${currentVenue.data.name} <span></span1></span3></span4>`;
      return currentVenue;
    });

    this.markers = [];
    this.markers = map(squashedVenues, (venue: { _id: string; data: any; info: any }) => {
      const point = new this.google.maps.LatLng(venue.data.address.coordinates[1], venue.data.address.coordinates[0]);
      const marker = new this.google.maps.Marker({
        map: this.map,
        position: point,
        _id: venue._id,
        icon: BLACK_ICON
      });

      bounds.extend(marker.position);

      this.google.maps.event.addListener(marker, 'click', (ev) => {
        this.clicked = true;
        this.selectedVenueId = venue._id;
        if ($(window).width() > 990) {
          this.$scrollService(`#${venue._id}`, 0);
        }
        this.setInfoWindow(venue, marker, scope, 'click');
        scope.$apply();
      });

      this.google.maps.event.addListener(marker, 'mouseover', (ev) => {
        if (!this.clicked) {
          this.selectedVenueId = venue._id;
          this.setInfoWindow(venue, marker, scope, 'mouseover');
          marker.setIcon(RED_ICON);
          scope.$apply();
        }
      });

      this.google.maps.event.addListener(marker, 'mouseout', (ev) => {
        if (!this.clicked) {
          this.selectedVenueId = null;
          this.infoWindow.close();
          marker.setIcon(BLACK_ICON);
          scope.$apply();
        }
      });

      this.google.maps.event.addListener(this.map, 'click', (ev) => {
        this.selectedVenueId = null;
        this.infoWindow.close();
        marker.setIcon(BLACK_ICON);
        scope.$apply();
      });

      this.google.maps.event.addListener(marker, 'card_hover', (ev) => {
        this.selectedVenueId = venue._id;
        this.setInfoWindow(venue, marker, scope, 'click');
        marker.setIcon(RED_ICON);
        scope.$applyAsync();
      });

      this.google.maps.event.addListener(marker, 'cardClick', (ev) => {
        this.clicked = true;
        this.selectedVenueId = venue._id;
        this.setInfoWindow(venue, marker, scope, 'click');
      });

      return marker;
    });

    this.map.fitBounds(bounds);

    if (this.markers.length === 1) {
      this.map.setZoom(13);
    } else {
      this.map.setCenter(bounds.getCenter());
    }
  }

  openVenueViewModal(venue) {
    const id = venue._id.toString();
    return this.listVenueViewModal(id, 'VENUE');
  }

  setInfoWindow = (venue, marker, scope, event) => {
    if (this.infoWindow) {
      this.infoWindow.close();
    }
    const contentString = `
    <div class="list-map-pin-box">
      <h4 id="map-venue">${venue.data.name}</h4>
      <h5>${venue.data.address.line1}</h5>
      <h5><span>${venue.data.address.neighborhood}</span> | ${venue.data.cuisineTypes.join(', ')}</h6>
      <div>${venue.admin.ambiance}</div>
    </div>`;
    const disableAutoPan = event === 'click' ? false : true;
    this.infoWindow = new this.google.maps.InfoWindow({ content: contentString, disableAutoPan: disableAutoPan });

    this.google.maps.event.addListener(this.infoWindow, 'domready', (ev) => {
      const mapVenue = document.getElementById('map-venue');
      if (mapVenue) {
        this.google.maps.event.addDomListener(mapVenue, 'click', (ev) => {
          this.openVenueViewModal(venue);
        });
      }
    });

    this.google.maps.event.addListener(this.infoWindow, 'closeclick', (ev) => {
      this.selectedVenueId = null;
      this.clicked = false;
      marker.setIcon(BLACK_ICON);
      scope.$apply();
    });

    this.infoWindow.open(this.map, marker);
  }

  selectMarker(venueId) {
    this.selectedVenueId = venueId;
    const selectedMarker = this.markers.find(marker => marker._id === venueId);
    this.google.maps.event.trigger(selectedMarker, 'cardClick');
  }
}
