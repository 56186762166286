// External Dependencies
import { get } from 'lodash';
import { fullPriceBreakdownCents } from 'common/dist/price';
import AuthorizedClientHelpers from 'common/dist/virtuals/AuthorizedClient';
// Our Dependencies
import { ApiService } from 'spc/shared/api/api.service';
import { UserService } from 'spc/services/user.service';

class RequestPreview {
  messages: any[];
  request: any;
  user: any;
  costBreakdownCents: any;
  attachments: any[];
  assignee: any;
  openSidebarMessage: boolean;
  closeSidebarMessage: boolean;
  hiddenSummary: boolean;
  tab: string = `REVIEW`;
  ui: {
    message?: string;
    displayRespondButton: boolean;
    unauthorizedMessage?: string;
  } = { displayRespondButton: false };
  constructor(private $scope: ng.IScope,
    private $api: ApiService,
    private loginRegisterModal,
    private frozenRegistrationModal,
    private $cloudinary,
    private proposalContactsModal,
    private unwrapError,
    private $user: UserService,
    private leadAuthenticationModal,
    private $location: ng.ILocationService
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.$user.$waitFor(`LOGGED_IN`, () => {
      this.user = this.$user.$;
      this.confirm();
    });

    this.$user.$waitFor(`NOT_LOGGED_IN`, () => {
      this.ui.displayRespondButton = true;
    });

    this.assignee = get(this, 'request.admin.assignee');
  }

  $onChanges = (changes) => {
    if (changes.request.isFirstChange()) {
      this.costBreakdownCents = fullPriceBreakdownCents(this.request);
    }
  }

  public determineLogInMessage = () => {
    return `Log in to continue`;
  }

  public isUserHost() {
    return this.user.roles.includes('Host');
  }

  public openSidebar = () => {
    this.openSidebarMessage = true;
    this.closeSidebarMessage = false;
  }

  public isUserOnRequest = () => this.request.clients.some(client => (get(client, 'user._id')).toString()  === this.user._id.toString());

  public closeSidebar = () => {
    this.closeSidebarMessage = true;
    this.openSidebarMessage = false;
  }

  public hideSummary = () => {
    this.hiddenSummary = !this.hiddenSummary;
  }


  public submit = () => {
    if (this.user) {
      return this.openFrozenRegistrationModal();
    }
    return this.openLoginRegisterModal();
  }

  public isLoggedInUser = () => {
    if (!this.user) {
      return;
    }

    return true;
  }

  public isPublicUser = () => {
    return !this.user;
  }

  private confirm = () => {
    if (this.user && this.isUserOnRequest()) {
      this.redirect(`/client/conversation/${this.request.conversation}`);
    }
  }

  private redirect = (url) => {
    this.$location.url(url);
  }

  private nonDeletedAttachments (attachments) {

    if (attachments && attachments.length) {
      const conversationAttachments = attachments.filter(attachment => !attachment.isDeleted && attachment.file.title !== 'Link').length;
      return conversationAttachments;
    }
  }

  private openLoginRegisterModal = () => {
    this.loginRegisterModal({
      request: this.request,
      userService: this.$user
    })
      .then((data) => {
        if (get<boolean>(data, 'value.register') || get<boolean>(data, 'value.login')) {
          this.ui.displayRespondButton = false;
          return this.setUser();
        }
      })
      .catch(error => this.unwrapError(error));
  }

  private setUser = () => {
    this.$user.load()
    .then((res) => {
      this.user = this.$user.$;
      return this.confirm();
    })
    .catch(error => this.unwrapError(error));
  }

  private openProposalContactsModal = () => {
    return this.proposalContactsModal({
      request: this.request,
      conversation: this.request.conversation,
      venue: this.request.venue,
      sixplusContacts: false,
      previewUser: true
    });
  }

  private openFrozenRegistrationModal = () => {
    this.frozenRegistrationModal(this.user)
      .then((data) => {
        if (get<boolean>(data, 'value.register')) {
          this.ui.displayRespondButton = false;
          return this.confirm();
        }
      })
      .catch(error => this.unwrapError(error));
  }

  handleLinkClick = () => {
    return this.leadAuthenticationModal(this.request)
      .catch(error => this.unwrapError(error));
  }
}

export const RequestPreviewComponent = {
  template: require('./request-preview.component.jade'),
  controller: RequestPreview,
  bindings: {
    request: '<',
    assignee: '<',
    client: '<',
    user: '<',
    venueUrl: '<',
    messages: '<',
    attachments: '<'
  }
};
