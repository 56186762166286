export const listOrderModal = ['ngDialog', function(ngDialog) {
    return function (list, venues) {
      return ngDialog.open({
        template: `<list-order list="$ctrl.list" venues="$ctrl.venues" close="$ctrl.close"> </list-order>`,
        plain: true,
        className: 'ngdialog-theme-plain',
        overlay: true,
        controllerAs: '$ctrl',
        controller: ['$scope', function($scope) {
          this.list = list;
          this.venues = venues;
          this.close = (response) => {
            $scope.closeThisDialog(response);
          };
        }]
      }).closePromise;
    };
  }];
