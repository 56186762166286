import { response } from 'express';
import mongoose from 'mongoose';
import {
  CreateExperienceRequest,
  CreateExperienceResponse,
  CreateExperienceCloneResponse,
  GetExperienceByVendorResponse,
  ValidateExperienceRequest,
  ValidateExperienceResponse,
  GetExperienceResponse,
  UpdateExperienceResponse,
  ExperienceQueryResponse,
  ExperienceQueryRequest,
  DeleteExperienceResponse,
  SearchedExperienceQueryResponse,
} from 'server/api/admin/experience/models';
import { RawExperience, OrganiseExperienceQuery, ExperienceQuery } from 'spc/lib/database/types/virtual-events';

export class Experience {
  BASE_URL: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${API_ROOT}/admin/experience`;
  }

  public createExperience = ({ experience, vendorId }: { experience: RawExperience, vendorId: mongoose.Types.ObjectId | string }): ng.IPromise<CreateExperienceResponse> => {
    experience.vendor = vendorId;
    return this.$http.post(`${ this.BASE_URL }`, { experience })
      .then((res: { data: CreateExperienceResponse }) => res.data);
  }

  public cloneExperience = ({ id }: { id: mongoose.Types.ObjectId | string }): ng.IPromise<CreateExperienceCloneResponse> => {
    return this.$http.post(`${this.BASE_URL}/clone/${id}`, null)
      .then((res: {data: CreateExperienceCloneResponse }) => {
         return res.data;
      });
  }

  public validate = ({ experience, vendorId }: { experience: RawExperience, vendorId: mongoose.Types.ObjectId | string }): ng.IPromise<ValidateExperienceResponse> => {
    experience.vendor = vendorId;
    return this.$http.post(`${ this.BASE_URL }/validate`, { experience })
      .then((res: { data: ValidateExperienceResponse }) => {
        return res.data;
      });

  }

  public getAllByVendor = ({ vendorId }: { vendorId: mongoose.Types.ObjectId}): ng.IPromise<GetExperienceByVendorResponse> => {
    return this.$http.get(`${ this.BASE_URL }/vendor/${vendorId}`)
      .then((res: { data: GetExperienceByVendorResponse }) => res.data);
  }

  public getExperienceById = ({ experienceId }: { experienceId: mongoose.Types.ObjectId | string }): ng.IPromise<GetExperienceResponse> => {
    return this.$http.get(`${ this.BASE_URL }/${experienceId}`)
      .then((res: { data: GetExperienceResponse }) => res.data);
  }

  public updateExperienceById = ({ updates,  experienceId }:  { updates: RawExperience, experienceId: mongoose.Types.ObjectId | string }): ng.IPromise<UpdateExperienceResponse> => {
    return this.$http.put(`${ this.BASE_URL }/${experienceId}`, { updates })
      .then((res: { data: UpdateExperienceResponse}) =>  res.data);
  }

  public updateExperienceOrder = ({ updates }: { updates: OrganiseExperienceQuery [] }) => {
    return this.$http.put(`${ this.BASE_URL }`, { updates })
      .then((res) => {
        return res;
      });
  }

  public getAll = ({ query }: { query: ExperienceQuery }): ng.IPromise<ExperienceQueryResponse> => {
    return this.$http.get(`${ this.BASE_URL }`, { params: query })
      .then(( res: { data: ExperienceQueryResponse }) => {
        return res.data;
    });
  }

  public getAllPriorityExperience = (): ng.IPromise<SearchedExperienceQueryResponse> => {
    return this.$http.get(`${this.BASE_URL}/priority`)
      .then((res: { data: SearchedExperienceQueryResponse }) => {
        return res.data;
      });
  }

  public deleteExperienceById = ({ id }: { id: mongoose.Types.ObjectId | string }): ng.IPromise<DeleteExperienceResponse> => {
    return this.$http.delete(`${ this.BASE_URL }/${id}`)
      .then((res: { data: DeleteExperienceResponse}) => {
        return res.data;
    });
  }
}
