class ClientMultiselectController {
  options: string[];
  selectedOptions: string[];
  closeMultiselect: ({ selectedOptions }: { selectedOptions: string[]}) => any;

  $onInit = () => {
    this.selectedOptions = this.selectedOptions || [];
  }

  handleClick = (option: string, $event: any) => {
    $event.stopPropagation();
    if (this.selectedOptions === undefined) {
      this.selectedOptions = [];
    }
    const index = this.selectedOptions.indexOf(option);
    index > -1 ? this.selectedOptions.splice(index, 1) : this.selectedOptions.push(option);
  }

  closeDropdown = () => {
    this.closeMultiselect({ selectedOptions: this.selectedOptions });
  }

  clearAll = () => {
    this.selectedOptions = [];
    this.closeDropdown();
  }
}

export const ClientMultiselectComponent = {
  controller: ClientMultiselectController,
  template: require('./client-multiselect.component.jade'),
  bindings: {
    options: '<',
    closeMultiselect: '&',
    selectedOptions: '<'
  }
};
