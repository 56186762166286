const HIDE_NAVBAR = 'HIDE_NAVBAR';
const SHOW_NAVBAR = 'SHOW_NAVBAR';

export const spScrollDirective  = ['$window', function ($window) {
  return {
    restrict: 'A',
    link: function (scope, $element, attrs) {
      let currentState = SHOW_NAVBAR;

      const scrollHandler = function () {
        if ($window.pageYOffset > attrs.offset) {
          if (currentState !== HIDE_NAVBAR) {
            scope.$ctrl.state.emit(HIDE_NAVBAR);
            currentState = HIDE_NAVBAR;
          }
        } else {
          if (currentState !== SHOW_NAVBAR) {
            scope.$ctrl.state.emit(SHOW_NAVBAR);
            currentState = SHOW_NAVBAR;
          }
        }
      };

      $($window).scroll(scrollHandler);

      scope.$on('$destroy', function () {
        $($window).off('scroll', scrollHandler);
      });
    }
  };
}];
