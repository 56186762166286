import { ApiService } from 'spc/shared/api/api.service';
import { RecommendationService } from 'spc/shared/recommendation.service';
import { RecoTransferService } from './reco-transfer.service';

class RecoTransferController {
  ui: {
    displayOptions: Boolean;
  } = { displayOptions: false };
  leads: any;
  lead: { _id: string, recommendations: any; };
  recommendation: any;
  updateList: ({ recommendations }) => any;
  close: () => any;
  constructor(
    private $api: ApiService,
    private unwrapError,
    private recoTransferService: RecoTransferService
  ) {
    'ngInject';
  }

  displayTransferOptions = () => {
    return this.recoTransferService.allowRecoTransfer(this.lead);
  }

  transferReco = (lead) => {
    this.recoTransferService.transferReco({ lead, recommendation: this.recommendation })
      .then(() => {
        this.lead.recommendations = this.lead.recommendations.filter(reco => reco._id !== this.recommendation._id);
        this.ui.displayOptions = false;
        this.updateList({ recommendations: this.lead.recommendations });
        return this.close();
      })
      .catch(error => this.unwrapError(error));
  }
}

export const RecoTransferComponent = {
  template: require('./reco-transfer.component.jade'),
  controller: RecoTransferController,
  bindings: {
    recommendation: '<',
    lead: '<',
    updateList: '&',
    close: '&'
  }
};
