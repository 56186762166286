const proposalRequestAccess = ['ngDialog', function(ngDialog) {
  return function ({ lead }) {
    return ngDialog.open({
      template: require('./proposal-request-access.modal.jade'),
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.lead = lead;
        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];

export default proposalRequestAccess;
