import { Experience } from './../../shared/api/admin/experience';
import { ApiService } from '../../shared/api/api.service';
import { RawVendor } from '../../../../database/types/virtual-events';
import { get, every, debounce , cloneDeep, findIndex } from 'lodash';
import * as CONSTANTS from '../../../../database/constants/Experience';

const newVendor: RawVendor = {
  additionalRequirements : CONSTANTS.VENDOR_DEFAULT_ADDITIONAL_REQUIREMENTS,
  name : '',
  description: '',
  url: '',
  city: '',
  hosts: [],
  items: [],
  termsAndConditions: CONSTANTS.VENDOR_DEFAULT_TERMS_AND_CONDITIONS,
  addOns: []
};

class AdminCreateEditVendorsDashboardController {
  ready: () => void;
  vendor: RawVendor;
  isModeCreate: boolean = true;
  errors: {
    [key: string]: {
      message: string
    }
  } | null = {};
  isFormDataValid = false;
  requiredFields: string[];
  originalVendor: RawVendor;
  vendorId: any;
  isReady: boolean = false;
  experiences ;
  editVendorDetails: boolean = false;
  constructor(
    private $api: ApiService,
    private unwrapError,
    private $routeParams,
    private $seo,
    private $cloudinary,
    private $location,
    private $clickOk
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.isModeCreate = true;
    this.requiredFields = ['name', 'description'];
    const vendorId = this.$routeParams.id;
    if (vendorId) {
      this.vendorId = vendorId;
      this.isModeCreate = false;
      this.getVendorDetails(vendorId);
    }
    else {
      this.isReady = true;
      this.ready();
      this.vendor = newVendor;
    }
    this.$seo.set('Create Vendor');

  }

  getVendorDetails = (vendorId) => {
    this.$api.Admin.Vendors.getVendorById({ vendorId })
    .then((response) => {
      this.originalVendor = response.vendor;
      this.vendor = cloneDeep(this.originalVendor);
      this.$seo.set(`${this.vendor.name}`);
      this.getExperiences();
    })
    .catch((error) => {
      this.isReady = true;
      this.unwrapError(error);
      this.ready();
      return;
    });
  }

  validate = (path, vendor = this.vendor) => {
    this.$api.Admin.Vendors.validate({ vendor })
      .then((response) => {
        const errors = get(response, 'errors.vendor');
        if (errors) {
          const pathErrors = get(errors, path);
          this.errors[path] = pathErrors ? pathErrors.message : null;
        } else {
          this.errors = {};
        }
      })
      .catch((error) => {
        this.unwrapError(error);
      });
  }

  checkForErrors = debounce(this.validate, 300) ;

  submit = () => {
    if (this.isModeCreate) {
    this.$api.Admin.Vendors.createVendor({ vendor: this.vendor })
      .then((response) => {
        this.vendor = {};
        const vendorId = response.vendor._id;
        this.$location.path(`/admin/vendors/${vendorId}/edit`);
      })
      .catch((error) => {
       this.unwrapError(error);
      });
    }
    else {
      const updates = this.getUpdates();
      this.$api.Admin.Vendors.updateVendorById({ vendorId: this.vendorId, updates })
      .then((response) => {
        this.vendor = cloneDeep(response.vendor);
        this.originalVendor = cloneDeep(response.vendor);
        this.toggleEditVendorDetails();
      })
       .catch((error) => {
       this.unwrapError(error);
      });
    }
  }

  cloneExperience = (id) => {
    this.$api.Admin.Experience.cloneExperience({ id })
    .then((response) => {
      const clonedExperience = response.experience;
      this.experiences.push(clonedExperience);
    })
    .catch((error) => {
      this.unwrapError(error);
      this.isReady = true;
      this.ready();
    });
  }

  executeDelete = (id) => {
    return this.$api.Admin.Experience.deleteExperienceById({ id })
    .then((response) => {
      const deletedExperience = response.experience;
      const index = this.experiences.indexOf(deletedExperience);
      this.experiences.splice(index, 1);
    })
    .catch((error) => {
      this.unwrapError(error);
      this.isReady = true;
      this.ready();
    });
  }

  deleteExperience = (id) => {
    const showCancel = true;
    return this.$clickOk('Deleting this experience is permanent. Are you sure you want to continue?', showCancel)
    .then((res) => {
      if (get(res, 'value.cancel')) {
        return;
      }
      return this.executeDelete(id);
    })
    .catch(error => this.unwrapError(error));
  }


  getUpdates = () => {
    const updates: RawVendor = {};
    if (this.vendor.name !== this.originalVendor.name) {
      updates.name = this.vendor.name;
    }
    if (this.vendor.description !== this.originalVendor.description) {
      updates.description = this.vendor.description;
    }
    if (this.vendor.url !== this.originalVendor.url) {
      updates.url = this.vendor.url;
    }
    if (this.vendor.city !== this.originalVendor.city) {
      updates.city = this.vendor.city;
    }
    return updates;
  }

  checkIfFormDataInValid = () => {
    const errorsPath = Object.keys(this.errors);
    let result = null;
    errorsPath.forEach((path) => {
      result = this.errors[path] || result;
    });
    return result === null;
  }

  hasAllTheRequiredFields = () => {
    const requiredFields = [
      get(this.vendor, 'name'),
      get(this.vendor, 'description'),
    ];
    return every(requiredFields);
  }

  getExperiences() {
    this.$api.Admin.Experience.getAllByVendor( { vendorId : this.vendorId })
    .then(((response) => {
      this.experiences = response.experiences;
      this.isReady = true;
      this.ready();
    }))
    .catch((error) => {
      this.unwrapError(error);
      this.isReady = true;
      this.ready();
    });
  }

  cancel = () => {
    return this.$location.path('/admin/vendors');
 }

 onStatusUpdate = (experience) => {
   const experienceIndex = findIndex(this.experiences, item => item['_id'] === experience._id);
   this.experiences[experienceIndex].status = experience.status;
 }

 toggleEditVendorDetails = () => {
   this.editVendorDetails = !this.editVendorDetails;
 }

 cancelEdit = () => {
   const originalBasicDetails = {
     name : this.originalVendor.name,
     description: this.originalVendor.description,
     url: this.originalVendor.url,
     city: this.originalVendor.city
   };
   this.vendor = { ...this.vendor, ...originalBasicDetails };
   this.toggleEditVendorDetails();
 }

}

export const adminCreateEditVendorsDashboardComponent = {
  template: require('./admin-create-edit-vendors-dashboard.component.jade'),
  controller: AdminCreateEditVendorsDashboardController,
  bindings: {
    ready: '<',
  },
};
