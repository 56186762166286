class TopLevelRecosController {
  canInit: boolean;
  id: string;

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $seo,
    private $routeParams,
    private $user,
    private $scope) {
      'ngInject';
  }

  $onInit() {
    this.canInit = true;
    this.id = this.$routeParams.id;
  }

  redirect = (url) => {
    this.$location.path(url);
  }

  sliderMessage = (message) => {
    this.$scope.$broadcast('MESSAGE_SENT', { message });
  }

  componentIsReady = () => {
    this.$seo.set(`Event Page`);
    this.$rootScope.$emit('$viewReady', 'SHOW_FOOTER');
  }
}

export const TopLevelRecosComponent = {
  controller: TopLevelRecosController,
  template: require('./top-level-recos.component.jade')
};
