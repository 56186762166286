import { get, every, filter, cloneDeep, isEqual, debounce } from 'lodash';
import { ApiService } from '../../../shared/api/api.service';
import {
   RawVendor, TermsAndCondition
} from 'spc/lib/database/types/virtual-events';
import { VIRTUAL_EVENTS_CHANGE } from '../../../../../database/constants/virtualEventsChange';

const newCondition: TermsAndCondition = {
  termName: '',
  description: '',
};

class TermsAndConditionsController {
  currentConditionIndex: number;
  isFormDisplayed: boolean = false;
  condition: TermsAndCondition = cloneDeep(newCondition);
  conditions: TermsAndCondition[]  = [];
  vendor: RawVendor;
  errors: {
    [key: string]: {
      message: string
    }
  } | null = {};
  object: any;
  type: string;
  isConditionEdit: boolean = false;
  updateReadyStatus: any;

  constructor(private unwrapError, private $api: ApiService, private $scope) {
    'ngInject';
  }

  $onInit = () => {
    if (this.object) {
      if (this.object.termsAndConditions && this.object.termsAndConditions.length) {
        this.conditions = cloneDeep(this.object.termsAndConditions);
      }
    }
     this.$scope.$on(VIRTUAL_EVENTS_CHANGE.VIRTUAL_TERM_CHANGE, this.cloneOriginalConditionToCondition);
  }

  cloneOriginalConditionToCondition = () => {
    this.conditions = cloneDeep(this.object.termsAndConditions);
  }

  validateCondition = (path) => {
    const currentItemPath = `termsAndConditions.${this.currentConditionIndex}.${path}`;
    if (this.type === 'vendor') {
      this.$api.Admin.Vendors.validate({
        vendor: { termsAndConditions: this.conditions },
      })
        .then((response) => {
          const errors = get(response, 'errors.vendor');
          if (errors) {
            const pathErrors = get(errors, currentItemPath);
            this.errors[currentItemPath] = pathErrors
              ? pathErrors.message
              : null;
          } else {
            this.errors = {};
          }
        })
        .catch((error) => {
          this.unwrapError(error);
        });
    } else if (this.type === 'experience') {
      this.$api.Admin.Experience.validate({
        experience: { termsAndConditions: this.conditions },
        vendorId: this.vendor['_id'],
      })
        .then((response) => {
          const errors = get(response, 'errors.experience');
          if (errors) {
            const pathErrors = get(errors, currentItemPath);
            this.errors[currentItemPath] = pathErrors
              ? pathErrors.message
              : null;
          } else {
            this.errors = {};
          }
        })
        .catch((error) => {
          this.unwrapError(error);
        });
    }
  }

  checkForErrors = debounce(this.validateCondition, 300);

  createNewCondition = () => {
    this.displayForm(true);
    const length = this.conditions.push(this.condition);
    this.currentConditionIndex = length - 1;
  }

  resetCondition = () => {
    this.condition = cloneDeep(newCondition);
  }

  displayForm(value) {
    this.isFormDisplayed = value;
  }

  cancel() {
    if (!this.isConditionEdit) {
      this.conditions = filter(
        this.conditions,
        (_, index) => index !== this.currentConditionIndex
      );
    } else {
        this.conditions[this.currentConditionIndex] = cloneDeep(
        this.object.termsAndConditions[this.currentConditionIndex]
      );
    }
    this.isConditionEdit = false;
    this.resetCondition();
    this.displayForm(false);
  }

  isFieldInvalid = (path) => {
    const fieldPath = `termsAndConditions.${this.currentConditionIndex}.${path}`;
    return this.errors[fieldPath] ? true : false;
  }

  getErrorMessage = (path) => {
    const fieldPath = `termsAndConditions.${this.currentConditionIndex}.${path}`;
    return this.errors[fieldPath];
  }

  submit = () => {
      if (this.type === 'vendor') {
        this.updateVendor();
      } else if (this.type === 'experience') {
        this.updateExperience();
      }
  }

  updateUi = () => {
    this.object.termsAndConditions = cloneDeep(this.conditions);
    this.displayForm(false);
    this.resetCondition();
    this.isConditionEdit = false;
    if (this.type === 'experience') {
       this.updateReadyStatus();
    }
  }

  updateVendor = () => {
    const updates = { termsAndConditions: this.conditions };
    this.$api.Admin.Vendors.updateVendorById({
      updates,
      vendorId: this.object['_id'],
    })
      .then((res) => {
        this.conditions = res.vendor.termsAndConditions;
        this.updateUi();
      })
      .catch((err) => {
        this.unwrapError(err);
      });
  }

  updateExperience = () => {
    const updates = { termsAndConditions: this.conditions };
    this.$api.Admin.Experience.updateExperienceById({
      updates,
      experienceId: this.object['id'],
    })
      .then((response) => {
        this.conditions = response.experience.termsAndConditions;
        this.updateUi();
        this.object.status = response.experience.status;
      })
      .catch((err) => {
        this.unwrapError(err);
      });
  }

  checkIfFormDataInvalid = () => {
    const errorsPath = Object.keys(this.errors);
    let result = null;
    errorsPath.forEach((path) => {
      result = this.errors[path] || result;
    });
    return result;
  }

  hasAllTheRequiredFields = () => {
    const requiredFields = [
      get(this.condition, 'termName'),
      get(this.condition, 'description'),
    ];
    return every(requiredFields);
  }

  edit = (index) => {
    this.condition = this.conditions[index];
    this.displayForm(true);
    this.currentConditionIndex = index;
    this.isConditionEdit = true;
  }

  delete = (conditionIndex) => {
    const conditionToDelete = this.conditions[conditionIndex];
    if (isEqual(this.condition, conditionToDelete)) {
      this.condition = cloneDeep(newCondition);
      this.displayForm(false);
    }
    this.conditions = filter(
      this.conditions,
      (_, index) => index !== conditionIndex
    );
        if (this.type === 'experience') {
        this.updateExperience();
        this.updateReadyStatus();
      } else if (this.type === 'vendor') {
        this.updateVendor();
      }
  }
}

export const VirtualEventsConditionComponent = {
  controller: TermsAndConditionsController,
  template: require('./virtual-events-terms-and-condition.component.jade'),
  bindings: {
    updateReadyStatus: '<',
    vendor: '<',
    type: '<',
    object: '<'
  },
};
