export default function() {
  return {
    template: require('./user-drinks-new.jade'),
    controller: 'UserDrinksNew',
    scope: {
      Record: "=record",
      Slug: "=slug"
    }
  };
};
