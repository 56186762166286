import * as angular from 'angular';

import { AdminVenuesDashboardComponent } from './admin-venues-dashboard.component';
import { DownloadVenueComponent } from './download-venues.component';
import { VenueFilterSidebarComponent } from './venue-filter-sidebar.component';

import { AdminVenueService } from './admin-venue.service';
export default angular.module('admin.venues', [])
  .component('adminVenuesDashboard', AdminVenuesDashboardComponent)
  .component('venueFilterSidebar', VenueFilterSidebarComponent)
  .component('downloadVenues', DownloadVenueComponent)
  .service('adminVenueService', AdminVenueService)
  .name;
