
class TopLevelAdminConversationsDashboardController {
  canInit: boolean;

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $seo,
    private $user) {
    'ngInject';
  }

  $onInit() {
    this.$user.$waitFor('LOGGED_IN', () => {
      if (!this.$user.isAdmin()) {
        return this.redirectTo(`/404`);
      }

      this.canInit = true;
    });

    this.$user.$waitFor('NOT_LOGGED_IN', () => {
      this.redirectTo('/404');
    });
  }

  redirectTo = (url: string) => {
    return this.$location.path(url);
  }

  componentIsReady = () => {
    this.$seo.set(`Conversations | SixPlus`);
    this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
  }
}

export const TopLevelAdminConversationsDashboardComponent = {
  controller: TopLevelAdminConversationsDashboardController,
  template: require('./top-level-admin-conversations-dashboard.component.jade')
};
