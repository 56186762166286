export default ['ngDialog', function(ngDialog) {
  return function () {
    return ngDialog.open({
      template: `<create-base-user close="$ctrl.close" ></create-base-user>`,
      className: 'ngdialog-theme-plain',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function ($scope) {
        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];
