/**
 * Slides notification header down on list of comma-separated events
 */
module.exports = function() {
  return {
    template: require('./notification-slider.jade'),
    scope: {
      message: '=',
      slideDownEvents: '@'
    }
  };
};
