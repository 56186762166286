import { findIndex, get, set } from 'lodash';
import { ApiService } from 'spc/shared/api/api.service';
import debounce from 'lodash/debounce';
import ambianceStyleService from 'spc/services/ambianceStyleService';
import VenueSearchMap from 'spc/components/search/venue-search/venue-search-map.service';

const DEBOUNCE_LIMIT = 500;
let ctrl;

class AddSpaceForShellController {
  lead;
  close: () => void;

 constructor($rootScope, $routeParams, $location, private $api: ApiService, private unwrapError, $seo, ENUMS, $injector, $timeout, $user, VenueSearchService, private VenueSearchFiltersService, private $scrollService, private VenueSearchMapService: VenueSearchMap, private $window) {
    'ngInject';
    ctrl = this;
    ctrl.search;
    ctrl.addedSpaces = [];
    ctrl.spacesInLead = [];
    ctrl.$seo = $seo;
    ctrl.$analytics = $injector.get('$analytics');
    ctrl.ENUMS = ENUMS;
    ctrl.$routeParams = $routeParams;
    ctrl.$rootScope = $rootScope;
    ctrl.$timeout = $timeout;
    ctrl.$user = $user;
    ctrl.$location = $location;
    ctrl.VenueSearchService = VenueSearchService;
    ctrl.filterService = VenueSearchFiltersService;
    ctrl.isShow = false;
    ctrl.handleGuestChange = debounce(ctrl.basicSearch, 300);
    ctrl.showMap = false;
    ctrl.mapService = VenueSearchMapService;
  }

  $onInit() {
    ctrl.$location.search('page', null);
    this.lead.recommendations.map(reco => ctrl.spacesInLead.push(reco.space._id));
    ctrl.matchRouteToCity(this.lead.request.city)
      .then((location) => {
        ctrl.location = location;
        const page = ctrl.$location.search().page;
        const calledFromShell = true;
        ctrl.search = ctrl.VenueSearchService.activate(location, page, calledFromShell);
        ctrl.search.setViewType('SPACE');
        this.setFiltersFromLead(ctrl.search.filters);
        ctrl.search.applyFilters()
          .then(() => ctrl.search.setInitialCounts());
        ctrl.filterService = ctrl.search ? this.VenueSearchFiltersService.activate(ctrl.search) : null;
        if (this.$window.innerWidth > 991) {
          ctrl.toggleMap();
        }
      })
      .catch(error => this.unwrapError(error));
  }

  setFiltersFromLead(searchFilters) {
    const maxGuestCount = get(this.lead, 'request.numGuests.max') || get(this.lead, 'request.numGuests.min');
    set(searchFilters, 'guests.max', maxGuestCount);
  }

  basicSearch() {
    ctrl.$location.search('page', null);
    ctrl.search.page = 0;
    ctrl.search.applyFilters()
      .then(ctrl.redrawMarkers)
      .catch(ctrl.unwrapError);
  }

  getCount(path, value, schema) {
    return ctrl.filterService.getCount(path, value, schema);
  }

  toggleFilterArray(path, value, schema, toPush) {
    return ctrl.filterService
      .toggleFilterArray(path, value, schema, toPush)
      .then(() => ctrl.redrawMarkers())
      .catch(ctrl.unwrapError);
  }

  isFilterEnabledArray(path, value, schema) {
    return ctrl.filterService.isFilterEnabledArray(path, value, schema);
  }

  redrawMarkers() {
    ctrl.mapService.drawMarkers(ctrl.search.venues);
  }

  setPage(index) {
    ctrl.scrollToTop();
    return ctrl.search.setPage(index)
      .then(ctrl.redrawMarkers)
      .catch(ctrl.unwrapError);
  }

  scrollToTop() {
    ctrl.$scrollService('body', 0, 0);
  }

  addSpace(space) {
    const index = findIndex(ctrl.addedSpaces, s => s._id === space._id);
    if (index < 0) {
      ctrl.addedSpaces.push(space);
    }
    else {
      ctrl.addedSpaces.splice(index, 1);
      ctrl.isShow = !ctrl.addedSpaces.length ? false : ctrl.isShow;
    }
  }

  isSpaceSelected(space) {
    return ctrl.addedSpaces.includes(space);
  }

  textSearch() {
    const zoom = get(ctrl.search.aliases[0], 'map.zoom', 12);
    const center = get(ctrl.search.aliases[0], 'map.center');
    if ( zoom && center) {
      ctrl.mapService.resetMap(zoom, center);
    }
    return ctrl.search
      .applyTextSearch()
      .then(() => ctrl.redrawMarkers())
      .catch(ctrl.unwrapError);
  }

  debouncedTextSearch = debounce(() => {
    return this.textSearch();
  }, DEBOUNCE_LIMIT);

  showPage() {
    ctrl.$timeout(() => {
      ctrl.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
    });
  }

  removeFormatting(str: String): string {
    const cleanUrlRegex = /[\s\-(%20)]*/gm;
    return str.replace(cleanUrlRegex, '').toLowerCase();
  }

  matchRouteToCity(city) {
    return this.$api.Locations.get()
      .then((res) => {
        const locations = res.locations;
        const location = locations.find(_location => this.removeFormatting(_location.city) === this.removeFormatting(city));

        if (!location) {
          ctrl.$location.path('/404');
        }
        return {
          searchLocation: `${location.city},${location.state},${location.country}`,
          display: location.city
        };
      })
      .catch(error => this.unwrapError(error));
  }

  makeRecommendation = ({ space, venue, order, isLiteVenue }) => {
    const suggestionType = [];
    const recommendation = {
      admin: {
        suggestionType
      },
      order,
      space,
      venue,
      lead: this.lead._id,
      isNew: true,
      isVisible: true,
      createdThrough: 'Client'
    };

    if (isLiteVenue) {
      Object.assign(recommendation, {
        isInterested: 'Liked Lite',
        interestIndicationDate: Date.now()
      });
    }

    return recommendation;
  }

  addReco() {
    const recos = [];
    ctrl.addedSpaces.forEach((space) => {
      recos.push(this.makeRecommendation({ space: space._id, venue: space.venue.id, order: 0, isLiteVenue: space.isLite }));
    });
    this.$api.Leads.addRecommendation(this.lead, { recommendations: recos })
      .then(() => {
        ctrl.addedSpaces = [];
        this.close();
      });

  }

  toggleMap() {
    ctrl.search.state.$waitFor('LOADED', () => {
      ctrl.showMap = !ctrl.showMap;
    });
  }

  ambianceClass(venue) {
    const ambiance = get(venue, 'admin.ambiance');
    return ambianceStyleService(ambiance);
  }

  toggleDisplay() {
    if (!ctrl.addedSpaces.length) {
      return;
    }
    ctrl.isShow = !ctrl.isShow;
  }

  getPrice(value) {
    let price = '';
    const currency = ctrl.search.display === 'London' ? '£' : '$';
    while (price.length < value) {
      price +=  currency;
    }
    return price;
  }

}

export const AddSpaceForShellComponent = {
  template: require('./add-space-for-shell.component.jade'),
  controller: AddSpaceForShellController,
  bindings: {
    lead: '<',
    close: '&'
  }
};
