// Our Deps
import { ApiService } from 'spc/shared/api/api.service';
import set from 'lodash/set';

/**
 * Stateful child component that will create a proposal based on a given conversation
 */
class CreateProposalFromConversationController {
  conversation: any;
  redirect: (obj: { url: string }) => void;

  constructor(private $api: ApiService, private unwrapError, private ENUMS) {
    'ngInject';
  }

  public createProposal = () => {
    const request = {};
    set(request, 'admin.createdThrough', this.ENUMS.proposalCreatedThrough.PROPOSAL_INQUIRY);
    return this.$api.Conversations.createProposal({ conversation: this.conversation, request })
      .then(data => this.redirect({ url: `/venue/conversation/${ data.conversationId }` }))
      .catch(error => this.handleHttpError(error));
  }

  private handleHttpError = (error: Error) => {
    this.unwrapError(error);
  }
}

export const CreateProposalFromConversationComponent = {
  template: require('./create-proposal-from-conversation.component.jade'),
  controller: CreateProposalFromConversationController,
  bindings: {
    conversation: '<',
    redirect: '&'
  }
};
