// External Dependencies

// SixPlus Dependencies

// SixPlus Types


/**
 * A factory function service that registers FullStory.
 * Permissions / admin-ignoring is handled by the analytics service that will
 * call `register()`
 */
export class FullstoryService {
  /* tslint:disable */
  private sessionUrl: string;

  constructor(private $config, private $window: ng.IWindowService, private $document: ng.IDocumentService) { 'ngInject'; }

  /**
   * Registers fullstory service if in production ONLY because for now, it doesn't look like fullstory allows multiple projects
   */
  public register = () => {

    if (this.isEnabled()) {
      
      window['_fs_debug'] = false;
      window['_fs_host'] = 'fullstory.com';
      window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
      window['_fs_org'] = this.$config.fullstory.org;
      window['_fs_namespace'] = 'FS';

      this.$window['_fs_ready'] = () => {
        this.sessionUrl = this.$window.FS.getCurrentSessionURL();
      };

      (function(m,n,e,t,l,o?,g?,y?){
        if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
        
        // @ts-ignore
        g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];

        o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+ _fs_script;
        y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
        g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
        g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
        g.log = function(a,b){g("log",[a,b])};
        g.consent=function(a){g("consent",!arguments.length||a)};
        g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
        g.clearUserCookie=function(){};
        g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
        // @ts-ignore
        if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
        })(this.$window, document, this.$window['_fs_namespace'],'script','user');
    }
  }

  public identify = ({ id, info: { fullName, email, role, city, thirdPartyLogin } }: { id: string, info: { fullName: string, email: string, role: string, city: string, thirdPartyLogin: boolean } }) => {
    if (this.isEnabled()) {
      this.$window.FS.identify(id, {
        displayName: fullName,
        email,
        role_str: role,
        city_str: city,
        thirdPartyLogin_bool: thirdPartyLogin
      });
    }
  };

  public getSession = (): string | undefined => {
    if (this.isEnabled()) {
      return this.sessionUrl;
    }
  }

  private isEnabled = (): boolean => {
    return !!this.$config.fullstory.org;
  }
  /* tslint:enable */
}
