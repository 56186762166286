import { fullName } from 'common/dist/virtuals/User';

const PIXELS_PER_HOUR = 60;

const helpers = require('../../../../../shared/scheduler_helpers');
export const horizontalSlot = () => {
  return {
    scope: {
      space: '<',
      model: '<',
      openBlockTimeModal: '<',
      openSetFbminModal: '<',
      from: '<',
      to: '<',
      minutesPerTick: '<',
      date: '<'
    },
    template: require('./horizontal-slot.directive.jade'),
    link: function (scope, element) {
      const TICKS_PER_HOUR = 60 / scope.minutesPerTick;
      const TICK_WIDTH = PIXELS_PER_HOUR / TICKS_PER_HOUR;
      scope.$schedulerHelpers = helpers;
      const _from = scope.model.from;
      const _to = scope.model.to;
      scope.$fullName = fullName;

      const totalTicksInGrid = helpers.timeToTicks(scope.from,
        scope.to, scope.minutesPerTick);
      const totalTicksFromStart = helpers.timeToTicks(scope.from,
        _from, scope.minutesPerTick);
      const slotWidth = helpers.timeToTicks(_from, _to,
        scope.minutesPerTick);
      element.css({
        left: `${totalTicksFromStart * TICK_WIDTH}px`,
        width: `${slotWidth * TICK_WIDTH}px`
      });

      scope.isContinuedTimeslot = (model) => {
        if (!model.data.event && !model.data.isNA) {
          return model.from !== model.data.from;
        }
      };

      scope.handleSlotClick = () => {
        if (scope.model.data.event) {
          const event = scope.model.data;
          const times = { startTime: event.from, endTime: event.to };
          return scope.openBlockTimeModal({
            space: scope.space,
            times,
            note: event.note,
            date: scope.date,
            user: event.user,
            created: event.created
          });
        } else if (!scope.model.data.isNA) {
          return scope.openSetFbminModal({
            space: scope.space,
            slot: scope.model,
            date: scope.date
          });
        }
      };
    }
  };
};
