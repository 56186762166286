import { get, every, filter, cloneDeep, isEqual, debounce } from 'lodash';
import { AdditionalRequirement, RawVendor } from 'spc/lib/database/types/virtual-events';
import { ApiService } from '../../../shared/api/api.service';
import { VIRTUAL_EVENTS_CHANGE } from '../../../../../database/constants/virtualEventsChange';

const newRequirement: AdditionalRequirement = {
  name: '',
  description: '',
};
class RequirementsController {
  currentRequirementIndex: number;
  isFormDisplayed: boolean = false;
  requirement: AdditionalRequirement = cloneDeep(newRequirement);
  requirements: AdditionalRequirement[] = [];
  vendor: RawVendor;
  errors: {
    [key: string]: {
      message: string
    }
  } | null = {};
  isRequirementEdit: boolean = false;
  object: any;
  type: string;
  updateReadyStatus: any;

  constructor(private unwrapError, private $api: ApiService, private $scope) {
    'ngInject';
  }
  $onInit = () => {
    if (this.object) {
      if (
        this.object.additionalRequirements &&
        this.object.additionalRequirements.length
      ) {
        this.requirements = cloneDeep(this.object.additionalRequirements);
      }
    }
    this.$scope.$on(VIRTUAL_EVENTS_CHANGE.VIRTUAL_ADDITIONALREQUIREMENTS_CHANGE, this.cloneOriginalItemToItem);
  }

  cloneOriginalItemToItem = () => {
    this.requirements = cloneDeep(this.object.additionalRequirements);
  }

  validateRequirement = (path) => {
    const currentItemPath = `additionalRequirements.${this.currentRequirementIndex}.${path}`;
    if (this.type === 'vendor') {
      this.$api.Admin.Vendors.validate({
        vendor: { additionalRequirements: this.requirements },
      })
        .then((response) => {
            const errors = get(response, 'errors.vendor');
            if (errors) {
              const pathErrors = get(errors, currentItemPath);
              this.errors[currentItemPath] = pathErrors
                ? pathErrors.message
                : null;
            } else {
              this.errors = {};
            }
        })
        .catch((error) => {
          this.unwrapError(error);
        });
    }
    else if (this.type === 'experience') {
        this.$api.Admin.Experience.validate({
          experience: {
            ...this.object,
            additionalRequirements: this.requirements,
          },
          vendorId: this.vendor['_id'],
        })
            .then((response) => {
            const errors = get(response, 'errors.experience');
            if (errors) {
              const pathErrors = get(errors, currentItemPath);
              this.errors[currentItemPath] = pathErrors
                ? pathErrors.message
                : null;
            } else {
              this.errors = {};
            }
          })
          .catch((error) => {
            this.unwrapError(error);
          });
    }
  }

  checkForErrors = debounce(this.validateRequirement, 300);

  createNewRequirement = () => {
    this.displayForm(true);
    const length = this.requirements.push(this.requirement);
    this.currentRequirementIndex = length - 1;
  }

  resetItem = () => {
    this.requirement = cloneDeep(newRequirement);
  }

  displayForm(value) {
    this.isFormDisplayed = value;
  }

  cancel() {
    if (!this.isRequirementEdit) {
      this.requirements = filter(
        this.requirements,
        (_, index) => index !== this.currentRequirementIndex
      );
    } else {
      this.requirements[this.currentRequirementIndex] = cloneDeep(
        this.object.additionalRequirements[this.currentRequirementIndex]
      );
      this.isRequirementEdit = false;
    }
    this.resetItem();
    this.displayForm(false);
  }

  isFieldInvalid = (path) => {
    const fieldPath = `additionalRequirements.${this.currentRequirementIndex}.${path}`;
    return this.errors[fieldPath] ? true : false;
  }

  getErrorMessage = (path) => {
    const fieldPath = `additionalRequirements.${this.currentRequirementIndex}.${path}`;
    return this.errors[fieldPath];
  }

  submit = () => {
      if (this.type === 'vendor') {
        this.updateVendor();
      } else if (this.type === 'experience') {
        this.updateExperience();
      }
      this.updateUi();
  }

  updateUi = () => {
    this.object.additionalRequirements = cloneDeep(this.requirements);
    this.displayForm(false);
    this.resetItem();
    this.isRequirementEdit = false;
    if (this.type === 'experience') {
      this.updateReadyStatus();
    }
  }

  updateVendor = () => {
    const updates = { additionalRequirements: this.requirements };
    this.$api.Admin.Vendors.updateVendorById({
      updates,
      vendorId: this.object['_id'],
    })
      .then((res) => {
        this.requirements = res.vendor.additionalRequirements;
        this.updateUi();
      })
      .catch((err) => {
        this.unwrapError(err);
      });
  }

  updateExperience = () => {
    const updates = { additionalRequirements: this.requirements };
    this.$api.Admin.Experience.updateExperienceById({
      updates,
      experienceId: this.object['id'],
    })
      .then((response) => {
        this.requirements = response.experience.additionalRequirements;
        this.object.status = response.experience.status;
        this.updateUi();
      })
      .catch((err) => {
        this.unwrapError(err);
      });
  }

  checkIfFormDataInvalid = () => {
    const errorsPath = Object.keys(this.errors);
    let result = null;
    errorsPath.forEach((path) => {
      result = this.errors[path] || result;
    });
    return result;
  }

  hasAllTheRequiredFields = () => {
    const requiredFields = [get(this.requirement, 'name')];
    return every(requiredFields);
  }

  edit = (index) => {
    this.requirement = this.requirements[index];
    this.displayForm(true);
    this.currentRequirementIndex = index;
    this.isRequirementEdit = true;
  }

  delete = (requirementIndex) => {
    const requirementToDelete = this.requirements[requirementIndex];
    if (isEqual(this.requirement, requirementToDelete)) {
      this.requirement = cloneDeep(newRequirement);
      this.displayForm(false);
    }
    this.requirements = this.requirements.filter((_, index) => requirementIndex !== index);
    if (this.type === 'experience') {
      this.updateExperience();
      this.updateReadyStatus();
    } else if (this.type === 'vendor') {
      this.updateVendor();
    }
  }
}

export const VirtualEventsRequirementsComponent = {
  controller: RequirementsController,
  template: require('./virtual-events-additional-requirements.component.jade'),
  bindings: {
    vendor: '<',
    object: '<',
    type: '<',
    updateReadyStatus: '<'
  },
};
