module.exports = function() {
  const $window = $(window);
  const $all = $('html, body');
  const mouseEvents = `mousewheel DOMMouseScroll touchmove`;
  return function(id, offset, time = 500, container) {
    const $id = `object` === typeof id ? id : $(id);

    let $container = $all;
    if (container) {
      $container = $(container);
    }

    if (!$id.length) {
      return;
    }

    if (offset) {
      offset = $(offset).height();
    } else {
      offset = 0;
    }

    $window.on(mouseEvents, (e) => {
      e.preventDefault();
      e.stopPropagation();
    });

    if (container) {
      $container.animate({ scrollTop: $id.offset().top - ($container.offset().top - $container.scrollTop() - offset - 80) }, time, 'swing', () => {
        $window.off(mouseEvents);
      });
    } else {
      $container.animate({ scrollTop: ($id.offset().top - offset - 80) }, time, 'swing', () => {
        $window.off(mouseEvents);
      });
    }
  };
};
