import AuthorizedClientHelpers from 'common/dist/virtuals/AuthorizedClient';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { RawBookingRequest } from 'spc/lib/database/types/booking-request';
class RefundReceiptController {
  client: RawBaseUser;
  request: RawBookingRequest;

  constructor() {
    'ngInject';
  }

  $onInit = () => {
    this.client = AuthorizedClientHelpers.primaryEventContact(this.request).user;
  }

}

export const refundReceiptComponent = {
  template: require('./refund-receipt.component.jade'),
  controller: RefundReceiptController,
  bindings: {
    request: '<',
  }
};
