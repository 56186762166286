import * as angular from 'angular';
const Chart = require('chart.js');
//import 'angular-chart.js';

// Services
import { ComposeChartService } from './compose-chart.service';

// Components
import { SpGraphComponent } from './sp-graph.component';
// Config
import { chartConfig } from './chart.config';
export default angular.module('metrics', [])
  .service('composeChartService', ComposeChartService)
  .constant('CHART_BASE_COLORS', ['#85259C', '#06958a', '#d53a58', '#d4b322'])
  .component('spGraph', SpGraphComponent)
  .name;