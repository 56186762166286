// External Dependencies
import clone from 'lodash/clone';
import get from 'lodash/get';
import set from 'lodash/set';
import includes from 'lodash/includes';
import merge from 'lodash/merge';
import moment from 'moment';

// SixPlus Dependencies
import { FullstoryService } from './fullstory.service';

// Constants
const constants = require('../../../../shared/constants');
import { ANALYTICS_EVENTS } from '../../constants/ENUMS/analyticsEvents';

// SixPlus Types

/**
 * Analytics service that abstracts functionality of different third-party
 * analytics services
*/

/* tslint:disable */
// @ts-ignore
let self;
export default class AnalyticsService {
  // Venue propoerties
  venue: string;
  slug: string;
  url: string;
  status: string;
  visibleSpaces: string;
  type: string;
  venueCity: string;
  address1: string;
  address2: string;
  state: string;
  neighborhood: string;
  zipcode: string;

  // Space properties
  space: string;
  privacy: string;
  isVisible: boolean;

  // Menu properties
  menu: string;
  price: string;
  numberOfCourses: number;
  
  // Drink properties
  drink: string;
  payAtTheVenue: boolean;
  duration: number;

  constructor(private $jwt, private $user, private $config, private $rootScope, private ENUMS, private $window, private $document, private fullstoryService: FullstoryService, private $injector, private unwrapError) {
    'ngInject';
    self = this;
    self.$jwt = $jwt;
    self.$user = $user;
    self.$config = $config;
    self.$rootScope = $rootScope;
    self.ENUMS = ENUMS;
    self.$window = $window;
    self.$document = $document;
    self.fullstoryService = fullstoryService;
  }

  /**
   * Waits to see if user is logged in. If the user is logged in and an admin, return early.
   * Otherwise, execute the callback, taking the user from `self.$user.$` as a parameter.
   * If the user is not logged in, just execute the callback.
   * The main utility of self is to short-circuit early if the user is an admin so that we
   * do not track admins for analytics.
   *
   * @public
   * @param {Function} callback
   * @return {Any}
   */
  waitForUser(callback) {
    var isLoggedIn = self.$jwt.get();

    // if logged in, check to see if admin so we can ignore them
    if (isLoggedIn) {
      self.$user.$waitFor('LOGGED_IN', function () {
        var user = self.$user.$;
        if (includes(user.roles, 'Admin')) {
          return self.trackAdminsOnHotjar();
        }
        return callback(user);
      });
    } else {
      return callback();
    }
  }

  /**
   * Prevent mixpanel tracking.
   * Can be undone with "unregister({'$ignore': true})"
   *
   * @public
   * @return {Void}
   */
  $ignore() {
    self.$window.mixpanel.register({ $ignore: true });
  }

  /**
   * Identifies a user on login, unless they are an admin
   *
   * @public
   * @param {User} user
   * @param {Boolean} justCreated, determines if self is identification upon register or on login
   * @return {Void}
   */
  $identify(user, justCreated) {
    try {
      let heapUser: any = {};
      let mixpanelUser = {};

      if (user) {
        if (self.$user.isAdmin()) {
          return;
        } else if (self.$window.mixpanel.get_property('$ignore')) {
          // unignore (for testing)
          self.$window.mixpanel.unregister('$ignore');
        }

        const baseUser = {
          fullName: user.fullName ? (user.fullName as string) : get<string>(user, 'profile.name.first') + ' ' + get<string>(user, 'profile.name.last'),
          city: get<string>(user, 'profile.city'),
          role: self.$user.isHost() ? 'Host' : 'Guest',
          thirdPartyLogin: (self.$user.isOauth as boolean)
        };

        this.fullstoryService.identify({
          id: (user._id as string),
          info: {
            fullName: baseUser.fullName,
            email: user.profile.email,
            city: baseUser.city,
            role: baseUser.role,
            thirdPartyLogin: baseUser.thirdPartyLogin
          }
        });

        self.$window.heap.identify(user._id);

        heapUser = clone(baseUser);

        merge(heapUser, {
          email: get(user, 'profile.email'),
          firstName: user.firstName ? user.firstName : get(user, 'profile.name.first'),
          lastName: get(user, 'profile.name.last'),
          fullstorySession: this.fullstoryService.getSession(),
          company: get(user, 'company.name')
        });
        // mixpanel has special properties, so define them separately
        mixpanelUser = merge(baseUser, {
          $first_name: heapUser.firstName,
          $last_name: heapUser.lastName,
          $email: heapUser.email,
          $total_booked_events: self.$user.$totalBookedEvents,
        });

        if (justCreated) {
          const now = moment.utc().local().toDate();
          heapUser.created = now;
          mixpanelUser['$created'] = now;
        }

        // don't track an anonymous mixpanel user - they are auto tracked
        self.$window.mixpanel.identify(user._id);
        self.$window.mixpanel.people.set(mixpanelUser);
        self.$window.heap.addUserProperties(heapUser);
        self.$window.wisepops('properties', baseUser);
      }
    } catch (error) {
      console.error('error running analytics', error);
    }
  }

  /**
   * Initialize analytics logic, instantiating our various services and placing them on $window.
   * Waits to see if user is logged in (and not an admin) before proceeding to store globals on $window.
   * If the user is an admin, things like $window.$zopim will `NOT` exist
   *
   * @public
   * @param {Object} injector, the angular dependency injector
   * @return {Void}
   */
  $init(injector, unwrapError) {
    try {
      self.waitForUser(function (user) {
        // mixpanel loads asynchronously so pass actual logic into init callback
        self._init(self.$config.googleAnalytics.trackingId,
          self.$config.heap.trackingId,
          self.$config.mixpanel.trackingId,
          self.$config.zopim.key,
          self.$config.pardot,
          self.$config.googleAnalytics4TrackingId.trackingId,
          function () {
            self.initialized = true;
            self.$identify(user);
            self._trackPageView(injector);
            self.$tracker = self.$rootScope.$on('$locationChangeSuccess', function trackGoogleAnalytics(ev, res) {
              self._trackPageView(injector);
            });
            if (user) {
              self.$window.$zopim(function () {
                self.$window.$zopim.livechat.setName(get(user, 'fullName'));
                self.$window.$zopim.livechat.setEmail(get(user, 'profile.email'));
                if (get(user, 'profile.phone')) {
                  self.$window.$zopim.livechat.setPhone(user.profile.phone);
                }
              });
            }
          });
      });
    } catch (error) {
      unwrapError(error)
    }
  }

  /**
   * Track each page on vwo, required to integrate VWO with SPAs
   *
   * @public
   * @return {Void}
   */
  $execVwoTracker() {
    self.$waitForInit(user => {
      self.executeVwoTrackingCode();
    });
  }

  /**
   * Register users on signup so we can alias them
   *
   * @public
   * @param {User} user
   * @return {Void}
   */
  $register(user) {
  
    try {
      if (self.$user.isAdmin()) {
        return;
      } else if (self.$window.mixpanel.get_property && self.$window.mixpanel.get_property('$ignore')) {
        // unignore (for testing);
        self.$window.mixpanel.unregister('$ignore');
      }
      self.$window.mixpanel.alias(user._id);
      setTimeout(() => self.$identify(user, true));
    } catch (error) {
      this.unwrapError(error);
    }
  }

  /**
   * Mainly for admins - stop tracking a user once they log in as an admin
   *
   * @public
   * @return {Void}
   */
  $untrack() {
    if (self.$tracker) {
      // delete google analytics tracker
      self.$tracker();
    }
    self.$ignore();
  }

  /**
   * Implementation details behind initialization logic
   *
   * @private
   * @param {String} googleAnalyticsId
   * @param {String} heapId
   * @param {String} mixpanelId
   * @param {String} zopimKey
   * @param {Function} cb
   */

  _init(googleAnalyticsId: any, heapId, mixpanelId, zopimKey, pardot, googleAnalytics4Id,cb) {

    const initStateTracker: any = {};

    /* tslint:disable */
    // Google analytics
    (function (i: any, s: any, o: any, g?: any, r?: any, a?: any, m?: any) {
      const date: any = new Date();
      i['GoogleAnalyticsObject'] = r;
      i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments); };
      i[r].l = 1 * date;
      a = s.createElement(o);
      m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

    ga('create', googleAnalyticsId, 'auto', { allowLinker: true });

    // Google analytics 4
    // register google tag manager
    const gTagManagerScript = document.createElement('script');
    gTagManagerScript.async = true;
    gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalytics4Id}`;
    document.head.appendChild(gTagManagerScript);

    // register google analytics
    const gaScript = document.createElement('script');
    gaScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function ga4() { dataLayer.push(arguments); }
      ga4('js', new Date());
      ga4('config', '${googleAnalytics4Id}');
    `;
    document.head.appendChild(gaScript);

    // Facebook
    !function (f?: any, b?: any, e?: any, v?: any, n?: any, t?: any, s?: any) {
      if (f.fbq) return;
      
      n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      
      if (!f._fbq) f._fbq = n;

      n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s);
      return f.fbq;
    }(window,
      document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '630174033753193'); // Insert your pixel ID here.
    fbq('track', 'PageView');

    // VWO

    self.executeVwoTrackingCode = function (hide) {
      var $ = self.$window.vwo_$ || self.$window.$ || self.$window.jQuery;
      $ && $('.vwo_loaded').removeClass('vwo_loaded');
      self.$window._vwo_code = (function () {
        var account_id = self.$config.vwo.accountId,
          settings_tolerance = 5000,
          library_tolerance = 5000,
          url = self.$window._vis_opt_url || document.URL,
          use_existing_jquery = false,
          // DO NOT EDIT BELOW THIS LINE
          f = false, d = document;
        return {
          use_existing_jquery: function () {
            return use_existing_jquery;
          },
          library_tolerance: function () {
            return library_tolerance;
          },
          finish: function () {
            if (!f) {
              f = true;
              var a = d.getElementById('_vis_opt_path_hides');
              if (a) a.parentNode.removeChild(a);
              initStateTracker.vwoLoaded = true;
            }
          },
          finished: function () {
            return f;
          },
          load: function (a) {
            var b = d.createElement('script');
            b.src = a;
            b.type = 'text/javascript';
            b.innerText;
            b.onerror = function () {
              self.$window._vwo_code.finish();
            };
            d.getElementsByTagName('head')[0].appendChild(b);
          },
          init: function () {
            self.$window.settings_timer = setTimeout('_vwo_code.finish()', settings_tolerance);
            this.load('//dev.visualwebsiteoptimizer.com/j.php?a=' + account_id + '&u=' + encodeURIComponent(url) + '&r=' + Math.random());
            var a = d.createElement('style'),
              b = hide ? hide + '{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}' : '',
              h = d.getElementsByTagName('head')[0];
            a.setAttribute('id', '_vis_opt_path_hides');
            a.setAttribute('type', 'text/css');
            if (a.styleSheet) a.styleSheet.cssText = b;
            else a.appendChild(d.createTextNode(b));
            h.appendChild(a);
            return self.$window.settings_timer;
          }
        };
      }());
      self.$window._vwo_settings_timer = self.$window._vwo_code.init();
    };

    self.executeVwoTrackingCode();
    // Heap

    self.$window.heap = self.$window.heap || [];
    self.$window.heap.load = function (e, t) {
      self.$window.heap.appid = e;
      self.$window.heap.config = t = t || {};
      var r = t.forceSSL || 'https:' === document.location.protocol;
      var a = document.createElement('script');
      a.type = 'text/javascript';
      a.async = !0;
      a.src = (r ? 'https:' : 'http:') +
        '//cdn.heapanalytics.com/js/heap-' + e + '.js';
      var n = document.getElementsByTagName('script')[0];
      n.parentNode.insertBefore(a, n);
      for (var o = function (e) { return function () { self.$window.heap.push([e].concat(Array.prototype.slice.call(arguments, 0))); }; }, p = ['addEventProperties', 'addUserProperties', 'clearEventProperties', 'identify', 'removeEventProperty', 'setEventProperties', 'track', 'unsetEventProperty'], c = 0; c < p.length; c++) self.$window.heap[p[c]] = o(p[c]);
    };
    self.$window.heap.load(heapId);

    // Zopim Live Chat
    self.$window.$zopim || (function (d, s) {
      var z = self.$window.$zopim = function (c) { z._.push(c); }, $ = z.s =
        d.createElement(s), e = d.getElementsByTagName(s)[0]; z.set = function (o) {
          z.set
            ._.push(o);
        }; z._ = []; z.set._ = []; $.async = !0; $.setAttribute('charset', 'utf-8');
      $.src = '//v2.zopim.com/?' + zopimKey; z.t = +new Date(); $
        .type = 'text/javascript'; e.parentNode.insertBefore($, e);
    })(document, 'script');

    // fullstory
    self.fullstoryService.register();

    // Mixpanel
    (function (e, b) {
      if (!b.__SV) {
        var a, f, i, g;
        self.$window.mixpanel = b;
        b._i = [];
        b.init = function (a, e, d) {
          function f(b, h) {
            var a = h.split('.');
            a.length == 2 && (b = b[a[0]], h = a[1]);
            b[h] = function () {
              b.push([h].concat(Array.prototype.slice.call(arguments, 0)));
            };
          }
          var c = b;
          'undefined' !== typeof d ?
            c = b[d] = [] :
            d = 'mixpanel';
          c.people = c.people || [];
          c.toString = function (b) {
            var a = 'mixpanel';
            'mixpanel' !== d && (a += '.' + d);
            b || (a += ' (stub)');
            return a;
          };
          c.people.toString = function () {
            return c.toString(1) + '.people (stub)';
          };
          i = 'disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user'.split(' ');
          for (g = 0; g < i.length; g++)f(c, i[g]); b._i.push([a, e, d]);
        }; b.__SV = 1.2; a = e.createElement('script'); a.type = 'text/javascript'; a.async = !0; a.src = 'undefined' !== typeof window.MIXPANEL_CUSTOM_LIB_URL ? window.MIXPANEL_CUSTOM_LIB_URL : 'file:' === e.location.protocol && '//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js'.match(/^\/\//) ? 'https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js' : '//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js'; f = e.getElementsByTagName('script')[0]; f.parentNode.insertBefore(a, f);
      }
    })(document, self.$window.mixpanel || []);
    self.$window.mixpanel.init(mixpanelId, {
      loaded: function () {
        if (cb) {
          cb();
        }
      }
    });

    // Pardot
    self.$window.piAId = pardot.piAId;
    self.$window.piCId = pardot.piCId;
    self.$window.piHostname = pardot.piHostname;

    (function() {
      function async_load(){
        var s = document.createElement('script'); s.type = 'text/javascript';
        s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
        var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
      }
      if (window.attachEvent) { window.attachEvent('onload', async_load); }
      else { window.addEventListener('load', async_load, false); }
    })();


    // WisePops

    (function(W,i,s,e,P,o,p){W['WisePopsObject']=P;W[P]=W[P]||function(){(W[P].q=W[P].q||[]).push(arguments)},W[P].l=new Date();o=i.createElement(s),p=i.getElementsByTagName(s)[0];o.async=1;o.src=e;p.parentNode.insertBefore(o,p)})(window,document,'script','//loader.wisepops.com/get-loader.js?v=1&site=Kg8vwGxNLN','wisepops', null, null);

    /* tslint:enable */
  }

  /**
   * Wait for all the relevant services to be initialized
   *
   * @public
   * @param {Function} cb
   * @return {Void}
   */
  $waitForInit(cb) {
    self.waitForUser(() => {
      if (!self.initialized) {
        setTimeout(() => self.$waitForInit(cb), 100);
      } else if (cb) {
        cb();
      }
    });
  }

  /**
   * Event tracking - waitForUser because analytics globals may be undefined
   * @param {String} eventName
   * @param {Object} eventParams
   *
   */
  $trackEvent(eventName, eventParams, eventAction) {
    self.$waitForInit(() => {
      self.$window.mixpanel.track(eventName, eventParams);
      self.$window.heap.track(eventName, eventParams);
      eventAction = eventAction ? eventAction : eventName;
      self.$window.ga('send', 'event', eventName, eventAction, eventName);
      self.$window.ga4('event', eventName, { eventAction: eventName });
    });
  }

  private startsWith = (str, test) => {
    return str.slice(0, test.length) === test;
  }

  private stripProtocol = (str) => {
    if (this.startsWith(str, 'https')) {
      str = str.replace('https://', '');
    } else if (this.startsWith(str, 'http')) {
      str = str.replace('http://', '');
    }
    return str;
  }

  private identifyService = (str) => {
    if (!str.length) {
      return;
    }
    // www.google.com, l.facebook.com
    const splitReferrer = str.split('.');
    if (splitReferrer.length > 1) {
      return splitReferrer[1];
    }
  }

  trackAdminsOnHotjar = () => {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3232215,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }

  createReferrerData = () => {
    const DEFAULT_SERVICE = 'direct';
    const referrer: any = {
      url: this.$document.referrer
    };

    // 1. check if query parameters match up with any services
    const $location = this.$injector.get('$location');
    const queryParams = $location.search();
    if (queryParams) {
      set(referrer, 'adwords.campaign', queryParams.utm_campaign);
      set(referrer, 'adwords.medium', queryParams.utm_medium);

      const keywords = queryParams.utm_term;
      if (get(keywords, 'length')) {
        const words = decodeURIComponent(keywords);
        const wordsSplitBySpace = words.split(' ');
        // slice word from index 1 if it starts with '+' bc google adwords appends + to each word
        set(
          referrer,
          'adwords.keywords',
          wordsSplitBySpace.map(w => this.startsWith(w, '+') ? w.slice(1) : w));
      }
    }

    if (queryParams.utm_source) {
      referrer.service = queryParams.utm_source === 'google' ? 'adwords' : queryParams.utm_source;
    } else {
      const serviceMap = this.ENUMS.referral.serviceMap;

      const params = Object.keys(queryParams);
      for (let param of params) {
        if (serviceMap[param]) {
          referrer.service = serviceMap[param];
          break;
        }
      }

      // 2. try and determine service based on document.referrer. If nothing, set service to direct
      if (!referrer.service) {
        const theService = this.identifyService(this.stripProtocol(referrer.url));
        referrer.service = theService ? theService : DEFAULT_SERVICE;
        if (referrer.service === 'google') {
          referrer.service = 'googleOrganic';
        }
      }
    }
    return referrer;
  }

  getReferrerData = () => {
    return JSON.parse(this.$window.localStorage.getItem(constants.REFERRER_LOCALSTORAGE_KEY));
  }

  saveReferrerData = (data) => {
    const isLoggedIn = self.$jwt.get();
    const existingReferrerData = this.$window.localStorage.getItem(constants.REFERRER_LOCALSTORAGE_KEY);

    // check if user is logged in or if there is existing referrer data
    // if either is truthy, don't do anything
    if (isLoggedIn || existingReferrerData) {
      return;
    }

    // if there is no user logged in, save their referral data to local storage
    // this data will get attached to the BaseUser or User when a lead is submitted or a user registers
    this.$window.localStorage.setItem(constants.REFERRER_LOCALSTORAGE_KEY, JSON.stringify(data));
  }

  clearReferrerData = () => {
    this.$window.localStorage.removeItem(constants.REFERRER_LOCALSTORAGE_KEY);
  }

  /**
   * Helper functions
   */

  /**
   * Tracks page view
   *
   * @private
   * @param {Object} injector
   * @param {Object} eventParams
   * @return {Void}
   */
  _trackPageView(injector, eventParams) {
    const $route = injector.get('$route');
    const $location = injector.get('$location');
    const theRoute = $location.path();
    self.$window.ga('send', 'pageview', theRoute);
    self.$window.ga4('pageview', theRoute);
    const eventName = ANALYTICS_EVENTS.visited[theRoute];
    if (eventName) {
      self.$trackEvent(eventName, eventParams);
    }
  }

  /**
   * Constructors
   */
  $Venue(venue) {
    this.venue = get(venue, 'data.name');
    this.slug = get(venue, 'slug');
    this.url = document.location.origin + '/venue/' + this.slug;
    const visibleSpaces = venue.data.spaces.filter(space => space.isVisible);
    this.status = get(venue, 'status');
    this.visibleSpaces = visibleSpaces.length;
    this.type = get(venue, 'data.kind');
    this.venueCity = get(venue, 'data.address.city');
    this.address1 = get(venue, 'data.address.line1');
    this.address2 = get(venue, 'data.address.line2');
    this.state = get(venue, 'data.address.state');
    this.neighborhood = get(venue, 'data.address.neighborhood');
    this.zipcode = get(venue, 'data.address.zipcode');
  }

  $Space(space, venue) {
    this.space = get(space, 'data.name');
    this.description = get(space, 'data.description');
    this.privacy = get(space, 'data.privacy');
    this.type = get(space, 'data.type');
    this.isVisible = space.isVisible;
    if (venue) {
      setVenueInfo.call(this, venue);
    }
  }

  $Menu(menu, venue) {
    this.menu = menu.name ? menu.name : get(menu, 'data.name');
    this.type = get(menu, 'data.type');
    this.description = get(menu, 'data.description');
    this.price = menu.price >= 0 ? '$' + menu.price : undefined;
    this.numberOfCourses = get(menu, 'data.courses', []).length;
    this.isVisible = menu.isVisible;
    if (venue) {
      setVenueInfo.call(this, venue);
    }
  }

  $Drink(drink, venue) {
    this.drink = get(drink, 'data.name');
    this.payAtTheVenue = get(drink, 'data.payAtTheVenue');
    this.duration = get(drink, 'data.duration');
    this.numberOfCourses = get(drink, 'data.courses', []).length;
    this.price = get(drink, 'data.price') >= 0 ?
      '$' + get(drink, 'data.price') :
      undefined;
    if (venue) {
      setVenueInfo.call(this, venue);
    }
  }

  adwordsConciergeCampaign() {
    const google_conversion_id = 992404209;
    const google_conversion_language = 'en';
    const google_conversion_format = '3';
    const google_conversion_color = 'ffffff';
    const google_conversion_label = 'IHsqCKavvGQQ8cWb2QM';
    const google_remarketing_only = false;

    self.$window.google_trackConversion({
      google_conversion_id: google_conversion_id,
      google_remarketing_only: google_remarketing_only,
      google_conversion_label: google_conversion_label,
      google_conversion_format: google_conversion_format
    });
  }

  adwordsSentBookingRequest = () => {
    if (self.$window.ga) {
      self.$window.ga('send', 'event', 'form', 'submit', 'booking-request');
      self.$window.ga('event', 'form', {'submit': 'booking-request'});
    }
  }

  adwordsSentInquiry = () => {
    if (self.$window.ga) {
      self.$window.ga('send', 'event', 'form', 'submit', 'contact-venue');
      self.$window.ga4('event', 'form', {'submit': 'contact-venue'});
    }
  }

  adwordsUpgradeInquiry = () => {
    if (self.$window.ga) {
      self.$window.ga('send', 'event', 'form', 'submit', 'try-pro-for-free');
      self.$window.ga4('event', 'form', {'submit': 'try-pro-for-free'});
    }
  }
}

function setVenueInfo(venue) {
  this.url = this.isVisible && venue.isVisible ? document.location.origin + '/venue/' + venue.slug : document.location.href;
  this.venue = get(venue, 'data.name');
  this.venueCity = get(venue, 'data.address.city');
}
/* tslint:enable */
