export class GoogleMapsService {
  constructor(private $config) {
    'ngInject';
  }

  getStaticGoogleMapsUrl(coordinatePair: number[]): string {
    const self = this;

    const coordinates = `${ coordinatePair[1] },${ coordinatePair[0] }`;
    const val = `//maps.googleapis.com/maps/api/staticmap?` +
      `center=${ coordinates }&` +
      `scale=2&` +
      `zoom=16&size=640x480&` +
      `markers=color:0xe0004c%7C${ coordinates }&` +
      `key=${ self.$config.googleMaps.apiKey }`;
    return val;
  }
}

