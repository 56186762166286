/**
*   Original source:
*   https://gist.github.com/VictorBjelkholm/6687484
*/

export function googlePlace() {
  var componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    neighborhood: 'short_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name',
    sublocality_level_1: 'long_name'
  };
  var mapping = {
    street_number: 'number',
    route: 'street',
    locality: 'city',
    neighborhood: 'neighborhood',
    administrative_area_level_1: 'state',
    country: 'country',
    postal_code: 'zip',
    place_id: 'placeId',
    sublocality_level_1: 'sublocality'
  };

  return {
    require: 'ngModel',
    scope: {
      ngModel: '=',
      details: '=?',
      onSelect: '&?'
    },

    link: function(scope, element, attrs, model) {
      var options = {
        types: attrs.googlePlace !== "" ? attrs.googlePlace.split(',') : [],
        componentRestrictions: {}
      };

      scope.gPlace = new google.maps.places.Autocomplete(element[0], options);
      $(element)[0].$$gPlace = scope.gPlace;

      google.maps.event.addListener(scope.gPlace, 'place_changed', function() {
        var place = scope.gPlace.getPlace();
        var details = place.geometry && place.geometry.location ? {
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng()
        } : {};

        if (!place.address_components) {
          return;
        }

        // Get each component of the address from the place details
        // and fill the corresponding field on the form.
        for (var i = 0; i < place.address_components.length; i++) {
          var addressType = place.address_components[i].types[0];
          if (componentForm[addressType]) {
            var val = place.address_components[i][componentForm[addressType]];
            details[mapping[addressType]] = val;
          }
        }
        details.formatted = place.formatted_address;
        details.placeId = place.place_id;
        details.url = place.url;
        details.utc_offset = place.utc_offset;
        details.vicinity = place.vicinity;

        scope.$apply(function() {
          scope.details = details; // array containing each location component
          model.$setViewValue(element.val());
          if (scope.onSelect) {
            scope.onSelect({ details: details });
          }
        });
      });
    }
  };
};
