// SixPlus Deps
import { ToastService } from 'spc/shared/toast.service';
import { RawBookingRequest } from 'spc/lib/database/types/booking-request';


class EditField {
  request: RawBookingRequest;
  editPath: string;
  savePaths: (edit: {
    request: RawBookingRequest,
    paths: {
      setPath: string,
      value: any
    }
  }) => any;
  tab: string;
  displayValue: number;
  value: number;
  pathValueOp: { setPath: string, value: any };
  targetPath: string;
  targetValue: number;

  constructor(private unwrapError, private toast: ToastService) {
    'ngInject'
   }

  $onInit() {
    this.tab = 'DISPLAY';

    this.displayValue = this.targetValue || 0;
    this.value = this.targetValue || 0;
  }

  setValue(val) {
    this.pathValueOp = {
      setPath: this.targetPath, value: val };
  }

  handleCancel() {
    this.value = this.targetValue;
    this.tab = 'DISPLAY';
  }

  saveThenDisplay() {
    this.tab = 'DISPLAY';
    this.displayValue =  this.value;
    if (this.value === this.targetValue) {
      return;
    }
    return this.savePaths({ request: this.request, paths: this.pathValueOp })
      .catch((error) => {
        this.displayValue = this.targetValue;
        this.toast.badNews('There was an error saving your update. Please try again');
        this.unwrapError(error); });
  }
}
export const EditFieldComponent = {
  template: require('./edit-field.component.jade'),
  controller: EditField,
  bindings: {
    request: '<',
    displayPath: '@',
    savePaths: '&',
    targetPath: '@',
    targetValue: '@'
  }
};
