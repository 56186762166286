import { RawLead } from 'spc/lib/database/types/lead';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { RawBookingRequest } from 'spc/lib/database/types/booking-request';
import { RawGroup } from 'spc/lib/database/types/group';
import { DAccount } from 'spc/lib/database/types/accounts';

class AddCollaboratorsController {
  lead: RawLead;
  clients: RawBaseUser[];
  user: RawBaseUser;
  searchValue: string;
  filteredClients: RawBaseUser[];
  connections: RawBaseUser[];
  loading: boolean;
  proposals: RawBookingRequest[];
  update: ({ updatedLead, updatedProposals }: { updatedLead: RawLead, updatedProposals: RawBookingRequest[] }) => void;
  ui: {
    invitedGroups: {
      [groupId: string]: boolean;
    }
  };
  close: (revised: DAccount) => void;

  constructor(private $api, private unwrapError, private $scope, private $window, private $location, private $user) {
    'ngInject';

    this.ui = {
      invitedGroups: {}
    };

    this.loading = true;
  }

  $onInit() {
    this.getConnectedUsers(this.user);
  }

  getConnectedUsers(user) {
    return this.$api.BaseUsers.getConnections(user._id.toString())
      .then((res) => {
        this.clients = res.users;
        this.setConnectedUsers(res.users);
      })
      .catch(error => this.unwrapError(error));
  }

  setConnectedUsers(users) {
    this.filteredClients = users.filter(u => u._id.toString() !== this.user._id.toString());
    this.connections = this.filteredClients;
    this.loading = false;
  }

  isOnLead({ user }) {
    // users with no _id have been newly added
    return !user._id || this.lead.clients.some((client) => {
      return (client.user as RawBaseUser)._id.toString() === user._id.toString();
    });
  }

  isGroupOwner({ user, group }) {
    const groupOwner = group.owner._id ? group.owner._id.toString() : group.owner.toString();
    return groupOwner === user._id.toString();
  }

  inviteUser = ({ user }) => {
    this.$api.Leads.addUser({ lead: this.lead, user, addedBy: this.user })
      .then((res) => {
        this.lead.clients = res.users;
        this.proposals = res.proposals;
        const ids = [];
        this.filteredClients = res.users.reduce((clients, currentClient) => {
          if (currentClient.user._id.toString() !== this.user._id.toString()) {
            ids.push(currentClient.user._id.toString());
            clients.push(currentClient.user);
          }
          return clients;
        }, []);
        this.setConnectedUsers(this.connections);
        this.update({ updatedLead: this.lead, updatedProposals: this.proposals });
        this.close(res);
      })
      .catch(error => this.unwrapError(error));
  }

  inviteGroup = (group) => {
    this.$api.Leads.addGroup({ group, lead: this.lead })
      .then((res) => {
        this.lead.clients = res.leadClients;
        this.proposals = res.proposals;
        const ids = [];
        this.ui.invitedGroups[group.id.toString()] = true;
        this.filteredClients = res.lead.clients.reduce((clients, currentClient) => {
          if (currentClient.user._id.toString() !== this.user._id.toString()) {
            ids.push(currentClient.user._id.toString());
            clients.push(currentClient.user);
          }
          return clients;
        }, []);
        this.setConnectedUsers(this.connections);
        this.update({ updatedLead: this.lead, updatedProposals: this.proposals });
      })
    .catch(error => this.unwrapError(error));
  }

}

export const AddCollaboratorsComponent = {
  template: require('./add-collaborators.component.jade'),
  controller: AddCollaboratorsController,
  bindings: {
    close: '&',
    lead: '<',
    user: '<',
    update: '&',
  }
};
