import get from 'lodash/get';
import { RawBookingRequest, BookingRequestClient } from 'database/types/booking-request';
import { RawConversation } from 'database/types/conversation';
import { RawBaseUser } from 'spc/lib/database/types/base-user';

class ProposalContactsController {
  request: RawBookingRequest;
  conversation: RawConversation;
  assignee: RawBaseUser;
  client: BookingRequestClient;
  ui: {
    copyClick?: boolean
  } = { copyClick: false };
  constructor(
    private addClientsModal,
    private unwrapError,
    private $timeout,
    private $injector
  ) {
    'ngInject';
  }
  $onInit = () => {
    this.assignee = get<RawBaseUser>(this.request, 'admin.assignee');
    this.client = this.request.clients[0];
  }

  handleCopyClick = () => {
    this.ui.copyClick = true;
    this.$timeout(() => this.ui.copyClick = false, 1000);
  }

  getPreviewLink = () =>  {
    const location = this.$injector.get('$location');
    let domain = `${location.protocol()}://${location.host()}`;
    if (location.port() !== 443) {
      domain = `${domain}:${location.port()}`;
    }
    return `${domain}/client/conversation/${this.request.conversation}/preview`;
  }

  openAddClientsModal = () => {
    this.addClientsModal({
      request: this.request,
      conversation: this.conversation
    })
    .then((data) => {
      if (get(data, 'value.cancel')) {
        return;
      }

      const update = get<RawBookingRequest>(data, 'value.request');
      if (update) {
        this.request.clients = get<BookingRequestClient[]>(update, 'clients');
      }
    })
    .catch(error => this.unwrapError(error));
  }
}

export const ProposalContactsComponent = {
  template: require('./proposal-contacts.component.jade'),
  controller: ProposalContactsController,
  bindings: {
    request: '<',
    conversation: '<',
    venue: '<',
    sixplusContacts: '<',
    previewUser: '<'
  }
};
