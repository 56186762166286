// NPM Deps
import * as angular from 'angular';

// SP Deps
import { VenueViewUIService } from './venue-view-ui.service';
import { VenueViewComponent } from './venue-view.component';
import { VenueLiteViewComponent } from './venue-lite-view.component';
import { VenueDistinctionsCarouselComponent } from './venue-distinctions-carousel.component';
import { TopLevelVenueViewComponent } from './top-level-venue-view.component';


export const VenueViewModule = angular.module('VenueViewModule', ['bc.Flickity'])
  .component('venueView', VenueViewComponent)
  .component('venueLiteView', VenueLiteViewComponent)
  .component('venueDistinctionsCarousel', VenueDistinctionsCarouselComponent)
  .component('topLevelVenueView', TopLevelVenueViewComponent)
  .service('venueViewUIService', VenueViewUIService)
  .name;
