// External Deps
import get from 'lodash/get';
import { primaryEventContact } from 'common/dist/virtuals/AuthorizedClient';
import { fullName } from 'common/dist/virtuals/User';

export class AdminConversationsService {
  constructor() {
    'ngInject';
  }

  public isProposal = conversation => get(conversation, 'request.isProposal');

  public getUrl = conversation => conversation.request ? `/venue/conversation/${ conversation._id }` : `/conversation/${ conversation._id }/messages`;

  public clientName = (conversation) => {
    const client = primaryEventContact(conversation);
    return fullName(client.user);
  }
}
