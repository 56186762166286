import { ApiService } from 'spc/shared/api/api.service';
import debounce from 'lodash/debounce';

import { FeaturedVenue } from 'server/api/admin/featured-venues/models';
import { ToastService } from 'spc/shared/toast.service';

class EditFeaturedVenuesController {
  city: string;
  venueSearchName: string;
  selectedVenue: FeaturedVenue;
  featuredVenues: FeaturedVenue[];
  searchVenues: [any];
  selectedVenueImages: [{ url: string; caption: string }];

  constructor(
    private $api: ApiService,
    private unwrapError,
    private $cloudinary,
    private toast: ToastService,
    private ENUMS
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.$api.Admin.FeaturedVenues.get(this.city)
      .then(data => this.featuredVenues = data.featuredVenues);
  }

  getCityName = () => {
    return this.city.split(',')[0];
  }

  moveUp = (index) => {
    if (!index) {
      return;
    }
    this.featuredVenues[index].position = this.featuredVenues[index].position - 1;
    this.featuredVenues[index - 1].position = this.featuredVenues[index - 1].position + 1;
    this.savePositions();
  }

  moveDown = (index) => {
    if (index === (this.featuredVenues.length - 1)) {
      return;
    }
    this.featuredVenues[index].position = this.featuredVenues[index].position + 1;
    this.featuredVenues[index + 1].position = this.featuredVenues[index + 1].position - 1;
    this.savePositions();
  }

  venueSearch = (venueName) => {
    if (venueName.length < 3) {
      return;
    }
    return this.$api.Admin.Venues.findManyByName(venueName, { skipVisibility: false })
      .then((data: any) => {
        this.searchVenues = data.data.venues;
      });
  }

  closeSidebar = () => {
    this.selectedVenue = null;
  }

  hasSixOrMore = () => {
    return this.featuredVenues.length < 6;
  }

  addFeaturedVenue = (venue) => {
    if (!this.canAddVenue(venue)) {
      return;
    }
    const featuredVenue = {
      city: this.city,
      venue: venue._id,
      position: this.featuredVenues.length + 1,
      url: venue.coverImage.url
    };

    this.$api.Admin.FeaturedVenues.create({ featuredVenue })
      .then((data) => {
        this.selectedVenue = data.featuredVenue;
        this.featuredVenues.push(this.selectedVenue);
        this.searchVenues = null;
        this.venueSearchName = null;
      })
      .catch(error => this.unwrapError(error));
  }

  canAddVenue = (venue) => {
    const hasEnoughSlots = this.featuredVenues && this.featuredVenues.length < 6;
    const existsInFeaturedList = this.featuredVenues.find(featuredVenue => featuredVenue.venue._id === venue._id);
    return hasEnoughSlots && !existsInFeaturedList;
  }

  update = (featuredVenue) => {
    return this.$api.Admin.FeaturedVenues.update({ featuredVenue })
      .then(() => {
        this.toast.goodNews('Success', 'Featured Venue Updated Successfully');
        this.selectedVenue = null;
      })
      .catch(() => this.toast.badNews('Uh oh!', 'Something went wrong.'));
  }

  savePositions = () => {
    this.featuredVenues
      .sort((venueA, venueB) => venueA.position - venueB.position)
      .forEach((venue, index) => venue.position = index + 1);
    const leanVenues = this.featuredVenues
      .map(venue => ({ _id: venue._id, position: venue.position }));

    return this.$api.Admin.FeaturedVenues.patch(leanVenues)
      .catch(error => this.unwrapError(error));
  }

  remove = (featuredVenue) => {
    return this.$api.Admin.FeaturedVenues.remove({ featuredVenue })
      .then(() => {
        this.toast.goodNews('Success', 'Featured Venue Removed Successfully');
        this.featuredVenues = this.featuredVenues
          .filter(_featuredVenue => _featuredVenue._id.toString() !== featuredVenue._id.toString());
        this.selectedVenue = null;
        this.savePositions();
      })
      .catch(() => this.toast.badNews('Uh oh!', 'Something went wrong.'));
  }

  debouncedVenueSearch = debounce(this.venueSearch, 500);
}

export const EditFeaturedVenuesComponent = {
  template: require('./edit-featured-venues.component.jade'),
  controller: EditFeaturedVenuesController,
  bindings: {
    city: '<'
  }
};
