import range from 'lodash/range';
import moment from 'moment';
import padStart from 'lodash/padStart';

class SpTimeSelectController {
  HOUR_SELECT = range(1, 13);
  MINUTES_SELECT = range(0, 60).map(num => padStart(num.toString(), 2, '0'));
  MERIDIEM_SELECT = ['AM', 'PM'];
  handleChange: ({ time }: { time: Date }) => any;
  time: any;
  hour: number;
  minutes: string;
  meridiem: string;
  constructor() {
    'ngInject';
  }

  isFinite = num => Number.isFinite(num);
  $onInit = () => {
    if (this.time) {
      this.hour = this.time.getHours();
      this.minutes = padStart(this.time.getMinutes().toString(), 2, '0');
      if (this.hour > 12) {
        this.hour = this.hour - 12;
        this.meridiem = 'PM';
      } else if (this.hour === 12) {
        this.meridiem = 'PM';
      } else {
        this.meridiem = 'AM';
      }
    }
  }

  setHour = (hour) => {
    this.hour = hour;
    this.calculateTime();
  }

  setMinutes = (minutes) => {
    this.minutes = minutes;
    this.calculateTime();
  }

  setMeridiem = (meridiem) => {
    this.meridiem = meridiem;
    this.calculateTime();
  }

  validTime = () => {
    return this.hour && this.isFinite(Number(this.minutes)) && this.meridiem;
  }

  calculateTime = () => {
    if (!this.validTime()) {
      return this.handleChange({ time: null });
    }
    const time = moment(`${this.hour}:${this.minutes} ${this.meridiem}`, 'h:mm A').toDate();
    this.handleChange({ time });
  }
}

export const SpTimeSelectComponent = {
  template: require('./sp-timeselect.component.jade'),
  controller: SpTimeSelectController,
  bindings: {
    handleChange: '&',
    time: '<'
  }
};
