export const proposalHelpModal = ['ngDialog', function(ngDialog) {
  return ({ request, venue }) => {
    return ngDialog.open({
      template: require('./proposal-help.modal.jade'),
      className: 'ngdialog-theme-small info-modal',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: function() {
        this.request = request;
        this.venue = venue;
      }
    });
  };
}];
