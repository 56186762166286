import ENUMS from 'common/dist/enums';
import { closestMatchIndex } from './helpers';

// Sixplus Types
import { SelectionModalService } from './selection-modal.service';

/**
 * @name selection-modal
 * @description modal replacement for select boxes
 */

  export const selectionModal = ['ngDialog', function (ngDialog) {
    return function ({ choices, currentChoice, options }) {
      return ngDialog.open({
        template: require('./selection-modal.jade'),
        plain: true,
        className: 'ngdialog-theme-dropdown',
        controllerAs: '$ctrl',
        controller: ['$scope', 'filterFilter', 'selectionModalService', '$timeout', function ($scope, filterFilter, selectionModalService: SelectionModalService, $timeout) {
          this.init = () => {
            this.choices = choices;

            this.filteredChoices = this.choices;
            this.highlightedIndex = 0;
            this.highlighted = this.filteredChoices[this.highlightedIndex];

            this.stateMangement = { inView: true };

            this.options = options || {};
            if (this.options.multi) {
              this.clickEffect = choice => toggleChoice(choice);
              this.currentChoices = Array.isArray(currentChoice) ? [ ...currentChoice] : [];
            } else {
              this.clickEffect = choice => selectChoice(choice);
              this.currentChoice = currentChoice;
            }

            if ((this.currentChoices && this.currentChoices.length) || this.currentChoice) {
              $timeout(selectionModalService.goToSelected, 100);
            }
          };

          this.init();

          this.submitChoices = () => $scope.closeThisDialog({ currentChoices: this.currentChoices });

          this.isPremiumCity = (city) => {
            const selectedCity = ENUMS.acceptableUserCities.find(landingCity => landingCity.name.toLowerCase() === city.toLowerCase());
            return selectedCity ? selectedCity.premiumCity : false;
          };

          this.submitCustom = (choice) => {
            const index = closestMatchIndex(choices, choice);
            if (this.options.multi) {
              if (index > -1) {
                this.clickEffect(this.choices[index]);
              } else {
                this.choices.push(choice);
                this.clickEffect(choice);
              }
            } else {
              this.clickEffect(choices[index] || choice);
            }
            this.searchInput = '';
            this.handleSearchChange();
          };
          this.handleSearchChange = () => {
            this.filteredChoices = filterFilter(this.choices, this.searchInput);
            if (this.filteredChoices.length) {
              this.highlightedIndex = 0;
              this.highlighted = this.filteredChoices[this.highlightedIndex];
            } else {
              this.highlighted = this.searchInput;
            }
          };

          this.handleKeyDown = ($event) => {
            if ($event.keyCode === 38) {
              this.handleUpArrow();
            } else if ($event.keyCode === 40) {
              this.handleDownArrow();
            } else if ($event.keyCode === 13 && this.options.custom) {
              this.submitCustom(this.highlighted);
            } else if ($event.keyCode === 13) {
              this.clickEffect(this.highlighted);
            }
            $event.stopPropagation();
          };

          this.handleDownArrow = () => {
            if (this.highlightedIndex + 1 >= this.filteredChoices.length) {
              return;
            }
            this.highlightedIndex++;
            this.highlighted = this.filteredChoices[this.highlightedIndex];
            const DOMElem = document.getElementsByClassName('option-highlighted')[0];
            const target = DOMElem.nextElementSibling;
            this.stateMangement.inView = selectionModalService.isElementInViewport(target);
            if (!this.stateMangement.inView) {
              selectionModalService.scrollToElem(DOMElem, 'down');
            }
          };

          this.handleUpArrow = () => {
            if (this.highlightedIndex - 1 < 0) {
              return;
            }
            this.highlightedIndex--;
            this.highlighted = this.filteredChoices[this.highlightedIndex];
            const DOMElem = document.getElementsByClassName('option-highlighted')[0];
            const target = DOMElem.previousElementSibling.previousElementSibling;
            this.stateMangement.inView = selectionModalService.isElementInViewport(target);
            if (!this.stateMangement.inView) {
              selectionModalService.scrollToElem(target, 'up');
            }
          };

          this.highlight = (choice) => {
            if (this.options.multi) {
              return this.currentChoices.indexOf(choice) > -1;
            } else {
              return choice === this.currentChoice;
            }
          };

          // Internal
          const selectChoice = choice => $scope.closeThisDialog({ choice });

          const toggleChoice = (choice: string): void => {
            const index = this.currentChoices.indexOf(choice);
            index > -1 ? this.currentChoices.splice(index, 1) : this.currentChoices.push(choice);
          };
        }]
      }).closePromise;
    };
  }];
