class TopLevelAdminController {
    constructor(private $rootScope, private $seo) {
        'ngInject';
    }

    componentIsReady = () => {
        this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
    }
}

export const topLevelAdminCreateEditAccountComponent = {
    template: require('./top-level-admin-create-edit-account.component.jade'),
    controller: TopLevelAdminController
};
