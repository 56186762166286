import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import ENUMS from 'common/dist/enums';
import userHelpers from 'common/dist/virtuals/User';
import timeHelpers from 'common/dist/time';
import { primaryEventContact } from 'common/dist/virtuals/AuthorizedClient';

import { MonthViewService } from './month-view.service';

class DayEventsController {
  spaceClassMap: any;
  space: any;
  spaceAvailability: any;
  date: any;
  bookingRequests: any[];
  pendingRequests: any[];
  events: any[];
  eventsOnly: boolean;
  selectedMonth: string;
  ui: { displayedEvents?: any[], selectable: boolean } = { displayedEvents: [], selectable: false };

  constructor(private monthViewService: MonthViewService) {
    'ngInject';
  }

  $onChanges = (data) => {
    if (data.spaceAvailability) {
      this.updateDisplayedEvents();
    }

    if (data.bookingRequests) {
      this.setPendingRequests();
    }
  }

  $onInit = () => {
    const dayIndex = moment(this.date, 'YYYY-MM-DD').day();
    let timeSlots = this.spaceAvailability ? this.spaceAvailability.timeSlots : this.space.data.timeSlots;

    timeSlots = sortBy(cloneDeep(timeSlots), (timeSlot: { from: number, days: boolean[], state: string }) => timeSlot.from)
      .filter(slot => slot.days[dayIndex])
      .map((slot) => {
        slot.state = 'timeslot';
        return slot;
      });

    if (this.events && this.events.length) {

      const events = sortBy(this.events, event => event.startTime)
        .map((event) => {
        const clonedEvent = cloneDeep(event);
        clonedEvent.from = event.startTime;
        clonedEvent.to = event.endTime;
        clonedEvent.state = event.bookingRequest ? 'booking' : 'blocked';
        return clonedEvent;
      });

      this.ui.displayedEvents = this.monthViewService.mergeEventsAndTimeSlots({ timeSlots, events });
    } else {
      this.ui.displayedEvents = timeSlots;
    }
    this.setPendingRequests();
    this.setSelectable();
  }

  updateDisplayedEvents = () => {
    if (!this.spaceAvailability) {
      return;
    }
    const timeSlots = this.spaceAvailability.timeSlots;
    timeSlots.forEach((timeSlot) => {
      const timeSlotId = timeSlot._id.toString();
      this.ui.displayedEvents.forEach((event) => {
        if (event._id.toString() === timeSlotId) {
          event.terms = timeSlot.terms;
        }
      });
    });

    return;
  }

  handleEndTimeDisplay = (endTime) => {
    return timeHelpers.integerToCivilianTime(endTime >= 2400 ? endTime - 2400 : endTime, true);
  }

  handleStartTimeDisplay = (time) => {
    return timeHelpers.integerToCivilianTime(time, true);
  }

  setSelectable = () => {
    this.ui.selectable = this.monthViewService.isValidAndInMonth(this.date, this.selectedMonth);
  }

  isFinite = (num) => {
    return Number.isFinite(num);
  }

  isEventSelectable = (event) => {
    if (!this.ui.selectable) {
      return false;
    }

    if (event.bookingRequest) {
      return false;
    }

    return true;
  }

  isEventSelected = (event) => {
    return this.monthViewService.isEventSelected({ event }) || this.monthViewService.isSlotSelected({ date: this.date, timeSlot: event });
  }

  eventTimeslotMatch = (event) => {
    const timeSlots = this.spaceAvailability ? this.spaceAvailability.timeSlots : this.space.data.timeSlots;
    return timeSlots.find(slot => slot.to === event.to && slot.from === event.from);
  }

  selectEvent = (event) => {
    if (!this.isEventSelectable(event)) {
      return;
    }

    if (event.state === 'timeslot') {
      event.siblingTimeSlots = this.findSiblingTimeSlots(event);
      this.monthViewService.handleTimeslotClick({ date: this.date, timeSlot: event });
    } else if (event.state === 'blocked') {
      const matchingTimeSlot = this.eventTimeslotMatch(event);
      if (matchingTimeSlot) {
        this.monthViewService.handleTimeslotClick({ date: this.date, timeSlot: matchingTimeSlot});
      }
      this.monthViewService.handleEventClick({ event, matchingTimeSlot: !!matchingTimeSlot, date: this.date });
    }
  }

  findSiblingTimeSlots = (timeSlot) => {
    const siblingTimeSlots = this.ui.displayedEvents.filter(_event => _event._id.toString() === timeSlot._id.toString() && (timeSlot.from !== _event.from || timeSlot.to !== _event.to));
    return siblingTimeSlots.length ? siblingTimeSlots : null;
  }

  setPendingRequests = () => {
    if (!this.bookingRequests) {
      return;
    }
    this.pendingRequests = this.bookingRequests.filter(request => !ENUMS.bookingRequestState.bookedStates.includes(request.state));
  }

  getBookingRequest = (event) => {
    if (!this.bookingRequests) {
      return;
    }
    return this.bookingRequests.find(request => request._id.toString() === event.bookingRequest);
  }

  getRequestClient = (request) => {
    const client = primaryEventContact(request);
    return userHelpers.fullName(client.user);
  }

  isRequestPending = (request) => {
    if (!this.pendingRequests) {
      return;
    }
    return this.pendingRequests.find(_request => _request._id.toString() === request._id.toString());
  }

  isConcludedEvent = (request) => {
    if (!request) {
      return;
    }

    return ENUMS.bookingRequestState.postPaymentStates.includes(request.state);
  }

  getRequestClass = (request) => {
    return this.spaceClassMap[request.spaceId];
  }

  getRequestEndTime = (request) => {
    return timeHelpers.getEndTimeFromStartTimeAndDuration(request.data.time, request.data.duration);
  }
}

export const DayEventsComponent = {
  template: require('./day-events.component.jade'),
  controller: DayEventsController,
  bindings: {
    bookingRequests: '<',
    spaceAvailability: '<',
    space: '<',
    date: '<',
    events: '<',
    eventsOnly: '<',
    selectedMonth: '<',
    spaceClassMap: '<'
  }
};
