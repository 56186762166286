// NPM Dependencies
import { get, includes, without } from 'lodash';
// constant
import { ANALYTICS_EVENTS } from '../constants/ENUMS/analyticsEvents';

// SixPlus Dependencies (CommonJS)
const appendCamelCase = require('../utils/appendCamelCase');
module.exports = function() {
  return {
    template: require('./user-account.jade'),
    link: function(scope, element, attrs) {
      scope.getFormContainer = function() {
        return element.find('#payment-form')[0];
      };
    },
    controller: ['$scope', 'ENUMS', '$api', 'ngDialog', 'deleteCardModal', '$braintree', '$timeout', 'braintreeHelpers', '$injector', 'unwrapError', function($scope, ENUMS, $api, ngDialog, deleteCardModal, $braintree, $timeout, braintreeHelpers, $injector, unwrapError) {

      function init() {
        $scope.ENUMS = ENUMS;
        // set links for tertiary nav bar
        $scope.links = [
          { name: 'Profile' },
          { name: 'Payment Methods' }
        ];
      }
      init();

      $scope.editPhoneNumber = () => {
        $scope.tempProfile = { phone: $scope.user.profile.phone };
        $scope.editingPhoneNumber = true;
      };

      $scope.cancel = () => {
        $scope.editingPhoneNumber = false;
      };

      $scope.save = () => {
        $api.Auth.change($scope.tempProfile)
          .then((response) => {
            $scope.user = response.data.user;
            $scope.editingPhoneNumber = false;
          })
          .catch(error => unwrapError(error));
      };

      $scope.isSubscribedToNewsletter = function(newsletter) {
        const newsletters = get($scope.user, 'profile.newsletters', []);
        return includes(newsletters, newsletter);
      };

      $scope.isState = function isState(state) {
        return $scope.currentState === state;
      };

      $scope.removeCard = function(method) {
        deleteCardModal(method, $scope.paymentMethods).
          then(function(options) {
            if (options && options.value && options.value.deleted) {
              trackDeletedCreditCard();
              $scope.$broadcast('CARD_DELETED', { message: 'Credit card was deleted' });
            }
          }).
          catch(unwrapError);
      };

      $scope.$on('CHANGE_PARENT_STATE', function(event, args) {
        $scope.currentState = args.state;
      });

      $scope.addCard = function() {
        if (!$scope.braintreeToken) {
          $timeout($scope.addCard, 100);
        }

        $scope.ccStateCtrl.$state('LOADING');
        const hostedFields = {
          number: {
            selector: '#card-number',
            placeholder: 'Credit Card Number'
          },
          cvv: {
            selector: '#cvv',
            placeholder: 'CVV'
          },
          expirationDate: {
            selector: '#expiration-date',
            placeholder: 'Expiration Date'
          },
          postalCode: {
            selector: '#postal-code',
            placeholder: '11111'
          },
          onFieldEvent: braintreeHelpers.handleOnFieldEvent($scope)
        };
        const extraMethods = {
          onPaymentMethodReceived: submitPaymentMethod,
          onError: braintreeHelpers.onError($scope),
          onReady: function(integration) {
            $scope.ccStateCtrl.$state('LOADED');
            $braintree.$instance = integration;
          }
        };

        $scope.cardParams = {};

        $braintree.init(
          $scope.getFormContainer(),
          $scope.braintreeToken,
          hostedFields,
          extraMethods
          );
        $scope.createPaymentMethod = true;
      };

      $scope.cancelCard = function() {
        $braintree.destroy(function() {
          $scope.createPaymentMethod = false;
        });
      };

      $scope.$on('$destroy', function() {
        $braintree.destroy();
      });

      $scope.disableButton = function() {
        $scope.processing = true;
      };

      function trackAddedNewCreditCard() {
        $injector.get('$analytics').$trackEvent(ANALYTICS_EVENTS.payment.addedNewCreditCard);
      }

      function trackDeletedCreditCard() {
        $injector.get('$analytics').$trackEvent(ANALYTICS_EVENTS.payment.deletedCreditCard);
      }

      function submitPaymentMethod(payload) {
        const extra = {
          cardParams: $scope.cardParams
        };

        return $api.Payment.addMethod(payload.nonce, extra).
          then(function(response) {
            trackAddedNewCreditCard();

            $scope.$broadcast('CARD_ADDED', { message: 'Credit Card was successfully added' });
            $scope.createPaymentMethod = false;
            const newMethod = get(response, 'data.data');
            if (newMethod) {
              $scope.paymentMethods.push(newMethod);
            }

            $scope.processing = false;
            $braintree.destroy();
          }).
          catch(function(error) {
            $scope.processing = false;
            unwrapError(error);
          });
      }
    }]
  };
};
