export class ClientNotes {
  BASE_URL: string;
  constructor(private API_ROOT, private $http: ng.IHttpService) {
    'ngInject';
    this.BASE_URL = `${this.API_ROOT}/admin/clientnotes`;
  }

  public get = (client) => {
    const id = client._id ? client._id.toString() : client.toString();
    return this.$http.get(`${this.BASE_URL}/client/${id}`)
      .then(response => response.data);
  }

  public update = ({ client, note }) => {
    const id = client._id ? client._id.toString() : client.toString();
    return this.$http.put(`${this.BASE_URL}/client/${id}`, { note })
      .then(response => response.data);
  }
}
