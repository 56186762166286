import moment from 'moment';
import get from 'lodash/get';

import { ApiService } from 'spc/shared/api/api.service';

import { RawLead } from 'lib/database/types/lead';
import { RawList } from 'lib/database/types/list';
import { RawBaseUser } from 'lib/database/types/base-user';
import { GetConversationsQuery } from 'server/api/conversations/models';
import { CityTzMapperService } from 'spc/shared/cityTZmapper.service';


class ClientDashboardController {
  lead: RawLead;
  client: RawBaseUser;
  user: RawBaseUser;
  canInit: boolean;
  confirmedLeads: RawLead[] = [];
  allLeads: RawLead[] = [];
  activeLeads: RawLead[] = [];
  concludedLeads: RawLead[] = [];
  inactiveLeads: RawLead[] = [];
  totalUpcoming: number;
  lists: RawList[] = [];
  loading: boolean;
  search: GetConversationsQuery;
  data: any;
  role: 'venue' | 'client';

  constructor( private $api: ApiService, private $user, private unwrapError, private $rootScope, private $location, private ENUMS, private $cloudinary, private createListModal, private cityTzMapperService: CityTzMapperService) {
    'ngInject';
    this.loading = true;
    this.search = { lastMessage: true, state: 'all', page: 0 };
  }

  $onInit = () => {
    this.$user.$waitFor('LOGGED_IN', () => {
      this.canInit = true;
      this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
      this.user = this.$user.$;
      this.setRole();
      this.getLeads();
      this.getLists();
      this.getConversations();
    });

    this.$user.$waitFor('NOT_LOGGED_IN', () => {
      this.$location.path('/login');
    });

  }

  setRole = () => {
    if (this.$user.$.roles.includes('Host')) {
      this.role = 'venue';
    }
    else {
      this.role = 'client';
    }
  }

  openCreateListModal() {
    return this.createListModal(this.lists)
      .then((res) => {
        if (res.value.list) {
          this.lists.push(res.value.list);
        }
      })
      .catch(error => this.unwrapError(error));
  }

  getConversations = () => {
    return this.$api.Conversations.getConversations({ from: this.role }, this.search)
      .then(data => this.data = data.results);
  }

  getLeads = (): ng.IPromise<any> => {
    return this.$api.Leads.getUserLeads(this.user._id.toString())
      .then(response => this.setData(response))
      .catch(error => this.unwrapError(error));
  }

  getLists = (): ng.IPromise<any> => {
    return this.$api.Lists.getAll({ userId: this.user._id.toString(), page: 0 })
      .then(res => this.lists = res.lists.data)
      .catch(error => this.unwrapError(error));
  }

  setData = (response) => {
    const events = response.sharedEvents;
    events.forEach(event => event.isShared = true);
    if (events.length) {
      response.leads = response.leads.concat(events);
    }
    const leads = response.leads;
    leads.forEach((lead) => {
      const bookedReco = lead.recommendations.find(reco => this.isBookedEvent(get(reco, 'conversation.request')));
      if (bookedReco) {
        lead.eventDate = moment(bookedReco.conversation.request.data.date).local();
      } else if (lead.request.date) {
        const tz = this.cityTzMapperService.getCityTimezone(lead.request.city);
        lead.eventDate = moment.tz(lead.request.date, tz);
      }
      const yesterday = moment().subtract(1, 'day');
      if (lead.eventDate > yesterday && bookedReco && !['Lost', 'Killed'].includes(lead.currentStatus)) {
        this.confirmedLeads.push(lead);
        lead.bookedReco = this.findBookedReco(lead);
      } else if ((!lead.eventDate || (lead.eventDate > yesterday && !bookedReco)) && !['Lost', 'Killed'].includes(lead.currentStatus)) {
        this.activeLeads.push(lead);
      }
    });
    this.totalUpcoming = this.confirmedLeads.length + this.activeLeads.length;
    this.allLeads = [...this.confirmedLeads, ...this.activeLeads];
    this.loading = false;
  }

  isBookedEvent = (event) => {
    if (!event) {
      return;
    }
    return this.ENUMS.bookingRequestState.bookedStates.includes(event.state);
  }

  findBookedReco = (lead) => {
    const booked = lead.recommendations.find(reco => this.ENUMS.bookingRequestState.bookedStates.includes(get(reco, 'conversation.requestState')));

    return booked;
  }

  pluralize(list) {
    return list.saved.length !== 1;
  }

}

export const ClientDashboardComponent = {
  controller: ClientDashboardController,
  template: require('./client-dashboard.component.jade')
};
