// NPM Dependencies

import moment from 'moment';
import { cloneDeep, get, isNumber, includes, each, set, unset, findIndex, remove, times } from 'lodash';

// Sixplus Dependencies
import SpaceAvailability from 'common/dist/spaceAvailability';
import availabilityCalendarHelpers from 'common/dist/virtuals/AvailabilityCalendar';
import BookingRequestHelpers from 'common/dist/virtuals/BookingRequest';
import iterateHalfHours from 'common/dist/iterateHalfHours';
import { timeAsInteger, integerToCivilianTime, getDurationFromTimes } from 'common/dist/time';
import { ApiService } from '../../shared/api/api.service';
import { RawSpace } from 'spc/lib/database/types/venue/space';
import { ConversationStatusMarkerComponent } from 'spc/components/events-dashboard/conversation-status-marker.component';

// Constants
const DELTA_PATHS: string[] = [
  'data.duration',
  'data.time',
  'data.date',
  'host.foodBeverageMinCents',
  'host.roomFeeCents',
  'venue.data.spaces',
  'data.space'
];

module.exports = function () {
  return {
    template: require('./space-counteroffer.jade'),
    controller: ['$scope', 'AvailabilityFactory', '$timeout', 'unwrapError', 'SelectTimeHostService', '$cloudinary', '$api', function ($scope, AvailabilityFactory, $timeout, unwrapError, SelectTimeHostService, $cloudinary, $api: ApiService) {
      $scope.getAvailability = getAvailability;
      $scope.addSpaceDelta = addSpaceDelta;
      $scope.cancelSpaceEdit = cancelSpaceEdit;
      $scope.$cloudinary = $cloudinary;
      $scope.counterClone = $scope.counterClone.toObject ? $scope.counterClone.toObject() : $scope.counterClone;

      $scope.moment = moment;
      $scope.allAvailabilities = {};
      $scope.canSubmit = canSubmit;
      $scope.changeDate = changeDate;
      $scope.handleTimeChange = handleTimeChange;
      $scope.isOutsidePlatform = isOutsidePlatform;
      $scope.convertToCents = convertToCents;
      $scope.selectTimeHostService = SelectTimeHostService;
      $scope.displaySpaceList = displaySpaceList;
      $scope.outsideOps = {
        addCustomSpace,
        getEndTime,
        pickSpace,
        undoAddCustomSpace,
        addCustomSpaceDelta
      };
      $scope.setStartTime = setStartTime;
      $scope.setEndTime = setEndTime;
      init();

      // Listeners

      $scope.$on('SELECTED_TIME', function (ev, params) {
        ev.stopPropagation();
        const slot = _findSlot($scope.counterClone);
        AvailabilityFactory
          .selectTime($scope.counterClone, params.time, slot);
      });

      $scope.$on('SELECTED_SPACE', function (ev, params) {
        ev.stopPropagation();
        _setSpace(params.space._id);
        getAvailability($scope.counterClone.data.date);
      });

      // Functions

      function init() {
        $scope.isOutsidePlatform = $scope.counterClone.isOutsidePlatform;
        if ($scope.isOutsidePlatform) {
          if (get($scope.counterClone, 'selectedSpace.isCustom')) {
            $scope.outsideOps.addCustomSpace();
          }
        } else {
          getAvailability($scope.counterClone.data.date);
        }

        if (!$scope.spaces) {
          $api.Venues.Spaces.get($scope.clone.venue.slug)
            .then(res => $scope.spaces = res.data.data)
            .catch(error => unwrapError(error));
        }

        $scope.inputs = {
          startTime: moment(integerToCivilianTime($scope.counterClone.data.time), 'H:mm').toDate(),
          endTime: getEndTime() ? moment(integerToCivilianTime(getEndTime()), 'H:mm').toDate() : null
        };
      }

      function changeDate(space, date) {
        if (!$scope.isOutsidePlatform && $scope.counterClone.selectedSpace) {
          getAvailability(date)
            .then(() => $scope.$broadcast('CHANGE_DATE'))
            .catch(unwrapError);
        }
      }

      function setStartTime(time: Date) {
        $scope.inputs.startTime = time;
        $scope.handleTimeChange();
      }

      function setEndTime(time: Date) {
        $scope.inputs.endTime = time;
        $scope.handleTimeChange();
      }

      function handleTimeChange() {

        if (!$scope.inputs.startTime) {
          unset($scope.counterClone, 'data.time');
        }

        if (!$scope.inputs.endTime) {
          unset($scope.counterClone, 'data.duration');
        }

        if (!$scope.inputs.startTime || !$scope.inputs.endTime) {
          return;
        }

        const startTime = timeAsInteger(moment($scope.inputs.startTime));
        const endTime = timeAsInteger(moment($scope.inputs.endTime));

        let duration = getDurationFromTimes(startTime, endTime);
        if (startTime > endTime) {
          duration = duration + 60 * 24;
        }


        $scope.counterClone.data.duration = duration;
        $scope.counterClone.data.time = startTime;
      }

      function addCustomSpaceDelta() {
        $scope.counterClone.venue.data.spaces.push($scope.customSpace);
        const spaceIdx = findIndex($scope.counterClone.venue.data.spaces, (s: any) => s.isCustom);
        $scope.counterClone.data.space = spaceIdx;
        DELTA_PATHS.push(`venue.data.spaces.${spaceIdx}`);
      }

      function pickSpace(space) {
        _setSpace(space._id);
      }

      function getEndTime() {
        return BookingRequestHelpers.getEndTime($scope.counterClone);
      }

      function convertToCents(dollars) {
        return (dollars * 100);
      }

      function undoAddCustomSpace() {
        unset($scope, 'customSpace');
        unset($scope.counterClone, 'data.space');
        remove(DELTA_PATHS, p => p === `venue.data.spaces.${$scope.counterClone.venue.data.spaces.length - 1}`);
      }

      function addCustomSpace() {
        $scope.customSpace = _createCustomSpace($scope.counterClone.selectedSpace);

        $scope.customUiModel = {
          startTime: $scope.counterClone.data.time,
          endTime: BookingRequestHelpers.getEndTime($scope.counterClone)
        };
      }

      /**
       * Determine if necessary fields have been filled out to add a space delta
       *
       * @param {Request} request
       * @return {Boolean}
       */
      function canSubmit(request) {
        let submit;

        const hasTime = isNumber(request.data.time);
        const hasDuration = isNumber(request.data.duration);
        const hasSpace = isNumber(request.data.space);
        const hasDate = !!request.data.date;
        const hasSelectedSpace = !!request.selectedSpace;

        if ($scope.isOutsidePlatform && $scope.customSpace) {
          submit = $scope.customSpace.data.name && hasTime && hasDuration && hasDate;
          if (!submit) {
            return false;
          }
        }

        if ($scope.isOutsidePlatform && !$scope.customSpace) {
          submit = hasTime && hasDuration && hasSpace && hasDate && hasSelectedSpace;
        }

        if (submit) {
          return submit;
        }

        if (!$scope.calendars) {
          return false;
        }

        const isAvailable = AvailabilityFactory.isAvailableRequest({ request: $scope.counterClone, calendars: $scope.calendars });

        if (!isAvailable) {
          return;
        }

        return hasTime && hasDuration && hasSpace && hasDate && hasSelectedSpace;
      }

      /**
       * Add deltas for space
       */
      function addSpaceDelta() {
        if ($scope.customSpace) {
          $scope.outsideOps.addCustomSpaceDelta();
        }

        const delta = {};

        each(DELTA_PATHS, function (path) {
          let newVal;

          if (path === 'data.space') {
            newVal = 0;
          } else {
            newVal = get($scope.counterClone, path);
          }

          delta[path] = { value: newVal };
        });

        $scope.addMultipleDeltas(delta)
          .then(() => {
            if ($scope.customSpace) {
              $scope.outsideOps.undoAddCustomSpace();
            }
          })
          .then(() => $scope.closeThisDialog());
      }

      /**
       * Cancel space edit
       */
      function cancelSpaceEdit() {
        if ($scope.customSpace) {
          $scope.outsideOps.undoAddCustomSpace();
        }
        each(DELTA_PATHS, p => set($scope.counterClone, p, get($scope.clone, p)));
        $scope.editing['data.space'] = false;
        $scope.editing['data.date'] = false;
        $scope.closeThisDialog();
      }

      function removeCurrentRequestFromCalendar ({ calendars }) {
        if (!$scope.counterClone.selectedSpace) {
          return calendars;
        }

        const date = $scope.counterClone.data.date;
        const requestId = $scope.counterClone._id.toString();
        const selectedSpaceId = $scope.counterClone.selectedSpace._id.toString();
        return calendars.map((_calendar) => {
          if (_calendar.space === selectedSpaceId) {
            _calendar.availability[date] = availabilityCalendarHelpers.getEvents({ calendar: _calendar, date }).filter(event => event.bookingRequest !== requestId);
          }
          return _calendar;
        });
      }

      /**
       * checks availability based on search parameters and shows availability
       *
       * @param {Space} space
       * @param {String} date, YYYY-MM-DD
       * @sets `$scope.allAvailabilities`
       * @mutates `$scope.counterClone`
       */
      function getAvailability(date) {
        return AvailabilityFactory.getAvailabilityCalendarsForVenue($scope.counterClone.venue)
          .then((calendars) => {
            $scope.calendars = removeCurrentRequestFromCalendar({ calendars });
            $scope.$broadcast('CHANGE_DATE');
            return AvailabilityFactory.getAvailabilityForDate($scope.counterClone.venue.data.spaces, date);
          })
          .then((availabilities) => {
            $scope.counterClone.venue.data.spaces = $scope.counterClone.venue.data.spaces
              .map(_space => SpaceAvailability.overridePrice(_space, availabilities));
          })
          .catch(error => unwrapError(error));
      }

      function isOutsidePlatform() {
        return $scope.counterClone.isOutsidePlatform;
      }

      /**
       * Set `data.space`
       * @param {String} id
       * @mutates `$scope.counterClone`
       */
      function _setSpace(id) {
        // check if id exists because ng-change triggers setSpace
        if (!$scope.counterClone || !id) {
          return;
        }

        const currentSpace = get($scope, 'counterClone.selectedSpace._id');
        if (currentSpace && currentSpace.toString() === id) {
          return;
        }

        const spaces = $scope.spaces as RawSpace[];
        const idx = findIndex(spaces, space => space._id.toString() === id.toString());

        if (idx !== -1) {
          set($scope, 'counterClone.data.space', 0);
          set($scope, 'counterClone.venue.data.spaces', [$scope.spaces[idx]]);
          unset($scope, 'counterClone.host.roomFeeCents');
          unset($scope, 'counterClone.host.foodBeverageMinCents');
        }
      }

      function displaySpaceList () {
        return $scope.counterClone.data.date && $scope.counterClone.data.time && $scope.counterClone.data.duration && $scope.calendars;
      }

      /**
       * Find a slot in a space's timeslots from a time
       *
       * @param {Space} space
       * @param {Array} slots
       * @return {Slot}
       */
      function _findSlot(space, slots?) {
        if (!$scope.allAvailabilities) {
          return;
        }

        slots = slots || get($scope.allAvailabilities[space._id.toString()], 'data.availability.timeSlots');

        const time = _isSelected(space)
          ? get($scope, 'counterClone.data.time')
          : $scope._time;

        return AvailabilityFactory.findSlot($scope.counterClone, slots, time);
      }

      /**
       * Determine if a space is a request's selectedSpace
       *
       * @param {Space} space
       * @return {Boolean}
       */
      function _isSelected(space) {
        const selected = $scope.counterClone.selectedSpace;
        return selected && space._id.toString() === selected._id.toString();
      }

      /**
       * Sets `$scope.availability` and `$scope.currentAvailability`.
       * Also checks time availability and unsets request time if not availability
       *
       */

      function _createCustomSpace(space) {
        return space && space.isCustom ?
          cloneDeep(space) : {
            isCustom: true,
            isDeleted: false,
            isVisible: true,
            data: {
              name: 'Custom Space'
            }
          };
      }
    }]
  };
};
