import { get, last, groupBy, findIndex, keys, includes, filter, each, toLower } from 'lodash';
import match from '../../utils/match';
import { ApiService } from '../../shared/api/api.service';

// constant
import { ANALYTICS_EVENTS } from '../../constants/ENUMS/analyticsEvents';
import { LITE_SPACE_STATUSES } from '../../../../database/constants/LiteSpaceStatuses';

export default function() {
  return {
    template: require('./user-venues-index.jade'),
    scope: {
      user: '='
    },
    controller: ['$scope', '$location', '$rootScope', '$venueValidator', '$api', 'ENUMS', 'ngDialog', '$routeParams', '$cloudinary', '$analytics', 'unwrapError', 'DeleteFactory', 'createVenueModal', 'DocFactory', function($scope, $location, $rootScope, $venueValidator, $api: ApiService, ENUMS,
      ngDialog, $routeParams, $cloudinary, $analytics, unwrapError, DeleteFactory,
      createVenueModal, DocFactory) {
      $scope.$cloudinary = $cloudinary;
      $scope.last = last;
      $scope.$routeParams = $routeParams;
      $scope.canToggle = canToggle;
      $scope.createVenue = createVenue;
      $scope.getKeys = keys;
      $scope.readyToView = readyToView;
      $scope.selectVenue = selectVenue;
      $scope.submitForReview = submitForReview;
      $scope.deleteDoc = deleteDoc;
      $scope.openSidebar = openSidebar;
      $scope.getVisibilityCount = getVisibilityCount;
      $scope.showToggleVenue = showToggleVenue;
      $scope.moveLeft = moveLeft;
      $scope.moveRight = moveRight;
      $scope.saveMenuOrder = saveMenuOrder;
      $scope.addNewMenu = addNewMenu;
      $scope.toggleShowAllFields = toggleShowAllFields;
      $scope.adminOps = {
        getVenue
      };
      $scope.ui = {};

      $scope.showLiteProfileFields = $location.search().isActive === 'true' ? true : false;

      init();

      // /// Listeners

      $scope.$on('RECALCULATE_VISIBILITY', function(ev, params) {
        if (params.schemaType !== 'Venue') {
          const key = toLower(params.schemaType) + 's';
          getVisibilityCount(key);
        }
      });
      // /// Functions

      /**
       * Initialization function
       *
       * Based on whether user is admin or host, different data is retrieved
       * since admins will search for venues using the text search and
       * hosts will have a dropdown of all their venues
       */
      function init() {
        $scope.validation = {};
        $scope.input = {};
        $scope.selectedVenue = null;
        $scope.isAdmin;
        $scope.user.$waitFor('LOGGED_IN', function() {
          $scope.isAdmin = $scope.user.isAdmin();
          $rootScope.$emit('HIDE_FOOTER');
          let method;
          let slug;
          if ($scope.user.isAdmin()) {
            if ($location.search().venue) {
              slug = $location.search().venue;
            }
            if ($routeParams.venue) {
              slug = $routeParams.venue;
            }
            if (!slug) {
              return $scope.$emit('userVenuesIndex');
            }
            method = _initAdmin;
          } else if ($scope.user.isHost()) {
            method = _initHost;
          } else {
            return $location.path('/404');
          }

          method(slug)
            .then(() => $scope.$emit('userVenuesIndex'))
            .catch(unwrapError);
        });

        $scope.user.$waitFor('NOT_LOGGED_IN', function() {
          $location.url(`/login?redirect=${$location.url()}`);
        });
      }

      function _initHost() {
        return $api.Venues.hosts.getAll({
          menus: true,
          drinks: true,
          getNonVisible: true,
          profileOwner: true
        }).
          then(function(response) {
            $scope.venues = get(response, 'data.data.venues');
            const menus: any = get(response, 'data.data.menus');
            $scope.menus = groupBy(menus, 'venue');
            const drinks: any = get(response, 'data.data.drinks');
            $scope.drinks = groupBy(drinks, 'venue');
          }).
          then(function() {
            if (get($scope, 'venues.length')) {
              let vidx = -1;

              if ($location.search().venue) {
                vidx = findIndex($scope.venues, { _id: $location.search().venue });
              }
              if ($routeParams.venue) {
                vidx = findIndex($scope.venues,
                  (venue: any) => venue.slug === $routeParams.venue);
              }
              $scope.selectVenue(vidx !== -1 ? vidx : 0);
              getVenueData($scope.selectedVenue);
            }
          })
          .catch(unwrapError);
      }

      function _initAdmin(slug) {
        return $api.Venues.get(slug, {
          menus: true,
          drinks: true,
          getNonVisible: true,
          profileOwner: true
        })
          .then(function(response) {
            const venue = get(response, 'data.data.venue');
            if (venue) {
              $scope.selectedVenue = venue;
              $scope.venues = [venue];
              const menus = get(response, 'data.data.menus');
              $scope.menus = groupBy(menus, 'venue');
              const drinks = get(response, 'data.data.drinks');
              $scope.drinks = groupBy(drinks, 'venue');
              getVenueData($scope.selectedVenue);
              if ($location.search().isActive === undefined && LITE_SPACE_STATUSES.includes($scope.selectedVenue.status)) {
                $scope.showLiteProfileFields = true;
              }
            }
          })
          .catch(unwrapError);
      }
      /**
       * Get keys from an object
       *
       * @param {Obj}
       * @return {Array}
       */
      function getKeys(obj) {
        return keys(obj);
      }

      /**
       * Validate a venue with the `$venueValidator` factory
       *
       * @param {Venue}
       */
      function getVenueData(venue) {
        return $venueValidator.validate(
          venue,
          venue.data.spaces,
          $scope.menus[venue._id.toString()],
          $scope.drinks[venue._id.toString()]
        ).then((res) => {
          $scope.validation = res;
          getVisibilityCount();
        })
        .catch(error => this.unwrapError(error));
      }

      /**
       * Select a venue on click and retrieve info for it
       *
       * @param {Number} idx
       * @return {Promise}
       */
      function selectVenue(idx) {
        $scope.selectedVenue = $scope.venues[idx];
        $scope.displayVenueList = false;
        $scope.input._venueName = '';
        getVenueData($scope.selectedVenue);
      }

      function readyToView(): any {
        const readyStatuses = ['Published', 'Review', 'Hidden'];
        const ready = includes(readyStatuses, get($scope.selectedVenue, 'status'));
        if (!ready) {
          return false;
        }
        return $scope.selectedVenue.status === 'Published' ?
          'See Published Venue' :
          'See Preview';
      }

      /**
       * Submit a venue for review by admins
       *
       * @param {Doc}
       * @return {Promise}
       */
      function submitForReview(doc) {
        return $api.Venues.save(doc, 'Review').
          then(function(response) {
            const submittedVenue = get(response, 'data.data');
            // analytics: venueDashboard.submittedVenueForReview
            const eventName = ANALYTICS_EVENTS.venueDashboard.submittedVenueForReview;
            const eventParams = submittedVenue;
            const eventAction = 'Submit';
            $analytics.$trackEvent(eventName, new $analytics.$Venue(submittedVenue), eventAction);

            const _scope = $scope;
            return ngDialog.
              open({
                template: require('./publish-venue-confirmation-modal.jade'),
                className: 'ngdialog-theme-small',
                overlay: true,
                plain: true,
                // tslint:disable-next-line: no-shadowed-variable
                controller: ['$scope', '$rootScope', function($scope, $rootScope) {
                  $scope.venue = submittedVenue;
                  $scope.goToVenue = function() {
                    $location.path('/venue/' + $scope.venue.slug).replace();
                  };
                  _scope.$on('$locationChangeStart', function() {
                    $scope.closeThisDialog($scope.venue);
                  });
                }]
              }).closePromise;
          }).catch(unwrapError);
      }

      /**
       * Get venues by name (admin only)
       *
       * @param {String} name
       */
      function getVenue(name) {
        $scope.displayVenueList = true;
        if (name && name.length < 3) {
          return;
        }
        $api.Admin.Venues.findManyByName(name, { menus: true, drinks: true, skipVisibility: true, profileOwner: true }).
          then(function(response) {
            $scope.venues = get(response, 'data.venues');
            const menus: any = get(response, 'data.menus');
            $scope.menus = groupBy(menus, 'venue');
            const drinks: any = get(response, 'data.drinks');
            $scope.drinks = groupBy(drinks, 'venue');
          })
          .catch(unwrapError);
      }

      /**
       * Delete a document on the backend by marking it as deleted
       *
       * @param {Doc}
       */
      function deleteDoc(doc) {
        const venueId = $scope.selectedVenue._id.toString();
        const schemaType = doc.schemaType;

        const docs = match(schemaType, {
          Menu: $scope.menus[venueId],
          Drink: $scope.drinks[venueId],
          Space: $scope.selectedVenue.data.spaces
        });

        DeleteFactory.deleteDoc($scope.selectedVenue, doc, docs).
          then(function(data) {
            if (get(data, 'cancel')) {
              return;
            }
            if (schemaType === 'Space') {
              getVisibilityCount('spaces');
              return;
            }
            if (schemaType === 'Menu') {
              $api.Menus.getVenueMenus(venueId, { skipVisibility: true })
              .then((menus) => {
                $scope.menus = groupBy(menus['menus'], 'venue');
                _sortMenus();
              })
               .catch(error => unwrapError(error));
            }
            const idx = findIndex(docs,
              (item: any) => item._id.toString() === doc._id.toString());
            docs.splice(idx, 1);
            match(schemaType, {
              Menu: () => getVisibilityCount('menus'),
              Drink: () => getVisibilityCount('drinks'),
              $default: () => getVisibilityCount()
            });

          })
          .catch(unwrapError);
      }

      /**
       * Gets the counts for incomplete, hidden, and complete documents. In this case,
       * hidden is complete but invisible
       */
      function getVisibilityCount(path?: string) {
        if (!$scope.visibilityCount) {
          $scope.visibilityCount = {};
        }

        const paths = path ? [path] : ['spaces', 'menus', 'drinks'];

        const sections = get($scope, 'validation.sections');
        if (!sections) {
          return;
        }

        each(paths, _setCounts);

        function _setCounts(key) {
          const docSection = sections[key];
          if (!docSection) {
            return;
          }
          $scope.visibilityCount[key] = {
            complete: 0,
            hidden: 0
          };
          const validIds = keys(docSection.valids);
          const invalidIds = keys(docSection.invalids);

          const arrayToLoopOver = key === 'spaces' ?
            $scope.selectedVenue.data.spaces.filter(s => !s.isDeleted) :
            $scope[key][$scope.selectedVenue._id.toString()];

          each(arrayToLoopOver, function(d) {
            const id = d._id.toString();

            // 1. First check if the doc is an invalid. If so, it's ALWAYS incomplete
            if (includes(invalidIds, id)) {
              return;
            }

            // 2. Then check if the item is visible AND valid - then it's COMPLETE
            if (d.isVisible && includes(validIds, id)) {
              $scope.visibilityCount[key].complete++;
              return;
            }

            $scope.visibilityCount[key].hidden++;
          });
        }
      }

      function createVenue() {
        createVenueModal().
          then(function(data) {
            if (get(data, 'value.cancel')) {
              return;
            }
            if (get(data, 'value.redirect')) {
              $scope.$emit('REDIRECT', { redirect: data.value.redirect });
            }
          });
      }

      function openSidebar() {
        this.openSidebarEdit = !this.openSidebarEdit;
      }

      function showToggleVenue() {
        if (!$scope.selectedVenue) {
          return { valid: false };
        }

        return DocFactory.Venues.hasEnoughAssociatedDocs($scope.selectedVenue,
          $scope.menus[$scope.selectedVenue._id.toString()]);
      }

      function canToggle() {
        const readyStatuses = ['Published', 'Hidden'];
        return includes(readyStatuses, get($scope.selectedVenue, 'status'));
      }

      function addNewMenu() {
        const menus = $scope.menus[$scope.selectedVenue._id.toString()];

        const order = menus ? menus.length + 1 : 1;
        $api.Menus.create({ venue: $scope.selectedVenue.slug, order })
          .then((menu: any) => {
            $location.path('/user/menus/' + menu.data.data._id);
          })
          .catch(error => unwrapError(error));
      }

      function updateOrder(list, index, direction) {
        let leftShifted;
        let rightShifted;
        if (direction === 'left') {
          leftShifted = list[index];
          const leftOrder = leftShifted.order;
          rightShifted = list[index - 1];
          const rightOrder = rightShifted.order;
          rightShifted.order = leftOrder;
          leftShifted.order =  rightOrder;
        } else if (direction === 'right') {
          leftShifted = list[index + 1];
          const leftOrder = leftShifted.order;
          leftShifted.order = leftShifted.order - 1;
          rightShifted = list[index];
          const rightOrder = rightShifted.order;
          leftShifted.order = rightOrder;
          rightShifted.order = leftOrder;
        }
        return [leftShifted, rightShifted];
      }

      function moveLeft(index, slug) {
        if (!index) {
          return;
        }

        let list ;

        if (slug === 'menus') {
          list = $scope.menus[$scope.selectedVenue._id.toString()];
          const updatedMenuOrder = updateOrder(list, index, 'left');
          saveMenuOrder(updatedMenuOrder)
            .then(() => _sortMenus())
            .catch(error => unwrapError(error));
        } else if ( slug === 'spaces') {
          list = $scope.selectedVenue.data.spaces;
          const updatedSpacesOrder = updateOrder(list, index, 'left');
          list.forEach((space) => {
            if (updatedSpacesOrder.indexOf(space) === -1) {
              updatedSpacesOrder.push(space);
            }
          });
          saveSpaceOrder(updatedSpacesOrder)
            .then(() => _sortSpaces())
            .catch(error => unwrapError(error));
        } else {
          return;
        }
      }

      function moveRight(index, slug) {
        let list ;

        if (slug === 'menus') {
          list = $scope.menus[$scope.selectedVenue._id.toString()];
          if (index === list.length - 1) {
            return;
          }
          const updatedMenuOrder = updateOrder(list, index, 'right');
          saveMenuOrder(updatedMenuOrder)
            .then(() => _sortMenus())
            .catch(error => unwrapError(error));
        } else if ( slug === 'spaces') {
          list = $scope.selectedVenue.data.spaces;
          const updatedSpacesOrder = updateOrder(list, index, 'right');
          list.forEach((space) => {
            if (updatedSpacesOrder.indexOf(space) === -1) {
              updatedSpacesOrder.push(space);
            }
          });
          saveSpaceOrder(updatedSpacesOrder)
            .then(() => _sortSpaces())
            .catch(error => unwrapError(error));
        } else {
          return;
        }
      }

      function saveMenuOrder (menus) {
        const data = {
          menus: menus.map((menu) => {
            return {
              _id: menu._id,
              order: menu.order
            };
          }),
          venueId: $scope.selectedVenue._id.toString()
        };
        return $api.Menus.saveMenuOrder(data)
          .then(() => $scope.ui.orderChanged = false)
          .catch(error => unwrapError(error));
      }

      function saveSpaceOrder (spaces) {
          spaces.sort((a, b) => a.order - b.order);
          const update = {
            spaces,
          };
          const venueId = $scope.selectedVenue._id.toString();
          return $api.Admin.Venues.updateSpacesOrder({ update, venueId })
            .then((data) => {
              $scope.selectedVenue.data.spaces = data.venue.data.spaces;
            }).catch (error => unwrapError(error));
      }

      function _sortMenus () {
        $scope.menus[$scope.selectedVenue._id.toString()].sort((a, b) => a.order - b.order);
      }

      function _sortSpaces () {
        $scope.selectedVenue.data.spaces.sort((a, b) => a.order - b.order);
      }

      function toggleShowAllFields() {
        return $scope.showLiteProfileFields = !$scope.showLiteProfileFields;
      }
    }]
  };
}
