import { get, keys, each } from 'lodash';

export default function() {
  return {
    template: require('./doc-edit-nav.jade'),
    scope: {
      slug: '=',
      hideBackButton: '='
    },
    controller: ['$scope', 'DocFactory', 'unwrapError', '$venueValidator', '$api', function($scope, DocFactory, unwrapError, $venueValidator, $api) {
      $scope.keys = keys;
      $scope.venueIsAllValid = venueIsAllValid;
      init();
      // /// Listeners
      $scope.$on('CHANGE_VENUE', function(ev, params) {
        init(params.slug);
      });

      // /// Functions
      function init(slug?: string) {
        slug = slug || $scope.slug;
        if (slug) {
          DocFactory.getDocsAndVenue(slug).then(function(response) {
            const venue: any = get(response, 'data.data.venue');
            $scope.venue = venue;
            $scope.spaces = get(venue, 'data.spaces');
            $scope.menus = get(response, 'data.data.menus');
            $scope.drinks = get(response, 'data.data.drinks');

            return validate();
          })
          .catch(unwrapError);
        }
      }

      function validate() {
        $venueValidator
          .validate($scope.venue, $scope.spaces, $scope.menus, $scope.drinks)
          .then((res) => {
            $scope.validation = res;
            $scope.back = `/user/venues?venue=${$scope.venue.slug}`;

            const lengthsToTrack = ['spaces', 'menus', 'drinks'];
            $scope.lengthTracker = {};

            each(lengthsToTrack,
              (l) => {
                const valids = get($scope, `validation.sections.${l}.valids`);
                let validIds = Object.keys(valids);
                validIds = validIds.filter(id => valids[id].isVisible);
                $scope.lengthTracker[l] = validIds.length;
              }
            );
          })
          .catch(error => unwrapError(error));
      }

      /**
       * Determine if the venue is all valid (has at least one space, one menu)
       *
       * @return {Boolean}
       */
      function venueIsAllValid() {
        if (!$scope.venue || !$scope.validation) {
          return;
        }

        return get($scope, 'validation.sections.venue.isAllValid');
      }
    }]
  };
}
