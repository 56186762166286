// NPM Dependencies
import get from 'lodash/get';
import moment from 'moment';

// Sixplus Dependencies
import { ApiService } from 'spc/shared/api/api.service';
import { MonthViewService } from './month-view/month-view.service';
import { TimeslotViewService } from './timeslot-view/timeslot-view.service';

class AvailabilityCalendarDashboardController {
  componentIsReady: () => any;
  isAdmin: boolean;
  isHost: boolean;
  venues: any[];
  venue: any;
  spaces: any[];
  calendars: any[];
  selectedDate: string;
  openSidebarEdit: boolean;
  closeSidebarEdit: boolean;
  changesSaved: () => any;

  ui: {
    viewState: 'calendar' | 'timeslots',
    calendarViewState: 'day' | 'month',
    selectedSpace?: any;
    eventsOnly: boolean;
    spaceClassMap?: any;
  } = {
    viewState: 'calendar',
    calendarViewState: 'month',
    eventsOnly: false
  };

  constructor(
    private $api: ApiService,
    private unwrapError,
    private now,
    private monthViewService: MonthViewService) {
    'ngInject';
  }

  $onInit() {
    this.componentIsReady();
    if (this.isHost) {
      this.$api.Venues.hosts.getPublished()
        .then(response => this.venues = get<any[]>(response, 'data.data.venues'))
        .then(() => this.selectVenue(0))
        .catch(error => this.unwrapError(error));
    }
  }

  getVenues = (name: string) => {
    return this.$api.Admin.Venues.findManyByName(name, { skipVisibility: false })
      .then((response) => {
        const venues: any = get(response, 'data.venues');
        if (!venues.length) {
          return;
        }
        this.venues = venues;
        if (this.venues.length === 1) {
          this.selectVenue(0);
        }
      })
      .catch(error => this.unwrapError(error));
  }

  selectVenue = (index: number): ng.IPromise<any> => {
    this.venue = this.venues[index];
    this.venues = this.isAdmin ? [] : this.venues;
    this.spaces = this.venue.data.spaces.filter(space => space.isVisible);
    this.ui.selectedSpace = this.spaces[0];
    this.ui.spaceClassMap = this.monthViewService.createSpaceClassMap(this.venue.data.spaces);
    return this.getCalendars()
      .then(() => this.selectedDate = this.now().format('YYYY-MM-DD'));
  }

  getCalendars = () => {
    return this.$api.Venues.getCalendars({ venueId: this.venue._id.toString() })
      .then(response => this.calendars = response.calendars)
      .catch(error => this.unwrapError(error));
  }

  openSidebar = () => {
    this.openSidebarEdit = true;
    this.closeSidebarEdit = false;
  }

  closeSidebar = () => {
    this.closeSidebarEdit = true;
    this.openSidebarEdit = false;
  }

  getCalendar = (space) => {
    return this.calendars.find(calendar => calendar.space.toString() === space._id.toString());
  }

  selectSpace = (space) => {
    this.ui.selectedSpace = space;
  }
}
export const AvailabilityCalendarDashboardComponent = {
  template: require('./availability-calendar-dashboard.component.jade'),
  controller: AvailabilityCalendarDashboardController,
  bindings: {
    componentIsReady: '&',
    changesSaved: '&',
    isAdmin: '<',
    venues: '<',
    isHost: '<'
  }
};
