// External Deps
import assign from 'lodash/assign';

// SP Deps
import { ApiService } from 'spc/shared/api/api.service';

// SP Interfaces
import { QueryRole } from 'server/api/requests/models';

export class PersonalEventsDashboardService {
  constructor(
    private $api: ApiService
  ) {
    'ngInject';
  }

  public getEvents = ({ role, search }: { role: QueryRole, search: any }) => {
    return this.$api.Requests.getEvents({ from: role }, assign({}, search, { count: true }));
  }
}
