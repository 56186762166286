import { get, cloneDeep } from 'lodash';
import { ApiService } from '../../shared/api/api.service';

const PAYMENT_TYPES = ['Manual', 'On Platform'];

export default function() {
  return {
    template: require('./manual-payment-control.jade'),
    scope: {
      requestParent: '=request',
      conversation: '<',
      user: '='
    },
    controller: ['$scope', 'unwrapError', 'ManualPaymentControlFactory', 'manualPaymentModal', '$clickOk', '$api', 'RequestStateValidatorFactory', 'adminPaymentModal', function($scope, unwrapError, ManualPaymentControlFactory, manualPaymentModal, $clickOk, $api: ApiService, RequestStateValidatorFactory, adminPaymentModal) {
      $scope.canSubmitBalancePayment = canSubmitBalancePayment;
      $scope.canSubmitManualDeposit = canSubmitManualDeposit;
      $scope.canSubmitIntermediate = canSubmitIntermediate;
      $scope.openManualDepositModal = openManualDepositModal;
      $scope.openManualBalanceModal = openManualBalanceModal;
      $scope.openIntermediateModal = openIntermediateModal;
      $scope.updateSkipBalance = updateSkipBalance;

      $scope.paymentTypes = PAYMENT_TYPES;

      $scope.paymentType;
      init();

      // Initialization Function
      function init() {
        if (!$scope.requestParent) {
          unwrapError(new Error('No request passed into admin manual payment control directive!'));
        }

        $scope.request = $scope.requestParent;
      }

      // Listeners
      $scope.$on('REQUEST_CHANGE', function(ev, params) {
        if (!params.request) {
          unwrapError(new Error('No request passed into params to change request in ManualPaymentControl'));
        }
        $scope.request = cloneDeep(params.request);
      });

      // Public functions

      /**
       * Opens modal for admin to fill out the deposit information for a request
       *
       * @api public
       * @params {Request}
       * @return {Promise}
       */
      function openManualDepositModal(request) {
        return manualPaymentModal({ request, currentUser: $scope.user.$, conversation: $scope.conversation, modal: 'Deposit' })
          .then((result) => {
            if (get(result, 'value.cancel') || !get(result, 'value.request')) {
              return;
            }

            $scope.request = result.value.request;
            $scope.$emit('TOP_DOWN_REQUEST_CHANGE', { request: result.value.request });

            $scope.$broadcast('PAID_DEPOSIT_MANUALLY');
          })
          .catch(unwrapError);
      }

      function openManualBalanceModal(request) {
        return manualPaymentModal({ request, currentUser: $scope.user.$, conversation: $scope.conversation, modal: 'Balance' })
          .then((result) => {
            if (get(result, 'value.cancel') || !get(result, 'value.request')) {
              return;
            }

            $scope.request = result.value.request;
            $scope.$emit('TOP_DOWN_REQUEST_CHANGE', { request: result.value.request });
          });
      }

    function openIntermediateModal(request, type) {
        if (type === 'Manual') {
         return manualPaymentModal({ request, currentUser: $scope.user.$, conversation: $scope.conversation, modal: 'Intermediate' })
            .then((result) => {
              if (get(result, 'value.cancel') || !get(result, 'value.request')) {
                return;
              }

              $scope.request = result.value.request;
              $scope.$emit('TOP_DOWN_REQUEST_CHANGE', { request: result.value.request });
            });
        } else if (type === 'Charge') {
          return adminPaymentModal({
            conversation: $scope.conversation,
            request: $scope.request
          }).then((result) => {
            if (get(result, 'value.cancel') || !get(result, 'value.request')) {
              return;
            }

            $scope.request = result.value.request;
            $scope.$emit('TOP_DOWN_REQUEST_CHANGE', { request: result.value.request });
          });
        }
      }

      function updateSkipBalance() {
        $api.Admin.Requests.savePaths({ request: $scope.request, pathsAndValues: { setPath: 'data.payment.skipBalance', value: $scope.request.data.payment.skipBalance } });
      }

      /**
       * Determine if admin can submit the final balance charges for an event
       *
       * @api public
       * @return {Boolean}
       */
      function canSubmitBalancePayment() {
        return RequestStateValidatorFactory.validateSubmitManualFinalBalance($scope.request).valid;
      }

      /**
       * Determine if the manual payment control directive should appear. The directive should
       * only appear if the user is an admin and if the state is `ACCEPTED_GUEST` OR `ALTERATION_ACCEPTED_GUEST`
       *
       * @api public
       * @return {Boolean}
       */
      function canSubmitManualDeposit() {
        return RequestStateValidatorFactory.validateSubmitManualDeposit($scope.request).valid;
      }

      function canSubmitIntermediate() {
        return RequestStateValidatorFactory.validateSubmitIntermediatePayment($scope.request).valid;
      }
    }]
  };
}
