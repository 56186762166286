import { DUser } from 'spc/lib/database/types/user';
import { ApiService } from 'spc/shared/api/api.service';
import { RawReview } from 'spc/lib/database/types/review';
import { RawBookingRequest } from 'spc/lib/database/types/booking-request';
import { ReviewsService } from './reviews.service';
import get from 'lodash/get';


interface DisplayedReview extends RawReview {
  stars?: {
    fullStars: number,
    halfStars: number,
    emptyStars: number
  };
}
interface Link {
  name: string;
}

class UserReviewsController {
  user: DUser;
  hasAccount: boolean = false;
  links: Link[];
  reviews: DisplayedReview[];
  proposals: RawBookingRequest[];
  activeView: string;
  constructor (
    private $api: ApiService,
    private $rootScope: ng.IRootScopeService,
    private $scope,
    private unwrapError,
    private reviewsService: ReviewsService,
    private $user ) {
    'ngInject';
    $scope.$on('CHANGE_PARENT_STATE', (event, args) => {
      if (args.state === 'Team Reviews') {
        this.activeView = 'Team Reviews';
        $scope.getTeamReview();
      }
      else if (args.state === 'Your Reviews') {
        this.activeView = 'Your Reviews';
        $scope.getUserReview();
      }
      else {
        this.activeView = 'Pending Reviews';
        $scope.getUserReview();
      }

        $scope.currentState = args.state;
    });
    $scope.getUserReview = () => {
      this.$api.BaseUsers.reviews()
        .then((data) => {
          this.reviews = data.reviews;
          this.proposals = data.proposals;
          this.reviews.forEach(review => review.stars = this.reviewsService.calculateStars(review.venueRecommendation));

          this.$rootScope.$emit('$viewReady', 'SHOW_FOOTER');
        })
        .catch(error => this.unwrapError(error));
    };
    $scope.getTeamReview = () => {
      this.$api.BaseUsers.teamReviews()
        .then((data) => {
          this.reviews = data.reviews;
          this.proposals = data.proposals;
          this.reviews.forEach(review => review.stars = this.reviewsService.calculateStars(review.venueRecommendation));

          this.$rootScope.$emit('$viewReady', 'SHOW_FOOTER');
        })
        .catch(error => this.unwrapError(error));
    };
  }

  $onInit = () => {
    this.user = !!this.$user.$ ? this.$user.$ : null;
    try {
      if (!this.user) {
        this.$api.Auth.me()
        .then((res) => {
          this.user = res.data.user;
          this.createLinks();
        });
      }
      this.createLinks();
    } catch (error) {
      this.unwrapError(error);
    }
  }


  createLinks = () => {
    if (get(this.user, 'account')) {
      this.hasAccount = true;
    }
    this.links = [
      { name: 'Pending Reviews' },
      { name: 'Your Reviews' },
      { name: 'Team Reviews' }
    ];
  }
}

export const userReviewComponent = {
  template: require('./user-reviews.component.jade'),
  controller: UserReviewsController
};
