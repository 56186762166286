const missingEventDetailModal = ['ngDialog', function(ngDialog) {
  return function (lead) {
    return ngDialog.open({
      template: require('./venue-edit-event-field-missing.component.jade'),
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.lead = lead;
        this.close = (proceed) => {
          $scope.closeThisDialog(proceed);
        };
      }]
    }).closePromise;
  };
}];


export default missingEventDetailModal;

