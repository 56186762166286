import { AuthorizedClientRequestBody, AuthorizedClientResponse } from 'server/api/authorized-clients/models';

export class AuthorizedClientRouter {
  BASE_URL: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    'ngInject';
    this.BASE_URL = `${API_ROOT}/authorized-clients`;
  }

  modifyClients = ({ request, clients }) => {
    const body: AuthorizedClientRequestBody = { clients };
    const url = `${this.BASE_URL}/request/${request._id.toString()}`;
    return this.$http.put(url, body)
      .then((response: { data: AuthorizedClientResponse }) => response.data);
  }
}
