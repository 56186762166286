import { ApiService } from 'spc/shared/api/api.service';
import get from 'lodash/get';
import { RawReview } from 'spc/lib/database/types/review';
import { ReviewsService } from './reviews.service';
import { RawVenue } from 'spc/lib/database/types/venue/venue';

interface DisplayedReview extends RawReview {
  stars: {
    fullStars: number,
    halfStars: number,
    emptyStars: number
  };
}

class VenueReviewsDashboard {
  venues: RawVenue[];
  venue: any;
  ui: {
    selectedVenueId?: string,
    scores?: { [key: string]: number; },
    stars?: {
      [criteria: string]: {
        fullStars: number,
        halfStars: number,
        emptyStars: number
      }
    }
  } = {};
  reviewsMap: { [key: string]: DisplayedReview[] };
  constructor(private $api: ApiService, private $route, private $rootScope, private unwrapError, private reviewsService: ReviewsService) {
    'ngInject';
  }

  $onInit = () => {
    this.$api.Venues.hosts.getAll({ reviews: true })
      .then((res) => {
        this.venues = get<RawVenue[]>(res, 'data.data.venues');
        this.reviewsMap = get<any>(res, 'data.data.reviewsMap');
        this.selectVenue(this.venues[0]);
        this.viewReady();
      })
      .catch(error => this.unwrapError(error));
  }

  private viewReady = () => {
    this.$rootScope.$emit('$viewReady', 'SHOW_FOOTER');
  }

  private selectVenue = (venue: RawVenue) => {
    this.ui.selectedVenueId = venue._id.toString();
    this.venue = venue;
    this.ui.scores = this.reviewsService.calculateRatings(this.reviewsMap[this.ui.selectedVenueId]);
    this.ui.stars = {
      venueRecommendation: this.reviewsService.calculateStars(this.ui.scores.venueRecommendation),
      ambience: this.reviewsService.calculateStars(this.ui.scores.ambience),
      communication: this.reviewsService.calculateStars(this.ui.scores.ambience),
      food: this.reviewsService.calculateStars(this.ui.scores.food),
      service: this.reviewsService.calculateStars(this.ui.scores.service)
    };

    if (Object.keys(this.reviewsMap).length > 0 && this.reviewsMap[this.ui.selectedVenueId]) {
      this.reviewsMap[this.ui.selectedVenueId].forEach((review) => {
        review.stars = this.reviewsService.calculateStars(review.venueRecommendation);
      });
    }
  }
}

export const VenueReviewsDashboardComponent = {
  template: require('./venue-reviews-dashboard.component.jade'),
  controller: VenueReviewsDashboard,
  bindings: {
    reviews: '<'
  }
};
