import * as angular from 'angular';

export default angular.module('ngClickCopy', [])
  .service('ngCopy', ['$window', function ($window) {
    const body = angular.element($window.document.body);
    const textarea = angular.element('<textarea/>');
    textarea.css({
      position: 'fixed',
      opacity: '0'
    });

    return function (toCopy) {
      textarea.val(toCopy);
      body.append(textarea);
      (textarea[0] as any).select();
      document.execCommand('copy');
      textarea.remove();
    };
  }])
  .directive('ngClickCopy', ['ngCopy', function (ngCopy) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.bind('click', function (e) {
          ngCopy(attrs.ngClickCopy);
        });
      }
    };
  }])
  .name;
