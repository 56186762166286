import { findIndex } from 'lodash';

/**
 * ngDialog to delete a given credit card / payment method
 * @param {PaymentMethod} payment method object
 * @param {PaymentMethods} $scope.paymentMethods
 * @return {Promise} resolved when modal closes
 */
export default ['ngDialog', function(ngDialog) {
  return function(paymentMethod, paymentMethods) {
    return ngDialog.open({
      template: require('./delete-card-modal.jade'),
      className: 'ngdialog-theme-small info-modal',
      overlay: true,
      plain: true,
      controller: ['$scope', '$api', 'unwrapError', function($scope, $api, unwrapError) {
        $scope.close = function(deleted) {
          $scope.closeThisDialog({
            deleted: deleted
          });
        };
        $scope.removeCard = function() {
          return $api.Payment.removeCard(paymentMethod.token).
            then(function() {
              if (paymentMethods) {
                const indexOfMethod = findIndex(paymentMethods, function(m) {
                  return m.token === paymentMethod.token;
                });
                if (indexOfMethod > -1) {
                  paymentMethods.splice(indexOfMethod, 1);
                }
              }
              $scope.close(true);
            }).
            catch(unwrapError);
        };
      }]
    }).closePromise;
  };
}];
