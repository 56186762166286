import { merge } from 'lodash';
import qs from 'qs';
import { UpdateRequest, UpdateResponse, UpdatePathOp } from 'shared/interfaces/admin/events/update-request.model';
import oboe from 'oboe';
import { resolve } from 'url';

import { ResolveEventRequest, PostDepositCancellationResponse, ValidateResponse } from 'server/api/admin/requests/models';
import { DBookingRequest, RawBookingRequest } from 'spc/lib/database/types/booking-request';

export class Requests {
  BASE_URL: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService, private $jwt) {
    this.BASE_URL = `${API_ROOT}/admin/requests`;
  }

  /**
   * Assigns clients to a request after it exists. Not used atm
   */
  assignClients = (request, clients: any[]) => {
    return this.$http.put(`${this.BASE_URL}/${request._id}/clients`, { clients });
  }

  changeState(request, state: string, paidDirectlyToVenue?: boolean) {
    const self = this;
    const url = `${self.BASE_URL}/${request._id}/state/${state}`;
    return self.$http.put(url, { paidDirectlyToVenue });
  }

  getAll(pageNum: number = 0, searchParams?) {
    const self = this;
    const query = merge({ pageNum }, searchParams);
    const url = `${self.BASE_URL}/all`;
    return self.$http.get(url, { params: query });
  }

  getCount(searchParams) {
    const self = this;
    const url = `${this.BASE_URL}/count`;
    return self.$http.get(url, { params: searchParams });
  }

  getCounts() {
    const self = this;
    const url = `${self.BASE_URL}/counts`;
    return self.$http.get(url);
  }

  putPayment(request, identifier, cardParams) {
    const self = this;
    const body = identifier;
    merge(body, cardParams);

    const url = `${self.BASE_URL}/${request._id}/payment`;
    return self.$http.put(url, body);
  }

  resolveEvent = ({ bookingRequestId, state, resolutionTransaction }: ResolveEventRequest): ng.IPromise<PostDepositCancellationResponse> => {
    const url = `${this.BASE_URL}/${bookingRequestId}/resolve`;
    return this.$http.post(url, { bookingRequestId, state, resolutionTransaction })
      .then((response: { data: PostDepositCancellationResponse }) => response.data);
  }

  save(request) {
    const self = this;
    const url = `${self.BASE_URL}/${request._id}`;
    return self.$http.put(url, request);
  }

  savePaths({ request, pathsAndValues }: { request: RawBookingRequest, pathsAndValues: UpdatePathOp[] | UpdatePathOp }): ng.IPromise<UpdateResponse> {
    const paths = Array.isArray(pathsAndValues) ? pathsAndValues : [pathsAndValues];
    const self = this;
    const url = `${self.BASE_URL}/${request._id}/paths`;
    const reqBody: UpdateRequest = { paths };

    return self.$http.put(url, reqBody)
      .then((response: { data: UpdateResponse }) => {
        return response.data;
      });
  }

  saveOverages(requestId: string, overages: any[]) {
    const self = this;
    const url = `${self.BASE_URL}/${requestId}/overage`;
    return self.$http.put(url, { overages });
  }

  // submitManualDeposit(request, payment) {
  //   const self = this;
  //   const url = `${ self.BASE_URL }/${ request._id.toString() }/payment/manual/deposit`;
  //   return self.$http.post(url, { payment });
  // }

  // submitManualFinalBalance(request, options) {
  //   const self = this;
  //   const url = `${ self.BASE_URL }/${ request._id.toString() }/payment/manual/final`;
  //   return self.$http.post(url, { options });
  // }

  changeLead = ({ request, leadId }) => {
    const requestId = request._id ? request._id.toString() : request.toString();
    const url = `${this.BASE_URL}/${requestId}/lead`;
    return this.$http.put(url, { leadId })
      .then(response => response.data);
  }

  download = (searchParams) => {
    // We have to set the array format to brackets. By default, QS sets the format to indicies however, for arrays larger than 20 indices, qs stops specifying indicies when parsing stringified arrays for security reasons. Setting the array format to brackets somehow fixes the issue.
    // There is solution to solve this directly - setting the array limit to larger a number - but that was tried and didn't work.

    const url = `${this.BASE_URL}/download?${qs.stringify(searchParams, { arrayFormat: 'brackets' })}`;
    return new Promise((resolve, reject) => {
      oboe({
        url,
         headers: {
          Authorization: this.$jwt.get(),
          Accept: 'application/json',
          'sixplus-browser-type': 'Modern Browser',
          'content-type': 'application/json'
        }
      })
        .done(function (requests) {
          resolve({ requests });
        })
        .fail(reject);
    });
  }

  validate({ request }: { request: DBookingRequest }): ng.IPromise<ValidateResponse> {
    const url = `${this.BASE_URL}/validate`;
    return this.$http.post(url, { request })
      .then(response => response.data);
  }
}
