class TopLevelController {
 constructor(private $rootScope, private $seo) {
   'ngInject';
 }
 componentIsReady = () => {
   this.$rootScope.$emit('$viewReady', 'SHOW_FOOTER');
 }
}

export const topLevelVirtualSearchComponent = {
  template: require('./top-level-virtual-search.component.jade'),
  controller: TopLevelController
};
