// Our Deps
import { HTTPResponse } from './models';
import {
  NewUserRequest,
  ExistingConversationMessageRequest,
  CreateProposalRequest,
  GetMessagesRequestQuery,
  GetConversationsResponse,
  GetConversationsQuery,
  GetConversationsCountsQuery,
  GetConversationsCountsResponse,
  UpdateAttachmentsResponse,
  AddUsersResponseBody,
  AddUsersResponse
} from 'server/api/conversations/models';

interface GetConversationUserOptions {
  from: 'venue' | 'client';
}
export class ConversationRouter {
  BASE_URL: string;

  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${this.API_ROOT}/conversations`;
  }

  public getConversations = (userOptions: GetConversationUserOptions,
    queryParams?: GetConversationsQuery) => {
    const url = `${this.BASE_URL}/${userOptions.from}`;

    return this.$http
      .get(url, { params: queryParams })
      .then((response: HTTPResponse<GetConversationsResponse>) => response.data);
  }

  public getConversationsCounts = (userOptions: GetConversationUserOptions,
    queryParams: GetConversationsCountsQuery | GetConversationsQuery) => {
    const url = `${this.BASE_URL}/${userOptions.from}/count`;

    return this.$http.get(url, { params: queryParams })
      .then((response: HTTPResponse<GetConversationsCountsResponse>) => response.data);
  }

  public getPending = (userOptions: GetConversationUserOptions,
    queryParams?: GetConversationsQuery) => {
    const url = `${this.BASE_URL}/${userOptions.from}/pending`;
    return this.$http
      .get(url, { params: queryParams })
      .then((response: HTTPResponse<GetConversationsResponse>) => response.data);
  }

  public markAllMessagesRead = ({ conversation }) => {
    const url = `${ this.BASE_URL }/${ conversation._id }/messages/read`;

    return this.$http
      .post(url, {})
      .then(response => response.data);
  }

  public getInquiries = (userOptions: GetConversationUserOptions,
    queryParams?: GetConversationsQuery) => {
    const url = `${this.BASE_URL}/${userOptions.from}/inquiry`;

    return this.$http
      .get(url, { params: queryParams })
      .then((response: HTTPResponse<GetConversationsResponse>) => response.data);
  }

  public startConversationWithVenue = ({ venue, data, user }: NewUserRequest &
    { venue: any }): ng.IPromise<{ conversationId: string }> => {
    const url = `${this.BASE_URL}/venue/${venue._id}`;

    const request: NewUserRequest = { data, user };

    return this.$http
      .post(url, request)
      .then((response: { data: { conversationId: string } }) => response.data);
  }

  public getConversationMessages = (conversationId: string,
    params?: GetMessagesRequestQuery): ng.IPromise<any> => {
    return this.$http
      .get(`${this.BASE_URL}/${conversationId}/messages`, { params })
      .then(res => res.data);
  }

  public addUserToConversation = (conversationId: string, params?: GetMessagesRequestQuery): ng.IPromise<AddUsersResponseBody> => {
    return this.$http
      .put(`${this.BASE_URL}/${conversationId}/users`, { params })
      .then((response: AddUsersResponse ) => response.data);
  }

  public sendMessage = ({ data, conversation, options, baseUser, attachments }:
    ExistingConversationMessageRequest &
    { conversation: any }): ng.IPromise<{ message: any }> => {
    const url = `${this.BASE_URL}/${conversation._id ? conversation._id : conversation }/messages`;

    const message: ExistingConversationMessageRequest = { data, options, baseUser, attachments };

    return this.$http
      .post(url, message)
      .then((response: { data: { message: any } }) => response.data);
  }

  public createProposal = ({ conversation, options, request }: CreateProposalRequest &
    { conversation: any }): ng.IPromise<{ conversationId: string }> => {
    const url = `${this.BASE_URL}/${conversation._id}/proposal`;

    const data: CreateProposalRequest = { options, request };
    return this.$http
      .post(url, data)
      .then((response: { data: { conversationId: string, request: any } }) => response.data);
  }

  public updateAttachments = ({ attachments, conversation }) => {
    const url = `${this.BASE_URL}/${conversation._id.toString()}/attachment`;
    const data = { attachments };
    return this.$http
      .post(url, data)
      .then((response: {data: UpdateAttachmentsResponse }) => response.data);
  }
}
