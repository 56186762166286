const cspQuickView = ['ngDialog', function(ngDialog) {
  return function ({ slug }) {
    return ngDialog.open({
      template: `<csp-quick-view slug="$ctrl.slug" close="$ctrl.close"></csp-quick-view>`,
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal csp-quik-view-modal-main',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.slug = slug;
        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];

export default cspQuickView;
