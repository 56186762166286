import * as angular from 'angular';

import { TopLevelConversationMessagesComponent, SendMessageComponent, CreateProposalFromConversationComponent } from './conversation-messages';

export const MessagingModule = angular.module('messaging', [])
  .component('topLevelConversationMessages', TopLevelConversationMessagesComponent)
  .component('sendMessage', SendMessageComponent)
  .component('createProposalFromConversation', CreateProposalFromConversationComponent)
  .name;

