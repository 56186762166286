const frozenRegistrationModal = ['ngDialog', function(ngDialog) {
  return function (user) {
    return ngDialog.open({
      template: '<frozen-registration user="$ctrl.user" close="$ctrl.close(register)"></frozen-registration>',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-plain info-modal',
      plain: true,
      overlay: false,
      controller: ['$scope', function ($scope) {
        this.user = user;
        this.close = (register) => {
          $scope.closeThisDialog({ register });
        };
      }]
    }).closePromise;
  };
}];

export default frozenRegistrationModal;
