const recosShareModal = ['ngDialog', function(ngDialog) {
    return function () {
      return ngDialog.open({
        template: require('./recos-share.jade'),
        controllerAs: '$ctrl',
        className: 'ngdialog-theme-small info-modal',
        plain: true,
        overlay: true,
        controller: ['$scope', function ($scope) {
          this.close = () => {
            $scope.closeThisDialog();
          };
        }]
      }).closePromise;
    };
  }];

  export default recosShareModal;
