import { RawBookingRequest } from 'spc/lib/database/types/booking-request';
import { RawLead } from 'spc/lib/database/types/lead';

const manageCollaboratorsModal = ['ngDialog', function(ngDialog) {
  let returnLeadClients;
  let returnProposals;
  return function ({ requests, lead }: { requests: RawBookingRequest[], lead: RawLead, userId: string }) {
    return ngDialog.open({
      preCloseCallback: function() {
        if (!returnLeadClients) {
          returnLeadClients = lead.clients;
        }
        if (!returnProposals) {
          returnProposals = requests;
        }
      },
      template: '<manage-collaborators requests="$ctrl.requests" lead ="$ctrl.lead" close="$ctrl.close({ updatedLead, updatedProposals })" update="$ctrl.update({updatedLeadClients, updatedProposals})"></manage-collaborators>',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.requests = requests;
        this.lead = lead;
        this.close = () => {
          $scope.closeThisDialog();
        };
        this.update = ({ updatedLeadClients, updatedProposals }) => {
          this.lead.clients = updatedLeadClients;
          returnLeadClients = updatedLeadClients;
          returnProposals = updatedProposals;
        };
      }]
    }).closePromise.then(() => {
      return Promise.resolve({ leadClients: returnLeadClients, proposals: returnProposals });
    });
  };
}];

export default manageCollaboratorsModal;
