export default ['ngDialog', function (ngDialog) {
  return function (venue, request) {
    return ngDialog.open({
      template: `<all-menus venue="$ctrl.venue" request="$ctrl.request"></all-menus>`,
      className: 'ngdialog-theme-plain info-modal',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function($scope) {
        this.venue = venue;
        this.request = request;
        this.close = (data) => {
          $scope.closeThisDialog(data);
        };
      }]
    }).closePromise;
  };
}];
