
const manageAccountMembersModal = ['ngDialog', function(ngDialog) {

  return function({ user, account }) {
    return ngDialog.open({
      template: '<manage-account-members user="$ctrl.user" account="$ctrl.account" close="$ctrl.close"></manage-account-members>',
      plain: true,
      className: 'ngdialog-theme-small',
      overlay: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function ($scope) {
        this.user = user;
        this.account = account;
        this.close = (response) => {
          $scope.closeThisDialog(response);
        };

      }],
    }).closePromise;
  };
}];
export default manageAccountMembersModal;
