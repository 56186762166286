import debounce from 'lodash/debounce';
import { RawLocation } from 'database/types/location';

import { ApiService } from 'spc/shared/api/api.service';
import { ToastService } from 'spc/shared/toast.service';

class EditLocationsController {
  locations: RawLocation[];
  ui: {
    editing?: boolean;
    location?: RawLocation;
    subLocations?: RawLocation[];
    adding?: boolean;
    cityName?: string;
    customUrl?: string;
  } = {};
  landingPageCities = this.ENUMS.acceptableUserCities.map(city => city.value);

  constructor(private $api: ApiService, private unwrapError, private ENUMS, private toast: ToastService) {
    'ngInject';
  }

  $onInit = () => {
    this.load();
  }

  load = () => {
    this.ui = {};
    return this.$api.Admin.Locations.get({ topLevelOnly: true })
      .then(data => this.locations = data.locations)
      .catch((error) => {
        this.toast.badNews('Failed', error.data.error.message);
        this.unwrapError(error);
      });
  }

  addTopLevelLocation = () => {
    this.ui.adding = true;
  }

  editLocation = (location: RawLocation) => {
    return this.$api.Admin.Locations.getOne(location)
      .then((data) => {
        this.ui = {
          editing: true,
          location: data.location,
          subLocations: data.subLocations
        };
      })
      .catch((error) => {
        this.toast.badNews('Failed', error.data.error.message);
        this.unwrapError(error);
      });
  }

  addSubLocation = ({ details, parent }) => {
    const location = {
      parent: parent._id,
      city: details.city || details.sublocality,
      state: details.state,
      country: details.country,
      area: details.vicinity,
      map: {
        center: {
          lat: details.latitude,
          lng: details.longitude
        }
      }
    };

    return this.$api.Admin.Locations.addLocationToSearchPage({ location, parent })
      .then((data) => {
        this.ui.subLocations.push(data.location);
        this.ui.cityName = null;
      })
      .catch((error) => {
        this.toast.badNews('Failed', error.data.error.message);
        this.unwrapError(error);
      });
  }

  addLocation = (details) => {
    const location = {
      isTopLevelLocation: true,
      city: details.city,
      state: details.state,
      country: details.country,
      area: details.vicinity,
      map: {
        center: {
          lat: details.latitude,
          lng: details.longitude
        }
      }
    };
    return this.createSearchPage(location)
      .then(() => {
        this.ui.cityName = null;
        this.load();
      })
      .catch((error) => {
        this.toast.badNews('Failed', error.data.error.message);
        this.unwrapError(error);
      });
  }

  saveLocation = ({ location, changes }) => {
    return this.$api.Admin.Locations.update({ location, changes })
      .catch((error) => {
        this.toast.badNews('Failed', error.data.error.message);
        this.unwrapError(error);
      });
  }

  debouncedSave = debounce(this.saveLocation, 400);

  createSearchPage = (location) => {
    return this.$api.Admin.Locations.createSearchPage(location)
      .catch(error => this.unwrapError(error));
  }

  getCitySearchLink = (city) => {
    return city.replace(' ', '-');
  }

  deleteLocation = (location: RawLocation) => {
    return this.$api.Admin.Locations.update({ location, changes: { isTopLevelLocation: false } })
      .then(() => this.locations = this.locations.filter(_location => _location._id !== location._id))
      .catch((error) => {
        this.toast.badNews('Failed', error.data.error.message);
        this.unwrapError(error);
      });
  }

  deleteSubLocation = (location) => {
    return this.$api.Admin.Locations.update({ location, changes: { parent: null } })
      .then(() => {
        this.ui.subLocations = this.ui.subLocations.filter(subLoc => subLoc._id !== location._id);
      })
      .catch((error) => {
        this.toast.badNews('Failed', error.data.error.message);
        this.unwrapError(error);
      });
  }
}

export const editLocationsComponent = {
  template: require('./edit-locations.component.jade'),
  controller: EditLocationsController
};
