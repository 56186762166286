import { includes } from 'lodash';
import { RawUser } from 'spc/lib/database/types/user';
import { RawVenue } from 'spc/lib/database/types/venue/venue';

export const roleChecker = {
  isHost(user): boolean {
    return includes(user.roles, 'Host');
  },

  isAdmin(user): boolean {
    return includes(user.roles, 'Admin');
  },

  isGuest(user): boolean {
    return !user.roles.length;
  },

  isHostOrAdmin(user): boolean {
    return roleChecker.isHost(user) || roleChecker.isAdmin(user);
  },

  isAuthorizedVenueUser({ user, venue }: { user: RawUser, venue: RawVenue} ): boolean {
    const userIds = venue.authorizedUsers.map( authUser => authUser.user._id ? authUser.user._id : authUser.user );
    return userIds.some(id => id.toString() === user._id.toString());
  }
};
