export const recoNotesModal = ['ngDialog', function(ngDialog) {
    return function (recoId) {
      return ngDialog.open({
        template: `<reco-notes reco-id="$ctrl.recoId" close="$ctrl.close"> </reco-notes>`,
        plain: true,
        className: 'ngdialog-theme-plain',
        overlay: true,
        controllerAs: '$ctrl',
        controller: ['$scope', function($scope) {
          this.recoId = recoId;
        }]
      }).closePromise;
    };
  }];
