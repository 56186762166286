// NPM Dependencies
import match from '../../utils/match';
import { get, set, debounce, includes } from 'lodash';

// SixPlus Dependencies
import { ApiService } from 'spc/shared/api/api.service';
import { ToastService } from 'spc/shared/toast.service';
import AdminService from '../admin.service';

// Interfaces
import { SearchCountResponse, SearchQuery } from 'server/api/admin/venues/models';
import { DistinctCity } from 'server/api/admin/search/models';
import { UpdateVenuePathOp } from 'server/api/admin/venues/models';
import { DVenue, RawVenue } from '../../../../database/types/venue/venue';
import fetchCitiesAndSubcities from 'spc/utils/fetchCitiesAndSubcities';
import { getCityNameFromSubcity } from 'spc/utils/getCityDisplayName';

interface CityHierarchyInterface {
  [key: string]: {
    neighborhoods: string[],
    state: string
  };
}

class AdminVenuesDashboard {
  statuses: string[];
  venuesPerPage: number;
  searchResults: boolean;
  distinctCities: DistinctCity[];
  search: SearchQuery;
  constants: { DEFAULT_ORDER: string };
  currentPage: number;
  review: number;
  published: number;
  lite: number;
  private: number;
  withAuthorizedUsers: number;
  onHold: number;
  loading: boolean;
  displayCitySelector: boolean = false;
  statusToClassMap: { [status: string]: string };
  allVenues: number;
  ui = { showFilters: false };
  assignees: any[];
  venues: any[];
  menus: any[];
  drinks: any[];
  invisibleStatuses: string[];
  targetFieldId: string;
  venueUsers: number;
  fullVideos: number;
  teaserVideos: number;
  venuePartners: number;
  onBoarding: number;
  incomplete: number;
  cityHierarchyObj: CityHierarchyInterface;
  getCityFromVenueCity = getCityNameFromSubcity;
  // constants

  headers: string[] = [
    'ID',
    'STATUS',
    'PRIORITY',
    'VENUE',
    'CITY',
    'SPACES',
    'MENUS',
    'USERS',
    'ACTION',
    'SUCCESS FEE',
    'PAYMENT PROCESSING FEE'
  ];

  ordering = {
    ID: 'admin.readableId',
    PRIORITY: 'admin.priority',
    STATUS: 'status',
    VENUE: 'data.name',
    CITY: 'data.address.city',
    SPACES: 'spaces',
    MENUS: 'menus',
    'SUCCESS FEE': 'admin.commissionPercentage'
  };

  constructor(
    private ENUMS,
    private $api: ApiService,
    private $jwt,
    private $venueValidator,
    private unwrapError,
    private toast: ToastService,
    private createVenueModal,
    private $location,
    private $rootScope,
    private $seo,
    private adminVenueService) {
    'ngInject';
    this.statuses = this.ENUMS.venueStatuses;
  }

  $onInit() {
    this.$seo.set('Venues (Admin) | SixPlus');
    const ctrl = this;
    ctrl.venuesPerPage = 50;
    ctrl.searchResults = false;
    ctrl.search = { sort: {} };

    ctrl.constants = {
      DEFAULT_ORDER: ctrl.ordering.ID
    };

    ctrl.search.sort = {};
    ctrl.search.sort[ctrl.constants.DEFAULT_ORDER] = -1;
    ctrl.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');

    ctrl.statusToClassMap = {
      Review: 'info-bg',
      'On Hold': 'info-bg',
      Published: 'success-bg',
      Partial: 'info-bg',
      Incomplete: 'error-bg',
      Rejected: 'error-bg',
      'Venue Quit': 'error-bg',
      'Venue Closed': 'error-bg',
      'Venue Fired': 'error-bg',
      Hidden: 'alternate-bg',
      Private: 'alternate-bg'
    };
    ctrl.invisibleStatuses = ctrl.ENUMS.venueStatuses.invisibleStatuses;
    ctrl.fetchAssignees();
    fetchCitiesAndSubcities(this.$api)
      .then(res => this.cityHierarchyObj = res);
    ctrl
      .displayVenues()
      .catch(error => ctrl.unwrapError(error));
  }

  displayVenues() {
    const ctrl = this;
    ctrl.search.sort = {};
    ctrl.search.sort[ctrl.constants.DEFAULT_ORDER] = -1;
    ctrl.startSearch();
    return ctrl.$api.Admin.Venues.getSearchCount()
      .then((countResults) => {
        ctrl.searchResults = false;
        ctrl.setCounts(countResults);

        return ctrl.$api.Admin.Venues.get(0, { includePrivate: true });
      })
      .then(function (venueData) {
        ctrl.currentPage = 0;
        ctrl.updateVenuesOnPage(venueData);
      })
      .catch((error) => {
        ctrl.endSearch();
        ctrl.unwrapError(error);
      });
  }

  createVenue = () => {
    this.createVenueModal()
      .then((data) => {
        if (get(data, 'value.cancel')) {
          return;
        }
        if (get(data, 'value.redirect')) {
          this.$location.path(data.value.redirect);
        }
      });
  }

  fetchAssignees = () => {
    return this.$api.Admin.Users.getAssignees({ team: 'Venue' })
      .then(response => this.assignees = response.data.assignees)
      .catch(error => this.unwrapError(error));
  }

  displayStatus(status: string) {
    const ctrl = this;
    ctrl.search = { status: status };
    return ctrl.searchVenues(0);
  }

  displayVideo(type: 'Full' | 'Teaser') {
    const ctrl = this;
    ctrl.search = { videoType: type };
    return ctrl.searchVenues(0);
  }

  displayProfileStatus(status: 'On-Boarding' | 'Venue Partner') {
    const ctrl = this;
    ctrl.search = { recoGuidance: status };
    return ctrl.searchVenues(0);
  }

  debouncedSearch = debounce(this.searchVenues, 300);

  searchVenues(pageNum) {
    const ctrl = this;

    if (!ctrl.search || !ctrl.search.text && !ctrl.search.cities && !ctrl.search.status && !ctrl.search.sort && !ctrl.search.recoGuidance && !ctrl.search.videoType) {
      return ctrl.displayVenues();
    }

    ctrl.startSearch();
    return ctrl.$api.Admin.Venues.getSearchCount(ctrl.search)
      .then(function (countResults: SearchCountResponse) {
        ctrl.setCounts(countResults);
        if (!ctrl.allVenues) {
          return ctrl.endSearch();
        }
        const searchQuery = ctrl.search || {};
        searchQuery.includePrivate = true;
        return ctrl.$api.Admin.Venues.search(searchQuery, pageNum).
          then(function (venueData) {
            ctrl.searchResults = true;
            ctrl.updateVenuesOnPage(venueData);
          });
      })
      .catch((error) => {
        ctrl.endSearch();
        ctrl.unwrapError(error);
      });
  }

  loadPage(index: number) {
    const ctrl = this;

    ctrl.currentPage = index + 1;
    ctrl.startSearch();
    if (ctrl.searchResults) {
      const searchQuery = ctrl.search || {};
      searchQuery.includePrivate = true;
      return ctrl.$api.Admin.Venues.search(searchQuery, index)
        .then(venueData => ctrl.updateVenuesOnPage(venueData))
        .catch(error => ctrl.unwrapError(error));
    } else {
      return ctrl.$api.Admin.Venues.get(index, { includePrivate: true })
        .then(venueData => ctrl.updateVenuesOnPage(venueData))
        .catch(error => ctrl.unwrapError(error));
    }
  }

  save = (venue: DVenue, paths: UpdateVenuePathOp) => {
    const ctrl = this;
    const origVenue = venue;
    ctrl.targetFieldId = '';
    return ctrl.$api.Admin.Venues.updateAdminFields({ venue: venue._id.toString(), paths: paths })
      .then(function (response) {
        const { getPath } = paths;
        origVenue.admin[getPath] = response.venue.admin[getPath];

        ctrl.toast.goodNews('Venue updated!', 'Venue was successfully updated');
      })
      .catch(function (error) {
        ctrl.toast.badNews('Error updating venue!', 'There was an error updating the venue');
        ctrl.unwrapError(error);
      });
  }

  updateVenueSpaces = async(venue, status) => {
    const spaces = venue.data.spaces;
    const updatedSpaces = [];
    if ( status === 'Lite' || status === 'Incomplete') {
      for (let i = 0; i < spaces.length; i++) {
        spaces[i].isLite = false;
        updatedSpaces.push(spaces[i]);
      }
      venue.spaces = updatedSpaces;
    }
    return venue;
  }

  validate = async (venue: RawVenue, originalStatus, newStatus) => {
    let validation;
    let canUpdate = true;
    venue.isVisible = true;
    venue.status = newStatus;
    venue = await this.updateVenueSpaces(venue, originalStatus);
    return this.$venueValidator.validate(
      venue,
      venue.data.spaces,
      this.menus ? this.menus[venue._id.toString()] : [],
      this.drinks ? this.drinks[venue._id.toString()] : []
    )
      .then((res) => {
        validation = res;
        if (!validation.isAllValid) {
          venue.status = originalStatus;
          this.toast.badNews(`Venue not ready', 'This venue isn\'t ready to be ${newStatus}`);
          canUpdate = false;
        }
        return canUpdate;
      })
      .catch(error => this.unwrapError(error));
  }

  updateVenueStatus = (venue, status) => {
    const ctrl = this;
    return ctrl.$api.Admin.Venues.changeStatus(venue, status)
      .then(function (response) {
        const update: any = get(response, 'data.venue');
        if (status === 'Private') {
          set(venue, 'internal.uuid', update.internal.uuid);
        }
        match(venue.status, {
          'On Hold': function () {
            if (ctrl.onHold > 0) {
              ctrl.onHold--;
            }
          },
          Review: function () {
            if (ctrl.review > 0) {
              ctrl.review--;
            }
          },
          $default: () => ({})
        });

        match(status, {
          'On Hold': function () { ctrl.onHold++; },
          Review: function () { ctrl.review++; },
          $default: () => ({})
        });

        venue.admin.recoGuidance = update.admin.recoGuidance;
        venue.status = status;
        ctrl.toast.goodNews('Status Update Successful', `${venue.data.name}'s status successfully updated to ${status}`);
      })
      .catch(function (error) {
        ctrl.toast.badNews('Status Updated Failed', `There was an error in updating ${venue.data.name}'s status`);
        ctrl.unwrapError(error);
      });
  }

  setReadableId = (id) => {
    this.targetFieldId = id;
  }

  showOkButton = (id) => {
    if (this.targetFieldId === id) {
      return true;
    }
    return false;
  }

  async changeStatusOfVenue(venue: any, status: string) {
    const ctrl = this;
    const originalStatus = venue.status;

    if (venue.status === status) {
      return;
    }
    if (venue.status === 'Lite' && !this.invisibleStatuses.includes(status)) {
      return;
    }

    // if changing to published, run validation to make sure admin fields are filled out
    let canUpdate = true;

    if (status === 'Published') {
      return this.validate(venue, originalStatus, status)
        .then((res) => {
          canUpdate = res;
          if (canUpdate) {
            return this.updateVenueStatus(venue, status);
          }
        });
    } else if (status === 'Hidden' && originalStatus === 'Published') {
      venue.isVisible = false;
    }

    return this.updateVenueStatus(venue, status);

  }

  setOrder(header: string) {
    const ctrl = this;

    const exclude = ['ACTION', 'SPACES', 'MENUS'];
    if (includes(exclude, header)) {
      return;
    }
    const path = ctrl.ordering[header];
    if (ctrl.search.sort[path]) {
      ctrl.search.sort[path] = ctrl.search.sort[path] * -1;
    } else {
      ctrl.search.sort = {};
      ctrl.search.sort[path] = -1;
    }
    return ctrl.searchVenues(0);
  }

  hasActiveFilters = () => {
    if (!this.search) {
      return false;
    }

    const activeFilters = Object.keys(this.search)
      .some((filter) => {
        if (this.search[filter] && filter !== 'sort') {
          return true;
        }
      });
    return activeFilters;
  }

  clearStatusFilter = () => {
    this.search.status = null;
    this.searchVenues(0);
  }

  clearCitiesFilter = () => {
    this.search.cities = [];
    this.searchVenues(0);
  }

  clearRecoGuidanceFilter = () => {
    this.search.recoGuidance = null;
    this.searchVenues(0);
  }

  clearProfileOwnerFilter = () => {
    this.search.profileOwner = null;
    this.searchVenues(0);
  }

  clearVideoFilter = () => {
    this.search.videoType = null;
    this.searchVenues(0);
  }

  clearAllFilters = () => {
    this.search = {};
    this.searchVenues(0);
  }

  privateUrl(venue: any): string {
    return `/venue/${venue.slug}/private/${get(venue, 'internal.uuid')}`;
  }

  getOwnerName = (owner) => {
    return this.adminVenueService.getOwnerName({ owner, assignees: this.assignees });
  }

  hideFilters = () => {
    this.ui.showFilters = false;
  }

  private startSearch() {
    this.ui.showFilters = false;
    this.loading = true;
  }

  private endSearch() {
    this.loading = false;
  }

  private setCounts(countResults: SearchCountResponse) {
    const ctrl = this;
    ctrl.allVenues = countResults.count;
    ctrl.venueUsers = countResults['Venue Users'];
    ctrl.fullVideos = countResults['Full Videos'];
    ctrl.teaserVideos = countResults['Teaser Videos'];
    ctrl.venuePartners = countResults['Venue Partners'];
    ctrl.onBoarding = countResults['On-Boarding'];
    ctrl.incomplete = countResults.Incomplete;
    ctrl.onHold = countResults.onHold;
    ctrl.published = countResults.published;
    ctrl.lite = countResults.lite;
    ctrl.private = countResults.private;
    ctrl.review = countResults.review;
  }

  private updateVenuesOnPage(venueData: { data: { data: { venues: any[], menus: any[], drinks: any[] } } }) {
    const ctrl = this;
    const data = venueData.data.data;
    ctrl.venues = data.venues;
    ctrl.menus = data.menus;
    ctrl.drinks = data.drinks;
    ctrl.endSearch();
  }
}

export const AdminVenuesDashboardComponent = {
  controller: AdminVenuesDashboard,
  template: require('./admin-venues-dashboard.component.jade')
};
