import * as angular from 'angular';
import { TopLevelAvailabilityCalendarComponent } from './top-level-availability-calendar.component';
import { AvailabilityCalendarDashboardComponent } from './availability-calendar-dashboard.component';

import { TimeslotViewModule } from './timeslot-view/timeslot-view.module';
import { DayViewModule } from './day-view/day-view.module';
import { MonthViewModule } from './month-view/month-view.module';

export const AvailabilityCalendarDashboardModule = angular.module('AvailabilityCalendarDashboardModule', [ TimeslotViewModule, DayViewModule, MonthViewModule ])
  .component('topLevelAvailabilityCalendar', TopLevelAvailabilityCalendarComponent)
  .component('availabilityCalendarDashboard', AvailabilityCalendarDashboardComponent)
  .name;
