// External Dependencies
import BookingRequestSchema from 'common/dist/schemas/BookingRequest';
import { fullPriceBreakdownCents } from 'common/dist/price';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';

// SixPlus Dependencies
import { ApiService } from 'spc/shared/api/api.service';

/**
 * Component to edit tax rates and update request directly
 */
export default function() {
  return {
    template: require('./edit-request-rate.jade'),
    scope: {
      request: '=',
      path: '@',
      readablePathName: '@',
      placeholderText: '@',
      previewPaths: '=',
      cancelPath: '@'
    },
    controller: ['$scope', '$api', 'unwrapError', function($scope, $api: ApiService, unwrapError) {
      $scope.onChangeValue = onChangeValue;
      $scope.validate = validate;
      $scope.save = save;
      $scope.cancel = cancel;

      init();

      // Initialiation function
      function init() {
        if (!$scope.cancelPath) {
          return unwrapError(new Error('Cancel path is required to instantiate editRequestRate directive'));
        }

        if (!$scope.path) {
          return unwrapError(new Error('Path to edit is required to instantiate editRequestRate directive'));
        }

        if (!$scope.previewPaths) {
          return unwrapError(new Error('Preview paths are required to display preview values'));
        }
        $scope.clone = cloneDeep($scope.request);
        $scope.value = get($scope.clone, $scope.path);
        $scope.costBreakdownCents = fullPriceBreakdownCents($scope.clone);
        $scope.validate();
      }

      const debouncedOnChangeValue = debounce(function(val) {
        // $scope.clone.set($scope.path, val);
        set($scope.clone, $scope.path, val);
        $scope.validate();
        $scope.costBreakdownCents = fullPriceBreakdownCents($scope.clone);
        $scope.$digest();
      }, 300);

      // Public Functions
      function onChangeValue(val) {
        debouncedOnChangeValue(val);
      }

      function validate() {
        const val = get($scope.clone, $scope.path);
        $scope.canSave = isFinite(get($scope.clone, $scope.path));
      }

      function cancel() {
        $scope.$emit('CANCEL_EDIT_RATE', { path: $scope.cancelPath });
      }

      function save() {
        $scope.loading = true;
        $scope.clone.prevContractAttachmentLength = get($scope.clone, 'contractAttachments.length');
        $api.Requests.updateDirectlyAsHost($scope.clone)
          .then((data) => {
            const r = data.request;
            $scope.$emit('REQUEST_CHANGE', { request: r });
            $scope.$emit('CANCEL_EDIT_RATE', { path: $scope.cancelPath });
          })
          .catch((error) => {
            $scope.loading = false;
            unwrapError(error);
          });
      }
    }]
  };
}
