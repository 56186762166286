import attachmentsModal from './attachments';
import newProposalModal from './newProposal';
import overagesModal from './overages';
import seePaymentAttachmentsModal from './seePaymentAttachments';
import manualPaymentModal from './manualPayment';
import addClientsModal from './add-clients.modal';
import allSpacesModal from './spaces-modal/all-spaces.modal';
import allMenusModal from './menus-modal/all-menus.modal';
import drinkPackagesModal from './drink-packages-modal/drink-packages.modal';
import { spaceCounterofferModal } from './space-counteroffer-modal/space-counteroffer.modal';
import { editGuestsModal } from './edit-guests-modal/edit-guests.modal';
import { proposalContactsModal } from './proposal-contacts-modal/proposal-contacts.modal';
import { proposalHelpModal } from './proposal-help-modal/proposal-help.modal';
import { adminManageModal } from './admin-manage-modal/admin-manage.modal';
import { adminPaymentModal } from './admin-payment-modal/admin-payment.modal';
import { editMenusModal } from './edit-menus-modal/edit-menus.modal';
import linkAttachmentModal from './link-attachment';
import { requestCancelModal } from './request-cancel-modal/request-cancel.modal';

export default {
  attachmentsModal,
  manualPaymentModal,
  newProposalModal,
  overagesModal,
  seePaymentAttachmentsModal,
  addClientsModal,
  allSpacesModal,
  allMenusModal,
  spaceCounterofferModal,
  editGuestsModal,
  proposalContactsModal,
  proposalHelpModal,
  adminManageModal,
  adminPaymentModal,
  editMenusModal,
  drinkPackagesModal,
  linkAttachmentModal,
  requestCancelModal
};
