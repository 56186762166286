import { set } from 'lodash';

export default function() {
  const ret = {
    setAddress
  };
  return ret;

  /**
   * Sets google places details on objects based on details returned from third-party API
   * 
   * @api public
   * @params {Doc}
   * @params {Details} details
   * @mutates `doc`
   */
  function setAddress(obj, details) {
    set(obj, 'address.city', _getGooglePlacesCity(details));
    set(obj, 'address.country', details.country);
    set(obj, 'address.zipcode', details.zip);
    set(obj, 'address.neighborhood', details.neighborhood);
    set(obj, 'address.state', details.state);
    set(obj, 'address.coordinates', [details.longitude, details.latitude]);
    set(obj, 'address.utcOffset', details.utc_offset);
    set(obj, 'address.googleMapsUrl', details.url);
    if (details.number && details.street) {
      set(obj, 'address.line1', `${ details.number } ${ details.street }`);
    }
  }

  // Private functions

  /**
   * Often, google will not return a value for "city" but will give us the correct city
   * for a sublocality. A good example of this is Brooklyn. Brooklyn will not be populated for
   * the city, but will be returned under the sublocality property.
   * 
   * @params {Details}
   * @return {String}
   * 
   */
  function _getGooglePlacesCity(details) {
    if (details.city) {
      return details.city;
    } else if (details.sublocality) {
      return details.sublocality;
    } else if (details.vicinity) {
      return details.vicinity;
    }
  }
}
