/*
 * Rust-inspired replacement for switch statements
 */

export default function Match(val, handlers) {
  if (handlers[val]) {
    return handleVal(handlers[val]);
  }
  if ('$default' in handlers) {
    return handleVal(handlers['$default']);
  }

  throw new Error('No handler specified for "' +
    val.toString() + '"');
}

function handleVal(v) {
  if (typeof v === 'function') {
    return v.call();
  }
  return v;
}
