module.exports = function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      var section = attrs.section;
      scope.$watch('accordion.section', function(n, o) {
        // Note: in JS, `'step2' > 'step1' === true`
        if (n === section || section > n) {
          $(element).hide();
        } else {
          $(element).show();
        }
      });

      $(element).hide();
    }
  }
};
