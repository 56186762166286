import { get, each, omit } from 'lodash';
// SixPlus Dependencies (CommonJS)
const cancellationPolicyHelpers = require('../../../shared/venues/cancellationPolicy');

export default function() {
  return {
    template: require('./cancellation-policy-message.jade'),
    scope: {
      venue: '='
    },
    controller: ['$scope', 'ENUMS', function($scope, ENUMS) {

      $scope.cancellationMessage = cancellationMessage;

      function init() {
        $scope.sortedPolicies = get($scope.venue, 'sortedCancellationPolicies', []);
        if (!$scope.sortedPolicies.length) {
          return;
        }
        each($scope.sortedPolicies, function(policyObj) {
          policyObj.isPositive = cancellationPolicyHelpers.isPositive(policyObj);
        });
      }

      ///// LISTENERS
      /**
       * Listen for venue, when it exists, run `init` and cancel listener
       */
      const listener = $scope.$watch('venue', function(venue) {
        if (!venue) {
          return;
        }
        init();
        listener();
      });
      ///// FUNCTIONS

      /**
       * Display cancellation policy message for a given cancellation policy
       * @param {policyObj} policyObj
       * @return {String}
       */
      function cancellationMessage(policyObj) {
        const availableBefore = policyObj ? policyObj.availableBefore : null;

        let singularOrPluralDays;
        if (get(policyObj, 'availableBefore') >= 0) {
          singularOrPluralDays = availableBefore > 1 ? 'days' : 'day';
        } else {
          singularOrPluralDays = 'days';
        }
        const policies = {
          'Full Refund': {
            firstHalfMessage: 'The client can cancel up to ',
            secondHalfMessage: `${ singularOrPluralDays } before the event and receive a full refund of their deposit.`,
            summary: 'they will receive a full refund of their deposit.'
          },
          'Partial Refund': {
            firstHalfMessage: 'The client can cancel up to ',
            secondHalfMessage: `${ singularOrPluralDays } before the event and receive a refund of `,
            refundTypes: omit(ENUMS.cancellationPolicy.refundQuantities, 'PERCENT_OF_THE_BALANCE_OF_THE_EVENT'),
            summary: `they will be refunded ${ cancellationPolicyHelpers.generatePartialRefundAmount(policyObj) }`
          },
          'No Refund': {
            firstHalfMessage: 'If the client cancels within ',
            secondHalfMessage: singularOrPluralDays + ' before the event, the client will forfeit their deposit.',
            summary: 'they will forfeit their deposit.'
          },
          Penalty: {
            firstHalfMessage: 'If the client cancels within ',
            secondHalfMessage: singularOrPluralDays + ' or less of the event, the guest will forfeit their deposit and owe ',
            refundTypes: omit(ENUMS.cancellationPolicy.refundQuantities, 'PERCENT_OF_DEPOSIT', 'PERCENT_OF_EVENT_TOTAL'),
            summary: 'they will forfeit their deposit and be charged ' + cancellationPolicyHelpers.generatePenaltyMessage(policyObj)
          }
        };
        return policies[policyObj.type];
      }
    }]
  };
}
