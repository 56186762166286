// NPM Dependencies
import moment from 'moment';
import JSONCrush from '../../../../../database/helpers/JSONCrush';

import {
  cloneDeep,
  each,
  find,
  get,
  set,
  isFinite,
  keys,
} from 'lodash';
import { queryHelpers } from '../../../utils/query';
// SixPlus Dependencies (CommonJS)
import { StateEmitter } from '../../../utils/StateEmitter';
import { load } from 'js-yaml';
import venueSearchMapComponent from './venue-search-map.component';

// constants
const JSON_DATE_FORMAT = 'YYYY-MM-DD HH:mm';
const STATES: string[] = ['START', 'INITIALIZED', 'LOADING', 'LOADED'];
const DEFAULT_STATE: string = 'START';
let ctrl;
export default class VenueSearchService {
  constructor(
    $api,
    unwrapError,
    ENUMS,
    $q,
    $routeParams,
    $location,
    $rootScope
  ) {
    'ngInject';
    ctrl = this;
    ctrl._api = $api;
    ctrl._$location = $location;
    ctrl._unwrapError = unwrapError;
    ctrl._ENUMS = ENUMS;
    ctrl._q = $q;
    ctrl._locationParams = $routeParams;
    ctrl._$rootScope = $rootScope;
  }

  /**
   * This is a stateless service. To eliminate state, the search service must be initialized with `activate`, which initializes an
   * object with all of the search methods, and which closes over the necessary dependencies. `Activate` Should be the only `public` method.
   *
   * @public
   * @param {String} location
   * @return {Promise} => {Object}
   *
   */

  activate(location, page, calledFromShell) {
    const res: any = {
      applyFilters,
      applyTextSearch,
      createFiltersFromParam,
      initSearch,
      reset,
      setPage,
      getValidTimes,
      setTime,
      setDate,
      getVenueViewUrl,
      toggleSkipVisibility,
      setViewType,
      setInitialCounts,
      setBounds,
      isUserbot,
    };

    res.filters = {
      date: null,
      venue: {},
      spaces: {},
      guests: {},
      menus: {},
    };

    res.createFiltersFromParam();

    res.state = new StateEmitter(STATES, DEFAULT_STATE);
    res.admin = {};
    res.page = page ? parseInt(page, 10) - 1 : 0;
    res.text = '';
    res.counts = {};
    res.bounds = { allowMapSearch: true };
    res.venues = [];
    res.spaces = [];
    res.initialCounts = {};
    res.viewType = 'VENUE';
    res.isBot = false;
    res.setZoomFlag = false;

    res.selectAll = {};

    res.location = location.searchLocation;
    res.display = location.display;
    res.stateBucket = location.stateBucket;
    res.state.$state('INITIALIZED');
    return res;

    /**
     * Initialize search service with a given location
     *
     * @private
     * @param {String} location
     * @return {Promise}
     */
    function initSearch() {
      if (!res.location) {
        throw new Error('Location must be provided');
      }

      if (res.state.$state() !== 'INITIALIZED') {
        throw new Error(
          'Search service must be initialized before running initial search'
        );
      }
      return res.applyFilters().then(() => res.setInitialCounts());
    }

    function createFiltersFromParam() {
      let filters;

      if (ctrl._locationParams.filters) {
        filters = JSON.parse(JSONCrush.uncrush(ctrl._locationParams.filters)).filters;
      }

      if (filters) {
        for (const key in filters) {
          if (filters[key]) {
            if (filters[key]['data.type']) {
              filters[key]['menus.data.type'] = filters[key]['data.type'];
              delete filters[key]['data.type'];
            }
            if (filters[key]['data.priceInCents']) {
              filters[key]['data.priceInCents'].forEach(function (e, index) {
                const range: any = find(
                  ctrl._ENUMS.searchPriceRanges,
                  (r: any) => r.value['$gte'] === e['$gte']
                );
                if (range) {
                  filters[key]['data.priceInCents'][index] = range.display;
                }
              });
              filters[key]['menus.data.priceInCents'] = filters[key]['data.priceInCents'];
              delete filters[key]['data.priceInCents'];
            }
            if (key !== 'guests') {
              res.filters[key] = filters[key];
            }
          }
        }
        res.filters['date'] = null;
      }
    }

    function setInitialCounts() {
      res.initialCounts = cloneDeep(res.counts);
    }

    /**
     * Reset search service
     *
     * @public
     * @return {Promise}
     */
    function reset() {
      res.filters = {
        date: null,
        venue: {},
        spaces: {},
        guests: {},
        menus: {},
      };
      res.text = '';
      res.counts = {};
      res.venues = [];
      res.page = 0;
      res.selectAll = {};
      res.bounds = { allowMapSearch: true };

      return res.applyFilters();
    }

    /**
     * Set pagination for search results
     *
     * @public
     * @param {Number} page
     * @return {Promise}
     */
    function setPage(pg: number) {
      res.venues = [];
      res.page = pg;
      return res.applyFilters();
    }

    /**
     * Set time on `res` for searches
     *
     * @public
     * @param {Object} time
     * @return {Void}
     */
    function setTime(time) {
      res.filters.date = res.filters.date || moment().add(4, 'days').hours(0).minutes(0);
      res.filters.date.hours(time.h).minutes(time.m);
    }

    /**
     * Sets view type (SPACE or VENUE)
     *
     * @public
     * @param {String} viewType
     * @return {Void}
     */
    function setViewType(viewType) {
      res.viewType = viewType;
    }

    function isUserbot() {
      return res.isBot;
    }
    /**
     * Set date on `res` for searches
     *
     * @public
     * @param {Moment} date
     * @return {Void}
     */
    function setDate(date) {
      res.filters.date = res.filters.date || moment().add(4, 'days').hours(19).minutes(0);
      res.filters.date.year(date.year()).month(date.month()).date(date.date());
    }

    /**
     * Run a text search along with the rest of the search filters
     *
     * @public
     * @return {Promise}
     */
    function applyTextSearch() {
      res.page = 0;
      res.bounds = res.text ? res.bounds : { allowMapSearch: true };
      return res.applyFilters();
    }

    /**
     * Toggles skipVisibility flag. Useful for admins to find non-visible spaces and venues
     *
     * @public
     * @return {Void}
     */
    function toggleSkipVisibility() {
      res.admin.skipVisibility = res.admin.skipVisibility ? null : 1;
    }

    /**
     * Apply the service's search filters and run the query on the backend
     *
     * @public
     * @param {String} pathToIgnore
     * @return {Promise}
     */
    function applyFilters(pathsToIgnore: string[]) {
      res.state.$state('LOADING');
      res.venues = [];
      const query = makeQuery();
      return ctrl._api.Search.find(res.location, query)
        .then(function (response) {
          const results = response.data.results;
          const isBot = response.data.isBot;
          const hasAccount = response.data.hasMembershipAccount;
          if (results.counts) {
            const countKeys = keys(results.counts);
            each(countKeys, function (path) {
              if (pathsToIgnore && pathsToIgnore.includes(path)) {
                return;
              }
              res.counts[path] = results.counts[path];
            });
          }
          res.venues = results.venues || res.venues;
          res.total = results.total;
          res.numPages =
            results.underThreshold && query.viewType === 'VENUE'
              ? null
              : results.numPages;
          res.numSpaces = results.numSpaces;
          res.aliases = results.aliases;
          res.successManagers = results.successManagers;
          res.ratings = results.ratings;
          res.state.$state('LOADED');
          res.isBot = isBot;
          res.hasAccount = hasAccount;
          res.liteCount = response.data.liteCount;
        })
        .catch(ctrl._unwrapError);
    }

    /**
     * Gets the url for the public venue view page for a venue (and a auto selects a space if spaceId)
     * and also allows for preloaded queries for space availability check in the url
     *
     * @public
     * @param {Object} venue
     * @param {String} spaceId
     * @return {String}
     */
    function getVenueViewUrl(venue, spaceId) {
      let queryString;
      const query: any = {};
      const date = get(res.filters, 'date');
      if (date) {
        query.time = date.valueOf();
      }

      const groupSize = get(res.filters, 'guests.max');
      if (isFinite(groupSize)) {
        query.groupSize = groupSize;
      }

      if (spaceId) {
        query.space = spaceId;
      }

      queryString = queryHelpers.makeQueryString(query);

      return `/venue/${venue.slug}${queryString}/`;
    }

    /**
     * Get an array of valid times that user can select for a search
     *
     * @public
     * @return {Array}
     */
    function getValidTimes() {
      const times = [];
      for (let i = 0; i < 24; i++) {
        const m = moment().hours(i);
        times.push({
          h: i,
          m: 0,
          display: m.minutes(0).format('h:mma'),
        });
      }

      return times;
    }
    // Private Functions

    /**
     * Since we use the string form of the search price for the UI (bc search counts use string form)
     * we just fix the price ranges before sending
     *
     * @private
     * @param {Array} venueFilters
     * @return {Void}
     */
    function handlePriceRanges(menuFilters) {
      if (menuFilters['menus.data.priceInCents']) {
        menuFilters['menus.data.priceInCents'].forEach(function (str, index) {
          const range: any = find(
            ctrl._ENUMS.searchPriceRanges,
            (r: any) => r.display === str
          );
          if (range) {
            menuFilters['menus.data.priceInCents'][index] = range.value;
          }
        });
      }
    }

    function setBounds({ upperRight, bottomLeft }) {
      set(res.bounds, 'upperRight', upperRight);
      set(res.bounds, 'bottomLeft', bottomLeft);
    }

    /**
     * Make the query to get venues/spaces
     *
     * @private
     * @return {Object}
     */
    function makeQuery() {
      const filters = cloneDeep(res.filters);
      const query: any = {};
      const search: any = { admin: res.admin };
      if (res.filters.date) {
        query.date = res.filters.date.format(JSON_DATE_FORMAT);
      }

      if (keys(filters.venue).length > 0) {
        handlePriceRanges(filters.venue);
        query.venue = cloneDeep(filters.venue);
      }
      if (keys(filters.spaces).length > 0) {
        query.spaces = filters.spaces;
      }
      if (keys(filters.guests).length > 0) {
        query.guests = filters.guests;
      }
      if (keys(filters.menus).length > 0) {
        query.menus = {};
        handlePriceRanges(filters.menus);
        keys(filters.menus).forEach((key) => {
          query.menus[key.replace('menus.', '')] = filters.menus[key];
        });
      }
      if (keys(query).length > 0) {
        search.filters = query;
      }

      if (res.page) {
        search.page = res.page;
      }

      if (res.text) {
        search.text = res.text;
      }

      if (res.viewType) {
        search.viewType = res.viewType;
      }

      if (res.bounds) {
        search.bounds = res.bounds;
      }

      const finalQuery = keys(search).length > 0 ? search : null;
      const searchStringQuery = cloneDeep(finalQuery);
      delete searchStringQuery.bounds;
      const crushedQuery = JSONCrush.crush(JSON.stringify(searchStringQuery)).replace('+', '%2B');
      const url = `/search/${location.display.replaceAll(' ', '-')}?filters=${crushedQuery}`;
      ctrl._$rootScope.disableViewTransition();
      if (!calledFromShell) {
        window.history.pushState({}, '', url);
      }
      return finalQuery;
    }
  }
}
