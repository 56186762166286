import MenuRequestHelpers from 'common/dist/virtuals/MenuRequest';

/**
 * Component for selecting a menu for the given bookingRequest.
 *
 * @property {Array} menus Array of menus
 * @property {BookingRequest} bookingRequest
 * @property {Function} persistRequest Persist the request to server
 * @fires SELECTED_MENU Fired when user completes this step
 * @fires SKIPPED_SELECT_MENU Fired when user skips this step
 */

module.exports = function () {
  return {
    template: require('./select-menu.jade'),
    scope: {
      menus: '=',
      bookingRequest: '=',
      persistRequest: '&'
    },
    controller: ['$scope', 'photoDialog', 'menuDetailModal', '$cloudinary', function ($scope, photoDialog, menuDetailModal, $cloudinary) {
      $scope.selectedMenu = null;
      $scope.menuDetailModal = menuDetailModal;
      $scope.$cloudinary = $cloudinary;

      $scope.select = select;
      $scope.Remove = Remove;
      $scope.openPhotoDialog = openPhotoDialog;
      $scope.continueOn = continueOn;

      /**
       * Selects given menu
       *
       * @param {Number} index
       * @sets/mutates $scope.bookingRequest
       */
      function select(index) {
          if (!$scope.bookingRequest) {
          return;
        }

        $scope.selectedMenu = index;
        if (index === null) {
          $scope.bookingRequest.data.menu = null;
        } else {
          $scope.bookingRequest.data.menu = MenuRequestHelpers.initFromMenu($scope.menus[index], $scope.bookingRequest.data.groupSize);
        }
      }
      function Remove(index) {
        if (!$scope.bookingRequest) {
          return;
        }
        $scope.bookingRequest.data.menu = null;
      }

      /**
       * Opens photo dialog for menu
       *
       * @param {Menu} menu
       * @return {Promise}
       */
      function openPhotoDialog(menu) {
        return photoDialog({ photos: menu.data.photos, initialPhoto: menu.data.coverIndex });
      }

      /**
       * Continues onto next step
       *
       * @emits `SKIPPED_SELECT_MENU` OR `SELECTED_MENU`
       */
      function continueOn() {
        return $scope.persistRequest().then(function () {
          if ($scope.selectedMenu === null) {
            $scope.$emit('SKIPPED_SELECT_MENU', $scope.bookingRequest);
          } else {
            $scope.$emit('SELECTED_MENU', $scope.bookingRequest);
          }
        });
      }
    }]
  };
};
