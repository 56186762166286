import { ApiService } from 'spc/shared/api/api.service';
import moment from 'moment';

class AdminCalendarController {
  ready: () => any;
  monthEvents: {
    [key: string]: { start: number; end: number; bookingRequest: string, conversation: string }[]
  };
  cities: string[];
  invisibleConversations: string[];

  filter: {
    cities: string[];
  } = { cities: [] };

  ui: {
    ready: boolean;
    spaceClassMap?: any;
    months: string[];
    selectedMonth?: string;
    selectedYear?: number;
    calendar: any[];
    weekdays: string[];
    displayCitySelector: boolean;
  } = {
    ready: false,
    months: moment.months(),
    calendar: [],
    weekdays: moment.weekdays(),
    displayCitySelector: false,
  };
  constructor(
    private $api: ApiService,
    private unwrapError,
    private now,
    private adminCalendarDayViewModal) {
    'ngInject';
  }

  $onInit = () => {
    this.ui.selectedMonth = moment.months()[this.now().month()];
    this.ui.selectedYear = this.now().year();
    this.createCalendar();
    return this.getEvents()
      .then(() => this.ready())
      .catch(error => this.unwrapError(error));
  }

  toggleStateDisplay = () => {
    this.ui.displayCitySelector = !this.ui.displayCitySelector;
  }

  closeCitySelector = (selectedCities: string[]) => {
    this.filter['cities'] = selectedCities;
    this.ui.displayCitySelector = false;
  }

  selectNextYear = (event) => {
    event.stopPropagation();
    this.ui.selectedYear++;
    this.selectMonth(this.ui.selectedMonth);
  }

  selectPreviousMonth = (event) => {
    event.stopPropagation();
    let currentMonth = this.ui.months.indexOf(this.ui.selectedMonth);
    if (currentMonth === 0) {
      currentMonth = 11;
      this.ui.selectedYear--;
    } else {
      currentMonth--;
    }
    this.ui.selectedMonth = this.ui.months[currentMonth];
    return this.selectMonth(this.ui.selectedMonth);
  }

  selectNextMonth = (event) => {
    event.stopPropagation();
    let currentMonth = this.ui.months.indexOf(this.ui.selectedMonth);
    if (currentMonth === 11) {
      currentMonth = 0;
      this.ui.selectedYear++;
    } else {
      currentMonth++;
    }
    this.ui.selectedMonth = this.ui.months[currentMonth];
    return this.selectMonth(this.ui.selectedMonth);
  }

  selectPreviousYear = (event) => {
    event.stopPropagation();
    this.ui.selectedYear--;
    this.selectMonth(this.ui.selectedMonth);
  }

  getEvents = () => {
    const data = {
      month: this.now().month(this.ui.selectedMonth).format('MM'),
      year: this.ui.selectedYear
    };

    return this.$api.Admin.AvailabilityCalendar.getMonthEvents(data)
      .then((response) => {
        this.monthEvents = response.monthEvents;
        this.cities = response.cities;
        this.invisibleConversations = response.invisibleConversations;
      });
  }

  selectMonth = (month) => {
    this.ui.selectedMonth = month;
    this.getEvents()
      .then(() => this.createCalendar())
      .catch(error => this.unwrapError(error));
  }

  createCalendar = () => {
    this.ui.calendar = [];
    const monthIndex = this.ui.months.indexOf(this.ui.selectedMonth);
    const startWeek = moment(`${monthIndex + 1}-${this.ui.selectedYear}`, 'M-YYYY').startOf('month').week();
    let endWeek = moment(`${monthIndex + 1}-${this.ui.selectedYear}`, 'M-YYYY').endOf('month').week();

    if (endWeek === 1) {
      endWeek = 53;
    }

    for (let week = startWeek; week <= endWeek; week++) {
      this.ui.calendar.push({
        week,
        days: Array.from({ length: 7 }, (n, i) => {
          const date = this.now().week(week).year(this.ui.selectedYear).startOf('week').add(i, 'day');
          return {
            date: date.format('D'),
            fullDate: date.format('YYYY-MM-DD')
          };
        })
      });
    }
  }

  handleCityClick = (city) => {
    const shouldRemove = this.filter.cities.includes(city);
    if (shouldRemove) {
      this.filter.cities = this.filter.cities.filter(filterCity => filterCity !== city);
    } else {
      this.filter.cities.push(city);
    }
  }

  filterEvents = (event) => {
    if (!this.filter.cities.length) {
      return true;
    }
    return this.filter.cities.includes(event.city);
  }

  isInvisibleConversation = (event) => {
    return this.invisibleConversations.includes(event.conversation.toString());
  }
}

export const AdminCalendarComponent = {
  controller: AdminCalendarController,
  template: require('./admin-calendar.component.jade'),
  bindings: {
    ready: '&'
  }
};
