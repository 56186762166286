// NPM Dependencies

import moment from 'moment';
import get from 'lodash/get';
import set from 'lodash/set';
import clone from 'lodash/clone';
import minBy from 'lodash/minBy';
import sortBy from 'lodash/sortBy';
import commafy from 'commafy';
import isEmpty from 'lodash/isEmpty';

// Sixplus Dependencies
import spaceAvailability from 'common/dist/spaceAvailability';
import availabilityCalendarHelpers from 'common/dist/virtuals/AvailabilityCalendar';
import bookingRequestHelpers from 'common/dist/virtuals/BookingRequest';

import time from 'common/dist/time';
import { ApiService } from 'spc/shared/api/api.service';
import { RecoRequestService } from '../../recos/reco-request/reco-request.service';
import { CityTzMapperService } from 'spc/shared/cityTZmapper.service';
const schedulerHelpers = require('../../../../shared/scheduler_helpers');

// Constants
const DEFAULT_BEGIN_TIME = 1900;
const DEFAULT_EVENT_DATE_IN_FUTURE = 7;

/**
 * Component for selecting the space for the given bookingRequest.
 *
 * @property {BookingRequest} bookingRequest
 * @property {Function} persistRequest Persist the request to server
 * @fires SELECTED_SPACE Fired when user completes this step
*/

class SelectSpaceController {
  pathsToValidate = [
    'startTime',
    'endTime',
    'date',
    'groupSize'
  ];

  persistRequest: () => any;

  ui: {
    startTimeOptions?: string[];
    endTimeOptions?: string[];
    showSpaces?: boolean;
    updatingSpaces?: boolean;
  } = {};

  search: {
    space?: string;
    startTime?: number;
    endTime?: number;
    date?: string;
    groupSize?: number;
  } = {};

  lead: {
    recommendations: any[];
    request: {
      date: string;
      time: number;
      duration: number;
    }
  };

  recommendation: any;

  bookingRequest: {
    data: {
      time: number,
      date: string;
      groupSize: number;
      duration: number;
      space: number;
      timeSlot: any;
    }
    selectedSpace: any;
    venue: {
      _id: string;
      slug: string;
      data: {
        spaces: any[];
      }
    }
  };

  allAvailabilities: any;
  timeAvailability: any = {};
  availabilityCalendars: any;
  allSpaces: any[];
  sortedSpaces: any[];
  displayedEvents: any = {};

  constructor(
    private photoDialog,
    private spaceDetailModal,
    private $api: ApiService,
    private $q,
    private $cloudinary,
    private AvailabilityFactory,
    private unwrapError,
    private ENUMS,
    private recoRequestService: RecoRequestService,
    private $timeout: ng.ITimeoutService,
    private cityTzMapperService: CityTzMapperService
  ) {
    'ngInject';
  }

  $onInit = () => {
    if (!this.bookingRequest || isEmpty(this.bookingRequest)) {
      return;
    }

    this.search.date = get<string>(this.bookingRequest, 'data.date') || get<string>(this.lead, 'request.date');
    this.search.startTime = get<number>(this.bookingRequest, 'data.time') || get<number>(this.lead, 'request.time');
    const duration = get<number>(this.bookingRequest, 'data.duration') || get<number>(this.lead, 'request.duration');
    this.search.endTime = time.getEndTimeFromStartTimeAndDuration(this.search.startTime, duration);

    this.search.groupSize = get<number>(this, 'bookingRequest.data.groupSize') || get<number>(this, 'lead.request.numGuests.max') || get<number>(this, 'lead.request.numGuests.min');

    if (!this.search.date) {
      const searchData = this.recoRequestService.getSearchData();
      const getData = this.recoRequestService.getData();
      this.search.space = get(getData, 'space._id', '');
      this.search.date = searchData.date;
      this.search.groupSize = searchData.guestCount;
      this.search.startTime = searchData.time;
      this.search.endTime = time.getEndTimeFromStartTimeAndDuration(this.search.startTime, searchData.duration);
    }
    this.getSpaces();

    this.setTimeSelectOptions();
    this.updateSpaces();
  }

  getSpaces = () => {{
    this.$api.Venues.Spaces.get(this.bookingRequest.venue.slug)
      .then(res => this.allSpaces = res.data.data)
      .catch(error => this.unwrapError(error));
  }}

  // Interface Manipulation
  canUpdateSpaces = () => {
    return this.search.startTime && this.search.endTime && this.search.date && this.search.groupSize;
  }

  availableSpaces = () => {
    if (!this.sortedSpaces) {
      return 0;
    }
    return this.sortedSpaces.filter(this.canSubmitBookingRequest).length;
  }

  timeSelected = () => {
    this.setTimeSelectOptions();
    this.updateSpaces();
  }

  dateSelected = (date) => {
    this.search.date = date.format('YYYY-MM-DD');
    this.setTimeSelectOptions();
    this.updateSpaces();
  }

  updateSpaces = () => {
    if (!this.canUpdateSpaces()) {
      return;
    }

    if (!this.allSpaces) {
      return this.$timeout(this.updateSpaces);
    }

    this.ui.updatingSpaces = true;

    const getCalendars = this.AvailabilityFactory.getAvailabilityCalendarsForVenue(this.bookingRequest.venue);

    const getAvailabilities = this.AvailabilityFactory.getAvailabilityForDate(this.allSpaces, this.search.date);

    this.$q.all([getCalendars, getAvailabilities])
      .then(([calendars, availabilities]) => {
        this.availabilityCalendars = calendars;
        this.updateFields(availabilities);
        this.ui.updatingSpaces = false;
      })
      .catch((error) => {
        this.ui.updatingSpaces = false;
        this.unwrapError(error);
      });
  }

  selectSpace = (space) => {
    if (!this.canSubmitBookingRequest(space)) {
      return;
    }
    const city = get(this.lead, 'request.city') ? get(this.lead, 'request.city') : get(this.bookingRequest, 'venue.data.address.city');
    this.bookingRequest.selectedSpace = space;
    this.bookingRequest.data.space = this.bookingRequest.venue.data.spaces.findIndex(_space => _space._id.toString() === space._id.toString());
    this.bookingRequest.data.date = moment.tz(this.search.date, this.cityTzMapperService.getCityTimezone(city)).format('YYYY-MM-DD');
    this.bookingRequest.data.time = this.search.startTime;
    const duration = time.getDurationFromTimes(this.search.startTime, this.search.endTime);

    this.bookingRequest.data.duration = duration;

    this.bookingRequest.data.timeSlot = spaceAvailability.getTimeSlot(
      this.bookingRequest.selectedSpace,
      this.bookingRequest.data.date,
      this.bookingRequest.data.time);
    this.bookingRequest.data.groupSize = this.search.groupSize;
    this.updateGroupSize(this.bookingRequest);
    return this.persistRequest();
  }

  matchesSpaceRequirements = (space) => {
    const spaceDoesntHaveRequest = !this.spaceHasRequest(space);
    const isAvailable = this.timeAvailability[space._id.toString()];
    return spaceDoesntHaveRequest && isAvailable;
  }

  canSubmitBookingRequest = (space) => {
    if (!this.bookingRequest) {
      return;
    }

    if (this.spaceHasRequest(space)) {
      return;
    }

    if (!space.isVisible) {
      return;
    }

    const isAvailable = this.timeAvailability[space._id.toString()];
    return isAvailable && !this.pathsToValidate.find(path => !get(this.search, path));
  }

  /**
 * If a time straddles two timeslots, display the minimum Food and Beverage
 * minimum between those slots. If a time falls squarely within a slot,
 * just display that time's food and beverage minimum.
 * @param {space}
 * @return {String|Number}
 */
  displayMin = (space) => {
    if (!space || !this.allAvailabilities) {
      return;
    }
    if (!space.showFbMin) {
      return 'Inquire to confirm';
    }

    let min;
    const slots = get<any>(this, 'allAvailabilities.' + space._id.toString() + '.data.availability.timeSlots');

    if (!slots || !slots.length) {
      min = undefined;
    } else {
      const inSlot = this.findSlot(space, slots);
      if (inSlot) {
        min = get(inSlot, 'terms.foodBeverageMin');
      } else {
        const theSlot = minBy(slots, function (slot) {
          return get(slot, 'terms.foodBeverageMin');
        });
        min = get(theSlot, 'terms.foodBeverageMin');
      }
    }
    if (isFinite(min)) {
      return !min ? 'No minimum spend' : '$' + commafy(min.toFixed(0));
    } else {
      return undefined;
    }
  }

  // modals

  openSpaceDetailModal = (space) => {
    const spaceClone = clone(space);
    this.spaceDetailModal(spaceClone);
  }

  // Helpers

  spaceHasRequest = (space) => {
    const id = space._id ? space._id : space;
    if (!this.lead) {
      return;
    }
    return this.lead.recommendations.find((reco) => {
      const selectedSpace = bookingRequestHelpers.selectedSpace(get(reco, 'conversation.request'));
      if (selectedSpace) {
        const spaceIsSelected = selectedSpace._id.toString() === id.toString();
        const isCurrentRecommendation = (get(this.recommendation, 'conversation.request.selectedSpace._id') || get(this.recommendation, 'space._id')) === id;
        return spaceIsSelected && !isCurrentRecommendation;
      }
    });
  }

  findSlot = (space, slots?) => {
    if (!this.allAvailabilities) {
      return;
    }
    slots = slots ? slots : get(this.allAvailabilities[space._id.toString()], 'data.availability.timeSlots');
    return this.AvailabilityFactory.findSlotWithStartAndEndTimes({ startTime: this.search.startTime, endTime: this.search.endTime, slots });
  }

  mockRequest = () => {
    return {
      data: {
        date: this.search.date,
        time: this.search.startTime,
        duration: time.getDurationFromTimes(this.search.startTime, this.search.endTime)
      }
    };
  }

  setTimeSelectOptions = () => {
    this.ui.startTimeOptions = time.getTimeSelectOptions(15);
    if (this.search.date === moment().format('YYYY-MM-DD')) {
      const currentTime = moment().hours() * 100 + moment().minutes();
      this.ui.startTimeOptions = this.ui.startTimeOptions.filter(time => time.value > currentTime);
    }
    if (this.search.startTime) {
      this.ui.endTimeOptions = time.getTimeSelectOptions(15)
        .filter(time => time.value > this.search.startTime);
    }
  }

  setDisplayedEvents = (space) => {
    const spaceId = space._id ? space._id.toString() : space;

    const calendar = this.availabilityCalendars.find(_calendar => _calendar.space === spaceId);

    let events = [];

    if (calendar) {
      events = get(calendar.availability, this.search.date, []);
      events = sortBy(events, event => event.startTime)
        .map((event) => {
          event.from = event.startTime;
          event.to = event.endTime;
          event.state = 'blocked';
          return event;
        });
    }
    /**
     * We're only restricting a BR submission if we know for certain that a space is blocked or booked (through the availability calendar) - timeslots have become irrelevant in this regard
     */
    this.displayedEvents[spaceId] = schedulerHelpers.mergeSlotsAndEvents(0, 2400, 1, events)
      .filter(event => event.data.isNA);
  }

  updateFields = (availabilities?) => {
    if (availabilities) {
      this.allAvailabilities = availabilities;
    }
    this.allSpaces.forEach((space) => {
      this.setDisplayedEvents(space);
      this.setIsTimeAvailable(space);
    });
    let spaces = this.allSpaces
      .map(space => spaceAvailability.overridePrice(space, this.allAvailabilities));

    // For reasons I don't yet understand - lodash's sorting function ranks `false` values higher than `true` values.
    spaces = spaces.map((space) => {
      const hasReason = this.matchesGroupSize(space).reason;
      space['isAvailable'] = hasReason ? false : true;
      return space;
    });
    const availableSpaces = spaces.filter(space => space.isAvailable);
    const warnedSpaces = spaces.filter(space => !space.isAvailable);
    this.sortedSpaces = [...availableSpaces, ...warnedSpaces];

    this.ui.showSpaces = true;
    return;
  }

  isAvailableInCalendar = (space) => {
    const calendar = this.availabilityCalendars.find(_calendar => _calendar.space.toString() === space._id.toString());
    if (!calendar) {
      return;
    }
    return availabilityCalendarHelpers.isAvailable({
      calendar,
      date: this.search.date,
      times: {
        startTime: this.search.startTime,
        endTime: this.search.endTime
      }
    });
  }

  updateGroupSize = (request) => {
    const groupSize = get(request, 'data.groupSize');

    // menu
    if (request.data.menu && !get(request, 'data.menu.none')) {
      set(request, 'data.menu.numGuests', groupSize);
    }

    // drinks
    request.data.drinks = request.data.drinks.map(function (drink) {
      if (!drink.payAtTheVenue) {
        drink.numGuests = groupSize;
      }
      return drink;
    });

    // addons
    request.data.addOns = request.data.addOns.map(function (addOn) {
      if (addOn.numGuests) {
        addOn.numGuests = groupSize;
      }
      return addOn;
    });
  }

  setIsTimeAvailable = (space) => {
    const isAvailable = this.isAvailableInCalendar(space);
    this.timeAvailability[space._id] = isAvailable;
  }

  isAvailable = (space) => {
    if (!space) {
      return;
    }
    return this.timeAvailability[space._id.toString()];
  }

  isNaNComparator = () => {
    return isNaN(this.search.endTime);
  }

  matchesGroupSize = (space) => {
    if (!space) {
      return;
    }
    return spaceAvailability.groupSizeAvailability(this.search.groupSize, space);
  }

  openPhotoDialog = (space) => {
    return this.photoDialog({ photos: space.data.photos, initialPhoto: space.data.photos[space.data.coverIndex] });
  }
}

export const SelectSpaceComponent = {
  template: require('./select-space.component.jade'),
  controller: SelectSpaceController,
  bindings: {
    lead: '=',
    recommendation: '<',
    bookingRequest: '=',
    persistRequest: '&',
    params: '=',
  }
};
