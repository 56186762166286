import { get, every } from 'lodash';

export default ['ngDialog', function (ngDialog) {
    return function (request) {
      return ngDialog.open({
        template: require('./link-attachment.jade'),
        className: 'ngdialog-theme-small',
        overlay: false,
        plain: true,
        controller: ['$scope', '$cloudinary', '$api', 'unwrapError', '$timeout', function ($scope, $cloudinary, $api, unwrapError, $timeout) {
          $scope.linkAttachment = {
            caption: '',
            url: '',
            type: 'Direct'
          };

          $scope.addToAttachments = addToAttachments;
          $scope.hasAllRequiredFields = hasAllRequiredFields;
          $scope.cancel = cancel;

          init();

          function init() {
            $scope.request = request;
          }

          function hasAllRequiredFields() {
            const requiredFields = [
              $scope.linkAttachment.caption,
              $scope.linkAttachment.url
            ];

            return every(requiredFields);
          }

          function cancel() {
            $scope.linkAttachment.caption = '';
            $scope.linkAttachment.url = '';
            $scope.closeThisDialog();
          }

          function addToAttachments() {
            if (!this.hasAllRequiredFields()) {
              $scope.closeThisDialog();
            }
            $scope.request.data.payment.attachments.push($scope.linkAttachment);
            $scope.closeThisDialog({ request: get($scope, `request.data.payment.attachments`) });
          }
        }]
      }).closePromise;
    };
}];
