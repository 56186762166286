import { capitalize, get, omit } from 'lodash';

module.exports = ['$api', '$rootScope', function ($api, $rootScope) {
  return {
    template: require('./change-login-info.jade'),
    scope: {
      user: '='
    },
    link: function (scope, element, attributes) {
      scope.data = {};
      scope.isOauth = scope.$parent.isOauth;
      scope.toChange = omit(attributes.$attr, ['user', 'isOauth']);
      /* This directive might be reusable for other info besides email/pw */
      scope.change = function change(property) {
        const capitalizedProperty = 'changing' + capitalize(property);
        scope[capitalizedProperty] = !scope[capitalizedProperty];
        if (scope[capitalizedProperty]) {
          scope[property] = '';
          if (property === 'password') {
            scope[property].showPassword = false;
          }
        }
      };

      scope.verifyEmail = function verifyEmail() {
        $api.Auth.verifyEmail(get(scope, 'data.email'))
          .then(function (res) {
            scope.email = {
              address: res.data.address,
              isValid: res.data.valid
            };
          })
          .catch(function (error) {
            scope.email = {
              address: error.data.address,
              isValid: error.data.valid
            };
          });
      };

      scope.getEmail = function getEmail() {
        return scope.email;
      };

      scope.resetEmail = function resetEmail() {
        scope.email = {};
        scope.error = null;
      };

      scope.submitChange = function submitChange(property) {
        if (!scope.data[property]) {
          scope.error = 'Please enter a value to change this field';
          return;
        }
        const change = {};
        change[property] = scope.data[property];
        const propertyChanged = capitalize(property);
        const confirmProperty = 'confirm' + propertyChanged;
        if (scope.data[confirmProperty]) {
          change[confirmProperty] = scope.data[confirmProperty];
        }

        $api.Auth.change(change).
          then(function (response) {
            scope['changing' + propertyChanged] = false;
            scope.data[property] = null;
            if (scope.data[confirmProperty]) {
              scope.data[confirmProperty] = null;
            }

            scope.user = response.data.user;
            $rootScope.$broadcast(propertyChanged.toUpperCase() + '_CHANGED', { message: propertyChanged + ' successfully changed' });
          }).
          catch(function (error) {
            scope.error = get(error, 'data.error') || 'There was an error changing your email. Please try again';
          });
      };

      scope.passwordsMatch = function passwordsMatch() {
        return scope.data.password === scope.data.confirmPassword;
      };

    }
  };
}];
