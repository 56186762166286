import { Attachment } from 'spc/lib/server/api/conversations/models';
import { RawConversation } from 'spc/lib/database/types/conversation';
import { ApiService } from 'spc/shared/api/api.service';
import cloneDeep from 'lodash/cloneDeep';

interface LeanAttachment {
  conversation: string;
  file: {
    url: string;
    title: string;
  };
  isNew: boolean;
  isDeleted?: boolean;
}

interface ContractAttachment {
  url: string;
  title: string;
  isDeleted?: boolean;
}

class MessageAttachmentsController {
  close: (attachments: LeanAttachment[] | ContractAttachment[]) => void;
  ui: {
    canSubmit?: boolean;
    displayedAttachments?: LeanAttachment[] | ContractAttachment[];
    buttonText?: string;
  } = {};
  attachments: any ;
  conversation: RawConversation;
  request: any;
  inputdisable: boolean;
  constructor(private $api: ApiService,
    private unwrapError,
    private $scope: ng.IScope,
    private $cloudinary,
    private attachmentModalServices) {
    'ngInject';
  }

  $onInit = () => {
    ({ attachments: this.attachments, ui: this.ui } = this.attachmentModalServices.setAttachments(this.attachments, this.$scope, this.conversation, this.request));
    this.disableOtherInputs();
  }

  public handleUpload = (url: string, title: string) => {
    let attachment;
    if (this.conversation) {
      attachment = {
      conversation: this.conversation._id ? this.conversation._id.toString() : this.conversation.toString(),
      file: {
        url,
        title
      },
      isNew: true
    };
    }
    else if (this.request) {
      if (this.request.contractUrl) {
        return;
      }
      attachment = {
        url,
        title,
        isNew: true
      };
    }
    this.attachments.push(attachment);
    this.ui.displayedAttachments = this.attachmentModalServices.setDisplayedAttachments(this.attachments);
    this.disableOtherInputs();
  }

  public removeAttachment = (attachment) => {
    attachment.isDeleted = true;
    this.ui.displayedAttachments = this.attachmentModalServices.setDisplayedAttachments(this.attachments);
    this.disableOtherInputs();
  }

  public disableOtherInputs = () => {
    if ( this.ui.displayedAttachments.length > 0) {
      this.inputdisable = true;
    }
    else {
      this.inputdisable = false;
    }
  }

  public finish = () => {
    if (this.ui.canSubmit) {
      let attachments = [];
      if (this.request && this.request.contractUrl) {
        this.request.contractUrl = this.request.contractUrl.includes('http') ? this.request.contractUrl : `https://${this.request.contractUrl}`;
        const newAttachment = {
          url: this.request.contractUrl,
          title: 'Link',
        };
        attachments.push(newAttachment);
      } else {
        attachments = this.ui.displayedAttachments;
      }
      return this.close(attachments);
    } else {
      return Promise.resolve();
    }
  }
}

export const messageAttachmentsComponent = {
  controller: MessageAttachmentsController,
  template: require('./message-attachments.component.jade'),
  bindings: {
    attachments: '<',
    conversation: '<',
    request: '<',
    close: '<'
  }
};
