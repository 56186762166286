
exports.$controller = function($scope, $seo, $user, $api, unwrapError, $location) {
  const title = 'Venue Dashboard';

  $seo.set(title);

  $scope.user = $user;

  $scope.$on('REDIRECT', function(ev, params) {
    $location.path(params.redirect);
  });

  $scope.$emit('$viewReady', 'SHOW_FOOTER');
};

exports.$inject = ['$scope', '$seo', '$user', '$api', 'unwrapError', '$location'];

exports.$waitFor = ['userVenuesIndex'];
