import { fullPriceBreakdownCents } from 'common/dist/price';

module.exports = function() {
  return {
    template: require('./request-price-detail.jade'),
    scope: {
      request: '='
    },
    controller: ['$scope', function($scope) {
      $scope.itemsWithoutRoomFeeCents = itemsWithoutRoomFeeCents;

      function itemsWithoutRoomFeeCents(doc) {
        const breakdown = fullPriceBreakdownCents(doc);
        return breakdown.totalWithoutTaxesAndFees - breakdown.roomFee - breakdown.extraToMeetMinimumSpend;
      }
    }]
  };
};
