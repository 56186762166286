const listShareModal = ['ngDialog', function(ngDialog) {
  return function () {
    return ngDialog.open({
      template: require('./list-share.jade'),
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];

export default listShareModal;

