// NPM Dependencies
import moment from 'moment';
import { fullPriceBreakdownCents, overageTotal, roomFeeCents } from 'common/dist/price';
import { getTimeSelectOptions } from 'common/dist/time';
import { overagePriceCents } from 'common/dist/virtuals/Payment';
import { fbMinCents, applyDelta } from 'common/dist/virtuals/BookingRequest';
import { each, get, set, unset, last, merge, reduce, isFinite, find, keys, some, startsWith, includes, round, cloneDeep, isNumber } from 'lodash';
import { ApiService } from '../../shared/api/api.service';
import menuRequestHelpers from 'common/dist/virtuals/MenuRequest';
import { downloadableUrl } from './directives-services';

/**
 * In contrast to the payment directive `costBreakdown`, this directive
 * displays the payment details for a bookingRequest. `costBreakdown` takes in
 * a payment from a request's payment schedule and shows a snapshot of the price
 * at that moment in time
 */
module.exports = function () {
  return {
    template: require('./request-payment-details-venue.jade'),
    scope: {
      request: '=',
      user: '=',
      client: '=',
      originalMenus: '<',
      originalDrinks: '=',
      venue: '=',
      shouldOpenReceiptModal: '<'
    },
    link: function (scope, elem) {
      scope.scrollToField = scrollToField;

      /////
      function scrollToField(field) {
        field = elem.find(field);
        if (field) {
          scope.$emit('SCROLL_TO_FIELD', { element: field });
        }
      }

      scope.$watch('originalMenus', function() {
        scope.handleMenusChange();
      });

      scope.$watch('originalDrinks', function() {
        scope.handleDrinksChange();
      });
    },
    controller: [
      '$scope',
      '$cloudinary',
      'ENUMS',
      '$api',
      '$counteroffer',
      '$timeout',
      '$q',
      'paymentHelpers',
      'overagesModal',
      'attachmentsModal',
      'OverageFactory',
      'RequestArrayFactory',
      'seePaymentAttachmentsModal',
      'menuDetailModal',
      'unwrapError',
      'AvailabilityFactory',
      'editWinelistsModal',
      'feeApplicationModal',
      'spaceCounterofferModal',
      'editGuestsModal',
      'messageAttachmentsModal',
      'editMenusModal',
      '$interval',
      function (
      $scope,
      $cloudinary,
      ENUMS,
      $api: ApiService,
      $counteroffer,
      $timeout,
      $q,
      paymentHelpers,
      overagesModal,
      attachmentsModal,
      OverageFactory,
      RequestArrayFactory,
      seePaymentAttachmentsModal,
      menuDetailModal,
      unwrapError,
      AvailabilityFactory,
      editWinelistsModal,
      feeApplicationModal,
      spaceCounterofferModal,
      editGuestsModal,
      messageAttachmentsModal,
      editMenusModal,
      $interval
    ) {
      $scope.fetchAndConvertUrl = $cloudinary.fetchAndConvertUrl;
      $scope.srcSet = $cloudinary.srcSet;
      $scope.hasTimeDelta = $counteroffer.hasTimeDelta;
      $scope.openSeePaymentAttachmentsModal = openSeePaymentAttachmentsModal;
      $scope.startEdit = startEdit;
      $scope.cancelEdit = cancelEdit;
      $scope.isNew = isNew;
      $scope.downloadableUrl = downloadableUrl;
      $scope.addDelta = addDelta;
      $scope.addMultipleDeltas = addMultipleDeltas;
      $scope.addNumGuestsDelta = addNumGuestsDelta;
      $scope.menuDetailModal = menuDetailModal;
      $scope.hasFbMinCents = hasFbMinCents;
      $scope.openFeeApplicationModal = openFeeApplicationModal;
      $scope.now = () => moment();

      // Type related fns
      $scope.selectEventType = selectEventType;

      // price-related fns
      $scope.overageTotal = overageTotal;
      $scope.overagePriceCents = overagePriceCents;
      $scope.needsRefund = needsRefund;
      $scope.fullPriceBreakdownCents = fullPriceBreakdownCents;
      $scope.roomFeeCents = roomFeeCents;
      $scope.getRoomFeeDollars = getRoomFeeDollars;
      $scope.calculatePayout = calculatePayout;

      // space-related coutneroffer fns
      $scope.startDateTimeEdit = startDateTimeEdit;
      $scope.startSpaceEdit = startSpaceEdit;
      $scope.startGuestEdit = startGuestEdit;
      $scope.setFbMin = setFbMin;
      $scope.setRoomFeeCents = setRoomFeeCents;

      // menu-related counteroffer fns
      $scope.setMenu = setMenu;
      $scope.startMenuEdit = startMenuEdit;
      $scope.addMenuDelta = addMenuDelta;
      $scope.setMenuPricePerGuestCents = setMenuPricePerGuestCents;
      $scope.handleMenusChange = handleMenusChange;
      $scope.handleDrinksChange = handleDrinksChange;

      // additional notes-related counteroffer fns
      $scope.addNewNote = addNewNote;
      $scope.isEditingNote = isEditingNote;
      $scope.saveNote = saveNote;
      $scope.canSaveNote = canSaveNote;
      $scope.deleteNote = deleteNote;

      // ui-related
      $scope.canEditRequest = canEditRequest;
      $scope.canAddOverages = canAddOverages;
      $scope.canAddFinalReceipt = canAddFinalReceipt;
      $scope.matchesTimeslot = matchesTimeslot;
      $scope.allowNote = ENUMS.bookingRequestState.activeStates.filter(state => state !== 'RECONCILED');
      $scope.shouldHidePayout = shouldHidePayout;
      $scope.isFbCustomFee = isFbCustomFee;
      $scope.isNonFbCustomFee = isNonFbCustomFee;
      $scope.hasVisible = hasVisible;

      // admin-ops
      $scope.openOveragesModal = openOveragesModal;
      $scope.openAttachmentModal = openAttachmentModal;
      $scope.openWinelistsModal = openWinelistsModal;
      $scope.openContractAttachmentsModal = openContractAttachmentsModal;
      $scope.isDisabled = isDisabled;
      const REQUEST_LISTENER_INTERVAL = 1000 * 10;

      ///// Listeners

      $scope.$on('CANCEL_EDIT_RATE',
        (ev, params) => set($scope, `state.${params.path}`, false));

      $scope.$on('REQUEST_CHANGE', function (ev, params) {
        $scope.request = params.request;
        createClone();
      });

      ///// Functions
      init();

      function init() {
        $scope.delta = {};
        $scope.deltaArr = $scope.deltaArr || [];
        $scope.fullPriceBreakdownCents = fullPriceBreakdownCents;
        $scope.host = true;
        $scope.editing = {};
        $scope.ENUMS = ENUMS;
        $scope.times = getTimeSelectOptions();
        $scope.loading = {};
        $scope.errorState = {};
        $scope.state = {};

        createClone();
        getSpaces();
        setRequestListener();

         // opens attachment modal
        if ($scope.shouldOpenReceiptModal) {
          return openAttachmentModal();
        }

        $scope.addOns = $counteroffer.addOns.getAll($scope.counterClone);
      }


      function handleDrinksChange() {
        $scope.drinks = $counteroffer.drinks.getAll($scope.originalDrinks, $scope.request);
      }

      function handleMenusChange() {
        const menus = $scope.originalMenus || [];

        $scope.menus = menus
          .filter(menu => menu.isVisible)
          .concat({
            data: {
              name: 'No Menu Desired'
            },
            none: true
          });

        const hasCustomMenu = $scope.counterClone.data.menu && $scope.counterClone.data.menu.isCustom;
        $scope.menus.push({
          isCustom: true,
          isVisible: true,
          data: {
            name: hasCustomMenu ? $scope.counterClone.data.menu.name : 'Custom Menu'
          },
          none: false
        });
      }

      function applyCounter(lastCounteroffer) {
        applyDelta($scope.clone, lastCounteroffer.delta);
        setSelectedSpace();
      }

      function setSelectedSpace() {
        $scope.clone.selectedSpace = $scope.clone.venue.data.spaces[0];
      }

      function createClone(options?) {
        if (!isFinite(get($scope.request, 'data.gratuityPercentage'))) {
          set($scope.request, 'data.gratuityPercentage',
            get($scope.request, 'venue.data.fees.gratuityPercentage'));
        }
        const lastCounteroffer: any = last($scope.request.counteroffers);
        $scope.clone = cloneDeep($scope.request);

        // host can apply deltas for READY and INIT counteroffer states
        if (lastCounteroffer && ['READY', 'INIT'].indexOf(lastCounteroffer.state) !== -1) {
          applyCounter(lastCounteroffer);
          $scope.deltaArr = lastCounteroffer.delta;
          each(lastCounteroffer.delta, function (delta) {
            $scope.delta[delta.path] = delta;
          });
        }

        // Get non-deleted elements from various subdoc arrays so that we dont have to do this every $digest cycle
        {
          $scope.fbAddOns = RequestArrayFactory.getFbAddOns($scope.clone);
          $scope.nonFbAddOns = RequestArrayFactory.getNonFbAddOns($scope.clone, true);
          $scope.visibleDrinks = RequestArrayFactory.filterNonDeleted($scope.clone.data.drinks);
        }

        $scope.counterClone = cloneDeep($scope.clone);
        $scope.schedule = paymentHelpers.getDisplayableSchedule($scope.request);
        $scope.costBreakdownCents =
          fullPriceBreakdownCents($scope.clone);
        $scope.timeSpan = $counteroffer.displayTime($scope.counterClone, $scope.delta);
      }

      function hasVisible(items: { isDeleted: boolean }[]) {
        return !!items.filter(item => !item.isDeleted).length;
      }

      function hasFbMinCents(request) {
        return isNumber(fbMinCents(request));
      }

      function openSeePaymentAttachmentsModal() {
        return seePaymentAttachmentsModal($scope.request).
          catch(unwrapError);
      }

      // Counteroffer-related fns

      function startEdit(path) {
        if (!$scope.clone) {
          return;
        }
        if (!$scope.counterClone) {
          $scope.counterClone = cloneDeep($scope.clone);
        }
        if (!path) {
          return;
        }

        $scope.editing[path] = true;
      }

      function cancelEdit(path) {
        if (!path) {
          return;
        }
        // dont use unset, so we can track all the fields that were edited with `hasOwnProperty`
        $scope.editing[path] = false;

        set($scope.counterClone, path, get($scope.clone, path));
        if ($scope.closeThisDialog) {
          $scope.closeThisDialog();
        }
      }

      function getRoomFeeDollars(request) {
        return round($scope.roomFeeCents(request) / 100, 2);
      }


      function isNew(path) {
        return $counteroffer.isNew($scope.delta, path);
      }

      /**
       * number of guests delta
       */
      // TODO: REFACTOR addMultipleDeltas to take in an array instead of an object so
      // we can guarantee order
      function addNumGuestsDelta() {
        const groupSize = get($scope.counterClone, 'data.groupSize');

        const clone = $scope.counterClone;

        const drinkDeltas = getDrinkDeltas(clone, groupSize);
        const addOnDeltas = getAddOnDeltas(clone, groupSize);

        const deltaUpdate = merge(drinkDeltas, addOnDeltas);
        deltaUpdate['data.groupSize'] = { value: groupSize };
        if ($scope.counterClone.data.menu) {
          deltaUpdate['data.menu.numGuests'] = { value: groupSize };
        }

        return $scope.addMultipleDeltas(deltaUpdate);

        function getDrinkDeltas(request, groupCount) {
          const startPath = 'data.drinks';
          return reduce(request.data.drinks, function (acc, drink: any, idx) {
            if (drink) {
              drink.numGuests = groupCount;
              acc[`${startPath}.${idx}`] = { value: drink };
            }
            return acc;
          }, {});
        }

        function getAddOnDeltas(request, groupCount) {
          const startPath = 'data.addOns';
          return reduce(request.data.addOns, function (acc, addOn: any, idx) {
            if (addOn && addOn.priceType === 'PER_PERSON') {
              addOn.numGuests = groupCount;
              acc[`${startPath}.${idx}`] = { value: addOn };
            }
            return acc;
          }, {});
        }
      }

      function getSpaces() {
        return $api.Venues.Spaces.get($scope.clone.venue.slug)
          .then((res) => { $scope.spaces = res.data.data; })
          .catch(error => unwrapError(error));
      }

      function startSpaceEdit() {
        return spaceCounterofferModal($scope);
      }

      function startDateTimeEdit() {
        spaceCounterofferModal($scope);
      }

      function startGuestEdit() {
        editGuestsModal($scope);
      }

      function setFbMin(dollars) {
        if (!$scope.counterClone) {
          return;
        }

        const dollarsAsNum = parseFloat(dollars);
        const cents = isFinite(dollarsAsNum) ?
          (dollarsAsNum * 100).toFixed(0) :
          0;

        set(
          $scope.counterClone,
          'host.foodBeverageMinCents',
          +cents
        );
      }

      function setRoomFeeCents(request, dollars) {
        const amount = dollars || 0;
        set(request, 'host.roomFeeCents', +(parseFloat(amount) * 100).toFixed(0));
      }

      function setMenu(menuIndex) {
        if (!$scope.menus || !menuIndex) {
          return;
        }

        const menu = $scope.menus[menuIndex];

        _resetCustomMenu();

        if (get($scope.counterClone, 'data.menu.none') && menu.none) {
          return;
        }

        if (!$scope.counterClone.data.menu || menu.none) {
          const clonedMenu = cloneDeep(menu);

          $scope.counterClone.data.menu = menuRequestHelpers.initFromMenu(clonedMenu, get($scope.counterClone, 'data.groupSize'));
        }

      }

      function startMenuEdit(path) {
        const theMenu = find($scope.menus,
          (menu: any) => menu.data.name === get($scope.counterClone, 'data.menu.name'));

        if (theMenu) {
          $scope._menu = theMenu;
        }

        editMenusModal($scope);
      }

      function addMenuDelta() {
        const menuToAdd: any = get($scope.counterClone, 'data.menu');
        $scope.menus.forEach((menu) => {
          if (menu.isCustom) {
            menu.data.name = menuToAdd.isCustom ? menuToAdd.name : 'Custom Menu';
          }
        });

        if ($scope.delta['data.menu']) {
          const delta: any = get($scope.delta, 'data.menu.newValue');
          if (delta && (delta.name === menuToAdd.name && delta.pricePerGuestCents === menuToAdd.pricePerGuestCents)) {
            $scope.editing['data.menu'] = false;
            return;
          }
        }

        return addDelta('data.menu', menuToAdd)
          .catch(unwrapError);
      }

      function setMenuPricePerGuestCents(dollars) {
        $scope.counterClone.data.menu.pricePerGuestCents =
          (parseFloat(dollars) * 100).toFixed(0);
      }

      /**
       * Add multiple deltas
       *
       * @param {PathsAndValues} pathsAndValues, object mapping path to new value
       * @param {Boolean} deleted, whether or not to delete
       */
      function addMultipleDeltas(deltas) {
        return $q(function (resolve, reject) {
          const pathsToPersist = [];

          each(deltas, function (bucket, path) {
            const originalValue = get($scope.clone, path);
            if (bucket.value === originalValue) {
              $scope.editing[path] = false;
              return;
            } else {
              pathsToPersist.push(path);
              if (!bucket.deleted) {
                $counteroffer.removeDelta($scope.deltaArr, path);
              }
              const oldValue: any = get($scope.request, path);

              // dont set newValue if deleted
              const newDelta: any = {
                deleted: !!bucket.deleted,
                oldValue: oldValue,
                path: path,
                user: $scope.user.$.fullName
              };
              if (!bucket.deleted) {
                newDelta.newValue = bucket.value;
              }

              $scope.deltaArr.push(newDelta);
            }
          });

          if (pathsToPersist.length) {
            return persistDelta(pathsToPersist).
              then(resolve).
              catch(function (error) {
                unwrapError(error);
                reject(error);
              });
          } else {
            return resolve();
          }
        });

      }

      /**
       * Add new note to `$scope.counterClone.host.notes`
       * @mutates `$scope.counterClone`
       */
      function addNewNote() {
        $scope.clone.host.notes.push({});
        const path = `host.notes.${$scope.counterClone.host.notes.length}`;
        $scope.creatingNewNote = true;
        $scope.startEdit(path);
      }

      /**
       * Determine if user is editing note
       * @param {Editing} editing map
       */
      function isEditingNote(editing) {
        if (!editing) {
          return;
        }
        const paths = keys(editing);
        return some(paths, p => startsWith(p, 'host.notes') && editing[p]);
      }

      /**
       * Determine if user can save a note
       * @param {Number} $index
       * @param {Note} note, optional
       * @return {Boolean}
       */
      function canSaveNote($index, note) {

        const theNote = note ?
          note :
          get($scope.counterClone, `host.notes.${$index}`);
        return theNote && theNote.subject && theNote.description;
      }

      /**
       * Save note
       *
       * @param {Number} $index
       * @mutates `$scope.counterClone` and `$scope.clone`
       */
      function saveNote($index) {
        const path = `host.notes.${$index}`;
        const note = get($scope.counterClone, path);
        if (!canSaveNote(null, note)) {
          return;
        }

        addDelta(path, note).
          then(() => $scope.creatingNewNote = false).
          catch(unwrapError);
      }

      /**
       * Delete note
       *
       * @param {Number} $index
       * @mutates `$scope.counterClone`
       */

      function deleteNote($index) {
        const path = `host.notes.${$index}`;
        $scope.addDelta(path, null, true).
          then(() => $scope.creatingNewNote = false).
          catch(_handleError);

        function _handleError(error) {
          $scope.creatingNewNote = false;
          unwrapError(error);
        }
      }

      function selectEventType(eventType) {
        if ($scope.counterClone.data.eventType === 'Other') {
          createDelta('data.eventType', $scope.counterClone.data.eventType);
          createDelta('data.customEventType', $scope.counterClone.data.customEventType);
        } else {
          createDelta('data.eventType', $scope.counterClone.data.eventType);
          createDelta('data.customEventType', null);
        }
        return persistDelta(['data.eventType', 'data.customEventType']);
      }

      function createDelta(path: string, value, deleted?: boolean) {
        if (!path) {
          return { persist: false };
        }

        // dont add/apply deltas if the value is the same
        if (get($scope.clone, path) === value) {
          $scope.editing[path] = false;
          return { persist: false };
        }

        // if the value doesnt exist in clone and we are deleting, just cancel out
        if (deleted && !get($scope.clone, path)) {
          $scope.editing[path] = false;
          createClone();
          return { persist: false };
        }

        $scope.loading[path] = true;

        // Do not remove a delta from the array if adding a delete Delta

        if (!deleted) {
          $counteroffer.removeDelta($scope.deltaArr, path);
        }

        const oldVal: any = get($scope.clone, path);

        const deltaObj = {
          deleted: !!deleted,
          oldValue: oldVal,
          path: path,
          user: $scope.user.$.fullName
        };

        if (!deleted) {
          deltaObj.newValue = value;
        }

        // dont set newValue if deleted
        $scope.deltaArr.push(deltaObj);


        return { persist: true };

      }
      /**
       * Set the given path as modified with the given value
       * @param {String} path, required
       * @param {Any} value, required
       * @param {Boolean} deleted, did the user remove this path?, optional
       */
      function addDelta(path: string, value, deleted?: boolean) {
        if (path === 'host.foodBeverageMinCents' && !value) {
          value = 0;
        }
        return $q(function (resolve, reject) {
          const { persist } = createDelta(path, value, deleted);
          if (persist) {
            return persistDelta(path).then(resolve).catch(reject);
          } else {
            return resolve();
          }
        });
      }

      function persistDelta(paths) {
        return $api.Requests.counteroffer($scope.request._id, $scope.deltaArr).
          then(function (response) {
            _resetRequest(get(response, 'data.request'));
            paths = Array.isArray(paths) ? paths : [paths];
            each(paths, function (path) {
              $scope.editing[path] = false;
              $scope.loading[path] = false;
            });
          });
      }

      function shouldHidePayout() {
        const isCancelledPostDeposit = ENUMS.bookingRequestState.cancelledPostDepositStates.includes($scope.clone.state);

        if (!isCancelledPostDeposit) {
          return false;
        }

        if (isCancelledPostDeposit && get($scope.clone, 'admin.payout.amountDollars')) {
          return false;
        }

        if ($scope.user.isAdmin() && $scope.clone.state === 'CONCLUDED') {
          this.applyDeltas();
        }

        return true;
      }

      function calculatePayout() {
        if ($scope.clone.state === 'CLOSED') {
          return $scope.costBreakdownCents.payout;
        }
        return $scope.costBreakdownCents.total - $scope.costBreakdownCents.bookingFee - $scope.costBreakdownCents.cardFee;
      }

      function applyDeltas() {
        if (!$scope.clone) {
          return;
        }

        applyCounter($scope.deltaArr);
        mapDeltas($scope.deltaArr);
      }

      function mapDeltas(deltaArr) {
        each(deltaArr, delta => $scope.delta[delta.path] = delta);
      }

      /**
       * Determines if Edit button shows up next to fields
       *
       * @param {Request}
       * @return {Boolean}
       */
      function canEditRequest(request) {
        return $scope.user.isAdmin() ?
          includes(ENUMS.bookingRequestState.adminCanCounteroffer, $scope.request.state) :
          includes(ENUMS.bookingRequestState.hostCanCounteroffer, $scope.request.state);
      }

      /**
       * Determine if user can see the button to add overages
       *
       * @return {Boolean}
       */
      function canAddOverages() {
        if (!$scope.user) {
          return false;
        }

        return $scope.user.isAdmin() &&
          $scope.request.state === 'CONCLUDED';
      }

      function canAddFinalReceipt() {
        if (!$scope.user) {
          return false;
        }

        return (($scope.user.isAdmin() || $scope.user.isHost()) &&
          $scope.request.state === 'CONCLUDED' || $scope.request.state === 'PAID');
      }

      function matchesTimeslot(request) {
        if (!request.selectedSpace || !request.data.time) {
          return;
        }
        return AvailabilityFactory.matchesTimeslot(request, request.selectedSpace);
      }

      function _resetRequest(request) {
        $scope.request = Object.assign(request, { clients: $scope.request.clients });
        $scope.$emit('REQUEST_CHANGE', { request: $scope.request });
        createClone();
      }

      function needsRefund(request) {
        if (request.state !== 'CONCLUDED') {
          return false;
        }
        return fullPriceBreakdownCents(request).balanceOutstanding < 0;
      }

      function findCustomFee(customFee) {
        const customFees = $scope.counterClone.venue.data.customFees;
        return customFees.find(fee => fee.name === customFee.name);
      }

      function isFbCustomFee(customFee) {
        const fullCustomFee = findCustomFee(customFee);
        return fullCustomFee.feeType === 'percentage' && fullCustomFee.appliesTo.fb;
      }

      function isNonFbCustomFee(customFee) {
        return !isFbCustomFee(customFee);
      }

      function openFeeApplicationModal(request) {
        if (!$scope.user.isAdmin()) {
          return;
        }
        feeApplicationModal(request)
          .then((data) => {
            const updatedRequest = get(data, 'value.request');
            if (updatedRequest) {
              $scope.$emit('REQUEST_CHANGE', { request: updatedRequest });
            }
          });
      }

      ///// Admin Ops

      function openOveragesModal() {
        _checkAdmin();
        OverageFactory.openOveragesModal($scope.user, overagesModal, $scope.clone).
          then(function (data) {
            const update = get(data, 'value.request');
            if (update) {
              _resetRequest(update);
            }
          }).
          catch(unwrapError);
      }

      function openAttachmentModal () {
        attachmentsModal($scope.request).
          then(function(data) {
            if (get(data, 'value.request')) {
              set($scope, 'request.data.payment.attachments', get(data, 'value.request.data.payment.attachments'));
            }
          }).
          catch(unwrapError);
      }

      function openWinelistsModal() {
        return editWinelistsModal($scope.venue);
      }

      function _checkAdmin() {
        if (!$scope.user.isLoggedIn() || !$scope.user.isAdmin()) {
          throw new Error('Not admin');
        }
      }

      function _resetCustomMenu() {
        $scope.menus.forEach((menu) => {
          if (menu.isCustom) {
            menu.data.name = 'Custom Menu';
          }
        });
      }

    function openContractAttachmentsModal() {
      const contractAttachment = this.request.contractAttachments;
      const contractUrlLink = this.request.contractUrl;
      const contractUrl = contractUrlLink ? contractUrlLink : '';
      const contractAttachmentLength = contractAttachment ? contractAttachment.length : 0;
      const prevContractAttachmentUrl = contractAttachmentLength > 0 ? contractAttachment[ contractAttachmentLength - 1 ] .url : '';
      this.request.contractAttachments = get(this.request, 'contractAttachments', []);
      return messageAttachmentsModal({
        request: this.request,
        attachments: this.request.contractAttachments,
      })
      .then((data) => {
        this.request.contractAttachments = get(data, 'value.attachments', this.request.contractAttachments);
        if (this.request.contractAttachments.length) {
            const currentContractAttachmentUrl = this.request.contractAttachments[this.request.contractAttachments.length - 1].url;
            if ( contractAttachmentLength !== this.request.contractAttachments.length  || currentContractAttachmentUrl !== prevContractAttachmentUrl) {
              $scope.request.prevContractAttachmentLength = contractAttachmentLength;
              $api.Requests.updateDirectlyAsHost ( $scope.request );
            }
          }
          else if ( this.request.contractUrl.length > 0 && this.request.contractUrl !== contractUrl ) {
          this.submitContractAttachment();
          }
        }).catch(error => this.unwrapError(error));
    }

    function isDisabled(contractAttachment) {
      if (contractAttachment.length !== 0) {
        return false;
      }
      else {
        return true;
      }
    }
    function setRequestListener() {
      $scope.requestListener = $interval(function () {
        if (!$scope.request) {
          return;
        }
        $api.Requests.checkCounteroffer($scope.request._id).
          then(function (response) {

            const newMessages = get(response, 'data.messages');
            const newAttachments = get(response, 'data.attachments');

            if (get(newMessages, 'length')) {
              $scope.$emit('UPDATE_MESSAGES', { messages: newMessages });
            }

            if (get(newAttachments, 'length')) {
              $scope.$emit('UPDATE_ATTACHMENTS', { attachments: newAttachments });
            }
          }).catch(unwrapError);
      }, REQUEST_LISTENER_INTERVAL);
    }
  }]
  };
};
