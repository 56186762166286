import * as angular from 'angular';
import directives from './directives';
import each from 'lodash/each';

const services = require('./services');

import { SelectTypeComponent } from './components/select-type.component';
import { SelectSpaceComponent } from './components/select-space.component';

const m = angular.module('venue_book', ['photos']);

each(services, function(factory, name) {
  m.factory(name, factory);
});

each(directives, function(directive, name) {
  m.directive(name, directive);
});

m.component('selectSpace', SelectSpaceComponent);
m.component('selectType', SelectTypeComponent);

module.exports = 'venue_book';
