import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import set from 'lodash/set';
import last from 'lodash/last';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import find from 'lodash/find';
import each from 'lodash/each';

import { ApiService } from 'spc/shared/api/api.service';
import moment from 'moment';

import bookingRequestHelpers from 'common/dist/virtuals/BookingRequest';
import availabilityCalendarHelpers from 'common/dist/virtuals/AvailabilityCalendar';
import spaceAvailability from 'common/dist/spaceAvailability';
import iterateHalfHours from 'common/dist/iterateHalfHours';

export const AvailabilityFactory = ['$q', '$api', function($q, $api: ApiService) {
  const ret: any = {};
  ret.selectTime = selectTime;
  ret.checkTimeValidOnUpdate = checkTimeValidOnUpdate;
  ret.getAvailabilityForDate = getAvailabilityForDate;
  ret.findSlot = findSlot;
  ret.findSlotWithStartAndEndTimes = findSlotWithStartAndEndTimes;
  ret.getTimeSlotBlocks = getTimeSlotBlocks;
  ret.getAvailabilityCalendarsForVenue = getAvailabilityCalendarsForVenue;
  ret.findSlotInSpace = findSlotInSpace;
  ret.isAvailableRequest = isAvailableRequest;
  ret.matchesTimeslot = matchesTimeslot;

  return ret;

  /**
   * Makes AJAX call to get all availability
   *
   * @param {Array} spaces
   * @param {Date} date
   * @return {Promise}
   */
  function getAvailabilityForDate(spaces, date) {
    const allSpaces = {};
    date = moment.isMoment(date) ? date : moment (date);
    date = date.toISOString().substr(0, 10);
    each(spaces, function(space) {
      const id = space._id.toString();
      allSpaces[id] = $api.Scheduler.getAvailability(id, date);
    });

    return $q.all(allSpaces);
  }

  function getAvailabilityCalendarsForVenue(venue) {
    const venueId = venue._id ? venue._id.toString() : venue;
    return $api.Venues.getCalendars({ venueId })
      .then(({ calendars }) => calendars);
  }
  /**
   * Set the time for a request while making sure f&b min doesn't change
   *
   * @param {Request} request
   * @param {Number} time
   * @param {Slot} slot
   * @mutates `request`
   */
  function selectTime(request, time, slot) {
    set(request, 'data.time', time);
    const priceOverrideDollars = get(slot, 'terms.foodBeverageMin');

    if (isNumber(priceOverrideDollars)) {
      const priceOverrideCents = priceOverrideDollars * 100;
      set(request, 'host.foodBeverageMinCents', priceOverrideCents);
    }
  }

  /**
   * Check if time is within the acceptable time range
   *
   * @param {Request} request
   * @param {CurrentAvailability} currentAvailability
   * @param {Number} time
   * @return {Boolean}
   */
  function checkTimeValidOnUpdate(request, currentAvailability, time) {
    return !get(currentAvailability, 'times.length') ?
      true :
      time >= currentAvailability.times[0] &&
        time + bookingRequestHelpers.getEndTime(request) <= last(currentAvailability.times);
  }


  /**
   * Find slot in a space
   *
   * @param {Request} request
   * @param {Array} slots
   * @param {Number} time
   * @return {Slot}
   */
  function findSlot(request, slots, time) {
    return find(slots, function(slot) {
      const duration = (get(request, 'data.duration', 60) / 60) * 100;
      const slotTo = slot.to < slot.from ? slot.to + 2400 : slot.to;
      return slotTo >= time + duration &&
        slot.from <= time;
    });
  }

  function findSlotWithStartAndEndTimes ({ slots, startTime, endTime }) {
    return find(slots, (slot: any) => {
      const slotTo = slot.to < slot.from ? slot.to + 2400 : slot.to;
      return slotTo >= endTime &&
        slot.from <= startTime;
    });
  }

  function findSlotInSpace({ space, time, duration }) {
    const slots = get<any[]>(space, 'data.timeSlots', []);
    return find(slots, function(slot) {
      const slotTo = slot.to < slot.from ? slot.to + 2400 : slot.to;
      return (slotTo >= (time + duration)) && (slot.from <= time);
    });
  }

  function matchesTimeslot (request, space) {
    const timeSlots = space.data.timeSlots.filter(slot => slot.days[moment.utc(request.data.date, 'YYYY-MM-DD').day()]);
    return !!findSlot(request, timeSlots, request.data.time);
  }

  /**
   * Takes a slot and returns an array of times
   *
   * @param {Slot} slot
   * @return {Array}
   */
  function getTimeSlotBlocks(slot) {
    const start = slot.from;
    let end = slot.to;
    if (start > end) {
      end += 2400;
    }

    return iterateHalfHours(start, end);
  }

  function markTimeSlotsUnavailable ({ calendar, timeSlots, date }) {
    return timeSlots.map((timeslot) => {
      const times = { startTime: timeslot.from, endTime: timeslot.to };
      const isAvailable = availabilityCalendarHelpers.isAvailable({ calendar, date, times });
      if (!isAvailable) {
        timeslot.isBlocked = true;
      }
      return timeslot;
    });
  }

  function markSpaceAvailabilitiesUnavailable ({ calendars, spaces, date, allAvailabilities }) {
    spaces.forEach((space) => {
      const spaceId = space._id.toString();
      const strDate = date.utc().format('YYYY-MM-DD');
      const availability = allAvailabilities[spaceId].data.availability;
      const calendar = calendars.find(_calendar => _calendar.space.toString() === spaceId);
      allAvailabilities[spaceId].data.availability.timeSlots = markTimeSlotsUnavailable({ calendar, timeSlots: availability.timeSlots, date });
    });
    return allAvailabilities;
  }

  function isAvailableRequest({ request, calendars }) {
    if (!request.selectedSpace) {
      return;
    }
    const selectedSpaceId = request.selectedSpace._id.toString();

    const calendar = calendars.find(_calendar => _calendar.space.toString() === selectedSpaceId);

    if (!calendar) {
      return;
    }

    const startTime = request.data.time;
    const duration = request.data.duration;
    const endTime = bookingRequestHelpers.getEndTime(request);

    return availabilityCalendarHelpers.isAvailable({
      calendar,
      date: request.data.date,
      times: {
        startTime,
        endTime
      }
    });
  }
}];
