import * as angular from 'angular';
import { AdminUsersDashboardComponent } from './admin-users-dashboard.component';
import { TopLevelAdminUsersComponent } from './top-level-users.component';
import { AdminUsersSidebarComponent } from './admin-users-sidebar.component';
import { AdminUsersService } from './admin-users.service';
import { EditUserComponent } from './edit-user.component';
import { DownloadUsersComponent } from './download-users.component';
import CreateBaseUssrModal from './createBaseUser/create-base-user.modal';
import { CreateBaseUserComponent } from './createBaseUser/create-base-user.component';

export default angular.module('admin.users', [])
  .component('topLevelAdminUsers', TopLevelAdminUsersComponent)
  .component('adminUserDashboard', AdminUsersDashboardComponent)
  .component('adminUsersSidebar', AdminUsersSidebarComponent)
  .component('editUser', EditUserComponent)
  .component('downloadUsers', DownloadUsersComponent)
  .component('createBaseUser', CreateBaseUserComponent)
  .service('adminUsersService', AdminUsersService)
  .service('createBaseUserModal', CreateBaseUssrModal)
  .name;
