import { ApiService } from 'spc/shared/api/api.service';
import { VenueNote } from 'server/api/admin/venuenotes/models';
import cloneDeep = require('lodash/cloneDeep');
import { ToastService } from 'spc/shared/toast.service';
import { SpDownloadService } from '../sp-download.service';
import { RawReview } from 'spc/lib/database/types/review';

class VenueNotesController {
  venue: any;
  venueNote: VenueNote;
  venueHistory: any;
  reviews: RawReview[];

  ui: {
    view: 'history' | 'notes' | 'reviews';
    editing: boolean,
    newNote?: boolean
    isLoading: boolean;
  } = {
    editing: false,
    view: 'notes',
    isLoading: true
  };
  editedNote: { title: string, message: string, isVisible: boolean };

  constructor(
    private $api: ApiService,
    private unwrapError,
    private toast: ToastService,
    private SpDownloadService: SpDownloadService
  ) {
    'ngInject';
  }

  $onChanges = (data: { venue?: any }) => {
    if (data.venue && this.venue) {
      this.getNote();
    }
  }

  getNote = () => {
    this.ui.isLoading = true;
    this.$api.Admin.VenueNotes.get(this.venue)
      .then(response => this.setNote(response))
      .catch(error => this.unwrapError(error));
  }

  setNote = ({ venueNote, venueHistory, reviews }: { venueNote: any, venueHistory?: any, reviews?: RawReview[] }) => {
    this.venueNote = venueNote;
    if (venueHistory) {
      this.venueHistory = venueHistory;
    }
    if (reviews) {
      this.reviews = reviews;
    }
    this.venueNote.notes.reverse();
    this.ui.isLoading = false;
  }
  editNote = (note) => {
    this.ui.editing = true;
    this.editedNote = cloneDeep(note);
  }

  newNote = () => {
    const baseNote = { title: '', message: '', isVisible: true, new: true  };
    this.ui.newNote = true;
    this.editNote(baseNote);
  }

  deleteNote = () => {
    this.editedNote.isVisible = false;
    this.saveNote();
  }

  saveNote = () => {
    this.$api.Admin.VenueNotes.update({ venue: this.venue, note: this.editedNote })
      .then(({ venueNote }) => {
        this.toast.goodNews('Done.', 'The note was updated successfully.');
        this.setNote({ venueNote });
        this.ui.editing = false;
        this.ui.newNote = false;
      })
      .catch((error) => {
        this.toast.badNews('Note saving failure', error.message);
        this.unwrapError(error);
      });
  }

  cancelEdit = () => {
    this.ui.editing = false;
    this.editedNote = null;
  }

  changeView = (view: 'notes' | 'history') => {
    this.cancelEdit();
    this.ui.view = view;
  }

  getEventSource = (event) => {
    if (event.source === 'Booking Request') {
      return 'Request';
    } else if (event.source === 'Recommendation') {
      return 'Reco';
    } else {
      return event.source;
    }
  }

  downloadVenueRecoData = (venue) => {
    return this.$api.Admin.Venues.getVenueRecoData(venue._id)
      .then((response) => {
        this.SpDownloadService.exportCSVFile({ data: response.data, fileTitle: venue.data.name });
      });
  }
}

export const VenueNotesComponent = {
  controller: VenueNotesController,
  template: require('./venuenotes.component.jade'),
  bindings: {
    venue: '<',
    allowEditing: '<'
  }
};
