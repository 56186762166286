class TopLevelController {
 constructor(private $rootScope, private $seo) {
   'ngInject';
 }

 componentIsReady = () => {
  this.$rootScope.$emit('$viewReady', 'SHOW_FOOTER');
 }
}

export const topLevelVirtualExperienceComponent = {
  template: require('./top-level-virtual-experience.component.jade'),
  controller: TopLevelController
};
