import * as angular from 'angular';

import { VirtualEventsRequirementsComponent } from './additionalRequirements/virtual-events-additional-requirements.component';
import { VirtualEventsAddOnsComponent } from './addOns/virtual-events-addOns.component';
import { VirtualEventsHostsComponent } from './hosts/virtual-events-hosts.component';
import { VirtualEventsItemsComponent } from './items/virtual-events-items.component';
import { VirtualEventsOtherFeesComponent } from './otherCosts/virtual-events-otherCosts.component';
import { ExperienceReviewComponent } from './reviews/experience-review.component';
import { VirtualEventsShippingDetailsComponent } from './shippingDetails/virtual-events-shippingDetails.component';
import { VirtualEventsConditionComponent } from './terms-and-condition/virtual-events-terms-and-condition.component';
import { TogglePublishedComponent } from './togglePublished.ts/toggle-published.component';

export const VirtualEventsModule = angular.module('VirtualEventsModule', [])
  .component('virtualEventsItems', VirtualEventsItemsComponent)
  .component('virtualEventsRequirements', VirtualEventsRequirementsComponent)
  .component('virtualEventsConditions', VirtualEventsConditionComponent)
  .component('virtualEventsHosts', VirtualEventsHostsComponent)
  .component('virtualEventsAddons', VirtualEventsAddOnsComponent)
  .component('virtualEventsOtherFees', VirtualEventsOtherFeesComponent)
  .component('virtualEventsShippingDetails', VirtualEventsShippingDetailsComponent)
  .component('togglePublished', TogglePublishedComponent)
  .component('experienceReview', ExperienceReviewComponent)
  .name;
