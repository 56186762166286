module.exports = function() {
  return {
    template: require('./request-status-marker.jade'),
    scope: {
      request: '='
    },
    controller: ['$scope', function($scope) {

    }]
  };
};
