export const proposalContactsModal = ['ngDialog', function(ngDialog) {
  return ({ request, conversation, venue, sixplusContacts, previewUser }) => {
    return ngDialog.open({
      template: `
      <proposal-contacts
        request="$ctrl.request"
        conversation="$ctrl.conversation"
        venue="$ctrl.venue"
        sixplus-contacts="$ctrl.sixplusContacts"
        preview-user="$ctrl.previewUser"
        close="$ctrl.close">
      </proposal-contacts>`,
      className: 'ngdialog-theme-small p-contacts',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function($scope) {
        this.request = request;
        this.conversation = conversation;
        this.venue = venue;
        this.sixplusContacts = sixplusContacts;
        this.previewUser = previewUser;
        this.close = function() {
          $scope.closeThisDialog();
        };
      }]
    });
  };
}];
