import { ApiService } from 'spc/shared/api/api.service';

export const venueAttachmentModal = ['ngDialog', function (ngDialog) {
    return function (reco) {
      return ngDialog.open({
        template: require('./venue-attachment-modal.jade'),
        className: 'ngdialog-theme-plain',
        overlay: false,
        plain: true,
        controllerAs: '$ctrl',
        controller: ['$scope', '$cloudinary', '$api', 'unwrapError', '$timeout', function ($scope, $cloudinary, $api: ApiService, unwrapError, $timeout) {

          // Initialization function
          this.init = () => {
            this.recoId = reco._id;
            this.attachments = reco.attachments || [];
            this.buttonText = 'I\'m done';
            this.$cloudinary = $cloudinary;
            this.setCanSubmit();
          };

          // Listeners
          $scope.$on('FILE_UPLOADING', () => {
            this.buttonText = 'Uploading...';
          });

          $scope.$on('FILE_UPLOADED', () => {
            this.buttonText = `I'm finished`;
            this.setCanSubmit();
          });

          this.handleUpload = (url: string, title: string) => {
            const attachment = {
                url,
                title
            };
            this.attachments.push(attachment);
          };

          this.setCanSubmit = () => {
            this.canSubmit =  this.attachments.every(attachment => attachment.title);
          };

          this.removeAttachment = (removedAttachment) => {
            this.attachments = this.attachments.filter(attachment => attachment.url !== removedAttachment.url);
          };

          this.finish = () => {
            if (this.canSubmit) {
              const attachments = this.attachments;
              return $api.Recos.updateAttachments({ recoId: this.recoId, attachments })
                .then(data => $scope.closeThisDialog({ attachments: data.reco.attachments }))
                .catch(error => unwrapError(error));
            } else {
              return Promise.resolve();
            }
          };

          this.init();
        }]
      }).closePromise;
    };
  }];
