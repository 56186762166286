
/**
 * Toggle fade-in/fade-out
 *
 * @property {String} ngFade fade-in when expression changes to truthy
 * @property {Number} delayFade
 */

export const ngFade = function () {
  return function (scope, element, attrs) {
    $(element).hide();
    var show = false;
    scope.$watch(attrs.ngFade, function (v) {
      if (v && !show) {
        show = true;
        setTimeout(function () {
          $(element).fadeIn().removeClass('test-hidden');
        }, parseInt(attrs.delayFade || 0, 10));

      } else if (!v && show) {
        show = false;
        setTimeout(function () {
          $(element).fadeOut().addClass('test-hidden');
        }, parseInt(attrs.delayFade || 0, 10));
      }
    });
  }
};