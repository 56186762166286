import match from '../../../utils/match';
import { get } from 'lodash';
import JSONCrush from '../../../../../database/helpers/JSONCrush';
import moment from 'moment';
import { ApiService } from 'spc/shared/api/api.service';

let ctrl;
class VenueSearchControlComponent {
  constructor($rootScope, $routeParams, $location, private $api: ApiService, private unwrapError, $seo, ENUMS, $injector, $timeout, $user, VenueSearchService) {
    'ngInject';
    ctrl = this;
    ctrl.$seo = $seo;
    ctrl.$analytics = $injector.get('$analytics');
    ctrl.ENUMS = ENUMS;
    ctrl.$routeParams = $routeParams;
    ctrl.$rootScope = $rootScope;
    ctrl.$timeout = $timeout;
    ctrl.$user = $user;
    ctrl.$location = $location;
    ctrl.VenueSearchService = VenueSearchService;
  }

  $onInit() {

    const searchCity = ctrl.$routeParams.name;
    const title = `Search for Event Spaces in ${searchCity}`;
    const description = ctrl.ENUMS.metaDescriptions.search(searchCity);
    ctrl.$seo.set(title, description);

    const eventName = get(ctrl.ENUMS, 'analyticsEvents.visited.custom.search');
    const eventParams = { searchCity };
    const eventAction = 'Search';
    // $trackEvent will auto ignore admins
    ctrl.$analytics.$trackEvent(eventName, eventParams, eventAction);

    ctrl.matchRouteToCity(searchCity)
      .then((location) => {
        ctrl.location = location;
        const page = ctrl.$location.search().page;
        ctrl.search = ctrl.VenueSearchService.activate(location, page);

        let filters;
        if (ctrl.$routeParams.filters) {
          filters = JSON.parse(JSONCrush.uncrush(ctrl.$routeParams.filters)).filters;
        }

        ctrl.$routeParams.date ? ctrl.search.setDate(moment(ctrl.$routeParams.date)) : null;

        ctrl.search.filters.guests.max = get(filters, 'guests') ? parseInt(filters.guests.max, 10) : null;

        ctrl.$user.$waitFor('LOGGED_IN', () => {
          ctrl.isAdmin = ctrl.$user.isAdmin();
          ctrl.showPage();
        });
        ctrl.$user.$waitFor('NOT_LOGGED_IN', () => {
          ctrl.isAdmin = false;
          ctrl.showPage();
        });
      })
      .catch(error => this.unwrapError(error));
  }

  // Public Functions

  // Private Functions

  /**
   * Shows page and unloads spinner, also hides footer
   *
   * @private
   * @return {Void}
   */
  showPage() {
    ctrl.$timeout(() => {
      ctrl.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
    });
  }

  disableViewTransition() {
    ctrl.$rootScope.disableViewTransition();
  }

  removeFormatting(str: String): string {
    const cleanUrlRegex = /[\s\-.(%20)]*/gm;
    return str.replace(cleanUrlRegex, '').toLowerCase();
  }

  /**
   * Matches the location in the route to a given city, or sends user to `/404`
   *
   * @private
   * @param {String} city
   * @return {Object}
   */
  matchRouteToCity(city) {
    return this.$api.Locations.get()
      .then((res) => {
        const locations = res.locations;
        const location = locations.find(_location => _location.customUrl ? _location.customUrl === city : this.removeFormatting(_location.city) === this.removeFormatting(city));

        if (!location) {
          ctrl.$location.path('/404');
        }

        return {
            searchLocation: `${location.city},${location.state},${location.country}`,
            display: location.customUrl ? location.customUrl : location.city
          };
      })
      .catch(error => this.unwrapError(error));
  }
}

export default {
  template: `
    <venue-search
      ng-if="$ctrl.search"
      is-admin="$ctrl.isAdmin"
      disable-view-transition="$ctrl.disableViewTransition()"
      search="$ctrl.search">
    </venue-search>
  `,
  bindToController: true,
  controller: VenueSearchControlComponent
};
