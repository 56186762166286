
export default ['ngDialog', function(ngDialog) {
  return function(parentScope, data) {
    return ngDialog.open({
      template: require('./save-confirmation-modal.jade'),
      className: 'ngdialog-theme-plain',
      overlay: false,
      plain: true,
      controller: ['$scope', '$rootScope', '$venueValidator', 'unwrapError', function($scope, $rootScope, $venueValidator, unwrapError) {
       $scope.selectedVenue = data.venue;

       $venueValidator.validate(
         $scope.selectedVenue,
         $scope.selectedVenue.data.spaces,
         data.menus,
         data.drinks
       ).then(res => $scope.validation = res)
        .catch(error => unwrapError(error));

       parentScope.$on('$locationChangeStart', function() {
         $scope.closeThisDialog();
       });
      }]
    }).closePromise;
  };
}];
