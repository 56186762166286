import * as angular from 'angular';
import { adminCreateEditExperienceDashboardComponent } from './admin-create-edit-experience.component';
import { topLevelAdminCreateEditExperienceComponent } from './top-level-admin-create-edit-experience.component';
import { organiseModal } from '../virtual-events/organiseModal/organise.modal';
import { OrganiseModalComponent } from '../virtual-events/organiseModal/organise-modal.component';

export const AdminCreateEditExperienceModule = angular.module('adminCreateEditExperienceModule', [])
  .component('topLevelAdminCreateEditExperience', topLevelAdminCreateEditExperienceComponent )
  .component('adminCreateEditExperienceDashboard', adminCreateEditExperienceDashboardComponent )
  .component('organiseModalComponent', OrganiseModalComponent)
  .service('organiseModal', organiseModal)
  .name;
