import { RawBookingRequest } from 'spc/lib/database/types/booking-request';
import { RawLead } from 'spc/lib/database/types/lead';
import { RawLeadClient } from 'spc/lib/database/types/lead-client';
import { RawConversation } from 'spc/lib/database/types/conversation';

const MANAGE_ALL = 'Manage All';
const MANAGE_INDIVIDUAL = 'Manage Individual';

class ManageCollaboratorsController {
  requests: RawBookingRequest[];
  lead: RawLead;
  view: 'Manage All' | 'Manage Individual';
  selectedUser: string;
  clients: RawLeadClient[];
  update: ({ updatedLeadClients, updatedProposals }: { updatedLeadClients: RawLeadClient[], updatedProposals: RawBookingRequest[] }) =>  void;

  constructor(private $api, private unwrapError) {
    'ngInject';
  }

  $onInit() {
    this.view = MANAGE_ALL;
    this.clients = this.setClients(this.lead.clients);
  }

  setClients(clients) {
    return clients.filter(client => !client.isPrimary);
  }

  setSelectedUser(user) {
    this.selectedUser = user._id.toString();
    this.view = MANAGE_INDIVIDUAL;
  }

  removeUser(user) {
    this.$api.Leads.removeCollaborator({ lead: this.lead, userId: user._id.toString() })
    .then((res) => {
      this.lead.clients = res.leadClients;
      this.clients = this.setClients(res.leadClients);
      this.requests = this.requests.map((request) => {
        const currentRequest = request._id.toString();
        const updatedRequest = res.proposals.find(proposal => proposal._id.toString() === currentRequest);
        if (updatedRequest) {
          return updatedRequest;
        }
        return request;
      });
      this.update({ updatedLeadClients: this.clients, updatedProposals: this.requests });
    })
    .catch(error => this.unwrapError(error));
  }

  closeIndividual({ updatedLeadClients, updatedProposals }) {
    if (updatedLeadClients) {
      this.clients = this.setClients(updatedLeadClients);
    }
    if (updatedProposals) {
      this.requests = updatedProposals;
    }
    this.update({ updatedLeadClients: this.clients, updatedProposals: this.requests });
    this.view = MANAGE_ALL;
  }

}

export const ManageCollaboratorsComponent = {
  template: require('./manage-collaborators.component.jade'),
  controller: ManageCollaboratorsController,
  bindings: {
    close: '&',
    requests: '=',
    lead: '=',
    userId: '<',
    update: '&'
  }
};
