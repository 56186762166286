interface Time {
  _hour: number;
  _minutes: number;
  _amPm: string;
}

export class LeadSidebarService {

  setTime = ({ _hour, _minutes, _amPm }: Time): number => {
    let hours = _hour;
    if (_amPm === 'AM' && hours === 12) {
      hours = 0;
    } else if (_amPm === 'PM' && hours === 12) {
      hours = hours;
    } else if ( _amPm === 'PM' ) {
      hours = hours + 12;
    }
    return hours * 100 + _minutes;
  }

  mapTime = (time: number): Time => {
    let _amPm = null;
    let _hour = null;
    let _minutes = null;

    const hour = Math.floor(time / 100);
    if (hour >= 13) {
      _amPm = `PM`;
      _hour = hour - 12;
    } else if (hour === 12) {
      _hour = hour;
      _amPm = `PM`;
    } else {
      _amPm = `AM`;
      _hour = hour;
    }
    _minutes = time % 100;
    return { _amPm, _hour, _minutes };
  }
}
