import * as angular from 'angular';
import { TopLevelLeadMetricsComponent } from './lead-metrics/top-level-lead-metrics.component';
import { MetricsTableComponent } from './lead-metrics/metrics-table.component';
import { LeadMetricsTableComponent } from './lead-metrics/lead-metrics-table.component';
import { ConciergeMetricsDashboardComponent } from './lead-metrics/concierge-metrics-dashboard.component';
import { ProposalMetricsDashboardComponent } from './proposal-metrics/proposal-metrics-dashboard.component';
import { ProposalMetricsChartComponent } from './proposal-metrics/proposal-metrics-chart.component';
import { ProposalMetricsBreakdownPanelComponent } from './proposal-metrics/proposal-metrics-breakdown-panel.component';
import { ProposalMetricsService } from './proposal-metrics/proposal-metrics.service';
import { ProposalMetricsSearchService } from './proposal-metrics/proposal-metrics-search.service';
import { ProposalMetricsDataService } from './proposal-metrics/proposal-metrics-data.service';

export const MetricsDashboardModule = angular.module('MetricsDashboardModule', [])
  .component('leadMetrics', TopLevelLeadMetricsComponent)
  .component('conciergeMetricsDashboard', ConciergeMetricsDashboardComponent)
  .component('metricsTable', MetricsTableComponent)
  .component('leadMetricsTable', LeadMetricsTableComponent)
  .component('proposalMetrics', ProposalMetricsDashboardComponent)
  .component('proposalMetricsChart', ProposalMetricsChartComponent)
  .component('proposalMetricsBreakdownPanel', ProposalMetricsBreakdownPanelComponent)
  .service('proposalMetricsService', ProposalMetricsService)
  .service('proposalMetricsSearchService', ProposalMetricsSearchService)
  .service('proposalMetricsDataService', ProposalMetricsDataService)
  .name;
