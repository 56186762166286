import { cloneDeep, debounce, get, filter, every, isEqual } from 'lodash';
import { RawPhoto } from 'spc/lib/database/types/photo';
import { RawExperience, Review } from 'spc/lib/database/types/virtual-events';
import { ApiService } from '../../../shared/api/api.service';

const newReview: Review = {
  firstName: '',
  lastName: '',
  title: '',
  description: '',
  company: '',
};

class ReviewsController {
  currentReviewIndex: number;
  experience: RawExperience;
  review: Review = cloneDeep(newReview);
  reviews: Review[] = [];
  isFormDisplayed: boolean = false;
  errors: {
    [key: string]: {
      message: string
    }
  } | null = {};
  isReviewEdit: boolean = false;
  photos: RawPhoto[] = [];

  constructor(private unwrapError, private $api: ApiService, private $cloudinary) {
    'ngInject';
  }

  $onInit = () => {
    if (this.experience) {
     if (this.experience.reviews && this.experience.reviews.length) {
       this.reviews = cloneDeep(this.experience.reviews);
     }
    }
  }

  validateCondition = (path) => {
    const currentItemPath = `reviews.${this.currentReviewIndex}.${path}`;
    this.$api.Admin.Experience.validate({
      experience: { reviews: this.reviews },
      vendorId: this.experience.vendor
    })
      .then((response) => {
        const error = get(response, 'errors.experience');
        if (error) {
          const pathErrors = get(error, currentItemPath);
          this.errors[currentItemPath] = pathErrors
            ? pathErrors.message
            : null;
          return error;
        } else {
          this.errors = {};
        }
      })
      .catch((error) => {
          this.unwrapError(error);
      });
  }

  checkForErrors = debounce(this.validateCondition, 300);

  createNewReview = () => {
    this.displayForm(true);
    const length = this.reviews.push(this.review);
    this.currentReviewIndex = length - 1;
  }

  displayForm(value) {
    this.isFormDisplayed = value;
  }

  resetReview = () => {
    this.review = cloneDeep(newReview);
  }

  cancel() {
    if (!this.isReviewEdit) {
      this.reviews = filter(
        this.reviews,
        (_, index) => index !== this.currentReviewIndex
      );
    } else {
        this.reviews[this.currentReviewIndex] = cloneDeep(
        this.experience.reviews[this.currentReviewIndex]
      );
    }
    this.isReviewEdit = false;
    this.resetReview();
    this.displayForm(false);
  }

  isFieldInvalid = (path) => {
    const fieldPath = `reviews.${this.currentReviewIndex}.${path}`;
    return this.errors[fieldPath] ? true : false;
  }

  getErrorMessage = (path) => {
    const fieldPath = `reviews.${this.currentReviewIndex}.${path}`;
    return this.errors[fieldPath];
  }


  submit = () => {
    this.updateExperience();
  }

  updateUi = () => {
    this.experience.reviews = cloneDeep(this.reviews);
    this.displayForm(false);
    this.resetReview();
    this.isReviewEdit = false;
  }

  updateExperience = () => {
    const dateValue = new Date;
    this.review.createdAt = dateValue.toISOString();
    const updates = { reviews: this.reviews };
    this.$api.Admin.Experience.updateExperienceById({
      updates,
      experienceId: this.experience['id'],
    })
      .then((response) => {
        this.reviews = response.experience.reviews;
        this.updateUi();
        this.experience.status = response.experience.status;
      })
       .catch((err) => {
        this.unwrapError(err);
      });
  }

  checkIfFormDataInvalid = () => {
    const errorsPath = Object.keys(this.errors);
    let result = null;
    errorsPath.forEach((path) => {
      result = this.errors[path] || result;
    });
    return result;
  }

  hasAllTheRequiredFields = () => {
    const requiredFields = [
      get(this.review, 'firstName'),
      get(this.review, 'lastName'),
      get(this.review, 'title'),
      get(this.review, 'description'),
      get(this.review, 'company'),
    ];
    return every(requiredFields);
  }

  edit = (index) => {
    this.review = this.reviews[index];
    this.displayForm(true);
    this.currentReviewIndex = index;
    this.isReviewEdit = true;
  }

  delete = (index) => {
    const reviewToDelete = this.reviews[index];
    if (isEqual(this.review, reviewToDelete)) {
      this.review = cloneDeep(newReview);
      this.displayForm(false);
    }
    this.reviews = filter(
      this.reviews,
      (_, i) => i !== index
    );
    this.updateExperience();
  }

}

export const ExperienceReviewComponent = {
  controller: ReviewsController,
  template: require('./experience-review.component.jade'),
  bindings: {
    experience: '<',
  },
};
