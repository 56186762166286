import { Metrics } from './metrics';
import { Venues } from './venues';
import { Requests } from './requests';
import { Conversations } from './conversations';
import { Leads } from './leads';
import { Users } from './users';
import { ClientNotes } from './clientnotes';
import { VenueNotes } from './venuenotes';
import { AvailabilityCalendar } from 'spc/shared/api/admin/availability-calendar';
import { FeaturedVenues } from './featured-venues';
import { CompaniesRouter } from './companies';
import { Locations } from './locations';
import { Vendors } from './vendors';
import { Experience } from './experience';
import { Account } from './accounts';
import { Recommendation } from './recommendation';

export function Admin(API_ROOT: string, $http: ng.IHttpService, $jwt) {
  return {
    AvailabilityCalendar: new AvailabilityCalendar(API_ROOT, $http),
    ClientNotes: new ClientNotes(API_ROOT, $http),
    Companies: new CompaniesRouter(API_ROOT, $http),
    Conversations: new Conversations(API_ROOT, $http),
    FeaturedVenues: new FeaturedVenues(API_ROOT, $http),
    Leads: new Leads(API_ROOT, $http),
    Locations: new Locations(API_ROOT, $http),
    Metrics: new Metrics(API_ROOT, $http),
    Payment: require('./payment')(API_ROOT, $http),
    Requests: new Requests(API_ROOT, $http, $jwt),
    Search: require('./search')(API_ROOT, $http),
    Users: new Users(API_ROOT, $http),
    Venues: new Venues(API_ROOT, $http),
    VenueNotes: new VenueNotes(API_ROOT, $http),
    Vendors : new Vendors(API_ROOT, $http),
    Experience: new Experience(API_ROOT, $http),
    Account: new Account(API_ROOT, $http),
    Recommendation: new Recommendation(API_ROOT, $http)
  };
}
