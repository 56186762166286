module.exports = function(API_ROOT, $http) {
  const ret = {};

  ret.allUsers = function(text: string) {
    return $http.get(API_ROOT + '/admin/search/users', { params: { text } });
  };

  ret.searchUsername = function(text: string) {
    return $http.get(API_ROOT + '/admin/search/username', { params: { text } });
  };

  ret.allBaseUsers = function(text: string) {
    return $http.get(`${API_ROOT}/admin/search/users`, { params: { text, baseUser: true } });
  };

  ret.emails = function(email: string) {
    return $http.get(`${API_ROOT}/admin/search/emails`, { params: { email } })
      .then(response => response.data);
  };

  ret.locations = function(collection: string) {
    const url = `${API_ROOT}/admin/search/locations/${collection}`;
    return $http.get(url)
      .then(response => response.data);
  };

  return ret;
};
