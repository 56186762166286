import { AdminUsersService } from './admin-users.service';
import { ApiService } from 'spc/shared/api/api.service';

import debounce from 'lodash/debounce';
import { ToastService } from '../../shared/toast.service';
import { DBaseUser } from '../../../../database/types/base-user';
import moment from 'moment';


class AdminUsersSidebarController {
  toggleSidebar: () => any;
  tab: 'User' | 'Venue';
  ui: { addingVenues: boolean } = { addingVenues: false };
  user: any;
  venues: any[];
  userVenues: any[];
  searchVenues: any[];
  conciergeTeam: DBaseUser[];
  lastSession: {
    fullStoryUrl: string;
    createdTime: moment.Moment;
  } = {
    fullStoryUrl: '',
    createdTime: null
  };

  constructor(
    private adminUsersService: AdminUsersService,
    private $api: ApiService,
    private unwrapError,
    private toast: ToastService) {
    'ngInject';
  }

  $onChanges = (changes) => {
    this.tab = 'User';
    if (changes.user) {
      this.handleUserChange();
      this.lastActivity();
    }
  }

  lastActivity = () => {
    const user = this.user._id;
    return this.$api.Admin.Users.getlastSession({ user }).then(( res ) => {
      if (res) {
        const { FsUrl, CreatedTime } = res;
        this.lastSession = {
          fullStoryUrl: FsUrl,
          createdTime: moment.unix(CreatedTime)
        };
      } else {
        this.lastSession.fullStoryUrl = '';
      }
    }).catch(error => this.unwrapError(error));
  }

  updateVenue = (venue) => {
    return this.$api.Venues.save({ authorizedUsers: venue.authorizedUsers, slug: venue.slug })
      .then(() => this.toast.goodNews('Success', 'The venue was updated successfully.'))
      .catch((error) => {
        this.toast.badNews('Uh oh!', 'Something went wrong');
        this.unwrapError(error);
      });
  }

  debouncedUpdateVenue = debounce(this.updateVenue, 500);

  venueSearch = (venueName) => {
    if (!venueName || venueName.length < 3) {
      return;
    }
    return this.$api.Admin.Venues.findManyByName(venueName, { skipVisibility: true })
      .then((data: any) => {
        this.searchVenues = data.data.venues.filter(venue => !this.adminUsersService.userExistsInVenue({ user: this.user, venue }));
      })
      .catch(error => this.unwrapError(error));
  }

  debouncedVenueSearch = debounce(this.venueSearch, 500);

  addUserToVenue = (venue) => {
    const userExistsInVenue = this.adminUsersService.userExistsInVenue({ user: this.user, venue });
    if (userExistsInVenue) {
      return;
    }
    venue.authorizedUsers.push({ user: this.user._id });
    return this.updateVenue(venue)
      .then(() => {
        this.userVenues.push(venue);
        this.searchVenues = null;
        this.ui.addingVenues = false;
      })
      .catch(error => this.unwrapError(error));
  }

  cancelAddingVenues = () => {
    this.ui.addingVenues = false;
    this.searchVenues = null;
  }

  handleUserChange = () => {
    this.userVenues = this.adminUsersService.getAssociatedVenues({ user: this.user, venues: this.venues });
  }

  removeVenueUser = (venue) => {
    venue.authorizedUsers = venue.authorizedUsers.filter(authUser => authUser.user.toString() !== this.user._id.toString());
    return this.updateVenue(venue)
      .then(() => {
        this.userVenues = this.userVenues.filter(userVenue => userVenue._id.toString() !== venue._id.toString());
      })
      .catch(error => this.unwrapError(error));
  }
}

export const AdminUsersSidebarComponent = {
  template: require('./admin-users-sidebar.component.jade'),
  controller: AdminUsersSidebarController,
  bindings: {
    toggleSidebar: '&',
    user: '<',
    venues: '<',
    conciergeTeam: '<',
  }
};
