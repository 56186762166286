import * as angular from 'angular';

import { each } from 'lodash';
import factories from './factories';
import constants from './constants';
import modals from './modals';

// Migrated
import { MessagesModule } from './messages';
import { TopLevelRequestPreviewComponent } from './top-level-request-preview.component';
import { RequestPreviewComponent } from './request-preview.component';
import { RequestClientContactDetailsComponent } from './contact-details';
import { RequestAttachmentsComponent } from './request-attachments/request-attachments.component';
import { components } from './components';
import { AddClientsComponent } from './add-clients/add-clients.component';
import { CustomMenuComponent } from './custom-menu/custom-menu.component';
import { allMenusComponent } from './modals/menus-modal/all-menus.component';
import { EditMenusComponent } from './modals/edit-menus-modal/edit-menus.component';
import { allSpacesComponent } from './modals/spaces-modal/all-spaces.component';
import { drinkPackagesComponent } from './modals/drink-packages-modal/drink-packages.component';
import quillEditor from './components/quill-editor.component';
import { adminPaymentComponent } from './modals/admin-payment-modal/admin-payment.component';
import { ProposalContactsComponent } from './modals/proposal-contacts-modal/proposal-contacts.component';
import { RequestCancelComponent } from './modals/request-cancel-modal/request-cancel.component';

const directives = require('./directives');

const m = angular
  .module('requests', [MessagesModule])
  .component('topLevelRequestPreview', TopLevelRequestPreviewComponent)
  .component('requestPreview', RequestPreviewComponent)
  .component('requestClientContactDetails', RequestClientContactDetailsComponent)
  .component('requestAttachments', RequestAttachmentsComponent)
  .component('addClients', AddClientsComponent)
  .component('customMenu', CustomMenuComponent)
  .component('allMenus', allMenusComponent)
  .component('editMenus', EditMenusComponent)
  .component('allSpaces', allSpacesComponent)
  .component('proposalContacts', ProposalContactsComponent )
  .component('quillEditor', quillEditor)
  .component('drinkPackages', drinkPackagesComponent)
  .component('adminPayment', adminPaymentComponent)
  .component('requestCancel', RequestCancelComponent);

each(components, (component, name) => {
  m.component(name, component);
});

each(constants, (constant, name) => {
  m.constant(name, constant);
});

each(factories, (factory, name) => {
  m.factory(name, factory);
});

each(modals, (modal, name) => {
  m.factory(name, modal);
});

each(directives, (directive, name) => {
  m.directive(name, directive);
});

module.exports = 'requests';
