module.exports = function() {
  return {
    template: require('./unsubscribe-newsletter.jade'),
    controller: ['$routeParams', '$location', '$api', 'unwrapError', '$scope', function($routeParams, $location, $api, unwrapError, $scope) {
      const user = $routeParams.user;
      if (!user) {
        $location.path('/404');
      }
      $api.Auth.unsubscribe($routeParams.user).
        then(function(response) {
          $scope.$emit('UNSUBSCRIBED_FROM_ALL');
        }).
        catch(unwrapError);
    }]
  };
};
