import { GetByIdResponse, SearchResponse, UpdateResponse } from 'lib/server/api/admin/companies/models';
export class CompaniesRouter {
  BASE_URL: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${API_ROOT}/admin/companies`;
  }

  getById = (id): ng.IPromise<GetByIdResponse> => {
    return this.$http.get(`${this.BASE_URL}/${id}`)
      .then((response: { data: GetByIdResponse }) => response.data);
  }

  search = ({ params, page, name }: { params?: any, page?: number, name?: string }): ng.IPromise<SearchResponse> => {
    return this.$http.get(this.BASE_URL, { params: { search: params, page, name } })
      .then((response: { data: SearchResponse }) => response.data);
  }

  update = ({ company, changes }): ng.IPromise<UpdateResponse> => {
    return this.$http.put(`${this.BASE_URL}/${company._id}`, { changes })
      .then((response: { data: UpdateResponse }) => response.data);
  }

  delete = (id: string): ng.IPromise<Express.Response> => {
    return this.$http.delete(`${this.BASE_URL}/${id}`);
  }
}
