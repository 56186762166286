import { RawList } from 'spc/lib/database/types/list';
import { RawBaseUser } from 'spc/lib/database/types/base-user';

class RemoveListCollaboratorsController {
  list: RawList;
  collaborators;

  constructor(private $api, private unwrapError, private $scope, private $user) {
    'ngInject';
  }

  $onInit() {
    this.getCollaborators();
  }

  getCollaborators() {
    this.collaborators = this.list.users.filter(user => !user.isPrimary);
  }

  removeListUser(user) {
    return this.$api.Lists.removeUser (user._id, this.list._id)
          .then(() => {
            this.updateCollaborators(user);
          });
  }

  updateCollaborators(user) {
    this.collaborators = this.collaborators.filter((collab => collab.client._id !== user._id));
  }

}

export const RemoveListCollaboratorsComponent = {
  template: require('./remove-list-collaborators.component.jade'),
  controller: RemoveListCollaboratorsController,
  bindings: {
    close: '&',
    list: '<',
    user: '<',
  }
};
