import { TimeslotViewService } from './timeslot-view.service';
import moment from 'moment';
import { ApiService } from 'spc/shared/api/api.service';
import debounce = require('lodash/debounce');
import ENUMS from 'common/dist/enums';
import { every, get, some } from 'lodash';

class EditTimeslotController {
  venue: { slug: string };
  deletingTimeslot: boolean = false;
  space: any;
  weekdays = moment.weekdays();
  closeSidebar: () => any;
  changesSaved: () => any;
  ENUMS: any = ENUMS;

  constructor(private timeslotViewService: TimeslotViewService, private $api: ApiService, private unwrapError) {
    'ngInject';
  }

  $onDestroy = () => {
    this.cancelEdit();
  }

  hasOverlappingConflict = () => {
    const editedTimeslot = this.timeslotViewService.selectedTimeslot;
    if (!editedTimeslot) {
      return false;
    }
    const otherTimeslots = this.space.data.timeSlots.filter((slot) => {
      if (!editedTimeslot._id && slot._id) {
        return true;
      } else if (slot._id && editedTimeslot._id) {
        return slot._id.toString() !== editedTimeslot._id.toString();
      } else {
        return false;
      }
    });

    return otherTimeslots.some(slot => this.timeslotViewService.areSlotsOverlapping(editedTimeslot, slot));
  }

  getRoomFee = (roomFee) => {
    return Number.isFinite(roomFee) ? roomFee / 100 : undefined;
  }

  roomFeeChange = (fee) => {
    if (!this.timeslotViewService.selectedTimeslot.terms) {
      this.timeslotViewService.selectedTimeslot.terms = { roomFeeCents: Number.isFinite(parseFloat(fee)) ? fee * 100 : null };
      return;
    }
    this.timeslotViewService.selectedTimeslot.terms.roomFeeCents = Number.isFinite(parseFloat(fee)) ? fee * 100 : null;
  }

  save = () => {
    if (!this.canSubmit()) {
      return;
    }
    // magic later
    return this.$api.Venues.Spaces.save(this.venue.slug, this.space)
      .then(() => this.changesSaved())
      .then(() => this.timeslotViewService.saveChanges())
      .catch(error => this.unwrapError(error));
  }

  cancelEdit = () => {
    if (this.timeslotViewService.editingNewTimeSlot) {
      this.deleteTimeslot(this.timeslotViewService.selectedTimeslot);
    }
    this.timeslotViewService.deselectTimeslot();
    this.timeslotViewService.registerSpaceEdit();
  }

  canSubmit = () => {
    if (this.deletingTimeslot && !this.hasOverlappingConflict()) {
      return true;
    }

    return !this.hasOverlappingConflict() && this.hasAllRequiredFields();
  }

  hasAllRequiredFields = () => {
    const hasWeekdays = some(get(this.timeslotViewService.selectedTimeslot, 'days'));
    const requiredFields = [
      get(this.timeslotViewService.selectedTimeslot, 'name'),
      get(this.timeslotViewService.selectedTimeslot, 'from'),
      get(this.timeslotViewService.selectedTimeslot, 'to'),
      hasWeekdays,
    ];

    return every(requiredFields);
  }

  isInvalidEndTime = (endTime: number) => {
    return this.timeslotViewService.isInvalidEndTime(this.timeslotViewService.selectedTimeslot, endTime);
  }

  setEndTime = (time: number) => {
    if (this.isInvalidEndTime(time)) {
      return;
    }

    this.timeslotViewService.selectedTimeslot.to = time;
    this.timeslotViewService.registerSpaceEdit();
    return this;
  }

  toggleSlotDay = (dayIndex: number) => {
    this.timeslotViewService.toggleSlotDay(dayIndex);
    this.timeslotViewService.registerSpaceEdit();
    return this;
  }

  deleteTimeslot = (timeSlot) => {
    const timeSlotId = timeSlot._id.toString();
    this.deletingTimeslot = true;
    this.timeslotViewService.deselectTimeslot();
    this.space.data.timeSlots = this.space.data.timeSlots.filter(slot => slot._id.toString() !== timeSlotId);
    this.timeslotViewService.registerSpaceEdit();
    this.save();
  }
}

export const EditTimeslotComponent = {
  controller: EditTimeslotController,
  template: require('./edit-timeslot.component.jade'),
  bindings: {
    space: '<',
    venue: '<',
    closeSidebar: '&',
    changesSaved: '&'
  }
};
