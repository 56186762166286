// NPM Deps
import * as angular from 'angular';

import { NeedToKnowComponent } from './need-to-know.component';
import editWinelistsModal from './edit-winelists.modal';
import { WinelistsDisplayComponent } from './winelists-display.component';

const VenuesModule = angular.module('VenuesModule', [])
  .component('needToKnow', NeedToKnowComponent)
  .component('winelistsDisplay', WinelistsDisplayComponent)
  .service('editWinelistsModal', editWinelistsModal);

module.exports = 'VenuesModule';
