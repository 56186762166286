import debounce from 'lodash/debounce';

import { RawVenue } from 'lib/database/types/venue/venue';
import { RawList } from 'lib/database/types/list';
import { RawBaseUser } from 'lib/database/types/base-user';

class AddVenueController {
  close: () => void;
  venue: RawVenue;
  lists: RawList[];
  loggedIn: boolean;
  user: RawBaseUser;
  selectedLists: string[];
  loading: boolean;
  count: number = 0;
  totalList: number;

  constructor(private $api, private unwrapError, private $user, private $cloudinary) {
    'ngInject';
    this.selectedLists = [];
    this.loading = true;
  }

  $onInit () {
    if (this.$user.isLoggedIn()) {
      this.user = this.$user.$;
      this.getUserLists();
    }
  }

  selectList(list) {
    this.selectedLists.push(list._id.toString());
    this.debouncedAddVenues();
  }

  addVenues() {
    this.$api.Lists.addVenue({ venueId: this.venue._id.toString(), lists: this.selectedLists })
      .then((res) => {
        res.lists.forEach((list) => {
          const i = this.lists.findIndex(existingList => existingList._id === list._id.toString() );
          this.lists[i] = list;
        });
        this.selectedLists = [];
      })
    .catch(error => this.unwrapError(error));
  }

  isVenueOnList(list) {
    return list.saved.find(s => s.venue && s.venue._id.toString() === this.venue._id.toString());
  }

  debouncedAddVenues = debounce(this.addVenues, 300);

  getUserLists () {
    this.$api.Lists.getAll({ userId: this.user._id.toString(), page: this.count })
      .then((res) => {
        this.lists = this.lists ? this.lists.concat(res.lists.data) : res.lists.data;
        this.totalList = res.lists.totalListCount;
        this.loading = false;
        this.count += 1;
      })
      .catch(error => this.unwrapError(error));
  }

  addList ({ list }) {
    if (list) {
      this.lists.push(list);
    } else {
      this.close();
    }
  }

  closeLogin ({ user }) {
    this.user = user;
    this.getUserLists();
  }

}

export const AddVenueComponent = {
  template: require('./add-venue.component.jade'),
  controller: AddVenueController,
  bindings: {
    venue: '<',
    close: '&',
    lists: '<'
  }
};
