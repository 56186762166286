// NPM Dependencies
import { fullPriceBreakdownCents } from 'common/dist/price';
import { overagePriceCents } from 'common/dist/virtuals/Payment';
import AuthorizedClientHelpers from 'common/dist/virtuals/AuthorizedClient';
import BookingRequestHelpers from 'common/dist/virtuals/BookingRequest';
import { each, find, get, keys, last, some, startsWith, unset, upperCase } from 'lodash';
import UserHelpers from 'common/dist/virtuals/User';
import status from 'http-status';
// SixPlus Dependencies (Modules)
import { StateEmitter } from '../../utils/StateEmitter';
import { ApiService } from 'spc/shared/api/api.service';
import { MessageService } from 'spc/requests/messages/message.service';
import { DConversation } from 'spc/lib/database/types/conversation';
import { getAttachments } from './guest-venue-conversation';
import { downloadableUrl } from './directives-services';
import { ToastService } from '../../shared/toast.service';

// constant
import { ANALYTICS_EVENTS } from '../../constants/ENUMS/analyticsEvents';

module.exports = function () {
  return {
    template: require('./guest-conversation.jade'),
    scope: {
      conversation: '='
    },
    controller: [
      '$config',
      '$scope',
      '$clickOk',
      '$api',
      '$rootScope',
      '$injector',
      'ENUMS',
      '$counteroffer',
      'humanizedPathMap',
      '$timeout',
      'REQUEST_STATES',
      '$cloudinary',
      'unwrapError',
      '$user',
      'paymentHelpers',
      'messageService',
      'spPrinter',
      'proposalContactsModal',
      'requestCancelModal',
      'proposalHelpModal',
      'addClientsModal',
      '$scrollService',
      'messageAttachmentsModal',
      'contractAttachmentsModal',
      'toast',
      function (
      $config,
      $scope,
      $clickOk,
      $api: ApiService,
      $rootScope: ng.IRootScopeService,
      $injector,
      ENUMS,
      $counteroffer,
      humanizedPathMap,
      $timeout: ng.ITimeoutService,
      REQUEST_STATES,
      $cloudinary,
      unwrapError,
      $user,
      paymentHelpers,
      messageService: MessageService,
      spPrinter,
      proposalContactsModal,
      requestCancelModal,
      proposalHelpModal,
      addClientsModal,
      $scrollService,
      messageAttachmentsModal,
      contractAttachmentsModal,
      toast: ToastService,
      ) {
      $scope.emitter = new StateEmitter(['LOADING', 'ERROR', 'LOADED'],
        'LOADING');
      $scope.emitter.$waitFor('LOADED', () => {
      $scope.$emit('$viewReady', 'HIDE_FOOTER');
        $timeout(() => {
          $scope.$emit('guestConversation', $scope.conversation);
        });
      $scope.openAddClientsModal = openAddClientsModal;
      });

      $scope.gratuityPercentage = 20;
      const conversationId = $scope.conversation;
      $scope.fetchAndConvertUrl = $cloudinary.fetchAndConvertURL;
      $scope.srcSet = $cloudinary.srcSet;

      $scope.goToPaymentPage = goToPaymentPage;
      $scope.message = message;
      $scope.cancel = cancel;
      $scope.confirm = confirm;
      $scope.hasCompletedPayments = hasCompletedPayments;
      $scope.hadEvent = $counteroffer.hadEvent;
      $scope.overagePriceCents = overagePriceCents;
      $scope.hideSummary = hideSummary;
      $scope.openSidebar = openSidebar;
      $scope.closeSidebar = closeSidebar;
      $scope.openProposalContactsModal = openProposalContactsModal;
      $scope.openProposalHelpModal = openProposalHelpModal;
      $scope.openMessageAttachmentsModal = openMessageAttachmentsModal;
      $scope.openRequestCancelModal = openRequestCancelModal;
      $scope.acceptOfflineProposal = acceptOfflineProposal;
      $scope.downloadableUrl = downloadableUrl;
      $scope.getAttachment = getAttachments;

      $scope.delta = {};

      $scope.primaryClient;
      $scope.agreedToTerms = false;

      $scope.REQUEST_STATES = REQUEST_STATES.guest;
      $scope.enumRequestStates = ENUMS.bookingRequestState;
      $scope.fullName = UserHelpers.fullName;
      $scope.setConversation = setConversation;
      $scope.printFinalReceipt = printFinalReceipt;
      $scope.handleCopyClick = handleCopyClick;
      $scope.getPreviewLink = getPreviewLink;
      $scope.scrollToBottom = scrollToBottom;
      $scope.scrollToTop = scrollToTop;
      $scope.scrollLastMessage = scrollLastMessage;
      $scope.nonDeletedAttachments = nonDeletedAttachments;
      $scope.determineReceiptLocation = determineReceiptLocation;
      $scope.openFilesTab = openFilesTab;
      $scope.changeTab = changeTab;
      $scope.toggleAgreedToTerms = toggleAgreedToTerms;
      $scope.balancePaymentMethod;
      $scope.isAccepted = false;
      $scope.allAttachments = [];
      $scope.sent = false;

      init();

      // Listeners
      /**
       * Listen for changes in request state from request listener in child directive
       */
      $scope.$on('REQUEST_CHANGE', (ev, params) => {
        const clone = params.clone;
        if (!clone) {
          return;
        }
        createClone(clone);
        const counteroffer = params.counteroffer;
        if (counteroffer && counteroffer.state === 'READY') {
          // check for 2nd to last delta in case it was a numguest change
          const secondToLastDelta = counteroffer.delta[counteroffer.delta.length - 2];
          let changedPath;
          if (secondToLastDelta && secondToLastDelta.path === 'data.groupSize') {
            changedPath = secondToLastDelta.path;
          } else {
            changedPath = last(counteroffer.delta).path;
          }
          const startingPath = find(keys(humanizedPathMap), path => startsWith(changedPath, path));
          if (startingPath) {
            const humanizedPath = humanizedPathMap[startingPath];
            $scope.$broadcast('REQUEST_CHANGE',
              { message: `${get($scope, 'request.venue.data.name', 'The venue')} made some changes to ${humanizedPath}` });
          }
        }
      });

      $scope.$on('UPDATE_MESSAGES', (ev, params) => params.messages ? $scope.messages = params.messages : '');
      $scope.$on('UPDATE_ATTACHMENTS', (ev, params) => params.attachments ? $scope.allAttachments = params.attachments : []);
      /**
       * Load data about this conversation from the server
       */

      function createClone(request) {
        $scope.clone = $counteroffer.createClone(request);
        const lastCounteroffer = last($scope.clone.counteroffers);
        const theState = get(lastCounteroffer, 'state');

        // guest can only see READY changes
        const guestCanSeeDelta = theState === 'READY';
        if (guestCanSeeDelta) {
          each(lastCounteroffer.delta, (delta) => {
            $scope.delta[delta.path] = delta;
          });
        }
        $scope.timeSpan = $counteroffer.displayTime($scope.clone, $scope.delta);
        $scope.costBreakdownCents = fullPriceBreakdownCents($scope.clone);

        $scope.schedule = paymentHelpers.getDisplayableSchedule($scope.request);
      }

      function init() {
        $scope.emitter.$state('LOADING');

        $scope.ui = {};

        $scope.uiData = {
          message: '',
          attachments: [],
          quillFormat: { ops: [] }
        };

        $scope.tab = 'REVIEW';

        $api.Requests.conversation(conversationId).then((res) => {
          if (res.noRequest) {
            redirectToMessages(conversationId);
            return;
          }

          if (res.redirect) {
            redirectToPreview(conversationId);
            return;
          }

          if (upperCase($injector.get('$location').search().tab) === 'FILES') {
            changeTab('FILES');
          }

          $scope.user = $user;
          $scope.conversation = res.conversation;
          $scope.venueUrl = res.venueUrl;
          $scope.request = res.request;
          $scope.primaryClient = get($scope.request, 'admin.acceptedBy');

          // if (shouldAddUser()) {
          //   addUser();
          // }

          // calling attachment
         $scope.allAttachments = $scope.getAttachment({ conversation : res.conversation, request: res.request });

          $scope.client = AuthorizedClientHelpers.primaryEventContact($scope.request).user;
            createClone($scope.request);
          $scope.coverImageUrl = $cloudinary.fetchAndConvertURL(get($scope, 'clone.coverImageUrl'), 'small');
          $scope.coverImageSrcSet = $cloudinary.srcSet(get($scope, 'clone.coverImageUrl'));
          $scope.assignee = get($scope, 'request.admin.assignee');
          $scope.messages = res.messages || [];
          $scope.emitter.$state('LOADED');
          })
          .then(() => getUserMethods())
          .then(() => $timeout(() => {
            if ($scope.user) {
              messageService.markConversationRead({ conversation: $scope.conversation })
                .catch(unwrapError);
            }
            $scope.scrollLastMessage(1);
          }))
          .catch((error) => {
            if (error.status === status.UNAUTHORIZED) {
              redirectToUnauthorized();
            }
            unwrapError(error);
          });
      }

      function getUserMethods() {
        const schedule = get($scope.request, 'data.payment.schedule', []);
        const hasBalancePending = some(schedule, s => s.state === 'PENDING');
        const skipBalance = get($scope.request, 'data.payment.skipBalance');

        if (!hasBalancePending || skipBalance) {
          return;
        }

        $api.Payment.initialize({
          getMethods: true,
          conversation: $scope.request.conversation.toString()
        }).then((res) => {
          const paymentMethods = get(res, 'data.paymentMethods');
          const paymentToken = get($scope.request, 'data.payment.token');

          if (paymentMethods && paymentMethods.length) {
            $scope.balancePaymentMethod = find(paymentMethods, m => m.token === paymentToken);
          }
        }).catch(error => unwrapError(error));
      }

      // function shouldAddUser (): boolean {
      //   return $scope.user && !$user.isHostOrAdmin() && !AuthorizedClientHelpers.allowedToView({ doc: $scope.request, user: $scope.user.$ });
      // }

      function changeTab(tab: string): void {
        $scope.tab = tab;
      }
      function openRequestCancelModal () {
        if (isAlreadyRequested()) {
          return $clickOk('Cancellation request has already been sent', false);
        }
        return requestCancelModal({
          request: $scope.request,
          conversation: $scope.conversation,
          venue: $scope.venue,
        });
      }

      function isAlreadyRequested () {
        return $scope.messages.some(msg => msg.kind === 'requestCancel');
      }

      function toggleAgreedToTerms () {
        if (!$scope.agreedToTerms && !$scope.primaryClient) {
          $scope.agreedToTerms = true;
          $scope.primaryClient = $user.$.fullName;
          document.getElementById('focusInput').focus();
          return;
        }
        return $scope.agreedToTerms = !$scope.agreedToTerms;
      }

      // function addUser () {
      //   $api.Conversations.addUserToConversation(conversationId).then((res) => {
      //     $scope.conversation = res.conversation;
      //     $scope.request = res.bookingRequest;
      //     $scope.$broadcast('USER_ADDED', { message: 'You are now a collaborator on this proposal' });
      //   }).catch(error => unwrapError(error));
      // }

      function determineReceiptLocation () {
        const completeEventStates = $scope.enumRequestStates.postPaymentStates.concat($scope.enumRequestStates.cancelledPostDepositStates);
        if (completeEventStates.includes($scope.request.state)) {
          return `final-receipt`;
        } else {
          const balancePayment = BookingRequestHelpers.balancePayment($scope.request);
          return balancePayment ? 'balance-receipt' : 'deposit-receipt';
        }
      }

      function redirectToUnauthorized() {
        $scope.$emit('REDIRECT', { redirect: '/unauthorized' });
      }

      function redirectToMessages(id: string) {
        $scope.$emit('REDIRECT', { redirect: `/conversation/${id}/messages` });
      }

      function redirectToPreview(id: string) {
        $scope.$emit('REDIRECT', { redirect:
         `/client/conversation/${id}/preview`
        });
      }

      function printFinalReceipt () {
        return spPrinter.printTemplate(require('../print-templates/final-receipt.jade'), $scope);
      }

      function scrollToBottom () {
        $scrollService('#accept-box');
      }

      function scrollToTop () {
        $scrollService('.cc-content');
      }

      function scrollLastMessage (time = 500) {
        const lastItem = $('.message-item').last();
        const offset = 0;
        const container = 'conversation-messages';
        $scrollService(lastItem, offset, time, container);
      }

      function hideSummary () {
        $scope.hiddenSummary = !$scope.hiddenSummary;
      }

      function openSidebar () {
        $scope.openSidebarMessage = true;
        $scope.closeSidebarMessage = false;
      }

      function closeSidebar () {
        $scope.closeSidebarMessage = true;
        $scope.openSidebarMessage = false;
      }

      function openProposalContactsModal() {
        return proposalContactsModal({
          request: $scope.request,
          conversation: $scope.conversation,
          venue: $scope.venue,
          sixplusContacts: false,
          previewUser: false
        });
      }

      function openProposalHelpModal() {
        return proposalHelpModal({
          request: $scope.request,
          venue: $scope.venue
        });
      }

      function openMessageAttachmentsModal() {
        return messageAttachmentsModal({
          conversation: $scope.conversation,
          attachments: $scope.uiData.attachments
        })
          .then((data) => {
            $scope.uiData.attachments = get(data, 'value.attachments', $scope.uiData.attachments);
          })
          .catch(error => this.unwrapError(error));
      }

      /**
       * Send the given message and reset message to null
       *
       * @param {String} message
       */

      function message() {
         if ($scope.uiData.message === '') {
          toast.badNews('Please enter a message');
          return;
        }
        if ($scope.uiData.message.length) {
          $scope.sendingMessage = true;
          const messageAttachment = $scope.uiData.attachments.filter( attach => attach.file);
          $scope.emitter.$waitFor('LOADED', () => {
            $api.Conversations.sendMessage({
              conversation: $scope.conversation,
              attachments: messageAttachment,
              data: {
                message: $scope.uiData.message,
                quillFormat: $scope.uiData.quillFormat
              },
            })
            .then((data) => {
              $scope.sent = true;
              handleNewMessage(data.message);
              unset($scope, 'uiData.message');
              $scope.sendingMessage = false;
              $scope.scrollLastMessage();
              $scope.$broadcast('MESSAGE_SENT', { message: 'Your message was sent' });
            })
            .catch((error) => {
              $scope.sent = false;
              $scope.sendingMessage = false;
              unwrapError(error);
            });
          });
          $scope.sent = false;
        }
      }
      function acceptOfflineProposal() {
        const signedContract = $scope.request.signedContractAttachments;
        const signedContractLength = signedContract ? signedContract.length : 0;
        const signedContractURL = signedContract ? signedContract.url : '';
        $scope.request.isSigned = true;
        $scope.request.signedContractAttachments = get($scope.request, 'signedContractAttachments', []);
        return contractAttachmentsModal({
          request: $scope.request,
          attachments: $scope.request.signedContractAttachments,
        })
          .then((data) => {
            $scope.request.signedContractAttachments = get(data, 'value.attachments', $scope.request.signedContractAttachments);
            if ( signedContractLength !== $scope.request.signedContractAttachments.length ) {
              $scope.isAccepted = true;
              $api.Requests.save ( { request: this.request } ).then( async () => {
                const newOfflineData = {
                  conversation: $scope.conversation._id,
                  prevSignedContractLength: signedContractLength
                };
              const porposal = await $api.Requests.acceptProposalOffline( $scope.request._id, newOfflineData ) .then( () => {
            window.location.reload();
          });
            });
          }
          });
      }
      /**
       * Cancel the request
       *
       * @param {String} [message] optional message
       */

      function cancel(message) {
        $api.Requests.guestCancel($scope.request._id, message)
          .then((res) => {
            $scope.request = Object.assign(res.data.request, { clients: $scope.request.clients });
      // create clone to show pricing for most recent version of request

            $scope.conversation = res.data.conversation;
            handleNewMessage(res.data.message);
          }).catch(unwrapError);
      }

      /**
       * Confirm the request
       *
       * @param {Number} gratuity percentage, for instance `gratuityPercentage=18` means 18%
       * @return {Promise}
       */

      function confirm(gratuityPercentage) {
        $scope.$broadcast('STOP_REQUEST_LISTENER');
        submitAndPay();
        function submitAndPay() {
          $api.Requests.confirm($scope.request._id, gratuityPercentage, $scope.primaryClient).
            then((res) => {
              $scope.request = res.data.request;
              trackGuestAcceptedRequest();
              if ($scope.request.state === 'ACCEPTED_GUEST') {
                goToPaymentPage();
              } else {
                createClone($scope.request);
                $scope.$broadcast('REQUEST_CHANGE', { message: `You've accepted ${$scope.request.venue.data.name}'s alterations` });
                $scope.$broadcast('START_REQUEST_LISTENER', { request: $scope.request  });
              }
            }).
            catch((error) => {
              $scope.$broadcast('START_REQUEST_LISTENER');
              unwrapError(error);
            });
        }
      }

      function handleCopyClick () {
        $scope.ui.copyClick = true;
        $timeout(() => $scope.ui.copyClick = false, 1000);
      }

      function getPreviewLink() {
        const location = $injector.get('$location');
        let domain = `${location.protocol()}://${location.host()}`;
        if (location.port() !== 443) {
          domain = `${domain}:${location.port()}`;
        }
        return `${domain}/client/conversation/${$scope.request.conversation}/preview`;
      }

      /**
       * Analytics - tracks guest accepting request
       */

      function trackGuestAcceptedRequest() {
        const eventParams = {
          venue: get($scope.request, 'venue.data.name'),
          venueCity: get($scope.request, 'venue.data.address.city'),
          eventType: get($scope.request, 'data.customEventType') || get($scope.request, 'data.eventType'),
          groupSize: get($scope.request, 'data.groupSize')
        };

        let eventName;

        if ($scope.request.state === 'ACCEPTED_HOST') {
          eventName = ANALYTICS_EVENTS.requests.guestAcceptedRequest;
        } else if ($scope.request.state === 'ALTERATION_ACCEPTED_HOST') {
          eventName = ANALYTICS_EVENTS.requests.guestAcceptedAlterations;
        }
        const eventAction = 'Accept';
        $injector.get('$analytics').$trackEvent(eventName, eventParams, eventAction);
      }

      /**
       * Go to payment page directly if Event Request is already confirmed
       */

      function goToPaymentPage() {
        $injector.get('$location').path('/client/conversation/' + $scope.request.conversation + '/pay');
      }

      function hasCompletedPayments(schedule) {
        if (!schedule || !schedule.length) {
          return false;
        }

        return some(schedule, p => p.state === 'COMPLETED');
      }

      function openAddClientsModal() {
        addClientsModal({ request: $scope.request, conversation: $scope.conversation })
          .then((data) => {
            if (get(data, 'value.cancel')) {
              return;
            }
            const request = get(data, 'value.request');
            const conversation = get<DConversation>(data, 'value.conversation');

            if (request) {
              $scope.request.clients = get(request, 'clients');
              createClone($scope.request);
            }
          })
          .catch(unwrapError);
      }

      function setConversation(conversation) {
        $scope.conversation = conversation;
      }

      function handleNewMessage (message) {
        $scope.messages = [message, ...$scope.messages];

        if (message.attachments) {
          $scope.conversation.attachments = [...$scope.conversation.attachments, ...message.attachments];
        }

        $scope.uiData.attachments = [];
      }

      function nonDeletedAttachments (conversation, request) {
        let fileCount = 0 ;
        if ( conversation && conversation.attachments && conversation.attachments.length ) {
          fileCount += conversation.attachments.filter(attachment => !attachment.isDeleted && attachment.file.title !== 'Link').length;
        }
        return fileCount ;
      }

      function openFilesTab () {
        $scope.tab = 'FILES';
        $timeout(() => {
          $scrollService('div.cccp-content.animate');
        });
      }

    }]
  };
};
