import * as angular from 'angular';

import { each } from 'lodash';
import directives from './directives';
import controllers from './controllers';
import factories from './factories';
import modals from './modals';
import { VenueAuthorizedUserComponent } from './components/venue-authorized-users.component';
import { FeeApplicationComponent } from './components/fee-application.component';
import { feeApplicationModal } from './components/fee-application.modal';

const m = angular.module('venue_dashboard', []);

each(factories, function(factory, name) {
  m.factory(name, factory);
});

each(modals, function(factory, name) {
  m.factory(name, factory);
});

each(controllers, function(controller, name) {
  m.controller(name, controller);
});

each(directives, function(directive, name) {
  m.directive(name, directive);
});

m.component('venueAuthorizedUsers', VenueAuthorizedUserComponent);
m.component('feeApplication', FeeApplicationComponent);
m.service('feeApplicationModal', feeApplicationModal);


module.exports = 'venue_dashboard';
