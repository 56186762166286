import { cloneDeep } from 'lodash';
import { IHttpProvider } from 'angular';
import  mongoose  from 'mongoose';

import { GetHistoryResponse, GetAllResponse, SearchQuery, UsersResponse, LastSession } from 'server/api/admin/users/models';
export class Users {
  BASE_URL: string;
  constructor(private API_ROOT, private $http: ng.IHttpService) {
    'ngInject';
    this.BASE_URL = `${ this.API_ROOT }/admin/users`;
  }

  public getCount = (): ng.IPromise<GetAllResponse> => {
    return this.$http.get(this.BASE_URL)
      .then((response: { data: GetAllResponse }) => response.data);
  }

  public getAssignees = function(options) {
    return this.$http.get(`${ this.BASE_URL }/assignees`, { params: options });
  };

  public get = (pageNum: number) => {
    const url = `${ this.BASE_URL }/all/${ pageNum }`;
    return this.$http.get(url);
  }

  public update = (user) => {
    const url = `${ this.BASE_URL }/${ user._id }`;
    return this.$http.put(url, user);
  }

  public search = (pageNum = 0, searchParams) => {
    const query = cloneDeep(searchParams);
    query.pageNum = pageNum;

    const url = `${ this.BASE_URL }/search`;
    return this.$http.get(url, { params: query });
  }

  public download = (searchQuery: SearchQuery): ng.IPromise<UsersResponse> => {
    const query = searchQuery;
    const url = `${this.BASE_URL}/download`;
    return this.$http.get(url, { params: query })
    .then((response: { data: UsersResponse }) => response.data);
  }

  public getOneByEmail = (email: string) => {
    const url = `${ this.BASE_URL }/search/email/${ email }`;
    return this.$http.get(url);
  }

  public getMultipleByText = (text: string) => {
    const url = `${ this.BASE_URL }/search/text/${ text }`;
    return this.$http.get(url);
  }

  public history = (user, venueId?): ng.IPromise<GetHistoryResponse> => {
    const id = user._id ? user._id.toString() : user.toString();
    return this.$http.get(`${this.BASE_URL}/history/${id}${venueId ? `?venue=${venueId}` : ''}`)
      .then((response: { data: GetHistoryResponse }) => response.data);
  }

  public startCampaign = ({ user, campaignName }) => {
    const id = user._id ? user._id.toString() : user.toString();
    return this.$http.put(`${this.BASE_URL}/campaigns/${id}/${campaignName}`, {})
      .then(response => response.data);
  }

  public getlastSession = ({ user }: { user: mongoose.Types.ObjectId | string }): ng.IPromise<LastSession> => {
    const id = user.toString();
    const url = `${ this.BASE_URL }/lastActivity/${ id }`;
    return this.$http.get(url)
      .then((response: { data: LastSession}) => response.data);
  }
}
