import { ProposalMetricsService } from './proposal-metrics.service';
import { ProposalMetricsSearchService } from './proposal-metrics-search.service';
import { MappedEventResult } from './mapped-event-result.model';
import { EventResult } from 'shared/interfaces/metrics/event-result.model';
import { ProposalMetricsDataService } from './proposal-metrics-data.service';
import { ProposalMetricsHelpers } from './proposal-metrics.helpers';
import { get, sample, sumBy, reduce, round } from 'lodash';
import moment from 'moment';
import { NPS } from 'server/api/admin/metrics/models';

class ProposalMetricsDashboard {
  _startDate: moment.Moment;
  _endDate: moment.Moment;
  search: ProposalMetricsSearchService;
  metrics: ProposalMetricsService;
  results: EventResult[][];
  cities: string[];
  labels: string[];
  randomMessage: { message: string, language: string };
  loading: boolean;
  nps: NPS;

  constructor(
    proposalMetricsService: ProposalMetricsService,
    public now,
    private unwrapError,
    proposalMetricsSearchService: ProposalMetricsSearchService,
    private $rootScope: ng.IRootScopeService,
    private proposalMetricsDataService: ProposalMetricsDataService) {
    'ngInject';
    this.search = proposalMetricsSearchService;
    this.metrics = proposalMetricsService;
  }

  $onInit() {
    const self = this;
    self.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');

    const initialValues = this.metrics.getInitialValues();
    self.search.initialize();

    self._startDate = moment(self.search.filters.startDate);
    self._endDate = moment(self.search.filters.endDate);
    self.executeSearch();
  }

  $onDestroy() {
    this.search.clearState();
  }

  public setStartDate(m: moment.Moment) {
    const self = this;

    self.search.setStartDate(m);
  }

  public getTotals(bucket: EventResult[]): number {
    return ProposalMetricsHelpers.sumByProperty(bucket, 'breakdown.totalCents');
  }

  public getNumEvents(bucket: EventResult[]): number {
    const revenueGeneratingEvents = bucket.filter(event => event.breakdown.totalCents);
    return revenueGeneratingEvents.length;
  }

  public getAverageValuePerEvent = (bucket: EventResult[]): number => {
    const total = sumBy(bucket, result => round(result.breakdown.totalCents / 100, 2));
    return round(total / this.getNumEvents(bucket), 2);
  }

  public getCommissionTotals(bucket: EventResult[]): number {
    return ProposalMetricsHelpers.sumByProperty(bucket, 'breakdown.hostCommissionCents');
  }

  public getCCTotals(bucket: EventResult[]): number {
    return ProposalMetricsHelpers.sumByProperty(bucket, 'breakdown.cardFee');
  }

  public getGroupSizeTotals(bucket: EventResult[]): number {
    return bucket.reduce((groupSize, eventResult) => {
      if (eventResult.breakdown.totalCents) {
        groupSize += eventResult.groupSize;
      }
      return groupSize;
    }, 0);
  }

  public getGrandTotalPerGuest(bucket: EventResult[]): number {
    const totals: { guests: number, value: number } = reduce(bucket, (acc, eventResult, idx) => {
      if (eventResult.breakdown.totalCents) {
        acc.guests += eventResult.groupSize;
        acc.value += round(eventResult.breakdown.totalCents / 100, 2);
      }
      return acc;
    }, { guests: 0, value: 0 });
    return round(totals.value / totals.guests, 2);
  }

  public setEndDate(m: moment.Moment) {
    const self = this;

    self.search.setEndDate(m);
  }

  public searchData () {
    const self = this;

    if (self.search.filters.startDate && self.search.filters.endDate) {
      return self.executeSearch();
    }
  }

  public switchTimeChunkStrategy(type: string) {
    const self = this;
    self.search.timeChunk = type;
    self.executeSearch();
  }

  public executeSearch() {
    const self = this;

    self.startSearch();
    self.search
      .runSearch()
      .then((results) => {
        const data = self.proposalMetricsDataService.transformDataForGraph({ data: results.data, timeOptions: self.search.getTimeOptions() });
        self.cities = data.cities;
        self.results = data.events;
        self.labels = data.labels;
        self.nps = results.nps;
      })
      .then(() => self.endSearch())
      .catch(self.unwrapError);
  }

  private startSearch() {
    const self = this;
    self.loading = true;
  }

  private endSearch() {
    const self = this;
    self.loading = false;
  }
}


export const ProposalMetricsDashboardComponent = {
  template: require('./proposal-metrics-dashboard.jade'),
  controller: ProposalMetricsDashboard
};
