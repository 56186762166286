
import commafy from 'commafy';

import { filter, get, includes, keys, lowerCase, isNil, set, some, startsWith, unset, cloneDeep, add } from 'lodash';
import { RawAddOn } from 'spc/lib/database/types/venue/add-on';

interface TempAddOn extends RawAddOn {
  $fake: boolean;
}

/**
 * Directive for handling addon counteroffers
 */
module.exports = function() {
  return {
    template: require('./addon-counteroffer.jade'),
    controller: ['$scope', 'ENUMS', 'ADDON_PRICE_TYPES', 'unwrapError', '$counteroffer', function($scope, ENUMS, ADDON_PRICE_TYPES, unwrapError, $counteroffer) {
      // UI-related fns
      $scope.canSaveAddOn = canSaveAddOn;
      $scope.allowAddOns = ENUMS.bookingRequestState.activeStates.filter(state => state !== 'RECONCILED');
      $scope.addOnPriceTypes = ENUMS.priceType;
      $scope.taxTypes = ENUMS.taxTypes;
      $scope.isNewAddOn = isNewAddOn;
      $scope.isEditingAddOn = isEditingAddOn;
      $scope.displayAddOnDetails = displayAddOnDetails;
      $scope.cleanup = cleanup;
      $scope.alreadySelectedAddOn = alreadySelectedAddOn;
      $scope.validateAddOn = validateAddOn;

      // counterClone-related fns
      $scope.startAddOnEdit = startAddOnEdit;
      $scope.setAddOn = setAddOn;
      $scope.setAddOnPrice = setAddOnPrice;
      $scope.addAddOnDeltas = addAddOnDeltas;
      $scope.addNewAddOn = addNewAddOn;
      $scope.deleteAddOn = deleteAddOn;
      $scope.addOnErrors = {};

      ///// Functions
      function isNewAddOn(path) {
        if (!$scope.delta) {
          return;
        }

        const paths = keys($scope.delta);
        return some(paths, p => startsWith(p, path));
      }

      function canSaveAddOn(path) {
        validateAddOn(path);
        return !$scope.addOnErrors.priceInCents && !$scope.addOnErrors.numGuests && !$scope.addOnErrors.item;
      }

      function isEditingAddOn(editing: { [key: string]: boolean }): boolean {
        const paths = keys(editing);
        return some(paths, path => startsWith(path, 'data.addOns') && editing[path]);
      }

      function startAddOnEdit(path: string): void {
        $scope.startEdit(path);

        const addOn: any = get($scope.counterClone, path);
        if (addOn.priceType !== 'FREE') {
          $scope._addOnPriceDollars = addOn.price;
        }
        if (includes(ADDON_PRICE_TYPES.perPerson, addOn.priceType) && !addOn.numGuests) {
          addOn.numGuests = get($scope, 'counterClone.data.groupSize');
        }
        validateAddOn(path);
      }

      function setAddOn(addOn: TempAddOn, $index: number): void {
        const original = get($scope.counterClone, `data.addOns.${ $index }`);

        const clone = addOn.$fake
          ? $counteroffer.addOns.initCustomAddOn($scope.counterClone, original)
          : cloneDeep(addOn);

        clone.isForBooking = true;
        if (clone.priceType === 'PER_PERSON') {
          clone.numGuests = get($scope.counterClone, 'data.groupSize');
        }
        const path = `data.addOns.${ $index }`;
        set($scope.counterClone, path, clone);
        $scope._addOnPriceDollars = clone.totalCostDollars;
        setAddOnPrice($scope._addOnPriceDollars, $index);
        validateAddOn(path);
      }

      function validateAddOn(path: string): void {
        $scope.addOnErrors = {};
        const addOn = get($scope.counterClone, path) as RawAddOn;

        if (!addOn.item) {
          $scope.addOnErrors.item = true;
        }


        if (addOn.priceType === 'FREE') {
          $scope.addOnErrors.priceInCents = addOn.priceInCents ? true : null;
        }

        if (!addOn.priceInCents && addOn.priceType !== 'FREE') {
          $scope.addOnErrors.priceInCents = true;
        }

        if (!addOn.numGuests && !['FREE', 'FLAT'].includes(addOn.priceType)) {
          $scope.addOnErrors.numGuests = true;
        }
      }

      function setAddOnPrice(dollars: string, $index: number): void {
        const path = `data.addOns.${ $index }`;
        const addOn: any = get($scope.counterClone, path);
        if (!dollars || !parseFloat(dollars)) {
          addOn.priceInCents = 0;
        } else {
          addOn.priceInCents = (parseFloat(dollars) * 100).toFixed(0);
        }
        validateAddOn(path);
      }

      function addAddOnDeltas($index: number): void {
        const path = 'data.addOns.' + $index;
        const deltaValue: any = get($scope.counterClone, path);

        deltaValue.priceInCents = deltaValue.priceInCents ? +deltaValue.priceInCents : null;

        $scope.addDelta(path, deltaValue).
          then(function() {
            $scope.loading[path] = false;
            $scope.editing[path] = false;
            unset($scope, '_newAddOn');
            cleanup();
          }).
          catch(function() {
            $scope.loading[path] = false;
            $scope.editing[path] = false;
            unset($scope, '_newAddOn');
            cleanup();
          });
      }

      function displayAddOnDetails(addOn: TempAddOn): string {
        const clone = cloneDeep(addOn);
        if (clone.priceType === 'FREE') {
          return 'Free';
        }
        const price = isNil(clone.priceInCents) ?
          null :
          (clone.priceInCents / 100).toFixed(2);

        return !isNil(price) ?
          `$${ commafy(price) } ${ lowerCase(ENUMS.priceType[clone.priceType]) }` :
          'Incomplete';
      }

      function addNewAddOn(addOn: TempAddOn): void {
        if (!addOn || $scope.alreadySelectedAddOn(addOn.item)) {
          return;
        }

        $scope.creatingAddOn = true;

        let newAddOn;
        if (addOn.$fake) {
          newAddOn = $counteroffer.addOns.initCustomAddOn($scope.counterClone);
        } else {
          newAddOn = cloneDeep(addOn);
          newAddOn.isForBooking = true;
        }

        if (includes(ADDON_PRICE_TYPES.perPerson, newAddOn.priceType) && !newAddOn.numGuests) {
          newAddOn.numGuests = get($scope, 'counterClone.data.groupSize');
        }

        $scope.counterClone.data.addOns.push(newAddOn);
        const length: any = get($scope, 'counterClone.data.addOns.length');
        const path: any = `data.addOns.${ length - 1 }`;
        startAddOnEdit(path);
      }

      function cleanup(): void {
        unset($scope, '_addOnPriceDollars');
        if ($scope.creatingAddOn) {
          $scope.creatingAddOn = false;
        }
      }

      function deleteAddOn(path: string): void {
        $scope.addDelta(path, null, true).
          catch(unwrapError);
      }

      function alreadySelectedAddOn(itemName: string): Boolean {
        const nonDeletedAddOns: any[] = filter($scope.counterClone.data.addOns, (a: any) => !a.isDeleted);

        return some(nonDeletedAddOns,
          (addOn: any) => addOn && !addOn.isCustom && addOn.item === itemName);
      }
    }]
  };
};
