interface Venue {
  _id: string;
  menus: {
    data: {
      name: string;
      type: string;
    }[]
  };
}
export class MenuDisplayService {
  ORIGINAL_VENUE_MENU_MAP: any = {};
  FILTERED_VENUE_MENU_MAP: any = {};
  startTime: number;
  constructor(private MENU_TIMES, private ENUMS) {
    'ngInject';
  }

  identifyMeals = () => {
    return Object.keys(this.MENU_TIMES)
      .filter((meal) => {
        const time = this.MENU_TIMES[meal];
        return time.from <= this.startTime && time.to >= this.startTime;
      })
      .concat(this.ENUMS.menuTypes.nonMealMenuTypes);
  }

  updateDisplayedMenus = () => {
    const allowedMeals = this.identifyMeals();
    for (const venueId in this.ORIGINAL_VENUE_MENU_MAP) {
      const menus = this.ORIGINAL_VENUE_MENU_MAP[venueId];
      this.FILTERED_VENUE_MENU_MAP[venueId] = menus.filter(menu => allowedMeals.includes(menu.data.type));
      if (!this.FILTERED_VENUE_MENU_MAP[venueId].length) {
        this.FILTERED_VENUE_MENU_MAP[venueId] = this.ORIGINAL_VENUE_MENU_MAP[venueId];
      }
    }
  }

  updateStartTime = ({ startTime }) => {
    this.startTime = startTime;
    this.updateDisplayedMenus();
  }

  addVenuesToList = (venues: Venue[]) => {
    venues.forEach((_venue) => {
      if (this.ORIGINAL_VENUE_MENU_MAP[_venue._id]) {
        return;
      }
      this.ORIGINAL_VENUE_MENU_MAP[_venue._id] = _venue.menus;
    });
    this.updateDisplayedMenus();

  }
  addVenueToList = (venue: Venue) => {
    if (this.ORIGINAL_VENUE_MENU_MAP[venue._id]) {
      return;
    }
    this.ORIGINAL_VENUE_MENU_MAP[venue._id] = venue.menus;
    this.updateDisplayedMenus();
  }
}
