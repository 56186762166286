import * as angular from 'angular';
import { submitReviewComponent } from './submit-review.component';
import { venueReviewsComponent } from './venue-reviews.component';
import { userReviewComponent } from './user-reviews.component';
import { ReviewsService } from './reviews.service';
import { VenueReviewsDashboardComponent } from './venue-reviews-dashboard.component';
import { starsComponent } from './stars.component';

export const ReviewsModule = angular.module('ReviewsModule', [])
  .component('submitReview', submitReviewComponent)
  .component('venueReviews', venueReviewsComponent)
  .component('userReviews', userReviewComponent)
  .component('venueReviewsDashboard', VenueReviewsDashboardComponent)
  .component('stars', starsComponent)
  .service('reviewsService', ReviewsService)
  .name;
