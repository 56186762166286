import moment from 'moment';
import sortBy = require('lodash/sortBy');
import debounce = require('lodash/debounce');

// SP Interfaces
import { TimeslotViewService } from './timeslot-view.service';

class DaysTimeslotGridController {
  space: any;
  selectSpace: () => any;
  weekdays = moment.weekdays();
  ui: { timeSlotMap?: any, ready: boolean } = { timeSlotMap: {}, ready: false };

  constructor(private timeslotViewService: TimeslotViewService) {
    'ngInject';
  }

  $onChanges = (data) => {
    if (!this.ui.ready) {
      return;
    }

    if (data.space) {
      this.createTimeSlotMap();
    }
  }

  $onInit = () => {
    this.createTimeSlotMap();
    this.timeslotViewService.executeOnTimeSlotEdit.push(this.createTimeSlotMap);
    this.ui.ready = true;
  }

  createTimeSlotMap = () => {
    this.weekdays.forEach((day: string, index: number) => {
      const timeSlots = this.getDaysTimeslots(index);
      this.ui.timeSlotMap[day] = timeSlots;
    });
  }

  getDaysTimeslots = (dayIndex: number) => {
    const timeSlots = this.space.data.timeSlots
      .filter(slot => slot.days[dayIndex]);
    return sortBy(timeSlots, (slot: any) => slot.from);
  }

  isFinite = (num) => {
    return Number.isFinite(parseFloat(num));
  }

  displayEndTime = (time) => {
    return this.timeslotViewService.displayEndTime(time);
  }
}

export const DaysTimeslotGridComponent = {
  bindings: {
    space: '<'
  },
  template: require('./days-timeslot-grid.component.jade'),
  controller: DaysTimeslotGridController
};
