export const SEARCH_CITIES = {
  'NYC Tri-State Area': [
    'Amherst',
    'Armonk',
    'Bridgehampton',
    'Bronx',
    'Brooklyn',
    'East Hampton',
    'Fair Harbor',
    'Fairfield',
    'Greenwich',
    'Hoboken',
    'Irvington',
    'Jersey City',
    'Livingston',
    'Long Island City',
    'Melville',
    'Morristown',
    'New York',
    'Oyster Bay',
    'Port Chester',
    'Pound Ridge',
    'Queens',
    'South Plainfield',
    'Stamford',
    'Staten Island',
    'Weehawken',
    'Westhampton Beach',
    'Westport',
    'White Plains'
  ],
  'DC Metro Area': [
    'Alexandria',
    'Annapolis',
    'Annandale',
    'Arlington',
    'Ashburn',
    'Baltimore',
    'Bethesda',
    'Bluemont',
    'Centreville',
    'Chevy Chase',
    'Delaplane',
    'Fairfax',
    'Falls Church',
    'Frederick',
    'Leesburg',
    'McLean',
    'Merrifield',
    'Middleburg',
    'North Bethesda',
    'Oxon Hill',
    'Potomac',
    'Reston',
    'Silver Spring',
    'Tysons',
    'Washington',
    'Vienna'
  ],
  'Boston Area': [
    'Boston',
    'Burlington',
    'Cambridge',
    'Chestnut Hill',
    'Dedham',
    'Everett',
    'Framingham',
    'Franklin',
    'Lynnfield',
    'Medford',
    'Methuen',
    'Needham',
    'Newton',
    'Salem',
    'Somerville',
    'Waltham',
    'Wayland',
    'Wellesley',
    'Westwood',
    'Woburn',
    'Worcester'
  ],
  'San Francisco Bay Area': [
    'Berkeley',
    'Burlingame',
    'Campbell',
    'Cupertino',
    'Daly City',
    'Danville',
    'East Palo Alto',
    'Half Moon Bay',
    'Livermore',
    'Los Altos',
    'Menlo Park',
    'Mill Valley',
    'Monterrey',
    'Mountain View',
    'Napa',
    'Oakland',
    'Palo Alto',
    'Pasadena',
    'Petaluma',
    'Redondo Beach',
    'Redwood City',
    'Rutherford',
    'San Carlos',
    'San Francisco',
    'San Jose',
    'San Mateo',
    'Santa Clara',
    'Saratoga',
    'Sonoma',
    'St. Helena',
    'Sunnyvale',
    'Walnut Creek',
    'Woodside',
    'Yountville'
  ],
  'Greater Los Angeles Area': [
    'Anaheim',
    'Beverly Hills',
    'Carson',
    'Costa Mesa',
    'Culver City',
    'Dana Point',
    'El Segundo',
    'Hermosa Beach',
    'Hollywood',
    'Glendale',
    'Irvine',
    'La Canada Flintridge',
    'Long Beach',
    'Los Angeles',
    'Malibu',
    'Manhattan Beach',
    'Marina del Rey',
    'Newport Beach',
    'Santa Ana',
    'Santa Monica',
    'Tustin',
    'West Hollywood'
  ],
  Chicago: [
    'Aurora',
    'Chicago',
    'Downers Grove',
    'Elmhurst',
    'Glenview',
    'Lincolnshire',
    'Lincolnwood',
    'Lombard',
    'Naperville',
    'Oak Brook',
    'Oakbrook Terrace',
    'Oak Park',
    'Rosemont',
    'Skokie',
    'Tinley Park',
    'Wheeling'
  ],
  Arizona: [
    'Phoenix',
    'Scottsdale',
    'Tempe',
    'Tucson'
  ],
  California: [
    'Cardiff',
    'Coronado',
    'San Diego',
  ],
  Colorado: [
    'Aspen',
    'Denver',
    'Genese',
    'Parker'
  ],
  Connecticut: [
    'Bloomfield',
    'Mashantucket',
    'New Haven',
    'Uncasville',
  ],
  Florida: [
    'Boca Raton',
    'Daytona Beach',
    'Destin',
    'Fort Lauderdale',
    'Jacksonville',
    'Lake Buena Vista',
    'Longboat Key',
    'Miami',
    'Miami Beach',
    'Melbourne',
    'Naples',
    'Orlando',
    'Palm Beach',
    'St. Petersburg',
    'Tampa'
  ],
  Georgia: [
    'Alpharetta',
    'Atlanta',
    'Norcross',
    'Savannah',
  ],
  Hawaii: [
    'Honolulu',
    'Maui',
  ],
  Illinois: [
    'Northbrook',
    'Schaumburg',
  ],
  Idaho: [
    'Boise'
  ],
  Indiana: [
    'Indianapolis',
  ],
  Iowa: [
    'West Des Moines',
  ],
  Kentucky: [
    'Louisville',
    'Newport',
  ],
  Louisiana: [
    'Lake Charles',
    'New Orleans',
  ],
  Michigan: [
    'Dearborn',
    'Detroit',
    'Grand Rapids',
  ],
  Minnesota: [
    'Bloomington',
    'Eden Prairie',
    'Edina',
    'Minneapolis',
    'Minnetonka',
    'Roseville',
  ],
  Mississippi: [
    'Biloxi',
  ],
  Missouri: [
    'Chesterfield',
    'Clayton',
    'Kansas City',
    'St. Louis'
  ],
  Nebraska: [
    'Omaha',
  ],
  Nevada: [
    'Lake Tahoe',
    'Las Vegas',
  ],
  'New Hampshire': [
    'Amsterdam',
  ],
  'New Jersey': [
    'Atlantic City',
    'Neptune',
    'Princeton'
  ],
  'New York': [
    'Buffalo',
    'Latham',
    'Montgomery',
    'San Diego',
  ],
  'North Carolina': [
    'Charlotte',
    'Durham',
    'Raleigh',
  ],
  Ohio: [
    'Cincinnati',
    'Columbus',
  ],
  Oregon: [
    'Portland',
  ],
  Pennsylvania: [
    'Philadelphia',
    'Pittsburgh',
    'Mount Pocono',
    'Wayne',
    'Willow Grove',
  ],
  'Puerto Rico': [
    'Dorado'
  ],
  'Rhode Island': [
    'Newport',
  ],
  'South Carolina': [
    'Charleston',
  ],
  Tennessee: [
    'Franklin',
    'Gatlinburg',
    'Nashville',
  ],
  Texas: [
    'Austin',
    'Dallas',
    'Fort Worth',
    'Galveston',
    'Grapevine',
    'Houston',
    'Humble',
    'Katy',
    'Kemah',
    'Other',
    'Plano',
    'San Antonio',
    'Seabrook',
    'Shenandoah',
    'Sugar Land'
  ],
  Utah: [
    'Park City',
    'Salt Lake City',
  ],
  Virgina: [
    'Charlottesville',
    'Virginia Beach',
  ],
  Washington: [
    'Bellevue',
    'Seattle'
  ],
  Wisconsin: [
    'Milwaukee',
  ],
  Wyoming: [
    'Jackson Hole',
  ],
  Bahamas: [
    'Paradise Island',
    'Nassau',
  ],
  Belgium: [
    'Brussels'
  ],
  Canada: [
    'Cuyahoga Falls',
    'Montreal',
    'New Westminster',
    'Port Moody',
    'Richmond',
    'Squamish',
    'Toronto',
    'White Rock',
    'Vancouver'
  ],
  China: [
    'Hong Kong',
    'Shanghai',
  ],
  England: [
    'London'
  ],
  Fance: [
    'Paris'
  ],
  Greece: [
    'Athens'
  ],
  India: [
    'Mumbai',
  ],
  Italy: [
    'Cividale del Friuli'
  ],
  Japan: [
    'Tokyo'
  ],
  Mexico: [
    'Los Cabos',
    'Mexico City',
  ],
  'Stockholm Area': [
    'Stockholm',
    'Norrmalm'
  ],
  Thailand: [
    'Bangkok'
  ],
  Turkey: [
    'Istanbul',
  ],
  'United Arab Emirates': [
    'Dubai',
  ]
};
