import { get, set, each, filter } from 'lodash';

export default ['$api', 'ENUMS', function($api, ENUMS) {
  const ret = {};
  ret.getDocsAndVenue = getDocsAndVenue;

  ret.Venues = {
    getPopulated,
    hasEnoughAssociatedDocs,
    setTaxes
  };

  ret.Spaces = {
    setRoomFeeCents
  };

  /**
   * Get a venue populated with its authorized users. Since this method
   * is used for both requests and venues, always fetch by _id
   *
   * @param {Venue}
   * @return {Promise}
   */
  function getPopulated(venue) {
    return $api.Venues.get(venue.slug, {
      getNonVisible: true,
      authorizedUsers: true,
      _id: venue._id.toString()
    });
  }

  /**
   * Get a venue and its associated documents, including its drinks and menus
   *
   * @param {String} slug
   * @return {Promise}
   */
  function getDocsAndVenue(slug) {
    return $api.Venues.get(slug, { menus: true, drinks: true });
  }

  /**
   * Determine if a venue has enough associated docs (1 space and 1 menu)
   *
   * @param {Venue} venue
   * @param {Array} menus
   * @return { valid: Boolean, reason: String }
   */
  function hasEnoughAssociatedDocs(venue, menus) {
    const val = {};
    const numSpaces = filter(get(venue, 'data.spaces'),
      s => s.isVisible).length;
    if (!numSpaces) {
      val.valid = false;
      val.reason = 'A published venue requires at least one visible space';
      return val;
    }

    const numMenus = filter(menus, m => m.isVisible).length;
    if (!numMenus) {
      val.valid = false;
      val.reason = 'A published venue requires at least one visible menu';
      return val;
    }

    val.valid = true;
    return val;
  }

  /**
   * Set a venue's F&B and nonF&B tax based on the city
   *
   * @param {Doc} doc
   * @param {String} city
   */
  function setTaxes(doc, city) {
    if (!city) {
      return;
    }
    const currentCity = get(doc, 'data.address.city');
    if (currentCity && currentCity === city) {
      return;
    }

    const taxPaths = [];

    if (!get(doc, 'data.fees.tax') || currentCity !== city) {
      taxPaths.push('tax');
    }
    if (!get(doc, 'data.fees.nonfbTax') || currentCity !== city) {
      taxPaths.push('nonfbTax');
    }

    each(taxPaths, function(path) {
      const cityBucket = get(ENUMS, `cities.${ city }`);
      if (!cityBucket) {
        return;
      }

      set(doc, `data.fees.${ path }`, cityBucket[path]);
    });
  }

  function setRoomFeeCents(slot, dollars) {
    const amount = dollars || 0;
    set(slot, 'terms.roomFeeCents', (parseFloat(amount) * 100).toFixed(0));
  }

  return ret;
}];
