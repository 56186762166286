import {
  RawExperience,
  RawVendor,
  VirtualEventInquiry
} from 'spc/lib/database/types/virtual-events';
import { ApiService } from '../shared/api/api.service';
import { get, every, capitalize, cloneDeep } from 'lodash';
import emailValidator from 'email-validator';
import { UserService } from 'spc/services/user.service';

const newVirtualEventInquiry: VirtualEventInquiry = {
  name: '',
  email: '',
  additionalInfo: '',
  company: '',
  date: '',
  isFlexible: false,
  guestCount: ''
};
class VirtualExperienceDashboardController {
  ready: () => void;
  experienceId;
  experience: RawExperience;
  isBot: boolean;
  vendor: RawVendor;
  virtualEventInquiry: VirtualEventInquiry = cloneDeep(newVirtualEventInquiry);
  errors: any = {};
  isLoading: boolean = false;
  isContactFormSubmitted: boolean = false;
  keys = Object.keys;
  isUser: boolean = this.$user.isLoggedIn();

  constructor(
    private $rootScope,
    private $seo,
    private $api: ApiService,
    private $routeParams,
    private $cloudinary,
    private photoDialog,
    private obscuredLoginModal,
    private $location,
    private $user: UserService,
    private $timeout: ng.ITimeoutService,
    private unwrapError
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.experienceId = this.$routeParams.experienceId;
    this.$api.VirtualExperience.getExperienceById({
      experienceId: this.experienceId,
    })
      .then((response) => {
        this.experience = response.experience;
        this.vendor = response.vendor;
        this.isBot = response.isBot;
        this.$seo.set(this.experience.name);
        this.ready();
      })
      .catch((error) => {
        this.ready();
        this.$location.path('/404');
      });

    if (!this.$user.isLoggedIn() || !this.isBot) {
      this.$timeout(this.openLoginModal, 7000);
    }
  }

  openPhotoDialog = ({ photos, initialPhoto }): Promise<any> => {
    return this.photoDialog({ photos: photos, initialPhoto: initialPhoto });
  }

  requestToBookVirtualEvent = () => {
    this.isLoading = true;
    this.$api.VirtualExperience.requestForVirtualEvent({
      experienceId: this.experienceId,
      virtualEventInquiry: this.virtualEventInquiry
    })
      .then((response) => {
        this.isLoading = false;
        this.isContactFormSubmitted = true;
      })
      .catch((error) => {
        this.isLoading = false;
        this.unwrapError(error);
      });
  }

   // opens when the user is not logged In
   openLoginModal = () => {
    if (this.$user.isLoggedIn() || this.isBot) {
      return;
    }
    return this.obscuredLoginModal({
      primaryUser: '',
      lead: '',
      allowClose: this.$user.isLoggedIn()
    }).then(() => {
      this.isUser = this.$user.isLoggedIn();
    }).catch((error) => {
      this.unwrapError(error);
    });
  }

  hasAllTheRequiredFields = () => {
    const requiredFields = [
      get(this.virtualEventInquiry, 'name'),
      get(this.virtualEventInquiry, 'email'),
      get(this.virtualEventInquiry, 'guestCount'),
    ];
    return every(requiredFields);
  }

  checkForErrors = (field, eventType) => {
    const errorMessage = 'Required Field';
    delete this.errors[field];
    const value =  get(this.virtualEventInquiry, field);
    if (!value.length) {
      this.errors[field] = errorMessage;
    }
    else if (eventType === 'focusout' && field === 'email') {
      if (!emailValidator.validate(value)) {
        this.errors[field] = 'Email is invalid';
      }
    }
  }
}

export const virtualExperienceDashbaordComponent = {
  template: require('./virtual-experience-dashboard.component.jade'),
  controller: VirtualExperienceDashboardController,
  bindings: {
    ready: '<',
  },
};
