import { get, every, filter, cloneDeep, isEqual, debounce } from 'lodash';
import { RawVendor, Host } from 'spc/lib/database/types/virtual-events';
import { ApiService } from '../../../shared/api/api.service';
import { VIRTUAL_EVENTS_CHANGE } from '../../../../../database/constants/virtualEventsChange';
const newHost: Host = {
  name: '',
  description: '',
  photos: [],
  coverIndex: 0,
};
class HostsController {
  currentHostIndex: number;
  isFormDisplayed: boolean = false;
  host: Host = cloneDeep(newHost);
  hosts: Host[] = [];
  vendor: RawVendor;
  errors: {
    [key: string]: {
      message?: string
    }
  } | null = {};
  isModeCreate: boolean;
  object: any;
  type: string;
  isHostEdit: boolean;
  updateReadyStatus: any;

  constructor(
    private unwrapError,
    private $api: ApiService,
    private $cloudinary,
    private $scope
  ) {
    'ngInject';
  }

  $onInit = () => {
    if (this.object) {
      if (this.object.hosts && this.object.hosts.length) {
        this.hosts = cloneDeep(this.object.hosts);
      }
    }
     this.$scope.$on(VIRTUAL_EVENTS_CHANGE.VIRTUAL_HOST_CHANGE, this.cloneOriginalItemToItem);
  }

  cloneOriginalItemToItem = () => {
    this.hosts = cloneDeep(this.object.hosts);
  }

  validateHost = (path) => {
    const currentItemPath = `hosts.${this.currentHostIndex}.${path}`;
    if (this.type === 'vendor') {
      this.$api.Admin.Vendors.validate({ vendor: { hosts: this.hosts } })
        .then((response) => {
          const errors = get(response, 'errors.vendor');
          if (errors) {
            const pathErrors = get(errors, currentItemPath);
            this.errors[currentItemPath] = pathErrors
              ? pathErrors.message
              : null;
          } else {
            this.errors = {};
          }
        })
        .catch((error) => {
          this.unwrapError(error);
        });
    }
    else if (this.type === 'experience') {
      this.$api.Admin.Experience.validate({
        experience: { hosts: this.hosts },
        vendorId: this.vendor['_id'],
      })
        .then((response) => {
          const errors = get(response, 'errors.experience');
          if (errors) {
            const pathErrors = get(errors, currentItemPath);
            this.errors[currentItemPath] = pathErrors
              ? pathErrors.message
              : null;
          } else {
            this.errors = {};
          }
        })
        .catch((error) => {
          this.unwrapError(error);
        });
    }
  }

  checkForErrors = debounce(this.validateHost, 300);

  createHost = () => {
    this.displayForm(true);
    const length = this.hosts.push(this.host);
    this.currentHostIndex = length - 1;
  }

  resetHost = () => {
    this.host = cloneDeep(newHost);
  }

  displayForm(value) {
    this.isFormDisplayed = value;
  }

  cancel() {
    if (!this.isHostEdit) {
      this.hosts = filter(
        this.hosts,
        (_, index) => index !== this.currentHostIndex
      );
    }
    else {
      this.hosts[this.currentHostIndex] = JSON.parse(
        JSON.stringify(this.object.hosts[this.currentHostIndex])
      );
      this.isHostEdit = false;
    }
    this.resetHost();
    this.displayForm(false);
  }

  isFieldInvalid = (path) => {
    const fieldPath = `hosts.${this.currentHostIndex}.${path}`;
    return this.errors[fieldPath] ? true : false;
  }

  getErrorMessage = (path) => {
    const fieldPath = `hosts.${this.currentHostIndex}.${path}`;
    return this.errors[fieldPath];
  }

  submit = () => {
    if (this.type === 'vendor') {
      this.updateVendor();
    } else if (this.type === 'experience') {
      this.updateExperience();
    }
  }

  updateUi = () => {
    this.object.hosts = cloneDeep(this.hosts);
    this.displayForm(false);
    this.resetHost();
    this.isHostEdit = false;
    if (this.type === 'experience') {
      this.updateReadyStatus();
    }
  }

  updateVendor = () => {
    const updates = { hosts: this.hosts };
    this.$api.Admin.Vendors.updateVendorById({
      updates,
      vendorId: this.object['_id'],
    })
      .then((res) => {
        this.hosts = res.vendor.hosts;
        this.updateUi();
      })
      .catch((err) => {
        this.unwrapError(err);
      });
  }

  updateExperience = () => {
    const updates = { hosts: this.hosts };
    this.$api.Admin.Experience.updateExperienceById({
      updates,
      experienceId: this.object['id'],
    })
      .then((response) => {
        this.hosts = response.experience.hosts;
        this.updateUi();
        this.object.status = response.experience.status;
      })
      .catch((err) => {
        this.unwrapError(err);
      });
  }

  checkIfFormDataInvalid = () => {
    const errorsPath = Object.keys(this.errors);
    let result = null;
    errorsPath.forEach((path) => {
      result = this.errors[path] || result;
    });
    return result;
  }

  hasAllTheRequiredFields = () => {
    const requiredFields = [
      get(this.host, 'name'),
      get(this.host, 'description'),
      get(this.host, 'photos.length')
    ];
    return every(requiredFields);
  }
  edit = (index) => {
    this.host = this.hosts[index];
    this.displayForm(true);
    this.currentHostIndex = index;
    this.isHostEdit = true;
  }

  delete = (hostIndex) => {
    const hostToDelete = this.hosts[hostIndex];
    if (isEqual(this.host, hostToDelete)) {
      this.host = cloneDeep(newHost);
      this.displayForm(false);
    }
    this.hosts = filter(this.hosts, (_, index) => index !== hostIndex);
    if (this.type === 'experience') {
      this.updateExperience();
    } else if (this.type === 'vendor') {
      this.updateVendor();
    }
  }

  savePhotos = (photos, coverIndex) => {
    this.host.photos = photos;
    if (coverIndex !== undefined) {
      this.host.coverIndex = coverIndex;
    }
  }
}

export const VirtualEventsHostsComponent = {
  controller: HostsController,
  template: require('./virtual-events-hosts.component.jade'),
  bindings: {
    vendor: '<',
    object: '<',
    type: '<',
    isModeCreate: '<',
    updateReadyStatus: '<'
  },
};
