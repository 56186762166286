
export default ['ngDialog', function (ngDialog) {
  return function(venueId: string, ui?: string, listId?: string) {

    return ngDialog.open({
      template: '<list-venue-view venue-id="$ctrl.venueId" list-id="$ctrl.listId" ui="$ctrl.ui"></list-venue-view>',
      className: 'ngdialog-theme-plain info-modal venue-view-modal',
      plain: true,
      controllerAs: '$ctrl',
      closeByNavigation: true,
      closeByDocument: true,
      controller: ['$scope', function($scope) {
        this.venueId = venueId;
        this.ui = ui;
        this.listId = listId;
        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];
