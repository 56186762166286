import { get, every, filter, cloneDeep, isEqual, debounce } from 'lodash';
import { OtherFees, RawVendor } from 'spc/lib/database/types/virtual-events';
const newOtherFee: OtherFees = {
  name: '',
  description: '',
};

import { ApiService } from '../../../shared/api/api.service';
class OtherFeesController {
  currentOtherFeeIndex: number;
  isFormDisplayed: boolean = false;
  otherFee: OtherFees = cloneDeep(newOtherFee);
  otherFees: OtherFees[] = [];
  validate: any;
  vendor: RawVendor;
  errors: {
    [key: string]: {
      message: string
    }
  } | null = {};
  isCostEdit: boolean = false;
  object: any;
  type: string;

  constructor(private unwrapError, private $api: ApiService) {
    'ngInject';
  }

  $onInit = () => {
    if (this.object) {
      if (
        this.object.otherFees &&
        this.object.otherFees.length
      ) {
        this.otherFees = cloneDeep(this.object.otherFees);
      }
    }
  }

  validateOtherCost = (path) => {
    const currentItemPath = `otherFees.${this.currentOtherFeeIndex}.${path}`;
    this.$api.Admin.Experience.validate({ experience: { otherFees: this.otherFees }, vendorId : this.vendor['_id'] })
      .then((response) => {
        const errors = get(response, 'errors.experience');
        if (errors) {
          const pathErrors = get(errors, currentItemPath);
          this.errors[currentItemPath] = pathErrors ? pathErrors.message : null;
        } else {
          this.errors = {};
        }
      })
      .catch((error) => {
        this.unwrapError(error);
      });
  }

  checkForErrors = debounce(this.validateOtherCost, 300);

  createNewOtherFee = () => {
    this.displayForm(true);
    const length = this.otherFees.push(this.otherFee);
    this.currentOtherFeeIndex = length - 1;
  }

  resetItem = () => {
    this.otherFee = cloneDeep(newOtherFee);
  }

  displayForm(value) {
    this.isFormDisplayed = value;
  }

  cancel() {
    if (!this.isCostEdit) {
      this.otherFees = filter(
        this.otherFees,
        (_, index) => index !== this.currentOtherFeeIndex
      );
    } else {
        this.otherFees[this.currentOtherFeeIndex] = cloneDeep(this.object.otherFees[this.currentOtherFeeIndex]);
        this.isCostEdit = false;
    }
    this.resetItem();
    this.displayForm(false);
  }

  isFieldInvalid = (path) => {
    const fieldPath = `otherFees.${this.currentOtherFeeIndex}.${path}`;
    return this.errors[fieldPath] ? true : false;
  }

  getErrorMessage = (path) => {
    const fieldPath = `otherFees.${this.currentOtherFeeIndex}.${path}`;
    return this.errors[fieldPath];
  }

  submit = () => {
    this.updateExperience();
  }

  updateUi = () => {
    this.object.otherFees = cloneDeep(this.otherFees);
    this.displayForm(false);
    this.resetItem();
    this.isCostEdit = false;
    if (this.type === 'experience') {
      this.validate('otherFees');
    }
  }


  updateExperience = () => {
    const updates = { otherFees: this.otherFees };
    this.$api.Admin.Experience.updateExperienceById({
      updates,
      experienceId : this.object['id']
    })
      .then((response) => {
        this.otherFees = response.experience.otherFees;
        this.updateUi();
        this.object.status = response.experience.status;
      })
      .catch((err) => {
        this.unwrapError(err);
      });
  }


  checkIfFormDataInvalid = () => {
    const errorsPath = Object.keys(this.errors);
    let result = null;
    errorsPath.forEach((path) => {
      result = this.errors[path] || result;
    });
    return result;
  }

  hasAllTheRequiredFields = () => {
    const requiredFields = [
      get(this.otherFee, 'name'),
      get(this.otherFee, 'description'),
    ];
    return every(requiredFields);
  }

  edit = (index) => {
    this.otherFee = this.otherFees[index];
    this.displayForm(true);
    this.currentOtherFeeIndex = index;
    this.isCostEdit = true;
  }

  delete = (itemIndex) => {
    const costToDelete = this.otherFees[itemIndex];
    if (isEqual(this.otherFee, costToDelete)) {
      this.otherFee = cloneDeep(newOtherFee);
      this.displayForm(false);
    }
    this.otherFees = filter(this.otherFees, (_, index) => index !== itemIndex);
    this.updateExperience();
    this.validate('otherFees');
  }
}

export const VirtualEventsOtherFeesComponent = {
  controller: OtherFeesController,
  template: require('./virtual-events-otherCosts.component.jade'),
  bindings: {
    validate: '<',
    vendor: '<',
    object : '<',
    type : '<',
  },
};
