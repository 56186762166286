import moment from 'moment';

export class AdminVenueService {
  public generateTooltipHtml = (venue) => {
    const changes = venue.internal.statusChanges;
    let display = '';
    if (changes.length) {
      changes.forEach((change) => {
        display += `<p>${change.oldValue} -> ${change.newValue} by ${change.person} on ${moment(change.time).format('MM-DD-YYYY, h:mm a')}</p>`;
      });
    }
    return display;
  }

  getOwnerName = ({ owner, assignees }) => {
    if (!owner) {
      return;
    }
    const ownerId = owner._id ? owner._id.toString() : owner.toString();
    return assignees.find(assignee => assignee._id.toString() === ownerId).fullName;
  }
}
