import { isString } from 'lodash';

var ret: any = {};

ret.makeQueryString = function(params) {
  var query = params ? "?" : '';
  for (var key in params) {
    var value = isString(params[key]) ?
      params[key] :
      JSON.stringify(params[key]);
    query += key + "=" + value + "&";
  }
  query = query.substring(0, query.length - 1);
  return query;
};

export { ret as queryHelpers };
