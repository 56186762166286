import { get, filter, toLower } from 'lodash';
import match from '../../utils/match';

export default ['$api', 'unwrapError', '$venueValidator', '$clickOk', function($api, unwrapError, $venueValidator, $clickOk) {
  const ret = {
    confirmHideDoc,
    toggleDrinkVisibility,
    toggleMenuVisibility,
    toggleSpaceVisibility,
    toggleVenueVisibility,
    toggleVisibility,
    warnUserAboutNumVisibleDocuments
  };

  return ret;

  ///// Public Functions

  /**
   * Toggle the visibility for core documents: venue, space, menu, or drink
   *
   * @api public
   * @param {Venue}
   * @param {Doc}
   * @return {Promise}
   */
  function toggleVisibility(venue, doc) {
    return match(doc.schemaType, {
      Drink: () => ret.toggleDrinkVisibility(venue, doc),
      Menu: () => ret.toggleMenuVisibility(venue, doc),
      Space: () => ret.toggleSpaceVisibility(venue, doc),
      Venue: () => ret.toggleVenueVisibility(venue)
    });
  }

  /**
   * Toggle visibility for a venue, flips venue.isVisible to the opposite value
   *
   * @api public
   * @param {Venue} venue
   * @return {Promise}
   */
  function toggleVenueVisibility(venue) {
    return $api.Venues.hosts.putVisibility(venue.slug, !venue.isVisible).
      then(function(response) {
        venue.isVisible = get(response, 'data.isVisible');

        const newStatus = get(response, 'data.status');
        if (newStatus) {
          venue.status = newStatus;
        }
      });
  }

  /**
   * Toggle visibility for a space, flips space.isVisible to the opposite value
   *
   * @api public
   * @param {Venue} venue
   * @param {Space} space
   * @return {Promise}
   */
  function toggleSpaceVisibility(venue, space) {
    return $api.Venues.Spaces.putVisibility(venue.slug, space, !space.isVisible).
      then(function(response) {
        space.isVisible = get(response, 'data.isVisible');
      });
  }

  /**
   * Toggle visibility for a menu, flips menu.isVisible to the opposite value. Unlike spaces,
   * we have to make an ajax request to check if a venue has at least one menu, so we'll just
   * handle all the logic in the endpoint
   *
   * @api public
   * @param {Venue} venue
   * @param {Menu}
   * @return {Promise}
   */
  function toggleMenuVisibility(venue, menu) {
    return $api.Menus.setVisibility(venue, menu, !menu.isVisible).
      then(function(response) {
        menu.isVisible = get(response, 'data.isVisible');
      });
  }

  /**
   * Toggle visibility for a drink, flips drink.isVisible to opposite value
   *
   * @api public
   * @param {Venue} venue
   * @param {Drink} drink
   * @return {Promise}
   */
  function toggleDrinkVisibility(venue, drink) {
    return $api.Drinks.setVisibility(drink, !drink.isVisible).
      then(function(response) {
        drink.isVisible = !drink.isVisible;
      });
  }

  /**
   * Confirm if user wants to hide or show the doc. The promise returned from the modal will
   * contain a value of { cancel: true } if the user changes their mind, and will shortcut the
   * operation.
   *
   * @api public
   * @param {Doc} doc
   * @return {Promise}
   */
  function confirmHideDoc(doc) {
    // const message = `Are you sure you want to ${ doc.isVisible ? 'hide' : 'show' } this ${ toLower(doc.schemaType) }?`;
    const message = `Are you sure you want to ${ doc.isVisible ? 'hide' : 'show' } this ${ toLower(doc.schemaType) }? ${ doc.isVisible ? 'The ' + toLower(doc.schemaType) + ' will no longer be publicly viewable on the site. Any pending requests will be unaffected.' : 'The ' + toLower(doc.schemaType) + ' will now be publicly viewable on the site.' }`;
    return $clickOk(message, true);
  }

  /**
   * Warn user that they need at least one visible document of this document schemaType
   *
   * @param {Doc}
   * @return {Promise}
   */
  function warnUserAboutNumVisibleDocuments(doc) {
    const item = toLower(doc.schemaType);
    const message = `You must have at least one visible ${ item } for a published venue.`;
    return $clickOk(message);
  }
  ///// Private Functions
}];

