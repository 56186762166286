export const spFocus = ['$timeout', function($timeout) {
  return {
    restrict: 'A',
    scope: {},
    link: function(scope, element, attrs) {
      $timeout(function() {
        element.focus();
      });
    }
  };
}];
