export const CITY_NEIGHBORHOODS = {
  'DC Metro Area': {
    'Washington DC': {
      'Adams Morgan': false,
      Brookland: false,
      'Capitol Hill': false,
      'Chevy Chase DC': false,
      'Cleveland Park': false,
      'Columbia Heights': false,
      'Downtown Chinatown': false,
      'Downtown DC': false,
      'Downtown Penn Quarter': false,
      'Dupont Circle': false,
      'Foggy Bottom': false,
      'Friendship Heights': false,
      Georgetown: false,
      'Glover Park': false,
      'LeDroit-Bloomingdale': false,
      'Logan Circle': false,
      'Mt. Vernon Square - Convention Center': false,
      'Navy Yard': false,
      'NoMa - H Street Corridor': false,
      Petworth: false,
      Shaw: false,
      'Southwest Waterfront': false,
      'U Street Corridor': false,
      '14th Street': false,
      'Woodley Park': false,
      'West End': false
    },
    Virginia: {
      Arlington: false,
      Alexandria: false,
      'Tysons Corner/McLean': false,
      'Falls Church': false,
      Vienna: false,
      Fairfax: false,
      Leesburg: false,
      Reston: false
    },
    Maryland: {
      Bethesda: false,
      'Silver Spring': false,
      'National Harbor': false
    }
  },
  'New York': {
    Manhattan: {
      Bowery: false,
      'Central Harlem': false,
      Chelsea: false,
      Chinatown: false,
      'Columbus Circle': false,
      Downtown: false,
      'East Harlem': false,
      'East Village': false,
      'Financial District': false,
      'Flatiron District': false,
      'Garment District': false,
      'Gramercy Park': false,
      Harlem: false,
      'Hamilton Heights': false,
      'Hell\'s Kitchen': false,
      'Herald Square': false,
      'Jackson Heights': false,
      'Kips Bay': false,
      Koreatown: false,
      'Lincoln Square': false,
      'Lower East Side': false,
      'Lower Manhattan': false,
      Midtown: false,
      'Midtown East': false,
      'Midtown West': false,
      'Madison Square': false,
      'Meatpacking District': false,
      'Morningside Heights': false,
      'Murray Hill': false,
      NoHo: false,
      NoMad: false,
      SoHo: false,
      'Theater District': false,
      'Times Square': false,
      Tribeca: false,
      'Union Square': false,
      'Upper East Side': false,
      'Upper Manhattan': false,
      'Upper West Side': false,
      'Washington Heights': false,
      'West Village': false,
    },
    Brooklyn: {
      'Brooklyn Heights': false,
      Bushwick: false,
      DUMBO: false,
      'Park Slope': false,
      Williamsburg: false,
      Greenpoint: false,
      'Long Island City': false
    }
  },
  'Boston Area': {
    Boston: {
      Allston: false,
      'Back Bay': false,
      'Bay Village': false,
      'Beacon Hill': false,
      'Boston Common': false,
      Brighton: false,
      Charlestown: false,
      'Chinatown/Leather District': false,
      Dorchester: false,
      Downtown: false,
      'East Boston': false,
      'Faneuil Hall': false,
      'Fenway Kenmore': false,
      'Hyde Park': false,
      'Jamaica Plain': false,
      Mattapan: false,
      'Mid Dorchester': false,
      'Mission Hill': false,
      'North End': false,
      Roslindale: false,
      Roxbury: false,
      Seaport: false,
      'South Boston': false,
      'South End': false,
      'Theater District': false,
      'West End': false,
      'West Roxbury': false
    },
    Cambridge: {
      Cambridgeport: false,
      'East Cambridge': false,
      'Harvard Square': false,
      'Kendall Square': false,
      'West Cambridge': false
    },
    Somerville: false,
    Brookline: false,
  },
  'Greater Los Angeles': {
    'Costa Mesa': false,
    Glendale: false,
    'Los Angeles': false,
    Malibu: false,
    'Newport Beach': false,
    'Santa Monica': false,
    'West Hollywood': false
  },
  Chicago: {
    Chicago: false,
    Elmhurst: false
  },
  'San Francisco Bay Area': {
    Berkeley: false,
    Burlingame: false,
    'Menlo Park': false,
    Oakland: false,
    'Palo Alto': false,
    'Redwood City': false,
    'San Francisco': false,
    'San Jose': false,
    'San Mateo': false,
    Sunnyvale: false
  }

};
