import { get } from 'lodash';

/**
 * Factory to validate paths of mongoose documents and maintain error state
 *
 */
export default function() {
  return function(doc) {
    if (!doc) {
      throw new Error('No document to instantiate PathValidatorService');
    }

    const ret = {
      _doc: doc,
      enableValidation,
      errorState: { $count: 0 },
      hasError,
      displayError,
      validatePath
    };

    return ret;

    /**
     * Enables validation of a path of a document that lives on the view-model
     *
     * @param {String} path
     * @param {Boolean} shouldValidate, whether or not to validate
     * @param {Ctx} context
     */
    function enableValidation(path, shouldValidate, ctx) {
      if (shouldValidate == null) {
        shouldValidate = true;
      }

      ret.errorState[path] = ret.errorState[path] || {};
      ret.errorState[path].shouldValidate = shouldValidate;

      ret.validatePath(path, null, ctx || ret._doc);
    }

    /**
     * Validates a document that lives on the view-model at a given path
     *
     * @param {String} path, path to validate `ret._doc` at
     * @param {Boolean} forceShouldValidate, forces validation
     * @param {Ctx} context
     * @return {Void}
     */
    function validatePath(path, forceShouldValidate, ctx) {
      const isVisible = !!ret._doc.isVisible;
      ret.errorState[path] = ret.errorState[path] || {};
      if (!ret.errorState[path].shouldValidate && !forceShouldValidate) {
        return;
      }
      ret._doc.isVisible = true;
      const error = ret._doc.schema.path(path).
        doValidateSync(ret._doc.get(path), ctx || ret._doc);
      ret._doc.isVisible = isVisible;

      if (error) {
        if (!ret.errorState[path].error) {
          ++ret.errorState.$count;
        }
        ret.errorState[path].error = error;
      } else {
        if (ret.errorState[path].error) {
          --ret.errorState.$count;
        }

        ret.errorState[path].error = null;
      }
    }


    /**
     * Determine if `ret.errorState` has an error for a given path
     *
     * @param {String} path
     * @return {Boolean}
     */
    function hasError(path) {
      return get(ret.errorState[path], 'error.kind');
    }

    function displayError(path) {
      return get(ret.errorState[path], 'error.message');
    }
  };
}
