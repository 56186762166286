import * as angular from 'angular';
import { ngFade } from './directives/ng-fade.directive';
import { fadeInOnEvent } from './directives/fade-in-on-event.directive';
import { fadeOutOn } from './directives/fade-out-on.directive';
import { fadeOutAfter } from './directives/fade-out-after.directive';


import { each } from 'lodash';

var m = angular.module('animations', [])
  .directive('ngFade', ngFade)
  .directive('fadeInOnEvent', fadeInOnEvent)
  .directive('fadeOutOn', fadeOutOn)
  .directive('fadeOutAfter', fadeOutAfter);


module.exports = 'animations';
