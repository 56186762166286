import { ApiService } from '../../shared/api/api.service';
import { RawExperience, RawVendor } from '../../../../database/types/virtual-events';
import { get, every, debounce , cloneDeep, includes, filter, omit , values } from 'lodash';
import { EXPERIENCE_TYPE, EXPERIENCE_DURATION_TYPE } from '../../../../database/constants/Experience';
import { VIRTUAL_EVENTS_CHANGE } from '../../../../database/constants/virtualEventsChange';
import mongoose from 'mongoose';

const newExperience: RawExperience  =  {
    experienceFormat : [],
    experienceDirective: '',
    groupSize: {
      min: null,
      max: null
    },
    url: '',
    typeOfExperience : EXPERIENCE_TYPE[0] ,
    duration : {
      durationType : EXPERIENCE_DURATION_TYPE[0],
      durationValue : null
    },
    photos: [],
    otherFees : [],
    addOns : [],
    items : [],
    hosts: [],
    termsAndConditions : [],
    additionalRequirements : [],
    shipmentDetails: [],
    coverIndex : 0,
    vendorName: ''
};
class AdminCreateEditExperienceDashboardController {
  ready: () => void;
  experienceTypes = EXPERIENCE_TYPE;
  experienceDurationTypes = EXPERIENCE_DURATION_TYPE;
  experienceFormatInput: string = '';
  experience: RawExperience = cloneDeep(newExperience);
  isModeCreate: boolean = true;
  errors: {
    [key: string]: {
      message: string
    }
  } | null = {};
  isFormDataValid: boolean = false;
  requiredFields: string[];
  originalExperience: RawExperience;
  experienceId: mongoose.Types.ObjectId | string;
  isReady: boolean = false;
  vendorId: mongoose.Types.ObjectId | string;
  vendor: RawVendor;
  readyStatusErrors: {
    path?: number;
    message?: string;
    }[] = [];
  editExperienceDetails: boolean = false;
  items = [];
  constructor(
    private $api: ApiService,
    private $clickOk,
    private unwrapError,
    private $window,
    private $routeParams,
    private organiseModal,
    private $seo,
    private $cloudinary,
    private $location,
    private $scope
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.requiredFields = ['name', 'description'];
    this.vendorId = this.$routeParams.vendorId;
    this.experienceId = this.$routeParams.id;
    this.$seo.set('Create Experience');
    this.isModeCreate = this.experienceId ? false : true;
    if (this.isModeCreate) {
      this.getVendorDetails();
      this.experience = cloneDeep(newExperience);
    }
    else {
      this.getExperienceDetails();
    }
     this.updateValidationsForReadyStatus();
  }

  getVendorDetails = () => {
    this.$api.Admin.Vendors.getVendorById({ vendorId: this.vendorId })
    .then((response) => {
      this.vendor = response.vendor;
      this.ready();
      this.isReady = true;
    })
    .then(() => {
      this.experience.vendorName = this.vendor.name;
    })
    .catch((err) => {
      this.unwrapError(err);
      this.$location.path('/404');
    });
  }


  onEnterPress = (e) => {
    if (e.which === 13 && this.experienceFormatInput.length) {
      this.addExperienceFormat();
      this.experienceFormatInput = '';
    }
  }

  addExperienceFormat = () => {
    if (!includes(this.experience.experienceFormat, this.experienceFormatInput)) {
      this.experience.experienceFormat.push(this.experienceFormatInput);
    }
    this.checkForErrors('experienceFormat');
  }

  removeExperienceFormat = (experienceFormat) => {
    let currentExperienceFormat =  cloneDeep(this.experience.experienceFormat);
    currentExperienceFormat = filter(currentExperienceFormat, format => format !== experienceFormat );
    this.experience.experienceFormat = currentExperienceFormat;
    this.checkForErrors('experienceFormat');
  }

  getExperienceDetails = () => {
    this.$api.Admin.Experience.getExperienceById({ experienceId : this.experienceId })
    .then((response) => {
      this.originalExperience = response.experience;
      this.experience = cloneDeep(this.originalExperience);
      this.items = this.experience.items;
      this.updateValidationsForReadyStatus();
      this.$seo.set(this.experience.name);
      this.vendor = response.vendor;
    })
    .then(() => {
      this.isReady = true;
      this.ready();
    })
    .catch((error) => {
      this.ready();
      this.$location.path('/404');
      this.isReady = true;
      this.unwrapError(error);
    });
  }

  validate = (path, experience = this.experience) => {
    this.$api.Admin.Experience.validate({ experience, vendorId: this.vendorId })
      .then((response) => {
        const errors = get(response, 'errors.experience');
        if (errors) {
          const pathErrors = get(errors, path);
          this.errors[path] = pathErrors ? pathErrors.message : null;
        } else {
          this.errors = {};
        }
        this.updateValidationsForReadyStatus(experience);
      })
      .catch((error) => {
        this.unwrapError(error);
      });
  }

  checkForErrors = debounce(this.validate, 300);

  setDurationValue = () => {
    const type = this.experience.duration.durationType;
    if (type === 'NA') {
      this.experience.duration.durationValue = null;
      return;
    }
    return;
  }

  organiseArray = (type) => {
    const arrayToOrganise = this.originalExperience[type];
    return this.organiseModal(arrayToOrganise, this.experienceId, type)
      .then(response => response.value && Array.isArray(response.value[type]) ? this.setUpdatedItem(response.value[type], type) : null)
      .catch(err => this.unwrapError(err));
  }

  setUpdatedItem = (items, type) => {
    this.originalExperience[type] = items;
    this.experience[type] = items;
    this.$scope.$broadcast(type);
  }

  submit = () => {
    if (this.isModeCreate) {
      this.$api.Admin.Experience.createExperience({ experience : this.experience , vendorId: this.vendorId })
        .then((response) => {
          this.$location.path(`/admin/vendors/${this.vendorId}/experience/${response.experience._id}/edit`);
        })
        .catch((error) => {
          this.unwrapError(error);
        });
    }
    else {
      const updates = {
        name: this.experience.name,
        description: this.experience.description,
        experienceDirective: this.experience.experienceDirective,
        experienceFormat : this.experience.experienceFormat,
        url: this.experience.url,
        groupSize: {
          min: this.experience.groupSize.min,
          max: this.experience.groupSize.max
          },
        typeOfExperience: this.experience.typeOfExperience,
        duration: {
          durationType: this.experience.duration.durationType,
          durationValue: this.experience.duration.durationValue
        },
        photos: this.experience.photos,
        coverIndex: this.experience.coverIndex
      };
      this.updateExperience(updates);
      this.toggleEditExperienceDetails();
    }
  }

  updateExperience = (updates) => {
    this.$api.Admin.Experience.updateExperienceById({ experienceId: this.experienceId, updates })
      .then((response) => {
        this.experience = cloneDeep(response.experience);
        this.originalExperience = cloneDeep(response.experience);
        this.updateValidationsForReadyStatus();
      })
      .catch((error) => {
        this.unwrapError(error);
      });
  }

  checkIfFormDataInvalid = () => {
    const errorsPath = ['name'];
    let result = null;
    errorsPath.forEach((path) => {
      result = this.errors[path] || result;
    });
    return result === null;
  }

  hasAllTheRequiredFields = () => {
    const requiredFields = [
      get(this.experience, 'name'),
    ];
    return every(requiredFields);
  }

  savePhotos = (photos, coverIndex) => {
    this.experience.photos = photos;
    this.experience.coverIndex = coverIndex;
    this.checkForErrors('photos');
    const updates = {
      photos,
      coverIndex
    };
    this.updateExperience(updates);
  }

  addToExperience = (data, type ) => {
    data = omit(data, ['_id']);
    const field = this.originalExperience[type];
    field.push(data);
    const updates  = { [type]: field };
    this.$api.Admin.Experience.updateExperienceById({
      updates ,
      experienceId : this.experienceId
    })
    .then((res) => {
      this.updateValidationsForReadyStatus();
      this.experience[type] = cloneDeep(res.experience[type]);
      this.experience.status = res.experience.status;
      this.originalExperience[type] = cloneDeep(res.experience[type]);
      this.originalExperience.status = res.experience.status;
      let virtualEventChange ;
      if (type === 'items') {
        virtualEventChange = VIRTUAL_EVENTS_CHANGE.VIRTUAL_ITEMS_CHANGE;
      }
      else if (type === 'addOns') {
        virtualEventChange = VIRTUAL_EVENTS_CHANGE.VIRTUAL_ADDONS_CHANGE;
      }
      else if (type === 'additionalRequirements') {
        virtualEventChange = VIRTUAL_EVENTS_CHANGE.VIRTUAL_ADDITIONALREQUIREMENTS_CHANGE;
      }
      else if (type === 'termsAndConditions') {
        virtualEventChange = VIRTUAL_EVENTS_CHANGE.VIRTUAL_TERM_CHANGE;
      }
      else if (type === 'hosts') {
        virtualEventChange = VIRTUAL_EVENTS_CHANGE.VIRTUAL_TERM_CHANGE;
      }
      this.$scope.$broadcast(virtualEventChange);
    })
    .catch((err) => {
      this.unwrapError(err);
    });
  }

  cancel = () => {
    const redirectPath =  `/admin/vendors/${this.vendorId}/edit` ;
    this.$location.path(redirectPath);
  }

  onStatusUpdate = (experience) => {
    this.experience.status = experience.status;
    this.originalExperience.status = experience.status;
  }

  updateValidationsForReadyStatus = (experience = this.originalExperience) => {
    this.$api.Admin.Experience.validate({ experience: { ...this.originalExperience, status: 'Ready' }, vendorId: this.vendorId })
      .then((response) => {
        if (response['errors'].experience) {
          return this.readyStatusErrors = values(response['errors'].experience);
        }
        return this.readyStatusErrors = [];
      })
      .catch((err) => {
        this.unwrapError(err);
      });
  }

  toggleEditExperienceDetails = () => {
    this.editExperienceDetails = !this.editExperienceDetails;
  }

  cancelEdit = () => {
    const originalExperienceDetails = {
      name: this.originalExperience.name,
      description: this.originalExperience.description,
      experienceFormat: this.originalExperience.experienceFormat,
      groupSize: {
        min: this.originalExperience.groupSize.min,
        max: this.originalExperience.groupSize.max
      },
      typeOfExperience: this.originalExperience.typeOfExperience,
      duration: {
        durationType: this.originalExperience.duration.durationType,
        durationValue: this.originalExperience.duration.durationValue
      },
    };
    this.experience = { ...this.experience, ...originalExperienceDetails };
    this.toggleEditExperienceDetails();
    this.updateValidationsForReadyStatus();
  }

}

export const adminCreateEditExperienceDashboardComponent = {
  template: require('./admin-create-edit-experience-dashboard.component.jade'),
  controller: AdminCreateEditExperienceDashboardController,
  bindings: {
    ready: '<',
  },
};
