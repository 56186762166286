
module.exports = ['ngDialog', function (ngDialog) {
  return function (request) {
    return ngDialog.open({
      template: require('./payment-history-modal.jade'),
      className: 'ngdialog-theme-small info-modal',
      overlay: true,
      plain: true,
      controller: 'PaymentHistoryModalController',
      resolve: {
        request: function () {
          return request;
        }
      }
    }).closePromise;
  };
}];
