module.exports = ['$scrollService', function($scrollService) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      var section = attrs.section;
      scope.$watch('accordion.section', function(n, o){
        if (n === section) {
          $(element).show();
          scope.accordion.triggered > 0 &&
            $scrollService($(element), scope.accordion.alignTo);
        } else {
          $(element).hide();
        }
      });
    }
  }
}];
