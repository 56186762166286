const _get = require('lodash/get');
const _includes = require('lodash/includes');

const cancellationPolicy = require('common/dist/enums/cancellationPolicy');
const strictnessOrder = cancellationPolicy.strictnessOrder;

const ret = {};

/**
 * Determines if a cancellation policy is "positive"
 * @param {Object} cancellationPolicyObject
*/

ret.isPositive = function(cancellationPolicyObject) {
  return _includes(cancellationPolicy.positives, cancellationPolicyObject.type);
};


/**
 * Helper function to generate amount of penalty or refund in units
 * @param {Object} policyObj
 * @param {Boolean} refund - if true, refund. else, penalty
*/
function generateAmountInUnits(policyObj, refund) {
  var units = refund ? _get(policyObj, 'refund.type') : _get(policyObj, 'penalty.type');
  var amount = refund ? _get(policyObj, 'refund.amount') : _get(policyObj, 'penalty.fee');
  var amountInUnits;
  if (units === 'DOLLARS') {
    amountInUnits = '$' + amount;
  } else {
    amountInUnits = amount + '%';
  }
  return amountInUnits ? amountInUnits : '...';
}

ret.generatePartialRefundAmount = function(policyObj) {
  var theAmountInUnits = generateAmountInUnits(policyObj, true);
  if (theAmountInUnits[0] === '$') {
    return theAmountInUnits + '.';
  }
  var restOfSentence = ' of ' + cancellationPolicy.multipliers[_get(policyObj, 'refund.type')] + '.';
  return theAmountInUnits + restOfSentence;
}

ret.generatePenaltyMessage = function(policyObj) {
  var theAmountInUnits = generateAmountInUnits(policyObj);
  if (theAmountInUnits[0] === '$') {
    return theAmountInUnits;
  } else {
    var restOfSentence = ' of the balance of the event.';
    return theAmountInUnits + restOfSentence;
  }
}

module.exports = ret;
