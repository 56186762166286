// NPM Deps
import { fullPriceBreakdownCents } from 'common/dist/price';
import { get } from 'lodash';
import { primaryEventContact } from 'common/dist/virtuals/AuthorizedClient';
import BookingRequestHelpers from 'common/dist/virtuals/BookingRequest';

// Our Dependencies
import { ApiService } from 'spc/shared/api/api.service';

class TopLevelRequestPreview {
  assignee: any;
  request: any;
  client: any;
  clone: any;
  conversationId: string;
  userId: string;
  user: any;
  costBreakdownCents: any;
  venueUrl: string;
  messages: any[];
  attachments: any[];

  constructor(private $scope: ng.IScope,
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $api: ApiService,
    private $routeParams,
    private $counteroffer,
    private $user,
    private unwrapError) {
    'ngInject';
    this.conversationId = $routeParams.id;
    this.userId = $routeParams.userId;
  }

  $onInit = () => {
    this.setListeners();

    this.$api.Requests.getPreview({ conversation: this.conversationId, user: this.userId })
      .then((response: { data: any }) => {
        if (get(response, 'data.redirect')) {
          return this.$location.path(response.data.redirect);
        }
        this.user = get(response, 'data.user');
        this.request = get(response, 'data.request');
        if (!this.request || ['INCOMPLETE', 'PROPOSAL'].includes(this.request.state)) {
          this.$location.path(`/conversation/${this.conversationId}/messages`);
        }
        BookingRequestHelpers.applyLastCounteroffer(this.request);
        this.request.selectedSpace = BookingRequestHelpers.selectedSpace(this.request);
        this.messages = get<any[]>(response, 'data.messages');
        const clients = get(response, 'data.clients');

        this.client = this.getClient(this.request, clients);
        this.attachments = get<any>(response, 'data.attachments');
        this.venueUrl = get<string>(response, 'data.venueUrl');
        this.assignee = get(this.request, 'admin.assignee');
        this.costBreakdownCents = fullPriceBreakdownCents(this.request);

        this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
        this.$rootScope.$emit('TOGGLE_NAV', { hide: true });
      })
      .catch((error) => {
        if (get(error, 'data.redirect')) {
          this.$location.path(error.data.redirect);
        } else {
          this.unwrapError(error);
        }
      });
  }

  private getClient(request, users) {
    // determine which client to display, for now display primary contact
    if (!request) {
      return;
    }
    const client = primaryEventContact(request);
    return users.find(user => user._id === client.user._id.toString());
  }

  private setListeners = () => {
    this.$scope.$on('$locationChangeStart', () => {
      this.$scope.$emit('TOGGLE_NAV');
      this.$rootScope.$emit('SHOW_FOOTER');
    });

    this.$scope.$on('READY_TO_PAY', (ev, params) => {
      ev.stopPropagation();
      if (!params.conversation) {
        return;
      }

      this.$location.path(`/client/conversation/${ params.conversation._id }`);
    });
  }
}

export const TopLevelRequestPreviewComponent = {
  template: `
    <request-preview client="$ctrl.client" assignee="$ctrl.assignee" request="$ctrl.request" venue-url="$ctrl.venueUrl" user="$ctrl.user" messages="$ctrl.messages" attachments="$ctrl.attachments" ng-if="$ctrl.request"></request-preview>
  `,
  controller: TopLevelRequestPreview
};
