export const RATINGS_FILTER = ['4+ stars on Yelp', '4+ stars on Google'];
export const MICHELIN_STARS = ['1 Michelin Star', '2 Michelin Stars', '3 Michelin Stars'];
export const PRICE = ['$', '$$', '$$$', '$$$$'];
export const PRICE_POUND = ['£', '££', '£££', '££££'];
export const STYLE_FILTER = ['Seated', 'Standing', 'Standing + Seated'];

export const MAPPED_PRICE = {
    $: 1,
    $$: 2,
    $$$: 3,
    $$$$: 4,
    '£': 1,
    '££': 2,
    '£££': 3,
    '££££': 4
};
