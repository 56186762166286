import { filter, round, set, some } from 'lodash';

export default ['$api', function($api) {
  const ret = {
    filterNewOverages,
    hasNewOverages,
    openOveragesModal,
    saveOverages,
    setOverageAmountCents,
    _isNew
  };
  return ret;

  /**
   * Opens overages modal for both admins and guests. We've pulled this
   * into a factory function because both `requestPaymentDetailsGuest.js`
   * and `requestPaymentDetailsHost.js` make use of the modal, but both rely
   * on different logic to open the modal.
   *
   * @param {User} user, user service (NOT `$user.$`, but `$user` itself)
   * @param {Function} modal, modal to open
   * @param {Request} request, an Event Request
   * @return {Promise}
   */
  function openOveragesModal(user, modal, request) {
    return modal(request, user).
      then(function(data) {
        return data;
      });
  }

  /**
   * Takes a dollar amount and sets an overage's amount in cents
   *
   * @param {Overage} overage
   * @param {Number|String} dollars
   * @mutates `overage`
   */
  function setOverageAmountCents(overage, dollars) {
    dollars = dollars || 0;
    const cents = round(parseFloat(dollars) * 100);
    set(overage, 'amountCents', cents);
  }

  /**
   * Saves overages on the backend
   *
   * @param {Request} request
   * @param {Array} overages
   * @return {Promise}
   */
  function saveOverages(request, overages) {
    return $api.Admin.Requests.saveOverages(request._id, overages);
  }

  /**
   * Filters array of overages and retrieves only those that need to be submitted
   *
   * @param {Array} overages
   * @return {Array}
   */
  function filterNewOverages(overages) {
    return filter(overages, _isNew);
  }

  /**
   * Determines if an array of overages has any new overages to save
   *
   * @param {Array} overages
   * @return {Boolean}
   */
  function hasNewOverages(overages) {
    return some(overages, _isNew);
  }

  /**
   * Determines if a given overage is new (can be submitted to the backend)
   *
   * @param {Overage}
   * @return {Boolean}
   */
  function _isNew(overage) {
    return overage.new;
  }
}];
