
module.exports = ['$user', function($user) {
  return {
    template: require('./secondary-nav.jade'),
    scope: {
      active: '@',
      back: '@'
    },
    link: function(scope, element, attrs) {
      scope.text = 'Upgrade';
      scope.subtext = 'Upgrade to Pro';

      $user.$waitFor('LOGGED_IN', function() {
        scope.user = $user;
        scope.text = scope.user.$.accountTier ? `Team (${scope.user.$.accountTier})` : 'Upgrade';
        scope.subtext = scope.user.$.accountTier && scope.user.$.accountTier !== 'Free' ? 'Manage ' + scope.user.$.accountTier + ' account' : 'Upgrade to Pro';
      });
      $user.$waitFor('NOT_LOGGED_IN', function() {
        element.hide();
      });

      scope.isActive = function(str) {
        return str === scope.active;
      };
    }
  };
}];
