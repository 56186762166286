import { get, reduce } from 'lodash';
/**
 * braintreeHelpers factory
 * Helper functions to keep payment functions DRY. Most fns take in scope as a parameter and return a function to
 * allow reuse across controllers/directives
 */

export default ['$timeout', '$api', function($timeout, $api) {
  const ret = {
    generateHostedFieldsOptions,
    handleOnFieldEvent,
    onError,
    setProcessingState
  };

  /**
   * Handles any potential errors returned from braintree on field changes
   *
   * @api public
   * @param {VM} vm, view-model
   * @return {Function}
   */
  function onError(vm) {
    return function(error) {
      if (vm.paymentState) {
        vm.paymentState = 'READY';
      }
      vm.processing = false;
      if (error.type === 'VALIDATION') {
        if (error.details) {
          const invalidKeys = get(error, 'details.invalidFieldKeys', []);
          vm.error = reduce(invalidKeys, function(acc, curr) {
            acc[curr] = true;
            return acc;
          }, {});
        } else {
          vm.error = { general: 'Please enter a valid card number, CVV, and expiration date' };
        }
        $timeout();
      }
    };
  }

  /**
   * Handles any event that occurs when input changes
   *
   * @api public
   * @param {VM} vm
   * @return {Function}
   */
  function handleOnFieldEvent(vm: any) {
    return function(event: any) {
      if (event.isPotentiallyValid && event.type !== 'focus') {
        const fieldKey: any = get(event, 'target.fieldKey');
        if (vm.error && vm.error[fieldKey]) {
          vm.error[fieldKey] = false;
          $timeout();
        }
      }
      if (event.target.fieldKey === 'number') {
        _handleCreditCardNumberEvent(vm, event);
      }
      if (event.isFocused) {
        $(event.target.container).addClass('payment-outline');
      } else {
        $(event.target.container).removeClass('payment-outline');
      }
    };
  }

  /**
   * Generates a reusable hosted fields object that can be reused whenever
   * braintree is initialized
   *
   * @api public
   * @param {VM} vm, the view-model object ($scope, vm, etc)
   * @return {Object}
   */
  function generateHostedFieldsOptions(vm) {
    return {
      number: {
        selector: '#card-number',
        placeholder: 'Credit Card Number'
      },
      cvv: {
        selector: '#cvv',
        placeholder: 'CVV'
      },
      expirationDate: {
        selector: '#expiration-date',
        placeholder: 'MMYY'
      },
      postalCode: {
        selector: '#postal-code',
        placeholder: '11111'
      },
      onFieldEvent: ret.handleOnFieldEvent(vm)
    };
  }

  /**
   * Handles the braintree response on the credit card number change event
   *
   * @api private
   * @param {VM} vm, view-model
   * @param {Event} event
   */
  function _handleCreditCardNumberEvent(vm, event) {
    if (event.card && event.isEmpty) {
      vm.newCardType = null;
      $timeout();
    } else if (event.card && vm.newCardType !== event.card) {
      vm.newCardType = event.card.type;
      $timeout();
    }
  }

  function setProcessingState(vm, bool) {
    vm.processing = bool;
  }

  return ret;
}];
