module.exports = ['$window', function($window) {
  return {
    restrict: 'E',
    scope: {
      options: '=',
      formatOption: '&',
      isSelected: '&',
      isDisabled: '&',
      onSelect: '&',
      selectPlaceholder: '@'
    },
    transclude: true,
    template: require('./multi-select.jade'),
    link: function(scope, element, attrs) {
      element.find('.multi-handle').click(function(ev) {
        element.find('.multi-dropdown').slideToggle('fast').
          toggleClass('test-hidden');
        ev.stopPropagation();
      });

      element.click(function(ev) {
        ev.stopPropagation();
      });

      $window.$('body').click(function() {
        element.find('.multi-dropdown').slideUp('fast').
          toggleClass('test-hidden');
      });
    },
    controller: ['$scope', function($scope) {
      setTimeout(function() {
        $scope.$emit('MultiSelectController');
      }, 0);
    }]
  };
}];
