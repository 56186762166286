/**
 * @name selection-modal
 * @description modal replacement for select boxes
 */

import { get } from 'lodash';

export const neighborhoodModal = ['ngDialog', function (ngDialog) {
  return function ({ selectedArea, currentChoices, neighborhoodsMap }) {
    return ngDialog.open({
      template: require('./neighborhood-modal.jade'),
      plain: true,
      className: 'ngdialog-theme-dropdown-neighborhoods',
      controllerAs: '$ctrl',
      controller: ['$scope', function ($scope) {
        this.init = () => {
          this.options = { multi: true };

          this.area = JSON.
          parse(JSON.stringify(neighborhoodsMap[selectedArea]));
          this.currentChoices = currentChoices ? [ ...currentChoices ] : [];
          for (const key in this.area) {
            this.area[key] = get(this.area, [key, 'neighborhoods']);
          }
        };
        this.init();

        this.submitChoices = () => {
          $scope.closeThisDialog({ currentChoices: this.currentChoices });
        };

      }]
    }).closePromise;
  };
}];

