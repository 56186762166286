import { filter, get, includes, some } from 'lodash';
export default function() {
  const ret = {
    filterNonDeleted,
    getFbAddOns,
    getNonFbAddOns,
    getNonTaxableAddOns,
    hasAddOns,
    hasDrinks,
    getVisibleDrinks
  };
  return ret;

  /**
   * Gets add ons that are f&b related
   *
   * @param {Request}
   * @return {Array}
   */
  function getFbAddOns(request) {
    const addOns = ret.filterNonDeleted(request.data.addOns);
    return filter(addOns, a => a.taxType === 'FOOD_AND_BEVERAGE');
  }

  /**
   * Gets addons that are not f&b related, and nontaxable addons as well if desired
   *
   * @param {Request} request
   * @param {Boolean} includeNonTaxable
   * @return {Array}
   */
  function getNonFbAddOns(request, includeNonTaxable) {
    const addOns = ret.filterNonDeleted(request.data.addOns);
    const acceptableTaxTypes = ['NON_FOOD_AND_BEVERAGE'];
    if (includeNonTaxable) {
      acceptableTaxTypes.push('NONE');
    }
    return filter(addOns, a => includes(acceptableTaxTypes, a.taxType));
  }

  function getNonTaxableAddOns(request) {
    const addOns = ret.filterNonDeleted(request.data.addOns);
    return filter(addOns, a => a.taxType === 'NONE');
  }

  /**
   * Get visible drinks only
   *
   * @param {Request}
   * @return {Array}
   */
   function getVisibleDrinks(request) {
     return filterNonDeleted(request.data.drinks);
   }

  /**
   *  Determines if a request has any non-deleted addOns
   *
   * @param {Request}
   * @return {Boolean}
   */
  function hasAddOns(request) {
    const addOns = get(request, 'data.addOns');
    return _hasNonDeletedItems(addOns);
  }

  /**
   * Determines if a request has any non-deleted drinks
   *
   * @param {Request} request
   * @return {Boolean}
   */
  function hasDrinks(request) {
    const drinks = get(request, 'data.drinks');
    return _hasNonDeletedItems(drinks);
  }

  /**
   * Filters out deleted items from array
   *
   * @api private
   * @param {Array} array
   * @return {Array}
   */
  function filterNonDeleted(arr) {
    return arr.filter(elem => !elem.isDeleted);
  }

  /**
   * Determines if an array has any non-deleted items
   *
   * @param {Array} Array
   * @return {Boolean}
   */
  function _hasNonDeletedItems(arr) {
    if (!arr || !arr.length) {
      return false;
    }
    return some(arr, r => !r.isDeleted);
  }
}
