import debounce from 'lodash/debounce';

// Types
import { ApiService } from 'spc/shared/api/api.service';
import { RawLead } from 'spc/lib/database/types/lead';

class LeadSearchController {
  leads: RawLead[] = [];
  request: any;
  fetchData: () => any;
  recoId: string;
  toggleActions: (recoId) => boolean;
  loaderState: string;
  ui: {
    loading: boolean
  } = {
      loading: false
    };
  name: string = '';

  constructor(private $api: ApiService, private unwrapError, private toast) {
    'ngInject';
  }

  debouncedSearch = debounce((text: string) => {
    return this.search(text);
  }, 300);

  search = (text: string) => {
    this.ui.loading = true;
    return this.$api.Admin.Leads.get(0, { text })
    .then((response) => {
      this.leads = response.data.data.leads;
      this.ui.loading = false;
    })
    .catch(error => this.unwrapError(error));
  }

  setLead = (lead) => {
    this.toggleActions(this.recoId);
    return this.$api.Admin.Requests.changeLead({ request: this.request, leadId: lead._id.toString() })
      .then(() => {
        this.toast.goodNews('Proposal Transfered Successfully');
        return this.fetchData();
      }).catch((error) => {
        this.toast.badNews('Error in transfering proposal');
        return this.unwrapError(error);
      });
  }
}

export const LeadSearchComponent = {
  template: require('./lead-search.component.jade'),
  controller: LeadSearchController,
  bindings: {
    request: '<',
    fetchData: '&',
    recoId: '<',
    toggleActions: '='
  }
};
