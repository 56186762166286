import { SpaceData, VenueData } from 'server/api/admin/metrics/models';
import sortBy from 'lodash/sortBy';
const DEFAULT_SORT_FIELD = 'numberOfRecommendations';

class MetricsTableController {
 data: SpaceData | VenueData;
 numberDisplayed = 10;
 displayedData: any[];
 sort: {
   field: string;
   order: number
 } = { field: DEFAULT_SORT_FIELD, order: 1 };

 constructor() {
   'ngInject';
 }

  $onChanges = (changes) => {
    if (!this.data) {
      return;
    }
    this.createDisplayedData();
  }

  toggleSortedField = (field) => {
    if (this.sort.field === field) {
      this.sort.order *= -1;
    } else {
      this.sort.field = field;
      this.sort.order = 1;
    }

    this.displayedData = sortBy(this.displayedData, [data => this.sort.order * data[field]]);
  }

  createDisplayedData = () => {
    this.displayedData = Object.keys(this.data)
      .map((name: string) => Object.assign({ name }, this.data[name]))
      .sort((first, second) => {
        if (first[this.sort.field] > second[this.sort.field]) {
          return -1 * this.sort.order;
        } else if (first[this.sort.field] < second[this.sort.field]) {
          return 1 * this.sort.order;
        } else {
          return 0;
        }
      });
  }

  showMore = () => {
    this.numberDisplayed += 10;
  }
}

export const MetricsTableComponent = {
  template: require('./metrics-table.component.jade'),
  controller: MetricsTableController,
  bindings: {
    data: '<'
  }
};
