// NPM Dependencies
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import { ApiService } from 'spc/shared/api/api.service';

class ClientAddVenuesController {
  lead: any;
  ui: { view: 'search' | 'book' } = { view: 'search' };
  menus: any[];
  drinks: any[];
  allMenus: any;
  allDrinks: any;
  venue: any;
  venues: any[];
  close: ({ request }: { request?: string }) => void;
  searchedCity;
  constructor(
    private $api: ApiService,
    private unwrapError,
    private photoDialog,
    private $cloudinary,
    private ENUMS,
    private recoRequestModal,
    ) {
    'ngInject';
  }

  $onInit = () => {
    this.searchedCity = this.ENUMS.acceptableUserCities.find(city => city.value === this.lead.request.city);
  }

  public isValidLocation = () => {
    if (this.searchedCity) {
      return true;
    }
    return false;
  }

  public findSearchLocation = () => {
    if (!this.isValidLocation()) {
      return;
    }
    return get(this.searchedCity, 'city');
  }

  public readableSearchLocation = () => {
    if (!this.isValidLocation()) {
      return;
    }
    return get(this.searchedCity, 'name');
  }

  public createCityUrl = () => {
    if (!this.isValidLocation()) {
      return;
    }
    return get(this.searchedCity, 'slug').replaceAll(' ', '-').replaceAll('.', '');
  }

  public openPhotoDialog = (photos, photo): Promise<any> => {
    return this.photoDialog({ photos: photos, initialPhoto: photo });
  }

  debouncedVenueSearch = debounce((name) => {
    if (name.length < 3) {
      return;
    }
    return this.$api.Venues.get(null, { status: ['Published'], name, menus: true, drinks: true, location: this.findSearchLocation(), limitToVisible: true })
      .then((response: any) => {
        this.venues = get(response, 'data.data.venues', []);
        this.allMenus = get(response, 'data.data.menus', []);
        this.allDrinks = get(response, 'data.data.drinks', []);
      })
      .catch(error => this.unwrapError(error));
  }, 300);

  selectVenue = (venue ) => {
    this.venue = venue;
    this.menus = this.allMenus[this.venue._id];
    this.drinks = this.allDrinks[this.venue._id];
    if (venue.status === 'Lite') {
      this.recoRequestModal({ origin: 'client-add-venue', venue: venue, lead: this.lead })
        .then((data) => {
          return this.close({ request: venue.status });
        });
    } else {
      this.ui.view = 'book';
    }
  }
}

export const ClientAddVenuesComponent = {
  controller: ClientAddVenuesController,
  template: require('./client-add-venues.component.jade'),
  bindings: {
    lead: '<',
    close: '&'
  }
};
