import { GetFeaturedVenuesResponse } from 'lib/server/api/featured-venue/models';

export class FeaturedVenuesRouter {
  BASE_URL: string;

  constructor(API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${ API_ROOT }/featured-venues`;
  }

  get = (city): ng.IPromise<GetFeaturedVenuesResponse> => {
    return this.$http.get(`${this.BASE_URL}/${city}`)
      .then((response: { data: GetFeaturedVenuesResponse }) => response.data);
  }
}
