import { RecosMapService } from './recos-map.service';

class RecoMapComponent {
  constructor(private recosMapService: RecosMapService, private ENUMS, private $element) {
    'ngInject';
  }

  $postLink() {
    const recosMapConfig = {
      scrollwheel: false,
      mapTypeControl: false,
      streetViewControl: false,
      panControl: false,
      zoomControl: true,
    };

    this.recosMapService.init($(this.$element.find('.reco-map-content'))[0], recosMapConfig);
  }
}

export default {
  template: require('./recos-map.jade'),
  controller: RecoMapComponent
};
