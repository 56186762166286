

import unset = require('lodash/unset');
import { RawLead } from 'database/types/lead';
import { DBaseUser } from 'spc/lib/database/types/base-user';

// const NUM_LEADS_PER_PAGE = 50;

interface MembersArray {
  member: DBaseUser;
  checked: boolean;
}

class AccountMembersTableViewFiltersController {
  search: {
    city?: string,
    currentStatuses?: string[],
    owner?: string[],
    eventDate?: { from?: Date; to?: Date };
  };
  currentPage: number;
  statuses: string[] = ['In Progress', 'Booked', 'Closed', 'Completed'];
  ui: { showOwnerMultiselect: boolean; showStatusMultiselect: boolean; } = {
    showStatusMultiselect: false,
    showOwnerMultiselect: false
  };
  members: any[];
  cities: string[];
  locations: any[] = [];
  newMembersArray: MembersArray[];
  searchLeads: ({ searchParams }: { searchParams: any }) => any;
  clearAllFilters: () => any;


  constructor(
    private unwrapError,
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.newMembersArray = this.members.map(member => member.fullName );
  }

  toggleStatusMultiselectDisplay = () => {
    this.ui.showStatusMultiselect = !this.ui.showStatusMultiselect;
  }

  toggleOwnerMultiselectDisplay = () => {
    this.ui.showOwnerMultiselect = !this.ui.showOwnerMultiselect;
  }

  hideStatusMultiSelect = (selectedOptions) => {
    this.search.currentStatuses = selectedOptions;
    this.ui.showStatusMultiselect = false;
  }

  hideOwnerMultiSelect = (selectedOptions) => {
    this.search.owner = selectedOptions;
    this.ui.showOwnerMultiselect = false;
  }

  startSearch = () => {
    this.searchLeads({ searchParams : this.search })
    .catch(error => this.unwrapError(error));
  }

}

export const AccountMembersTableViewFiltersComponent = {
  template: require('./account-members-table-view-filters.component.jade'),
  controller: AccountMembersTableViewFiltersController,
  bindings: {
    searchLeads: '&',
    cities: '<',
    search: '=',
    toggleDisplay: '&',
    members: '<',
    clearAllFilters: '&'
  }
};
