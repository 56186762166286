import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import get from 'lodash/get';
import { integerToCivilianTime } from 'common/dist/time';

export class TimeslotViewService {
  selectedTimeslot: any;
  editing: boolean = false;
  originalTimeslot: any;
  editablePaths = [
    'name',
    'terms.foodBeverageMin',
    'terms.roomFeeCents',
    'terms.extraHourFee',
    'days',
    'from',
    'to'
  ];
  editingNewTimeSlot: boolean = false;
  executeOnTimeSlotEdit: any[] = [];

  constructor() {
    'ngInject';
  }

  selectTimeslot = (timeSlot) => {
    this.selectedTimeslot = timeSlot;
    this.editing = true;
    this.originalTimeslot = cloneDeep(this.selectedTimeslot);
  }

  deselectTimeslot = () => {
    this.resetValues();
    this.selectedTimeslot = null;
    this.editing = false;
    this.editingNewTimeSlot = false;
  }

  isEditing = (): boolean => {
    return !!this.selectedTimeslot;
  }

  isSelectedTimeslot = (timeslot): boolean => {
    if (!this.selectedTimeslot) {
      return false;
    }
    return timeslot._id === get(this, 'selectedTimeslot._id');
  }

  resetValues = () => {
    this.editablePaths.forEach((path) => {
      set(this.selectedTimeslot, path, get(this.originalTimeslot, path));
    });
  }

  saveChanges = () => {
    this.originalTimeslot = null;
    this.selectedTimeslot = null;
    this.editingNewTimeSlot = false;
    this.editing = false;
  }

  areSlotsOverlapping = (a, b) => {
    let onSameDay = false;

    for (let i = 0; i < 7; ++i) {
      if (a.days[i] && a.days[i] === b.days[i]) {
        onSameDay = true;
        break;
      }
    }
    if (!onSameDay) {
      return false;
    }

    const endOfFirstSlot = a.to < a.from ? a.to + 2400 : a.to;
    const endOfSecondSlot = b.to < b.from ? b.to + 2400 : b.to;
    return a.from < endOfSecondSlot && b.from < endOfFirstSlot;
  }

  isInvalidEndTime = (timeslot, endTime) => {
    const startTime = timeslot.from;
    if (!isFinite(startTime)) {
      return false;
    }

    const time  = endTime >= 0 && endTime <= 400 ? endTime + 2400 : endTime;
    return isFinite(time) && time <= startTime;
  }

  toggleSlotDay = (dayIndex) => {
    this.selectedTimeslot.days[dayIndex] = !this.selectedTimeslot.days[dayIndex];
  }

  registerSpaceEdit = () => {
    this.executeOnTimeSlotEdit.forEach(func => func());
    return this;
  }

  displayEndTime = (endTime) => {
    if (!endTime) {
      return;
    }
    return integerToCivilianTime(endTime >= 2400 ? endTime - 2400 : endTime);
  }
}
