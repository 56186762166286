import { SearchQuery } from 'server/api/admin/venues/models';

class VenueFilterSidebarController {
  statuses: string[];
  search: SearchQuery;
  searchVenues: ({ pageNum }: { pageNum: number }) => any;
  ui = { displayCitySelector: false };
  assignees: any[];

  hideFilters: () => any;
  searchRequests: ({ searchParams }: { searchParams: any }) => any;

  constructor(private ENUMS) {
    'ngInject';
    this.statuses = this.ENUMS.venueStatuses;
  }

  public closeCitySelector = (selectedCities: string[]) => {
    this.search.cities = selectedCities;
    this.ui.displayCitySelector = false;
  }

  public toggleCityDisplay = () => {
    this.ui.displayCitySelector = !this.ui.displayCitySelector;
  }

  public setSearchDueDate = (date) => {
    this.search.dueDate = date.toDate();
  }

  public getOwnerName = (ownerId: string) : string => {
    const owner = this.assignees.find(admin => admin._id.toString() === ownerId.toString());
    return owner.fullName || '--';
  }
}

export const VenueFilterSidebarComponent = {
  template: require('./venue-filter-sidebar.component.jade'),
  controller: VenueFilterSidebarController,
  bindings: {
    assignees: '<',
    search: '<',
    searchVenues: '&',
    hideFilters: '&',
    cities: '<'
  }
};
