import { get } from 'lodash';

module.exports = function($scope, $routeParams, Record, Model) {
  $scope.Model = Model;
  $scope.Record = Record.data.data;
  $scope.coverIndex = get($scope, 'Record.data.coverIndex');
  $scope.$routeParams = $routeParams;
  $scope.buttonText = 'I\'m done';

  $scope.$on('PHOTO_UPLOADING', function() {
    $scope.buttonText = 'Uploading...';
  });

  $scope.$on('PHOTO_UPLOADED', function() {
    $scope.buttonText = 'I\'m finished';
  });

  $scope.savePhotos = function(photos, coverIndex) {
    var data = {
      _id : $scope.Record._id.toString(),
      data: {
        photos: photos,
        coverIndex: coverIndex
      }
    }

    $scope.Model.save(data);
  };

};

module.exports.$inject = ['$scope', '$routeParams', 'Record', 'Model'];
