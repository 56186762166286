import { debounce, get } from 'lodash';

// Constants
const DEBOUNCE_LIMIT = 300;

let service;
export default class AdminService {
  constructor($api, $q) {
    'ngInject';
    service = this;
    service.$api = $api;
    service.$q = $q;
  }

  /**
   * Search for a venue by name
   */
  getVenueByName(name, query) {
    if (!name) {
      return service.$q.resolve();
    }
    return service.$api.Admin.Venues.findOneByName(name, query)
      .then(response => {
        return get(response, 'data.data.venues');
      });
  }

  getClientByText(clientString) {
    if (!clientString) {
      return;
    }

    return service.$api.Admin.Users.getMultipleByText(clientString)
      .then(response => {
        return get(response, 'data.users');
      });
  }
  /**
   * Enforce admin for admin components
   * 
   * @param {UserService}
   * @param {resultOptions}
   */
  enforceAdmin(UserService, resultOptions) {
    if (!UserService) {
      throw new Error('No UserService to detect if user is authorized');
    }
    if (!resultOptions.success) {
      throw new Error('Success handler must be passed to enforceAdmin function');
    }

    if (!resultOptions.error) {
      throw new Error('Error hander must be passed to enforceAdmin function');
    }

    UserService.$waitFor('LOGGED_IN', resultOptions.success);
    UserService.$waitFor('NOT_LOGGED_IN', resultOptions.error);
  }
}
