import JSONCrush from '../../../../database/helpers/JSONCrush';

// Sixplus Dependencies
import { ApiService } from 'spc/shared/api/api.service';
import { UserService } from 'spc/services/user.service';

// External Dependencies

const DEFAULT_LOCATION = `New York`;

interface LandingPageVenue {
  slug: string;
  url: string;
  name: string;
}

interface LandingPageExperience {
  experienceName: string;
  vendorName: string;
  experienceType: string;
  imgUrl?: string;
  link: string;
}

export default {
  template: require('./home-landing-page.jade'),
  controller: class HomeLandingPageComponent {
    location: string;
    searchLocation;
    locations;
    date: number;
    guests: number;
    flickityOptions: {
      wrapAround: boolean;
      autoPlay: number;
    };
    displayedVenues: LandingPageVenue[] = [];
    allowDateInput: boolean = false;
    isPremiumMember: boolean;
    loading: boolean = false;
    hideBanner: boolean = true;
    constructor(
      private $element: ng.IRootElementService,
      private $cloudinary,
      private $api: ApiService,
      private unwrapError,
      private $httpParamSerializer,
      private $location,
      private ENUMS,
      private $user: UserService) {
      'ngInject';
      this.flickityOptions = {
        wrapAround: true,
        autoPlay: 5000
      };
    }


    $onInit = () => {
      this.locations = this.ENUMS.acceptableUserCities;
      this.location = DEFAULT_LOCATION;
      this.$user.isPremiumMember()
        .then((res) => {
          this.isPremiumMember = res;

          this.hideBanner = this.isPremiumMember;
        });
      return this.$api.Landing.getLocation()
        .then((data: { city?: string }) => {
          this.locations.forEach((location) => {
            if (location.name === data.city ) {
              if ((location.premiumCity && this.isPremiumMember) || !location.premiumCity) {
                this.location = data.city;
              }
            }
          });

        if (!this.isPremiumMember) {
            const freeCitiesList = this.locations.filter(location => location.premiumCity === false);
            const premiumCitiesList = this.locations.filter(location => location.premiumCity === true);
            this.locations = [...freeCitiesList, ...premiumCitiesList];
        }
          this.setLocation(this.location);
        })
        .catch((error) => {
          this.location = DEFAULT_LOCATION;
          this.unwrapError(error);
        });
    }

    displayVenues = () => {
      this.loading = true;
      this.displayedVenues = [];
      this.$api.FeaturedVenues.get(this.searchLocation.city)
        .then((data) => {
          this.loading = false;
          this.displayedVenues = data.featuredVenues;
        });
    }

    getColumnCities = (column: number, totalColumns: number) => {
      const cityEachColumnLength = Math.ceil(this.ENUMS.acceptableUserCities.length / totalColumns);
      return this.ENUMS.acceptableUserCities.slice((column - 1) * cityEachColumnLength, column * cityEachColumnLength);
    }

    setDate = (date) => {
      this.date = date.format('YYYY-MM-DD');
    }

    setLocation = (location) => {
      this.location = location;
      this.searchLocation = this.locations.find(loc => loc.name === location);
      this.displayVenues();
      this.allowDateInput = true;
    }

    search = () => {
      const searchParams = {
        filters: {
          date: this.date,
          guests: {
            max: this.guests
          }
        }
      };
      const query = {
        guestCount: this.guests
      };
      let url: string;

      if (this.location === 'Virtual') {
        url = `/experiences`;
        this.$location.path(url).search(query);
      } else {
        const qs = JSONCrush.crush(JSON.stringify(searchParams));
        url = `/search/${ this.searchLocation.slug.replaceAll(' ', '-') }?filters=${ qs }`;
        this.$location.url(url);
      }
    }

    searchCards = () => {
      window.location.href = `/search/${ this.searchLocation.slug.replaceAll(' ', '-') }`;
    }

    $onDestroy() {
      $('meta[name="robots"]').remove();
    }

    fetchAndConvertUrl(url, type) {
      return this.$cloudinary.fetchAndConvertUrl(url, type);
    }

    srcSet(url) {
      return this.$cloudinary.srcSet(url);
    }

    shouldBeDisabled(location) {
      if (!location.premiumCity || (location.premiumCity && this.isPremiumMember)) {
        return false;
      }
      return true;
    }
  }
};
