import { get } from 'lodash';
import moment from 'moment';
import { DLead } from 'spc/lib/database/types/lead';
class ArchiveEventController {

  archiveReasons: string[];
  archiveData: {
    archiveReason?: string;
    alternativeVenue?: string;
  };
  close: (isArchived: boolean) => void;

  lead: DLead;
  constructor(private $api, private ENUMS) {
    'ngInject';
  }
  $onInit() {
    this.archiveReasons = this.ENUMS.concierge.archiveReason;
  }
  needVenueName = () => {
    return ['I Found Another Venue (Off SixPlus)', 'I Booked A Venue Offline'].includes(get(this.archiveData, 'archiveReason'));
  }

  saveArchiveData = () => {
    this.$api.Leads.archive({ lead: this.lead, archiveData: this.archiveData })
      .then((res) => {
        res.updatedLead.eventDate = moment(res.updatedLead.request.date).utc();
        this.close({ isArchived: true, updatedLead: res.updatedLead });
      });
  }
}

export const ArchiveEventComponent = {
  template: require('./archive-event.component.jade'),
  controller: ArchiveEventController,
  bindings: {
    close: '&',
    lead: '<'
  }
};
