import emailValidator from 'email-validator';
import { get, filter, includes, some, debounce } from 'lodash';

module.exports = ['ngDialog', function(ngDialog) {
  return function(request) {
    return ngDialog.open({
      template: require('./assign-client-modal.jade'),
      className: 'ngdialog-theme-small info-modal',
      overlay: true,
      plain: true,
      controller: ['$scope', '$api', function($scope, $api, unwrapError) {
        $scope.request = request;
        $scope.searchUser = debounce(searchUser, 100, null);

        function searchUser(text) {
          if (!text || text.length < 3) {
            return;
          }

          return $api.Admin.Search.allUsers(text).
            then(function(response) {
              let users = get(response, 'data.data', []);
              users = filter(users, user => !includes(user.roles, 'Host'));

              $scope.searchUsers = users;
              if ($scope.selectedUser && !some(users, user => user._id === $scope.selectedUser._id.toString())) {
                $scope.selectedUser = null;
              }
            });
        }

        $scope.selectUser = function(user) {
          const userId = user._id.toString();
          if (userId === request.clients[0]._id.toString()) {
            $scope.selectedUser = null;
            return;
          }

          if ($scope.selectedUser && $scope.selectedUser._id.toString() === userId) {
            $scope.selectedUser = null;
            return;
          }

          $scope.selectedUser = user;
        };

        $scope.canSubmit = function() {
          return $scope.client &&
            $scope.client.firstName &&
            $scope.client.lastName &&
            emailValidator.validate($scope.client.email);
        };

        $scope.assignClientExisting = function(user) {
          $api.Admin.Requests.assignClientExisting(request, user._id).
            then(handleUpdate).
            catch(unwrapError);
        };

        $scope.assignClientNew = function() {
          $api.Admin.Requests.assignClientNew(request, $scope.client).
            then(function(response) {
              handleUpdate(response, true);
            }).
            catch(unwrapError);
        };

        function handleUpdate(response, newUser) {
          const update = get(response, 'data.data');
          $scope.closeThisDialog({ update: update, new: newUser });
        }
      }]
    }).closePromise;
  };
}];
