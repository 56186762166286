import { cloneDeep, get } from 'lodash';
import { DAccount } from 'spc/lib/database/types/accounts';
import { RawBaseUser } from 'spc/lib/database/types/base-user';

class AddAccountMemberController {
  user: RawBaseUser;
  searchValue: string;
  filteredClients: RawBaseUser[];
  connections: RawBaseUser[];
  clients: RawBaseUser[];
  loading: boolean;
  account: DAccount;
  memberLimit: number;
  errorMessage: string;
  close: (revised: DAccount) => void;

  constructor(private $api, private unwrapError, private $scope, private $user) {
    'ngInject';
    this.loading = true;
  }

  $onInit() {
    if (!this.user) {
      this.user = this.$user.$;
    }
    this.getConnectedUsers(this.user);
  }

  getConnectedUsers(user) {
     return this.$api.BaseUsers.getConnections(user._id.toString())
      .then((res) => {
        this.clients = res.users;
        this.connections = this.clients;
        this.filteredClients = this.connections.filter(u => u._id.toString() !== this.user._id.toString());
        this.loading = false;
      })
      .catch(error => this.unwrapError(error));
  }

  isOnAccount({ user }) {
    const accountMembers = this.account.members as RawBaseUser[];
    return !user._id || accountMembers.some((u) => {
      return u._id.toString() === user._id.toString();
    });
  }

  inviteUser = ({ user }) => {
    const query = { add: true };
    this.$api.MembershipAccount.updateAccountMembers({ accountId: this.account._id.toString(), user, query })
      .then((res) => {
        if (res.account._id) {
          this.account.members = [...this.account.members, ...res.account.members];
          this.close(res);
        }
      })
      .catch((error) => {
        if (error.data) {
          this.errorMessage = error.data.error.message;
        }
        this.unwrapError(error);
      });
    }
  }

export const AddAccountMemberComponent = {
  template: require('./add-account-member.component.jade'),
  controller: AddAccountMemberController,
  bindings: {
    close: '<',
    account: '<',
    user: '<',
    memberLimit: '<'
  }
};
