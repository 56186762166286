'use strict';
const _includes = require('lodash/includes');
const BookingRequestHelper = require('common/dist/virtuals/BookingRequest');
const ENUMS = require('common/dist/enums');

module.exports = {
  validateReconcile: validateReconcile,
  validateSubmitManualDeposit: validateSubmitManualDeposit,
  validateSubmitManualFinalBalance: validateSubmitManualFinalBalance,
  checkStateCustom: checkStateCustom,
  validateSubmitIntermediatePayment: validateSubmitIntermediatePayment
};

// Public functions

/**
 * Allows custom request state check in case we need to customize it for a specific use case
 *
 * @params {Request} request
 * @params {Array} states
 * @return {Object}
 */
function checkStateCustom(request, states) {
  if (!Array.isArray(states)) {
    states = [states];
  }
  return _stateIncludedDetails(request, states);
}

/**
 * Determine if a request can be reconciled manually in its current state
 *
 * @params {Request} request
 */
function validateReconcile(request) {
  const acceptableStates = ['CONCLUDED'];
  return _stateIncludedDetails(request, acceptableStates);
}

/**
 * Determines if a request is in the correct state for a manual final balance charge to occur
 *
 * @api public
 * @params {Request} request
 * @return {Object}
 */
function validateSubmitManualFinalBalance(request) {
  const acceptableStates = ['DEPOSIT', 'ALTERATION_ACCEPTED_GUEST', 'ALTERATION_ACCEPTED_HOST', 'POST_DEPOSIT_ALTERATION'];
  return _stateIncludedDetails(request, acceptableStates);
}

/**
 * Determines if a request is in the correct state for a manual deposit payment to be submitted
 *
 * @params {Request} request
 * @return {Object}
 */
function validateSubmitManualDeposit(request) {
  const acceptableStates = ['ACCEPTED_GUEST', 'ACCEPTED_HOST', 'REQUEST', 'COUNTEROFFER', 'PRE_DEPOSIT_ALTERATION'];
  return _stateIncludedDetails(request, acceptableStates);
}

function validateSubmitIntermediatePayment(request) {
  const acceptableStates = ENUMS.bookingRequestState.refundStates;
  return _stateIncludedDetails(request, acceptableStates);
}

// Private functions
function _stateIncludedDetails(request, states) {
  return { valid: _includes(states, request.state), acceptableStates: states };
}
