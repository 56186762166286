// NPM Dependencies
import * as angular from 'angular';
import facebook from 'facebook-oauth-agent';
import linkedin from 'linkedin-oauth-agent';
import { get, includes, some } from 'lodash';

// SixPlus dependencies (Modules)
import unwrapError from './services/unwrapError';
import ambianceStyleService from './services/ambianceStyleService';
import { convertURL } from './services/convertUrl';
import ToggleService from './services/ToggleService';
import GooglePlacesFactory from './services/googlePlaces';
import { UserService } from './services/user.service';
import { ServerSentEventsService } from './services/server-sent-events.service';
import { VenueValidator } from './services/venueValidator';

// SixPlus dependencies (CommonJS)
const constants = require('../../shared/constants');

const m = angular.module('services', []);

module.exports = m;

m.factory('$jwt', function($window) {
  return {
    get: function() {
      return $window.localStorage.getItem(constants.JWT_LOCALSTORAGE_KEY);
    },
    set: function(v) {
      $window.localStorage.setItem(constants.JWT_LOCALSTORAGE_KEY, v || '');
    }
  };
});

m.factory('$seo', function() {
  const ret: any = {};

  const setPageTitle = function(title) {
    title = title ? title + ' | SixPlus' : 'SixPlus | Private Dining: Simplified';
    document.title = title;
  };

  /**
   * Optimal length for Meta descriptions: ~155 characters
   * Search engines often truncate descriptions > 160 characters
  */
  const setPageDescription = function(description) {
    const metaTag = $('meta[name="description"]');
    metaTag.attr('content', description ? description : 'SixPlus is like OpenTable for groups. Our website helps you find and book the best private and semi-private spaces for group dining events of 6 or more guests.');
  };

  ret.set = function(title, description) {
    setPageTitle(title);
    setPageDescription(description);
  };

  return ret;

});

m.factory('$validator', require('./services/validator'));
m.factory('$venueValidator', function ($api, unwrapError) {
  return new VenueValidator($api, unwrapError);
});
m.factory('$cloudinary', require('./services/cloudinary'));
m.factory('$locationSpy', require('./services/locationSpy'));
m.factory('$scrollService', require('./services/scrollService'));
m.factory('$clickOk', require('./services/clickOk'));
m.factory('unwrapError', () => unwrapError);
m.factory('convertUrl', () => convertURL);
m.factory('ambianceStyleService', () => ambianceStyleService);
m.factory('GooglePlacesFactory', GooglePlacesFactory);
m.service('ToggleService', ToggleService);
m.service('$user', UserService);
m.service('serverSentEventsService', function ($api, unwrapError) {
  return new ServerSentEventsService($api, unwrapError);
});
