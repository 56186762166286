import { ApiService } from 'spc/shared/api/api.service';
import mongoose from 'mongoose';
import { RawExperience, Item, AdditionalRequirement, Addon } from '../../../../../database/types/virtual-events';
import { startCase, cloneDeep } from 'lodash';
class OrganiseController {
  items: Item[] | AdditionalRequirement[] | Addon[];
  id: mongoose.Types.ObjectId | string;
  type: string;
  heading: string;
  close: (revised: RawExperience) => void;

  constructor(
    private $scope,
    private $api: ApiService,
    private unwrapError
    ) {
    'ngInject';
  }

  $onInit = () => {
    this.$scope.items = cloneDeep(this.items);
    this.$scope.type = this.type;
    this.heading = startCase(this.type);
  }

  saveOrder = () => {
    const updatedItems: { [key: string]: Item[] | AdditionalRequirement[] | Addon[] } = { [this.type] : this.$scope.items };
    return this.$api.Admin.Experience.updateExperienceById({ experienceId: this.id, updates: updatedItems })
      .then(response => this.close(response.experience))
      .catch(err => this.unwrapError(err));
  }
}

export const OrganiseModalComponent = {
  controller: OrganiseController,
  controllerAs: '$ctrl',
  template: require('./organise-modal-component.jade'),
  bindings: {
    items: '<',
    id: '<',
    type: '<',
    close: '<'
  }
};
