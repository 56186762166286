export const organiseModal = ['ngDialog', function(ngDialog) {
  return function (items, id, type) {
    return ngDialog.open({
      template: `<organise-modal-component items="$ctrl.items" id="$ctrl.id" type="$ctrl.type" close="$ctrl.close"> </organise-modal-component>`,
      plain: true,
      className: 'ngdialog-theme-plain',
      overlay: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function($scope) {
        this.items = items;
        this.id = id;
        this.type = type;
        this.close = (response) => {
          $scope.closeThisDialog(response);
        };
      }]
    }).closePromise;
  };
}];
