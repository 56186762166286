import get from 'lodash/get';
import isFinite from 'lodash/isFinite';

// Constants
const DEFAULT_NUM_PER_PAGE = 50;


/**
 * Directive to create a pagination array based on a count, number of docs per page, and a promise-based
 * function to load specific pages
 *
 * @params {Number} count
 * @params {Number} numPerPage
 * @params {Function} loadPage, promise-based function that loads a given page
 */
class PaginationController {
  numPages: number;
  calculatedNumPages: number;
  currentPage: number;
  count: number;
  numPerPage: number;
  range: {
    start: number;
    end: number;
  };
  loadPage: ({ idx }: { idx: number }) => any;
  constructor(private unwrapError) {
    'ngInject';
  }

  $onInit = () => {
    this.init();
  }

  $onChanges = (changes) => {
    if (get(changes, 'count') || get(changes, 'currentPage')) {
      this.init();
    }
  }

  previous = () => {
    const ctrl = this;
    if (ctrl.currentPage < 2) {
      return;
    }
    this.setPage(ctrl.currentPage - 1);
  }

  next = () => {
    const ctrl = this;
    if (ctrl.currentPage >= ctrl.numPages) {
      return;
    }
    this.setPage(ctrl.currentPage + 1);
  }

  setPage = (pageNum) => {
    const ctrl = this;
    ctrl.loadPage({ idx: pageNum - 1 })
      .then(() => {
        ctrl.currentPage = pageNum;
        ctrl.range = this.calculateRange();
      })
      .catch(error => ctrl.unwrapError(error));
  }

  private calculateRange = () => {
    const ctrl = this;
    if (ctrl.numPages < 5) {
      return { start: 1, end: ctrl.numPages };
    } else if (ctrl.currentPage < 3) {
      return { start: 1, end: 5 };
    } else if (ctrl.currentPage > ctrl.numPages - 3) {
      return { start: ctrl.numPages - 5, end: ctrl.numPages };
    } else {
      return { start: ctrl.currentPage - 2, end: ctrl.currentPage + 2 };
    }
  }

  private init = () => {
    const ctrl = this;
    if (!isFinite(ctrl.count)) {
      ctrl.unwrapError(new Error(`ctrl.count must be set to initialize the pagination directive. Invalid value of ${ctrl.count} passed in`));
    }


    if (this.calculatedNumPages) {
      this.numPages = this.calculatedNumPages;
    } else {
      ctrl.numPages = Math.ceil(ctrl.count / (ctrl.numPerPage || DEFAULT_NUM_PER_PAGE));
    }

    ctrl.currentPage = ctrl.currentPage || 1;
    ctrl.range = this.calculateRange();
  }
}
export const PaginationComponent = {
  template: require('./pagination.component.jade'),
  bindings: {
    count: '<',
    numPerPage: '<',
    calculatedNumPages: '<',
    loadPage: '&',
    currentPage: '<',
  },
  controller: PaginationController
};
