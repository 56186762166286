import get from 'lodash/get';
import { ApiService } from './api/api.service';

// Interfaces
import { Recommendation } from 'server/api/admin/leads/models';
import { UpdateRecommendationRequestBody } from 'server/api/recos/models';
import { UserService } from '../services/user.service';
import { RawRecommendation } from '../../../database/types/recommendation';

export class RecommendationService {
  constructor (
    private $q,
    private $user: UserService,
    private $api: ApiService,
    private unwrapError,
    private ENUMS
  ) {
    'ngInject';
  }

  swapOrders = ({ firstReco, secondReco }) => {
    const tempOrder = secondReco.order;
    secondReco.order = firstReco.order;
    firstReco.order = tempOrder;
  }

  moveRecommendationUp = ({ lead, recommendation }) => {
    if (recommendation.order === 0) {
      return this.$q.resolve();
    }
    const recommendations = lead.recommendations.filter(reco => !reco.archived);
    const currentRecoIndex = recommendations.findIndex(leadReco => leadReco._id.toString() === recommendation._id.toString());
    const previousRecoIndex = currentRecoIndex - 1;
    const firstReco = recommendations[previousRecoIndex];
    const secondReco = recommendations[currentRecoIndex];
    this.swapOrders({ firstReco, secondReco });
    return this.updateOrder({ firstReco, secondReco });
  }

  moveRecommendationDown = ({ lead, recommendation }) => {
    if (recommendation.order === lead.recommendations.length) {
      return this.$q.resolve();
    }
    const recommendations = lead.recommendations.filter(reco => !reco.archived);
    const currentRecoIndex = recommendations.findIndex(leadReco => leadReco._id.toString() === recommendation._id.toString());
    const nextRecoIndex = currentRecoIndex + 1;
    const firstReco = recommendations[nextRecoIndex];
    const secondReco = recommendations[currentRecoIndex];
    this.swapOrders({ firstReco, secondReco });
    return this.updateOrder({ firstReco: { _id: firstReco._id, order: firstReco.order }, secondReco: { _id: secondReco._id, order: secondReco.order } });
  }

  allowArchive = (recommendation) => {
    const allowedStates = this.ENUMS.bookingRequestState.cancelledStates.concat(['INCOMPLETE']);
    if (!recommendation.conversation) {
      return true;
    } else if (allowedStates.includes(recommendation.conversation.requestState)) {
      return true;
    }
    return false;
  }

  archive = (recommendation) => {
    if (!this.allowArchive(recommendation)) {
      return;
    }
    const changes = { archived: true };
    return this.updateRecommendation({ recommendation, changes })
      .then((response) => {
        const responseReco = response.recommendation;
        recommendation.archived = responseReco.archived;
      })
      .catch(error => this.unwrapError(error));
  }

  unarchive = (recommendation) => {
    const changes = { archived: false };
    return this.updateRecommendation({ recommendation, changes })
      .then((response) => {
        const responseReco = response.recommendation;
        recommendation.archived = responseReco.archived;
      })
      .catch(error => this.unwrapError(error));
  }

  createCommentChanges = (recommendation, comment) => {
    if (!comment.value) {
      return;
    }
    const clientResponses: any[] = recommendation.clientResponses ? Array.from(recommendation.clientResponses).reverse() : [];
    clientResponses.push({
      from: get(this.$user, '$.fullName'),
      comment: comment.value,
      date: Date.now(),
      senderId: get(this.$user, '$._id')
    });
    comment.value = '';
    return {
      clientResponses, 'activity.comments': true,
      'activity.unreadByAdmin': !this.$user.isAdmin(),
      'activity.unreadByClient': this.$user.isAdmin()
    };
  }

  deleteComment = (recommendation, comment) => {
    return this.$api.Recos.updateRecoComment(recommendation._id, comment, 'delete')
      .catch(error => this.unwrapError(error));
  }

  editComment = (recommendation, comment) => {
    return this.$api.Recos.updateRecoComment(recommendation._id, comment, 'edit')
      .catch(error => this.unwrapError(error));
  }

  addComment = (recommendation, comment) => {
    const changes = this.createCommentChanges(recommendation, comment);
    return this.updateRecommendation({ recommendation, changes });
  }

  updateRecommendation = ({ recommendation, changes }: { recommendation: Recommendation, changes: UpdateRecommendationRequestBody }): ng.IPromise<any> => {
    return this.$api.Recos.updateRecommendation(recommendation._id, changes)
      .catch(error => this.unwrapError(error));
  }

  updateOrder = ({ recosToUpdate, leadId }: { recosToUpdate: Recommendation[], leadId: string}) => {
    return this.$api.Recos.updateOrder({ recosToUpdate, leadId })
      .catch(error => this.unwrapError(error));
  }

  public determineProposalUrl = (recommendation: RawRecommendation) => {
    if (!this.canViewProposal(recommendation)) {
      return;
    }

    let conversation = recommendation.conversation;
    if (get(recommendation, 'admin.wasOutsideBooking')) {
      conversation = recommendation.admin.originalConversation;
      return `/conversation/${conversation}/messages`;
    } else {
      return `/client/conversation/${conversation._id.toString()}`;
    }
  }

  public canViewProposal = (recommendation: RawRecommendation) => {
    if (get(recommendation, 'admin.wasOutsideBooking')) {
      const conversation = recommendation.admin.originalConversation;
      if (conversation) {
        return true;
      }
    } else if (recommendation.conversation && recommendation.conversation.requestState !== 'PROPOSAL') {
      return true;
    }
  }
}
