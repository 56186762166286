import { ApiService } from 'spc/shared/api/api.service';
import { ClientNote } from 'server/api/admin/clientnotes/models';
import cloneDeep = require('lodash/cloneDeep');
import { ToastService } from 'spc/shared/toast.service';


class ClientNotesController {
  client: any;
  clientNote: ClientNote;
  ui: {
    editing: boolean,
    newNote?: boolean
  } = { editing: false };
  editedNote: { title: string, message: string, isVisible: boolean };

  constructor(private $api: ApiService, private unwrapError, private toast: ToastService) {
    'ngInject';
  }

  $onInit = () => {
    this.$api.Admin.ClientNotes.get(this.client)
      .then(({ clientNote }) => this.clientNote = clientNote)
      .catch(error => this.unwrapError(error));
  }

  editNote = (note) => {
    this.ui.editing = true;
    this.editedNote = cloneDeep(note);
  }

  newNote = () => {
    const baseNote = { title: '', message: '', isVisible: true, new: true  };
    this.ui.newNote = true;
    this.editNote(baseNote);
  }

  deleteNote = () => {
    this.editedNote.isVisible = false;
    this.saveNote();
  }

  saveNote = () => {
    this.$api.Admin.ClientNotes.update({ client: this.client, note: this.editedNote })
      .then(({ clientNote }) => {
        this.toast.goodNews('Done.', 'The note was updated successfully.');
        this.clientNote = clientNote;
        this.ui.editing = false;
        this.ui.newNote = false;
      })
      .catch((error) => {
        this.toast.badNews('Note saving failure', error.message);
        this.unwrapError(error);
      });
  }

  cancelEdit = () => {
    this.ui.editing = false;
    this.editedNote = null;
  }
}

export const ClientNotesComponent = {
  controller: ClientNotesController,
  template: require('./clientnotes.component.jade'),
  bindings: {
    client: '<',
    allowEditing: '<'
  }
};
