import { each, map, maxBy, merge, defaultsDeep, isArray } from 'lodash';
import { defaultBarColorConfig, defaultLineColorConfig, BASE_COLORS } from './default-config';

class SpGraph {
  selectors = {
    drawCanvas: '.chart-area-wrapper-2 canvas',
    surrogateCanvas: '.surrogate-canvas'
  };
  chart: any;
  chartOptions: any;
  chartType: string;
  labels: string[];
  options: any;
  data: any[];
  constructor(private $element: ng.IRootElementService, private CHART_BASE_COLORS) {
    'ngInject';
  }

  $onChanges(changes) {
    const self = this;
    if (changes.data && !changes.data.isFirstChange()) {
      self.chartOptions.data.labels = self.labels;
      self.chartOptions.data.datasets = self.createDatasets(changes.data.currentValue);
      self.chart.update();
    }
  }

  $postLink() {
    const self = this;
    self.chartOptions = {
      type: self.chartType || 'bar',
      data: {
        labels: self.labels,
        datasets: self.createDatasets(self.data)
      },
      options: defaultsDeep(self.options, {
        legend: {
          position: 'bottom',
          display: true,
          labels: {
            generateLabels: function (chart) {
              return isArray(chart.data.datasets)
                ? chart.data.datasets.map((dataset, i) => {
                  return {
                    text: dataset.label,
                    fillStyle: BASE_COLORS[i],
                    hidden: !chart.isDatasetVisible(i),
                    lineCap: dataset.borderCapStyle,
                    lineDash: dataset.borderDash,
                    lineDashOffset: dataset.borderDashOffset,
                    lineJoin: dataset.borderJoinStyle,
                    lineWidth: dataset.borderWith,
                    strokeStyle: dataset.borderColor,
                    datasetIndex: i
                  };
                }, this)
                : [];
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          onComplete: function (animation) {
            const srcCanvas = self.chart.chart.canvas;
            const copyWidth = self.chart.scales['y-axis-0'].width - 10;
            const copyHeight = self.chart.scales['y-axis-0'].height + self.chart.scales['y-axis-0'].top + 10;
            const targetCtx = self.$element.find(self.selectors.surrogateCanvas)[0].getContext('2d');
            targetCtx.drawImage(srcCanvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight);
          }
        }
      }),
    };

    self.chart = new Chart(self.$element.find(self.selectors.drawCanvas), self.chartOptions);
  }

  getDynamicWidth() {
    const self = this;
    const maxLengthBucket = maxBy(self.data, 'length');

    if (maxLengthBucket.length < 10) {
      return { 'dynamic-chart-area-short': true };
    } else {
      return { 'dynamic-chart-area-medium': true };
    }
  }

  createDatasets(data) {
    const self = this;

    return map(data, (cityBucket, idx) => {
      return merge({
        label: self.series[idx],
        data: cityBucket,
      }, self.getColors(idx));
    });
  }

  getColors(idx: number) {
    const self = this;

    if (self.chartType === 'bar') {
      return defaultBarColorConfig(idx);
    } else if (self.chartType === 'line') {
      return defaultLineColorConfig(idx);
    }
  }
}

export const SpGraphComponent = {
  bindings: {
    data: '<',
    options: '<',
    labels: '<',
    series: '<',
    chartType: '@'
  },
  bindToController: true,
  controller: SpGraph,
  template: require('./sp-graph.component.jade')
};
