import { ApiService } from 'spc/shared/api/api.service';
import price from 'common/dist/price';
import { RawBookingRequest } from 'spc/lib/database/types/booking-request';
import { RawVenueNote } from 'spc/lib/database/types/venue-note';
import { RawVenue } from 'spc/lib/database/types/venue/venue';
import convertLinksToHyperlinks from 'spc/utils/convertLinksToHyperlinks';


class UserVenueHistoryController {
  venue: RawVenue;
  booked: RawBookingRequest[];
  inPlay: RawBookingRequest[];
  note: RawVenueNote;
  loading: boolean;
  editableNote: string;
  venueId: string;
  listId: string;
  uiTab;
  newNote: {
    message: string;
  };
  updatedNote: {
    message: string;
  };
  ui: {
        loading: boolean
    } = {
        loading: false
    };

  convertLinksToHyperlinks = convertLinksToHyperlinks;

  constructor(private $api: ApiService, private unwrapError) {
    'ngInject';
    this.loading = true;
    this.newNote = {
      message: ''
    };
    this.updatedNote = {
      message: ''
    };
    this.editableNote = '';
  }

  costBreakdown = price.fullPriceBreakdownDollars;

  $onInit() {
    this.ui.loading = true;
    return (this.getHistory(),
    this.fetchVenue());
  }

  fetchVenue () {
    return this.$api.Venues.get(this.venueId, {
      _id: this.venueId,
      listView: true,
    })
    .then((res) => {
      const { venue } = res.data.data;
      this.venue = venue;
    })
    .catch((error) => {
      this.unwrapError(error);
    })
    .finally(() => {
      this.ui.loading = false;
    });
  }

  getHistory() {
    return this.$api.BaseUsers.getVenueHistory(this.venueId)
      .then((res) => {
        this.booked = res.booked;
        this.inPlay = res.inPlay;
        this.note = res.note;
      })
      .catch((error) => {
        this.unwrapError(error);
      })
      .finally(() => {
        this.ui.loading = false;
      });
  }

  getVisibleNotesLength() {
    return this.note.notes.filter(note => note.isVisible === true).length;
  }

  updateNote(noteId, message) {
    return this.$api.BaseUsers.updateVenueNote({ venueId: this.venueId, noteId, message: message, listId: this.listId })
        .then((res) => {
          this.note = res.note;
        })
        .catch(error => this.unwrapError(error));
  }

  createNote() {
    return this.$api.BaseUsers.createVenueNote({ venueId: this.venueId, note: this.newNote, listId: this.listId })
      .then((res) => {
        this.note = res.note;
        this.newNote.message = '';
      })
      .catch(error => this.unwrapError(error));
  }

}

export const UserVenueHistoryComponent = {
  controller: UserVenueHistoryController,
  template: require('./user-venue-history.component.jade'),
  bindings: {
    venueId: '<',
    uiTab: '<',
    listId: '<'
  }
};
