export default {
  guest: {
    accepted: ['REQUEST', 'REPLIED', 'COUNTEROFFER', 'DEPOSIT', 'PAID', 'ALTERATION_ACCEPTED_GUEST', 'CONCLUDED', 'RECONCILED', 'CLOSED'],
    declined: ['DECLINED_GUEST', 'DECLINED_HOST', 'CANCELLED_REQUEST_GUEST', 'CANCELLED_POST_DEPOSIT_CLIENT', 'CANCELLED_POST_DEPOSIT_VENUE'],
    pending: ['ACCEPTED_GUEST', 'ACCEPTED_HOST', 'PRE_DEPOSIT_ALTERATION', 'POST_DEPOSIT_ALTERATION', 'ALTERATION_ACCEPTED_HOST']
  },
  host: {
    accepted: ['REPLIED', 'DEPOSIT', 'ACCEPTED_GUEST', 'PRE_DEPOSIT_ALTERATION', 'ACCEPTED_HOST', 'PAID', 'ALTERATION_ACCEPTED_HOST', 'ALTERATION_ACCEPTED_GUEST', 'RECONCILED', 'CLOSED'],
    declined: ['DECLINED_GUEST', 'DECLINED_HOST', 'CANCELLED_REQUEST_GUEST', 'CANCELLED_POST_DEPOSIT_CLIENT', 'CANCELLED_POST_DEPOSIT_VENUE'],
    pending: ['PROPOSAL', 'REQUEST', 'COUNTEROFFER', 'POST_DEPOSIT_ALTERATION', 'CONCLUDED']
  },
  general: {
    concluded: ['CONCLUDED', 'RECONCILED', 'CLOSED']
  }
};
