// Constants
const LOAD_TIME = 10000;

/**
 * Base level class that other component controllers can inherit from. It's main utility
 * is to make sure that components emit the `$viewReady` event. We can extend this component
 * to eventually handle authentication-related functionality if we need.
 */
let ctrl;
export default class ToplevelComponent {
  constructor(_rootScope, _location) {
    ctrl = this;
    ctrl._location = _location;
    ctrl._rootScope = _rootScope;

    if (!ctrl._rootScope) {
      throw new Error('Top Level Component requires $rootScope to inform Angular that component is ready');
    }
    ctrl.loaded = false;

    ctrl.componentIsReady = () => ctrl._rootScope.$emit('$viewReady', 'SHOW_FOOTER');
    ctrl.redirect = url => !ctrl._location ? null : ctrl._location.url(url);

    const deregister = _rootScope.$on('$viewReady', () => {
      ctrl.loaded = true;
      deregister();
    });

    if (SIXPLUS_CLIENT_GLOBALS.appEnv === 'development') {
      setTimeout(() => {
        if (!ctrl.loaded) {
          throw new Error('Component must emit $viewReady event to signal that page is ready');
        }
      }, LOAD_TIME);
    }
  }
}
