// NPM Deps
import BookingRequestSchema from 'common/dist/schemas/BookingRequest';
import { debounce, every, get, includes, keys, set, unset } from 'lodash';

// SP Deps
import { ApiService } from 'spc/shared/api/api.service';

export default ['ngDialog', function (ngDialog) {
  /**
   * Opens modal to assign proposal to a new or existing client
   *
   * @param {Boolean} isAdmin, if admin is using modal
   * @param {Array} venues
   * @param {modalOptions} options to customize modal for different needs
   */
  return function (isAdmin, venues, modalOptions) {
    return ngDialog.open({
      template: require('./new-proposal-modal.jade'),
      plain: true,
      className: 'ngdialog-theme-small info-modal',
      overlay: true,
      controller: ['$scope', '$api', 'ENUMS', '$timeout', '$injector', 'unwrapError', function ($scope, $api: ApiService, ENUMS, $timeout, $injector, unwrapError) {
        $scope.searchForClient = searchForClient;
        $scope.searchForVenue = searchForVenue;
        $scope.selectClient = selectClient;
        $scope.selectVenue = selectVenue;
        $scope.canSubmit = canSubmit;
        $scope.create = create;
        $scope.verifyEmail = verifyEmail;
        $scope.verifyEmailOnBlur = verifyEmailOnBlur;
        $scope.isAdmin = isAdmin;
        $scope.venues = venues;
        $scope.setClient = setClient;
        $scope.setTab = setTab;
        init();

        // /// Functions

        function init() {
          $scope.proposal = {};

          $scope.errors = {};
          $scope.client = { _isSet: false };
          $scope.ENUMS = ENUMS;

          $scope._tab = 'HOST';
          $scope.selectedVenue = venues[0];
          getPreviousClients()
            .then((response) => {
              $scope.previousClients = get(response, 'data.clients', []);
              $scope.previousClients.sort((client1, client2) => {
                const name1 = client1.fullName.toLowerCase();
                const name2 = client2.fullName.toLowerCase();
                if (name1 < name2) {
                  return -1;
                } else {
                  return 1;
                }
              });
            })
            .catch(unwrapError);

        }

        function create(tab) {
          if ($scope.disableCreate) {
            return;
          }
          $scope.disableCreate = true;

          const request = $scope.proposal;

          set(request, 'admin.createdThrough', ENUMS.proposalCreatedThrough.PROPOSAL_VENUE_INITIATED);

          $api.Requests.postProposal(request, [$scope.client], $scope.selectedVenue._id)
            .then(function (response) {
              const conversationId = get(response, 'data.conversationId');
              $injector.get('$location').path(`/venue/conversation/${conversationId}`);
              $scope.closeThisDialog();
            })
            .catch(function (error) {
              $scope.disableCreate = false;
              unwrapError(error);
            });
        }

        const debouncedClientSearch = debounce(function (name) {
          $api.Admin.Search.allBaseUsers(name)
            .then(response => $scope.clientResults = get(response, 'data.data'))
            .catch(unwrapError);
        }, 300);

        const debouncedVenueSearch = debounce(function (name) {
          $api.Admin.Venues.search({ text: name, status: { $in: ['Published', 'Private', 'Review', 'On Hold'] } })
            .then(response => $scope.venueResults = get(response, 'data.data.venues'))
            .catch(unwrapError);
        }, 300);

        const debouncedVerifyEmail = debounce(function (email) {
          $api.Auth.verifyEmail(email.toLowerCase())
            .then(function (res) {
              $scope.email = {
                address: res.data.address,
                isValid: res.data.valid
              };
            })
            .catch(function (error) {
              $scope.email = {
                address: error.data.address,
                isValid: error.data.valid
              };
            });
        }, 300);

        /**
         * Make ajax request to get a host's previous clients
         *
         * @return {Promise}
         */
        function getPreviousClients() {
          return $api.Requests.getClients()
            .catch(unwrapError);
        }

        /**
         * Set client from response from getPreviousClients
         *
         * @param {Client} client, from client array
         * @mutates `$scope.client`
         */
        function setClient(client) {
          $scope.client = createClientFromDoc(client);
          verifyEmailOnBlur($scope.client.email);
        }

        function createClientFromDoc(doc) {
          const ret = {
            _isSet: true,
            name: {
              first: get(doc, 'profile.name.first'),
              last: get(doc, 'profile.name.last')
            },
            email: get(doc, 'profile.email')
          };
          if (doc._id) {
            ret._id = doc._id;
          }

          return ret;
        }

        function canSubmit(tab) {
          if ($scope.disableCreate) {
            return false;
          }
          const requiredProposalFields = [
            'data.eventType'
          ];

          if (keys($scope.errors).length) {
            return false;
          }

          if (tab === 'EXISTING') {
            return every(requiredProposalFields, f => get($scope.proposal, f)) && $scope.client._isSet;
          } else if (includes(['NEW', 'HOST'], tab)) {
            return every(requiredProposalFields, f => get($scope.proposal, f)) && get($scope, 'email.isValid');
          }
        }

        /**
         * Check if user already exists in db
         *
         * @param {String} email
         * @return {Promise}
         */
        function checkIfUserExists(email) {
          return $api.Admin.Users.getOneByEmail(email);
        }

        /**
         * Verify email on blur - checks if user exists as well
         */
        function verifyEmailOnBlur(email) {
          if (!isAdmin) {
            unset($scope, 'errors.email');
            return verifyEmail(email);
          }

          checkIfUserExists(email)
            .then(function (response) {
              const user = get(response, 'data.data.user');
              if (user) {
                $scope.errors.email = 'This user already exists.';
              } else {
                unset($scope, 'errors.email');
                verifyEmail(email);
              }
            })
            .catch(unwrapError);
        }

        function verifyEmail(email) {
          debouncedVerifyEmail(email);
        }
        /**
         * Select client in row of results as client for the proposal
         *
         * @param {Client} client
         */
        function selectClient(client) {
          $scope.client = createClientFromDoc(client);
          if ($scope.client && includes($scope.client.roles, 'Host')) {
            $scope.errors.client = 'This user is a venue user. Are you sure you want to assign them as the client for this proposal?';
          } else {
            unset($scope, 'errors.client');
          }
        }

        /**
         * Select venue in row of results as row for proposal
         *
         * @param {Venue} venue
         * @sets `$scope.selectedVenue`
         */
        function selectVenue(venue) {
          if ($scope.selectedVenue && $scope.selectedVenue._id.toString() === venue._id.toString()) {
            $scope.selectedVenue = null;
          } else {
            $scope.selectedVenue = venue;
          }
        }

        /**
         * Debounced search for user/client
         *
         * @param {String} name
         * @sets `$scope.clientResults`
         */
        function searchForClient(name) {
          if (!name || name.length < 3) {
            return;
          }

          debouncedClientSearch(name);
        }

        /**
         * Debounced search for venue
         *
         * @param {String} name
         * @sets `$scope.venueResults`
         */
        function searchForVenue(name) {
          if (!name || name.length < 3) {
            return;
          }

          debouncedVenueSearch(name);
        }

        /**
         * Change tab
         *
         * @param {String} tab
         */
        function setTab(tab) {
          $scope.client = { _isSet: false };
          $scope._tab = tab;
        }
      }]
    }).closePromise;
  };
}];
