import moment from 'moment';
import Pikaday from 'pikaday';

module.exports = function() {
  return {
    restrict: 'A',
    scope: {
      // Current date in YYYY-MM-DD
      date: '@',
      // Function to call when date changes
      onDateChange: '&',
      selector: '=',
      noMinDate: '=',
      minDate: '@',
      maxDate: '@',
      allowNull: '<'
    },
    link: function(scope, element, attrs) {

      // allows pikaday to work on concierge form
      const selector = scope.selector ? scope.selector : $;

      const initOptions: Pikaday.PikadayOptions = {
        field: selector(element)[0],
        format: attrs.format || 'YYYY-MM-DD',
        minDate: scope.noMinDate ? null : (scope.minDate ? new Date(scope.minDate) : new Date()),
        maxDate: scope.maxDate ? new Date(scope.maxDate) : null,
        onSelect: function(date) {
          scope.onDateChange({ date: moment(date) });
          scope.$apply();
        }
      };

      const picker: any = new Pikaday(initOptions);

      // Often handy for testing to have access to the pikaday directly,
      // since pointing and clicking is often tricky because pikaday
      // divs get attached to weird places in the DOM
      selector(element)[0].$$pikaday = picker;

      scope.$watch('date', function(v) {
        if (!v && !scope.allowNull) {
          return;
        }
        if (!v) {
          return picker.setDate(null);
        }
        const m = moment(v, 'YYYY-MM-DD');
        picker.setMoment(m, true);
      });
    }
  };
};
