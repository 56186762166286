import * as angular from 'angular';

import { ClientDashboardComponent } from './client-dashboard.component';
import { ClientDashboardListComponent } from './client-dashboard-list.component';
import ArchiveEventModal from './archive-event/archive-event.modal';
import { ArchiveEventComponent } from './archive-event/archive-event.component';
import CreateEditEventModal from './create-edit-event/createEditEvent.modal';
import { CreateEditEventComponent } from './create-edit-event/createEditEvent.component';

export const ClientDashboardModule = angular.module('ClientDashboardModule', [])
  .component('clientDashboard', ClientDashboardComponent)
  .component('clientDashboardList', ClientDashboardListComponent)
  .component('archiveEvent', ArchiveEventComponent)
  .service('archiveEventModal', ArchiveEventModal )
  .component('createEditEvent', CreateEditEventComponent)
  .service('createEditEventModal', CreateEditEventModal )
  .name;
