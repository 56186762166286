class TopLevelLeadMetricsController {
  canInit: boolean;

  constructor(
    private $rootScope,
    private $location,
    private $seo,
    private $user) {
      'ngInject';
    }

  $onInit = () => {
    this.$user.$waitFor('LOGGED_IN', () => {
      if (!this.$user.isAdmin()) {
        return this.redirectTo('/404');
      }
      this.componentIsReady();
      this.canInit = true;
    });
    this.$user.$waitFor('NOT_LOGGED_IN', () => {
      this.redirectTo('/404');
    });
  }

  redirectTo = (url: string) => {
    return this.$location.path(url);
  }

  componentIsReady = () => {
    this.$seo.set('Admin Metrics');
    this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
  }
}

export const TopLevelLeadMetricsComponent = {
  controller: TopLevelLeadMetricsController,
  template: require('./top-level-lead-metrics.component.jade')
};
