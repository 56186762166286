import { cloneDeep } from 'lodash';
import { RawAccount } from 'spc/lib/database/types/accounts';
import { ApiService } from '../../shared/api/api.service';
import get from 'lodash/get';
import moment from 'moment';
import { ACCOUNT_TYPE, ACCOUNT_TIER } from '../../../../database/constants/Account';

class AdminAccountsDashboardController {
    ready: () => void;
    ui: {
        loading: boolean;
    } = {
        loading : false
    };
    accounts: RawAccount[] = [];
    viewType: string = 'TEAM';
    numAccounts: number = 0;
    premiumUsers: number = 0;
    documentsPerPage: number = 50;
    currentPageAccount: number  = 0;
    currentPageMember: number = 0;
    accountMembers;
    accountTier: string[] = ACCOUNT_TIER;
    accountType: string[] = ACCOUNT_TYPE;
    membersCount: number;
    link: string;
    searchParams: {
        accountName?: string;
        memberName?: string;
    } = {};
    currentPage: number;

    constructor(private $api: ApiService, private $clickOk, private unwrapError, private toast, private $window) {
        'ngInject';
    }

    $onInit = () => {
        this.getAccounts();
        this.getMembers();
    }

    getTrialExpiryDate = ({ createdAt, endDate }) => {
        if (!endDate && endDate !== null) {
            const createdDate = Date.parse(createdAt);
            return moment(new Date(createdDate)).add(30, 'days').format('MMM DD, YYYY');
        } else {
            const date = !endDate ? 'N/A' : moment(endDate).format('MMM DD, YYYY');
            return date;
        }
    }

    getAccounts = (page = 0) => {
        this.ui.loading = true;
        return this.$api.Admin.Account.getAll({ page: page, documentsPerPage: this.documentsPerPage, searchParams: this.searchParams })
            .then((response) => {
                this.accounts = cloneDeep(response.accounts);
                this.ui.loading = false;
                this.numAccounts = response.numAccounts;
                this.premiumUsers = response.premiumUsers;
                this.currentPage = page + 1;
                this.ready();
            })
            .catch((error) => {
                this.ui.loading = false;
                this.unwrapError(error);
                this.ready();
            });
    }

    getMembers = (page = 0) => {
        return this.$api.Admin.Account.getMembers({ page: page, documentsPerPage: this.documentsPerPage, searchParams: this.searchParams })
            .then((response) => {
                this.accountMembers = response.accountMembers;
                this.ui.loading = false;
                this.membersCount = response.memberCount;
                this.currentPageMember = page + 1;
            })
            .catch((error) => {
                this.ui.loading = false;
                this.unwrapError(error);
                this.ready();
            });
    }

    getFullstory = async(member) => {
        this.$api.Admin.Users.getlastSession({ user: member._id })
        .then(( res ) => {
            if (res) {
                const { FsUrl, CreatedTime } = res;
                this.link = FsUrl;
                this.$window.open(FsUrl);
            } else {
                this.toast.badNews('No Full-Story session found');
            }
        }).catch(error => this.unwrapError(error));
    }

    changeTab = (viewType) => {
        this.viewType = viewType;
    }
    deleteAccount = (accountId) => {
        const showCancel = true;
        return this.$clickOk('Are you sure you want to delete this account?', showCancel)
            .then((response) => {
                if (get(response, 'value.cancel')) {
                    return;
                }
                return this.$api.Admin.Account.delete(accountId)
                    .then(() => {
                        const index = this.accounts.findIndex(acc => acc._id === accountId);
                        this.accounts.splice(index, 1);
                    });
            })
            .catch(error => this.unwrapError(error));
    }

    updateAccount = ({ slug, account, value }: { slug: 'Tier' | 'Type', account: RawAccount, value: string }) => {
        const updates: RawAccount = {};
        const accountId = account._id;
        if (slug === 'Tier') {
            updates.accountTier = value;
        } else if (slug === 'Type') {
            updates.accountType = value;
            updates.startDate = account.startDate || new Date(account.createdAt).getTime();
            updates.endDate = updates.accountType === 'Complimentary' ? null : (account.endDate ? account.endDate :  moment(updates.startDate).add(30, 'days').valueOf());
            updates.membersLimit = updates.accountType === 'Complimentary' ? null : account.members.length;
        }
        if (updates.accountTier === 'Free') {
            Object.assign(updates, { accountType: 'Complimentary', subscriptionTerm: 'TBD', endDate: null, accountDowngrade: true, membersLimit: null });
        } else if (updates.accountTier === 'Pro' || updates.accountTier === 'Premium') {
            updates.membersLimit = account.accountType === 'Complimentary' ? null : account.members.length;
        } else {
            Object.assign(updates, { accountDowngrade: false });
        }

        this.$api.Admin.Account.updateAccount({ accountId: accountId, updates })
            .then((res) => {
                const index = this.accounts.findIndex(acc => acc._id === accountId);
                this.accounts.splice(index, 1, res.account);
                this.getMembers();
                this.toast.goodNews('Account Type Updated');

            });
    }
}

export const adminAccountsDashboardComponent = {
    template: require('./admin-accounts-dashboard.component.jade'),
    controller: AdminAccountsDashboardController,
    bindings: {
        ready: '<'
    }
};
