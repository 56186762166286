/**
 * Open a ngDialog that shows detailed information about a menu.
 *
 * @param {Menu} menu
 * @return {Promise} resolved when this modal is closed
 */

module.exports = ['ngDialog', function(ngDialog) {
  return function(menu) {
    return ngDialog.
      open({
        template: require('./menu-detail.jade'),
        className: 'ngdialog-theme-small info-modal menuModal',
        overlay: true,
        plain: true,
        controller: ['$scope', 'Data', function($scope, Data) {
          $scope.menu = Data;
        }],
        resolve: {
          Data: function() { return menu; }
        }
      }).
      closePromise;
  };
}];
