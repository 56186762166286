export const googleStreetViewModal = ['ngDialog', function(ngDialog) {
  return function (venue) {
    return ngDialog.open({
      template: '<google-street-view venue="$ctrl.venue"></google-street-view>',
      plain: true,
      className: 'ngdialog-theme-plain',
      controllerAs: '$ctrl',
      controller: function() {
        this.venue = venue;
      }
    }).closePromise;
  };
}];
