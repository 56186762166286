export const VENUE_TEMPLATES = {
  VENUE_OFFLINE_SUBMIT_REQ: [
    {
      title: 'Request response - soft hold',
      value: `If you would like to secure the date/space with a contract, take a look at the contract I uploaded for your review. I put the date and room on a soft hold, but please check back before paying the deposit if you are not paying today.`
    },
    {
      title: 'Request response - no hold',
      value: `If you would like to secure the date/space with a contract, take a look at the contract I uploaded for your review. We don’t hold dates, so please confirm that the space is still available before paying the deposit if you are not paying today.`
    }
  ],
  STANDARD_VENUE_SUBMIT_REQ: [
    {
      title: 'Request response - soft hold',
      value: `Hi CLIENT_FIRST_NAME, \n\nGreat news, we have availability on DATE_OF_EVENT_REQUEST in EVENT_SPACE. You can view the Food & Beverage minimum and other details in the proposal to the left. \n\nIf you would like to secure the date/space with a contract, all you need to do is scroll down to the bottom of the proposal to digitally sign and securely add your credit card information. I put the date and room on a soft hold, but please check back before paying the deposit if you are not paying today.\n\nLooking forward to working with you! \n\nPDM_NAME`
    },
    {
      title: 'Request response - no hold',
      value: `Hi CLIENT_FIRST_NAME, \n\nGreat news, we have availability on DATE_OF_EVENT_REQUEST in EVENT_SPACE. You can view the Food & Beverage minimum and other details in the proposal to the left. \n\nIf you would like to secure the date/space with a contract, all you need to do is scroll down to the bottom of the proposal to digitally sign and securely add your credit card information. We don't hold dates, so please confirm that the space is still available before paying the deposit if you are not paying today.\n\nLooking forward to working with you!\n\nPDM_NAME`
    }
  ],
  VENUE_OFFLINE_MESSAGE: [
    {
      title: 'Still Interested',
      value: `A friendly check in to see if you are still interested in hosting your event with us?\n\nIf you do want to move forward, take a look at the contract by scrolling down to the bottom of this event file. To secure the date/space, we will need you to sign off on the contract and send it back to us. Send a message here if you have any questions. If you have made other plans, let us know and we'll release the date/space.\n\nThanks`
    },
    {
      title: 'Another Inquiry',
      value: `We have another client interested in the EVENT_SPACE on DATE_OF_EVENT_REQUEST. Are you still interested in moving forward? If so, please scroll down to the bottom of this event file to view the contract. Sign off on the contract and send it back to us. Send a message here if you have any questions. If you have made other plans, let us know and we will release the date/space to the other client.\n\nThanks`
    },
    {
      title: 'Deposit Steps',
      value: `Great to hear, I am so glad that you would like to move forward! We do still have the date available. \n\nTo confirm your event, scroll down to the bottom of this event file to view the contract. Sign off on the contract and send it back to us. Send a message here if you have any questions.\n\nPlease don't hesitate to reach out with any questions you have. `
    },
    {
      title: 'Date Flexibility',
      value: `Hi CLIENT_FIRST_NAME,\n\nThank you for your interest in VENUE_NAME. Unfortunately, EVENT_SPACE is not available. Do you have flexibility with your date?\n\nPDM_NAME`
    },
    {
      title: 'Closing Request',
      value: `I haven't heard back in a while, so I will be closing out this request. Looking forward to working with you in the future.\n\nThanks`
    },
    {
      title: 'Menu Selections Due',
      value: `I am touching base as your event is in EVENT_IN_DAYS and your menu selections are due on MENU_DUE_DATE. I have uploaded our most current menus for you to make selections from. Let me know if you have any questions.\n\nThanks`
    },
    {
      title: 'Guest Count Due',
      value: `I am touching base as your event is in EVENT_IN_DAYS and your guaranteed guest count is due. Are you still GROUP_SIZE guests? We will charge for the guaranteed number of guests or the actual number of guests present at the event, whichever is greater.\n\nThanks`
    },
    {
      title: 'Post Event FU + Review Ask',
      value: 'It was so lovely to host your group. Thank you so much for choosing VENUE_NAME!\n\nWe would love for you to review your experience with us on SixPlus so other clients can see what you thought of the booking and event experience. Please click REVIEW_LINK to answer a few quick questions.\n\nLooking forward to working with you again in the future!'
    }
  ],
  STANDARD_VENUE_MESSAGE: [
    {
      title: 'Still Interested',
      value: `A friendly check in to see if you are still interested in hosting your event with us?\n\nIf you do want to move forward, please scroll down to the bottom of the proposal to digitally sign and securely add your credit card information. Send a message here if you have any questions. If you have made other plans, let us know and we will release the date/space to the other client.\n\nThanks`
    },
    {
      title: 'Another Inquiry',
      value: `We have another client interested in SPACE AT VENUE on DAY OF WEEK, MONTH DATE and YEAR OF EVENT. Are you still interested in moving forward? If so, please scroll down to the bottom of the proposal to digitally sign and securely add your credit card information. Send a message here if you have any questions. If you have made other plans, let us know and we will release the date/space to the other client.\n\nThanks`
    },
    {
      title: 'Deposit Steps',
      value: `Great to hear, I am so glad that you would like to move forward! We do still have the date available.\n\nTo confirm your event, scroll down to the bottom of the proposal to digitally sign and securely add your credit card information. We will receive an email when this is completed and follow-up closer to your events date to confirm all details.\n\nPlease don't hesitate to reach out with any questions you have.`
    },
    {
      title: 'Date Flexibility',
      value: `Hi CLIENT_FIRST_NAME,\n\nThank you for your interest in VENUE_NAME. Unfortunately, EVENT_SPACE is not available. Do you have flexibility with your date?\n\nPDM_NAME`
    },
    {
      title: 'Closing Request',
      value: `I haven't heard back in a while, so I will be closing out this request. Looking forward to working with you in the future.\n\nThanks`
    },
    {
      title: 'Menu Selections Due',
      value: `I am touching base as your event is in EVENT_IN_DAYS and your menu selections are due on MENU_DUE_DATE. I have uploaded our most current menus for you to make selections from. Let me know if you have any questions.\n\nThanks`
    },
    {
      title: 'Guest Count Due',
      value: `I am touching base as your event is in EVENT_IN_DAYS and your guaranteed guest count is due. Are you still GROUP_SIZE guests? We will charge for the guaranteed number of guests or the actual number of guests present at the event, whichever is greater.\n\nThanks`
    },
    {
      title: 'Post Event FU + Review Ask',
      value: 'It was so lovely to host your group. Thank you so much for choosing VENUE_NAME!\n\nWe would love for you to review your experience with us on SixPlus so other clients can see what you thought of the booking and event experience. Please click REVIEW_LINK to answer a few quick questions.\n\nLooking forward to working with you again in the future!'
    }
  ],
  SEND_VENUE_VIDEO: [
    {
      title: 'Send Venue Video',
      value: 'Here is video tour of our venue. It should give you a good understanding of what our space looks like without having to do an in-person site visit. Let us know if you have any further questions.'
    }
  ]
};
