
const clientLeadDetailsModal = ['ngDialog', function(ngDialog) {
  return function (lead) {
    return ngDialog.open({
      template: require('./lead-details.modal.jade'),
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.lead = lead;
        this.activityIndicator = lead.activityIndicator;
        this.counter = { notInquired: 0, inquired: 0, available: 0, notAvailable: 0 };
        this.getLeadActivityIndicator = (reco) => {
          reco.forEach((r) => {
            if (r.availabilityDeltas && r.availabilityDeltas.length) {
              if (r.availabilityDeltas[r.availabilityDeltas.length - 1].value === 'Not Inquired') {
                 this.counter['notInquired'] = (this.counter['notInquired'] || 0) + 1;
              } else if (r.availabilityDeltas[r.availabilityDeltas.length - 1].value === 'Inquired') {
                this.counter['inquired'] = (this.counter['inquired'] || 0) + 1;
              } else if (r.availabilityDeltas[r.availabilityDeltas.length - 1].value === 'Available') {
                this.counter['available'] = (this.counter['available'] || 0) + 1;
              }
              else if (r.availabilityDeltas[r.availabilityDeltas.length - 1].value === 'Not Available') {
                this.counter['notAvailable'] = (this.counter['notAvailable'] || 0) + 1;
              }
            }
          });
        };
        this.getLeadActivityIndicator(this.lead.recommendations);
        this.getRecommendationsStatus = (reco) => {
          if (!reco.availabilityDeltas || !reco.availabilityDeltas.length) {
            return '--';
          }
          let availability;
            if (reco.availabilityDeltas[reco.availabilityDeltas.length - 1].value === 'Inquired' ) {
              availability = 'Waiting for confirmation';
            } else if (reco.availabilityDeltas[reco.availabilityDeltas.length - 1].value === 'Available') {
              availability = 'Confirmed by Venue';
            } else if (reco.availabilityDeltas[reco.availabilityDeltas.length - 1].value === 'Not Available') {
              availability = 'Declined by Venue';
            }
             else {
              availability = reco.availabilityDeltas[reco.availabilityDeltas.length - 1].value;
            }
          return availability;
        };
        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];

export default clientLeadDetailsModal;
