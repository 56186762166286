export const adminLeadFormModal = ['ngDialog', function(ngDialog) {
  return function(conciergeTeam, cities) {
    return ngDialog.open({
      template: `<admin-lead-form concierge-Team="$ctrl.conciergeTeam" cities="$ctrl.cities" close="$ctrl.close()"></admin-lead-form>`,
      plain: true,
      className: 'ngdialog-theme-plain',
      overlay: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function($scope) {
        this.conciergeTeam = conciergeTeam;
        this.cities = cities;
        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];
