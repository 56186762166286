/**
 * Directive to toggle on and off. Emits events based on which is selected
 *
 * @param {String} on, text to display when toggled on
 * @param {String} off, text to display when toggled off
 * @param {Boolean} defaultOn, if true, defaults toggle to on, otherwise defaults to off
 */

export default ['ToggleService', function(ToggleService) {
  return {
    template: require('./http-toggle.jade'),
    scope: {
      on: '@',
      off: '@',
      defaultOn: '='
    },
    link: function(scope, elem, attrs) {
      scope.switchToggle = switchToggle;

      init(scope.defaultOn);

      ///// Listeners

      scope.$on('FINISH_TOGGLE', function(ev, params) {
        scope.toggle.switchToggle();
        if (scope.toggle._toggled) {
          _addToggledClass(scope.toggleContainer);
        } else {
          _removedToggledClass(scope.toggleContainer);
        }
      });

      scope.$on('UNDO_TOGGLE', () => _undoToggle(scope.toggleContainer));
      
      ///// Functions

      /**
       * Initialize directive with the bar toggled on or off based on the default
       *
       * @param {Boolean} defaultOn
       */
      function init(defaultOn) {
        scope.toggle = new ToggleService(defaultOn);
        scope.toggleContainer = elem.find('.toggle-container');
        if (defaultOn) {
          _addToggledClass(scope.toggleContainer)
        } else {
          _removedToggledClass(scope.toggleContainer);
        }
      }

      function switchToggle() {
        if (!scope.toggle._toggled) {
          _toggleOn(scope.toggleContainer);
        } else {
          _toggleOff(scope.toggleContainer);
        }
      }

      function _undoToggle(container) {
        if (scope.toggle._toggled) {
          _removedToggledClass(container);
          scope.toggle._toggled = false;
        } else {
          _addToggledClass(container);
          scope.toggle._toggled = true;
        }
      }
      /**
       * Add and remove the necessary classes to show the bar as toggled on
       * 
       * @api private
       * @param {HTMLElement} container
       */
      function _addToggledClass(container) {
        if (container.hasClass('pushed-left')) {
          container.removeClass('pushed-left');
        }
        container.addClass('pushed-right');

      }

      /**
       * Add and remove the necessary classes to show the bar as toggled off
       *
       * @api private
       * @param {HTMLElement}
       */
      function _removedToggledClass(container) {
        if (container.hasClass('pushed-right')) {
          container.removeClass('pushed-right');
        }
        container.addClass('pushed-left');
      }

      /**
       * Switch the bar to toggled on and inform parent scope
       *
       * @api private
       * @param {HTMLElement}
       * @fires `TOGGLED_ON`
       */
      function _toggleOn(container) {
        scope.$emit('TOGGLED_ON');
      }

      /**
       * Switch the bar to toggled off and inform parent scope
       *
       * @api private
       * @param {HTMLElement}
       * @fires `TOGGLED_OFF`
       */
      function _toggleOff(container) {
        scope.$emit('TOGGLED_OFF');
      }

    },
    controller: ['$scope', function($scope) {

    }]
  };
}];