import * as angular from 'angular';

// Components
import { ConversationMessagesComponent } from './conversation-messages.component';
import { MessageService } from './message.service';
import cspVideoPlayer from 'spc/components/search/venue-search/cspVideoPlayer/csp-video-player.modal';

export const MessagesModule = angular.module('requests.messages', [])
  .component('conversationMessages', ConversationMessagesComponent)
  .service('cspVideoPlayer', cspVideoPlayer)
  .service('messageService', MessageService)
  .name;
