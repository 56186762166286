import { RawExperience, Status } from 'spc/lib/database/types/virtual-events';
import { ApiService } from '../../../shared/api/api.service';
import { cloneDeep } from 'lodash';
import mongoose from 'mongoose';

class TogglePublishedController {
  private toggleOn: boolean = false;
  private status: string;
  private experience: RawExperience;
  onChange: any;
  experienceClone: RawExperience;
  experienceId: mongoose.Types.ObjectId | string;
  constructor(
    private $api: ApiService,
    private unwrapError
  ) {
    'ngInject';
   }

  $onInit() {
    this.experienceClone = cloneDeep(this.experience);
    this.experienceId = this.experienceClone['_id'];
    this.status = this.experienceClone.status;
    if (this.status === 'Published') {
      this.toggle_on();
    }
    else {
      this.toggle_off();
    }
  }

  $onChanges = (changes) => {
    if (changes && changes.experience && changes.experience.currentValue) {
      const currentValue = changes.experience.currentValue;
      this.status = currentValue.status;
      if (this.status === 'Incomplete') {
        this.toggle_off();
      }
    }
  }

  onStatusChange = () => {
    if (this.status === 'Ready') {
      this.status = 'Published';
      this.updateStatus();
    }
    else if (this.status === 'Published') {
      this.status = 'Ready';
      this.updateStatus();
    }
  }

  updateStatus = () => {
    const updates  = { status : this.status };
    this.$api.Admin.Experience.updateExperienceById({ updates, experienceId: this.experienceClone._id })
    .then((res) => {
      this.onChange(res.experience);
    })
    .catch((err) => {
      this.unwrapError(err);
      this.toggleOn = !this.toggleOn;
    });
  }

  toggle_on = () => {
    this.toggleOn = true;
  }

  toggle_off = () => {
    this.toggleOn = false;
  }
}

export const  TogglePublishedComponent = {
  controller:  TogglePublishedController,
  template: require('./toggle-published.component.jade'),
  bindings: {
    experience : '<',
    onChange : '<'
  }
};
