class RequstProAccountModalController {
    closeModal: () => any;
    constructor() {
      'ngInject';
    }
}

export const requstProAccountModalComponent = {
    template: require('./request-pro-account-modal.component.jade'),
    controller: RequstProAccountModalController,
    bindings: {
        closeModal: '&'
    }
};
