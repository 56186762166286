import commafy from 'commafy';
import moment from 'moment';
import ENUMS from 'common/dist/enums';
import pluralize from 'pluralize';
import * as angular from 'angular';
import { capitalize, get, includes, isInteger, isNil, map, padStart, startCase, truncate, words, round } from 'lodash';
import './globals';
import { coverImageSort } from './filters/cover-image-sort';
import { getExtension } from './filters/get-extension';

const m = angular.module('filters', []);

/**
 * Helper function to hide decimals if the number is truly an integer.
 * For instance, `70.00` => `70`
 * For instance, `45.30` => `45.30`
 * @param {Number} cents
 * @param {Number} spec for how many trailing numbers to allow. Defaults to 2
 * @param {Boolean} alreadyDollars - skip conversion to dollars if true
 * @return {String}
*/

function removeTrailingZeros(cents, precision?, alreadyDollars?) {
  let dollars = alreadyDollars ? cents : (cents / 100);
  precision = isNil(precision) ? 2 : precision;
  dollars = isInteger(dollars) ?
    dollars.toFixed(0) :
    dollars.toFixed(precision);
  return dollars;
}

// See : http://stackoverflow.com/questions/11160513/angularjs-ng-options-create-range
m.filter('range', () => {
  return function (input, min, max) {
    min = parseInt(min, 10);
    max = parseInt(max, 10);
    for (let i = min; i <= max; ++i) {
      input.push(i);
    }
    return input;
  };
});

// https://gist.github.com/ferronrsmith/5630696
// Ordinal Number Filter
// ---------------------
// This filter takes a number and returns its ordinal value
// i.e. 1 -> 1st, 2 -> 2nd, etc.
// h/t http://ecommerce.shopify.com/c/ecommerce-design/t/ordinal-number-in-javascript-1st-2nd-3rd-4th-29259

m.filter('ordinal', () => {
  return function (input) {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = input % 100;
    return input + (s[(v - 20) % 10] || s[v] || s[0]);
  };
});

m.filter('timeSelectRange', () => {
  return function (input, from, to, inc) {
    from = parseInt(from, 10);
    to = parseInt(to, 10);
    to = to < from ? (to + 24) : to;
    inc = inc ? parseInt(inc, 10) : 30;

    for (let i = from; i <= to; i++) {
      let min = 0;
      while (min < 60) {
        input.push(parseInt('' + padStart(i, 2, '0') + padStart(min, 2, '0'), 10));
        min += inc;
      }
    }

    return input;

  };
});

m.filter('timeSelectLabel', () => {
  return function (input, amPm: boolean) {
    amPm = amPm || false;
    input = parseInt(input, 10);

    const minutes: any = input % 100;
    const hours: any = (input - minutes) / 100;
    const am_pm = hours >= 12 && hours <= 23 ? 'PM' : 'AM';

    return amPm ?
      padStart((hours % 12) || 12, 2, '0') + ':' + padStart(minutes, 2, '0') + ' ' + am_pm :
      padStart(hours, 2, '0') + ':' + padStart(minutes, 2, '0');
  };
});

m.filter('timeSelectLabelShort', () => {
  return function (input) {
    input = parseInt(input, 10);

    const minutes = input % 100;
    const hours = (input - minutes) / 100;
    const am_pm = hours >= 12 && hours <= 23 ? 'pm' : 'am';

    return ((hours % 12) || 12) +
      (minutes > 0 ? ':' + minutes : '') +
      ' ' + am_pm;
  };
});

/**
 * Takes in a string, creates a moment with the given input format,
 * and formats it with the given output format.
 *
 * For instance, `'2011-06-01' | convertMoment:'YYYY-MM-DD':'MMMM DD'` =>
 * 'June 1'
 *
 * @param {String} input
 * @param {String} inputFormat format according to moment
 * @param {String} outputFormat format according to moment
 * @return {String}
 */

m.filter('convertMoment', () => {
  return function (input, inputFormat, outputFormat) {
    return moment(input, inputFormat).format(outputFormat);
  };
});


m.filter('eventTimeRange', () => {
  return (startTime, duration) => {
    if (!startTime || !duration) {
      return '--';
    }
    const endTime = moment(startTime, 'Hmm').add(duration, 'minutes').format('h:mma');
    return `${moment(startTime, 'Hmm').format('h:mma')} - ${endTime}`;
  };
});

/**
 * Takes a date, creates a moment with format MMMM Do YYYY, h:mm a
 *
 * @param {String} input
 * @return {String}
 */

m.filter('dateAndTime', () => {
  return function (input) {
    return moment(input).format('MMM Do YYYY, h:mm a');
  };
});

m.filter('dateAndTimeAbbreviated', () => {
  return function (input) {
    return moment(input).format('MMM Do [at] h:mm a');
  };
});

/**
 * Takes in a Date or Moment and indicates how much time has elapsed between now and then
 * @param {Moment|Date} input
 * @return {String}
*/

m.filter('timeElapsedHumanized', () => {
  return function (input) {
    if (!input) {
      return;
    }

    input = moment.isMoment(input) ? input : moment(input);

    const TODAY = moment().startOf('day');
    const YESTERDAY = moment().subtract(1, 'days').startOf('day');

    const isToday = input.isSame(TODAY, 'd');
    const isYesterday = input.isSame(YESTERDAY, 'd');

    const sentAt = input.valueOf();
    const now = moment().valueOf();

    const diff = now - sentAt;
    const duration = moment.duration(diff);

    if (isToday) {
      return `Today, ${moment(input).format('h:mm A')}`;
    } else if (isYesterday) {
      return `Yesterday, ${moment(input).format('h:mm A')}`;
    } else {
      return moment(input).format('ddd. MMM DD YYYY, h:mm A');
    }
  };
});

/**
 * Converts our usual numeric format to a 'H:m' string, because moment has
 * some gnarly edge cases when parsing 'Hm'
 *
 * For instance, 630 => '6:30'
 *
 * @param {Number} input
 * @return {String}
 */

m.filter('numberToTime', () => {
  return function (input, amPm) {
    if (input > 2400) {
      input -= 2400;
    }
    let val = Math.floor(input / 100) + ':' + (input % 100);

    if (amPm) {
      return val += (input >= 1200 ? 'pm' : 'am');
    }
    return val;
  };
});

/**
 * Since we often store duration in minutes, convert 120 => '2 hours'
 *
 * @param {Number} minutes
 * @return {String}
 */

m.filter('minutesToHours', () => {
  return function (minutes) {
    const durationHours = minutes / 60;
    return durationHours + ' hour' + (durationHours > 1 ? 's' : '');
  };
});

/**
 * convert 120 => '2 hrs'
 * @param {Number} minutes
 * @return {String}
 */
m.filter('minutesToHoursShort', () => {
  return function (minutes) {
    const hrs = minutes / 60;
    return hrs + ' hr' + (hrs > 1 ? 's' : '');
  };
});

m.filter('omitArticle', () => {
  return function (name) {
    if (!name) {
      return;
    }

    const articlesToOmit = ['the', 'a', 'an'];
    let wordArray = words(name);
    const firstWordLower = (wordArray[0] || '').toLowerCase();
    if (includes(articlesToOmit, firstWordLower)) {
      wordArray = wordArray.slice(1);
    }
    return wordArray.join(' ');
  };

});

m.filter('addArticle', () => {
  return function (name) {
    if (!name) {
      return;
    }

    const articlesToAdd = ['the'];
    let wordArray = words(name);
    const firstWordLower = (wordArray[0] || '').toLowerCase();
    if (includes(articlesToAdd, firstWordLower)) {
      wordArray = wordArray.slice(1);
      wordArray.unshift('the');
    } else {
      wordArray.unshift('the');
    }
    return wordArray.join(' ');
  };

});

m.filter('centsToRoundedDollars', () => {
  return function (cents) {
    return `$${commafy((cents / 100).toFixed(0))}`;
  };
});

/**
 * Given a number, divide it by 100, add necessary commas, and prepend '$'
 *
 * @param {Number} cents
 * @param {Boolean} omitTrailingZeros
 * @return {String}
 */

m.filter('centsToDollars', () => {
  return function (cents, omitTrailingZeros) {
    if (cents == null) {
      return '';
    }
    cents = omitTrailingZeros ? removeTrailingZeros(cents) : round((cents / 100), 2).toFixed(2);
    return '$' + commafy(cents);
  };
});

/**
 * Given a number, prepend '$' and add necessary commas
 *
 * @param {Number|String} cents
 * @param {Boolean} preserveTrailingZeros
 * @return {String}
 */

m.filter('dollarsToString', () => {
  return function (dollars, preserveTrailingZeros) {
    if (!isFinite(dollars)) {
      return '';
    }
    if (typeof dollars === 'string') {
      dollars = parseFloat(dollars);
    }
    dollars = preserveTrailingZeros ? dollars.toFixed(2) : removeTrailingZeros(dollars, null, true);
    return '$' + commafy(dollars);
  };
});

m.filter('commafy', () => {
  return function (num, isFloat) {
    if (isFloat) {
      num = parseFloat(num);
    }
    return '$' + commafy(isFloat ? num.toFixed(2) : parseInt(num, 10));
  };
});

m.filter('commafyNum', () => {
  return function (num, isFloat) {
    if (isFloat) {
      num = parseFloat(num);
    }
    return commafy(isFloat ? num.toFixed(2) : parseInt(num, 10));
  };
});

/**
 * Take a string and remove non-standard characters
 * like "_" and convert to startCase. Then add "by" before subjects in sentences
 *
 * For instance, 'fooBar' => 'Foo Bar'
 *
 * @param {String} str
 * @return {String}
 */

m.filter('startCase', () => {
  return function (str) {
    str = startCase(str).toLowerCase();
    str = words(str);
    str = map(str, (word: string) => {
      const action = ['guest', 'client', 'host', 'venue'].includes(word);
      return action ? 'by ' + capitalize(word) : capitalize(word);
    });
    return str.join(' ');
  };
});

/**
 * Take a string and return in lowercase
 * @param {String} str
*/

m.filter('toLower', () => {
  return function (str) {
    return str.toLowerCase();
  };
});

/**
 * Capitalizes a word, i.e. MONK => Monk
 *
 * @param {String} str
 */
m.filter('capitalize', () => {
  return function (str) {
    return capitalize(str);
  };
});

/**
 * Truncate string
 *
 * @param {String} str
 * @param {Number} numChars, number of chars to show
 * @param {String} omission, text to end truncated string with (defaults to '...')
 */

m.filter('truncate', () => {
  return function (str, numChars, omission) {
    return truncate(str, { length: numChars, omission: omission || '...' });
  };
});

m.filter('readablePhone', () => {
  return function (num) {
    if (!num) {
      return `--`;
    }

    const phoneNumber = libphonenumber.parsePhoneNumberFromString(num, 'US');

    if (phoneNumber && phoneNumber.country === 'US') {
      return phoneNumber.formatNational();
    } else if (phoneNumber) {
      return `+ ${phoneNumber.countryCallingCode} ${phoneNumber.formatNational()}`;
    } else {
      return `--`;
    }
    // const standardNumber = num.replace(/[^\d\+]/g, '');

    // return `${standardNumber.includes('+') ? '' : ''} (${ standardNumber.substring(0, 3) }) ${ standardNumber.substring(3, 6) }-${ standardNumber.substring(6, 10)}`;
  };
});
/**
 * Convert internal city names to public
 * city names.
 *
 * For instance, "New York City" => "New York City area"
 * @param {String} internalCityName
 * @return {String}
 */

m.filter('publicCityName', () => {
  return function (internalCityName) {
    return get(ENUMS, `cities.${internalCityName}.name }`);
  };
});

/**
 * Take a quantity and return that quantity + a noun in the form that agrees with the quantity
 * @param {Number} quantity
 * @param {String} noun
 * @return {String}
*/

m.filter('pluralize', () => {
  return function (quantity, noun) {
    const theQuantity = quantity || 0;
    return theQuantity + ' ' + pluralize(noun, theQuantity);
  };
});

m.filter('trustHtml', ['$sce', function ($sce) {
  return function (htmlString) {
    return $sce.trustAsHtml(htmlString);
  };
}]);

m.filter('eventTypeToIcon', () => {
  const eventTypeToIconMap = {
    'Company Event': 'icon-company-event3',
    'Personal Event': 'icon-personal-event',
    'Board Meeting': 'icon-board-meeting3',
    'Shareholder Meeting': 'icon-shareholders',
    'Advisory Board Meeting': 'icon-advisory-meeting',
    'Holiday Party': 'icon-holiday-party',
    'Team Building': 'icon-team-building-3',
    'Networking Event': 'icon-networking-clients',
    'Seminar/Workshop': 'icon-presentation-thick',
    'Client Event': 'icon-client',
    'Milestone Event': 'icon-milestone',
    'Anniversary Party': 'icon-gift',
    'Retirement Celebration': 'icon-retirement',
    'Corporate Event': 'icon-bag3',
    Birthday: 'icon-cake2',
    Graduation: 'icon-graduation',
    'Wedding Rehearsal Dinner': 'icon-dinner-party',
    Wedding: 'icon-wedding-cake',
    'Post Wedding Brunch': 'icon-toast-festive',
    'Baby Shower': 'icon-rubber-duck2',
    'Bridal Shower': 'icon-bride2',
    'Gender Reveal': 'icon-gender',
    'Family Gathering': 'icon-family',
    'Friend Gathering': 'icon-friend-gathering',
    Thanksgiving: 'icon-thanksgiving',
    Other: 'icon-calendar-other'
  };
  return (eventType) => {
    return eventTypeToIconMap[eventType.label];
  };
});

m.filter('amenityToIcon', () => {
  const amenityToIconMap = {
    'A/V Available': 'icon-film',
    'Billiards/Games': 'icon-games',
    'Ceremony On Site': 'icon-podium',
    'Can Bring Decorations': 'icon-party2',
    Fireplace: 'icon-flame',
    'Great View': 'icon-landscape',
    'Handicap Accessible': 'icon-handicapped',
    'Activity Offered': 'icon-interactive',
    'Outdoor Space': 'icon-outside',
    Piano: 'icon-piano',
    'Private Full Bar': 'icon-bar',
    Bartender: 'icon-bar',
    'Beer & Wine Only': 'icon-bottles',
    'Seated Event': 'icon-seated',
    Plating: 'icon-plated-fancy',
    Plated: 'icon-plated-fancy',
    'Buffet Style': 'icon-buffet',
    'Standing Cocktail Reception': 'icon-cocktail',
    'Space for DJ': 'icon-dj',
    Stage: 'icon-stage',
    'Thermostat Control': 'icon-thermostat',
    TV: 'icon-tv',
    'Wine Cellar': 'icon-wine-cellar',
    'Outside Dessert OK': 'icon-cake',
    'Live Music Allowed': 'icon-music',
    'Dance Floor': 'icon-music',
    'Bridal Suite': 'icon-bride2',
    'Heat Lamps/Heaters': 'icon-heater',
    'Can Host Hybrid Events': 'icon-event-type',
    'Can Play Own Music': 'icon-ipod',
    'Open Kitchen': 'icon-kitchen',
    'Private Bathroom': 'icon-bathroom',
    'Private Entrance': 'icon-entrance',
    Windows: 'icon-window',
    'Lighting Control': 'icon-switch',
    Tent: 'icon-tent',
    'Parking Garage Nearby': 'icon-garage',
    'Customized Menus': 'icon-menu',
    'Dessert Plating': 'icon-dessert',
    'Onsite Parking (Paid)': 'icon-paid-parking',
    'Onsite Parking (Free)': 'icon-parking-1',
    BYOB: 'icon-bottles',
    Waterfront: 'icon-anchor',
    'Movable Dividers': 'icon-divider',
    'Water View': 'icon-waves',
    'City View': 'icon-city',
    'Outdoor Heaters': 'icon-heater',
    Cheesemonger: 'icon-cheese',
    Sommelier: 'icon-wine',
    WIFI: 'icon-wifi',
    'Chef\'s Table': 'icon-cleaver',
    'Photo & Video': 'icon-camera',
    'A/V Setup': 'icon-camera',
    'Corkage Allowed': 'icon-corkscrew',
    'Corkage Fee': 'icon-corkscrew',
    'Piano Onsite': 'icon-piano',
    'Piano Playing': 'icon-piano',
    Microphone: 'icon-microphone',
    'Valet Parking': 'icon-parking',
    'Validated Parking': 'icon-parking',
    'Coat Check': 'icon-clothes-hanger'
  };

  return function (amenity) {
    return amenityToIconMap[amenity];
  };


});

m.filter('recoStateToColor', () => {
  const REJECTED_COLOR = 'highlight-color';
  const MAYBE_COLOR = 'info-color';
  const BOOKED_COLOR = 'alternate-color';

  return (recoState) => {
    const rejectedColorState = ['Not A Good Fit', 'Expired', 'Declined by Venue', 'Declined by You', 'Event Cancelled by You', 'Event Cancelled by Venue', 'Cancelled Proposal'];
    const maybeColorStates = ['Not Sure'];
    const bookedColorStates = ['Booked', 'Concluded', 'Proposal Accepted by Venue'];

    if (rejectedColorState.includes(recoState)) {
      return REJECTED_COLOR;
    }

    if (maybeColorStates.includes(recoState)) {
      return MAYBE_COLOR;
    }

    if (bookedColorStates.includes(recoState)) {
      return BOOKED_COLOR;
    }
  };
});

m.filter('coverImageSort', coverImageSort);
m.filter('getExtension', getExtension);


module.exports = m;
