// External Dependencies

export default ['ngDialog', function(ngDialog) {
  return function (params) {
    return ngDialog.open({
      template: `<reco-transfer recommendation="$ctrl.recommendation" lead="$ctrl.lead" update-list="$ctrl.update(recommendations)" close="$ctrl.close()"></reco-transfer>`,
      className: 'ngdialog-theme-small info-modal',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function ($scope) {
        if (params) {
          const { recommendation, lead, update } = params;
          this.recommendation = recommendation;
          this.lead = lead;
          this.update = update;
        }
        this.close = (data) => {
          return $scope.closeThisDialog(data);
        };
      }]
    }).closePromise;
  };
}];
