export const getExtension = () => {
  const map = {
    pdf: 'PDF',
    doc: 'DOC',
    docx: 'DOC',
    xls: 'XLS',
    xlsx: 'XLS',
    ppt: 'PPT',
    pptx: 'PPT',
    jpg: 'JPG',
    jpeg: 'JPG',
    png: 'PNG',
    gif: 'GIF'
  };

  return (url) => {
    if (!url) {
      return;
    }
    const extension = url.split('.').pop();
    return map[extension];
  };
};

