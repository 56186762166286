import { RecoRequestService } from '../reco-request/reco-request.service';
import get from 'lodash/get';

// External Dependencies

export default ['ngDialog', function(ngDialog) {
  return function ({ lead }) {
    return ngDialog.open({
      preCloseCallback: function(value) {
        /**
         * SKIP SCENARIOS
         * If the request has been submitted, dont show the modal
        */
        if (value === '$closeButton') {
          return;
        }
        if (get(value, 'state') === 'REQUEST') {
          return;
        }
        if (value === 'Lite') {
          return;
        }
        const nestedConfirmDialog = ngDialog.openConfirm({
          className: 'ngdialog-theme-small',
          controller: ['$scope', 'recoRequestService', function($scope, recoRequestService: RecoRequestService) {
            const data = recoRequestService.getData();
            const { recommendation } = data;

           if (recommendation) {
              $scope.case = 'CASE_2';
            } else {
              $scope.case = 'CASE_1';
            }
          }],
          template: require('../reco-request/reco-request-cancel.jade'),
          plain: true
        });

        // NOTE: return the promise from openConfirm
        return nestedConfirmDialog;
      },
      template: `<client-add-venues close="$ctrl.close(request)" lead="$ctrl.lead"></client-add-venues>`,
      className: 'ngdialog-theme-plain',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function ($scope) {
        this.lead = lead;
        this.close = (request) => {
          $scope.closeThisDialog(request);
        };
      }]
    }).closePromise;
  };
}];
