
import { BusinessTrackingSearch } from 'spc/admin/metrics/proposal-metrics/proposal-metrics-search.model';
export class AdminSortService {

  /**
   * @constant
   * the value that identifies the last sort before undoing sort altogether
   * Descending Order is from A-Z so oldest entries would show up first, which is
   * what we would want for the first sort. On First click, it will sort on
   * FIRST_SEARCH which will be 1 (and not -1), so oldest entries will show up first
   */
  LAST_SEARCH: number = -1;

  /**
   * @constant
   *
   * The value that identifies the first search sort (the first time yo uclick on a field to sort on it)
   * Not technically a constant, computed from LAST_SEARCH so we only need to change one value to quickly flip things
   */
  FIRST_SEARCH: number = this.LAST_SEARCH * -1;

  public setSort(
    searchParams: BusinessTrackingSearch,
    path: string): (undefined | { path: string, order: number }) {
    const self = this;

    const currentSort = searchParams.sort;
    if (!currentSort) {
      return { path, order: self.FIRST_SEARCH };
    }

    if (currentSort.order === self.FIRST_SEARCH) {
      return { path, order: self.LAST_SEARCH };
    } else if (currentSort.order === self.LAST_SEARCH) {
      return undefined;
    } else {
      return { path, order: self.LAST_SEARCH };
    }
  }

  public getSortClass(sort: { path: string, order: number }, path: string):
    (undefined | { fa: boolean, 'fa-caret-down': boolean, 'fa-caret-up': boolean }) {
    if (!sort || sort.path !== path) {
      return;
    }

    const ctrl = this;
    return {
      fa: true,
      'fa-caret-down': sort.order === ctrl.FIRST_SEARCH,
      'fa-caret-up': sort.order === ctrl.LAST_SEARCH
    };
  }
}
