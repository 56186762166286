export default ['ngDialog', function createEditEventModal(ngDialog) {
  return function ({ editEvent, editableLead, preFilledData }: {editEvent: boolean, editableLead, preFilledData?}) {
    return ngDialog.open({
      template: '<create-edit-event close="$ctrl.close(response)" edit-event="$ctrl.editEvent" editable-lead="$ctrl.editableLead" pre-filled-data="$ctrl.preFilledData"></create-edit-event>',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.editEvent = editEvent;
        this.editableLead = editableLead;
        this.preFilledData = preFilledData;
        this.close = (response) => {
          $scope.closeThisDialog(response);
        };
      }]
    }).closePromise;
  };
}];
