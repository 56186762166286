import { camelCase } from 'lodash';

/**
 * Helper function to modularize  where different parts of page
 * must be shown and hidden. This function returns a string with an initial
 * word and a camelCase version of a string appended to it, with the first letter
 * of the camelCased string capitalized
 * @param {String} initial
 * @param {String} string
 * @return {String}
*/
module.exports = function(initial, string) {
  initial = initial ? initial : '';
  string = string ? string : '';
  if (!initial) {
    return camelCase(string);
  }
  if (string && string.length === 1) {
    return initial + string.toUpperCase();
  }

  return initial + string[0].toUpperCase() + camelCase(string.slice(1));
};
