import * as angular from 'angular';

import { ClientReceitsComponent } from './client-receipts.component';
import clientReceiptsRequestAccessModal from './client-receipts-request-access/client-receipts-request-access.model';


export const ClientReceiptsModule = angular.module('ClientReceiptsModule', [])
  .component('clientReceipts', ClientReceitsComponent)
  .service('clientReceiptsRequestAccessModal', clientReceiptsRequestAccessModal)
  .name;
