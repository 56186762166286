// imports
import { fbMinRangeDollars, roomFeeRange } from 'common/dist/price';
import get from 'lodash/get';

class AllSpacesController {
  request: any;
  spaces: any;
  flickityOptionsPhotos: {
    initialIndex: 0,
    cellAlign: 'left',
    freeScroll: true,
    contain: true,
    pageDots: false,
    groupCells: true
  };

  recoOption: string = `DESCRIPTION`;

  constructor(
    private $cloudinary,
    private photoDialog
  ) {
    'ngInject';
  }

  $onInit() {
    this.request.selectedSpace = this.request.venue.data.spaces[0];
  }

  public displayMin = (space) => {
    const inquireMessage = 'Inquire to confirm';
    if (get(space, 'data.hidden.fbMin')) {
      return inquireMessage;
    }
    return fbMinRangeDollars(space);
  }

  public openPhotoDialog = (photos, initialPhoto): Promise<any> => {
    return this.photoDialog({ photos: photos, initialPhoto: initialPhoto });
  }

  visibleSpaces = () => {
    return this.spaces.filter(space => space.isVisible).length - 1;
  }

}

export const allSpacesComponent = {
  controller: AllSpacesController,
  template: require('./all-spaces.component.jade'),
  bindings: {
    spaces: '<',
    request: '<'
  }
};
