// NPM Deps
const sortBy = require('lodash/sortBy');
import timeHelpers from 'common/dist/time';
import bookingRequestHelpers from 'common/dist/virtuals/BookingRequest';

// SixPlus Dependencies
const schedulerHelpers = require('../../../../../shared/scheduler_helpers');

// SP Interfaces
import { Event } from 'server/api/availability-calendar/models';

export class DayViewService {
  constructor() {
    'ngInject';
  }

  createSpaceGrid = ({ from, to, mintuesPerTick, levels }) => {
    return levels.map((level, i) => {
      return schedulerHelpers.padEvents(
        from,
        to,
        mintuesPerTick,
        level
      );
    });
  }

  createLevels = ({ availabilityCalendarEvents, timeSlots, pendingEvents, confirmedEvents }) => {
    const levels = [];

    levels[0] = this.sortTimeSlots(timeSlots);

    levels[1] = this.formatEvents({ events: availabilityCalendarEvents, confirmedEvents });

    const formattedPendingEvents = this.formatPendingEvents(pendingEvents);

    formattedPendingEvents.forEach((event) => {
      let currentLevel = 1;
      let level = levels[currentLevel];
      let conflict = false;
      do {
        conflict = this.findConflict({ level, event });
        if (conflict) {
          currentLevel++;
          levels[currentLevel] = levels[currentLevel] || [];
          level = levels[currentLevel];
        } else {
          level.push(event);
        }
      } while (conflict);
    });

    return levels;
  }

  private formatPendingEvents = (requests: any) => {
    return requests.map((request) => {
      return {
        request,
        state: 'pending',
        from: request.data.time,
        to: timeHelpers.getEndTimeFromStartTimeAndDuration(request.data.time, request.data.duration)
      };
    });
  }

  private formatEvents = ({ events, confirmedEvents }) => {
    const formattedEvents = events.map((event) => {
      return {
        event,
        state: event.bookingRequest ? 'booked' : 'blocked',
        request: event.bookingRequest ? this.findRequest({ event, requests: confirmedEvents }) : null,
        from: event.startTime,
        to: event.endTime
      };
    });
    return this.sortEvents(formattedEvents);
  }

  private findRequest = ({ event, requests }: { event: Event, requests: any[] }) => {
    return requests.find(request => request._id.toString() === event.bookingRequest);
  }

  private sortTimeSlots = (timeSlots) => {
    return sortBy(timeSlots, timeSlot => timeSlot.from);
  }

  private sortEvents = (events: Event[]): Event[] => {
    return sortBy(events, event => event.from);
  }

  private findConflict = ({ level, event }: { level: any[], event: Event }) => {
    return level.find((savedEvent) => {
      return (event.from < savedEvent.to) && (event.to > savedEvent.from);
    });
  }

  public getPendingEventsForSpace = ({ pendingEvents, space }) => {
    return pendingEvents.filter((request) => {
      const requestSpace = bookingRequestHelpers.selectedSpace(request);
      return space._id === requestSpace._id;
    });
  }

}
