export const EXPERIENCE_ADDON_PRICE_TYPE = ['per person', 'per event', 'per item', 'per kit', 'per box'];
export const EXPERIENCE_DURATION_TYPE = ['hours', 'days', 'minutes', 'NA'];
export const EXPERIENCE_SHIPPING_PRICE =  ['per person', 'per event', 'per item', 'per kit', 'per box'];
export const EXPERIENCE_STATUS = [ 'Incomplete', 'Published', 'Ready'];
export const EXPERIENCE_ITEM_PRICE_TYPE =  ['per person', 'per event', 'per item', 'per kit', 'per box'];
export const EXPERIENCE_TYPE =  ['Baking Class', 'Beer Tasting', 'Cheese & Charcuterie Class', 'Chef Demo', 'Cocktail Class', 'Cooking Class', 'Creative', 'Floral Arranging', 'Food & Wine Tasting', 'Gift Boxes', 'Magic, Trivia & Games', 'Pasta or Pizza Class', 'Spirit Tasting', 'Wellness Class', ' Wine Tasting', 'Other'];
export const VENDOR_DEFAULT_ADDITIONAL_REQUIREMENTS =
  [{
      name: 'Technology Needs',
      isRequired: true,
      description: '***Confirming with VENDOR'
    },
    {
      name: 'Cooking Tools',
      isRequired: true,
     description: '***Confirming with VENDOR'
    },
    {
      name: 'Recipes',
      isRequired: true,
      description: '***Confirming with VENDOR'
    }
  ];

export const VENDOR_DEFAULT_TERMS_AND_CONDITIONS =
  [{
      termName: 'Deposit',
      isRequired: true,
      description: '***Confirming with VENDOR',
    },
    {
      termName: 'Cancellation Policy',
      isRequired: true,
      description: '***Confirming with VENDOR',
    },
    {
      termName: 'Shipping Guarantee',
      isRequired: true,
      description: '***Confirming with VENDOR',
    },
  ];
