// Sixplus Dependencies
import { ApiService } from 'spc/shared/api/api.service';
import { IContact } from 'spc/shared/contact.constant';
import moment from 'moment';
import commafy from 'commafy';

// External Dependencies
import { fullName, isActualUser } from 'common/dist/virtuals/User';
import BookingRequestHelpers from 'common/dist/virtuals/BookingRequest';


import ENUMS from 'common/dist/enums';
import price from 'common/dist/price';
import { set, cloneDeep, get } from 'lodash';
import _ from 'lodash';
import * as angular from 'angular';

// driver.js
import Driver from 'driver.js';

// Sixplus Types
import { RawRecommendation } from 'spc/lib/database/types/recommendation';
import { RawLead } from 'spc/lib/database/types/lead';

// Services
import { RecosService } from './recos.service';
import { RecommendationService } from 'spc/shared/recommendation.service';
import { MenuDisplayService } from './menu-display.service';
import { RecoTransferService } from './reco-transfer/reco-transfer.service';
import { UserService } from '../services/user.service';
import { RecosMapService } from './recos-map.service';
import { ServerSentEventsService } from 'spc/services/server-sent-events.service';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { RawConversation } from 'spc/lib/database/types/conversation';
import { RawBookingRequest } from 'spc/lib/database/types/booking-request';
import { RawLeadClient } from 'spc/lib/database/types/lead-client';
import { getCityNameFromValue } from 'spc/utils/getCityDisplayName';
import { ToastService } from 'spc/shared/toast.service';
import convertLinksToHyperlinks from 'spc/utils/convertLinksToHyperlinks';
import { fbMinRangeDollars, roomFeeRange } from 'common/dist/price';
import { ResultCardService } from 'spc/components/search/venue-search/result-card.service';
import { GoogleLocationService } from 'spc/lib/client/typescript/components/concierge/google-location-modal/google-location.service';
import { ArchiveService } from 'spc/shared/archive.service';

const YELLOW_BANNER_CLASS = 'yellow-banner';
const INCOMPLETE_BANNER_CLASS = 'incomplete-banner';
const GREEN_BANNER_CLASS = 'green-banner';
const RED_BANNER_CLASS = 'red-banner';
const HIDE_BANNER_CLASS = 'hide-banner';
const SUBMIT_REQUEST_CLASS = 'submit-request-banner';

const RECOS_LISTENER_INTERVAL = 30 * 1000;

const driver = new Driver({
  opacity: 0.2,
  doneBtnText: 'Get Started!',
  closeBtnText: 'Skip Tips',
  nextBtnText: 'Next Tip',
});

const commonStepsArray = [
  {
    element: '.red-wrapper',
    popover: {
      title: 'Hi there! 👋 Welcome to your Interactive Venue Page.',
      description: 'You\'ll be able to see all your details here, and communicate with our concierge specialist. Let\'s continue to see what you can do.',
      position: 'top-right',
      closeBtnText: 'Skip Tips',
      prevBtnText: ''
    }
  },
  {
    element: '.recos-nav-container',
    popover: {
      title: 'Viewing Options 👀',
      description: 'Prefer to look at a map? Choose your favorite view here.',
      position: 'top-center'
    }
  },
  {
    element: '.rc-messaging',
    popover: {
      title: 'Any questions or comments about a venue or space? 💬',
      description: 'It\'s just you and our concierge. Let us know what you think or if you have any questions. Chances are we\'ll have an answer for you.',
      position: 'top-right',
      closeBtnText: ''
    }
  },
];

const addVenues = {
  element: '#add',
  popover: {
    title: 'Add more venues',
    description: 'If you already have venues in mind, add them to your list here.',
    position: 'top-center',
    closeBtnText: ''
  }
};

const reactVenues = {
  element: '.rc-actions',
  popover: {
    title: 'Interested in a space? 👍 or 👎 ',
    description: 'Easily check availability and pricing by clicking thumbs up.',
    position: 'top-right'
  }
};


class RecosController {
  id: string;
  lead: RawLead;
  leads: RawLead[];
  allLeads: number;
  statuses: string[];
  tempName: string;
  recommendations: RawRecommendation[];
  archivedRecommendations: RawRecommendation[];
  client: any;
  messagebody: string;
  availabilities: string[];
  flickityOptions: any;
  recoOption: string = `DESCRIPTION`;
  cardOption: string = `CARDS`;
  flickityOptionsRecos: any;
  flickityOptionsDrinks: any;
  recosListener: EventSource;
  updatingInterest: boolean;
  showDetails: boolean = true;
  isNewUser: boolean;
  redirect: ({ url }: { url: string }) => any;
  sliderMessage: ({ message }: { message: string }) => any;
  ui: {
    editingName?: boolean,
    editingFbMin?: string,
    editingRoomRental?: string,
    editingEventTotal?: string,
    editAvailabilityStatus?: string
  } = {
      editingName: false,
      editingFbMin: '',
      editingRoomRental: '',
      editingEventTotal: '',
      editAvailabilityStatus: ''
    };
  user: UserService;
  justAdded: boolean;
  proposalData: {
    venue: any;
    space: any;
  } = {
    venue: {},
    space: {}
  };
  openRecoRequestModal: boolean = false;
  getCityNameFromValue = getCityNameFromValue;
  showNotesEditForReco: string = '';
  editCommentDetails: {
    id?: string,
    comment?: string,
  };
  deleteCommentId: string;
  convertLinksToHyperlinks = convertLinksToHyperlinks;
  clonedCustomRoomRent: any;
  clonedCustomFbMin: any;
  clonedCustomEventTotal: any;
  isPremiumUser: boolean;

  componentIsReady: () => any;
  constructor(
    private $api: ApiService,
    private $user: UserService,
    private unwrapError,
    private recommendationService: RecommendationService,
    private FlickityService,
    private $scrollService,
    private spaceDetailModal,
    private menuDetailModal,
    private drinksDetailModal,
    private recosService: RecosService,
    private CONTACT: IContact,
    private eventWithLiteModal,
    private $cloudinary,
    private photoDialog,
    private $interval,
    private recoRequestModal,
    private clientAddVenuesModal,
    private addSpaceForShellModal,
    private recoFeedbackModal,
    private menuDisplayService: MenuDisplayService,
    private recoTransferService: RecoTransferService,
    private recoTransferModal,
    private $timeout,
    private recosMapService: RecosMapService,
    private sendProposalModal,
    private proposalRequestAccess,
    private $scope,
    private serverSentEventsService: ServerSentEventsService,
    private obscuredLoginModal,
    private leadPermissionsModal,
    private manageCollaboratorsModal,
    private addCollaboratorsModal,
    private createEditEventModal,
    private recoOrderModal,
    private venueAttachmentModal,
    private missingEventDetailModal,
    private recosShareModal,
    private $location,
    private $window,
    private cspVideoPlayer,
    private $clickOk,
    private toast: ToastService,
    private resultCardService: ResultCardService,
    private googleLocationService: GoogleLocationService,
    private archiveService: ArchiveService
  ) {
    'ngInject';
    this.statuses = ENUMS.leadStatuses;
    this.availabilities = ENUMS.concierge.availabilities;
  }
  costBreakdown = price.fullPriceBreakdownDollars;

  $onInit = (): ng.IPromise<any> => {
    this.flickityOptionsRecos = {
      initialIndex: 0,
      cellAlign: 'left',
      freeScroll: true,
      contain: true,
      pageDots: false,
      groupCells: true
    };

    this.flickityOptionsDrinks = {
      initialIndex: 0,
      cellAlign: 'left',
      freeScroll: true,
      contain: true,
      pageDots: false,
      groupCells: true
    };

    this.recoTransferService.getUserLeads();
    return this.fetchAndSetData()
      .then(() => {
        this.user = this.$user;
        this.componentIsReady();
        this.setReviewStatus();
        this.handleDriver();
        this.setRecosListener();
        this.setIsPremiumUser();
        // if (this.shouldObscure()) {
        //   this.$timeout(this.obscureRecos, 7000);
        // }
      })
      .then(() => this.drawMarkers())
      .catch(error => this.unwrapError(error));
  }

  $onDestroy = () => {
    this.serverSentEventsService.stopTrackingLead(this.recosListener);
  }

  setIsPremiumUser = async () => {
    this.isPremiumUser = await this.user.isPremiumMember();
  }

  toggleNotesTextArea = (reco) => {
    this.showNotesEditForReco = this.showNotesEditForReco === reco._id.toString() ? '' : reco._id.toString();
    return;
  }

  backButton = () => '<';

  showVideoIcon(venue) {
    return this.resultCardService.showVideoIcon({ venue, isPremiumUser: this.isPremiumUser });
  }

  async getVideoPlayer(videos, venueName, venueAddress, venueSlug) {
    return this.resultCardService.getVideoPlayer({ isPremiumUser: this.isPremiumUser, videos, venueName, venueAddress, venueSlug });
  }

  shouldObscure = (): boolean => {
    if (!this.recommendations.length || this.$user.isLoggedIn() && isActualUser(this.$user.$)) {
      return false;
    }
    // if not, we will only obscure if there are no full users on the lead.
    return !this.lead.clients.some(client => isActualUser(client.user));
  }

  readOnly = (): boolean => {
    return !this.user.isLoggedIn();
  }

  private obscureRecos = () => {
    return this.obscuredLoginModal({
      // no primary on leads user at the moment
      primaryUser: this.lead.primaryClient,
      lead: this.lead,
      allowClose: true
    }).then((data) => {
      this.isNewUser = data.value.isNewUser;
      this.user = this.$user;
    })
    .catch(error => this.unwrapError(error));
  }

  private checkUser = () => {
    if (this.$user.isLoggedIn() &&
      !this.$user.isHostOrAdmin() &&
      !this.isUserOnLead()) {
        return this.addUser();
      }
  }

  private isUserOnLead = () => {
    const userId = this.$user.$._id.toString();
    const isPrimaryUser = (get(this.lead, 'primaryClient') as RawBaseUser) ? (get(this.lead, 'primaryClient') as RawBaseUser)._id.toString() === userId : false;
    let isLeadClient;
    if (this.lead && this.lead.clients && this.lead.clients.length) {
      isLeadClient = this.lead.clients.some(client => (client.user as RawBaseUser)._id.toString() === userId);
    }
    return isPrimaryUser || isLeadClient;
  }

  allowedToEditNotes = () => {
    return this.isUserOnLead();
  }

  private addUser = () => {
    return this.$api.Leads.addUser({ lead: this.lead, isNewUser: this.isNewUser })
      .then((res) => {
        this.lead.clients = res.users;
        this.justAdded = true;
      })
      .catch(err => this.unwrapError(err));
  }

  updateCustomFbMin = (reco) => {
    return this.$api.Recos.updateRecommendation(reco._id.toString(), { customFbMin: this.clonedCustomFbMin })
      .then((response) => {
        this.ui.editingFbMin = '';
        reco.customFbMin = response.recommendation.customFbMin;
        reco.fbMinInfo = this.recosService.getFbMin({ recommendation: reco, lead: this.lead });
      })
      .catch(error => this.unwrapError(error));
  }

  updateCustomRoomRental = (reco) => {
    return this.$api.Recos.updateRecommendation(reco._id.toString(), { customRoomRent: this.clonedCustomRoomRent })
      .then((response) => {
        this.ui.editingRoomRental = '';
        reco.customRoomRent = response.recommendation.customRoomRent;
      })
      .catch(error => this.unwrapError(error));
  }

  updateCustomEventTotal = (reco) => {
    return this.$api.Recos.updateRecommendation(reco._id.toString(), { customEventTotal: this.clonedCustomEventTotal })
      .then((response) => {
        this.ui.editingEventTotal = '';
        reco.customEventTotal = response.recommendation.customEventTotal;
      })
      .catch(error => this.unwrapError(error));
  }

  deleteAttachment = (reco, removedAttachment) => {
    reco.attachments = reco.attachments.filter(attachment => attachment.url !== removedAttachment.url);
    return this.$api.Recos.updateAttachments({ recoId: reco._id, attachments: reco.attachments });
  }

  openRecosShareModal = () => {
    return this.recosShareModal();
  }

  getDomain = () => {
    let domain = `${this.$location.protocol()}://${this.$location.host()}`;
    if (this.$location.port() !== 443) {
      domain = `${domain}:${this.$location.port()}`;
    }
    return domain;
  }

  openPermissionsModal = () => {
    const requests = this.recommendations.reduce((reqs, recommendation) => {
      if (recommendation.conversation && (recommendation.conversation as RawConversation).request) {
        reqs.push((recommendation.conversation as RawConversation).request);
      }
      return reqs;
    }, []);
    return this.leadPermissionsModal({ requests, lead: this.lead, userId: this.$user.$._id.toString() })
      .then((res) => {
        if (res.value.leadClients) {
          this.lead.clients = res.value.leadClients;
        }
        if (res.value.proposals) {
          this.recommendations = this.mapRequests(res.value.proposals);
        }
      });
  }

  mapRequests = (proposals) => {
    return this.recommendations.map((reco) => {
      if (reco.conversation && (reco.conversation as RawConversation).request) {
        const currentRequest = (reco.conversation as RawConversation).request as RawBookingRequest;
        const updatedRequest = proposals.find(request => request._id.toString() === currentRequest._id.toString());
        if (updatedRequest) {
          (reco.conversation as RawConversation).request = updatedRequest;
        }
      }
      return reco;
    });
  }

  isPrimaryClient = () => {
    return this.$user.$._id.toString() === (this.lead.primaryClient as RawBaseUser)._id.toString();
  }

  shouldBeVisible = (reco?) => {
    // the CTA should only be visible to primary client / admins / collaborators
    if (!this.$user.isLoggedIn()) {
      return false;
    }

    if (reco && reco.venue.status === 'Lite'  && (reco.createdThrough === 'Recos Venue Search' || reco.createdThrough === 'Event Request')) {
      return false;
    }

    const isAdmin = this.$user.isAdmin();
    const isPrimaryOrCollaborator = this.isUserOnLead();

    if (isAdmin || isPrimaryOrCollaborator) {
      return true;
    }
    return false;
  }

  shouldEditFbMin = () => {
    if (!this.$user.isLoggedIn()) {
      return false;
    }
    const isAdmin = this.$user.isAdmin();
    const isPrimaryOrCollaborator = this.isUserOnLead();
    if ((isAdmin || isPrimaryOrCollaborator) && !this.ui.editingFbMin) {
      return true;
    }
    return false;
  }

  openCollaboratorsModal = () => {
    const requests = this.recommendations.reduce((reqs, recommendation) => {
      if (recommendation.conversation && (recommendation.conversation as RawConversation).request) {
        reqs.push((recommendation.conversation as RawConversation).request);
      }
      return reqs;
    }, []);
    return this.manageCollaboratorsModal({ requests, lead: this.lead })
      .then((res) => {
        if (res.lead) {
          this.lead = res.lead;
        }
        if (res.proposals) {
          this.recommendations = this.mapRequests(res.proposals);
        }
      });
  }

  addLiteVenue(reco) {
    this.eventWithLiteModal({ status: reco.venue.status, venue: reco.venue, user: this.$user.$, showButton: false });
  }

  openAddCollaboratorsModal = () => {
    let user = this.$user.$ as RawBaseUser;
    if (this.$user.isAdmin()) {
      user = this.lead.primaryClient as RawBaseUser;
    }
    return this.addCollaboratorsModal({ user: user, lead: this.lead })
      .then((res) => {
        if (res.lead) {
          this.lead = res.lead;
        }
        if (res.proposals) {
          this.recommendations = this.mapRequests(res.proposals);
        }
      });
  }

  handleDriver = () => {
    if (this.shouldObscure()) {
      return;
    }
      this.$timeout(() => {
          if (this.lead.statuses['Client Review'].value === false && this.client.internal.leads.length < 2) {
            if (this.lead.statuses['Recos Sent'].value === false) {
              commonStepsArray.push(addVenues);
            } else {
                commonStepsArray.splice(2, 0, reactVenues);
              }
            // this.startTips();
          }
      }, 2000);
  }
  // tips functionally has been hidden temporarily.

  // startTips = () => {
  //   this.$timeout(() => {
  //     driver.defineSteps(commonStepsArray);
  //     driver.start();
  //   }, 1);
  // }

  messageHandler = (e) => {
    if (e.data === 'tracking') {
      return;
    }
    return this.$api.Recos.getRecos(this.lead._id.toString())
    .then((response) => {
      this.updateRecommendationChanges(response.recommendations);
      this.lead.messages = response.messages;
      this.lead.state = response.state;
      this.lead.request = response.request;
      this.menuDisplayService.updateStartTime({ startTime: this.lead.request.time });
    })
    .catch(error => this.unwrapError(error));
  }

  setRecosListener = () => {
    this.serverSentEventsService.stopTrackingLead(this.recosListener);
    this.recosListener = this.serverSentEventsService.startTrackingLead(this.lead);
    this.serverSentEventsService.setMessageHandler({
      listener: this.recosListener,
      messageHandler: this.messageHandler
    });
  }

  /**
   * Get the google maps url for the ng-href for a venue based on its coordinates
   */
  getGoogleMapsLink = (venue): string => {
    const name = get(venue, 'name');
    const str = `${name}`;
    const coordinates = get(venue, 'address.coordinates');
    if (!coordinates) {
      return null;
    }
    const coordinatePair = coordinates[1] + ',' + coordinates[0];
    return 'http://maps.google.com/?q=' + encodeURIComponent(str) + '&' + coordinatePair;
  }

  fetchAndSetData = (): ng.IPromise<any> => {
    return this.$api.Recos.getLead(this.id)
      .then(response => this.checkValidResponse(response))
      .then(response => this.setData(response))
      .catch(error => this.unwrapError(error));
  }

  setReviewStatus = (): ng.IPromise<any> => {
    if (!this.recommendations.length) {
      return;
    }
    return this.$api.Recos.underReview(this.lead._id.toString())
      .catch(error => this.unwrapError(error));
  }

  addComment = (recommendation, comment): ng.IPromise<any> => {
    // if (this.readOnly()) {
    //   return this.obscureRecos();
    // }
    return this.recommendationService.addComment(recommendation, comment)
      .then((response) => {
        recommendation.clientResponses = response.recommendation.clientResponses.reverse();
        this.toast.goodNews('Message sent successfully');
      })
      .catch(error => this.unwrapError(error));
  }

  isDerLead = () => {
    return this.recommendations.filter(reco => reco.createdThrough === 'Concierge').length;
  }

  openCreateEditEventModal = async () => {
    return await this.createEditEventModal({ editEvent: true, editableLead: cloneDeep(this.lead) })
      .then((res) => {
        if (res.value.lead) {
          this.$window.location.reload();
        }
      } );
  }

  requestProposal = (recommendation) => {
    // if (this.readOnly()) {
    //   return this.obscureRecos();
    // }

    if (this.updatingInterest || recommendation.conversation) {
      return;
    }
    this.updatingInterest = true;
    return this.$api.Recos.requestProposal(recommendation)
      .then((response) => {
        this.updatingInterest = false;
        recommendation.isInterested = response.recommendation.isInterested;
        recommendation.conversation = response.recommendation.conversation;
        recommendation.lastAvailability = response.recommendation.lastAvailability;
        recommendation.conversation = response.recommendation.conversation;
      })
      .catch((error) => {
        this.updatingInterest = false;
        this.unwrapError(error);
      });
  }

  changeInterest = (recommendation, value): ng.IPromise<any> => {
    if (this.readOnly()) {
      return this.obscureRecos();
    } else if (!this.shouldBeVisible()) {
      return this.proposalRequestAccess({ lead: this.lead });
    }

    if (recommendation.isInterested === value || this.updatingInterest) {
      return;
    }

    this.updatingInterest = true;
    const isInterested = value;
    const data = { isInterested, interestIndicationDate: Date.now(), 'activity.interest': true };

    return this.updateRecommendation({ recommendation, data })
      .then((response) => {
        this.updatingInterest = false;
        recommendation.isInterested = response.recommendation.isInterested;
        recommendation.conversation = response.recommendation.conversation;
        if (isInterested === 'Send Me A Proposal') {
          return this.recoRequestModal({
            origin: 'recommendation',
            recommendation,
            request: recommendation.conversation.request,
            conversation: get(recommendation, 'conversation'),
            lead: this.lead
          })
            .then((_data) => {
              const request = get<any>(_data, 'value.request');
              set(recommendation, 'conversation', { request, requestState: request.state, _id: request.conversation });
            });
        } else if (isInterested === 'Not A Good Fit') {
          return this.recoFeedbackModal(recommendation)
            .then((modalData) => {
              const reco = get<RawRecommendation>(modalData, 'value.recommendation');
              if (reco && reco.archived) {
                const existingReco = this.lead.recommendations.find(_reco => _reco._id.toString() === reco._id.toString());
                existingReco.archived = true;
                this.setSubmittedRecommendations(this.lead.recommendations);
              }
            })
            .catch(error => this.unwrapError(error));
        }
      })
      .catch(error => this.unwrapError(error));
  }

  continueProposal = (recommendation) => {
    if (this.readOnly()) {
      return this.obscureRecos();
    } else if (!this.shouldBeVisible()) {
      return this.proposalRequestAccess({ lead: this.lead });
    }
    return this.recoRequestModal({
      origin: 'recommendation',
      recommendation,
      conversation: recommendation.conversation,
      request: recommendation.conversation.request,
      lead: this.lead
    })
      .then((data) => {
        const request = get<any>(data, 'value.request');
        if (request) {
          recommendation.conversation.requestState = request.state;
          recommendation.conversation.request = request;
        }
      })
      .catch(error => this.unwrapError(error));
  }

  sendMessage = (): ng.IPromise<void> => {
    // if (this.readOnly()) {
    //   return this.obscureRecos();
    // }

    if (!this.messagebody) {
      return;
    }
    const data = {
      message: this.messagebody,
      client: this.client,
      leadId: this.lead._id.toString()
    };
    return this.$api.Recos.sendMessage(data)
      .then((response) => {
        this.sliderMessage({ message: 'Your message has been sent' });
        this.setMessages();
      })
      .catch(error => this.unwrapError(error));
  }

  updateRecommendation = ({ recommendation, data }): ng.IPromise<any> => {
    return this.$api.Recos.updateRecommendation(recommendation._id, data)
      .then((response) => {
        this.sliderMessage({ message: 'Your feedback has been received.' });
        return response;
      });
  }

  toggleEventDetails = () => {
    this.showDetails = !this.showDetails;
  }

  isValidLocation = () => {
    return ENUMS.acceptableUserCities.map(city => city.value).includes(get(this.lead, 'request.city'));
  }

  openClientAddVenueModal = () => {
    if (!this.isValidLocation()) {
      return;
    }
    this.addSpaceForShellModal(this.lead)
      .then(() => this.fetchAndSetData());

    // this code is commented for future.
    // if (this.lead.eventType === 'shellEvent') {
    //   this.addSpaceForShellModal(this.lead)
    //     .then(() => this.fetchAndSetData());
    // }
    // else {
    //   this.clientAddVenuesModal({ lead: this.lead })
    //     .then(() => this.fetchAndSetData());
    //   }
  }
  checkAction = (reco) => {
    if (reco.venue.status === 'Lite') {
      this.$clickOk(`This venue is not in our Partner Network. Please reach out directly to confirm pricing, availability, and to request a proposal. Pro Tip: Once you have inquired with the venue, you can update venue's availability, F&B min, Room Rental and total pricing in your SixPlus Event Page.`, false, 'Proceed to Venue Website', reco.venue.data.venueUrl);
    }
    else if (!reco.conversation || (get(reco, 'conversation.requestState') === 'INCOMPLETE')) {
      if (this.readOnly()) {
        return this.obscureRecos();
      } else if (!this.shouldBeVisible()) {
        return this.proposalRequestAccess({ lead: this.lead });
      }
      this.proposalData.venue = reco.venue;
      this.proposalData.space = reco.space;
      const params = { lead: this.lead,
        venue: this.proposalData.venue,
        menus: this.proposalData.venue.menus,
        drinks: this.proposalData.venue.drinks,
        addOns: this.getAddOns({ recommendation: reco, space: this.proposalData.space }),
        space: this.proposalData.space,
        origin: 'client-add-venue' };
        this.recoRequestModal(params)
          .then((data) => {
            this.$window.location.reload();
        });

    }
    else if (ENUMS.bookingRequestState.activeStates.includes(get(reco, 'conversation.requestState')) || ENUMS.bookingRequestState.cancelledStates.includes(get(reco, 'conversation.requestState'))) {
      const url = `/client/conversation/${reco.conversation._id.toString()}`;
      window.open(url);
    }
  }

  getAddOns = ({ recommendation, space }) => {
    return []
    .concat(get(recommendation, 'venue.data.addOns'), [])
    .concat(get(space, 'selectedSpace.data.addOns', []));
  }

  openRecoTransferModal = (recommendation) => {
    return this.recoTransferModal({ recommendation, lead: this.lead, update: this.updateRecommendationChanges });
  }

  openSendProposalModal = (recommendation) => {
    if (this.readOnly()) {
      return this.obscureRecos();
    } else if (!this.shouldBeVisible()) {
      return this.proposalRequestAccess({ lead: this.lead });
    }
    return this.sendProposalModal(recommendation)
      .then((response) => {
        const requiresProposal = response.value === 'Build My Proposal' || response.value === 'Send Me A Proposal';
        if (!requiresProposal) {
          return;
        }
        if ((!this.lead.request.numGuests || !this.lead.request.time || !this.lead.request.date) && response.value === 'Send Me A Proposal') {
          return this.missingEventDetailModal(this.lead)
            .then((data) => {
              if (data.value.proceed) {
                this.openCreateEditEventModal();
              }
            });
        }
        else if (response.value === 'Send Me A Proposal') {
          return this.requestProposal(recommendation);
        } else {
          return this.changeInterest(recommendation, 'Send Me A Proposal');
        }
      })
      .catch(error => this.unwrapError(error));
  }

  updateLiteReco = (recommendation, value) => {
    if (this.readOnly()) {
      return this.obscureRecos();
    }
    if (!this.shouldBeVisible()) {
      return this.proposalRequestAccess({ lead: this.lead });
    }
    this.updatingInterest = true;
    return this.$api.Recos.updateLiteReco(recommendation._id, value)
    .then((res) => {
      this.updatingInterest = false;
      recommendation.isInterested = res.data.isInterested;
      if (recommendation.isInterested === 'Disliked Lite' ) {
        return this.recoFeedbackModal(recommendation)
          .then((modalData) => {
            const reco = get<RawRecommendation>(modalData, 'value.recommendation');
            if (reco && reco.archived) {
              const existingReco = this.lead.recommendations.find(_reco => _reco._id.toString() === reco._id.toString());
              existingReco.archived = true;
              this.setSubmittedRecommendations(this.lead.recommendations);
            }
          })
          .catch(error => this.unwrapError(error));
      }

    })
    .catch(error => this.unwrapError(error));
  }

  updateAvailability = (recommendation: RawRecommendation, availabilityStatus?: string): ng.IPromise<any> => {
    if (availabilityStatus) {
      recommendation.lastAvailability.value = availabilityStatus;
    }
    const availabilityState = recommendation.lastAvailability.value;
    return this.$api.Recos.updateAvailability({ leadId: this.lead._id.toString(), recommendationId: recommendation._id.toString(), availabilityState })
      .then((response) => {
        recommendation.lastAvailability = response.recommendation.lastAvailability;
        this.ui.editAvailabilityStatus = '';
      })
      .catch(error => this.unwrapError(error));
  }

  openAttachmentsModal = (reco) => {
    this.venueAttachmentModal(reco)
      .then((data) => {
        if (!data.value.attachments) {
          return;
        }
        const attachments = data.value.attachments;
        this.recommendations = this.recommendations.map((recommendation) => {
          if (recommendation._id === reco._id) {
            recommendation.attachments = attachments;
          }
          return recommendation;
        });
      });
  }

  saveNotes = (reco) => {
    const recommendationId = reco._id;
    return this.$api.Recos.updateRecommendation(recommendationId, { clientNote: reco.clientNote })
      .then((response) => {
        this.sliderMessage({ message: 'Your Notes have been updated.' });
        return response;
      });
  }

  // Helpers
  checkValidResponse = (response) => {
    if (response.redirectUrl) {
      this.redirect({ url: response.redirectUrl });
      return;
    }
    return response;
  }

  getfbMin = (space) => {
    return price.fbMinRangeDollars(space) === '' || isNaN(price.fbMinRangeDollars(space)) ? 'TBD' : price.fbMinRangeDollars(space);
  }

  close = () => {
    this.openRecoRequestModal = false;
  }

  drawMarkers() {
    this.$timeout(() => {
      this.recosMapService.drawMarkers({ recos: this.recommendations, scope: this.$scope, lead: this.lead, shouldbeVisible: this.shouldBeVisible() });
    });
  }

  selectMarker(venueId) {
    this.recosMapService.selectMarker(venueId);
  }

  isSelectedRecommendation = (reco) => {
    return this.recosMapService.selectedVenueId === get(reco, 'venue._id', '').toString();
  }

  getDate(date) {
    return moment(date).local().format('ddd. MMM DD, YYYY');
  }

  setData = (response) => {
    if (!response) {
      return;
    }
    this.lead = response.lead;
    this.menuDisplayService.updateStartTime({ startTime: this.lead.request.time });
    this.setMessages();
    this.setSubmittedRecommendations(this.lead.recommendations);
    this.client = this.lead.primaryClient;
    this.client.fullName = fullName(this.client);
    this.flickityOptions = {
      wrapAround: true,
      cellAlign: 'left'
    };
    this.user = this.$user;
    if (this.lead.request.duration) {
      const duration = parseFloat(this.lead.request.duration as string);
      this.lead.request.duration = isNaN(duration) ? null : duration;
    }
  }

  setMessages = () => {
    this.messagebody = '';
  }

  handleSelectedSpace = (recommendation) => {
    if (!recommendation.conversation) {
      return;
    }
    const selectedSpace = BookingRequestHelpers.selectedSpace(recommendation.conversation.request);
    recommendation.conversation.request['selectedSpace'] = selectedSpace;

    // If the space on the conversation has changed and is different form the one on the recommendation, overwrite the space on the reco for visual purposes only.

    // We don't want to permanently change the recommendation because of a few reasons, one of which is that it would inaccurately represent which spaces were recommended. Additionally, we would have to maintain the change of space in two places.

    const selectedSpaceId = get(selectedSpace, '_id');

    if (!selectedSpaceId) {
      return;
    }

    if (selectedSpaceId.toString() !== recommendation.space._id.toString()) {
      recommendation.space = selectedSpace;
    }
  }

  setSubmittedRecommendations = (recommendations: RawRecommendation[]) => {
    this.recommendations = recommendations
      .filter(recommendation => recommendation.isRecommended && !recommendation.archived);

    this.archivedRecommendations = recommendations
      .filter(recommendation => recommendation.archived);

    this.menuDisplayService.addVenuesToList(this.recommendations.map(reco => reco.venue));

    this.drawMarkers();

    this.recommendations.forEach((recommendation) => {
      this.handleSelectedSpace(recommendation);
      recommendation.fbMinInfo = this.recosService.getFbMin({ recommendation, lead: this.lead });
      recommendation.timeSlot = this.getTimeSlot(recommendation);
      recommendation.clientResponses = recommendation.clientResponses.reverse();
      recommendation.venueCity = get(recommendation, 'venue.data.address.city');
    });
  }

  updateRecommendationChanges = (recommendations: RawRecommendation[]) => {
    recommendations = recommendations
      .filter(recommendation => recommendation.isRecommended);
    if ((this.recommendations.length + this.archivedRecommendations.length) !== recommendations.length) {
      return this.setSubmittedRecommendations(recommendations);
    }

    this.archivedRecommendations = recommendations
      .filter(recommendation => recommendation.archived);

    this.recommendations.forEach((reco: RawRecommendation) => {
      const updatedReco = recommendations.find(recommendation => recommendation._id.toString() === reco._id.toString());
      this.handleSelectedSpace(updatedReco);
      const fbMinInfo = this.recosService.getFbMin({ recommendation: updatedReco, lead: this.lead });
      const timeSlot = this.getTimeSlot(updatedReco);
      const lastAvailability = updatedReco.lastAvailability;
      const conversation = updatedReco.conversation;
      const order = updatedReco.order;
      const updated = {
        fbMinInfo,
        timeSlot,
        lastAvailability,
        conversation,
        order
      };

      if (reco.space._id.toString() !== updatedReco.space._id.toString()) {
        updated['space'] = updatedReco.space;
        this.$timeout(() => {
          const id = `carousel-${updatedReco._id}`;
          this.FlickityService.destroy(id);
          const element = angular.element(document.getElementById(id));
          this.FlickityService.create(element[0], id);
        });
      }
      Object.assign(reco, updated);
    });
  }

  openPhotoDialog = ({ photos, initialPhoto }): Promise<any> => {
    return this.photoDialog({ photos: photos, initialPhoto: initialPhoto });
  }

  getTimeSlot = (recommendation) => {
    if (recommendation.venue.status === 'Lite') {
      return;
    }
    return this.recosService.getTimeSlot({ space: recommendation.space, lead: this.lead });
  }

  getRoomFee = (recommendation) => {
    const roomFeeCents = get<number>(recommendation, 'timeSlot.terms.roomFeeCents');
    if (isFinite(roomFeeCents)) {
      return roomFeeCents / 100;
    }
  }

  displayViewProposalButton = (recommendation) => {
    return this.recommendationService.canViewProposal(recommendation);
  }

  archive = (reco) => {
    if (this.readOnly()) {
      return this.obscureRecos();
    }
    if (!this.shouldBeVisible()) {
      return this.proposalRequestAccess({ lead: this.lead });
    }
    return this.recommendationService.archive(reco)
      .then(() => {
        const existingReco = this.lead.recommendations.find(_reco => _reco._id.toString() === reco._id.toString());
        existingReco.archived = true;
        this.setSubmittedRecommendations(this.lead.recommendations);
      })
      .catch(error => this.unwrapError(error));
  }

  unarchive = (reco) => {
    return this.recommendationService.unarchive(reco)
      .then(() => {
        const existingReco = this.lead.recommendations.find(_reco => _reco._id.toString() === reco._id.toString());
        existingReco.archived = false;
        this.setSubmittedRecommendations(this.lead.recommendations);
      });
  }

  getButtonTextForShell = (reco) => {
    if (!reco.conversation && reco.venue.status !== 'Lite') {
      return 'Submit Request';
    }
    if (!reco.conversation && reco.venue.status === 'Lite') {
      return 'Inquire Directly';
    }
    else if (get(reco, 'conversation.requestState') === 'INCOMPLETE') {
      return 'Finalize Request';
    }
    else if (['PROPOSAL', 'COUNTEROFFER', 'REQUEST'].includes(get(reco, 'conversation.requestState'))) {
      return 'Awaiting Response';
    }
    else if (['ACCEPTED_HOST', 'DEPOSIT', 'ACCEPTED_GUEST', 'ALTERATION_ACCEPTED_GUEST', 'PRE_DEPOSIT_ALTERATION', 'POST_DEPOSIT_ALTERATION', 'ALTERATION_ACCEPTED_HOST', 'CANCELLED_REQUEST_GUEST', 'DECLINED_GUEST', 'DECLINED_HOST', 'CONCLUDED', 'RECONCILED', 'CLOSED'].includes(get(reco, 'conversation.requestState'))) {
      return 'View Proposal';
    }
    else if (ENUMS.bookingRequestState.cancelledStates.includes(get(reco, 'conversation.requestState'))) {
      return 'Message Venue';
    }
  }

  updateName = () => {
    this.lead.name = this.tempName;
    this.$api.Leads.update({ lead: this.lead })
      .then((response) => {
        this.lead.name = response.lead.name;
        this.ui.editingName = false;
      })
      .catch(error => this.unwrapError(error));
  }


  handleLinkClick = (recommendation) => {
    const url = this.determineProposalUrl(recommendation);
    window.open(url);
  }

  determineProposalUrl = (recommendation) => {
    return this.recommendationService.determineProposalUrl(recommendation);
  }

  // Stylistic Helpers

  getBannerClass = (reco) => {
    if (reco.isInterested === 'Not Sure' && !reco.conversation) {
      return YELLOW_BANNER_CLASS;
    }

    const requestState = get<string>(reco, 'conversation.requestState');

    if (!requestState && reco.venue.status !== 'Lite' && reco.createdThrough !== 'Concierge') {
      return SUBMIT_REQUEST_CLASS;
    }

    if (requestState === 'INCOMPLETE') {
      return INCOMPLETE_BANNER_CLASS;
    }

    if (requestState === 'PROPOSAL') {
      return;
    }

    if (requestState === 'PROPOSAL') {
      return;
    }

    const isNotGoodFit = reco.isInterested === 'Not A Good Fit';
    const isDisliked = reco.isInterested === 'Disliked Lite';
    const hasRedBannerState = ENUMS.bookingRequestState.cancelledStates.includes(requestState);
    const isNotAvailable = !get(reco, 'conversation.request') && reco.lastAvailability.value === 'Not Available';

    if (isNotGoodFit || hasRedBannerState || isNotAvailable || isDisliked) {
      return RED_BANNER_CLASS;
    }

    const hasGreenBannerState = ENUMS.bookingRequestState.activeSentProposalStates.includes(requestState);

    if (hasGreenBannerState || reco.isInterested === 'Liked Lite' && reco.createdThrough === 'Concierge') {
      return GREEN_BANNER_CLASS;
    }
    if (reco.isInterested === 'Liked Lite' && reco.createdThrough !== 'Concierge') {
      return HIDE_BANNER_CLASS;
    }
  }

  reorderRecos = () => {
    const allRecos = this.recommendations.filter(reco => reco.archived === false);
    return this.recoOrderModal(allRecos, this.lead._id)
      .then(response => response.value && Array.isArray(response.value) ? this.setSubmittedRecommendations(response.value) : null);
  }

  displayRequestGuestNum = () => {
    const minGuest = get(this.lead.request, 'numGuests.min');
    const maxGuest = get(this.lead.request, 'numGuests.max');
    if (minGuest && maxGuest) {
      return `${minGuest} - ${maxGuest}`;
    }
    return minGuest ? minGuest : maxGuest;
  }

  isEditDeleteButtonVisible = (message) => {
    const userId = get(this.$user, '$._id');
    if (!userId) {
      return false;
    }

    const isAdmin = this.$user.isAdmin();
    const messageSenderId = get(message, 'senderId') ? get(message, 'senderId').toString() : get(message, 'senderId');
    if (isAdmin || (userId.toString() === messageSenderId)) {
      return true;
    }
    return false;
  }

  deleteComment = (recommendation, message) => {
    return this.recommendationService.deleteComment(recommendation, message)
      .then((response) => {
        this.cancelUpdatingComment('delete');
        recommendation.clientResponses = response.recommendation.clientResponses.reverse();
        this.toast.goodNews('Message deleted successfully');
      })
      .catch(error => this.unwrapError(error));
  }

  editComment = (recommendation, message) => {
    const newComment = get(this.editCommentDetails, 'comment').trim();
    if (!newComment) {
      return this.toast.badNews('Please enter the message you want to edit.');
    }
    if (newComment === get(message, 'comment')) {
      return this.cancelUpdatingComment('edit');
    }
    return this.recommendationService.editComment(recommendation, this.editCommentDetails)
      .then((response) => {
        this.cancelUpdatingComment('edit');
        recommendation.clientResponses = response.recommendation.clientResponses.reverse();
        this.toast.goodNews('Message edited successfully');
      })
      .catch(error => this.unwrapError(error));
  }

  startUpdatingComment = (message, operation) => {
    if (operation === 'edit') {
      this.cancelUpdatingComment('delete');
      this.editCommentDetails = {
        id: get(message, '_id'),
        comment: get(message, 'comment')
      };
    }

    if (operation === 'delete') {
      this.cancelUpdatingComment('edit');
      this.deleteCommentId = get(message, '_id');
    }
  }

  cancelUpdatingComment = (operation) => {
    if (operation === 'edit') {
      this.editCommentDetails = {};
    }

    if (operation === 'delete') {
      this.deleteCommentId = '';
    }
  }

  startEditingRoomRental = (reco) => {
    this.ui.editingRoomRental = reco._id;
    this.clonedCustomRoomRent = get(reco, 'customRoomRent');
  }

  startEditingFbMin = (reco) => {
    this.ui.editingFbMin = reco._id;
    this.clonedCustomFbMin = get(reco, 'customFbMin');
  }

  startEditingEventTotal = (reco) => {
    this.ui.editingEventTotal = reco._id;
    this.clonedCustomEventTotal = get(reco, 'customEventTotal');
  }

  getDisplayDollarValue = (price) => {
    const formattedPrice = parseFloat(price);
    if (formattedPrice % 1 === 0) {
      return commafy(formattedPrice);
    }
    return commafy(formattedPrice.toFixed(2));
  }

  displayFbMinValue(reco) {
    if (get(reco, 'venue.status') === 'Lite') {
      if (reco.customFbMin) {
        return '$' + this.getDisplayDollarValue(reco.customFbMin);
      }
      return 'TBC';
    }

    if (reco.fbMinInfo.hidden ) {
      return 'Pending Confirmation';
    }

    const recoState = get(reco, 'conversation.requestState');
    if (!recoState || recoState === 'INCOMPLETE') {
      const fbMin = fbMinRangeDollars(reco.space);
      return (fbMin === 'Inquire to confirm minimum spend' || fbMin === 'No minimum spend') ? 'TBC' : fbMin || 'TBC';
    }

    if (['PROPOSAL', 'REQUEST'].includes(recoState)) {
      return (get(reco, 'fbMinInfo.number') && get(reco, 'fbMinInfo.message') !== '0.00') ? '$' + this.getDisplayDollarValue(get(reco, 'fbMinInfo.message')) : 'Pending Confirmation';
    }

    if (recoState === 'COUNTEROFFER') {
      if (get(reco, 'fbMinInfo.number')) {
        if (get(reco, 'fbMinInfo.message') === '0.00') {
          return 'Pending Confirmation';
        }
        return '$' + this.getDisplayDollarValue(get(reco, 'fbMinInfo.message'));
      }
    }

    return get(reco, 'fbMinInfo.number') ? '$' + this.getDisplayDollarValue(get(reco, 'fbMinInfo.message')) : 'TBC';
  }

  displayRoomRentValue = (reco) => {
    if (get(reco, 'venue.status') === 'Lite') {
      if (reco.customRoomRent) {
        return '$' + this.getDisplayDollarValue(reco.customRoomRent);
      }
      return 'TBC';
    }

    const recoState = get(reco, 'conversation.requestState');
    if (!recoState || recoState === 'INCOMPLETE') {
      return roomFeeRange(reco.space) || 'TBC';
    }

    if (['PROPOSAL', 'REQUEST'].includes(recoState)) {
      return get(reco, 'timeSlot.terms.roomFeeCents') ? '$' + this.getDisplayDollarValue(this.getRoomFee(reco)) : 'Pending Confirmation';
    }

    if (!get(reco, 'timeSlot.terms.roomFeeCents') && get(reco, 'conversation.request.host.roomFeeCents')) {
      return '$' + this.getDisplayDollarValue(reco.conversation.request.host.roomFeeCents / 100);
    }

    return '$0';
  }

  displayEventTotalValue = (reco) => {
    if (get(reco, 'venue.status') === 'Lite') {
      if (reco.customEventTotal) {
        return '$' + this.getDisplayDollarValue(reco.customEventTotal);
      }
      return 'TBC';
    }

    const recoState = get(reco, 'conversation.requestState');
    if (!recoState || recoState === 'INCOMPLETE') {
      return 'TBC';
    }

    const calculatedTotal = this.costBreakdown(reco.conversation.request, true).total;
    return calculatedTotal !== '$0.00' ? calculatedTotal : 'TBC';
  }

  startEditingAvailabilityStatus = (reco) => {
    if (this.ui.editAvailabilityStatus === reco._id) {
      this.ui.editAvailabilityStatus = '';
      return;
    }

    this.ui.editAvailabilityStatus = reco._id;
  }

  setGoogleLocation = async () => {
    const locationUpdateResult = await this.googleLocationService.setGoogleLocation(this.lead);
    if (locationUpdateResult) {
      this.$window.location.reload();
    }
  }

  archiveEvent = async () => {
    try {
      const archiveResponse = await this.archiveService.archiveEvent(this.lead);
      if (get(archiveResponse, 'isArchived')) {
        this.$window.location.reload();
      }
    } catch (error) {
      this.unwrapError(error);
    }
  }

  shouldAllowArchiveEvent = () => {
    if (!this.shouldBeVisible()) {
      return false;
    }

    if (['Lost', 'Killed'].includes(this.lead.currentStatus)) {
      return false;
    }

    const yesterday = moment().subtract(1, 'day');

    // check for any booked reco present in lead
    const bookedReco = this.lead.recommendations.find((reco) => {
      return ENUMS.bookingRequestState.bookedStates.includes(get(reco, 'conversation.request.state'));
    });

    // In case of booked reco, allow the user to archive the event only if the event date has not yet passed
    if (bookedReco) {
      set(bookedReco, 'eventDate', moment(get(bookedReco, 'conversation.request.data.date')).local());
      return get(bookedReco, 'eventDate') < yesterday ? false : true;
    }

    if (get(this.lead, 'request.date')) {
      set(this.lead, 'eventDate', moment(this.lead.request.date).local());
      return get(this.lead, 'eventDate') < yesterday ? false : true;
    }

    return true;
  }

}

export const ClientRecosComponent = {
  controller: RecosController,
  template: require('./recos.component.jade'),
  bindings: {
    id: '<',
    componentIsReady: '&',
    sliderMessage: '&',
    redirect: '&'
  }
};

