
import { ApiService } from 'spc/shared/api/api.service';
import capitalize = require('lodash/capitalize');
import { SpPardotService } from 'spc/shared/sp-pardot/sp-pardot.service';

// constant
import { ANALYTICS_EVENTS } from '../constants/ENUMS/analyticsEvents';

class FrozenRegistrationController {
  cities = ['New York', 'Boston', 'Washington'];
  close: ({ register }: { register: boolean }) => any;
  user: {
    profile: {
      name: {
        first: string;
        last: string;
      },
      email: string;
      newsletters: string[];
      city: string;
    }
  };

  password: string;

  ui: {
    error?: string;
    disableRegisterButton: boolean;
    wantsNewsletter: boolean;
  } = {
    disableRegisterButton: false,
    wantsNewsletter: true
  };

  constructor(private $user, private $api: ApiService, private unwrapError, private ENUMS, private $analytics, private spPardot: SpPardotService) {
    'ngInject';
  }

  $onInit = () => {
    this.user.profile.newsletters = ['Main'];
  }

  register = () => {
    this.ui.disableRegisterButton = true;
    const userData = {
      guest: this.user,
      password: this.password
    };

    return this.$api.Auth.register(userData)
      .then(res => this.handleRegisterSuccess(res.data))
      .catch((error) => {
        if (error.data) {
          this.ui.error = error.data.error.message;
          this.ui.disableRegisterButton = false;
        }
        this.unwrapError(error);
      });
  }

  handleRegisterSuccess = ({ user, token, provider }) => {
    try {
      this.$user.setUser(user, token, provider);
      // Analytics - register (alias) user, then identify
      this.$analytics.$register(this.$user.$);
        const eventName = provider ?
          'registered' + capitalize(provider) :
          'registered';
        this.$analytics.$trackEvent(ANALYTICS_EVENTS.auth[eventName]);
        const pardotEvent = this.$user.isHost() ? 'venue easy registration page' : 'client easy registration page';
        this.spPardot.trackUser({ event: pardotEvent });
        return this.close( { register: true });
    } catch (error) {
      this.close({ register: true });
      this.unwrapError(error);
    }
  }

  toggleNewsletters = () => {
    this.ui.wantsNewsletter = !this.ui.wantsNewsletter;
    const newsletters = this.ui.wantsNewsletter ? ['Main'] : [];
    this.user.profile.newsletters = newsletters;
  }
}

export const FrozenRegistrationComponent = {
  template: require('./frozen-registration.component.jade'),
  controller: FrozenRegistrationController,
  bindings: {
    user: '<',
    close: '&'
  }
};
