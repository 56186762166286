import payment from './payment';
import newCcForm from './newCCForm';
import paymentConfirmation from './paymentConfirmation';
import paymentSchedule from './paymentSchedule';

export default {
  payment,
  newCcForm,
  paymentConfirmation,
  paymentSchedule,
};
