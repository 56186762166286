// External Deps
import debounce from 'lodash/debounce';

// SP Deps
import { ApiService } from 'spc/shared/api/api.service';
import { PersonalEventsDashboardService } from './personal-events-dashboard.service';
import { TabulationService, TabManager } from 'spc/shared/tabulation/tabulation.service';

// SP Interfaces
import { EventStates, GetEventsQuery, NUM_EVENTS_PER_PAGE } from 'server/api/requests/models';
import { RawBookingRequest } from 'database/types/booking-request';

class PersonalEventsDashboardController {
  componentIsReady: () => void;
  role: 'venue' | 'client';
  tabManager: TabManager;
  search: GetEventsQuery;
  actionItems: RawBookingRequest[];
  idleItems: RawBookingRequest[];
  count: number;
  NUM_PER_PAGE: NUM_EVENTS_PER_PAGE = 10;

  EVENT_DATE_DESCENDING = {
    name: 'Event Date',
    order: 'Descending',
    path: 'data.date'
  };

  EVENT_DATE_ASCENDING = {
    name: 'Event Date',
    order: 'Ascending',
    path: 'data.date'
  };

  SUBMITTED_DATE = {
    name: 'Most Recently Sent By Clients',
    order: 'Descending',
    path: 'history.0.time'
  };


  sortOptions = [
    this.EVENT_DATE_DESCENDING,
    this.EVENT_DATE_ASCENDING,
    this.SUBMITTED_DATE
  ];

  constructor(
    private $api: ApiService,
    private personalEventsDashboardService: PersonalEventsDashboardService,
    private tabulationService: TabulationService,
    private unwrapError,
    private newProposalModal,
    private VENUE_STATES,
    private CLIENT_STATES
  ) {
    'ngInject';
  }

  $onInit() {
    const states: EventStates[] = this.role === 'venue' ? this.VENUE_STATES : this.CLIENT_STATES;
    const initialState = states[0];

    this.tabManager = this.tabulationService.createTabManager(states, initialState);
    this.search = { page: 0, state: initialState };
    this.search.sort = this.getDefaultSortOptions();
    this.getEvents()
      .then(() => this.componentIsReady())
      .catch(error => this.handleHttpError(error));
  }

  getDefaultSortOptions = () => {
    if (this.search.state === 'in play') {
      return this.EVENT_DATE_ASCENDING;
    } else if (this.search.state === 'confirmed') {
      return this.EVENT_DATE_ASCENDING;
    } else if (this.search.state === 'cancelled') {
      return this.SUBMITTED_DATE;
    } else if (this.search.state === 'concluded') {
      return this.EVENT_DATE_DESCENDING;
    }
  }

  setState = (state: EventStates) => {
    this.tabManager.switchTab(state);
    this.search.state = state;
    this.search.page = 0;
    this.search.sort = this.getDefaultSortOptions();
    return this.getEvents()
      .catch(error => this.handleHttpError(error));
  }

  loadPage = (idx: number) => {
    this.search.page = idx;
    return this.getEvents()
      .catch(error => this.handleHttpError(error));
  }

  getEvents = () => {
    return this.personalEventsDashboardService
      .getEvents({ role: this.role, search: this.search })
      .then((data) => {
        this.count = data.count;
        this.actionItems = data.actionItems;
        this.idleItems = data.idleItems;
      });
  }

  debouncedGetEvents = debounce(() => {
    this.search.page = 0;
    this.getEvents();
  }, 300);

  openNewProposalModal = () => {
    return this.$api.Venues.hosts.getPublishedAndPrivate()
      .then(response => this.newProposalModal(false, response.data.data.venues))
      .catch(error => this.handleHttpError(error));
  }

  handleHttpError = (error: Error) => {
    this.unwrapError(error);
  }

  selectSortOption = (option) => {
    this.search.sort = option;
    this.loadPage(0);
  }
}

export const PersonalEventsDashboardComponent = {
  template: require('./personal-events-dashboard.component.jade'),
  controller: PersonalEventsDashboardController,
  bindings: {
    componentIsReady: '&',
    role: '@'
  }
};
