import cloneDeep from 'lodash/cloneDeep';
import { ApiService } from 'spc/shared/api/api.service';

/**
 *
 * This component is linked to a directive `attachment-upload`
 * which requires a function: onUpload.
 *
 * The attachment-upload directive is only concerned with uploading
 * a file to cloudinary. It will upload the file then execute the
 * attachment-upload function which should be defined here.
 *
 */

export const organizeAttachmentsModal = ['ngDialog', function (ngDialog) {
  return function (source, conversation, message) {
    return ngDialog.open({
      template: require('./attachments-modal.jade'),
      className: 'ngdialog-theme-plain',
      overlay: false,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', '$cloudinary', 'unwrapError', '$api', function ($scope, $cloudinary, unwrapError, $api: ApiService) {

        this.init = () => {

          this.attachments = source.attachments
            .map(attachment => cloneDeep(attachment));
          this.setDisplayedAttachments();
          this.setCanSubmit();

          this.buttonText = 'I\'m done';
          this.$cloudinary = $cloudinary;
        };

        ///// Listeners
        $scope.$on('FILE_UPLOADING', () => {
          this.buttonText = 'Uploading...';
        });

        $scope.$on('FILE_UPLOADED', () => {
          this.buttonText = `I'm finished`;
          this.setCanSubmit();
        });

        this.handleUpload = (url: string, title: string) => {
          const messageId = message ? message._id.toString() : null ;
          const attachment = {
            conversation: conversation._id.toString(),
            message: message,
            file: {
              url,
              title
            },
            isNew: true
          };
          this.attachments.push(attachment);
          this.setDisplayedAttachments();
        };

        this.setDisplayedAttachments = () => {
          this.displayedAttachments = this.attachments
            .filter(attachment => !attachment.isDeleted && attachment.file.title !== 'Link');
        };

        this.setCanSubmit = () => {
            this.canSubmit =  this.attachments.every(attachment => attachment.file.title);
        };

        this.removeAttachment = (attachment) => {
          attachment.isDeleted = true;
          this.setDisplayedAttachments();
        };

        this.finish = () => {
          if (this.canSubmit) {
            const attachments = this.attachments;
            return $api.Conversations.updateAttachments({ attachments, conversation })
              .then(data => $scope.closeThisDialog({ conversation: data.conversation }))
              .catch(error => unwrapError(error));
          } else {
            return Promise.resolve();
          }
        };

        this.init();
      }]
    }).closePromise;
  };
}];
