import get from 'lodash/get';

import { ApiService } from 'spc/shared/api/api.service';
import { RawCompany } from 'spc/lib/database/types/company';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { RawBookingRequest } from 'spc/lib/database/types/booking-request';

class AdminCompaniesDataController {
  company: RawCompany;
  users: RawBaseUser[];
  requests: RawBookingRequest[];
  totalBookings: number;
  updateDisplay: (company: RawCompany) => void;
  ui: {
    addingDomain?: boolean;
    newDomain?: string;
    addingAcronym?: boolean;
    newAcronym?: string;
    editingName?: boolean;
    newName?: string;
  } = {};

  constructor(private $api: ApiService, private unwrapError, private $q, private $clickOk, private userTransferModal) {
    'ngInject';
  }

  $onChanges = (data) => {
    if (data.company) {
      this.ui = {};
      this.getCompanyDetails();
    }
  }

  toggleDataSidebar: () => any;

  // API Calls
  getCompanyDetails = () => {
    this.users = [];
    this.requests = [];

    return this.$api.Admin.Companies.getById(this.company._id)
      .then((data) => {
        this.company = data.company;
        this.users = data.users;
        this.requests = data.requests;
        this.setTotalBookings();
      })
      .catch(error => this.unwrapError(error));
  }

  update = (changes) => {
    return this.$api.Admin.Companies.update({ company: this.company, changes })
      .then((data) => {
        Object.assign(this.company, data.company);
        this.updateDisplay(this.company);
      })
      .catch(error => this.unwrapError(error));
  }

  removeDomain = (domain) => {
    this.company.domains = this.company.domains.filter(_domain => _domain !== domain);
    return this.update({ domains: this.company.domains });
  }

  addDomain = (domain) => {
    const domainExists = this.company.domains.find(_domain => _domain.toLowerCase().trim() === domain.toLowerCase().trim());
    if (domainExists) {
      return this.$q.resolve();
    }
    this.company.domains.push(domain);
    return this.update({ domains: this.company.domains })
      .then(() => this.stopAddingDomain())
      .catch(error => this.unwrapError(error));
  }

  removeAcronym = (acronym) => {
    this.company.acronyms = this.company.acronyms.filter(_acronym => _acronym !== acronym);
    return this.update({ acronyms: this.company.acronyms });
  }

  addAcronym = (acronym) => {
    const acronymExists = this.company.acronyms.find(_acronym => _acronym.toLowerCase().trim() === acronym.trim().toLowerCase());
    if (acronymExists) {
      return this.$q.resolve();
    }
    this.company.acronyms.push(acronym);
    return this.update({ acronyms: this.company.acronyms })
      .then(() => this.stopAddingAcronym())
      .catch(error => this.unwrapError(error));
  }

  saveName = (name) => {
    return this.update({ name })
      .then(() => this.stopEditingName())
      .catch(error => this.unwrapError(error));
  }

  disassociateUser = (user) => {
    const showCancel = true;
    this.$clickOk('You sure you wanna do this, bud? This user and all their events will no longer be associated with the company.', showCancel)
      .then((response) => {
        if (get(response, 'value.cancel')) {
          return;
        }
        return this.$api.Admin.Users.update({ _id: user._id, company: null })
          .then(() => this.getCompanyDetails())
          .catch(error => this.unwrapError(error));
      });
  }

  transferUsers = () => {
    this.userTransferModal({ users: this.users, company: this.company })
      .then(() => this.getCompanyDetails())
      .catch(error => this.unwrapError(error));
  }

  // UI Manipulators
  startAddingDomain = () => {
    this.ui.addingDomain = true;
  }

  stopAddingDomain = () => {
    this.ui.addingDomain = false;
    this.ui.newDomain = null;
  }

  startAddingAcronym = () => {
    this.ui.addingAcronym = true;
  }

  stopAddingAcronym = () => {
    this.ui.addingAcronym = false;
    this.ui.newAcronym = null;
  }

  startEditingName = () => {
    this.ui.editingName = true;
  }

  stopEditingName = () => {
    this.ui.editingName = false;
    this.ui.newName = null;
  }

  // Helpers
  setTotalBookings = () => {
    if (!this.requests || !this.requests.length) {
      this.totalBookings = 0;
    }
    this.totalBookings = this.requests.reduce((total, request) => {
      total += request.totalCostBreakdownCents.amountPaid;
      return total;
    }, 0);
  }

  findBookingUser = (request) => {
    return this.users.find(user => request.clients.find(client => client.user === user._id));
  }
}

export const adminCompaniesDataComponent = {
  template: require('./admin-companies-data.component.jade'),
  controller: AdminCompaniesDataController,
  bindings: {
    company: '<',
    toggleDataSidebar: '&',
    updateDisplay: '<'
  }
};
