import * as CONSTANTS from '../../../../../database/constants/Experience';
import { ApiService } from '../../../shared/api/api.service';
import { get, every, filter, cloneDeep, isEqual, debounce } from 'lodash';
import { VIRTUAL_EVENTS_CHANGE } from '../../../../../database/constants/virtualEventsChange';
import {
  Item, RawVendor
} from 'spc/lib/database/types/virtual-events';
import { RawPhoto } from 'spc/lib/database/types/photo';
const newItem: Item = {
  name: '',
  description: '',
  photos: [],
  coverIndex: 0,
  price: {
    priceType: 'per person',
    priceValue: null,
  },
};
class ItemsController {
  currentItemIndex: number;
  isFormDisplayed: boolean = false;
  item: Item = cloneDeep(newItem);
  items: Item[] = [];
  vendor: RawVendor;
  errors: {
    [key: string]: {
      message: string
    }
  } | null = {};
  priceTypes: string[] = CONSTANTS.EXPERIENCE_ITEM_PRICE_TYPE;
  isItemEdit: boolean = false;
  photos: RawPhoto[] = [];
  type: string;
  object: any;
  updateReadyStatus: any;

  constructor(
    private unwrapError,
    private photoDialog,
    private $cloudinary,
    private $api: ApiService,
    private $scope
  ) {
    'ngInject';
  }

  $onInit = () => {
    if (this.object && this.object.items) {
      this.items = cloneDeep(this.object.items);
    }
    this.$scope.$on(VIRTUAL_EVENTS_CHANGE.VIRTUAL_ITEMS_CHANGE, this.cloneOriginalItemToItem);
  }

  cloneOriginalItemToItem = () => {
    this.items = cloneDeep(this.object.items);
  }

  validateItem = (path) => {
    const currentItemPath = `items.${this.currentItemIndex}.${path}`;
    if (this.type === 'vendor') {
      this.$api.Admin.Vendors.validate({ vendor: { items: this.items } })
        .then((response) => {
          const errors = get(response, 'errors.vendor');
          if (errors) {
            const pathErrors = get(errors, currentItemPath);
            this.errors[currentItemPath] = pathErrors
              ? pathErrors.message
              : null;
          } else {
            this.errors = {};
          }
        })
        .catch((error) => {
          this.unwrapError(error);
        });
    } else if (this.type === 'experience') {
      const experience = cloneDeep(this.object);
      experience.items = this.items;
      this.$api.Admin.Experience.validate({
        experience,
        vendorId: this.vendor['_id'],
      })
        .then((response) => {
          const errors = get(response, 'errors.experience');
          if (errors) {
            const pathErrors = get(errors, currentItemPath);
            this.errors[currentItemPath] = pathErrors
              ? pathErrors.message
              : null;
          } else {
            this.errors = {};
          }
        })
        .catch((error) => {
          this.unwrapError(error);
        });
    }
  }

  checkForErrors = debounce(this.validateItem, 300);

  savePhotos = (photos, coverIndex) => {
    this.item.photos = photos;
    if (coverIndex !== undefined) {
      this.item.coverIndex = coverIndex;
    }
  }

  createNewItem = () => {
    this.displayForm(true);
    const length = this.items.push(this.item);
    this.currentItemIndex = length - 1;
  }

  resetItem = () => {
    this.item = cloneDeep(newItem);
  }

  displayForm(value) {
    this.isFormDisplayed = value;
  }

  cancel() {
    if (!this.isItemEdit) {
      this.items = filter(
        this.items,
        (_, index) => index !== this.currentItemIndex
      );
    }
    else {
      this.items[this.currentItemIndex] = cloneDeep(
        this.object.items[this.currentItemIndex]
      );
    }
    this.resetItem();
    this.isItemEdit = false;
    this.displayForm(false);
  }

  selectPriceType = (selectedValue) => {
    this.item.price.priceType = selectedValue;
  }

  isFieldInvalid = (path) => {
    const fieldPath = `items.${this.currentItemIndex}.${path}`;
    return this.errors[fieldPath] ? true : false;
  }

  getErrorMessage = (path) => {
    const fieldPath = `items.${this.currentItemIndex}.${path}`;
    return this.errors[fieldPath];
  }

  submit = () => {
    if (this.type === 'vendor') {
      this.updateVendor();
    }
    else if (this.type === 'experience') {
      this.updateExperience();
    }
  }

  updateUi = () => {
    this.displayForm(false);
    this.resetItem();
    this.isItemEdit = false;
    if (this.type === 'experience') {
      this.updateReadyStatus();
    }
  }

  updateVendor = () => {
    const updates = { items: this.items };
    this.$api.Admin.Vendors.updateVendorById({
      updates,
      vendorId: this.object['_id'],
    }).then((res) => {
        this.items = res.vendor.items;
        this.updateUi();
    })
    .catch((err) => {
      this.unwrapError(err);
    });
  }

  updateExperience = () => {
    const updates = { items : this.items };
    this.$api.Admin.Experience.updateExperienceById({
      updates,
      experienceId : this.object['id']
    })
    .then((response) => {
      this.items = cloneDeep(response.experience.items);
      this.object.items = cloneDeep(response.experience.items);
      this.object.status = response.experience.status;
      this.updateUi();
    })
    .catch((err) => {
      this.unwrapError(err);
    });
  }
  checkIfFormDataInvalid = () => {
    const errorsPath = Object.keys(this.errors);
    let result = null;
    errorsPath.forEach((path) => {
      result = this.errors[path] || result;
    });
    return result;
  }

  hasAllTheRequiredFields = () => {
    const requiredFields = [
      get(this.item, 'name'),
      get(this.item, 'description')
    ];
    return every(requiredFields);
  }

  edit = (index) => {
    this.item = this.items[index];
    this.displayForm(true);
    this.currentItemIndex = index;
    this.isItemEdit = true;
  }

  delete = (itemIndex) => {
    const itemToDelete = this.items[itemIndex];
    if (isEqual(this.item, itemToDelete)) {
      this.item = cloneDeep(newItem);
      this.displayForm(false);
    }
    this.items = filter(this.items, (_, index) => index !== itemIndex);
    if (this.type === 'experience') {
      this.updateExperience();
    }
    else if (this.type === 'vendor') {
      this.updateVendor();
    }
  }
}

export const VirtualEventsItemsComponent = {
  controller: ItemsController,
  template: require('./virtual-events-items.component.jade'),
  bindings: {
   updateReadyStatus: '<',
    vendor: '<',
    type: '<',
    object: '<'
  },
};
