class TopLevelAdminCalendarController {
  constructor(
    private $seo,
    private $rootScope
  ) {
    'ngInject';
  }

  componentIsReady = () => {
    this.$seo.set(`Admin Calendar`);
    this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
  }
}

export const TopLevelAdminCalendarComponent = {
  controller: TopLevelAdminCalendarController,
  template: require('./top-level-admin-calendar.component.jade')
};
