import { ApiService } from 'spc/shared/api/api.service';
import { ToastService } from '../../shared/toast.service';
import { fullPriceBreakdownCents, fullPriceBreakdownDollars } from 'common/dist/price';

import get from 'lodash/get';

export class AdminEventsService {
  assignees: any[];

  constructor(private $api: ApiService, private toast: ToastService, private unwrapError, private $q) {
    'ngInject';
  }

  closePostDepositCancellations = (request) => {
    const update = {
      _id: request._id.toString(),
      'admin.payout.status': null
    };
    return this.$api.Admin.Requests.save(update)
      .then(() => request.admin.payout.status = null);
  }

  calculateVenuePayout = (request, payout?) => {
    if (!request || !fullPriceBreakdownDollars(request)) {
      return;
    }

    const totalPayout = payout ? payout : get(fullPriceBreakdownCents(request), 'payout');
    if (!request.admin.venuePayments) {
      return (totalPayout / 100).toFixed(2);
    }

    const pastPayments = request.admin.venuePayments.map(payment => payment.amountDollars * 100);
    const actualPayout = pastPayments.reduce((payout, pastPayment) => {
      return payout - pastPayment;
    }, totalPayout);

    return (actualPayout / 100).toFixed(2);
  }

  handleStateChange(request: any, state: string) {
    if (!request.conversation) {
      this.toast.badNews('Request State Change Error', 'A request must be complete before you can change the state');
      return this.$q.reject();
    }

    if (request.state === state) {
      return this.$q.resolve();
    }

    let paidDirectlyToVenue = false;
    if (state === 'CLOSED') {
      const venuePayoutAmount = get(request, 'admin.payout.amountDollars') || this.calculateVenuePayout(request);
      if (venuePayoutAmount && venuePayoutAmount < 0) {
        paidDirectlyToVenue = true;
      }
    }
    return this.$api.Admin.Requests.changeState(request, state, paidDirectlyToVenue)
      .then(() => {
        request.state = state;
        this.stateChangeToast(state);
      });
  }

  private stateChangeToast(state: string) {
    this.toast.goodNews('Request State changed', `Request state successfully changed to ${state}`);
  }

  getAssignees = () => {
    if (this.assignees) {
      return this.$q.resolve(this.assignees);
    }
    return this.$api.Admin.Users.getAssignees({ team: 'Concierge' })
      .then((response) => {
        this.assignees = response.data.assignees;
        return this.assignees;
      });
  }

  getAuthorizedUsers = (request) => {
    const venue = request.venue;
    return this.$api.Venues.getAuthorizedUsers(venue._id)
      .then(data => data.authorizedUsers);
  }

  saveAssignee = (request, assignee) => {
    return this.$api.Admin.Requests.savePaths({ request, pathsAndValues: { setPath: `admin.assignee`, value: assignee._id } });
  }
}

