import moment from 'moment';
import commafy from 'commafy';

import { each, flatten, keys, groupBy, map, sum, values } from 'lodash';
// SixPlus Deps
import { EventResult } from 'shared/interfaces/metrics/event-result.model';
import { ProposalMetricsSearchService } from './proposal-metrics-search.service';
import { ProposalMetricsService } from './proposal-metrics.service';
import { DateGroupedEvents, TimeLabels, DataMap, ProposalMetricsDataService } from './proposal-metrics-data.service';
import { MappedEventResult } from './mapped-event-result.model';
import { TimeOptions } from './time-options.model';
import { ChartOptions, ComposeChartService } from 'spc/shared/metrics/compose-chart.service';

interface Changes {
  results: any;
}


let ctrl;
class ProposalMetricsChartController {
  sumStrategy: string;
  search: ProposalMetricsSearchService;
  dataService: ProposalMetricsDataService;
  events: DateGroupedEvents[];
  cities: string[];
  labels: string[];
  times: string[];
  isoDates: string[];
  dataMap: DataMap;

  chartOptions: ChartOptions;

  constructor(
    private proposalMetricsSearchService: ProposalMetricsSearchService,
    private proposalMetricsDataService: ProposalMetricsDataService,
    private composeChartService: ComposeChartService,
    private unwrapError,
    private ngDialog
  ) {
    'ngInject'
    ctrl = this;
    const self = this;
    self.search = proposalMetricsSearchService;
    self.dataService = proposalMetricsDataService;
  }

  $onInit() {
    const ctrl = this;
    ctrl.chartType = 'bar';

    this.options = {
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: ctrl.yAxisLabel || ctrl.unit || `forgot dis label ¯\\_(ツ)_/¯`
            },
            ticks: {
              callback: function(label, index, labels) {
                if (ctrl.unit === 'money') {
                  return `$${ commafy(label) }`;
                } else {
                  return label;
                }
              }
            }
          }
        ]
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            const item = data.datasets[tooltipItem.datasetIndex];
            const val = ctrl.events[tooltipItem.datasetIndex][tooltipItem.index];
            return `${ item.label } ${ ctrl.formatValue(val) }`;
          }
        }
      },
      onClick: ctrl.onClickBar
    };

  }

  $onChanges(changes: Changes) {
    const self = this;
    if (changes.results.currentValue) {
      const results = changes.results.currentValue;
      const timeOptions = self.search.getTimeOptions();

      const data = self.dataService.mapData({
        labels: ctrl.labels,
        data: results,
        timeOptions,
        summer: self.mapFunc
      });

      self.dataMap = data.dataMap;
      self.events = data.events;
      self.labels = map(data.labels, label => label.label);
      self.isoDates = map(data.labels, label => label.isoDate);
    }
  }

  setChartType(type) {
    this.chartType = type;
  }


  /**
   * This is not this controller but the chart
   */
  public onClickBar(mouseEvt: MouseEvent, charts: any[]) {
    const chartElems = this.getElementAtEvent(mouseEvt);
    const chartElemClicked = chartElems ? chartElems[0] : null;
    if (!chartElemClicked) {
      return;
    }

    const cityIdx = chartElemClicked._datasetIndex;
    const timeBucketIdx = chartElemClicked._index;
    const data: EventResult[] = ctrl.dataMap[cityIdx][ctrl.isoDates[timeBucketIdx]];
    return ctrl.ngDialog.open({
      plain: true,
      template: require('./display-metrics-breakdown.jade'),
      className: 'ngdialog-theme-plain',
      overlay: false,
      controller: ['$scope', function ($scope) {
        $scope.data = data;
        $scope.startDate = ctrl.isoDates[timeBucketIdx];
        $scope.endDate = getEndDate(timeBucketIdx);
        $scope.city = ctrl.cities[cityIdx];
        $scope.getClientName = event => event.client.name;
        $scope.getCompanyName = event => event.client.company;

        function getEndDate(timeIdx) {
          if (ctrl.isoDates[timeIdx + 1]) {
            return ctrl.isoDates[timeIdx + 1];
          }
          const startRef = moment($scope.startDate);
          const diff = startRef.diff(ctrl.isoDates[timeIdx - 1]);
          return startRef.add(diff, 'ms').format('YYYY-MM-DD');
        }
      }]
    })
      .closePromise
      .catch(ctrl.unwrapError);
  }

  private formatValue(value) {
    const self = this;

    if (self.unit === 'money') {
      return `$` + commafy(value.toFixed(2));
    } else {
      return `${ value } ${ self.unit }`;
    }
  }
}

/**
 * Presentational component that transforms data passed to it to display the event revenue grand totals for
 * a given time period
 */
export const ProposalMetricsChartComponent = {
  bindToController: true,
  controller: ProposalMetricsChartController,
  template: require('./proposal-metrics-chart.component.jade'),
  bindings: {
    results: '<',
    chartTitle: '@',
    mapFunc: '<',
    unit: '@',
    cities: '<',
    sumStrategy: '@',
    labels: '<',
    yAxisLabel: '@'
  }
};
