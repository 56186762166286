// External Deps

// SP Deps
import { ApiService } from 'spc/shared/api/api.service';
import { AdminConversationsService } from './admin-conversations.service';
import keys from 'lodash/keys';

// Interfaces
import { DistinctCity } from 'server/api/admin/search/models';


class AdminConversationsDashboardController {
  componentIsReady: () => void;
  search: { page: number } = { page: 0 };
  states: string[];
  counts: any;
  cities: string[];
  unreadStatuses: string[];
  conversationTypes: string[];
  searchParams: any = {};
  results: any;
  currentPage: number = 1;
  distinctCities: DistinctCity[];
  displayCitySelector: boolean = false;
  constructor(
    private $api: ApiService,
    private unwrapError,
    private ENUMS,
    private adminConversationsService: AdminConversationsService
  ) {
    'ngInject';
    this.cities = keys(ENUMS.cities);
    this.states = ENUMS.bookingRequestState;
    this.unreadStatuses = ['Unread for Client', 'Unread for Venue'];
    this.conversationTypes = ['Inquiry', 'Request', 'Proposal'];
  }

  $onInit() {
    this.getCounts()
      .catch(error => this.handleHttpError(error));
    this.fetchConversations()
      .then(() => this.componentIsReady())
      .catch(error => this.handleHttpError(error));
    this.fetchDistinctCities()
      .catch(error => this.handleHttpError(error));
  }

  getCounts = () => {
    return this.$api.Admin.Conversations.getCounts(this.search)
      .then(data => this.counts = data.counts);
  }

  fetchDistinctCities = () => {
    return this.$api.Admin.Search.locations('bookingRequests')
      .then(response => this.distinctCities = response.distinctCities);
  }

  toggleCityDisplay = () => {
    this.displayCitySelector = !this.displayCitySelector;
  }

  closeCitySelector = (selectedCities) => {
    this.searchParams.cities = selectedCities;
    this.displayCitySelector = false;
  }

  searchRequests() {
    if (this.searchParams.client) {
      this.searchParams.client = this.searchParams.client.split('@')[0];
    }
    this.$api.Admin.Conversations.getCounts(this.searchParams)
      .then(response => this.counts = response.counts)
      .catch(error => this.handleHttpError(error));
    return this.$api.Admin.Conversations
      .getConversations(this.searchParams)
      .then(response => this.results = response.results)
      .catch(error => this.handleHttpError(error));
  }

  isProposal = this.adminConversationsService.isProposal;

  getUrl = this.adminConversationsService.getUrl;

  clientName = this.adminConversationsService.clientName;

  loadPage = (idx: number) => {
    this.searchParams.page = idx;
    this.currentPage = idx + 1;
    return this.searchRequests()
      .catch(error => this.handleHttpError(error));
  }

  fetchConversations = () => {
    return this.$api.Admin.Conversations.getConversations(this.search)
      .then(data => this.results = data.results);
  }

  handleHttpError = (error: Error) => {
    this.unwrapError(error);
  }

}

export const AdminConversationsDashboardComponent = {
  controller: AdminConversationsDashboardController,
  template: require('./admin-conversations-dashboard.component.jade'),
  bindings: {
    componentIsReady: '&'
  }
};
