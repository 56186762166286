import { MonthViewService } from './month-view.service';
import { ApiService } from 'spc/shared/api/api.service';
import ENUMS from 'common/dist/enums';

class MonthViewSidebarController {
  space: any;
  closeSidebar: () => any;
  changesSaved: () => any;
  roomFeeChange: boolean = false;
  fbMinChange: boolean = false;
  extraHourFeeChange: boolean = false;
  ENUMS: any = ENUMS;

  getCalendars: () => any;

  constructor(private monthViewService: MonthViewService, private $api: ApiService, private unwrapError) {
    'ngInject';
  }

  canEditTimeSlots = () => {
    return !this.monthViewService.preventTimeSlotEditing();
  }

  canEditAvailability = () => {
    return this.monthViewService.allowNoteEditing();
  }

  makeEventsAvailable = () => {
    this.monthViewService.makeAvailable();
  }

  blockEvents = () => {
    this.roomFeeChange = false;
    this.fbMinChange = false;
    this.monthViewService.blockEvents();
  }

  cancel = () => {
    this.monthViewService.stopEditing();
    this.roomFeeChange = false;
    this.fbMinChange = false;
  }

  isEditing = () => this.monthViewService.isEditing();

  registerFbMinChange = () => {
    this.fbMinChange = true;
  }

  registerRoomFeeChange = () => {
    this.roomFeeChange = true;
  }

  registerExtraHourFeeChange = () => {
    this.extraHourFeeChange = true;
  }

  hasFeeChanged = () => {
    return this.roomFeeChange || this.fbMinChange || this.extraHourFeeChange;
  }

  allowSave = () => {
    return this.hasFeeChanged() || this.monthViewService.blockedChange || (this.monthViewService.allowNoteEditing() && this.monthViewService.model.note );
  }

  isFinite = (num) => {
    return Number.isFinite(num);
  }

  save = () => {

    if (!this.allowSave()) {
      return;
    }

    /**
     * Possibilities:
     * 1. Block
     * 2. Make Available
     *    Optional: Then change fbmin etc
     * 3. Change fbmins etc
     *
     * 1: we would know the user wants to block if blockedChange is blocked
     * 2. we would know the user wants to make available
     *    i. blockedChange is available
     * 3. we would know fees are changed based on the model
     */
    const changes: any = { };
    if (this.monthViewService.blockedChange === 'blocked') {
      changes.blocked = true;
      // block the selected events / timeSlots
      // save the note if there is a note
    } else if (this.monthViewService.blockedChange === 'available') {
      changes.available = true;
      // make all selected timeslots / events available
    }

    if (this.hasFeeChanged()) {
      changes.fees = { fbMin: this.fbMinChange, roomFee: this.roomFeeChange, extraHourFee: this.extraHourFeeChange };
    }

    if (!changes.blocked && !changes.available && this.monthViewService.model.note) {
      changes.updateNote = true;
    }

    const spaceId = this.space._id.toString();
    const data = {
      changes,
      selectedEvents: this.monthViewService.selectedEvents,
      selectedTimeSlots: this.monthViewService.selectedSlots,
      fbMinValue: this.monthViewService.model.fbMinValue,
      roomFeeValue: this.monthViewService.model.roomFeeValue,
      extraHourFeeValue: this.monthViewService.model.extraHourFeeValue,
      note: this.monthViewService.model.note
    };

    return this.$api.AvailabilityCalendar.modifyMultipleEvents({ spaceId, data })
      .then(() => {
        this.cancel();
        this.changesSaved();
        return this.getCalendars();
      })
      .catch(error => this.unwrapError(error));
  }
}


export const MonthViewSidebarComponent = {
  controller: MonthViewSidebarController,
  template: require('./month-view-sidebar.component.jade'),
  bindings: {
    space: '<',
    closeSidebar: '&',
    getCalendars: '&',
    changesSaved: '&'
  }
};
