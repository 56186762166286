export class AvailabilityCalendarRouter {
  BASE_URL: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    'ngInject';
    this.BASE_URL = `${API_ROOT}/availability-calendar`;
  }

  public markUnavailable = ({ data }: { data: MarkUnavailableRequestBody }) => {
    return this.$http.post(`${this.BASE_URL}/${data.event.space}`, data)
      .then(response => response.data);
  }

  public modifyEvent = ({ data }: { data: ModifyEventRequestBody }) => {
    return this.$http.put(`${this.BASE_URL}/${data.oldEvent.space}`, data)
      .then(response => response.data);
  }

  public getSpaceEvents = ({ data }) => {
    return this.$http.get(`${this.BASE_URL}/space/${data.spaceId}/events`, { params: data })
      .then(response => response.data);
  }

  public getVenueEvents = ({ data }) => {
    return this.$http.get(`${this.BASE_URL}/venue/${data.venueId}/events`, { params: data })
      .then(response => response.data);
  }

  public modifyMultipleEvents = ({ data, spaceId }: { data: ModifyManyRequestBody, spaceId: string }) => {
    return this.$http.put(`${this.BASE_URL}/batch/${spaceId}/`, data)
      .then(response => response.data);
  }
}
