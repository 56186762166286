
/**
 * Fade in an element on a given list of events, and hide it after a given
 * number of milliseconds.
 *
 * @property {String} fadeInOnEvent comma-delimited list of events to listen for
 * @property {Number} [fadeOutAfterEvent] fade out the element after this many ms
 * @property {Number|String} [fadeSpeed]
 */

export const fadeInOnEvent = function() {
  return function(scope, element, attrs) {
    attrs.fadeInOnEvent.split(',').forEach(function(ev) {
      scope.$on(ev, function() {
        element.fadeIn(attrs.fadeSpeed).removeClass('test-hidden');
        if (attrs.fadeOutAfterEvent) {
          handleTimeoutAttrFade(attrs.fadeOutAfterEvent, element);
        }
      });
    });
  };
};

function handleTimeoutAttrFade(attr, element) {
  var timeout = parseInt(attr, 10);
  if (attr && !isNaN(timeout)) {
    setTimeout(function () {
      element.fadeOut().addClass('test-hidden');
    }, timeout);
  }
}
