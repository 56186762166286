
import cloneDeep from 'lodash/cloneDeep';
import { every, get } from 'lodash';
import { RawBookingRequest, BookingRequestMenu } from 'spc/lib/database/types/booking-request';
import menuRequestHelpers from 'common/dist/virtuals/MenuRequest';

interface Course {
  name: string;
  maxSelection: number;
  description: string;
}

interface Menu {
  data: BookingRequestMenu;
}

class EditMenusController {
  menu: Menu;
  menus: Menu[];
  customMenu: BookingRequestMenu;
  counterClone: RawBookingRequest;
  close: () => void;
  addMenuDelta: () => void;
  setMenu: (idx: number) => void;

  constructor() {
    'ngInject';
  }

  $onInit() {
    const selected = get(this.counterClone, 'data.menu', { none: true });
    this.customMenu = cloneDeep(selected);
  }

  updateMenu = (idx) => {
    if (!idx) {
      return;
    }

    this.setMenu(idx);
    const selectedMenu = cloneDeep(this.menus[idx]);

    this.customMenu = menuRequestHelpers.initFromMenu(selectedMenu, get(this.counterClone, 'data.groupSize'));
  }

  hasAllRequiredFields = () => {
    if (get(this.customMenu, 'name') === 'No Menu Desired') {
      return true;
    }
    const requiredFields = [
      get(this.customMenu, 'name'),
      get(this.customMenu, 'pricePerGuestCents') >= 0,
      get(this.customMenu, 'type'),
    ];

    return every(requiredFields);
  }

  persistEdit = () => {
    this.counterClone.data.menu = this.customMenu;
    this.addMenuDelta();
    this.close();
  }
}

export const EditMenusComponent = {
  controller: EditMenusController,
  template: require('./edit-menus.component.jade'),
  bindings: {
    counterClone: '<',
    menus: '<',
    setMenu: '<',
    close: '<',
    addMenuDelta: '<'
  }
};
