// NPM Dependencies

import get from 'lodash/get';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';

import * as angular from 'angular';
import { fullName } from 'common/dist/virtuals/User';

// Sixplus Dependencies
import { ToastService } from 'spc/shared/toast.service';
import { ApiService } from 'spc/shared/api/api.service';

// Helpers
import LeadHelpers from 'common/dist/virtuals/Lead';

// Models
import { DLead } from '../../../../database/types/lead';
import { DVenue } from '../../../../database/types/venue/venue';

const ADMIN_DETAIL_DEBOUNCE_TIME = 2000;

class AdminDetailsController {
  lead: DLead;
  venues: DVenue[];
  conciergeTeam: any[];
  clone: any;
  ownerName: string;
  updateOwner: (ownerName: string, ownerId: string) => void;
  ownerId: string;
  sendEmail: string;
  reason: string;
  lastEmail: {id: string, date: Date};

  constructor(
    private ENUMS,
    private $api: ApiService,
    private unwrapError,
    private toast: ToastService,
    private alternativeVenueModal
  ) {
    'ngInject';
  }


  $onInit = () => {
    this.ownerName = this.lead.owner ? fullName(this.lead.owner) : null;
    this.lead.admin.priorityLevel = LeadHelpers.prioritize(this.lead);
    this.clone = { admin: cloneDeep(this.lead.admin) };
    this.reason = this.lead.admin.closeReason;
  }

  setReason = (reason) => {
    this.reason = reason;
  }

  assignOwner = (member): ng.IPromise<any> => {
    if (this.lead && member) {
      return this.$api.Admin.Leads.assignOwner(this.lead._id.toString(), member._id.toString())
        .then(() => {
          this.lead.owner = member;
          this.ownerName = fullName(this.lead.owner);
          this.ownerId = member._id.toString();
          this.updateOwner(this.ownerName, this.ownerId);
        })

        .catch(error => this.unwrapError(error));

    }
  }

  findAssistant = (): any => {
    if (this.conciergeTeam) {
      return this.conciergeTeam.find(member => member._id.toString() === get(this.lead, 'admin.assistant'));
    }
  }

  saveAssistant = (): ng.IPromise<void> => {
    return this.save({ 'admin.assistant': this.lead.admin.assistant || null })
      .then(() => this.toast.goodNews('Saved Assistant', `Set the assistant to ${this.findAssistant() ? this.findAssistant().fullName : 'null' }`))
      .catch(error => this.lead.admin.assistant = this.clone.admin.assistant);
  }

  saveDueDate = (date): ng.IPromise<void> => {
    return this.save({ 'admin.dueDate': date })
      .then((response) => {
        this.toast.goodNews('Saved Due Date');
        this.lead.admin.dueDate = response.admin.dueDate;
      })
      .catch(error => this.lead.admin.dueDate = this.clone.admin.dueDate);
  }

  clearDueDate = () => {
    this.saveDueDate(null);
  }

  savePriorityLevel = (): ng.IPromise<void> => {
    return this.save({ 'admin.priorityLevel': this.lead.admin.priorityLevel })
      .then((response) => {
        this.lead.admin.priorityLevel = LeadHelpers.prioritize(response);
        this.toast.goodNews('Saved Priority Level');
      })
      .catch(error => this.lead.admin.priorityLevel = this.clone.admin.priorityLevel);
  }

  debouncedSaveNextToDo = debounce((): ng.IPromise<void> => {
    return this.save({ 'admin.nextToDo': this.lead.admin.nextToDo })
      .then((response) => {
        this.toast.goodNews('Saved Next To Do');
        this.lead.admin.nextToDo = response.admin.nextToDo;
      })
      .catch(error => this.lead.admin.nextToDo = this.clone.admin.nextToDo);
  }, ADMIN_DETAIL_DEBOUNCE_TIME);

  saveCloseReason = (reason): ng.IPromise<void> => {
    this.lead.admin.closeReason = reason;
    if (this.lead.admin.closeReason === 'Client Found Other Venue w/o SixPlus') {
      return this.alternativeVenueModal()
        .then((data) => {
          return this.save({
            'admin.closeReason': this.lead.admin.closeReason,
            'admin.alternativeVenue': get(data, 'value.name'),
            sendEmail: this.sendEmail
          })
            .then((response) => {
              this.lead.admin.closeReason = response.admin.closeReason;
              this.lead.admin.alternativeVenue = response.admin.alternativeVenue;
              this.lead.statuses = response.statuses;
              this.toast.goodNews('Saved Reason Closed');
            })
            .catch((error) => {
              this.lead.admin.closeReason = this.clone.admin.closeReason;
              this.unwrapError(error);
            });
      });
    } else {
      return this.save({ 'admin.closeReason': this.lead.admin.closeReason, sendEmail: this.sendEmail })
        .then((response) => {
          this.lead.admin.closeReason = response.admin.closeReason;
          this.lead.statuses = response.statuses;
          this.toast.goodNews('Saved Close Reason');
        })
        .catch((error) => {
          this.lead.admin.closeReason = this.clone.admin.closeReason;
          this.unwrapError(error);
        });
    }
  }

  removeAlternativeVenue = () => {
    return this.save({ 'admin.alternativeVenue': null })
      .then((response) => {
        this.lead.admin.alternativeVenue = response.admin.alternativeVenue;
        this.toast.goodNews('Saved Alternative Venue');
      });
  }

  editAlternativeVenue = () => {
    return this.alternativeVenueModal()
      .then((data) => {
        const name = get(data, 'value.name');
        if (!name) {
          return;
        }
        return this.save({ 'admin.alternativeVenue': name })
          .then((response) => {
            this.lead.admin.alternativeVenue = response.admin.alternativeVenue;
            this.toast.goodNews('Saved Alternative Venue');
          });
      });
  }

  save = (update): ng.IPromise<any> => {
    Object.assign(update, { 'admin.adminLastUpdateAt': new Date() });

    return this.$api.Admin.Leads.updateAdmin({ lead: this.lead._id, update })
      .catch((error) => {
        const message = get(error, 'data.error.message', 'Something went wrong trying to update the lead. Try refreshing the page and trying again.');
        this.toast.badNews('Something went wrong :(', message);
        return this.unwrapError(error);
      });
  }
  getLastEmailSentDate = (campaignHistory) => {
    if (campaignHistory && campaignHistory.length > 0) {
      this.lastEmail = campaignHistory[campaignHistory.length - 1];
      return this.lastEmail.date ? this.lastEmail.date : null;
    }
    return null;
  }
}

export const AdminDetailsComponent = {
  controller: AdminDetailsController,
  template: require('./admin-details.component.jade'),
  bindings: {
    lead: '<',
    conciergeTeam: '<',
    updateOwner: '<'
  }
};
