import price from 'common/dist/price';
import { ApiService } from 'spc/shared/api/api.service';
import { PostDepositCancellationResponse } from 'spc/lib/server/api/admin/requests/models';
import { some, get } from 'lodash';

// constant
import { REFUND_CONFIRMATION_MESSAGE } from '../../../constants/refundConfirmaionMessage';
class EventCancelController {
  request: any;
  close: ({ request }?: { request?: any }) => any;
  cancellor: 'venue' | 'client';
  possibleTransactionTypes: string[] = [];
  selectedTransactionType: string;
  openRefundConfirmationModal;
  resolutionTransaction: {
    paymentType?: string;
    method?: string;
    date?: string;
    amountDollars?: number;
    amountCents?: number;
    note?: string;
    emails: {
      client: boolean;
      venue: boolean;
    }
  } = { emails: { client: true, venue: true } };
  submitted: boolean = false;
  constructor(private ENUMS, private $api: ApiService, private $clickOk, private unwrapError) {
    'ngInject';
  }

  selectCancellor = (cancellor) => {
    this.cancellor = cancellor;
    this.possibleTransactionTypes = ['cancellation refund'];
    if (this.cancellor === 'client') {
      this.possibleTransactionTypes.push('cancellation charge');
    }
    this.possibleTransactionTypes.push('none');
  }

  shouldDisplayForm = () => {
    return this.resolutionTransaction.paymentType === 'cancellation charge' || this.resolutionTransaction.paymentType === 'cancellation refund';
  }

  hasManualPayments = () => {
    const schedule = get(this.request, 'data.payment.schedule');
    const hasManual = some(schedule, s => s.isManual);

    if (!hasManual) {
      this.resolutionTransaction.method = this.ENUMS.payment.methods[2];
    }

    return hasManual;
  }

  calculateDefaultValues = () => {
    const breakdown = price.fullPriceBreakdownCents(this.request);
    if (this.resolutionTransaction.paymentType === 'cancellation charge') {
      this.resolutionTransaction.amountDollars = 0;
    } else if (this.resolutionTransaction.paymentType === 'cancellation refund') {
      if (this.request.state === 'CONCLUDED') {
        this.resolutionTransaction.amountDollars = price.convertToDollarsAndRound(breakdown.balanceOutstanding);
      } else {
        this.resolutionTransaction.amountDollars = price.convertToDollarsAndRound(breakdown.amountPaid);
      }
    }
  }

  setTransactionDate = (date) => {
    this.resolutionTransaction.date = date;
  }

  openConfirmCancellationModal = () => {
    if (this.resolutionTransaction.paymentType === 'none' || this.resolutionTransaction.paymentType === 'cancellation charge') {
      return this.processCancellation();
    }
    const refundType = this.hasManualPayments() ? 'Manual' : 'Not Manual';
    const message = REFUND_CONFIRMATION_MESSAGE[refundType];
    const showCancel = true;
    const btnText = 'Confirm';

    return this.$clickOk(message, showCancel, btnText)
      .then((response) => {
        if (get(response, 'value.cancel')) {
          return;
        }
        this.processCancellation();
      });
  }

  processCancellation = () => {
    let state = '';
    this.submitted = true;
    if (this.cancellor === 'venue') {
      state = 'CANCELLED_POST_DEPOSIT_VENUE';
    } else if (this.cancellor === 'client') {
      state = 'CANCELLED_POST_DEPOSIT_CLIENT';
    }

    const resolutionTransaction = this.resolutionTransaction;

    if (resolutionTransaction.paymentType === 'none') {
      resolutionTransaction.paymentType = undefined;
      resolutionTransaction.amountCents = 0;
    } else {
      resolutionTransaction.amountCents = this.resolutionTransaction.amountDollars * 100;
    }

    this.$api.Admin.Requests.resolveEvent({ bookingRequestId: this.request._id, state, resolutionTransaction })
      .then((response: PostDepositCancellationResponse) => {
        this.close({ request: response.bookingRequest });
      })
      .catch(this.unwrapError);
  }

  cancel = () => {
    this.close();
  }

  allowProcessCancellation = () => {
    const hasCancellor = !!this.cancellor;
    if (!hasCancellor) {
      return;
    }

    const noTransaction = this.resolutionTransaction.paymentType === 'none';
    const hasDate = this.resolutionTransaction.date;
    const hasMethod = this.resolutionTransaction.method;
    const hasAmount = this.resolutionTransaction.amountDollars;

    if (noTransaction) {
      return true;
    } else {
      return hasDate && hasMethod && hasAmount;
    }
  }
}

export const EventCancelComponent = {
  controller: EventCancelController,
  template: require('./event-cancel.component.jade'),
  bindings: {
    request: '<',
    openRefundConfirmationModal: '<',
    close: '&'
  }
};
