import { SearchQuery } from 'spc/lib/server/api/admin/venues/models';
import { ApiService } from 'spc/shared/api/api.service';
import { SpDownloadService } from 'spc/shared/sp-download.service';

class DownloadVenuesController {
  downloading: boolean = false;
  search: SearchQuery;
  fields: any[];

  constructor(private $api: ApiService, private unwrapError, private SpDownloadService: SpDownloadService, private ENUMS, private $location) {
    'ngInject';
    this.fields = [
      { label: 'Parent Company', value: 'Parent Company' },
      { label: 'Venue Name', value: 'Venue Name' },
      { label: 'Order', value: 'Order' },
      { label: 'Neighborhood', value: 'Neighborhood' },
      { label: 'City', value: 'City' },
      { label: 'State', value: 'State' },
      { label: 'Profile Status', value: 'Profile Status' },
      { label: 'Venue Status', value: 'Venue Status' },
      { label: 'City Seatch Page URL', value: 'City Seatch Page URL' },
    ];
  }

  downloadVenues = () => {
    this.downloading = true;
    const fileTitle = `Venue_${new Date().toISOString()}`;
    return this.$api.Admin.Venues.download(this.search)
      .then((response) => {
        this.SpDownloadService.exportXLSXFile({ data: response.Venues, fields: this.fields, fileTitle, sheetName: fileTitle });
      })
      .catch((error) => {
        this.unwrapError(error);
      })
      .finally(() => this.downloading = false);
  }
}

export const DownloadVenueComponent = {
  controller: DownloadVenuesController,
  template: require('./download-venues.component.jade'),
  bindings: {
    search: '<'
  }
};
