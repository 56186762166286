import cloneDeep from 'lodash/cloneDeep';

interface Course {
  name: string;
  maxSelection: number;
  description: string;
}
interface Menu {
  name: string;
  courses: Course[];
  pricePerGuestCents: Number;
}

class CustomMenuController {
  menu: Menu;
  editing: { courses: boolean } = { courses: false };
  course: Course;
  _menuPriceDollars: string;
  customMenu: Menu;

  constructor(private ENUMS) {
    'ngInject';
  }

  setPrice = () => {
    this.menu.pricePerGuestCents = Number.parseFloat(this._menuPriceDollars) * 100;
  }

  addCourse = () => {
    this.editing.courses = true;
    this.course = {
      maxSelection: null,
      description: null,
      name: null
    };
  }

  allowSaveCourse = () => {
    return this.course.maxSelection && this.course.description && this.course.name;
  }

  saveCourse = () => {
    this.menu.courses = this.menu.courses || [];
    this.menu.courses.push(cloneDeep(this.course));
    this.editing.courses = false;
  }

  cancelEdit = () => {
    this.editing.courses = false;
    this.course = null;
  }

  deleteCourse = (index) => {
    this.menu.courses.splice(index, 1);
  }
}

export const CustomMenuComponent = {
  template: require('./custom-menu.component.jade'),
  controller: CustomMenuController,
  bindings: {
    menu: '='
  }
};
