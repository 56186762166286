import ENUMS from 'common/dist/enums';
import { get, debounce } from 'lodash';

import { RawLocation } from 'lib/database/types/location';
import { RawList } from 'lib/database/types/list';

import { ApiService } from 'spc/shared/api/api.service';
import { UserService } from 'spc/services/user.service';

class ClientAddVenuesController {
  lead: any;
  ui: { view: 'search' | 'book' } = { view: 'search' };
  menus: any[];
  drinks: any[];
  allMenus: any;
  allDrinks: any;
  venue: any;
  venues: any[];
  locations: string[];
  ALL_SEARCH_LOCATIONS: {};
  selectedLocation: string | null;
  list: RawList;
  isPremiumUser: boolean;
  addedVenues: Set<string>;
  update: ({ update }: {update: RawList }) => void;
  search: {
    name: string
  };
  cities = ENUMS.acceptableUserCities;
  loading: boolean = false;

  constructor(
    private $api: ApiService,
    private unwrapError,
    private photoDialog,
    private $cloudinary,
    private $user: UserService
  ) {
    'ngInject';
    this.search = {
      name: ''
    };
    this.selectedLocation = 'All Cities';
  }

  $onInit = () => {
    this.$user.isPremiumMember()
      .then((res) => {
        this.isPremiumUser = res;
        this.setSearchLocations();
        this.setAddedVenues();
      }).catch((error) => {
        this.unwrapError(error);
      });
  }

  setSearchLocations = () => {
    const defaultLocations = {};
    const premiumLocations = {};
    for (const key in this.cities) {
      if (this.cities[key].premiumCity) {
        premiumLocations[this.cities[key].name] = this.cities[key].city;
      } else {
        defaultLocations[this.cities[key].name] = this.cities[key].city;
      }
    }
    const allLocations = ['All Cities', ...Object.keys(defaultLocations), ...Object.keys(premiumLocations)].sort();
    this.ALL_SEARCH_LOCATIONS = !this.isPremiumUser ? { 'All Cities': '', ...defaultLocations } : { 'All Cities': '', ...defaultLocations, ...premiumLocations };
    this.locations = this.isPremiumUser ? allLocations : ['All Cities', ...Object.keys(defaultLocations)];
  }

  selectLocation = () => {
    this.venueSearch(this.search.name);
  }

  setAddedVenues = () => {
    this.addedVenues = new Set();
    this.list.saved.forEach((saved) =>  {
      if (saved.venue && saved.venue._id) {
        this.addedVenues.add(saved.venue._id.toString());
      }
    });
  }

  venueSearch = (name) => {
    const query = {
      name,
      menus: true,
      drinks: true,
      location: this.ALL_SEARCH_LOCATIONS[this.selectedLocation],
      limitToVisible: true
    };
    this.loading = true;
    return this.$api.Venues.get(null, query)
      .then((response: any) => {
        this.loading = false;
        this.venues = get(response, 'data.data.venues', []);
        this.allMenus = get(response, 'data.data.menus', []);
        this.allDrinks = get(response, 'data.data.drinks', []);
      })
      .catch(error => this.unwrapError(error));
  }

  debouncedVenueSearch = debounce(this.venueSearch, 600);

  addVenue = (venue) => {
    this.$api.Lists.addVenue({ lists: [this.list._id.toString()], venueId: venue._id.toString() })
      .then((res) => {
        this.list = res.lists[0];
        this.setAddedVenues();
        this.update({ update: this.list });
      })
      .catch(error => this.unwrapError(error));
  }
}

export const ListVenueSearchComponent = {
  controller: ClientAddVenuesController,
  template: require('./list-venue-search.component.jade'),
  bindings: {
    list: '<',
    close: '&',
    update: '&'
  }
};
