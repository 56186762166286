/**
 * State management service to control logic in toggles.
 * The general rule of thumb with this service is that
 * "true" means on, "false" means off. For instance, if
 * this._toggled is true, that means the toggle is ON
 *
 * @param {Boolean} defaultOn
 */
export default function() {
  return function(defaultOn) {
    this._toggled = !!defaultOn;
    this.switchToggle = switchToggle;

    /////
    function switchToggle() {
      this._toggled = !this._toggled;
    }

  };
};