import * as angular from 'angular';
import { compact, debounce, get, isNumber, map, remove } from 'lodash';
import { DPhoto } from 'database/types/photo';

// Vendor dependencies
const ngSortableModule = require('../../vendor/ngSortable');
// Dropzone is not exported as a module
const Dropzone = require('dropzone');

const m = angular.module('photos', [ngSortableModule(), 'services']);

m.directive('fileUpload', function () {

  interface DropzoneScope extends ng.IScope {
    dropzone: any;
    onUpload: ({ url, caption }: { url: string, caption: string }) => any;
  }

  return {
    template: require('./file-upload.jade'),
    scope: {
      onUpload: '&',
      allowedFileTypes: '<'
    },
    link: function (scope: DropzoneScope, element: ng.IRootElementService, attrs) {
      const el = element.find('.upload-container')[0];
      const CLICKABLE_SELECTOR = '.upload-container h3';
      scope.dropzone = new Dropzone(el, {
        uploadMultiple: false,
        clickable: element.find(CLICKABLE_SELECTOR)[0],
        acceptedFiles: scope.allowedFileTypes ? scope.allowedFileTypes.join(',') : '.jpg,.png,.jpeg,.gif,.pdf',
        parallelUploads: 6,
        url: scope.allowedFileTypes ? `https://api.cloudinary.com/v1_1/dbwijvnzn/auto/upload` : 'https://api.cloudinary.com/v1_1/dbwijvnzn/image/upload',
        previewTemplate: '<div class="dz-progress">' +
          '<span class="dz-upload" data-dz-uploadprogress></span>' +
          '</div>'
      });

      scope.dropzone.on('sending', function (file, xhr, formData) {
        formData.append('upload_preset', 'afliugec');
        scope.$emit('PHOTO_UPLOADING');
        scope.$apply();
      });

      scope.dropzone.on('success', function (file, response) {
        $(element).find('.dz-progress').fadeOut();
        scope.onUpload({ url: response.secure_url, caption: response.original_filename });
        scope.$emit('PHOTO_UPLOADED');
        scope.$apply();
      });
    }
  };
});

m.directive('photoModule', function () {
  return {
    template: require('./photo-module.jade'),
    scope: {
      photos: '=',
      coverIndex: '=',
      savePhotos: '=',
      allowedFileTypes: '<',
      copyOriginalFileName: '<',
      captionTemplate: '<'
    },
    controller: ['$scope', '$user', '$cloudinary', 'photoDialog', function ($scope, $user, $cloudinary, photoDialog) {
      $scope.user = $user;
      $scope.state = {
        mode: 'SHOW', // Valid options: SHOW, ORGANIZE
        coverIndex: null
      };
      $scope.$cloudinary = $cloudinary;
      $scope.state.coverIndex = $scope.coverIndex;

      const debouncedSave = debounce(function () {
        if ($scope.state.coverIndex < 0 || $scope.state.coverIndex > $scope.state.photos.length) {
          $scope.state.coverIndex = 0;
        }
        $scope.savePhotos(angular.copy($scope.state.photos), $scope.state.coverIndex);
      }, 0);

      $scope.state.photos = compact($scope.photos).length ?
        map($scope.photos, function (photo, i) {
          return {
            _id: get(photo, '_id'),
            url: get(photo, 'url'),
            caption: get(photo, 'caption') || ''
          };
        }) :
        [];

      $scope.$watch('state.photos', function (n, o) {
        if (n) {
          debouncedSave();
        }
      }, true);

      $scope.$watch('state.coverIndex', function (n, o) {
        if (isNumber(n) && n !== o) {
          debouncedSave();
        }
      }, true);

      $scope.openPhotoDialog = function (photo) {
        return photoDialog({ photos: $scope.state.photos, initialPhoto: photo });
      };

      $scope.toggleViewModes = function () {
        $scope.state.mode = $scope.state.mode === 'SHOW' ?
          'ORGANIZE' :
          'SHOW';
      };

      $scope.removePhoto = function (photo, index) {
        if (!isNumber($scope.state.coverIndex)) {
          $scope.state.photos = $scope.state.photos.filter(p => p.url !== photo.url);
        } else if (index !== $scope.state.coverIndex) {
          const coverUrl = $scope.state.photos[$scope.state.coverIndex].url;
          $scope.state.photos = $scope.state.photos.filter(p => p.url !== photo.url);
          $scope.state.coverIndex = $scope.state.photos.findIndex(currentPhoto => currentPhoto.url === coverUrl);
        }
      };

      $scope.addPhoto = function (url, caption) {
        if (!$scope.copyOriginalFileName) {
          caption = $scope.captionTemplate ? $scope.captionTemplate : '';
        }
        $scope.state.photos.push({
          url,
          caption
        });
      };

      setTimeout(function () {
        $scope.$emit('PhotoModuleLoaded');
      }, 0);
    }]
  };
});

/**
 * Open an ngDialog that displays the given photos.
 *
 * @param {Array} photos List of `{ url: String, caption: String }`
 * @param {initialIndex} Initial index to show
 * @return {Promise} resolved when dialog is closed.
 */

m.factory('photoDialog', ['ngDialog', '$rootScope', '$cloudinary', function (ngDialog, $rootScope, $cloudinary) {
  return function ({ photos, initialPhoto }: { photos: DPhoto[], initialPhoto: DPhoto }) {
    const initialIndex = photos.findIndex(photo => photo._id.toString() === initialPhoto._id.toString());
    const $scope = $rootScope.$new();
    $scope.lightboxIdx = initialIndex;
    $scope.lightboxImg = photos[$scope.lightboxIdx];
    $scope.$cloudinary = $cloudinary;

    return ngDialog.open({
      scope: $scope,
      plain: true,
      template: require('./photo-dialog.jade'),
      // Not a shadow variable
      // tslint:disable-next-line: no-shadowed-variable
      controller: ['$scope', 'photoCount', ($scope, photoCount) => {
        $scope.photoCount = photoCount;
        $scope.nextFn = function () {
          $scope.lightboxIdx = ($scope.lightboxIdx + 1) % photos.length;
          $scope.lightboxImg = photos[$scope.lightboxIdx];
          // Sometimes ngDialog bugs out :(
          setTimeout(function () {
            $scope.$apply();
          }, 0);
        };
        $scope.prevFn = function () {
          $scope.lightboxIdx = ($scope.lightboxIdx - 1 + photos.length) %
            photos.length;
          $scope.lightboxImg = photos[$scope.lightboxIdx];
          // Sometimes ngDialog bugs out :(
          setTimeout(function () {
            $scope.$apply();
          }, 0);
        };
      }],
      resolve: {
        photoCount: function () {
          return photos.length;
        }
      }
    }).
      closePromise;
  };
}]);

module.exports = m;
