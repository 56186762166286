
class TopLevelListsController {
  canInit: boolean = false;
  venues: any[];
  constructor(
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $user) { 'ngInject'; }

  $onInit() {
    this.$user.$waitFor('LOGGED_IN', () => {
      this.canInit = true;
    });

    this.$user.$waitFor('NOT_LOGGED_IN', () => {
      this.$location.path('/login?redirect=' + this.$location.path());
    });
  }

  componentIsReady = () => {
    this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
  }
}

export const TopLevelListsComponent = {
  template: require('./top-level-lists.component.jade'),
  controller: TopLevelListsController
};
