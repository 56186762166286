import { SearchQuery, DownloadFields } from 'spc/lib/server/api/admin/users/models';
import { ApiService } from 'spc/shared/api/api.service';
import { SpDownloadService } from 'spc/shared/sp-download.service';

class DownloadUsersController {
  downloading: boolean = false;
  search: SearchQuery;
  fields: DownloadFields[];

  constructor(private $api: ApiService, private unwrapError, private SpDownloadService: SpDownloadService, private ENUMS, private $location) {
    'ngInject';
    this.fields = [
      { label: 'Readable Id', value: 'Readable Id' },
      { label: 'Created Date', value: 'Created Date' },
      { label: 'Type', value: 'Type' },
      { label: 'Name', value: 'Name' },
      { label: 'Owner', value: 'Owner' },
      { label: 'Company', value: 'Company' },
      { label: 'City', value: 'City' },
      { label: 'Email', value: 'Email' },
      { label: 'Phone', value: 'Phone' },
      { label: 'Status', value: 'Status' },
      { label: 'Referer', value: 'Referer' },
      { label: 'Keywords', value: 'Keywords' },
      { label: 'Referrer URL', value: 'URL' },
      { label: 'Heard About', value: 'HeardAbout' }
    ];
  }

  downloadUsers = () => {
    this.downloading = true;
    const fileTitle = `Users_${new Date().toISOString()}`;
    return this.$api.Admin.Users.download(this.search)
      .then((response) => {
        this.SpDownloadService.exportXLSXFile({ data: response.Users, fields: this.fields, fileTitle, sheetName: fileTitle });
      })
      .catch((error) => {
        this.unwrapError(error);
      })
      .finally(() => this.downloading = false);
  }
}

export const DownloadUsersComponent = {
  controller: DownloadUsersController,
  template: require('./download-users.component.jade'),
  bindings: {
    search: '<'
  }
};
