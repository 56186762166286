export default function() {
  return {
    template: require('./user-spaces-new.jade'),
    controller: 'UserSpacesNew',
    scope: {
      Record: "=record",
      venue: '=',
      drinks: '=',
      menus: '='
    }
  };
};