import { roleChecker } from '../../utils/roleChecker';
import { capitalize, get, includes, keys, merge, range } from 'lodash';
import { ApiService } from 'spc/shared/api/api.service';
import { Counter } from 'server/api/admin/users/models';
import ENUMS from 'common/dist/enums';
import { AdminUsersService } from './admin-users.service';
import { DBaseUser } from '../../../../database/types/base-user';
import { ACCOUNT_TIER } from '../../../../database/constants/Account';
import { getCityNameFromValue } from 'spc/utils/getCityDisplayName';

class AdminUserDashboardController {
  USERS_PER_PAGE = 50;
  numPages: number;
  users: any[];
  venues: any[];
  currentPage: 0;
  counter: Counter;
  types = ENUMS.userTypes;
  accountTier = ACCOUNT_TIER;
  statuses = ENUMS.userStatuses;
  conciergeTeam: DBaseUser[];

  cities = ENUMS.acceptableUserCities;
  ui: { displaySidebar: boolean; } = { displaySidebar: false };
  searchParams: {
    userTypes?: string;
    userStatuses?: string;
    accountTier?: string;
    owner?: string;
    text?: string;
    city?: string;
    type?: string;
  } = {};
  getCityNameFromValue = getCityNameFromValue;

  constructor(
    private $api: ApiService,
    private unwrapError,
    private toast,
    private addVenueModal,
    private $location,
    private adminUsersService: AdminUsersService,
    private createBaseUserModal
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.$api.Admin.Users.getAssignees({ team: 'Concierge' })
      .then(response => this.conciergeTeam = response.data.assignees )
      .catch(error => this.unwrapError(error));

    this.$api.Admin.Users.getCount()
    .then((response) => {
      this.counter = response.counter;
      this.numPages = this.counter.numTotalUsers / this.USERS_PER_PAGE;
      return this.loadPage(0);
    })
    .catch(error => this.unwrapError(error));
  }

  isHostOrAdmin = roleChecker.isHostOrAdmin;

  clearSearchText = () => {
    this.searchParams.text = '';
    return this.loadPage(0);
  }

  loadPage = (index) => {
    return this.$api.Admin.Users.search(index, this.searchParams)
      .then((response) => {
        this.currentPage = index + 1;
        this.users = response.data.data.users;
        this.venues = response.data.data.venues;
      }).catch(error => this.unwrapError(error));
  }

  openCreateBaseUser() {
    return this.createBaseUserModal();
  }

  displayType = (rolesArr) => {
    if (includes(rolesArr, 'Admin')) {
        return 'Admin';
    }
    if (includes(rolesArr, 'Host')) {
      return 'Venue User';
    }
    return 'Client';
  }

  changeStatusOfUser = (user, status) => {
    const originalStatus = user.status;
    status = capitalize(status.toLowerCase());
    if (user.status === status) {
      return;
    }
    const userName = user.fullName;

    /**
     * Pending users are hosts waiting to be approved by admins.
     * Give these users host privileges when no longer pending.
     */
    if (originalStatus === 'Pending' && status === 'Active') {
      user.roles.push('Host');
    }
    user.account = get(user, 'account._id', user.account);
    return this.$api.Admin.Users.update(merge(user, { status: status })).
      then((response) => {
        this.toast.goodNews('Status Updated', `${ userName }'s status was successfully updated`);
      }).
      catch((error) => {
        user.status = originalStatus;
        this.toast.badNews('Status Update Error', `There was an error updating ${ userName }'s status!`);
      });
  }

  changeOwnerOfUser = ({ user, owner }) => {
    const originalOwner = user.owner;
    user.account = get(user, 'account._id', user.account);
    user.owner = owner;
    this.$api.Admin.Users.update(user).then((response) => {
      this.toast.goodNews('Owner Updated', `${ owner.fullName } has been set as the owner for ${ user.fullName}`);
    }).
    catch((error) => {
      user.owner = originalOwner;
      this.toast.badNews('Owner Update Error', `There was an error updating the owner for ${ user.fullName }`);
      this.unwrapError(error);
    });
  }

  selectUser = (user) => {
    if (this.adminUsersService.user && (user._id.toString() === this.adminUsersService.user._id.toString())) {
      return this.toggleSidebar();
    }
    this.adminUsersService.selectUser(user);
    this.ui.displaySidebar = true;
  }

  toggleSidebar = () => {
    this.ui.displaySidebar = !this.ui.displaySidebar;
  }

  tooltipAssociatedVenues = (user) => {
    if (this.venues) {
      return this.adminUsersService.getAssociatedVenues({ user, venues: this.venues })
        .map(venue => `<p>- ${ venue.data.name } (${ venue.data.address.neighborhood })</p>`)
        .join('');
    }
  }

  displayNumVenues = (userId, venues) => {
    if (venues) {
      return venues.filter(function(venue) {
        return includes(venue.authorizedUsers.map(authUser => authUser.user), userId);
      }).length;
    }
  }
}

export const AdminUsersDashboardComponent = {
  template: require('./admin-users-dashboard.component.jade'),
  controller: AdminUserDashboardController
};
