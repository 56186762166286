/**
 * Component for displaying message history
 *
 * @property {Guest|Object} guest The guest involved in this convo as mongoose doc or POJO
 * @property {Array} messages
 */

// NPM Deps
import { get } from 'lodash';
import moment from 'moment';

// Our Deps
import { Message } from 'shared/interfaces/message.model';
import { RawBookingRequest } from 'spc/lib/database/types/booking-request';

interface Attachment {
  url: string;
  message: string;
  isDeleted: boolean;
  file: { name: string, url: string };
}

interface ContractAttachment {
  url: string;
  isDeleted?: boolean;
}

class ConversationMessages {
  messages: any[];
  attachments: Attachment[];
  attachmentsMap: { [key: string]: Attachment[] } = {};
  contractAttachment: ContractAttachment[];
  signedContractAttachments: ContractAttachment[][];
  request: RawBookingRequest;
  constructor(private cspVideoPlayer) {
    'ngInject';
  }

  $onChanges = (data) => {
    if (data.messages) {
      this.createAttachmentsMap();
    }
  }


  constants = {
    SHOW_CHAR: 500
  };

  showMoreText = (message: string) => {
    return message && message.length > this.constants.SHOW_CHAR ? 'Show more' : '';
  }

  getRoundContainerClass = (message: Message) => {
    if (!message) {
      return null;
    }
    return {
      'brand-message': message.kind === 'message',
      'brand-counteroffer': message.kind === 'submitted_edits',
      'brand-accepted': message.kind === 'accepted'
    };
  }

  createAttachmentsMap = () => {
    if (!this.messages) {
      return;
    }
    this.messages.forEach((message) => {
      this.attachmentsMap[message._id] = this.findMessageAttachments(message);
    });
    this.contractAttachment = this.attachments.filter(attach => attach.file && attach.file.url);
    let AfterSignedContractAttachments;
    AfterSignedContractAttachments = this.attachments[ this.attachments.length - 1 ];

    if (AfterSignedContractAttachments) {
      this.signedContractAttachments = AfterSignedContractAttachments;
    }
}

  findMessageAttachments = (message) => {
    if (!this.attachments) {
      return;
    }
    return this.attachments.filter(attachment => attachment.message === message._id);
  }

  getIcon = (message: Message) => {
    if (!message) {
      return null;
    }

    return {
      'icon-feedback2': message.kind === 'message',
      'icon-arrow-left-circle-fancy': message.kind === 'submitted_edits',
      'icon-check-circle-thin': message.kind === 'accepted',
      'icon-close-thin': message.kind === 'declined' || message.kind === 'cancelled',
      'icon-add-thin': message.kind === 'created',
    };
  }

  openVenueVideoPlayer = (videoURL) => {
    const venueDetails = get(this.request, 'venue');
    const videoData = get(venueDetails.data, 'videos').find(video => video.url === videoURL);
    this.cspVideoPlayer({ video: videoData, venueAddress: get(venueDetails.data, 'address'), venueName: get(venueDetails.data, 'name'), venueSlug: get(venueDetails, 'slug') });
  }
}
export const ConversationMessagesComponent = {
  template: require('./conversation-messages.component.jade'),
  bindings: {
    guest: '<',
    messages: '<',
    request: '<',
    isGuest: '<',
    attachments: '<'
  },
  controller: ConversationMessages
};
