import mongoose from 'mongoose';
import { CreateAccountResponse, GetAccountsResponse, GetAccountResponse, UpdateAccountResponse, UpdateMemberResponse, GetMembersResponse } from 'server/api/admin/account/models';
import { RawAccount, AccountActionQuery } from 'spc/lib/database/types/accounts';
import { RawBaseUser } from 'spc/lib/database/types/base-user';

export class Account {
    BASE_URL: string;

    constructor(private API_ROOT: string, private $http: ng.IHttpService) {
        this.BASE_URL = `${API_ROOT}/admin/account`;
    }

    public createAccount = ({ account }: { account: RawAccount }): ng.IPromise<CreateAccountResponse> => {
        return this.$http.post(`${this.BASE_URL}`, { account })
            .then((res: { data: CreateAccountResponse }) => {
                return res.data;
            });
    }

    public updateAccount = ({ updates, accountId }: { updates: RawAccount, accountId: mongoose.Types.ObjectId | string }): ng.IPromise<UpdateAccountResponse> => {
        return this.$http.put(`${this.BASE_URL}/${accountId}`, { updates })
            .then((res: { data: UpdateAccountResponse }) => {
                return res.data;
            });
    }

    public getAll = ({ page, documentsPerPage, searchParams }: { page: number, documentsPerPage: number, searchParams: any}): ng.IPromise<GetAccountsResponse> => {
        return this.$http.get(`${this.BASE_URL}`, { params: { page, documentsPerPage, searchParams } })
            .then((res: { data: GetAccountsResponse }) => {
                return res.data;
            });
    }

    public getMembers = ({ page, documentsPerPage, searchParams }: { page: number, documentsPerPage: number, searchParams: any}): ng.IPromise<GetMembersResponse> => {
        return this.$http.get(`${this.BASE_URL}/members`, { params: { page, documentsPerPage, searchParams } })
            .then((res: { data: GetMembersResponse }) => {
                return res.data;
            });
    }

    public getAccountById = ({ accountId }: { accountId: mongoose.Types.ObjectId | string }): ng.IPromise<GetAccountResponse> => {
        return this.$http.get(`${this.BASE_URL}/${accountId}`)
            .then((res: { data: GetAccountResponse }) => {
                return res.data;
            });
    }

    public updateAccountMembers = ({ accountId, user, query }: { accountId: mongoose.Types.ObjectId | string, user: RawBaseUser | string, query: AccountActionQuery }): ng.IPromise<UpdateMemberResponse> => {
        return this.$http.put(`${this.BASE_URL}/${accountId}/members`, { query, user })
            .then((res: { data: UpdateMemberResponse }) => {
                return res.data;
            });
    }

    public delete = (id: string): ng.IPromise<Express.Response> => {
        return this.$http.delete(`${this.BASE_URL}/${id}`);
    }
}
