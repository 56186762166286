// External Deps
import * as angular from 'angular';

// Our Deps
import { ConciergeDashboardComponent } from './concierge-dashboard.component';
import { LeadSidebarComponent } from './lead-sidebar.component';
import { conciergeVenueSearchModal, ConciergeVenueSearchComponent } from './concierge-venue-search-modal.component';
import { TopLevelConciergeComponent } from './top-level-concierge.component';
import { LeadSidebarService } from './lead-sidebar.service';
import { ConciergeDashboardService } from './concierge-dashboard.service';
import { AdminDetailsComponent } from './admin-details.component';
import { LeadSearchComponent } from './lead-search/lead-search.component';
import alternativeVenueModal from './alternative-venue.modal';
import { recoOrderModal } from './reco-order-modal/reco-order.modal';
import { RecoOrderComponent } from './reco-order-modal/reco-order.component';
import { RecoNotesComponent } from './reco-notes-modal/reco-notes.component';
import { recoNotesModal } from './reco-notes-modal/reco-notes.modal';
import googleLocationModal from '../../components/concierge/google-location-modal/google-location.modal';

export const ConciergeDashboardModule = angular.module('conciergeDashboardModule', [])
  .component('topLevelConcierge', TopLevelConciergeComponent)
  .component('conciergeDashboard', ConciergeDashboardComponent)
  .component('leadSidebar', LeadSidebarComponent)
  .component('adminDetails', AdminDetailsComponent)
  .component('leadSearch', LeadSearchComponent)
  .component('conciergeVenueSearch', ConciergeVenueSearchComponent)
  .component('recoOrder', RecoOrderComponent)
  .component('recoNotes', RecoNotesComponent)
  .factory('conciergeVenueSearchModal', conciergeVenueSearchModal)
  .factory('googleLocationModal', googleLocationModal)
  .service('leadSidebarService', LeadSidebarService)
  .service('conciergeDashboardService', ConciergeDashboardService)
  .service('alternativeVenueModal', alternativeVenueModal)
  .service('recoOrderModal', recoOrderModal)
  .service('recoNotesModal', recoNotesModal)
  .name;
