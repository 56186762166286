export class TableScroll {
  restrict: string;

  constructor() {
    'ngInject';
    this.restrict = 'A';
  }

  link($scope, $element, $attrs) {
    const $headerDiv = $element.find('.header-wrapper');
    const $rowDiv = $element.find('.row-wrapper');
    $rowDiv.scroll(function (ev) {
      $headerDiv.css({
        left: -$rowDiv[0].scrollLeft + 'px'
      });
    });
  }
}