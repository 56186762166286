import { get, includes } from 'lodash';

const membersLimitMessageModal = ['ngDialog', function(ngDialog) {
  return function () {
    return ngDialog.open({
      template: require('./limit-message.jade'),
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        $scope.cancel = cancel;
        function cancel() {
          $scope.closeThisDialog({ cancel: true });
        }
      }]
    }).closePromise.then(function(data) {
      const isCancel = includes(['$document', '$closeButton'], get(data, 'value'));
      if (isCancel) {
        data.value = { cancel: true };
      }

      return data;
    });
  };
}];

export default membersLimitMessageModal;
