
export default ['ngDialog', (ngDialog) => {
  return (lead) => {
    return ngDialog.open({
      template: `<lead-authentication lead="$ctrl.lead" close="$ctrl.close()"></lead-authentication>`,
      className: 'ngdialog-theme-plain',
      overlay: false,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function($scope) {
        this.lead = lead;
        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];
