import { gt, gte, isEmpty, lte } from 'lodash';

module.exports = function() {
  var ret = {};

  ret.gt = function(value, other) {
    return gt(value, other);
  };

  ret.gte = function(value, other) {
    return gte(value, other);
  };

  ret.lte = function(value, other) {
    return lte(value, other);
  };

  ret.isEmpty = function(value) {
    return isEmpty(value);
  };

  ret.minChars = function(value, min) {
    return (value || '').length >= min;
  };

  return ret;
};
