import JSONCrush from '../../../../database/helpers/JSONCrush';

module.exports = function(API_ROOT, $http) {
  const ret = {
    count,
    find,
    findByEmail
  };
  return ret;

  function count(location, query) {
    return $http.get(`${ API_ROOT }/search/${ encodeURIComponent(location) }/count`,
      { params: query });
  }

  function find(location, query) {
    const crushedQuery = JSONCrush.crush(JSON.stringify(query));

    return $http.get(API_ROOT + '/search/' +
      encodeURIComponent(location), { params: { crushed: crushedQuery } });
  }

  function findByEmail(email: string)  {
    return $http.get(`${API_ROOT}/search/email/${email}`)
      .then(response => response.data);
  }
};
