import { ApiService } from 'spc/shared/api/api.service';
import price from 'common/dist/price';
import get from 'lodash/get';

class FeeApplicationController {
  pathValidattor: any;
  venue: any;
  source: 'request' | 'venue';
  request: any;
  save: ({ venue }: { venue?: any }) => any;
  cancel: () => any;
  constructor(private PathValidatorFactory, private $api: ApiService, private $scope, private unwrapError) {
    'ngInject';
  }

  $onInit = () => {
    if (this.request) {
      this.venue = this.request.venue;
    }
    this.venue.data.customFees.forEach((customFee) => {
      if (customFee.feeType === 'flat') {
        customFee.valueDollars = (customFee.value / 100).toFixed(2);
      }
    });
    this.pathValidattor = this.PathValidatorFactory(this.venue);
  }

  addCustomFee = () => {
    this.venue.data.customFees.push({});
  }

  removeCustomFee = (index) => {
    this.venue.data.customFees = this.venue.data.customFees.filter((customFee, i) => i !== index);
  }

  displayFeeInputs = () => {
    return this.source === 'venue';
  }

  canSave = () => {
    return this.source === 'request' && this.save;
  }

  onDollarChange = (customFee) => {
    if (get(this.venue, 'data.customFees.length')) {
      if (customFee.feeType === 'flat') {
        customFee.value = customFee.valueDollars * 100;
      }
    }
  }

  saveVenue = () => {
    if (!this.canSave) {
      return;
    }
    this.save({ venue: this.venue });
  }
}
export const FeeApplicationComponent = {
  template: require('./fee-application.component.jade'),
  controller: FeeApplicationController,
  bindings: {
    request: '=',
    venue: '=',
    source: '@',
    cancel: '&',
    save: '&'
  }
};
