import * as angular from 'angular';
import { TopLevelPersonalInboxComponent } from './top-level-personal-inbox.component';
import { PersonalInboxComponent } from './personal-inbox.component';
import { ConversationMessagesTableComponent } from './conversation-messages-table.component';
import { ConversationStatusMarkerComponent } from './conversation-status-marker.component';
import {
  TopLevelPersonalEventsDashboardComponent,
  PersonalEventsDashboardComponent,
  PersonalEventsDashboardService,
  PersonalEventsTableComponent,
  VENUE_STATES,
  CLIENT_STATES } from './personal-events-dashboard';

import {
  TEXT_MAP,
  VENUE_GUIDANCE_MAP,
  CLIENT_GUIDANCE_MAP,
  STATUS_MAP
} from './conversation-status-marker.constant';

import {
  STATES,
  STATE_COPY_MAPPER
} from './personal-inbox.constant';

export const EventsDashboardModule = angular.module('EventsDashboardModule', [])
  .component('topLevelPersonalEventsDashboard', TopLevelPersonalEventsDashboardComponent)
  .component('personalEventsDashboard', PersonalEventsDashboardComponent)
  .component('topLevelPersonalInbox', TopLevelPersonalInboxComponent)
  .component('personalInbox', PersonalInboxComponent)
  .component('conversationMessagesTable', ConversationMessagesTableComponent)
  .component('conversationStatusMarker', ConversationStatusMarkerComponent)
  .component('personalEventsTable', PersonalEventsTableComponent)
  .service('personalEventsDashboardService', PersonalEventsDashboardService)
  .constant('TEXT_MAP', TEXT_MAP)
  .constant('VENUE_GUIDANCE_MAP', VENUE_GUIDANCE_MAP)
  .constant('CLIENT_GUIDANCE_MAP', CLIENT_GUIDANCE_MAP)
  .constant('STATUS_MAP', STATUS_MAP)
  .constant('STATES', STATES)
  .constant('STATE_COPY_MAPPER', STATE_COPY_MAPPER)
  .constant('VENUE_STATES', VENUE_STATES)
  .constant('CLIENT_STATES', CLIENT_STATES)
  .name;
