import { get } from 'lodash';

module.exports = function() {
  return {
    template: require('./reset-password.jade'),
    controller: ['$scope', '$timeout', '$location', '$api', '$routeParams', '$clickOk', 'toast', 'unwrapError', function($scope, $timeout, $location, $api, $routeParams, $clickOk, toast, unwrapError) {
      $scope.data = {};

      // if $routeParams.uuid exists, show diff part of template
      $scope.uuid = $routeParams.uuid;
      // Functions shared for !$scope.uuid and $scope.uuid

      $scope.getUserFromUUID = function getUserFromUUID() {
        if ($scope.uuid) {
          return $api.Auth.getUserFromUUID($scope.uuid)
            .then(res => $scope.data.email = res.data.email)
            .catch(error => unwrapError(error));
        }
      };

      $scope.getUserFromUUID();

      $scope.allFieldsEntered = function allFieldsEntered() {
        if ($scope.uuid) {
          return $scope.data.email && $scope.data.password && $scope.data.confirmPassword;
        } else {
          return $scope.data.email;
        }
      };

      $scope.resetError = function resetError() {
        $scope.error = null;
      };

      // Functions for if $scope.uuid exists

      $scope.checkMatch = function checkMatch() {
        if (!$scope.data.password || !$scope.data.confirmPassword) {
          return;
        }
        $scope.canCheckMatch = true;
      };

      $scope.passwordsMatch = function passwordsMatch() {
        return $scope.data.password === $scope.data.confirmPassword;
      };

      $scope.submitNewPassword = function submitNewPassword() {
        const data = {
          email: $scope.data.email,
          password: $scope.data.password,
          confirmPassword: $scope.data.confirmPassword,
        };

        $api.Auth.submitReset(data, $scope.uuid)
          .then((response) => {
            if (get(response, 'data.reset')) {
              $scope.reset = true;
              $scope.$broadcast('PASSWORD_RESET');
              $timeout(function() {
                $location.url('/login');
              }, 3100);
            }
          })
          .catch(error => unwrapError(error));
      };

      // Functions for if !$scope.uuid
      $scope.resetPassword = function resetPassword() {
        $scope.resetError();
        $scope.noUserFound = false;
        if (!$scope.allFieldsEntered()) {
          toast.warning('Please enter value', 'Please enter a value for this field');
          return;
        }

        $api.Auth.resetPassword($scope.data.email)
          .then((response) => {
            $scope.noUserFound = false;
            $scope.error = '';
            const message = `We've sent an email to that email address with a link to reset your password`;
            $clickOk(message).
              then(function() {
                $scope.data.email = null;
              });
            })
            .catch((error) => {
              $scope.error = error.data.error;
              if ($scope.error.includes('No user')) {
                $scope.noUserFound = true;
              }
              unwrapError(error);
            });
      };
    }]
  };
};
