module.exports = ['$window', function($window) {
  return {
    restrict: 'E',
    template: require('./text-tooltip.jade'),
    scope: {
      direction: '@',
      text: '@'
    },
    link: function(scope, element, attrs) {
      scope.direction = scope.direction ?
        scope.direction :
        'right';

      element.attr({
        rel: 'tooltip',
        'data-html': true,
        'data-toggle': 'tooltip',
        'data-placement': scope.direction,
        title: scope.text
      });

      if (element.tooltip) {
        element.tooltip();
      }

    }
  };
}];
