import json2csv from 'json2csv';
import XLSX from 'xlsx';
import get from 'lodash/get';

const { allLabels, dateLabels, percentLabels, currencyLabels } = require('shared/requests/download-constants');

// import DownloadHelpers from 'shared/requests/download-constants';

export class SpDownloadService {
  public exportCSVFile = ({ data, fields, fileTitle }: { data: any[], fields?: any[], fileTitle: string }) => {
    const csv = json2csv({ data, fields });
    this.downloadCSVFile(fileTitle, csv);
  }

  private downloadCSVFile = (filename, csv) => {
    const exportedFileName = filename + '.csv';

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== 'undefined') { // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', exportedFileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
    }
  }


  public exportEventsXLSXFile = ({ data, fileTitle, sheetName }) => {
    const ws = XLSX.utils.json_to_sheet(data, { header: allLabels });
    this.formatEventsDownload(ws);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileTitle}.xlsx`);
  }

  public exportXLSXFile = ({ data, fields, fileTitle, sheetName }: { data: any, fields?: any, fileTitle: string, sheetName: string }) => {
    const formattedData = this.formatData({ data, fields });
    const ws = XLSX.utils.json_to_sheet(formattedData, { headers: 1 });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileTitle}.xlsx`);
  }

  private formatData = ({ data, fields }: { data: any, fields?: any[] }) => {
    return data.map((doc) => {
      return fields.reduce((row, field) => {
        if (typeof field.value === 'function') {
          row[field.label] = field.value(doc);
        } else {
          row[field.label] = get(doc, field.value);
        }
        return row;
      }, {});
    });
  }

  private formatEventsDownload (sheet) {
    const dateCols = [];
    const currencyCols = [];
    const percentCols = [];
    const range = XLSX.utils.decode_range(sheet['!ref']);

    const NUMBER_FORMAT = 164;
    const CURRENCY_FORMAT = 165;
    const PERCENT_FORMAT = 166;
    const DATE_FORMAT = 167;

    XLSX.SSF.load('0.00', NUMBER_FORMAT);
    XLSX.SSF.load('$#,##0.00', CURRENCY_FORMAT);
    XLSX.SSF.load('0.00##\%;[Red](0.00##\%)', PERCENT_FORMAT);
    XLSX.SSF.load('mm/dd/yyyy', DATE_FORMAT);

    for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
      const cell = sheet[
        XLSX.utils.encode_cell({ r: 0, c: colNum })
      ];
      if (!cell) {
        continue;
      }

      if (dateLabels.includes(cell.v)) {
        dateCols.push(colNum);
      }

      if (currencyLabels.includes(cell.v)) {
        currencyCols.push(colNum);
      }

      if (percentLabels.includes(cell.v)) {
        percentCols.push(colNum);
      }
    }

    for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
      let format;
      if (dateCols.includes(colNum)) {
        format = DATE_FORMAT;
      }

      if (currencyCols.includes(colNum)) {
        format = CURRENCY_FORMAT;
      }

      if (percentCols.includes(colNum)) {
        format = PERCENT_FORMAT;
      }

      for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
        if (!format) {
          continue;
        }

        const nextCell = sheet[
          XLSX.utils.encode_cell({ r: rowNum, c: colNum })
        ];

        if (!nextCell) {
          continue;
        }

        delete nextCell.w;

        nextCell.z = XLSX.SSF.get_table()[format];
        nextCell.t = 'n';

        XLSX.utils.format_cell(nextCell);
      }
    }
    return sheet;
  }
}
