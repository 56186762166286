// External Deps

// SP Deps
import { ApiService } from 'spc/shared/api/api.service';

export class MessageService {
  constructor(
    private $api: ApiService
  ) { 'ngInject'; }

  public markConversationRead = ({ conversation }) => {
    return this.$api.Conversations.markAllMessagesRead({ conversation });
  };
}