import { DExperience } from './../../../database/types/virtual-events';
import { ApiService } from '../shared/api/api.service';
import { EXPERIENCE_TYPE } from '../../../database/constants/Experience';
import { ExperienceQuery } from 'spc/lib/database/types/virtual-events';
import { debounce, get } from 'lodash';
import { UserService } from '../services/user.service';
import { StateEmitter } from 'spc/utils/StateEmitter';

// constant
import { ANALYTICS_EVENTS } from '../constants/ENUMS/analyticsEvents';
import { SEARCH } from '../constants/search.constant';

const HIDE_NAVBAR = 'HIDE_NAVBAR';
const SHOW_NAVBAR = 'SHOW_NAVBAR';
let ctrl;

class VirtualExperiencesDashboardController {
  ready: () => void;
  ui: {
        loading: boolean
      } = {
    loading : false
  };

  experiences: DExperience[] = [];
  page: number = 0;
  textParams: string = '';
  guestCount: number = null;
  documentsPerPage: number = 24;
  type: string = '';
  currentPage: number = 0;
  experienceTypes = EXPERIENCE_TYPE;
  numExperiences: number = null;
  numVendors: number = null;
  showAll: boolean = false;
  isAdmin: boolean = false;
  showButton: boolean = false;
  $rootParams;
  state = new StateEmitter([HIDE_NAVBAR, SHOW_NAVBAR], SHOW_NAVBAR);
  constructor(
    private $location,
    private $api: ApiService,
    private unwrapError,
    private $cloudinary,
    private $user: UserService,
    private $scope,
    ENUMS,
    $injector
    ) {
    'ngInject';
    ctrl = this;
    ctrl.$analytics = $injector.get('$analytics');
  }
  $onInit = () => {
    const queryParams = this.$location.search();
    if (queryParams && queryParams.guestCount) {
      this.guestCount = Number(queryParams.guestCount);
    }
    this.getExperiences()
    .then(() => {
      this.isAdmin = this.$user.isAdmin();
      return this.isAdmin;
    })
    .then(() => {
      this.ready();
    })
    .catch((error) => {
      this.unwrapError(error);
      this.ready();
    });
  }

  selectTypeHandler() {
    this.getExperiences();
  }

  onPageChange = (page) => {
    this.page = page;
    return this.getExperiences(page = this.page);
  }

  trackSearchEvent = () => {
    const eventName = ANALYTICS_EVENTS.Virtual.search;
    const type = this.type;
    const eventParams = this.eventParamsHandler();
    const eventAction = 'Search';
    // works only when something is searched or any filter is applied
    if (Object.keys(eventParams).length) {
      ctrl.$analytics.$trackEvent(eventName, eventParams, eventAction);
    }
  }

  eventParamsHandler = () => {
    const eventParams: { guestCount?: number, textParams?: string, type?: string } = {};
    if (this.textParams && this.textParams.length >= 1 ) {
      eventParams.textParams = this.textParams;
    }
    if (this.guestCount >= 1) {
      eventParams.guestCount = this.guestCount;
    }
    if (this.type && this.type.length >= 1 ) {
      eventParams.type = this.type;
    }
    return eventParams;
  }

  getExperiences = (page = 0) => {
    this.ui.loading = true;
    this.trackSearchEvent();
    const query = this.queryHandler(page);
    const getExperienceMethod = this.showAll ? this.$api.Admin.Experience.getAll : this.$api.VirtualExperience.getAllPublished;
    return  getExperienceMethod({ query })
      .then((response) => {
        this.experiences = response.experiences;
        this.numExperiences = response.numExperiences;
        this.numVendors = response.numVendors;
        this.ui.loading = false;
        this.currentPage = page + 1;
      })
      .catch((error) => {
        this.ui.loading = false;
        this.unwrapError(error);
      });
  }

  searchHandler = debounce(this.getExperiences, SEARCH.DEBOUNCE);

  queryHandler = (page) => {
    const query: ExperienceQuery = {
      page,
      documentsPerPage: this.documentsPerPage
    };
    if (this.textParams.length) {
      query.textParams = this.textParams;
    }
    if (this.guestCount) {
      query.guestCount = this.guestCount;
    }
    if (this.type.length) {
      query.type = this.type;
    }
    return query;
  }

  toggleShowAll () {
    this.showAll = !this.showAll;
    this.getExperiences();
  }
}

export const virtualExperiencesDashboardComponent = {
  template: require('./virtual-experiences-dashboard.component.jade'),
  controller: VirtualExperiencesDashboardController,
  bindings: {
    ready: '<',
  },
};
