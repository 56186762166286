// NPM Dependencies
import * as angular from 'angular';
import 'angular-tooltips';
import 'angular-elastic-input';

// Vendor Dependencies
const NgStickyModule = require('../../vendor/ngSticky');
const NgDialogModule = require('../../vendor/ngDialog');

// Modules
import AnalyticsModule from './analytics/analytics.module';
import LoadersModule from './loaders/loaders.module';
import MetricsModule from 'spc/shared/metrics/metrics.module';
import GoogleStreetViewModule from './google-street-view/google-street-view.module';
import { BrowserDetectorService } from './browser-detector.service';
import NgCopy from './ng-copy.module';
// Constants
import { CONTACT } from './contact.constant';

// Services
import { TabulationService } from 'spc/shared/tabulation/tabulation.service';
import UibCarouselModule from './uib-carousel/uib.bootstrap.carousel';
import { ApiService } from './api/api.service';
import { ToastService } from './toast.service';
import { CityTzMapperService } from './cityTZmapper.service';
import { now } from './now.value';
import { GoogleMapsService } from './google-maps.service';
import { SelectionModalService } from './selection-modal/selection-modal.service';
import { SpDownloadService } from './sp-download.service';
import { VenueNotesModal } from './venuenotes/venuenotes.modal';

// Components
import SpaceAmenityIconsComponent from './space-amenity-icons.component';
import { PaginationComponent } from './pagination/pagination.component';
import { SpMultiselectComponent } from './sp-multiselect/sp-multiselect.component';
import { ClientMultiselectComponent } from './client-multiselect/client-multiselect.component';
import { VenueNotesComponent } from './venuenotes/venuenotes.component';
import { SpTimeSelectComponent } from 'spc/shared/sp-timeselect/sp-timeselect.component';
import SpTimeDisplay from './sp-timedisplay/sp-timedisplay.directive';
// Factories
import { selectionModal } from 'spc/shared/selection-modal/selection.modal';
import { SpPrinter } from './sp-printer/sp-printer.service';

// Directives
import { spFocus } from './sp-focus';
import { spScrollDirective } from './sp-scroll.directive';
import { RecommendationService } from './recommendation.service';
import { SpPardotService } from './sp-pardot/sp-pardot.service';
import { starRatingComponent } from './sp-star-rating/sp-star-rating.component';

export default angular
  .module('shared', [UibCarouselModule, NgStickyModule, NgDialogModule, AnalyticsModule, LoadersModule, MetricsModule, GoogleStreetViewModule, '720kb.tooltips', 'puElasticInput', NgCopy])
  .service('browserDetectorService', BrowserDetectorService)
  .service('$api', ApiService)
  .service('toast', ToastService)
  .service('cityTzMapperService', CityTzMapperService)
  .service('tabulationService', TabulationService)
  .service('googleMaps', GoogleMapsService)
  .service('selectionModalService', SelectionModalService)
  .service('SpDownloadService', SpDownloadService)
  .service('spPrinter', SpPrinter)
  .service('venueNotesModal', VenueNotesModal)
  .service('recommendationService', RecommendationService)
  .service('spPardot', SpPardotService)
  .value('now', now)
  .constant('CONTACT', CONTACT)
  .component('spaceAmenityIcons', SpaceAmenityIconsComponent)
  .component('spPagination', PaginationComponent)
  .component('clientMultiselect', ClientMultiselectComponent)
  .component('spMultiselect', SpMultiselectComponent)
  .component('venueNotes', VenueNotesComponent)
  .component('spTimeSelect', SpTimeSelectComponent)
  .component('spStarRating', starRatingComponent)
  .factory('selectionModal', selectionModal)
  .directive('spFocus', spFocus)
  .directive('spTimeDisplay', SpTimeDisplay)
  .config(['tooltipsConfProvider', function configConf(tooltipsConfProvider) {
    tooltipsConfProvider.configure({
      smart: true,
      size: 'medium',
      class: 'concierge-error',
      speed: 'fast',
      showTrigger: 'disabled',
      hideTrigger: 'disabled',
    });
  }])
  .directive('spScroll', spScrollDirective)
  .name;
