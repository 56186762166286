class ExpandAreaController {
  currentChoices: [string];
  area: any;
  displayMapper: any = {};

  constructor() {
    'ngInject';
  }

  $onInit = () => {
    for (const key in this.area) {
      if (this.isObject(this.area[key])) {
        this.displayMapper[key] = false;
      }
    }
  }

  toggleDisplay = (key) => {
    this.displayMapper[key] = !this.displayMapper[key];
  }

  isObject = (area) => {
    return typeof area === 'object';
  }

  selectArea = (value) => {
    const index = this.currentChoices.indexOf(value);
    index > -1 ? this.currentChoices.splice(index, 1) : this.currentChoices.push(value);
  }
}

export const ExpandAreaComponent = {
  controller: ExpandAreaController,
  template: require('./expand-area.component.jade'),
  bindings: {
    area: '<',
    currentChoices: '='
  }
};
