// NPM Deps
import get from 'lodash/get';

// SixPlus Deps
import { ApiService } from 'spc/shared/api/api.service';
import { RawVenue } from 'spc/lib/database/types/venue/venue';
import { UserService } from 'spc/services/user.service';
import status from 'http-status';
// constant
import { ANALYTICS_EVENTS } from '../constants/ENUMS/analyticsEvents';

class TopLevelVenueView {
  searchParams: any;
  data: { venue: any, menus?: any[], drinks?: any[] };
  isBot: boolean = false;
  similarVenues: RawVenue[];
  private: boolean;
  user: UserService;
  isPremiumMember: boolean = false;

  constructor(private $location,
    private $rootScope,
    private $seo,
    private $user: UserService,
    private $api: ApiService,
    private $routeParams,
    private unwrapError,
    private ENUMS,
    private $analytics) {
    'ngInject';
    this.searchParams = $location.search();
  }

  $onInit() {
    this.user = this.$user;
    this.getVenue()
      .then(() => this.onReady())
      .catch((error) => {
          if ([status.FORBIDDEN, status.NOT_FOUND, status.UNAUTHORIZED].includes(error.status)) {
          this.$location.url('/upgrade').replace();
          }
          this.unwrapError(error);
        });
  }

  getVenue = () => {
    const slug = this.$routeParams.slug.toLowerCase();
    return this.$api.Venues
      .get(slug, { menus: true, drinks: true, reviews: true })
      .then((data) => {
        this.isBot = data.data.isBot;
        this.data = data.data.data;
        this.data.venue = this.addAmenitesToSpace(this.data.venue);

        this.similarVenues = data.data.similarVenues;
        if (slug !== this.data.venue.slug) {
          this.redirect(`/venue/${this.data.venue.slug}`);
        }
        this.setSeo(this.data.venue);
      });
  }

  addAmenitesToSpace = (venue) => {
    const venueAmenities = get(venue, 'data.addOns');

    venue.data.amenities.forEach((element) => {
      venueAmenities.push({ item: element.item });
    });

    const updatedSpaces = venue.data.spaces.map((space) => {
      const spaceAmenities = get(space, 'data.addOns', []);

      space.data.amenities.forEach((element) => {
        spaceAmenities.push({ item: element });
      });

      space['amenities'] = [...venueAmenities, ...spaceAmenities] || [];
      return space;
    });

    venue.data.spaces = updatedSpaces;
    return venue;
  }

  public redirect(url) {
    this.$location.url(url);
  }

  private setSeo = (venue: any) => {
    const title = get(venue, 'data.name');
    const tagline = get(venue, 'admin.tagline');
    const description = this.ENUMS.metaDescriptions.venueView(title, tagline);
    this.$seo.set(title, description);

    this.trackAnalytics(title);
  }

  private trackAnalytics = (title: string) => {
    const eventName = ANALYTICS_EVENTS.visited.custom.venueView;
    const eventParams = { venue: title };
    const eventAction = 'View';
    this.$analytics.$trackEvent(eventName, eventParams, eventAction);
  }

  private onReady = () => {
    this.$rootScope.$emit('$viewReady', 'SHOW_FOOTER');
  }

}

export const TopLevelVenueViewComponent = {
  template: require('./top-level-venue-view.component.jade'),
  controller: TopLevelVenueView,
  bindings: {
    private: '<'
  }
};
