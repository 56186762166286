import { get, map, reduce, round, sum } from 'lodash';

class ProposalMetricsBreakdownPanel {
  sumStrategy?: string;
  unit: string;
  totals: { sum: number }[];
  data: any;
  title: string;
  overallTotal: number;

  constructor() {
    'ngInject';
  }

  $onInit() {
    const self = this;
    self.unit = self.unit || 'money';
  }

  $onChanges(changes) {
    if (get(changes, 'data')) {
      this.getTotals();
    }
  }

  getTotals() {
    const self = this;
    self.totals = map(self.data, bucket => ({ sum: self.getSum(bucket) }) );

    self.overallTotal = self.getSum(self.totals.map(total => total.sum));
  }

  getPercentOfTotal = (total: number): string => {
    return (total / this.overallTotal * 100).toFixed(2);
  }

  getSum(bucket): number {
    const self = this;
    if (self.unit === 'money') {
      return self.sumStrategy === 'average'
        ? round(sum(bucket) / bucket.length, 2)
        : round(sum(bucket), 2);
    } else {
      return self.sumStrategy === 'average'
        ? round(sum(bucket) / bucket.length, 2)
        : round(sum(bucket), 2);
    }
  }

  isMoney() {
    return this.unit === 'money';
  }
}

export const ProposalMetricsBreakdownPanelComponent = {
  bindToController: true,
  template: require('./proposal-metrics-breakdown-panel.component.jade'),
  bindings: {
    cities: '<',
    data: '<',
    title: '@',
    unit: '@',
    sumStrategy: '@'
  },
  controller: ProposalMetricsBreakdownPanel
};
