// External Dependencies
export default ['ngDialog', function(ngDialog) {
  return function (city) {
    return ngDialog.open({
      template: `<edit-featured-venues city=$ctrl.city date=$ctrl.date></edit-featured-venues>`,
      className: 'ngdialog-theme-plain info-modal',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function ($scope) {
        this.city = city;
        this.close = (data) => {
          $scope.closeThisDialog(data);
        };
      }]
    }).closePromise;
  };
}];
