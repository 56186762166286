import addCustomContact from './addCustomContact';
import docEditNav from './docEditNav';
import userVenuesIndex from './userVenuesIndex';
import userVenueNew from './userVenueNew';
import userSpacesNew from './userSpacesNew';
import userDrinksNew from './userDrinksNew';
import userMenusNew from './userMenusNew';
import toggleVisibility from './toggleVisibility';

export default {
  addCustomContact,
  docEditNav,
  toggleVisibility,
  userVenuesIndex,
  userVenueNew,
  userSpacesNew,
  userDrinksNew,
  userMenusNew,
};
