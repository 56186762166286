import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { RawGroup } from 'spc/lib/database/types/group';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { RawLead } from 'spc/lib/database/types/lead';
import _ from 'lodash';

class UserConnectionsController {
  doc: RawGroup | RawLead ;
  searchValue: string;
  connectedUsers: RawBaseUser[];
  user: RawBaseUser;
  addUser: (user) => any;
  removeUser: (user) => any;
  getGroups: boolean;
  sort: ({ users }: { users: RawBaseUser[] }) => RawBaseUser[];

  newUser: {
    profile: {
      name: {
        first: string;
        last: string;
      }
      email: string;
    }
    company?: {
      name: string
    };
  };

  filteredClients: RawBaseUser[];
  displayFilteredData: { [key: string]: RawBaseUser[] };
  errors: {
    lastName?: boolean;
    firstName?: boolean;
    email?: boolean;
  };
  loading: boolean;

  constructor(private $api, private unwrapError, private $scope, private $user) {
    'ngInject';
    this.searchValue = '';
    this.newUser = this.setEmptyUser();
    this.errors = {};
    this.loading = true;
  }

  $onInit() {
    if (!this.user) {
      this.user = this.$user.$;
    }

    if (!this.connectedUsers) {
      this.getConnectedUsers();
    } else {
      this.filteredClients = this.connectedUsers;
      this.setDisplayFilteredData();
      this.loading = false;
    }
  }

  getConnectedUsers() {
    return this.$api.BaseUsers.getConnections(this.user._id.toString())
      .then((res) => {
        this.connectedUsers = res.users.filter(u => u._id.toString() !== this.user._id.toString());
        this.filteredClients = this.connectedUsers;
        this.setDisplayFilteredData();
        this.loading = false;
      })
      .catch(error => this.unwrapError(error));
  }

  setEmptyUser() {
    return {
      profile: {
        name: {
          first: '',
          last: '',
        },
        email: ''
      },
      company: {
        name: get(this.user, 'company.name', '')
      },
    };
  }

  debouncedSearch = debounce((searchString) => {
    searchString = searchString.toLowerCase();
    if (searchString.length === 0) {
      this.filteredClients = this.connectedUsers;
      this.setDisplayFilteredData();
    } else {
      this.filteredClients = this.connectedUsers.reduce((clientList, currentClient) => {
        currentClient.fullName = currentClient.fullName || `${currentClient.profile.name.first} ${currentClient.profile.name.last}`;

        if (currentClient.fullName.toLowerCase().includes(searchString) ||
          currentClient.profile.email.toLowerCase().includes(searchString)) {
          clientList.push(currentClient);
        }
        return clientList;
      }, []);
      this.setDisplayFilteredData();
    }

    if (!this.filteredClients.length && this.hasEmail(searchString)) {
      const searchItems = searchString.split(' ');
      const email = searchItems.find(str => this.hasEmail(str));
      this.newUser.profile.email = email;
      this.newUser.company.name = get(this.doc, 'company.name');
      this.searchByEmail(email);
    }
    this.$scope.$apply();
  }, 500);

  searchByEmail = (email) => {
    return this.$api.BaseUsers.searchByEmail(email)
      .then((res) => {
        this.filteredClients = res.users;
        this.setDisplayFilteredData();
      })
      .catch(error => this.unwrapError(error));
  }

  showManualInvite() {
    return this.hasEmail(this.searchValue) && !this.filteredClients.length;
  }

  hasEmail = (str) => {
    const emailPattern = /[^@\s]+@[^@.\s]*\.[^@\s]+/i;
    return str.match(emailPattern);
  }

  canCreateUser() {
    this.errors.firstName = this.newUser.profile.name.first ? false : true;
    this.errors.lastName = this.newUser.profile.name.last ? false : true;
    this.errors.email = this.newUser.profile.email ? false : true;
    return this.newUser.profile.name.first && this.newUser.profile.name.last && this.newUser.profile.email;
  }

  inviteUser({ user }) {
    this.addUser({ user });
    if (!this.connectedUsers.some(u => u._id.toString() === user._id.toString())) {
      this.connectedUsers.push(user as RawBaseUser);
    }
    this.searchValue = '';
    this.filteredClients = this.connectedUsers;
    this.setDisplayFilteredData();
  }

  createAndAddUser() {
    if (this.canCreateUser()) {
      this.addUser({ user: this.newUser });
      this.connectedUsers.push(this.newUser as RawBaseUser);
      this.newUser = this.setEmptyUser();
      this.searchValue = '';
      this.filteredClients = this.connectedUsers;
      this.setDisplayFilteredData();
    }
  }

  setDisplayFilteredData = () => {
    this.displayFilteredData = _.groupBy(this.filteredClients, 'connectionSource');
  }

}

export const UserConnectionsComponent = {
  template: require('./user-connections.component.jade'),
  controller: UserConnectionsController,
  bindings: {
    doc: '<',
    addUser: '&',
    removeUser: '&',
    isAddedClient: '&',
    connectedUsers: '<',
    user: '<',
  }
};
