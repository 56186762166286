class TopLevelMembershipAccountController {
    constructor(private $rootScope, private $seo) {
      'ngInject';
    }

    componentIsReady = () => {
     this.$rootScope.$emit('$viewReady', 'SHOW_FOOTER');
    }
   }

   export const topLevelMembershipAccountComponent = {
     template: require('./top-level-membership-account.component.jade'),
     controller: TopLevelMembershipAccountController
   };
