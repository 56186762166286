class TopLevelConciergeController {
  canInit: boolean;
  leadId: string;

  constructor (
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $seo,
    private $routeParams,
    private $user) {
      'ngInject';
    }


  $onInit() {
    this.$user.$waitFor('LOGGED_IN', () => {
      if (!this.$user.isAdmin()) {
        return this.redirectTo(`/404`);
      }
      this.canInit = true;
      this.leadId = this.$routeParams.id;
    });

    this.$user.$waitFor('NOT_LOGGED_IN', () => {
      this.redirectTo('/404');
    });
  }

  redirectTo = (url: string) => {
    return this.$location.path(url);
  }

  componentIsReady = () => {
    this.$seo.set(`Event Management Dashboard`);
    this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
  }
}

export const TopLevelConciergeComponent = {
  controller: TopLevelConciergeController,
  template: require('./top-level-concierge.component.jade')
};
