import { ApiService } from 'spc/shared/api/api.service';
import { RawLead } from 'spc/lib/database/types/lead';

export class ServerSentEventsService {
  constructor(private $api: ApiService, private unwrapError) {
    'ngInject';
  }

  public startTrackingLead = (lead: RawLead) => {
    const listener = this.$api.Leads.track(lead._id.toString());
    listener.onerror = e => this.unwrapError(e);
    return listener;
  }

  public setMessageHandler = ({ listener, messageHandler }: { listener: any, messageHandler: (e?: any) => any }) => {
    listener.onmessage = messageHandler;
  }

  public stopTrackingLead = (listener: any) => {
    if (listener) {
      listener.close();
    }
  }
}
