export default function (ambiance) {
    return {
        'success-bg': ambiance === 'Casual',
        'primary-alternate-bg': ambiance === 'Charming',
        'info2-bg': ambiance === 'Local Spot',
        'primary-dark-bg': ambiance === 'Historic',
        'info-bg': ambiance === 'Rustic',
        'alternate-bg': ambiance === 'Elegant',
        'primary-bg': ambiance === 'Ritzy',
        'highlight-bg': ambiance === 'Trendy',
        'cozy-bg': ambiance === 'Cozy',
        'eclectic-bg': ambiance === 'Eclectic',
        'hidden-gem-bg': ambiance === 'Hidden Gem',
        'modern-bg': ambiance === 'Modern',
        'stylish-bg': ambiance === 'Stylish',
        'glamorous-bg': ambiance === 'Glamorous',
        'traditional-bg': ambiance === 'Traditional',
        'bright-bg': ambiance === 'Bright',
        'retro-bg': ambiance === 'Retro',
        'old-school-bg': ambiance === 'Old School',
        'functional-bg': ambiance === 'Functional'
    }
}