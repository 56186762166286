// NPM dependencies
import UserHelpers from 'common/dist/virtuals/User';
import get from 'lodash/get';
import { ApiService } from 'spc/shared/api/api.service';
import { RawBookingRequest } from '../../../../database/types/booking-request';
import { RawBaseUser } from '../../../../database/types/base-user';

class RequestClientContactDetailsController {
  request: RawBookingRequest;
  primaryUser: RawBaseUser;
  ui: {
    copied: string;
    sendingEmail: boolean;
  } = { copied: null, sendingEmail: false };
  constructor(
    private $user,
    private ENUMS,
    private $location,
    private $timeout,
    private $api: ApiService,
    private unwrapError,
    private $clickOk
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.primaryUser = this.request.clients.find(client => client.isPrimary).user as RawBaseUser;
  }

  public isNewUser = client => !UserHelpers.isActualUser(client);

  public hideClientDetails = () => {
    if (!this.request) {
      return;
    }
    return this.ENUMS.bookingRequestState.preBookedStates.includes(this.request.state) && this.$user.isHost();
  }

  public getUserLink = (client) => {
    let domain = `${this.$location.protocol()}://${this.$location.host()}`;
    if (this.$location.port() !== 443) {
      domain = `${domain}:${this.$location.port()}`;
    }
    const conversationId = this.request.conversation._id ? this.request.conversation._id.toString() : this.request.conversation;
    const link = `${domain}/client/conversation/${conversationId}`;
    if (this.isNewUser(client.user)) {
      return `${link}/preview/`;
    }
    return link;
  }

  public handleCopyClick = (client) => {
    this.ui.copied = client._id.toString();
    this.$timeout(() => this.ui.copied = null, 1000);
  }

  public sendClientEmail = async (client) => {
    try {
      const promptResponse = await this.$clickOk('This client will receive an email with a link to this proposal.', true);

      if (promptResponse && get(promptResponse, 'value.cancel')) {
        return;
      }

      this.ui.sendingEmail = true;
      await this.$api.Requests.emailClient({ request: this.request, recipients: [client.user] });
      this.ui.sendingEmail = false;

    } catch (error) {
      this.ui.sendingEmail = false;
      this.unwrapError(error);

    }
  }
}

export const RequestClientContactDetailsComponent = {
  template: require('./request-client-contact-details.component.jade'),
  bindings: {
    request: '<',
    client: '<',
  },
  controller: RequestClientContactDetailsController
};
