class TopLevelAdminController {
    constructor(private $rootScope, private $seo) {
        'ngInject';
    }

    componentIsReady = () => {
        this.$seo.set('Teams');
        this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
    }
}

export const topLevelAdminAccountsComponent = {
    template: require('./top-level-admin-accounts.component.jade'),
    controller: TopLevelAdminController
};
