import { ApiService } from 'spc/shared/api/api.service';
import { RawBookingRequest } from 'lib/database/types/booking-request';

class TopLevelReceiptsController {
  request: RawBookingRequest;
  location: string;
  constructor(
    private $rootScope,
    private $user,
    private $api: ApiService,
    private $routeParams,
    private $location,
    private $timeout
  ) {
    'ngInject';
  }


  $onInit = () => {
    const conversationId = this.$routeParams.id;
    this.$user.allowOnly('Guest', () => {
      this.$api.Requests.conversation(conversationId)
        .then((response: any)  => {
          this.request = response.request;
          this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
        });
    });

    this.$rootScope.$on('$locationChangeSuccess', () => {
      this.location = this.$location.search().receipt;
      this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
    });
  }

  handleReceiptClick = ({ location }) => {
    this.$location.search('receipt', location);
    this.$timeout(() => {
      this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
    });
  }

  redirectTo = (url: string) => {
    return this.$location.path(url);
  }
}

export const topLevelReceiptsComponent = {
  template: require('./top-level-receipts.component.jade'),
  controller: TopLevelReceiptsController
};
