import { ApiService } from '../../../shared/api/api.service';
import { Recommendation, AdminNote } from 'server/api/admin/recos/models';
import { ToastService } from 'spc/shared/toast.service';
import { cloneDeep } from 'lodash';
import { UserService } from 'spc/services/user.service';
import { RawUser } from 'spc/lib/database/types/user';

class RecoNotesController {
    recoId: string;
    reco: Recommendation;
    adminNotes: AdminNote[];

    ui: {
        editing: boolean,
        newNote?: boolean,
    } = {
        editing: false,
        newNote: false,
    };
    noteForm: {
        title: string,
        message: string,
        updatedBy: string,
    } = {
        title: '',
        message: '',
        updatedBy: '',
    };
    user: RawUser;
    editNoteIndex: number;

    constructor(
        private $scope,
        private $api: ApiService,
        private toast: ToastService,
        private $user: UserService,
        private unwrapError,
    ) {
        'ngInject';
    }

    $onInit = () => {
        this.getReco(this.recoId);
        this.user = this.$user.$;
    }

    getReco = (id) => {
        this.$api.Admin.Recommendation.getReco({ id: this.recoId })
            .then((res) => {
                this.reco = res.reco;
                this.adminNotes = this.reco.adminNotes;
            }).catch((error) => {
                this.unwrapError(error);
            });
    }

    newNote = () => {
        this.ui.newNote = true;
    }

    editNote = (note, noteIndex) => {
        this.ui.editing = true;
        this.editNoteIndex = noteIndex;
        this.noteForm = cloneDeep(note);
    }

    cancelEdit = () => {
        this.ui.editing = false;
        this.ui.newNote = false;
        this.resetNoteForm();
    }

    resetNoteForm = () => {
        this.noteForm.title = '';
        this.noteForm.message = '';
        this.noteForm.updatedBy = '';
    }

    handleEditedNote = () => {
        this.adminNotes[this.editNoteIndex] = this.noteForm;
    }

    saveNotes = () => {
        if (this.ui.editing || this.ui.newNote) {
            this.noteForm.updatedBy = this.user.fullName;
            this.ui.newNote ? this.adminNotes.push(this.noteForm) : this.handleEditedNote();
        }

        return this.$api.Admin.Recommendation.updateAdminNotes({ id: this.reco._id, adminNotes: this.adminNotes })
            .then((res) => {
                this.reco = res.reco;
                this.resetNoteForm();
                this.ui.newNote = false;
                this.ui.editing = false;
            });
    }

    deleteNote = ({ index }) => {
        this.adminNotes.splice(index, 1);
        this.saveNotes();
    }

}

export const RecoNotesComponent = {
    controller: RecoNotesController,
    controllerAs: '$ctrl',
    template: require('./reco-notes.component.jade'),
    bindings: {
        recoId: '<',
        close: '<',
    }
};
