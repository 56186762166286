import * as angular from 'angular';

import VenueSearchControlComponent from './venue-search-control.component';
import VenueSearchComponent from './venue-search.component';
import VenueSearchService from './venue-search.service';
import VenueSearchCounts from './venue-search-counts.component';
import VenueSearchMapService from './venue-search-map.service';
import VenueSearchFiltersService from './venue-search-filters.service';
import VenueSearchFiltersComponent from './venue-search-filters.component';
import VenueSearchMapComponent from './venue-search-map.component';
import SpaceInfoWindowComponent from './space-info-window.component';
import { VenueSearchResultCardComponent } from './venue-search-result-card.component';
import SpaceSearchResultCardComponent from './space-search-result-card.component';
import SpaceResultForShellCardComponent from './space-result-for-shell-card.component';
import { ViewWrapperStateService } from './view-wrapper-state.service';
import { ResultCardService } from './result-card.service';
import { KEY_TO_ABBREVIATION_MAPPER } from './key-to-abbreviation-mapper.constant';
import cspVideoPlayer from './cspVideoPlayer/csp-video-player.modal';
import cspQuickView from './cspQuickView/csp-quick-view.modal';
import { cspVideoPlayerComponent } from './cspVideoPlayer/csp-video-player.component';
import { cspQuickViewComponent } from './cspQuickView/csp-quick-view.component';
export default angular.module('components.search.venue', [])
  .component('venueSearchControl', VenueSearchControlComponent)
  .component('venueSearch', VenueSearchComponent)
  .component('venueSearchCounts', VenueSearchCounts)
  .component('venueSearchFilters', VenueSearchFiltersComponent)
  .component('venueSearchMap', VenueSearchMapComponent)
  .component('spaceInfoWindow', SpaceInfoWindowComponent)
  .component('venueSearchResultCard', VenueSearchResultCardComponent)
  .component('spaceSearchResultCard', SpaceSearchResultCardComponent)
  .component('spaceResultForShellCard', SpaceResultForShellCardComponent)
  .component('cspVideoPlayer', cspVideoPlayerComponent)
  .component('cspQuickView', cspQuickViewComponent)
  .service('cspVideoPlayer', cspVideoPlayer)
  .service('cspQuickView', cspQuickView)
  .service('VenueSearchService', VenueSearchService)
  .service('VenueSearchMapService', VenueSearchMapService)
  .service('VenueSearchFiltersService', VenueSearchFiltersService)
  .service('viewWrapperStateService', ViewWrapperStateService)
  .service('resultCardService', ResultCardService)
  .constant('KEY_TO_ABBREVIATION_MAPPER', KEY_TO_ABBREVIATION_MAPPER )
  .name;
