import { ApiService } from 'spc/shared/api/api.service';
import { isActualUser } from 'common/dist/virtuals/User';
import { SpPardotService } from 'spc/shared/sp-pardot/sp-pardot.service';
class VenueUserRegistrationController {
  user: any;
  constructor(private $location, private $routeParams, private $api: ApiService, private $rootScope, private spPardot: SpPardotService) {
    'ngInject';
  }

  $onInit = () => {
    const userId = this.$routeParams.id;
    this.$api.BaseUsers.get(userId)
      .then((data) => {
        const user = data.user;
        if (isActualUser(user)) {
          this.redirectTo('/login');
        }
        this.user = data.user;
      })
      .then(() => this.componentIsReady());
  }


  redirectTo = (path) => {
    return this.$location.path(path);

  }
  postRegistration = () => {
    this.redirectTo('/');
  }

  componentIsReady = () => {
    this.$rootScope.$emit('$viewReady', 'SHOW_FOOTER');
  }
}

export const VenueUserRegistrationComponent = {
  template: require('./venue-user-registration.component.jade'),
  controller: VenueUserRegistrationController
};
