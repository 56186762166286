import {
  GetLocationsResponse,
  CreateLocationResponse,
  ModifyLocationResponse,
  GetOneResponse,
  CreateSearchPageResponse,
  AddLocationToSearchPageResponse
} from 'lib/server/api/admin/locations/models';


export class Locations {
  BASE_URL: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${API_ROOT}/admin/locations`;
  }

  public get = (params?: { topLevelOnly: boolean }): ng.IPromise<GetLocationsResponse> => {
    return this.$http.get(this.BASE_URL, { params })
      .then((res: { data: GetLocationsResponse }) => res.data);
  }

  public getOne = (location): ng.IPromise<GetOneResponse> => {
    return this.$http.get(`${this.BASE_URL}/${location._id}`)
      .then((res: { data: GetOneResponse }) => res.data);
  }

  public create = (location): ng.IPromise<CreateLocationResponse>  => {
    return this.$http.post(this.BASE_URL, { location })
      .then((res: { data: CreateLocationResponse }) => res.data);
  }

  public createSearchPage = (location): ng.IPromise<CreateSearchPageResponse> => {
    return this.$http.post(`${this.BASE_URL}/search-page/`, { location })
      .then((res: { data: CreateSearchPageResponse }) => res.data);
  }

  public addLocationToSearchPage = ({ location, parent }): ng.IPromise<AddLocationToSearchPageResponse> => {
    return this.$http.put(`${this.BASE_URL}/search-page/${parent._id}`, { location })
      .then((res: { data: AddLocationToSearchPageResponse }) => res.data);
  }

  public update = ({ location, changes }): ng.IPromise<ModifyLocationResponse> => {
    const url = `${this.BASE_URL}/${location._id}`;
    return this.$http.put(url, { changes })
      .then((res: { data: ModifyLocationResponse }) => res.data);
  }

  public delete = (location): ng.IPromise<any> => {
    const url = `${this.BASE_URL}/${location._id}`;
    return this.$http.delete(url);
  }
}
