// External Deps

// SP Deps

// SP Interfaces

class TopLevelPersonalEventsDashboardController {
  canInit: boolean;
  role: 'client' | 'venue';

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $user
  ) {
    'ngInject';
  }

  $onInit() {
    this.$user.$waitFor('LOGGED_IN', () => {
      this.role = this.$user.isHost() ? 'venue' : 'client';
      if (this.$user.isGuest()) {
        return this.redirect(`/user/events`);
      }
      this.canInit = true;
    });
    this.$user.$waitFor('NOT_LOGGED_IN', () => this.$location.url(`/login?redirect=${ encodeURIComponent(this.$location.path()) }`));
  }


  componentIsReady = () => {
    this.$rootScope.$emit('$viewReady', 'SHOW_FOOTER');
  }


  redirect = (url: string) => {
    return this.$location.path(url);
  }
}

export const TopLevelPersonalEventsDashboardComponent = {
  template: require('./top-level-personal-events-dashboard.component.jade'),
  controller: TopLevelPersonalEventsDashboardController
};
