import each from 'lodash/each';
import pdfjs from 'pdfjs-dist/build/pdf';
pdfjs.GlobalWorkerOptions.workerSrc = 'https://npmcdn.com/pdfjs-dist@2.4.456/build/pdf.worker.js';

module.exports = function() {
  const ret: any = {};
  const BASE  = 'res.cloudinary.com/dbwijvnzn/image/upload/';
  const pptUrl = 'https://res.cloudinary.com/dbwijvnzn/image/upload/v1494275921/assets/Powerpoint-icon.svg';
  const wordUrl = 'https://res.cloudinary.com/dbwijvnzn/image/upload/v1494276106/assets/Word-icon.svg';
  const excelUrl = 'https://res.cloudinary.com/dbwijvnzn/image/upload/v1494276105/assets/Excel-icon.svg';

  ret.types = {
    micro: 'a_exif,c_fill,g_center,h_35,q_auto,w_50,f_auto/',
    thumb: 'a_exif,c_fill,g_center,h_240,q_auto,w_320,f_auto/',
    ipad: 'a_exif,c_fill,g_center,q_auto,w_768,f_auto/',
    small: 'a_exif,c_fill,g_center,q_auto,w_1024,f_auto/',
    large: 'a_exif,c_fill,g_center,q_auto,w_1400,f_auto/',
    retina: 'a_exif,c_fill,g_center,q_auto,w_2000,f_auto/'
  };

  const extensionToUrl = {
    doc: wordUrl,
    docx: wordUrl,
    txt: wordUrl,
    xls: excelUrl,
    xlsx: excelUrl,
    csv: excelUrl,
    ppt: pptUrl,
    pptx: pptUrl
  };

  ret.isPDF = function(attachment) {
    const pdfExt = /.pdf$/;
    return attachment.url.search(pdfExt) > -1;
  };

  ret.getPageCount = (attachment) => {
    const loadingTask = pdfjs.getDocument({ url: attachment.url });
    return loadingTask.promise
      .then((doc) => {
        const numPages = doc.numPages;
        attachment.pages = ret.setPDFPages(attachment, numPages);
        return attachment;
      });
  };

  ret.setPDFPages = function(attachment, numPages) {
    const pdfPages = [];
    for (let i = 1; i <= numPages; i++) {
      const splitUrl = attachment.url.split('/');
      const addPageIndex = splitUrl.indexOf('uploads');
      splitUrl.splice(addPageIndex - 1, 0, `pg_${i}`);
      const pageUrl = splitUrl.join('/');
      pdfPages.push(pageUrl);
    }
    return pdfPages;
  };

  ret.getPDFUrl = function(url, type) {
    if (type) {
      const splitUrl = url.split('/');
      const typeIndex = splitUrl.indexOf('upload');
      let formatStr = splitUrl[typeIndex + 1];
      formatStr = `${ret.types[type].slice(0, -1)},${formatStr}`;
      splitUrl[typeIndex + 1 ] = formatStr;
      url = splitUrl.join('/');
    }
    return url;
  };

  ret.convertURL = function (url, type) {
    if (!url) {
      return;
    }
    const header = BASE + '/upload/';
    const index = url.indexOf(header);
    if (index === -1) {
      return url;
    }

    const point = index + header.length;
    let str = url.substr(0, point);

    str += ret.types[type] || '';
    str += url.substr(point);

    const lastDot = str.lastIndexOf('.');
    str = str.substr(0, lastDot) + '.jpg';
    return str;
  };

  ret.fetchAndConvertURL = function (url, type) {
    if (!url) {
      return;
    }
    const baseURL = '://res.cloudinary.com/dbwijvnzn/image/upload/';
    let link = '//' + BASE;
    link += ret.types[type] || '';

    if (url.indexOf(baseURL) > -1) {
      url = url.substr(url.lastIndexOf('upload/') + 7);
    }

    link += url;

    return link;
  };

  ret.fetchAndConvertDocumentUrl = function (url, type) {
    if (!url) {
      return;
    }
    const extension = url.split('.').pop();
    return extensionToUrl[extension] || ret.fetchAndConvertURL(url, type);
  };

  ret.srcSet = function (url) {
    if (!url) {
      return;
    }

    const extension = url.split('.').pop();
    if (extensionToUrl[extension]) {
      return;
    }

    const urls = [];
    const baseURL = BASE;
    // var baseURL = BASE + '/upload/';
    let isUpload = false;

    const sets = {
      '1024w': 'a_exif,c_fill,g_center,q_auto,w_1024,f_auto',
      '50w': 'a_exif,c_fill,g_center,q_auto,w_50,f_auto',
      '180w': 'a_exif,c_fill,g_center,q_auto,w_180,f_auto',
      '320w': 'a_exif,c_fill,g_center,q_auto,w_320,f_auto',
      '768w': 'a_exif,c_fill,g_center,q_auto,w_768,f_auto',
      '1400w': 'a_exif,c_fill,g_center,q_auto,w_1400,f_auto',
      '2000w': 'a_exif,c_fill,g_center,q_auto,w_2000,f_auto',
    };

    // If we have an url like this, get the original image
    // http://res.cloudinary.com/dbwijvnzn/image/fetch/a_exif,c_fill,g_center,h_240,q_99,w_320/http://www.theglamnationnetwork.com/uploads/1/3/1/2/13123626/9188008_orig.jpg
    if (url && url.lastIndexOf('http') > -1) {
      url = url.substr(url.lastIndexOf('http'));
    }

    // If we have an url like this, get the original image
    // http://res.cloudinary.com/dbwijvnzn/image/upload/v1439920887/b2ktt72macnamsvyfuvj.jpg
    // http://res.cloudinary.com/dbwijvnzn/image/upload/a_exif,c_fill,g_center,h_240,q_99,w_320/v1439920887/b2ktt72macnamsvyfuvj.jpg

    if (url && url.indexOf(baseURL) > -1) {
      isUpload = true;
      url = url.substr(url.lastIndexOf('/') + 1);
    }

    each(sets, (filterParams, resolution) => {
      const u = isUpload ?
        '//' + baseURL + filterParams + '/' + url :
        '//' + BASE + '/fetch/' + filterParams + '/' + url;

      urls.push(u + ' ' + resolution);
    });

    return urls.join(',');
  };

  return ret;
};
