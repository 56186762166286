// SixPlus Types

import {
  GetLeadResponse,
  UpdateRecommendationRequestBody,
  UpdateRecommendationResponse,
  SendMessageRequestBody,
  SendMessageResponse,
  GetRecosResponseBody,
  GetMessagesResponseBody,
  RequestProposalResponse,
  UpdateAttachmentResponse,
  UpdateLiteRecoResponse,
} from 'server/api/recos/models';
import { RawRecommendation } from '../../../../database/types/recommendation';

export class RecosRouter {
  BASE_URL: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${API_ROOT}/recos`;
  }

  public getLead = (id: string): ng.IPromise<GetLeadResponse> => {
    return this.$http.get(`${this.BASE_URL}/${id}`)
      .then((response: { data: GetLeadResponse }) => response.data);
  }

  public createReco = ({ recommendation, createProposal, bookingRequest }: { recommendation: any, createProposal?: boolean; bookingRequest: string; }): ng.IPromise<any> => {
    return this.$http.post(`${this.BASE_URL}`, { recommendation, createProposal, bookingRequest })
      .then(response => response.data);
  }

  public updateRecommendation = (recommendationId: string, data: UpdateRecommendationRequestBody): ng.IPromise<UpdateRecommendationResponse> => {
    return this.$http.put(`${this.BASE_URL}/${recommendationId}`, data)
      .then((response: { data: UpdateRecommendationResponse }) => response.data);
  }

  public updateAttachments = ({ recoId, attachments }: { recoId: string, attachments: { url: string, title: string }[] }): ng.IPromise<UpdateAttachmentResponse> => {
    return this.$http.put(`${this.BASE_URL}/${recoId}/attachments`, { attachments })
      .then((response: { data: UpdateAttachmentResponse }) => response.data);
  }

  public updateOrder = ({ recosToUpdate, leadId }) => {
    return this.$http.put(`${this.BASE_URL}/order`, { recosToUpdate, leadId })
      .then(response => response.data);
  }

  public sendMessage = (data: SendMessageRequestBody): ng.IPromise<SendMessageResponse> => {
    return this.$http.post(`${this.BASE_URL}/${data.leadId.toString()}/message`, data)
      .then((response: { data: SendMessageResponse }) => response.data);
  }

  public underReview = (id: string): ng.IPromise<any> => {
    return this.$http.put(`${this.BASE_URL}/${id}/review`, {})
      .then(response => response.data);
  }

  public getRecos = (id: string): ng.IPromise<GetRecosResponseBody> => {
    return this.$http.get(`${this.BASE_URL}/${id}/recos`)
      .then((response: { data: GetRecosResponseBody }) => response.data);
  }

  public getMessages = (id: string): ng.IPromise<GetMessagesResponseBody> => {
    return this.$http.get(`${this.BASE_URL}/${id}/messages`)
      .then((response: { data: GetMessagesResponseBody }) => response.data);
  }

  public requestProposal = (recommendation: RawRecommendation): ng.IPromise<RequestProposalResponse> => {
    const id = recommendation._id ? recommendation._id.toString() : recommendation;
    return this.$http.post(`${this.BASE_URL}/${id}/proposal`, {})
      .then((response: { data: RequestProposalResponse }) => response.data);
  }

  public updateLiteReco = (recoId: string, value: string): ng.IPromise<UpdateLiteRecoResponse> => {
    const id = recoId;
    return this.$http.put(`${this.BASE_URL}/${id}/lite`, { isInterested: value })
      .then((response: { data: UpdateLiteRecoResponse }) => response.data);
  }

  public updateAvailability =  ({ leadId, recommendationId, availabilityState }: { leadId: string, recommendationId: string, availabilityState: string }): ng.IPromise<UpdateAvailabilityResponse> => {
      return this.$http.put(`${this.BASE_URL}/${recommendationId}/${ leadId }/availability`, { availabilityState })
      .then((response: { data: UpdateAvailabilityResponse }) => response.data);
    }

  public updateRecoComment =  (recommendationId: string, comment: any, operation: string): ng.IPromise<UpdateRecommendationResponse> => {
    return this.$http.put(`${this.BASE_URL}/${recommendationId}/comment`, { comment, operation })
    .then((response: { data: UpdateRecommendationResponse }) => response.data);
  }
}
