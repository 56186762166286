import { capitalize } from 'lodash';
// constant
import { ANALYTICS_EVENTS } from '../constants/ENUMS/analyticsEvents';

exports.$controller = function($rootScope, $scope, $location, $user, $seo, $injector, ENUMS, unwrapError) {
  const title = $location.url() === '/login' ? 'Login' : 'Register';
  const description = title === 'Login' ? ENUMS.metaDescriptions.login : ENUMS.metaDescriptions.register;
  $seo.set(title, description);
  const $analytics = $injector.get('$analytics');

  $scope.$on('LOGIN_SUCCESS', function(ev, res) {
    $analytics.$identify($user.$);

    // Analytics - logged in with email, facebook, or linkedin
    const eventName = res && res.provider ?
      'loggedIn' + capitalize(res.provider) :
      'loggedIn';
    const eventAction = 'login success';
    $analytics.$trackEvent(ANALYTICS_EVENTS.auth[eventName], '', eventAction);

    // Redirect
    if ($location.search()['redirect']) {
      $location.path($location.search()['redirect']);
    } else {
      $location.path('/');
    }
  });

  $scope.$on('REGISTER_SUCCESS', function(ev, res) {
    $user.setUser(res.user, res.token, res.provider);

    // Analytics - register (alias) user, then identify
    $analytics.$register($user.$);
    const eventName = res.provider ?
      'registered' + capitalize(res.provider) :
      'registered';
    const eventAction = 'registration success';
    $analytics.$trackEvent(ANALYTICS_EVENTS.auth[eventName], '', eventAction);

    // Redirect
    if ($location.search()['redirect']) {
      $location.url($location.search()['redirect']);
    } else if (res.provider) {
      $location.url('/welcome?social=' + (res.provider || '1'));
    } else {
      $location.url('/welcome');
    }
  });
};

exports.$inject = ['$rootScope', '$scope', '$location', '$user', '$seo', '$injector', 'ENUMS'];
