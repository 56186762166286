import events from 'events';
import { each } from 'lodash';

const Emitter = events.EventEmitter;


/*
 * State machine on top of event emitters, or, a more general approach
 * to promises. The primary motivation is the `$waitFor()` function,
 * which fires a callback the next time the state machine is in a
 * given state. This includes firing the callback immediately if the
 * emitter is currently in the desired state.
 */

export class StateEmitter {
  _emitter: any = {};
  _log: string[] = [];
  _was: any = {};
  _state: string;

  on: (state: string, cb?: any) => void;
  once: (state: string, cb?: any) => void;
  emit: (state: string, cb?: any) => void;

  constructor(states, init) {
    this._emitter = new Emitter();
    this._log = [init];
    this._was;
    this._state = init;

    each(['on', 'once', 'emit'], (fn) => {
      this[fn] = function() {
        this._emitter[fn].apply(this._emitter, arguments);
      };
    });
  }

  $waitFor = (state, callback) => {
    if (this._state === state) {
      return callback();
    }
    this.once(state, function() {
      callback();
    });

    return this;
  }

  $expect = (state, unless, callback) => {
    if (typeof unless === 'function') {
      callback = unless;
      unless = [];
    }
    if (this._state === state) {
      return setTimeout(function() {
        callback();
      }, 0);
    }

    this.once('change', function(delta) {
      if (unless.indexOf(delta.from) !== -1) {
        return callback(new Error('Transitioned from excluded state ' +
          delta.from));
      }

      if (delta.to === state) {
        return callback();
      }

      callback(new Error('Transitioned to unexpected state ' + delta.to));
    });

    return this;
  }

  $was = (state) => {
    return this._was[state];
  }

  stream = () => {
    const ret: any = new Emitter();

    setTimeout(() => {
      ret.emit(this.$state());
    }, 0);

    const listener = function(delta) {
      setTimeout(function() {
        ret.emit(delta.to);
      }, 0);
    };

    this.on('change', listener);

    ret.off = function() {
      this._emitter.removeListener('change', listener);
    };

    return ret;
  }

  $state = (state?) => {
    if (!state) {
      return this._state;
    }

    const old = this._state;
    if (old === state) {
      return;
    }
    this._was[state] = true;
    this._state = state;
    this._log.push(state);
    this.emit('change', { from: old, to: state });
    this.emit(state);
  }
}

