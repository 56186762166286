import ENUMS from 'common/dist/enums';

exports.$controller = function($scope, $location, $user) {
  $scope.user = $user;
  $scope.showEmail = $location.search()['social'];
  $scope.locations = [...ENUMS.acceptableUserCities.filter(location => location.premiumCity === false), ...ENUMS.acceptableUserCities.filter(location => location.premiumCity === true)];
  $scope.isPremiumMember = false;

  $scope.shouldBeDisabled = function(location) {
    if (!location.premiumCity || (location.premiumCity && $scope.isPremiumMember)) {
      return false;
    }
    return true;
  };

  $scope.getRoutes = function(locationSlug) {
    if (locationSlug === 'experiences') {
      return '/experiences';
    }
    return `/search/${locationSlug.replaceAll(' ', '-')}`;
  };

  $scope.user.$waitFor('LOGGED_IN', function () {
    $scope.user.isPremiumMember()
      .then((res) => {
        $scope.isPremiumMember = res;
        if ($scope.isPremiumMember) {
          $scope.locations = ENUMS.acceptableUserCities;
        }
      });
  });
};

exports.$inject = ['$scope', '$location', '$user'];
