
import VenueSchema from 'common/dist/schemas/Venue';
import { get } from 'lodash';
import { ApiService } from 'spc/shared/api/api.service';
import { RawVenue } from 'spc/lib/database/types/venue/venue';
class CreateVenueController {
  error: string;
  doc: RawVenue;
  constructor(
    private $scope,
    private $api: ApiService,
    private unwrapError) {
      'ngInject';
    }

    public createVenue = (doc) => {
      if (!doc || !get(doc, 'data.name')) {
        this.error = 'Please enter a venue name before submitting';
        return;
      }
      if (this.error) {
        this.error = null;
      }

      this.$api.Venues.new(doc)
        .then((response) => {
          const venue = get(response, 'data.data');
          const slug = get(venue, 'slug');
          if (!slug) {
            throw new Error('No venue slug');
          }

          this.$scope.closeThisDialog({ redirect: `/user/venues/${ slug }` });
        })
        .catch(error => this.unwrapError(error));
    }

    public cancel = () => {
      this.$scope.closeThisDialog({ cancel: true });
    }

}
export default ['ngDialog', function(ngDialog) {
  return function() {
    return ngDialog.open({
      template: require('./create-venue-modal.jade'),
      className: 'ngdialog-theme-small info-modal',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: CreateVenueController
    }).closePromise;
  };
}];

