// NPM Dependencies
import moment from 'moment';
import timeHelpers from 'common/dist/time';
import cloneDeep from 'lodash/cloneDeep';
import spaceAvailability from 'common/dist/spaceAvailability';
import priceHelpers from 'common/dist/price';
import get from 'lodash/get';

// SP interfaces
import { ApiService } from 'spc/shared/api/api.service';
import { EventStates } from 'server/api/requests/models';
import { DayViewService } from './day-view.service';

// Sixplus Dependencies
const helpers = require('../../../../../shared/scheduler_helpers');

class DayViewController {
  calendars: any[];
  allAvailabilities: any;
  spaces: any[];
  date: string;
  grids: any = {};
  ticks: any[] = [];
  from: number = 700;
  to: number = 2400;
  HEADER_MINUTES_PER_TICK = 30;
  EVENT_MINUTES_PER_TICK = 15;
  venue: any;
  ui: {
    confirmedEvents?: any[];
    pendingEvents?: any[];
    ready: boolean;
  } = { ready: false };
  getCalendars: () => any;
  constructor (
    private AvailabilityFactory,
    private blockTimeModal,
    private setFbMinModal,
    private unwrapError,
    private $api: ApiService,
    private dayViewService: DayViewService,
    private $q,
    private now
  ) {
    'ngInject';
  }

  $onChanges = (data) => {
    if (!this.ui.ready) {
      return;
    }

    if (data.calendars || data.date) {
      return this.resetView();
    }
  }

  $onInit = () => {
    this.createTicks();
    return this.resetView()
      .then(() => { this.ui.ready = true; })
      .catch(error => this.unwrapError(error));
  }

  resetView = () => {
    return this.$q.all([
      this.getAllSpaceAvailabilities(),
      this.getEvents()
    ])
    .then(() => this.createGrids())
    .catch(error => this.unwrapError(error));
  }

  getEvents = () => {
    const eventStates: EventStates[] = ['confirmed', 'in play'];

    const [gettingConfirmedEvents, gettingPendingEvents] = eventStates.map((state: EventStates) => {
      const query = {
        date: this.date,
        state,
        venueId: this.venue._id.toString()
      };

      return this.$api.Requests
        .getEvents({ from: 'venue' }, query)
        .then(({ actionItems, idleItems }) => {
          return [...actionItems, ...idleItems]
            .map((event) => {
              event.totalCostBreakdownCents = priceHelpers.fullPriceBreakdownCents(event);
              return event;
            });
        })
        .catch(error => this.unwrapError(error));
    });

    return Promise.all([
      gettingConfirmedEvents.then((events: any) => this.ui.confirmedEvents = events),
      gettingPendingEvents.then((events: any) => this.ui.pendingEvents = events)
    ]);
  }

  getAllSpaceAvailabilities = () => {
    return this.AvailabilityFactory.getAvailabilityForDate(this.spaces, moment(this.date))
      .then((allAvailabilities) => {
        this.spaces = this.spaces.map(space => spaceAvailability.overridePrice(space, allAvailabilities));
        this.allAvailabilities = allAvailabilities;
      })
      .catch(error => this.unwrapError(error));
  }

  createTicks = () => {
    const start = moment.utc(helpers.numericTimeToString(this.from),
    'H:m');
    const end = moment.utc(helpers.numericTimeToString(this.to), 'H:m');
    const numTicks = Math.ceil(end.diff(start, 'minutes') / this.HEADER_MINUTES_PER_TICK);
    for (let i = 0; i < numTicks; ++i) {
      this.ticks.push(start.clone());
      start.add(this.HEADER_MINUTES_PER_TICK, 'minutes');
    }
  }

  displayTickHour = (tick) => {
    return (tick.minutes() === 0 && tick.format('ha') !== '7am') ? tick.format('h') : '';
  }

  displayTickMinutes = (tick) => {
    return (tick.minutes() === 0 && tick.format('ha') !== '7am') ? tick.format('A') : '';
  }

  getCalendar = (spaceId) => {
    return this.calendars.find(calendar => calendar.space.toString() === spaceId.toString());
  }

  openBlockTimeModal = ({ space, times, note, date, user, created }) => {
    return this.blockTimeModal({ space, times, note, date, user, created })
      .then(data => this.getCalendars());
  }

  openSetFbMinModal = ({ space, date, slot }) => {
    const momentDate = moment.utc(date);
    return this.setFbMinModal({ space, date: momentDate, slot, availabilities: this.allAvailabilities })
      .then(() => this.getAllSpaceAvailabilities())
      .catch(error => this.unwrapError(error));
  }

  createGrids = () => {
    this.grids = {};
    this.spaces.forEach((space) => {

      const calendar = this.getCalendar(space._id.toString());
      const availabilityCalendarEvents = get(calendar, `availability.${this.date}`, []);

      const timeSlots = space.data.timeSlots.filter(slot => slot.days[moment.utc(this.date, 'YYYY-MM-DD').day()]);

      const pendingEvents = this.dayViewService.getPendingEventsForSpace({ pendingEvents: this.ui.pendingEvents, space });

      const levels = this.dayViewService.createLevels({ availabilityCalendarEvents, timeSlots, pendingEvents, confirmedEvents: this.ui.confirmedEvents });

      this.grids[space._id.toString()] = this.dayViewService.createSpaceGrid({
        from: this.from,
        to: this.to,
        mintuesPerTick: this.EVENT_MINUTES_PER_TICK,
        levels
      });
    });
    return this.grids;
  }

  getSpace = (id) => {
    return this.spaces.find(space => space._id.toString() === id);
  }

  public getSpaceIndex = (id) => {
    return this.spaces.findIndex(_space => _space._id.toString() === id);
  }

  setDate = (date: any) => {
    this.date = date.format('YYYY-MM-DD');
    return this.resetView();
  }

  selectToday = () => {
    this.date = this.now().format('YYYY-MM-DD');
    return this.resetView();
  }

  selectPreviousDay = (event) => {
    this.date = moment(this.date, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD');
    return this.resetView();
  }

  selectNextDay = (event) => {
    this.date = moment(this.date, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
    return this.resetView();
  }

  isAfterToday = () => {
    return this.now().format('YYYY-MM-DD') < this.date;
  }
}

export const DayViewComponent = {
  template: require('./day-view.component.jade'),
  controller: DayViewController,
  bindings: {
    date: '@',
    calendars: '<',
    spaces: '<',
    getCalendars: '&',
    venue: '<'
  }
};
