import * as angular from 'angular';
import { each } from 'lodash';


// Unmigrated section
const services = require('./services');
import controllers from './controllers';

// Migrated section
import AdminService from './admin.service';
import { AdminRequestsService } from './admin-requests.service';
import { AdminSortService } from 'spc/admin/sort.service';

// Modules
import { MetricsDashboardModule } from './metrics/metrics.module';
import { ConciergeDashboardModule } from './concierge/concierge.dashboard.module';
import AdminVenuesModule from './venues/admin-venues.module';
import AdminProposalsModule from './proposals/admin-proposals.module';
import AdminLeadsModule from './leads/admin-leads.module';
import { AdminConversationsModule } from './conversations/admin-conversations.module';
import AdminUsersModule from './users/admin-users.module';
import { AdminCalendarModule } from 'spc/admin/calendar/admin-calendar.module';

// Components
import { EditFieldComponent } from './edit-field.component';
import { CitySelectorComponent } from './shared-components/city-selector/city-selector.component';
import { ClientHistoryComponent } from './shared-components/client-history/client-history.component';
import { ClientNotesComponent } from './shared-components/clientnotes/clientnotes.component';
import { ClientNotesModal } from './shared-components/clientnotes/clientnotes.modal';
// Directives
import { TableScroll } from './table-scroll.directive';

// Constant
import { SEARCH_CITIES } from './searchCities.constant';
import AdminSettingsModule from './admin-settings/admin-settings.module';
import { proposalVisibilityControlComponent } from './shared-components/proposal-visibility-control/proposal-visibilitty-control.component';
import { AdminCompaniesModule } from './companies/admin-companies.module';
import { AdminVendorsModule } from './vendor/admin-vendors.module';
import { AdminExperienceModule } from './experience_dashboard/admin-experience.module';
import { AdminCreateEditVendorsModule } from './create-edit-vendor/admin-create-edit-vendors.module';
import { AdminCreateEditExperienceModule } from './create-edit-experience/admin-create-edit-experience.module';
import { VirtualEventsModule } from './virtual-events/virtual-event.module';
import { AdminCreateEditAccountModule } from './create-edit-account/admin-create-edit-account.module';
import { AdminAccountsModule } from './accounts/admin-accounts.module';

const m = angular.module('admin', [
  'metrics',
  AdminVenuesModule,
  AdminProposalsModule,
  AdminConversationsModule,
  AdminLeadsModule,
  ConciergeDashboardModule,
  MetricsDashboardModule,
  AdminUsersModule,
  AdminCalendarModule,
  AdminSettingsModule,
  AdminCompaniesModule,
  AdminVendorsModule,
  AdminExperienceModule,
  AdminCreateEditVendorsModule,
  AdminCreateEditExperienceModule,
  VirtualEventsModule,
  AdminCreateEditAccountModule,
  AdminAccountsModule
])
  .service('AdminRequestsService', AdminRequestsService)
  .service('AdminSortService', AdminSortService)
  .service('AdminService', AdminService)
  .service('clientNotesModal', ClientNotesModal)
  .component('editField', EditFieldComponent)
  .component('citySelector', CitySelectorComponent)
  .component('clientHistory', ClientHistoryComponent)
  .component('clientNotes', ClientNotesComponent)
  .component('proposalVisibilityControl', proposalVisibilityControlComponent)
  .directive('tableScroll', () => new TableScroll())
  .constant('SEARCH_CITIES', SEARCH_CITIES);


// Unmigrated
each(controllers, function(controller, name) {
  m.controller(name, controller);
});

each(services, function(factory, name) {
  m.factory(name, factory);
});

module.exports = 'admin';
