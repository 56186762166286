module.exports = function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      scope.accordion = {
        alignTo: (attrs.alignTo ? $('#' + attrs.alignTo) : null)
      };

      scope.accordion.reset = function() {
        scope.accordion.section = '';
        scope.accordion.activated = []
        scope.accordion.triggered = 0;
        return scope.accordion;
      };

      scope.accordion.activate = function(section) {
        scope.accordion.section = section;
        scope.accordion.triggered++;
        scope.$emit('CHANGED_STEP', section);
      };

      scope.accordion.goBackTo = function(section) {
        scope.accordion.activate(section);
        scope.$emit('CHANGED_STEP', section);
      };

      scope.accordion.reset().activate(scope.$eval(attrs.defaultSection));
    }
  }
};
