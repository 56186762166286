// NPM Dependencies
import moment from 'moment';
import get from 'lodash/get';
import set from 'lodash/set';
import round from 'lodash/round';
import ENUMS from 'common/dist/enums';

// Sixplus Dependencies
import { ApiService } from 'spc/shared/api/api.service';

export interface OnDialogClose {
  value: {
    cancel?: boolean;
  } & string;
}

export const setFbMinModal = ['ngDialog', 'unwrapError', 'AvailabilityFactory', function (ngDialog, unwrapError, AvailabilityFactory) {
  return function ({ space, slot, date, availabilities }): Promise<OnDialogClose> {
    if (slot.data.isNA) {
      return;
    }

    return ngDialog.open({
      template: require('./set-fbmin-modal.component.jade'),
      className: 'ngdialog-theme-small',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', '$rootScope', '$api', function ($scope: any, $rootScope, $api: ApiService) {
        this.space = space;
        this.ENUMS = ENUMS;
        const timeSlots = get(availabilities[space._id.toString()], 'data.availability.timeSlots');
        this.slot = timeSlots.find(_slot => _slot._id.toString() === slot.data._id.toString());
        const roomFeeCents: any = get(this.slot, 'terms.roomFeeCents');
        this.uiModel = {
          foodBeverageMin: get(this.slot, 'terms.foodBeverageMin'),
          extraHourFee: get(this.slot, 'terms.extraHourFee'),
          roomFee: round(roomFeeCents / 100, 2)
        };

        this.setRoomFeeCents = function (dollars) {
          const cents = (parseFloat(dollars) * 100).toFixed(0);
          set(this, 'slot.terms.roomFeeCents', cents);
        };

        this.saveSlot = function () {
          return $api.Scheduler.setFees(
            this.space._id,
            date.toISOString().substr(0, 10),
            this.slot)
            .then(response => $scope.closeThisDialog(response))
            .catch(unwrapError);
        };
      }]
    }).closePromise;
  };
}];
