export const STATE_COPY_MAPPER = {
  all: 'All Messages',
  unread: 'Unread Messages',
  inquiries: 'Inquiries',
  active: 'Active Proposals',
  confirmed: 'Confirmed Proposals',
  concluded: 'Concluded Events',
  cancelled: 'Cancelled Proposals'
};

export const STATES = Object.keys(STATE_COPY_MAPPER);
