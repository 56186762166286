import ENUMS from 'common/dist/enums';

class FilterSidebarController {
  ui: { displayCitySelector: boolean; displayStateSelector: boolean } = { displayCitySelector: false, displayStateSelector: false };
  searchParams: any = {};
  states: string[] = ENUMS.bookingRequestState;

  toggleDisplay: () => any;
  searchRequests: ({ searchParams }: { searchParams: any }) => any;

  constructor() {
    'ngInject';
  }

  toggleCityDisplay = () => {
    this.ui.displayCitySelector = !this.ui.displayCitySelector;
  }

  toggleStateDisplay = () => {
    this.ui.displayStateSelector = !this.ui.displayStateSelector;
  }

  closeCitySelector = (selectedCities: string[]) => {
    this.searchParams['cities'] = selectedCities;
    this.ui.displayCitySelector = false;
  }

  closeStateSelector = (selectedStates: string[]) => {
    this.searchParams['requestStates'] = selectedStates;
    this.ui.displayStateSelector = false;
  }
}

export const FilterSidebarComponent = {
  controller: FilterSidebarController,
  template: require('./filter-sidebar.component.jade'),
  bindings: {
    searchRequests: '&',
    toggleDisplay: '&',
    searchParams: '=',
    cityHierarchyObj: '<'
  }
};
