export const NEIGHBORHOOD_SPACES = [
  {
    neighborhood: 'Midtown West',
    imgUrl:
      '//res.cloudinary.com/dbwijvnzn/image/upload/v1635302187/ResidentMidtown1_lw6lxa.jpg',
    link: '/search/New-York?filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bcity%5D=New%20York&filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bneighborhood%5D=Midtown%20West&filters%5Bguests%5D%5Bmax%5D=&viewType=VENUE&bounds%5BallowMapSearch%5D=true',
  },
  {
    neighborhood: 'Midtown East',
    imgUrl:
      '//res.cloudinary.com/dbwijvnzn/image/upload/a_exif,c_fill,g_center,q_auto,w_768,f_auto/v1606137009/OyQ-HApA_dn0szf.jpg',
    link: '/search/New-York?filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bcity%5D=New%20York&filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bneighborhood%5D=Midtown%20West&filters%5Bguests%5D%5Bmax%5D=&viewType=VENUE&bounds%5BallowMapSearch%5D=true',
  },
  {
    neighborhood: 'SoHo',
    imgUrl:
      '//res.cloudinary.com/dbwijvnzn/image/upload/a_exif,c_fill,g_center,q_auto,w_768,f_auto/v1569955940/North_Dining_Room_and_Bar_nd6yq0.jpg',
    link: '/search/New-York?filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bcity%5D=New%20York&filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bneighborhood%5D=SoHo&filters%5Bguests%5D%5Bmax%5D=&viewType=VENUE&bounds%5BallowMapSearch%5D=true',
  },
  {
    neighborhood: 'Flatiron District',
    imgUrl:
      '//res.cloudinary.com/dbwijvnzn/image/upload/a_exif,c_fill,g_center,q_auto,w_768,f_auto/v1480462986/dbxraujemvhxphhquyri.jpg',
    link: '/search/New-York?filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bcity%5D=New%20York&filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bneighborhood%5D=Flatiron%20District&filters%5Bguests%5D%5Bmax%5D=&viewType=VENUE&bounds%5BallowMapSearch%5D=true',
  },
  {
    neighborhood: 'West Village',
    imgUrl:
      '//res.cloudinary.com/dbwijvnzn/image/upload/a_exif,c_fill,g_center,q_auto,w_768,f_auto/v1621317515/Front_Dining_Room_Banquette_h0mgtd.jpg',
    link: '/search/New-York?filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bcity%5D=New%20York&filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bneighborhood%5D=West%20Village&filters%5Bguests%5D%5Bmax%5D=&viewType=VENUE&bounds%5BallowMapSearch%5D=true',
  },
  {
    neighborhood: 'East Village',
    imgUrl:
      '//res.cloudinary.com/dbwijvnzn/image/upload/a_exif,c_fill,g_center,q_auto,w_768,f_auto/v1626319812/Greenhouse_3_nfwvxr.jpg',
    link: '/search/New-York?filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bcity%5D=New%20York&filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bneighborhood%5D=East%20Village&filters%5Bguests%5D%5Bmax%5D=&viewType=VENUE&bounds%5BallowMapSearch%5D=true',
  },
  {
    neighborhood: 'Chelsea',
    imgUrl:
      '//res.cloudinary.com/dbwijvnzn/image/upload/a_exif,c_fill,g_center,q_auto,w_768,f_auto/v1622667799/2_rr2qwa.jpg',
    link: '/search/New-York?filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bcity%5D=New%20York&filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bneighborhood%5D=Chelsea&filters%5Bguests%5D%5Bmax%5D=&viewType=VENUE&bounds%5BallowMapSearch%5D=true',
  },
  {
    neighborhood: 'Lower East Side',
    imgUrl:
      '//res.cloudinary.com/dbwijvnzn/image/upload/a_exif,c_fill,g_center,q_auto,w_768,f_auto/v1542076976/170228_FlowerShop2938_dqihyp.jpg',
    link: '/search/New-York?filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bcity%5D=New%20York&filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bneighborhood%5D=Lower%20East%20Side&filters%5Bguests%5D%5Bmax%5D=&viewType=VENUE&bounds%5BallowMapSearch%5D=true',
  },
  {
    neighborhood: 'Upper West Side',
    imgUrl:
      '//res.cloudinary.com/dbwijvnzn/image/upload/a_exif,c_fill,g_center,q_auto,w_768,f_auto/v1529517037/DSR_Lincoln_10-10_8570_ntl0zx.jpg',
    link: '/search/New-York?filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bcity%5D=New%20York&filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bneighborhood%5D=Upper%20West%20Side&filters%5Bguests%5D%5Bmax%5D=&viewType=VENUE&bounds%5BallowMapSearch%5D=true',
  },
  {
    neighborhood: 'Upper East Side',
    imgUrl:
      '//res.cloudinary.com/dbwijvnzn/image/upload/a_exif,c_fill,g_center,q_auto,w_768,f_auto/v1559597601/JPS002_q6kzom.jpg',
    link: '/search/New-York?filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bcity%5D=New%20York&filters%5Bvenue%5D%5Bdata.address.neighborhood%5D%5B0%5D%5Bneighborhood%5D=Upper%20East%20Side&filters%5Bguests%5D%5Bmax%5D=&viewType=VENUE&bounds%5BallowMapSearch%5D=true',
  },
];
