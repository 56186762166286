import * as angular from 'angular';
import { TopLevelConciergeRequestsDashboardComponent } from './top-level-concierge-requests-dashboard.component';
import { ConciergeRequestsDashboardComponent } from './concierge-requests-dashboard.component';
import { LeadListComponent } from './lead-list.component';
import { AccountMembersCardViewComponent } from './account-members-card-view.component';
import { AccountMembersTableViewComponent } from './account-members-table-view.component';
import { AccountMembersTableViewFiltersComponent } from './account-members-table-view-filters.component';
import clientLeadDetailsModal from './lead-details/lead-details.modal';
import { AccountMemberLeadsDetailsService } from './account-member-leads-details.service';

export const ConciergeRequestsDashboardModule = angular.module('conciergeRequestsModule', [])
  .component('topLevelConciergeRequestsDashboard', TopLevelConciergeRequestsDashboardComponent)
  .component('conciergeRequestsDashboard', ConciergeRequestsDashboardComponent)
  .component('leadList', LeadListComponent)
  .component('accountMembersCardView', AccountMembersCardViewComponent )
  .component('accountMembersTableView', AccountMembersTableViewComponent )
  .component('accountMembersTableViewFilters', AccountMembersTableViewFiltersComponent )
  .service('clientLeadDetailsModal', clientLeadDetailsModal )
  .service('accountMemberLeadsDetailsService', AccountMemberLeadsDetailsService )
  .name;
