export class SelectionModalService {
  public scrollToElem = (elem: Element, direction: string) => {
    const offset = direction === 'down' ? 280 : 0;
    const DOMElem = $(elem);
    const pos = DOMElem.position().top + DOMElem.parent().scrollTop() - offset;
    DOMElem.parent().animate({
      scrollTop : pos
    }, 0);
  }

  public isElementInViewport = (el: any) => {
    const container = document.getElementsByClassName('concierge-form-modal-dropdown-container')[0];
    const containerRect = container.getBoundingClientRect();
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= containerRect.top &&
      rect.bottom <= containerRect.bottom
    );
  }

  public goToSelected = () => {
    const DOMElem = document.getElementsByClassName('option-selected')[0];
    const target = DOMElem.previousElementSibling && DOMElem.previousElementSibling.previousElementSibling ? DOMElem.previousElementSibling.previousElementSibling :  null;
    if (target) {
      this.scrollToElem(target, 'up');
    }
  }
}
