import { TabManager } from './tab-manager.class';

class TabulationService {
  constructor() {

  }

  createTabManager(tabs: string[], defaultTab: string): TabManager {
    const manager = new TabManager(tabs, defaultTab);
    return manager;
  }
}

export { TabulationService, TabManager }

