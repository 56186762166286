import { includes } from 'lodash';

export class BrowserDetectorService {
  browser: string;

  constructor() {
    this.browser = this.detectBrowser();
  }

  public detectBrowser(): string {
    if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
      // This is internet explorer 9 or 11
      return 'IE';
    }

    if (/Edge\/\d./i.test(navigator.userAgent)) {
      // This is Microsoft Edge
      return 'Edge';
    }

    return 'Modern Browser';
  }

  public isMicrosoft() {
    return includes(['IE', 'Edge'], this.browser);
  }
}
