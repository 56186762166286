class SendMessageController {
  role: 'client' | 'venue';
  sendMessage: (param: { message: string }) => any;
  message: string;
  submitted: boolean;

  constructor(private unwrapError) {
    'ngInject';
    this.submitted = false;
  }

  public submitMessage = (message: string): ng.IPromise<void> => {
    if (this.submitted) {
      return;
    }

    this.submitted = true;
    return this.sendMessage({ message })
      .then(() => {
        this.submitted = false;
        this.message = '';
       })
      .catch((error) => {
        this.submitted = false;
        this.handleHttpError(error);
      });
  }

  private handleHttpError = (error: Error) => {
    this.unwrapError(error);
  }

  public isClient = () => this.role === 'client';
}

export const SendMessageComponent = {
  template: require('./send-message.component.jade'),
  controller: SendMessageController,
  bindings: {
    sendMessage: '&',
    role: '@'
  }
};
