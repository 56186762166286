import { get } from 'lodash';
import { convertURL } from '../../services/convertUrl';

export default ['ngDialog', function(ngDialog) {
  return function(request) {
    return ngDialog.open({
      template: require('./see-payment-attachments.jade'),
      className: 'ngdialog-theme-plain',
      overlay: false,
      plain: true,
      controller: ['$scope', '$cloudinary', function($scope, $cloudinary) {
        $scope.fetchAndConvertUrl = $cloudinary.fetchAndConvertURL;
        $scope.srcSet = $cloudinary.srcSet;

        init();
        ///// Initialization function
        function init() {
          $scope.attachments = get(request, 'data.payment.attachments');
          $scope.convertUrl = convertURL;
        }
      }]
    }).closePromise;
  };
}];
