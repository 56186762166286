
export default ['ngDialog', function createDuplicateListModal(ngDialog) {
  return function (list) {
    return ngDialog.open({
      template: '<create-duplicate-list list="$ctrl.list" close="$ctrl.close({ list })"></create-duplicate-list>',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal create-list-modal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.list = list;
        this.close = ({ list }) => {
          $scope.closeThisDialog({ list });
        };
      }]
    }).closePromise;
  };
}];
