// NPM Dependencies
import VanillaToasts from 'vanillatoasts';

export class ToastService {
  TIMEOUT_MILLISECONDS = 5000;

    /**
   * Toast for indicating good news / success. Takes an optional callback that is executed when the toast is clicked.
   *
   * @param {String} title (required)
   * @param {String} text (required)
   * @param {Function} cb (optional), executes when toast is clicked
   */
  public goodNews(title: string, text?: string, cb?) {
    const self = this;

    VanillaToasts.create({
      title,
      text,
      type: 'success',
      timeout: self.TIMEOUT_MILLISECONDS,
      cb
    });
  }

  public warning(title: string, text: string, cb?) {
    const self = this;

    VanillaToasts.create({
      title,
      text,
      type: 'warning',
      timeout: self.TIMEOUT_MILLISECONDS,
      cb
    });
  }
  /**
   * Toast for indicating bad news / error. Takes an optional callback that is executed when the toast is clicked.
   *
   * @param {String} title (required)
   * @param {String} text (required)
   * @param {Function} cb (optional), executes when toast is clicked
   */
  public badNews(title: string, text?: string, cb?) {
    const self = this;
    VanillaToasts.create({
      title,
      text,
      type: 'error',
      timeout: self.TIMEOUT_MILLISECONDS,
      cb
    });
  }
}
