import { every, get } from 'lodash';
/**
 * Directive that allows users to edit a custom contact.
 * NOTE : This directive uses 2 way dataflow and is prone to bugs relating to data mutation
 * Tread carefully because 2 way dataflow sucks
 *
 * @param {String} type
 * @param {Contact} contact
 * @param {Boolean} isRequest
 */
export default function() {
  return {
    template: require('./add-custom-contact.jade'),
    scope: {
      type: '@',
      contact: '=',
      isRequest: '='
    },
    controller: ['$scope', '$api', function($scope, $api) {
      $scope.cancel = cancel;
      $scope.getEmail = getEmail;
      $scope.validatePhone = validatePhone;
      $scope.verifyEmail = verifyEmail;
      init();

      function init() {
        $scope.errors = {};
      }
      // Public Functions

      function cancel() {
        $scope.$emit('END_ADD_CUSTOM_CONTACT');
      }

      function getEmail() {
        return $scope.emailValidation;
      }

      /**
       * Verifies email that user inputs for custom contact, spitting out
       * potential suggestions if user made a typo
       *
       * @api public
       * @param {String} email
       * @sets `$scope.email`
       */
      function verifyEmail(email) {
        $api.Auth.verifyEmail(email)
          .then((res) => {
            $scope.emailValidation = _getEmailValidation(res);
            $scope.$emit('VALIDATE_EMAIL', { emailValidation: $scope.emailValidation });
          })
          .catch(error => $scope.emailValidation = _getEmailValidation(error));
      }

      /**
       * Validate a phone number if it exists
       *
       * @return {Object} if exists
       */
      function validatePhone() {
        $scope.errors.phone = $scope.contact.validatePathsSync(['phone']);
      }

      // Private Functions

      /**
       * returns an object based on response from verifying email
       *
       * @api private
       * @param {Data} data
       * @return {Object}
       */
      function _getEmailValidation(data) {
        return {
          address: get(data, 'data.address'),
          isValid: get(data, 'data.valid'),
        };
      }
    }]
  };
}
