import { RawList } from 'spc/lib/database/types/list';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { DAccount } from 'spc/lib/database/types/accounts';

class AddListCollaboratorsController {
  list: RawList;
  clients: RawBaseUser[];
  user: RawBaseUser;
  searchValue: string;
  filteredClients: RawBaseUser[];
  connections: RawBaseUser[];
  loading: boolean;
  close: (revised: DAccount) => void;
  // update: ({ updatedClients:  }: { updatedLead: RawLead, updatedProposals: RawBookingRequest[] }) => void;

  constructor(private $api, private unwrapError, private $scope, private $user) {
    'ngInject';
    this.loading = true;
  }

  $onInit() {
    if (!this.user) {
      this.user = this.$user.$;
    }
    this.getConnectedUsers(this.user);
  }

  getConnectedUsers(user) {
     return this.$api.BaseUsers.getConnections(user._id.toString())
      .then((res) => {
        this.clients = res.users;
        this.setConnectedUsers(res.users);
        this.connections = this.filteredClients;
        this.loading = false;
      })
      .catch(error => this.unwrapError(error));
  }

  setConnectedUsers(users) {
    this.filteredClients = users.filter(u => u._id.toString() !== this.user._id.toString());
  }

  isOnList({ user }) {
    return !user._id || this.list.users.some((u) => {
      return (u.client as RawBaseUser)._id.toString() === user._id.toString();
    });
  }

  inviteUser = ({ user }) => {
    this.$api.Lists.addUser({ listId: this.list._id.toString(), user, sendEmail: true })
      .then((res) => {
        if (res.addedUser._id) {
          this.list.users = [...this.list.users, ...res.addedUser];
          this.close(res);
        }
      })
      .catch(error => this.unwrapError(error));
  }
}

export const AddListCollaboratorsComponent = {
  template: require('./add-list-collaborators.component.jade'),
  controller: AddListCollaboratorsController,
  bindings: {
    close: '&',
    list: '<',
    user: '<',
    update: '&'
  }
};
