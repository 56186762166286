import { TimeslotViewService } from './timeslot-view.service';

class TimeSlotSidebarController {
  space: any;
  venue: any;
  closeSidebar: () => any;
  changesSaved: () => any;
  constructor(private timeslotViewService: TimeslotViewService) {
    'ngInject';
  }

  initializeTimeSlot = () => {
    return {
      isCustom: false,
      days: [false, false, false, false, false, false, false],
      terms: {
        foodBeverageMin: null,
        extraHourFee: null,
        roomFeeCents: null
      },
    };
  }

  addTimeslot = () => {
    const timeSlot = this.initializeTimeSlot();
    this.space.data.timeSlots.push(timeSlot);
    this.timeslotViewService.selectTimeslot(timeSlot);
    this.timeslotViewService.editingNewTimeSlot = true;
  }
}

export const TimeslotSidebarComponent = {
  controller: TimeSlotSidebarController,
  template: require('./timeslot-sidebar.component.jade'),
  bindings: {
    space: '<',
    venue: '<',
    closeSidebar: '&',
    changesSaved: '&'
  }
};
