export const userTransferModal = ['ngDialog', (ngDialog) => {
  return ({ users, company }) => {
    return ngDialog.open({
      template: `<user-transfer users="$ctrl.users" company="$ctrl.company" close="$ctrl.close"></user-transfer>`,
      plain: true,
      className: 'ngdialog-theme-plain',
      overlay: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function($scope) {
        this.users = users;
        this.company = company;
        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];
