import * as angular from 'angular';

import cancellationPolicyMessage from './directives/cancellationPolicyMessage';
import httpToggle from './directives/httpToggle';
import readMore from './directives/readMore';
import { googlePlace } from './directives/googlePlace';
import { SpPardotService } from 'spc/shared/sp-pardot/sp-pardot.service';

const m = angular.module('directives', []);
module.exports = m;

m.directive('stickyFooter', ['$rootScope', '$injector', function ($rootScope, $injector) {
  return {
    template: require('./sticky-footer.jade'),
    controller: ['spPardot', '$scope', function (spPardot: SpPardotService, $scope) {
      this.email = '';

      function validEmail(emailStr) {
          const emailPattern = /[^@\s]+@[^@.\s]*\.[^@\s]+/i;
          return emailStr.match(emailPattern);
      }

      $scope.submit = function(email) {
        if (!validEmail(email)) {
          this.invalidEmail = true;
          return;
        } else {
          this.invalidEmail = false;
          const user = {
            email: email.toLowerCase().trim(),
            event: 'newsletter signup'
          };
          spPardot.trackEmailInput(user);
          this.emailSubmitted = true;
          this.email = '';
        }
      };
    }],
    scope: true,
    link: function (scope, element, attrs) {
      $rootScope.$on('HIDE_FOOTER', function () {
        element.hide();
      });
      $rootScope.$on('SHOW_FOOTER', function () {
        element.removeClass('spinner-hidden').show();
      });
    },
  };
}]);

m.directive('errorView', function () {
  return {
    restrict: 'E',
    template: require('./error-view.jade')
  };
});

m.directive('objectfitPolyfill', ['$rootScope', function ($rootScope) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      setTimeout(function () {
        if (!window.Modernizr.objectfit) {
          const img = element.find('img');
          const imgUrl = img.prop('src');
          if (imgUrl) {
            element.
              css('backgroundImage', 'url(' + imgUrl + ')').
              addClass('compat-object-fit');
          }
        }
      }, 0);
    }
  };
}]);

m.directive('viewWrapper', ['$rootScope', 'viewWrapperStateService', '$timeout', function ($rootScope, viewWrapperStateService, $timeout) {
  return {
    restrict: 'E',
    scope: false,
    template: require('./view-wrapper.jade'),
    link: function (scope, element, attrs) {
      const state = {
        loading: true,
        disabled: false,
        hasRouteChangeError: false
      };

      scope.image = attrs.image;
      scope.state = state;
      const $spinner = element.find('#spinner');
      const $footer = element.find('sticky-footer');

      const hideFooter = function () {
        $footer.hide().addClass('spinner-hidden');
      };

      const showFooter = function () {
        $footer.show().removeClass('spinner-hidden');
      };

      const hideView = function () {
        $spinner.show().removeClass('spinner-hidden');
        element.find('div[ng-view="true"]').hide().
          addClass('spinner-hidden');
        hideFooter();
      };

      hideView();
      viewWrapperStateService.setState(state);
      // Sometimes we want to disable the transition for exactly one
      // location change, specifically when reloadOnSearch is false for a view.
      $rootScope.disableViewTransition = function () {
        viewWrapperStateService.disable();
      };

      $rootScope.$on('$locationChangeStart', function () {
        hideFooter();
        $timeout(() => {
          if (state.loading || state.disabled) {
            return;
          }
          state.loading = true;
          hideView();
        });
      });

      $rootScope.$on('$locationChangeSuccess', function () {
        $timeout(() => {
          viewWrapperStateService.enable();
        });
      });

      // Oops there was a resolve error
      $rootScope.$on('$routeChangeError', function (_scope, current, prev, error) {
        $timeout(() => {
          viewWrapperStateService.stopLoading();
          viewWrapperStateService.hasRouteChangeError();
          $spinner.hide().addClass('spinner-hidden');
        });
      });

      $rootScope.$on('$viewReady', function (event, footerEvent) {
        $timeout(() => {
          if (!state.loading) {
            return;
          }
          viewWrapperStateService.stopLoading();
          element.find('div[ng-view="true"]').removeClass('spinner-hidden').
          fadeIn({ duration: 500 });
          $spinner.hide().addClass('spinner-hidden');
          if (footerEvent === 'HIDE_FOOTER') {
            hideFooter();
          } else if (footerEvent === 'SHOW_FOOTER') {
            showFooter();
          }
        });
      });
    }
  };
}]);

m.directive('loadingScreen', function () {
  return {
    restrict: 'E',
    scope: {
      stateEmitter: '=',
    },
    transclude: true,
    template: '<div class="loading-spinner">' +
    '<div class="spinner loading-spinner-image"></div>' +
    '<div class="loading-spinner-container"' +
    '     style="display: none"><ng-transclude></ng-transclude></div>' +
    '</div>',
    link: function (scope, element, attrs) {
      scope.image = attrs.image;
      const hideView = function () {
        element.find('.loading-spinner-image').
          show().
          removeClass('spinner-hidden');
        element.find('.loading-spinner-container').hide().
          addClass('spinner-hidden');
      };

      const showView = function () {
        element.find('.loading-spinner-image').
          hide().
          addClass('spinner-hidden');
        element.find('.loading-spinner-container').fadeIn({ duration: 500 }).
          removeClass('spinner-hidden');
      };

      hideView();

      const loadedFn = function () {
        showView();
      };

      const loadingFn = function () {
        hideView();
      };

      scope.stateEmitter.on(attrs.loadedState, loadedFn);
      scope.stateEmitter.on(attrs.loadingState, loadingFn);

      scope.$on('$destroy', function () {
        scope.stateEmitter._emitter.removeListener(attrs.loadedState, loadedFn);
        scope.stateEmitter._emitter.removeListener(attrs.loadingState,
          loadingFn);
      });
    }
  };
});

m.directive('simpleLoadingScreen', function () {
  return {
    restrict: 'E',
    scope: false,
    transclude: true,
    template: '<div class="loading-spinner-small">' +
    '<div class="spinner loading-spinner-image"></div>' +
    '<div class="loading-spinner-container"' +
    '     "><ng-transclude></ng-transclude></div>' +
    '</div>'
  };
});

/**
 * Triggers jQuery's `.slideDown()` whenever a the event specified
 * in the `ngSlide` attribute fires.
 */

m.directive('slideDownOnEvent', function () {
  return {
    scope: false,
    restrict: 'A',
    link: function (scope: ng.IScope, element: ng.IRootElementService, attrs: ng.IAttributes) {
      attrs.slideDownOnEvent.split(',').forEach(function (ev) {
        if (!String.prototype.trim) {
          String.prototype.trim = function () {
            return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
          };
        }
        ev = ev.trim();
        scope.$on(ev, function (event, params) {
          element.addClass('banner-display');
          scope.eventMessage = params ? params.message : null;
        });
      });
    }
  };
});

/**
 * Triggers jQuery's `.slideUp()` after a specified amount of time after
 * a `.slideDown()` event
 */

m.directive('slideUpAfterEvent', function () {
  return {
    scope: false,
    restrict: 'A',
    link: function (scope: ng.IScope, element: ng.IRootElementService, attrs: ng.IAttributes) {
      const events = attrs.slideDownOnEvent.split(',');
      const timeout = attrs.slideUpAfterEvent;
      events.forEach(function (event) {
        scope.$on(event.trim(), function (ev, params) {
          setTimeout(function () {
            element.removeClass('banner-display');
          }, timeout);
        });
      });
    }
  };
});

/**
 * Triggers jQuery's `.slideUp()` whenever a the event specified
 * in the `ngSlide` attribute fires.
 */

m.directive('slideUpOnEvent', function () {
  return {
    scope: false,
    restrict: 'A',
    link: function (scope: ng.IScope, element: ng.IRootElementService, attrs: ng.IAttributes) {
      attrs.slideUpOnEvent.split(',').forEach(function (ev) {
        scope.$on(ev, function () {
          element.slideUp().addClass('test-hidden');
        });
      });
    }
  };
});

m.directive('userMenu', function ($window) {
  return {
    template: require('./user-menu.jade'),
    controller: ['$scope', '$user', 'ENUMS', function ($scope, $user, ENUMS) {
      $scope.user = $user;
      $scope.ENUMS = ENUMS;
      $scope.isPremiumMember = false;
      $scope.ui = {
        freeCities: {
          displayCities: true,
          defaultCities: ENUMS.sidebarCitiesList.defaultFreeCities,
          additionalCities: ENUMS.sidebarCitiesList.additionalFreeCities,
          displayAdditionalCities: false
        },
        proCities: {
          displayCities: true,
          defaultCities: ENUMS.sidebarCitiesList.defaultProCities,
          additionalCities: ENUMS.sidebarCitiesList.additionalProCities,
          displayAdditionalCities: false
        }
      };
    }],
    link: function (scope: any, element, attrs) {
      scope.$on('TOGGLE_NAV', function (ev, params) {
        if (params && params.hide) {
          element.hide();
        } else {
          element.show();
        }
      });

      scope.shouldBeDisabled = function(location) {
        if (!location.premiumCity || (location.premiumCity && scope.isPremiumMember)) {
          return false;
        }
        return true;
      };

      scope.toggleAdditionalCities = function(cityCategory) {
        scope.ui[cityCategory].displayAdditionalCities = !scope.ui[cityCategory].displayAdditionalCities;
      };

      scope.toggleCityDropdown = function(cityCategory) {
        scope.ui[cityCategory].displayCities = !scope.ui[cityCategory].displayCities;
      };

      scope.accountText = function() {
        return {text: scope.user.$.accountTier ? `Team (${scope.user.$.accountTier})` : 'Upgrade',
          subtext: scope.user.$.accountTier && scope.user.$.accountTier !== 'Free' ? 'Manage ' + scope.user.$.accountTier + ' account' : 'Upgrade to Pro'
          };
      };

      scope.getRoutes = function(locationSlug) {
        let path;
        if (locationSlug === 'Virtual') {
          path = '/experiences';
        } else {
          path = `/search/${locationSlug.replaceAll(' ', '-')}`;
        }
        return location.pathname === path ? '' : path;
      };

      scope.user.$waitFor('LOGGED_IN', function () {
        scope.user.isPremiumMember()
          .then((res) => {
            scope.isPremiumMember = res;
            if (scope.isPremiumMember) {
              scope.locations = scope.ENUMS.acceptableUserCities;
            }
          });
        setArrowAnimation();
      });

      scope.user.$waitFor('NOT_LOGGED_IN', function () {
        setArrowAnimation();
      });

      function setArrowAnimation() {
        const scrollArrow = element.find('#scroll-arrow');
        scrollArrow.click(function() {
          const pos = scrollArrow.offset().top;
          // scrollArrow.addClass('rotated');
          element.find('nav.drawer ul').animate({ scrollTop: pos }, '1000');
          return false;
        });
      }

      const lastScrollTop = null;
      const nav = element.find('nav');
      const burger = element.find('.burger');
      const restOfPage = angular.element('.content');

      const closeMenu = function () {
        nav.removeClass('slide-in').addClass('slide-out');
        burger.removeClass('slide-in').addClass('slide-out');
        restOfPage.css({
          opacity: 1
        });
      };

      const openMenu = function () {
        nav.removeClass('slide-out').addClass('slide-in');
        burger.removeClass('slide-out').addClass('slide-in');
        restOfPage.css({
          opacity: 0.5
        });
        nav.find('a').click(function (event) {
          closeMenu();
        });
      };

      restOfPage.click(function (event) {
        if (nav.hasClass('slide-in')) {
          closeMenu();
          event.preventDefault();
        } else {
          return true;
        }
      });

      burger.click(function () {
        if (nav.hasClass('slide-in')) {
          closeMenu();
        } else {
          openMenu();
        }
      });

      jQuery($window).on('scroll', function () {
        const navbar = element.find('.navbar');
        if (jQuery($window).scrollTop() > 0) {
          navbar.removeClass('at-top').
            addClass('not-at-top');
        } else {
          navbar.addClass('at-top');
        }
      });
    }
  };
});

m.directive('iframeUrl', function () {
  return function (scope, element, attrs) {
    scope.$watch(attrs.iframeUrl, function (v: any) {
      $(element).prop('src', v);
    });
  };
});

/**
 * One-way data binding. Good for cases when you want to only
 * change the underlying value on blur.
 *
 * @property {String} ngModel the variable name to use on the scope
 */

m.directive('ngIn', function () {
  return function (scope, element, attrs) {
    const variableName = attrs.ngModel;
    scope.$watch(attrs.ngIn, function (value) {
      scope[variableName] = value;
    });
  };
});

m.directive('httpToggle', httpToggle);

m.directive('accordion', require('./directives/accordion'));
m.directive('accordionCollapsed', require('./directives/accordionCollapsed'));
m.directive('accordionOpened', require('./directives/accordionOpened'));
m.directive('errorTooltip', require('./directives/errorTooltip'));
m.directive('googlePlace', googlePlace);
m.directive('httpButton', require('./directives/httpButton'));
m.directive('login', require('./directives/login'));
m.directive('moneyFormat', require('./directives/moneyFormat'));
m.directive('multiSelect', require('./directives/multiSelect'));
m.directive('notificationSlider', require('./directives/notificationSlider'));
m.directive('pikaday', require('./directives/pikaday'));
m.directive('promiseButton', require('./directives/promiseButton'));
m.directive('registration', require('./directives/registration'));
m.directive('trunkJs', require('./directives/trunkJs'));
m.directive('unsubscribeNewsletter', require('./directives/unsubscribeNewsletter'));
m.directive('userScheduler', require('./directives/UserScheduler'));
m.directive('secondaryNav', require('./directives/secondaryNav'));
m.directive('resetPassword', require('./directives/resetPassword'));
m.directive('userAccount', require('./directives/userAccount'));
m.directive('tertiaryNav', require('./directives/tertiaryNav'));
m.directive('changeLoginInfo', require('./directives/changeLoginInfo'));
m.directive('cancellationPolicy', require('./directives/cancellationPolicy'));
m.directive('bookingProcess', require('./directives/bookingProcess'));
m.directive('cancellationPolicyMessage', cancellationPolicyMessage);
m.directive('readMore', readMore);
m.directive('textTooltip', require('./directives/textTooltip'));

m.directive('bootstrapTooltip', function () {
  return function (scope, element, attrs) {
    let enabled = false;
    if (!($(element) as any).tooltip) {
      return;
    }
    scope.$watch(attrs.bootstrapTooltip, function (v) {
      if (!!v) {
        enabled = true;
        ($(element) as any).tooltip('enable');
      } else {
        ($(element) as any).tooltip('disable');
        enabled = false;
      }
    });
  };
});

