import { ListMapService } from './list-map.service';

class ListMapController {
  constructor(private listMapService: ListMapService, private ENUMS, private $element) {
    'ngInject';
  }

  $postLink() {
    const listMapConfig = {
      scrollwheel: false,
      mapTypeControl: false,
      streetViewControl: false,
      panControl: false,
      zoomControl: true,
    };

    this.listMapService.init($(this.$element.find('.list-map-content'))[0], listMapConfig);
  }
}

export const ListMapComponent = {
  template: require('./list-map.component.jade'),
  controller: ListMapController
};
