import { EXPERIENCE_ADDON_PRICE_TYPE } from '../../../../../database/constants/Experience';
import { ApiService } from '../../../shared/api/api.service';
import { get, every, filter, cloneDeep, isEqual, debounce } from 'lodash';
import { Addon, RawVendor, DVendor } from 'spc/lib/database/types/virtual-events';
import { VIRTUAL_EVENTS_CHANGE } from '../../../../../database/constants/virtualEventsChange';
const newAddOn: Addon = {
  name: '',
  description: '',
  price: {
    priceType: 'per person',
    priceValue: null,
  },
};
class AddOnsController {
  currentAddOnIndex: number;
  isFormDisplayed: boolean = false;
  addOn: Addon = cloneDeep(newAddOn);
  addOns: Addon[] = [];
  validate: any;
  vendor: DVendor;
  errors: {
    [key: string]: {
      message: string
    }
  } | null = {};
  priceTypes: string[] = EXPERIENCE_ADDON_PRICE_TYPE;
  isAddOnEdit: boolean = false;
  object: any;
  type: string;
  updateReadyStatus: any;


  constructor(
    private unwrapError,
    private $api: ApiService,
    private $scope
    ) {
    'ngInject';
  }
  $onInit = () => {
    if (this.object && this.object.addOns) {
      this.addOns = cloneDeep(this.object.addOns);
    }
    this.$scope.$on(VIRTUAL_EVENTS_CHANGE.VIRTUAL_ADDONS_CHANGE, this.cloneOriginalItemToItem);
  }

  cloneOriginalItemToItem = () => {
    this.addOns = cloneDeep(this.object.addOns);
  }

  validateAddOn = (path) => {
    const currentAddOnPath = `addOns.${this.currentAddOnIndex}.${path}`;
    if (this.type === 'vendor') {
      this.$api.Admin.Vendors.validate({ vendor: { addOns: this.addOns } })
        .then((response) => {
          const errors = get(response, 'errors.vendor');
          if (errors) {
            const pathErrors = get(errors, currentAddOnPath);
            this.errors[currentAddOnPath] = pathErrors ? pathErrors.message : null;
          } else {
            this.errors = {};
          }
        })
        .catch((error) => {
          this.unwrapError(error);
        });
    }
    else if (this.type === 'experience') {
      const experience = cloneDeep(this.object);
      experience.addOns = this.addOns;
      this.$api.Admin.Experience.validate({ experience , vendorId : this.vendor._id })
        .then((response) => {
          const errors = get(response, 'errors.experience');
          if (errors) {
            const pathErrors = get(errors, currentAddOnPath);
            this.errors[currentAddOnPath] = pathErrors ? pathErrors.message : null;
          } else {
            this.errors = {};
          }
        })
        .catch((error) => {
          this.unwrapError(error);
        });
    }
  }

  checkForErrors = debounce(this.validateAddOn, 300);

  createNewAddOn = () => {
    this.displayForm(true);
    const length = this.addOns.push(this.addOn);
    this.currentAddOnIndex = length - 1;
  }

  resetItem = () => {
    this.addOn = cloneDeep(newAddOn);
  }

  displayForm(value) {
    this.isFormDisplayed = value;
  }

  cancel() {
    if (!this.isAddOnEdit) {
      this.addOns = filter(
        this.addOns,
        (_, index) => index !== this.currentAddOnIndex
      );
    }
    else {
      this.addOns[this.currentAddOnIndex] = cloneDeep(this.object.addOns[this.currentAddOnIndex]);
    }
    this.resetItem();
    this.isAddOnEdit = false;
    this.displayForm(false);
  }

  selectPriceType = (selectedValue) => {
    this.addOn.price.priceType = selectedValue;
  }

  isFieldInvalid = (path) => {
    const fieldPath = `addOns.${this.currentAddOnIndex}.${path}`;
    return this.errors[fieldPath] ? true : false;
  }

  getErrorMessage = (path) => {
    const fieldPath = `addOns.${this.currentAddOnIndex}.${path}`;
    return this.errors[fieldPath];
  }

  submit = () => {
    if (this.type === 'vendor') {
      this.updateVendor();
    }
    else if (this.type === 'experience') {
      this.updateExperience();
    }
    this.updateUi();
  }

  updateUi = () => {
    this.object.addOns = cloneDeep(this.addOns);
    this.displayForm(false);
    this.resetItem();
    this.isAddOnEdit = false;
    if (this.type === 'experience') {
      this.updateReadyStatus();
    }
  }

  updateVendor = () => {
    const updates = { addOns: this.addOns };
    this.$api.Admin.Vendors.updateVendorById({
      updates,
      vendorId: this.object['_id'],
    })
      .then((res) => {
          this.addOns = res.vendor.addOns;
          this.updateUi();
      })
      .catch((err) => {
        this.unwrapError(err);
      });
  }

  updateExperience = () => {
    const updates = { addOns : this.addOns };
    this.$api.Admin.Experience.updateExperienceById({
      updates,
      experienceId : this.object['id']
    })
      .then((response) => {
        this.addOns = response.experience.addOns;
        this.object.status = response.experience.status;
      })
      .catch((err) => {
        this.unwrapError(err);
      });
  }


  checkIfFormDataInvalid = () => {
    const errorsPath = Object.keys(this.errors);
    let result = null;
    errorsPath.forEach((path) => {
      result = this.errors[path] || result;
    });
    return result;
  }

  hasAllTheRequiredFields = () => {
    const requiredFields = [
      get(this.addOn, 'name'),
      get(this.addOn, 'description')
    ];
    return every(requiredFields);
  }

  edit = (index) => {
    this.addOn = this.addOns[index];
    this.displayForm(true);
    this.currentAddOnIndex = index;
    this.isAddOnEdit = true;
  }

  delete = (addOnIndex) => {
    const addOnToDelete = this.addOns[addOnIndex];
    if (isEqual(this.addOn, addOnToDelete)) {
      this.addOn = cloneDeep(newAddOn);
      this.displayForm(false);
    }
    this.addOns = this.addOns.filter((addOn, index) => index !== addOnIndex);
    if (this.type === 'experience') {
      this.updateExperience();
      this.updateUi();
    }
    else if (this.type === 'vendor') {
      this.updateVendor();
    }
  }
}

export const VirtualEventsAddOnsComponent = {
  controller: AddOnsController,
  template: require('./virtual-events-addOns.component.jade'),
  bindings: {
    validate: '<',
    vendor: '<',
    object : '<',
    type : '<',
    updateReadyStatus: '<'
  },
};
