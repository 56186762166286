import { get } from 'lodash';
import { getUniqueList } from './helpers';

interface CityState {
  city: string;
  state: string;
}

interface CityHierarchyInterface {
  [key: string]: {
    neighborhoods: string[],
    state: string
  };
}

interface SearchCityInterface {
  [key: string]: string[];
}

class CitySelectorController {
  distinctCities: CityState[];
  additionalCities: CityState[];
  selectedCities: string[];
  closeCitySelector: ({ selectedCities }: { selectedCities: string[] }) => any;
  SEARCH_CITIES_LIST: CityHierarchyInterface | SearchCityInterface;
  cityHierarchyObj: CityHierarchyInterface;

  constructor(private SEARCH_CITIES) {
    'ngInject';
  }

  $onInit = () => {
    this.SEARCH_CITIES_LIST = this.cityHierarchyObj || this.SEARCH_CITIES;
    this.sortCitySubCitiesList();
    this.selectedCities = this.selectedCities || [];
    this.additionalCities = this.getAdditionalCityList();
  }

  handleClick = (city: string, $event: any, multiSelect: boolean, parentCity: any) => {
    $event.stopPropagation();
    const topLocation = get(this.cityHierarchyObj, city);
    if (multiSelect) {
      if (!topLocation) {
        return;
      }

      const sublocations = [];
      for (const loc in topLocation) {
        sublocations.push(`${loc}, ${get(topLocation[loc], 'state')}`);
      }
      const isParentCitySelected = !!this.selectedCities.find(selectedCity => sublocations.includes(selectedCity));

      if (isParentCitySelected) {
        this.selectedCities = this.selectedCities.filter(selectedCity => !sublocations.includes(selectedCity));
      }
      else {
        this.selectedCities = getUniqueList(this.selectedCities.concat(sublocations));
      }
    }
    else {
      const cityState = get(this.cityHierarchyObj, [parentCity, city, 'state']);
      this.selectedCities = this.isCitySelected(city, parentCity) ? this.unselectCity(city, cityState) : this.selectedCities.concat(cityState ? `${city}, ${cityState}` : city);
    }
  }

  unselectCity = (city, state) => {
    return this.selectedCities.filter(_city => state ? _city !== `${city}, ${state}` : _city !== city);
  }

  isCitySelected = (city, parentCity) => {
    const cityState = get(this.cityHierarchyObj, [parentCity, city, 'state']);
    return this.selectedCities.includes(cityState ? `${city}, ${cityState}` : city);
  }

  closeDropdown = () => {
    this.closeCitySelector({ selectedCities: this.selectedCities });
  }

  getAdditionalCityList = () => {
    const parentCities = this.SEARCH_CITIES_LIST;
    if (this.distinctCities) {
      return this.distinctCities.filter((cityState) => {
        for (const key in parentCities) {
          if (parentCities[key].includes(cityState.city)) {
            return false;
          }
        }
        return true;
      });
    }
  }

  sortCitySubCitiesList = () => {
    if (this.cityHierarchyObj) {
      const sortedKeys = Object.keys(this.cityHierarchyObj).sort();
      const sortedCityData = {};
      sortedKeys.forEach((key) => {
        sortedCityData[key] = Object.keys(this.cityHierarchyObj[key]).sort();
      });
      sortedCityData['All Other Cities'] = ['Other'];
      this.SEARCH_CITIES_LIST = sortedCityData;
    }
  }
}

export const CitySelectorComponent = {
  controller: CitySelectorController,
  template: require('./city-selector.component.jade'),
  bindings: {
    distinctCities: '<?',
    closeCitySelector: '&',
    selectedCities: '<',
    cityHierarchyObj: '<?'
  }
};
