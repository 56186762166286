import { ApiService } from 'spc/shared/api/api.service';
import { DAccount } from 'spc/lib/database/types/accounts';
import { DBaseUser } from 'spc/lib/database/types/base-user';
import { DUser } from 'spc/lib/database/types/user';
import { DBookingRequest } from 'spc/lib/database/types/booking-request';
import AnalyticsService from '../shared/analytics/analytics.service';
import Vimeo from '@vimeo/player';

class PremiumPageController {
    ready: () => void;
    account: DAccount;
    bookings: DBookingRequest[];
    members: DBaseUser[];
    user: DUser;
    hasAccount: boolean;
    isLoggedIn: boolean;
    premiumRegistrationRequest: boolean;
    isComponentReady: boolean;
    premiumAccountRequested: boolean;
    requestModal: boolean = false;
    isUser: boolean;
    pro: boolean;
    premium: boolean;
    isDowngraded: boolean;
    isReqForTrailPending: boolean = false;

    constructor(
        private subscriptionCardServices,
        private unwrapError
    ) {
        'ngInject';
    }

    $onInit = async () => {
        // this.playVideoWithJWPlayer();
        this.playVideoWithVimeoPlayer();
        try {
                ({ isDowngraded: this.isDowngraded,
                user: this.user,
                isLoggedIn: this.isLoggedIn,
                pro: this.pro,
                premium: this.premium,
                premiumAccountRequested: this.premiumAccountRequested} = await this.subscriptionCardServices.setUpUserData());
                this.ready();
                this.isComponentReady = true;
        }
        catch (error) {
            this.unwrapError(error);
        }
    }

    public playVideoWithVimeoPlayer = () => {
        const options = {
            id: 802662558,
            width: 720,
            loop: true,
            controls: false,
            autoplay: true,
            muted: true,
            title: false
        };
        setTimeout(() => {
            new Vimeo('vimeoPlayerUpgradeDiv', options);
        }, 500);
    }

    closeModal = () => {
        this.requestModal = this.subscriptionCardServices.closeModal();
    }

    async requestAccess() {
        this.isReqForTrailPending = true;
        try {
            this.requestModal = await this.subscriptionCardServices.requestAccess();
            this.isReqForTrailPending = false;
        }
        catch (error) {
            this.isReqForTrailPending = false;
            this.unwrapError(error);
        }
    }
}
export const premiumPageComponent = {
    template: require('./premium-page.component.jade'),
    controller: PremiumPageController,
    bindings: {
        ready: '<',
    },
};
