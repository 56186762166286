export const recoOrderModal = ['ngDialog', function(ngDialog) {
  return function (recos, leadId) {
    return ngDialog.open({
      template: `<reco-order recos="$ctrl.recos" lead-id="$ctrl.leadId" close="$ctrl.close"> </reco-order>`,
      plain: true,
      className: 'ngdialog-theme-plain',
      overlay: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function($scope) {
        this.recos = recos;
        this.leadId = leadId;
        this.close = (response) => {
          $scope.closeThisDialog(response);
        };
      }]
    }).closePromise;
  };
}];
