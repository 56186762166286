import { TimeslotViewService } from './timeslot-view.service';
import { integerToCivilianTime } from 'common/dist/time';

const indexDayMapper = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat'
};

class SideBarTimeslotCardController {
  timeSlot: any;
  constructor(private timeslotViewService: TimeslotViewService) {
    'ngInject';
  }

  displayValidDays = () => {
    const days = [];
    this.timeSlot.days.forEach((day, i) => {
      if (day) {
        days.push(indexDayMapper[i]);
      }
    });
    return days.join(' | ');
  }

  displayEndTime = (endTime) => {
    return this.timeslotViewService.displayEndTime(endTime);
  }
}

export const SidebarTimeslotCardComponent = {
  controller: SideBarTimeslotCardController,
  bindings: {
    timeSlot: '<',
  },
  template: require('./sidebar-timeslot-card.component.jade')
};
