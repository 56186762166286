import { ApiService } from 'spc/shared/api/api.service';
import { ObjectID } from 'mongodb';
import { get, last, some, values, keys } from 'lodash';
import { DBookingRequest } from '../../../../database/types/booking-request';
import { QuillInsert } from 'spc/lib/server/api/conversations/models';
import { TransmittedAttachment } from 'spc/lib/database/types/attachments';

// constant
import { RECONCILIATION_REFUND_MESSAGE } from '../../constants/refundConfirmaionMessage';
import { VENUE_TEMPLATES  } from '../../../../database/constants/venueTemplate';

const DECLINED_HOST_REASONS = [
  'The space is not available for that date and time',
  'Other'
];

const DECLINED_HOST_BLOCK_CALENDAR_REASONS = [
  'The space is not available for that date and time'
];

const CANCELLED_REQUEST_GUEST_REASONS = [
  'Client told us they no longer needed the space', 'No response from client'
];

class SubmitRequestController {
  msgData: any;
  selectedKey;
  templates;
  options;
  accept: ({ message, resolutionTransaction, attachments }: {
    message: {
      quillFormat: QuillInsert[],
      message: string
    },
    resolutionTransaction?: any,
    attachments: TransmittedAttachment[],
  }) => any;
  decline: ({
    terminationReason,
    message,
    requestTerminationState,
    blockCalendar,
    attachments
  }: {
    terminationReason: string,
    attachments: TransmittedAttachment[]
    message: {
      quillFormat: QuillInsert[],
      message: string,
    },
    requestTerminationState: string,
    blockCalendar: boolean
  }) => any;
  request: DBookingRequest;
  isContractUploaded: Boolean = false;
  costBreakdownCents: {
    balanceOutstanding: number
  };
  placeholderText: string;
  userInput: {
    terminationReason?: string;
    messageToClient?: string;
    customTerminationReason?: string;
  } = {};
  location: string;
  ui: {
    attachments?: TransmittedAttachment[];
    allowTermination: boolean;
    intentToTerminate: boolean;
    terminationReasons: string[];
    allowAcceptance: boolean;
    displayedError?: string;
    terminating?: boolean;
    accepting?: boolean;
    contractUpload: {
    url: string;
    title: string;
  }[];
  } = {
    allowTermination: false,
    intentToTerminate: false,
    terminationReasons: null,
    allowAcceptance: false,
    contractUpload: null,
    attachments: []
  };
  selectedCard: any;
  errors: {
    [key: string]: {
      message: string
    }
  } | null = {};
  messageToSend: {quillFormat: QuillInsert[], message: string} = { quillFormat: [], message: '' };

  refund: { isManual: boolean } = { isManual: true };
  constructor(
    private $api: ApiService,
    private ENUMS,
    private unwrapError,
    private $user,
    private $counteroffer,
    private now,
    private $clickOk,
    private replaceCardModal,
    private $scope: ng.IScope,
    private messageAttachmentsModal) {
    'ngInject';
  }

  $onChanges = () => {
    this.ui.allowTermination = this.allowTermination();
    this.ui.allowAcceptance = this.allowAcceptance();
    this.setDefaultResolutionValues();
    this.ui.terminationReasons = []
      .concat(CANCELLED_REQUEST_GUEST_REASONS)
      .concat(DECLINED_HOST_REASONS);

    this.getCardOnFile();
  }

  $onInit = () => {
    this.options = [];

    if (this.request.venue.bookingType && this.request.venue.bookingType === 'Defer_to_Venue') {
      this.templates = VENUE_TEMPLATES['VENUE_OFFLINE_SUBMIT_REQ'];
    }
    else {
      this.templates = VENUE_TEMPLATES['STANDARD_VENUE_SUBMIT_REQ'];
    }
    this.templates.map(template => this.options.push(template.title));
    if (this.ENUMS.bookingRequestState.pendingRequestState.includes(this.request.state)) {
      this.placeholderText = `- If you're accepting the request, please include a brief message such as 'We hope to host you!' or 'Let us know if you have questions about the menu!'\n\n- If you're declining the request, please include a brief note explaining why.`;
    } else if (this.ENUMS.bookingRequestState.outstandingStates.includes(this.request.state)) {
      this.placeholderText = `Please enter your message here.`;
    }
    const toolbarOptions = ['bold', 'italic', 'underline', 'strike'];
  }

  hasCardOnFile = () => {
    return this.selectedCard || get(this.request, 'data.payment.token') || get(this.request, 'data.payment.schedule', []).find(payment => payment.state === 'COMPLETED' && payment.response);
  }

  getCardOnFile = () => {
    if (!this.hasCardOnFile() || !this.$user.isAdmin()) {
      return;
    }
    this.$api.Payment.initialize({
      getMethods: true,
      conversation: this.request.conversation.toString()
    }).then(({ data }: { data: any }) => {
      const { paymentMethods } = data;
      this.selectedCard = paymentMethods && paymentMethods.find(method => method.token === this.request.data.payment.token);
      if (!this.selectedCard) {
        const completedPayment = this.request.data.payment.schedule.find(payment => payment.response && payment.state === 'COMPLETED');
        this.selectedCard = completedPayment ? completedPayment.cardDetails : null;
      }
    })
    .catch(error => this.unwrapError(error));
  }

  openReplaceCardModal = () => {
    return this.replaceCardModal(this.request)
      .then((data) => {
        if (get(data, 'value.method')) {
          this.selectedCard = get(data, 'value.method');
        }
      });
  }

  needsReconciliation = (): boolean => {
    const hasOutstandingBalance = this.costBreakdownCents.balanceOutstanding !== 0;
    const isConcluded = this.request.state === 'CONCLUDED';
    if (this.costBreakdownCents.balanceOutstanding < 0) {
      const schedule = get(this.request, 'data.payment.schedule', []);
      this.refund.isManual = some(schedule, s => s.state === 'COMPLETED' && s.isManual);
    }
    return hasOutstandingBalance && isConcluded;
  }

  needsRefund = (): boolean => {
    return this.costBreakdownCents.balanceOutstanding < 0;
  }

  needsPayment = (): boolean => {
    return this.costBreakdownCents.balanceOutstanding > 0;
  }

  hideMessageField = (): boolean => {
   const isOutsideProposal = get(this.request, 'isOutsideProposal');
   const isConcluded = this.request.state === 'CONCLUDED';
   const isReconciled = this.request.state === 'RECONCILED';
   return isOutsideProposal || isConcluded || isReconciled;
  }

  getSubmitText = (): string => {
    if (this.request.state === 'CONCLUDED' && this.needsPayment()) {
      return 'Reconcile event and Submit Charges';
    } else if (this.request.state === 'CONCLUDED' && this.needsRefund()) {
      return 'Reconcile event and Process Refund';
    } if (this.request.state === 'CONCLUDED') {
      return 'Reconcile event with no extra charges';
    }
    return 'Send changes';
  }

  allowTermination = () => {
    return this.ENUMS.bookingRequestState.outstandingStates.includes(this.request.state);
  }

  allowAcceptance = () => {
    const lastCounteroffer: any = last(this.request.counteroffers);
    if (lastCounteroffer && ['INIT', 'READY'].includes(lastCounteroffer.state)) {
      const acceptableStates = ['COUNTEROFFER', 'POST_DEPOSIT_ALTERATION'];
      return acceptableStates.includes(this.request.state);
    } else {
      const acceptableStates = ['COUNTEROFFER', 'REQUEST', 'PROPOSAL'];
      if (this.$user.isAdmin()) {
        acceptableStates.push('CONCLUDED');
      }
      return acceptableStates.includes(this.request.state);
    }
  }

  determineTerminatedRequestState = () => {
    if (DECLINED_HOST_REASONS.includes(this.userInput.terminationReason)) {
      return 'DECLINED_HOST';
    } else if (CANCELLED_REQUEST_GUEST_REASONS.includes(this.userInput.terminationReason)) {
      return 'CANCELLED_REQUEST_GUEST';
    }
  }

  determineBlockCalendar = () => {
    return DECLINED_HOST_BLOCK_CALENDAR_REASONS.includes(this.userInput.terminationReason);
  }

  setDefaultResolutionValues = () => {
    if (this.needsReconciliation() && this.needsRefund()) {
      this.request.set('admin.resolutionTransaction.amountCents', this.costBreakdownCents.balanceOutstanding * -1);
      this.request.set('admin.resolutionTransaction.paymentType', 'reconciliation refund');
      this.request.set('admin.resolutionTransaction.method', 'Credit Card');
      this.request.set('admin.resolutionTransaction.date', this.now().format('YYYY-MM-DD'));
    }
  }

  validateRequest = () => {
    const request = this.request;
    return this.$api.Admin.Requests.validate({ request })
      .then((res) => {
        if (Object.keys(res['errors']).length) {
          const messages = this.createMessage(res['errors']);
          const showCancel = false;
          const btnText = 'OK';
          return this.$clickOk(messages, showCancel, btnText);
        } else {
          this.openReonciliationConfirmationModal();
        }
      });
  }

  createMessage = (errors) => {
    const messageValues = values(errors.request);
    const messageKeys = keys(errors.request);
    return messageKeys.map((value, idx) => ({ key: value, message: messageValues[idx].message }));
  }

  openReonciliationConfirmationModal = () => {
    if (!this.needsRefund()) {
      this.acceptRequest();
      return;
    }
    const refundType = this.refund.isManual ? 'Manual' : 'Not Manual';
    const message = RECONCILIATION_REFUND_MESSAGE[refundType];
    const showCancel = true;
    const btnText = 'Confirm';

    return this.$clickOk(message, showCancel, btnText)
      .then((response) => {
        if (get(response, 'value.cancel')) {
          return;
        }
        this.acceptRequest();
      });
  }

  acceptRequest = () => {
    if (this.request.venue.bookingType === 'Defer_to_Venue' && this.request.state !== 'CONCLUDED') {
      if (this.isContractUploaded) {
        if (this.ui.accepting) {
          return;
        }
        const errors = this.$counteroffer.getDisabledDetails(this.request);
        if (errors) {
          return this.unwrapError(errors);
        }

        this.ui.accepting = true;
        return this.accept({
          message: this.messageToSend,
          resolutionTransaction: this.request.admin.resolutionTransaction,
          attachments: this.ui.attachments,
        })
          .then((response) => {
            this.ui.allowAcceptance = false;
            this.ui.allowTermination = false;
            this.ui.intentToTerminate = false;
            this.ui.accepting = false;
            this.userInput.terminationReason = null;
            return response;
          })
          .catch((error) => {
            if (get(error, 'data.error.conflict')) {
              this.ui.displayedError = `There is an existing event in the availability calendar for this date and time.`;
            }
            this.ui.accepting = false;
            this.unwrapError(error);
          });
      }
    }
    else {
      if (this.ui.accepting) {
        return;
      }
      const errors = this.$counteroffer.getDisabledDetails(this.request);
      if (errors) {
        return this.unwrapError(errors);
      }

      this.ui.accepting = true;
      return this.accept({
        message: this.messageToSend,
        resolutionTransaction: this.request.admin.resolutionTransaction,
        attachments: this.ui.attachments,
      })
        .then((response) => {
          this.ui.allowAcceptance = false;
          this.ui.allowTermination = false;
          this.ui.intentToTerminate = false;
          this.ui.accepting = false;
          this.userInput.terminationReason = null;
          return response;
        })
        .catch((error) => {
          if (get(error, 'data.error.conflict')) {
            this.ui.displayedError = `There is an existing event in the availability calendar for this date and time.`;
          }
          this.ui.accepting = false;
          this.unwrapError(error);
        });
    }
  }

  getDeclineText = () => {
    const DECLINE_STATES = ['INCOMPLETE', 'PROPOSAL', 'REQUEST', 'COUNTEROFFER'];
    if (DECLINE_STATES.includes(this.request.state) || this.request.venue.bookingType === 'Defer_to_Venue') {
      return 'Decline Request';
    } else {
      return 'Cancel Proposal';
    }
  }

  isCreatorHost = () => {
    const venueAuthUsers = get(this.request, 'venue.authorizedUsers').map(authUser => authUser._id);
    const creator = get(this.request, 'creator.userId');
    const roles = get(creator, 'roles', []);

    return roles.includes('Host') && venueAuthUsers.includes(creator._id);
  }

  terminateRequest = () => {

    if (!this.canTerminate()) {
      return;
    }

    this.ui.terminating = true;

    return this.decline({
      terminationReason: this.userInput.customTerminationReason || this.userInput.terminationReason,
      message: this.messageToSend,
      attachments: this.ui.attachments,
      requestTerminationState: this.determineTerminatedRequestState(),
      blockCalendar: this.determineBlockCalendar()
    })
      .then((response) => {
        this.ui.allowAcceptance = false;
        this.ui.allowTermination = false;
        this.ui.intentToTerminate = false;
        this.userInput.terminationReason = null;
        this.ui.terminating = false;
        return response;
      })
      .catch((error) => {
        this.ui.terminating = false;
        this.unwrapError(error);
      });
  }

  cancelTermination = () => {
    this.ui.intentToTerminate = false;
    this.userInput.terminationReason = null;
  }

  openMessageAttachmentsModal = () => {
    return this.messageAttachmentsModal({
      conversation: this.request.conversation,
      attachments: this.ui.attachments
    })
      .then((data) => {
        this.ui.attachments = get(data, 'value.attachments', this.ui.attachments);
      })
      .catch(error => this.unwrapError(error));
  }

  openContractAttachmentsModal = () => {
    return this.messageAttachmentsModal({
      request: this.request,
      attachments: [],
    })
    .then((data) => {
      if (!get(data, 'value.attachments.length')) {
        return;
      }
      this.request.contractAttachments = get(data, 'value.attachments');
      this.submitContractAttachment();
    })
    .catch(error => this.unwrapError(error));
  }

  submitContractAttachment = () => {
    this.$api.Requests.updateDirectlyAsHost(this.request)
      .then(() => {
        this.isContractUploaded = true;
        return this.acceptRequest();
      })
      .then(() => window.location.reload());
  }

  afterUploadingofflineContract = ( data ) => {
    this.request.contractAttachments = get(data, 'value.attachments', this.request.contractAttachments);
    this.$api.Requests.save({ request: this.request });
    this.isContractUploaded = true;
    this.acceptRequest()
      .then(() => window.location.reload());
  }

  handleEditorTextChanges = () => {
    this.$scope.$applyAsync();
  }

  canTerminate = () => {
    if (!this.userInput.terminationReason) {
      return;
    }
    // this code is commented for fiture refrance and making cancel proposal message field optional.
    // if (!this.messageToSend.message) {
    //   return;
    // }

    if (this.userInput.terminationReason === 'Other' && !this.userInput.customTerminationReason) {
      return;
    }

    if (this.ui.terminating) {
      return;
    }

    return true;
  }
}

export const SubmitRequestComponent = {
  controller: SubmitRequestController,
  template: require('./submit-request.component.jade'),
  bindings: {
    request: '<',
    conversation: '<',
    costBreakdownCents: '<',
    accept: '&',
    decline: '&',
    location: '@',
    msgData: '<'
  }
};
