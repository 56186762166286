export class ViewWrapperStateService {
  state: any;

  public setState(state) {
    this.state = state;
  }

  public enable = () => this.state.disabled = false;
  public disable = () => this.state.disabled = true;
  public startLoading = () => this.state.loading = true;
  public stopLoading = () => this.state.loading = false;
  public getState = () => this.state;
  public hasRouteChangeError = () => this.state.hasRouteChangeError = true;

}
