import { RawList } from 'spc/lib/database/types/list';

class EditListController {
  close: (params?: { updatedList: RawList }) => void;
  edits: { name: string };
  canEdit: boolean;
  venueId: string;
  userId: string;
  list: RawList;

  constructor(private $api, private $user, private unwrapError, private $scope) {
    'ngInject';
    this.canEdit = true;
    this.edits = { name: '' };
  }

  $onInit = () => {
    this.edits = { name: this.list.name };
  }

  editGroup = () => {
    if (!this.edits.name) {
      this.canEdit = false;
      return;
    }

    if (this.edits.name === this.list.name) {
      this.close();
    }

    let userId;
    if (this.userId && this.$user.isAdmin()) {
      userId = this.userId;
    }

    return this.$api.Lists.editList({ listId: this.list._id.toString(), edits: this.edits })
      .then((res) => {
        this.close({ updatedList: res.list });
      })
      .catch(error => this.unwrapError(error));
  }

}

export const EditListComponent = {
  template: require('./edit-list.component.jade'),
  controller: EditListController,
  bindings: {
    list: '<',
    close: '&'
  }
};
