import { ApiService } from 'spc/shared/api/api.service';
import isFinite from 'lodash/isFinite';
import { MenuDisplayService } from '../../../recos/menu-display.service';

class AllMenusController {
  venue: any;
  menus: { data: { name: string, type: string } }[];
  request: any;
  selected2: any;

  flickityOptionsPhotos: {
    initialIndex: 0,
    cellAlign: 'left',
    freeScroll: true,
    contain: true,
    pageDots: false,
    groupCells: true
  };
  flickityOptionsNav: any;

  constructor(private $api: ApiService, private unwrapError, private $cloudinary,
    private photoDialog, private FlickityService, private $timeout, private menuDisplayService: MenuDisplayService) {
    'ngInject';
  }

  $onInit = () => {
    this.$api.Menus.getVenueMenus(this.venue)
      .then((res) => {
        this.menuDisplayService.addVenueToList({ _id: this.venue._id.toString(), menus: res.menus });
        this.menuDisplayService.updateStartTime({ startTime: this.request.data.time });
        this.menus = this.menuDisplayService.FILTERED_VENUE_MENU_MAP[this.venue._id.toString()];
      })
      .catch(error => this.unwrapError(error));

    this.flickityOptionsNav = {
      initialIndex: 0,
      cellAlign: 'left',
      freeScroll: true,
      contain: true,
      pageDots: false,
      groupCells: true
    };
  }

  openPhotoDialog = (photos, initialPhoto): Promise<any> => {
    return this.photoDialog({ photos: photos, initialPhoto: initialPhoto });
  }

  isSelectedMenu = (menu) => {
    if (isFinite(this.selected2)) {
      const selectedMenu = this.menus[this.selected2];
      return selectedMenu._id.toString() === menu._id.toString();
    } else if (!this.request.data.menu || this.request.data.menu.none) {
      return this.menus[0]._id.toString() === menu._id.toString();
    }
    return this.request.data.menu._id.toString() === menu._id.toString();
  }

  public selectMenu = (index: number) => {
    this.selected2 = index;

    const menu = this.menus[this.selected2];

    if (!menu) {
      return;
    }

    return this
      .$timeout(() => {
        const ids = [`carousel-menu-${menu._id.toString()}`, 'flick-carousel'];
        // Initialize our Flickity instance
        ids.forEach((id) => {
          return this.FlickityService
            .get(id)
            .then(() => this.FlickityService.resize(id))
            .catch(error => null);
        });
      })
      .catch(error => this.unwrapError(error));
  }
}

export const allMenusComponent = {
  controller: AllMenusController,
  template: require('./all-menus.component.jade'),
  bindings: {
    venue: '<',
    request: '<'
  }
};
