export const contractAttachmentsModal = ['ngDialog', (ngDialog) => {
  return function({ attachments, request }: { attachments, request?: any}) {
    return ngDialog.open({
      template: `<contract-attachments
        attachments="$ctrl.attachments"
        request="$ctrl.request"
        close="$ctrl.close"
        user="$ctrl.$user"
      ></contract-attachments>`,
      className: 'ngdialog-theme-plain',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function($scope) {
        this.request = request;
        this.attachments = attachments;
        this.close = (_attachments) => {
          $scope.closeThisDialog({ attachments: _attachments });
        };
      }]
    }).closePromise;
  };
}];
