const cspVideoPlayer = ['ngDialog', function(ngDialog) {
  return function ({ video, venueName, venueAddress, venueSlug }) {
    return ngDialog.open({
      template: `<csp-video-player video="$ctrl.video" venue-slug="$ctrl.venueSlug" venue-name="$ctrl.venueName" venue-address="$ctrl.venueAddress" close="$ctrl.close"></csp-video-player>`,
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small videoPlayerNgdialog',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.video = video;
        this.venueName = venueName;
        this.venueAddress = venueAddress;
        this.venueSlug = venueSlug;
        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];

export default cspVideoPlayer;
