const Spaces = require('./spaces');
import { queryHelpers } from '../../utils/query';
import { GetWinelistResponse, ValidateVenueResponseBody  } from 'server/api/venues/models';
import { DVenue } from 'spc/lib/database/types/venue/venue';
import{ DDrink } from 'spc/lib/database/types/drink';
import { DSpace } from 'spc/lib/database/types/venue/space';
import { DMenu } from 'spc/lib/database/types/menu';

const m: any = function(API_ROOT, $http) {

  const ret: any = {
    Spaces: Spaces(API_ROOT, $http),
  };

  ret.new = function(data) {
    return $http.post(API_ROOT + '/venues', data);
  };

  ret.get = function(slug, searchParams) {
    const query = queryHelpers.makeQueryString(searchParams);

    const url = `${ API_ROOT }/venues/${ slug ? slug : '' }${ query ? query : '' }`;
    return $http.get(url);
  };

  ret.getAuthorizedUsers = (venueId) => {
    const url = `${API_ROOT}/venues/${venueId}/authorized-users`;
    return $http.get(url)
      .then(res => res.data);
  };

  ret.save = function(doc, status) {
    return $http.put(API_ROOT + '/venues/' + doc.slug + (status ? '?status=' + status : ''), doc);
  };

  ret.validate = function({ venue, menus, drinks, spaces }: { venue?: DVenue, menus?: DMenu[], drinks?: DDrink[], spaces?: DSpace[]}): ng.IPromise<ValidateVenueResponseBody> {
    const url = `${API_ROOT}/venues/validate`;
    return $http.put(url, { venue, menus, drinks, spaces })
      .then(response => response.data);
  };

  ret.message = function({ message, venue, user, conversationId }) {
    const url = `${ API_ROOT }/venues/${ venue._id }/message`;
    return $http.post(url, { message, user, conversationId });
  };

  ret.getCalendars = ({ venueId }: { venueId: string }) => {
    const url = `${API_ROOT}/venues/availability-calendar/${venueId}`;
    return $http.get(url)
      .then(response => response.data);
  };

  ret.getWinelists = (slug: string): ng.IPromise<GetWinelistResponse> => {
    const url = `${ API_ROOT }/venues/${ slug }/winelists`;
    return $http.get(url)
      .then(response => response.data);
  };

  ret.hosts = {
    getAll: function(searchParams) {
      const query = queryHelpers.makeQueryString(searchParams);

      const url = `${ API_ROOT }/venues/hosts/all${ query ? query : '' }`;
      return $http.get(url);
    },
    getPublished: function(searchParams) {
      const query = queryHelpers.makeQueryString(searchParams);
      const url = `${ API_ROOT }/venues/hosts/published${ query ? query : '' }`;
      return $http.get(url);
    },
    getPublishedAndPrivate: function(searchParams) {
      const query = queryHelpers.makeQueryString(searchParams);
      const url = `${ API_ROOT }/venues/hosts/publishedAndPrivate${ query ? query : '' }`;
      return $http.get(url);
    },
    putVisibility: function(slug, isVisible) {
      return $http.put(`${ API_ROOT }/venues/hosts/${ slug }/visibility`, { isVisible: isVisible });
    }
  };

  return ret;
};

module.exports = m;
