module.exports = function(API_ROOT, $http) {
  const ret: any = {
  };

  ret.new = function(doc) {
    return $http.post(API_ROOT + '/drinks', doc);
  };

  ret.get = function(id, params) {
    return $http.get(API_ROOT + '/drinks/' + id, { params: params });
  };

  ret.getVenueDrinks = (venue) => {
    const venueId = venue._id ? venue._id.toString() : venue.toString();
    return $http.get(`${API_ROOT}/drinks/venue/${venueId}`)
      .then(res => res.data);
  };

  ret.validate = (drink) => {
    return $http.put(`${API_ROOT}/drinks/${drink._id.toString()}/validate`, { drink })
      .then(response => response.data);
  };

  ret.save = function(doc) {
    return $http.put(API_ROOT + '/drinks/' + doc._id, doc);
  };

  ret.setVisibility = function(doc, isVisible) {
    return ret.save({ _id: doc._id, isVisible: isVisible });
  };

  ret.delete = function(doc) {
    return $http.delete(`${ API_ROOT }/drinks/${ doc._id }`);
  };

  return ret;
};
