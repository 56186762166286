import { fullPriceBreakdownDollars, fullPriceBreakdownCents, refundAmount, extraChargeAmount } from 'common/dist/price';
import userHelpers from 'common/dist/virtuals/User';
import authorizedClientsHelpers from 'common/dist/virtuals/AuthorizedClient';
import { find, filter, get, set, without, cloneDeep, isFinite, round, every, merge } from 'lodash';
import moment from 'moment';

import {
  cardFeePercentage as _cardFeePercentage,
  hostCommissionPercentage as _hostCommissionPercentage,
  nthCompletedPayment as _nthCompletedPayment,
  nthCompletedIntermediatePayment as  _nthCompletedIntermediatePayment, applyDelta,
  getRefund as _getRefund, nthCompletedOveragePayment as _nthCompletedOveragePayment } from 'common/dist/virtuals/BookingRequest';

import { AdminEventsService } from 'spc/admin/proposals/admin-events.service';
import { RawBookingRequest } from 'spc/lib/database/types/booking-request';
import { UpdatePathOp } from 'spc/lib/shared/interfaces/admin/events/update-request.model';

// SixPlus Deps
import { ApiService } from 'spc/shared/api/api.service';
import { ToastService } from 'spc/shared/toast.service';

// constant
import { REFUND_TYPES } from '../../constants/ENUMS/refundTypes';
import { REFUND_TITLES } from 'spc/receipts/refundTitles';
import { RawPayment } from 'spc/lib/database/types/payment';
import { REFUND_CONFIRMATION_MESSAGE } from '../../constants/refundConfirmaionMessage';

class EventDataSidebarController {
  authorizedUsers: any;
  request: any;
  client: {
    user: {
      profile: {
        city: String;
        name: {
          first: string,
          last: string;
        },
        email: string
      }
    }
  };
  dollarsBreakdown: any;
  centsBreakdown: any;
  noteOpen: boolean;
  originalPayoutData: { amountDollars: number, date: string, note: string, method: string };
  VENUE_PAYMENTS_PATH = 'admin.venuePayments';
  VENUE_PAYOUT_PATH = 'admin.payout';
  RESOLUTION_TRANSACTION_PATH = 'admin.resolutionTransaction';
  refundForm: {
    date?: string;
    method?: string;
    amountDollars?: number;
    type: string;
    refundedFrom: string;
    amountSettled?: boolean;
    intermediatePaymentPosition?: number;
    overagePaymentPosition?: number;
    isManual?: boolean;
    emails: {
      client: boolean,
      venue: boolean
    }
  } = {
    date: '',
    method: '',
    amountDollars: null,
    type: '',
    refundedFrom: '',
    amountSettled: false,
    intermediatePaymentPosition: null,
    overagePaymentPosition: null,
    isManual: false,
    emails: {
      client: false,
      venue: false
    }
  };

  saveRequest: ({ request, paths }: { request: RawBookingRequest, paths: UpdatePathOp[] | UpdatePathOp }) => Promise<RawBookingRequest>;
  ui: { tab: 'payments' | 'general', editingPayment?: boolean, payoutChanged?: boolean, displayCancelForm: boolean, makeRefund?: boolean } = { tab: 'general', displayCancelForm: false };
  payment: {
    amountDollars: number,
    method: string,
    note: string,
    date: string
  };
  states: string[];
  assignees: any[] = [];
  refundTypes: string[];
  overagePayment: RawPayment;
  overargePaymentIndex;
  intermediatePayments: RawPayment[];

  constructor(
    private $api: ApiService,
    private ENUMS,
    private unwrapError,
    private RequestArrayFactory,
    private adminEventsService: AdminEventsService,
    private $clickOk,
    private toast) {
    'ngInject';
    this.states = without(ENUMS.bookingRequestState, ...ENUMS.bookingRequestState.cancelledPostDepositStates);
  }

  $onChanges = (data) => {
    if (data.request) {
      this.handleRequestUpdate();
    }
  }

  $onInit = () => {
    this.adminEventsService.getAssignees()
      .then(assignees => this.assignees = assignees)
      .catch(error => this.unwrapError(error));
    this.handleRequestUpdate();
    this.refundTypes = REFUND_TYPES;
  }

  handleRequestUpdate = () => {
    const last: any = this.request.counteroffers[this.request.counteroffers.length - 1];

    if (last) {
      applyDelta(this.request, last.delta);
    }

    this.getAuthorizedUsers();
    this.request.fbAddOns = this.RequestArrayFactory.getFbAddOns(this.request);
    this.request.nonFbAddOns = this.RequestArrayFactory.getNonFbAddOns(this.request);
    this.request.nonTaxableAddOns = this.RequestArrayFactory.getNonTaxableAddOns(this.request);
    this.request.visibleDrinks = this.RequestArrayFactory.getVisibleDrinks(this.request);
    this.dollarsBreakdown = fullPriceBreakdownDollars(this.request);
    this.centsBreakdown = fullPriceBreakdownCents(this.request);
    this.originalPayoutData = cloneDeep(this.request.admin.payout);
    this.intermediatePayments = this.setIntermediatePayments();
    this.client = authorizedClientsHelpers.primaryEventContact(this.request).user;
  }

  displayResolutionTransaction = () => {
    const resolutionTransaction = this.request.admin.resolutionTransaction;
    return resolutionTransaction && resolutionTransaction.paymentType;
  }

  needsRefund = () => {
    return this.centsBreakdown.balanceOutstanding < 0;
  }

  openNote = () => {
    return this.noteOpen = !this.noteOpen;
  }

  getSpaceName = (request) => {
    return request.venue.data.spaces[request.data.space].data.name;
  }

  shouldDisplayVenueUsers = (request) => {
    return this.authorizedUsers && this.authorizedUsers.length;
  }

  getClientName = () => {
    return `${userHelpers.fullName(this.client)}`;
  }

  getClientEmail = () => {
    return get(this.client, 'profile.email');
  }

  getClientCompany = (request) => {
    return `${get(this.client, 'company.name', '')}`;
  }

  getClientPhone = (request) => {
    return `${get(this.client, 'profile.phone', '')}`;
  }

  hostCommissionPercentage(request) {
    return _hostCommissionPercentage(request);
  }

  cardFeePercentage(request) {
    return _cardFeePercentage(request);
  }

  nthCompletedPayment(request, num, slug) {
    if (slug === 'intermediate') {
      return _nthCompletedIntermediatePayment(request, num);
    } else if (slug === 'overage') {
      return _nthCompletedOveragePayment(request, num);
    } else {
      return _nthCompletedPayment(request, num);
    }
  }

  displayTotalPayout = () => {
    if (this.ENUMS.bookingRequestState.cancelledPostDepositStates.includes(this.request.state)) {
      if (this.ui.payoutChanged) {
        return 'TBD';
      }
      const adminPayout = get(this.request, 'admin.payout.amountDollars');
      if (!isFinite(adminPayout)) {
        return 'TBD';
      } else if (isFinite(adminPayout)) {
        return this.centsBreakdown.payout;
      }
    }

    if (this.request.state === 'CLOSED') {
      return this.centsBreakdown.payout;
    }
    return this.centsBreakdown.total - this.centsBreakdown.bookingFee - this.centsBreakdown.cardFee;
  }

  calculateFinalPayout = () => {
    const payout = this.centsBreakdown.total - this.centsBreakdown.bookingFee - this.centsBreakdown.cardFee;
    set(this.request, 'admin.payout.amountDollars', this.adminEventsService.calculateVenuePayout(this.request, payout));
  }

  addPayment = () => {
    this.ui.editingPayment = true;
    this.payment = {
      amountDollars: null,
      date: null,
      method: null,
      note: null
    };
  }

  allowSavePayment = () => {
    return this.payment.amountDollars && this.payment.date && this.payment.method;
  }

  savePayment = () => {

    if (!this.allowSavePayment()) {
      return;
    }
    this.ui.editingPayment = false;
    this.request.admin.venuePayments = this.request.admin.venuePayments || [];
    this.request.admin.venuePayments.push(this.payment);
    this.payment = null;

    const paths = {
      setPath: this.VENUE_PAYMENTS_PATH,
      value: this.request.admin.venuePayments
    };

    return this.saveRequest({ request: this.request, paths })
      .then((request) => {
        this.request = request;
        this.handleRequestUpdate();
      })
      .catch(error => this.unwrapError(error));
  }

  saveNote = () => {
    return this.saveRequest({ request: this.request, paths: { setPath: 'admin.note', value: this.request.admin.note } })
      .then((request) => {
        this.request = request;
        this.handleRequestUpdate();
      })
      .catch(error => this.unwrapError(error));
  }

  openRefundForm = ({ slug, num }: { slug: 'deposit' | 'balance' | 'intermediate' | 'overage', num?: number }) => {
    const { paidAmountCents, refundedAmountCents } = this.getChargedAndRefundedAmount({ slug: slug.toLowerCase(), num });
    const paymentPosition = ['intermediate', 'overage'].includes(slug.toLowerCase()) ? num : (slug.toLowerCase() === 'deposit' ? 1 : 2);
    const payment = this.nthCompletedPayment(this.request, paymentPosition, slug.toLowerCase());
    this.refundForm.isManual = slug.toLowerCase() === 'intermediate' ? true : payment.isManual;
    this.refundForm.type = refundedAmountCents !== 0 ? 'Partial' : '';
    if (slug.toLowerCase() === 'intermediate') {
      this.refundForm.intermediatePaymentPosition = num;
    }
    if (slug.toLowerCase() === 'overage') {
      this.refundForm.overagePaymentPosition = num;
    }

    const paidAmountDollars = Number(round((paidAmountCents / 100), 2).toFixed(2));
    const refundedAmountDollars = Number(round((refundedAmountCents / 100), 2).toFixed(2));
    this.refundForm.amountDollars = Number(round((paidAmountDollars - refundedAmountDollars), 2).toFixed(2));
    this.refundForm.refundedFrom = slug.toLowerCase();
    this.trackPaymentStatus();
    this.ui.makeRefund = true;
  }

  setTransactionDate = (date) => {
    this.refundForm.date = moment(date).format('YYYY-MM-DD');
  }

  setIntermediatePayments = () => {
    const schedule = get(this.request, 'data.payment.schedule', []);
    return schedule.filter(s => s.state === 'COMPLETED' && s.title === 'INTERMEDIATE');
  }

  trackPaymentStatus = () => {
    const paymentType = this.refundForm.refundedFrom;
    const refundType = this.refundForm.type;
    const isManual = this.refundForm.isManual;

    if (refundType === 'Full' || isManual) {
      return;
    }
    return this.$api.Admin.Payment.getPaymentStatus({ requestId: this.request._id, paymentType })
      .then((res) => {
        if (res.status === 'settled') {
          this.refundForm.amountSettled = true;
        }
      }).catch((error) => {
        this.unwrapError(error);
      });
  }

  enteredIncorrectRefundAmount ({ slug, num }: { slug: 'deposit' | 'balance' | 'intermediate' | 'overage', num?: number }) {
    if (!slug) {
      return;
    }
    const amount = this.refundForm.amountDollars;

    if (!amount) {
      return true;
    }

    const amountCents = Number(round(amount * 100).toFixed(2));
    const { paidAmountCents, refundedAmountCents } = this.getChargedAndRefundedAmount({ slug, num });

    return amountCents > paidAmountCents - refundedAmountCents;
  }

  allowProcessingRefund() {
    const refundType = this.refundForm.type;
    const refundedFrom = this.refundForm.refundedFrom as 'deposit' | 'balance' | 'intermediate' | 'overage';
    const amountSettled = this.refundForm.amountSettled;
    const num = this.refundForm.refundedFrom === 'overage' ? this.refundForm.overagePaymentPosition : this.refundForm.intermediatePaymentPosition;


    const isInvalidRefundAmount = this.enteredIncorrectRefundAmount({ slug: refundedFrom, num });

    const isManual = this.refundForm.isManual;

    if (isManual ) {
      const requiredFields: any[] = [
        get(this.refundForm, 'date'),
        get(this.refundForm, 'method'),
        refundType,
      ];

      if (refundType === 'Partial') {
        requiredFields.push(!isInvalidRefundAmount);
      }
      return every(requiredFields);
    }

    if (isInvalidRefundAmount || !refundType || (!amountSettled && refundType === 'Partial')) {
      return false;
    }
    return true;
  }

  resetRefundChanges = () => {
    this.refundForm.amountDollars = null;
    this.refundForm.type = '';
    this.ui.makeRefund = false;
    this.refundForm.date = '';
    this.refundForm.method = '';
    this.refundForm.amountDollars = null;
    this.refundForm.refundedFrom = '';
    this.refundForm.amountSettled = false;
    this.refundForm.isManual = false;
    this.refundForm.intermediatePaymentPosition = null;
  }

  openRefundConfirmationModal = () => {
    const paymentType = this.refundForm.isManual ? 'Manual' : 'Not Manual';
    const message = REFUND_CONFIRMATION_MESSAGE[paymentType];
    const showCancel = true;
    const btnText = 'Confirm';

    return this.$clickOk(message, showCancel, btnText)
      .then((response) => {
        if (get(response, 'value.cancel')) {
          return;
        }
        this.processRefund();
      });
  }

  processRefund = () => {
    this.ui.makeRefund = false;
    if (!this.allowProcessingRefund) {
      return;
    }

    const isManual = this.refundForm.isManual;
    const refundedFrom = this.refundForm.refundedFrom;

    const transactionInformation = {
      amountCents: round(Number(this.refundForm.amountDollars) * 100).toFixed(2),
      refundType: this.refundForm.type,
      emails: this.refundForm.emails
    };

    if (isManual) {
      merge(transactionInformation, {
        date: this.refundForm.date,
        method: this.refundForm.method
      });
    }

    if (refundedFrom === 'intermediate') {
      merge(transactionInformation, {
        intermediatePaymentPosition: this.refundForm.intermediatePaymentPosition,
      });
    }

    if (refundedFrom === 'overage') {
      merge(transactionInformation, {
        overagePaymentPosition: this.refundForm.overagePaymentPosition,
      });
    }

    return this.$api.Admin.Payment.processRefund({ requestId: this.request._id, transactionInformation, refundedFrom, isManual })
      .then((res) => {
        this.request = res.request;
        this.toast.goodNews(`Amount has been successfully refunded`);
      }).catch((error) => {
        this.toast.badNews('Error processing Refund', error.data.error.readableError);
        this.unwrapError(error);
      }).finally(() => {
        this.resetRefundChanges();
      });
  }

  getRefund({ slug, num }: { slug: string, num?: number }) {
    const request = this.request;
    return _getRefund(request, slug, num);
  }

  shouldAllowRefund({ slug, num }) {
    const payment = this.nthCompletedPayment(this.request, num, slug.toLowerCase());

    let paidAmountCents;
    let refundedAmountCents;

    slug = slug.toLowerCase();

    if (slug === 'deposit') {
      paidAmountCents = payment.amountCents;
      refundedAmountCents = this.getRefund({ slug, num });
    } else if (slug === 'balance') {
      paidAmountCents = payment.amountCents;
      refundedAmountCents = this.getRefund({ slug, num });
    } else if (slug === 'intermediate') {
      refundedAmountCents = this.getRefund({ slug, num });
      paidAmountCents = payment.amountCents;
    } else if (slug === 'overage') {
      refundedAmountCents = this.getRefund({ slug, num });
      paidAmountCents = payment.amountCents;
    }

    return paidAmountCents > refundedAmountCents;
  }

  hasRefundState() {
    const currentState = this.request.state;
    const refundStates = this.ENUMS.bookingRequestState.refundStates;

    return refundStates.includes(currentState);
  }

  getRefundTitle(refund) {
    if (refund.state !== 'REFUNDED') {
      return;
    }
    return REFUND_TITLES[refund.descriptor] || (refund.descriptor.includes('OVERAGE') ? 'Overage Refund' : 'Intermediate Refund');
  }

  fullAmountRefunded({ slug, nthValue }) {
    let refundedAmount;
    let paidAmount;
    slug = slug.toLowerCase();

    if (slug === 'deposit') {
      refundedAmount = this.getRefund({ slug });
      paidAmount = this.nthCompletedPayment(this.request, nthValue, slug).amountCents;
    } else if (slug === 'balance') {
      refundedAmount = this.getRefund({ slug });
      paidAmount = this.nthCompletedPayment(this.request, nthValue, slug).amountCents;
    } else if (slug === 'intermediate') {
      refundedAmount = this.getRefund({ slug, num: nthValue });
      paidAmount = this.nthCompletedPayment(this.request, nthValue, slug).amountCents;
    } else if (slug === 'overage') {
      refundedAmount = this.getRefund({ slug, num: nthValue });
      paidAmount = this.nthCompletedPayment(this.request, nthValue, slug).amountCents;
    }

    return paidAmount === refundedAmount;
  }

  getChargedAndRefundedAmount({ slug, num }: { slug: string, num?: number }) {
    let paidAmountCents;
    let refundedAmountCents;

    if (slug === 'deposit') {
      paidAmountCents = this.nthCompletedPayment(this.request, 1, slug).amountCents;
      refundedAmountCents = this.getRefund({ slug: 'deposit' });
    } else if (slug === 'balance') {
      paidAmountCents = this.nthCompletedPayment(this.request, 2, slug).amountCents;
      refundedAmountCents = this.getRefund({ slug: 'balance' });
    } else if (slug === 'intermediate') {
      paidAmountCents = this.nthCompletedPayment(this.request, num, slug).amountCents;
      refundedAmountCents = this.getRefund({ slug: 'intermediate', num });
    } else if (slug === 'overage') {
      paidAmountCents = this.nthCompletedPayment(this.request, num, slug).amountCents;
      refundedAmountCents = this.getRefund({ slug: 'overage', num });
    }

    return { paidAmountCents, refundedAmountCents };
  }

  hasOveragePayment() {
    const schedule = get(this.request, 'data.payment.schedule', []);
    this.overagePayment = find(schedule, s => s.state === 'COMPLETED' && s.title === 'OVERAGE');
    return this.overagePayment;
  }

  cancelEdit = () => {
    this.ui.editingPayment = false;
    this.payment = null;
  }

  deletePayment = (id) => {
    this.request.admin.venuePayments = this.request.admin.venuePayments.filter(payment => payment._id !== id);

    const paths = {
      setPath: this.VENUE_PAYMENTS_PATH,
      value: this.request.admin.venuePayments
    };

    return this.saveRequest({ request: this.request, paths })
      .then((request) => {
        this.request = request;
        this.handleRequestUpdate();
      })
      .catch(error => this.unwrapError(error));
  }

  trackPayoutChanges = () => {
    if (isFinite(get(this.request, 'admin.resolutionTransaction.successFeeCents'))) {
      this.request.admin.resolutionTransaction.processingFeeDollars = this.centsBreakdown.cardFee / 100;
      this.request.admin.resolutionTransaction.successFeeDollars = this.centsBreakdown.bookingFee / 100;
    }
    this.ui.payoutChanged = true;
  }

  revertPayoutChanges = () => {
    this.ui.payoutChanged = false;
    this.request.admin.payout = cloneDeep(this.originalPayoutData);
  }

  addFinalPayout = () => {
    this.trackPayoutChanges();
    if (this.ENUMS.bookingRequestState.cancelledPostDepositStates.includes(this.request.state)) {
      return;
    }

    if (!get(this.request, 'admin.payout.amountDollars')) {
      set(this.request, 'admin.payout.amountDollars', this.calculateVenuePayout());
    }
  }

  editFinalPayout = () => {
    this.trackPayoutChanges();
  }

  toggleEmail = ({ emailTo }) => {
    return this.refundForm.emails[emailTo] = !this.refundForm.emails[emailTo];
  }

  allowSavePayout = () => {
    const hasDate = get(this.request, 'admin.payout.date');
    const hasMethod = get(this.request, 'admin.payout.method');
    const hasStatus = get(this.request, 'admin.payout.status');
    if (this.ENUMS.bookingRequestState.cancelledPostDepositStates.includes(this.request.state)) {
      const hasSuccessFee = get(this.request, 'admin.resolutionTransaction.successFeeDollars');
      const hasProcessingFee = get(this.request, 'admin.resolutionTransaction.processingFeeDollars');

      if (get(this.request, 'admin.payout.amountDollars') && Number(this.request.admin.payout.amountDollars) === 0) {
        return hasDate && hasStatus && hasSuccessFee && hasProcessingFee;
      }
      return hasDate && hasMethod && hasStatus && hasSuccessFee && hasProcessingFee;
    }
    return hasDate && hasMethod && hasStatus;
  }

  savePayout = () => {
    const paths = [{
      setPath: this.VENUE_PAYOUT_PATH,
      value: this.request.admin.payout
    }];

    if (this.ENUMS.bookingRequestState.cancelledPostDepositStates.includes(this.request.state)) {
      const { processingFeeDollars, successFeeDollars } = this.request.admin.resolutionTransaction;
      this.request.admin.resolutionTransaction.processingFeeCents = processingFeeDollars * 100;
      this.request.admin.resolutionTransaction.successFeeCents = successFeeDollars * 100;
      paths.push({
        setPath: this.RESOLUTION_TRANSACTION_PATH,
        value: this.request.admin.resolutionTransaction
      });
    }

    this.saveRequest({ request: this.request, paths })
      .then((request) => {
        this.request = request;
        this.handleRequestUpdate();
        this.ui.payoutChanged = false;
      })
      .catch(error => this.unwrapError(error));
  }

  calculateVenuePayout = () => {
    return this.adminEventsService.calculateVenuePayout(this.request);
  }

  payoutMismatch = () => {
    /* tslint:disable:triple-equals */
    return get(this.request, 'admin.payout.amountDollars') != this.calculateVenuePayout();
    /* tslint:enable:triple-equals */
  }

  setPayoutDate = (date) => {
    set(this.request, 'admin.payout.date', date.toDate());
    this.ui.payoutChanged = true;
  }

  setPaymentDate = (date) => {
    this.payment.date = date.toDate();
  }

  saveState = (request: any, state: string) => {
    return this.adminEventsService.handleStateChange(request, state)
      .catch((error) => {
        this.toast.badNews(`Request State Change Error`, error.data.error.readableError);
        this.unwrapError(error);
      });
  }

  saveAssignee = (request, assignee) => {
    return this.adminEventsService.saveAssignee(request, assignee)
      .then((response) => {
        const newAssignee = response.request.admin.assignee;
        request.admin.assignee = newAssignee;
        this.toast.goodNews('Request Assignee Changed', `Assignee successfully changed to ${newAssignee.fullName}`);
        return response;
      })
      .catch(error => this.unwrapError(error));
  }

  closeCancelSection = (request) => {
    if (request) {
      this.request.state = request.state;
      this.request.admin.resolutionTransaction = request.admin.resolutionTransaction;
      this.request.data.payment = request.data.payment;
      this.request = request;
      this.handleRequestUpdate();
    }
    this.ui.displayCancelForm = false;
  }

  allowCancellation = () => {
    return this.ENUMS.bookingRequestState.cancellableStates.includes(this.request.state);
  }

  getAuthorizedUsers = () => {
    this.adminEventsService.getAuthorizedUsers(this.request)
      .then(authorizedUsers => this.authorizedUsers = authorizedUsers);
  }

  isClosedEvent = () => {
    return this.request.state === 'CLOSED' || this.ENUMS.bookingRequestState.cancelledPostDepositStates.includes(this.request.state);
  }

  closeEvent = () => {
    if (this.ENUMS.bookingRequestState.cancelledPostDepositStates.includes(this.request.state)) {
      return this.adminEventsService.closePostDepositCancellations(this.request)
        .catch((error) => {
          this.toast.badNews('Request Closing Error', error.data.error.readableError);
          this.unwrapError(error);
        });
    } else {
      return this.adminEventsService.handleStateChange(this.request, 'CLOSED')
        .then(() => this.request.admin.payout.status = null)
        .catch((error) => {
          this.toast.badNews(`Request State Change Error`, error.data.error.readableError);
          this.unwrapError(error);
        });
    }
  }
}

export const EventDataSidebarComponent = {
  controller: EventDataSidebarController,
  template: require('./event-data-sidebar.component.jade'),
  bindings: {
    toggleDataSidebarView: '&',
    request: '<',
    saveRequest: '&'
  }
};
