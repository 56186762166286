const welcomePromoCodeModel = ['ngDialog', function(ngDialog) {
    return function () {
      return ngDialog.open({
        template: require('./welcome-promo-code.model.jade'),
        controllerAs: '$ctrl',
        className: 'ngdialog-theme-small micro-modal',
        plain: true,
        overlay: true,
      }).closePromise;
    };
}];

export default welcomePromoCodeModel;
