import cloneDeep from 'lodash/cloneDeep';
import { Search } from './models';

// Sixplus Types
import { ApiService } from 'spc/shared/api/api.service';
import { SpDownloadService } from 'spc/shared/sp-download.service';


class DownloadEventsController {
  search: Search;
  delimiter: string = ', ';
  year: string;
  yearList: string[] = [];
  NUM_PAST_YEARS = 3;
  downloading = false;

  constructor(private $api: ApiService, private unwrapError, private SpDownloadService: SpDownloadService, private now, private $q, private ENUMS) {
    'ngInject';
  }

  $onInit = () => {
    const month = new Date().getMonth();
    let year = +this.now().format('YYYY');

    // if we're in Q4, allow download of next year's data
    if (month >= 9) {
      year = year + 1;
    }

    let startingYear = this.now().subtract(this.NUM_PAST_YEARS, 'years').format('YYYY');
    while (startingYear <= year) {
      this.yearList.push(startingYear);
      startingYear = (parseFloat(startingYear) + 1).toString();
    }
  }

  downloadEvents = (option) => {
    this.downloading = true;
    const params = cloneDeep(this.search);
    if (option === 'Post Deposit Events') {
      params.requestStates = this.ENUMS.bookingRequestState.paidStates;
    } else {
      params.year = option;
    }
    const fileTitle = `proposals_${this.now().format('MMM DD, YYYY')}`;
    return this.$api.Admin.Requests.download(params)
      .then((response: any) => {
        this.downloading = false;
        this.SpDownloadService.exportEventsXLSXFile({ data: response.requests, fileTitle, sheetName: 'proposals' });
        return this.$q.resolve();
      })
      .catch((error) => {
        this.downloading = false;
        this.unwrapError(error);
      });
  }
}

export const DownloadEventsComponent = {
  controller: DownloadEventsController,
  template: require('./download-events.component.jade'),
  bindings: {
    search: '<'
  }
};
