import { ApiService } from '../../shared/api/api.service';
import { RawCompany } from '../../../../database/types/company';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

export interface SearchParams {
  $text?: { $search: string };
  domains?: string[];
}

class AdminCompaniesDashboardController {
  ready: () => void;
  companies: RawCompany[];
  numCompanies: number;
  userCountMap: { [companyId: string]: number };

  params: SearchParams = {};

  ui: {
    displayFilterSidebar?: boolean;
    displayDataSidebar?: boolean;
    selectedCompany?: RawCompany;
    name?: string;
  } = {};

  constructor(private $api: ApiService, private $clickOk, private unwrapError) {
    'ngInject';
  }

  $onInit = () => {
    this.ready();
    this.search();
  }

  search = (page = 0) => {
    return this.$api.Admin.Companies.search({ params: this.params, page, name: this.ui.name })
      .then((data) => {
        this.companies = data.companies;
        this.userCountMap = data.userCountMap;
        this.numCompanies = data.numCompanies;
      })
      .catch(error => this.unwrapError(error));
  }

  debouncedSearch = debounce(this.search, 300);

  selectCompany = (company) => {
    if (this.ui.selectedCompany === company) {
      return this.toggleDataSidebar();
    }

    this.ui.selectedCompany = company;
    this.ui.displayDataSidebar = true;
  }

  toggleFilterSidebar = () => {
    this.ui.displayFilterSidebar = !this.ui.displayFilterSidebar;
  }

  toggleDataSidebar = () => {
    this.ui.displayDataSidebar = !this.ui.displayDataSidebar;
  }

  delete = (company) => {
    const showCancel = true;
    return this.$clickOk('Deleting a company is permanent. This will also remove all affiliations between users and this company', showCancel)
      .then((response) => {
        if (get(response, 'value.cancel')) {
          return;
        }
        return this.$api.Admin.Companies.delete(company._id)
          .then(() => {
            this.search();
          });
      })
      .catch(error => this.unwrapError(error));
  }

  updateDisplay = (company) => {
    const selectedCompany = this.companies.find(_company => _company._id === company._id);
    Object.assign(selectedCompany, company);
  }
}

export const adminCompaniesDashboardComponent = {
  template: require('./admin-companies-dashboard.component.jade'),
  controller: AdminCompaniesDashboardController,
  bindings: {
    ready: '<'
  }
};
