import { queryHelpers } from '../../utils/query';
import { RawList } from 'lib/database/types/list';
import { RawListClient } from 'lib/database/types/list-client';
import { RawBaseUser } from 'lib/database/types/base-user';
import { GetAllListsResponse, CreateListResponse, AddVenueResponse, AddUserResponse } from 'spc/lib/server/api/lists/models';
import { get } from 'lodash';
export class ListsRouter {
  BASE_URL: string;

  constructor (private API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${this.API_ROOT}/lists`;
  }

  getAll = ({ userId, page, filters }: { userId: string, page?: number, filters?: any }): ng.IPromise<{ lists: RawList[]}> => {
    let URL = `${this.BASE_URL}/users/${userId}`;
    const options = {
      page: page
    };
    if (page >= 0) {
      URL += queryHelpers.makeQueryString(options);
    }

    return this.$http.get(URL, { params: { filters: filters } })
      .then((res: GetAllListsResponse) => res.data);
  }

  getAccountLists = ({ userId, page, filters }): ng.IPromise<{ lists: RawList[]}> => {
    let URL = `${this.BASE_URL}/accountsLists/${userId}`;
    if (page >= 0) {
      const options = { page };
      URL += queryHelpers.makeQueryString(options);
    }
    return this.$http.get(URL, { params: { filters: filters } })
      .then((res: GetAllListsResponse) => res.data);
  }

  create = ({ venueId, name, userId }: { venueId?: string, name: string, userId?: string }): ng.IPromise<{ list: RawList }> => {
    return this.$http.post(this.BASE_URL, { name, venueId, userId })
      .then((res: CreateListResponse) => res.data);
  }

  createDuplicateList = ({ venues, name }: {venues?: [], name: string }): ng.IPromise<{ list: RawList }> => {
    return this.$http.post(`${this.BASE_URL}/duplicate`, { name, venues })
      .then((res: CreateListResponse) => res.data);
  }

  addVenue = ({ venueId, lists }: { venueId: string, lists: string[] }): ng.IPromise<{ lists: RawList[] }> => {
    return this.$http.put(`${this.BASE_URL}/venue/${venueId}`, { lists })
      .then((res: AddVenueResponse) => res.data);
  }

  getSingleList = (listId: string): ng.IPromise<{ list: RawList }> => {
    return this.$http.get(`${this.BASE_URL}/${listId}`)
      .then((res: SingleListResponse) => res.data);
  }

  editList = ({ listId, edits }: { listId: string, edits: { name: string }}): ng.IPromise<{ list: RawList}> => {
    return this.$http.put(`${this.BASE_URL}/${listId}`, { edits })
      .then((res: SingleListResponse) => res.data);
  }

  addUser = ({ listId, user, sendEmail }: { listId: string, user: RawBaseUser, sendEmail: boolean}): ng.IPromise<{ addedUser: RawListClient }> => {
    return this.$http.put(`${this.BASE_URL}/${listId}/users`, { user, sendEmail })
      .then((res: AddUserResponse) => res.data );
  }

  updateListOrder = ({ listId, update }: {listId: string, update: { _id: string, order: number }[] }): ng.IPromise<any> => {
    return this.$http.put(`${this.BASE_URL}/${listId}/order`, { update })
      .then(res => res);
  }

  toggleListVisibility = ( list) => {
    return this.$http.put(`${this.BASE_URL}/${list._id}/visibility`, { list });
  }

  delete = (listId: string): ng.IPromise<Express.Response> => {
    return this.$http.delete(`${this.BASE_URL}/${listId}`);
  }

  removeUser = (userId, listId): ng.IPromise<Express.Response> => {
    return this.$http.delete(`${this.BASE_URL}/list/remove/user/${userId}`, { params: { listId : listId } });
  }

  deleteListVenue = (venueId, listId): ng.IPromise<Express.Response> => {
    return this.$http.delete(`${this.BASE_URL}/list/${venueId}`, { params: { listId : listId, venueId : venueId } });
  }
}
