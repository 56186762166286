import { DBaseUser } from 'spc/lib/database/types/base-user';
import { RawLead } from 'spc/lib/database/types/lead';
import { AccountMemberLeadsDetailsService } from './account-member-leads-details.service';

interface MembersArray {
  member: DBaseUser;
  checked: boolean;
}
class AccountMembersCardViewController {
  leads: RawLead[];
  members: DBaseUser[];
  activeLeads: RawLead[];
  inactiveLeads: RawLead[];
  confirmedLeads: RawLead[];
  concludedLeads: RawLead[];
  newMembersArray: MembersArray[];
  loading: boolean;
  displayTab: string;
  currentPage: number;
  numLeads: number = 0;
  viewType: string = 'Card View';
  cardViewLeadsPerPage: number = 10;
  isChecked: Boolean = true;
  updateSelectionArray: string[];
  sortParameters: { key: string, clientEventSort: string, teamEventSort: {} }[];
  eventFilters = {
    sort: { 'request.date': -1 }
  };

  constructor(private ENUMS,
    private $api,
    private unwrapError,
    private accountMemberLeadsDetailsService: AccountMemberLeadsDetailsService ) {
    'ngInject';
  }

  $onInit = () => {
    this.loading = true;
    this.displayTab = 'Active';
    this.newMembersArray = this.members.map((member) => {
    return { member, checked: false };
    });
    this.allAccountMembersLeads();
  }


  displayChange = () => {
    this.leads = [];
    this.activeLeads = [];
    this.confirmedLeads = [];
    this.inactiveLeads = [];
    this.loading = true;
    if (Array.isArray(this.updateSelectionArray) && this.updateSelectionArray.length) {
      this.toggleAccountMembersFilter(this.updateSelectionArray);
    } else {
      this.allAccountMembersLeads();
    }
  }

  getFilterSelection = ({ userId, check }) => {
    if (userId !== undefined && check !== undefined) {
      this.updateSelectionArray = this.accountMemberLeadsDetailsService.updateSelection({ member: userId, check, members: this.newMembersArray });
      if (Array.isArray(this.updateSelectionArray) && this.updateSelectionArray.length) {
        this.toggleAccountMembersFilter(this.updateSelectionArray);
      } else {
        this.isChecked = true;
        this.allAccountMembersLeads();
      }
    } else {
      this.allAccountMembersLeads();
    }
  }

 toggleAccountMembersFilter = (member, page = 0) => {
  if (this.isChecked === true) {
    this.isChecked = !this.isChecked;
  }
  return this.$api.Leads.getAccountLeads({ member, page: page, leadsPerPage: this.cardViewLeadsPerPage, viewType: this.viewType, state: this.displayTab, filters: this.eventFilters  })
    .then((res) => {
      this.accountMembersLeads(res, page);
      this.loading = false;
    })
    .catch((error) => {
      this.unwrapError(error);
  });
 }

  allAccountMembersLeads = (page = 0) => {
    // uncheck account members checkbox when selecting all;
    this.newMembersArray.forEach(m => m.checked ? m.checked = false : undefined );

    // clear updateSelectionArray when selecting all;
    if (this.updateSelectionArray !== undefined) {
      this.updateSelectionArray.splice(0, this.updateSelectionArray.length);
    }
    return this.$api.Leads.getAccountLeads({ page: page, leadsPerPage: this.cardViewLeadsPerPage, viewType: this.viewType, state: this.displayTab, filters: this.eventFilters })
      .then((res) => {
        this.loading = false;
        this.accountMembersLeads(res, page);
      })
      .catch((error) => {
        this.unwrapError(error);
      });
  }

  accountMembersLeads = (res, page) => {
    const leadsData = this.accountMemberLeadsDetailsService.setLeads(res);
    this.leads = leadsData.leads;
    this.activeLeads = leadsData.activeLeads;
    this.concludedLeads = leadsData.concludedLeads;
    this.inactiveLeads = leadsData.inactiveLeads;
    this.confirmedLeads = [...leadsData.confirmedLeads, ...this.concludedLeads];
    this.numLeads = leadsData.numLeads;
    this.currentPage = page + 1;
  }

  setTeamEventSorting = (param) => {
    this.eventFilters.sort = param.teamEventSort;
    this.allAccountMembersLeads();
  }
}


export const AccountMembersCardViewComponent = {
  controller: AccountMembersCardViewController,
  template: require('./account-members-card-view.component.jade'),
  bindings: {
    members: '<',
    sortParameters: '<'
  }
};
