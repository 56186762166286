import { get, isEqual, cloneDeep } from 'lodash';

module.exports = ['$rootScope', '$location', 'ngDialog', function($rootScope, $location, ngDialog) {
  const ret: { unregister?: () => void; register?: (doc: any) => void; _handler?: any } = {};

  ret.register = function(doc) {
    ret.unregister();
    const originalDoc = JSON.stringify(doc);
    ret._handler = $rootScope.$on('$locationChangeStart', function(e, nextRoute) {
      const currentDoc = JSON.stringify(doc);
      if (originalDoc === currentDoc) { return; }
      e.preventDefault();
      $rootScope.$broadcast('$viewReady');

      ngDialog.
        open({
          template: require('./location-change-confirmation.jade'),
          className: 'ngdialog-theme-small',
          overlay: true,
          plain: true,
          // tslint:disable-next-line: no-shadowed-variable
          controller: ['$scope', 'nextRoute', 'doc', function($scope, nextRoute, doc) {
            $scope.doc = doc;
            $scope.goToNext = function() {
              $scope.closeThisDialog({ nextRoute });
            };

            $scope.stay = function() {
              $scope.closeThisDialog();
            };
          }],
          resolve: {
            nextRoute: function() { return nextRoute; },
            doc: function() { return doc; }
          }
        }).
        closePromise.
        then(function(decision) {
          if (get(decision, 'value.nextRoute', false)) {
            ret.unregister();
            $location.url(
              decision.value.nextRoute.substring(
                $location.absUrl().length - $location.url().length
                )
            );
          } else {
            $rootScope.$broadcast('$viewReady');
          }
        });
    });

    return ret;
  };

  ret.unregister = function() {
    ret._handler && ret._handler();
    return ret;
  };

  return ret;
}];
