import { RawVenue } from 'spc/lib/database/types/venue/venue';

export class VenueDistinctionsCarousel {
  venue: RawVenue;
  accoladeUrl: String;
  ENUMS;
  constructor(private $cloudinary, ENUMS) {
    'ngInject';
    this.ENUMS = ENUMS;
  }
  getDistinctions = (distinctions) => {
    const sortByObject = this.ENUMS.liteProfileAccolades.DISTINCTIONS.reduce((obj, item, index) => { return { ...obj, [item]: index }; }, {});
    const customSort = distinctions.sort((a, b) => sortByObject[a.accolade] - sortByObject[b.accolade]);
    const data = customSort.sort((a, b) => {
      return b.year - a.year;
    });
    return data;
  }

  scrollCards(scrollDirection) {
    const carousel = document.getElementById('distinction');
    if (scrollDirection === 'left') {
      carousel.scrollLeft -= 200;
    }
    else if (scrollDirection === 'right') {
      carousel.scrollLeft += 200;
    }
  }
}

export const VenueDistinctionsCarouselComponent = {
  bindToController: true,
  bindings: {
    venue: '<',
  },
  controller: VenueDistinctionsCarousel,
  template: require('./venue-distinctions-carousel.jade')
};
