class TopLevelPremiumPageController {
    constructor(private $rootScope, private $seo) {
      'ngInject';
    }

    componentIsReady = () => {
      this.$seo.set(`Upgrade`);
      this.$rootScope.$emit('$viewReady', 'SHOW_FOOTER');
      }
    }

   export const topLevelPremiumPageComponent = {
     template: require('./top-level-premium-page.component.jade'),
     controller: TopLevelPremiumPageController
   };
