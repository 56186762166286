
import { each, get, includes, isString } from 'lodash';
import { RawBaseUser } from 'spc/lib/database/types/base-user';

module.exports = ['$venueValidator', '$rootScope', '$timeout', function($venueValidator, $rootScope, $timeout) {
  return {
    restrict: 'A',
    scope: false,
    link: function(scope, element, attrs) {
      init();

      ///// Initialization function
      function init() {
        _waitForUser(function() {
          _setAdminValidation();
          getErrors();
        });
      }
      
      function getErrors() {
        if (!attrs.errorTooltip) {
          return;
        }

        scope.doc = isString(attrs.errorTooltip) ?
          JSON.parse(attrs.errorTooltip) :
          attrs.errorTooltip;
        
        $venueValidator.displayInvalidFields(scope.doc, scope.doc.schemaType.toString(), !!scope.validateAdminFields)
          .then((res) => {
            scope.errorList = res;

            if (scope.errorList) {
              let errorStr = '';
              each(scope.errorList, function(error) {
                errorStr += '<i class="fa fa-circle" /><div class="tooltip-error-message">' + error + '<br /></div>';
              });

              element.attr({
                rel: 'tooltip',
                'data-html': true,
                'data-toggle': 'tooltip',
                'data-placement': 'right',
                title: errorStr
              });
            }
            element.tooltip();
          });
      }


      function _setAdminValidation() {
        const user = get($rootScope, 'user.$') as RawBaseUser;
        if (user) {
          scope.validateAdminFields = includes(user.roles, 'Admin');
        }
      }

      /**
       * Helper utility to wait for the user to be set on `$rootScope` before executing callback.
       * Helpful because we dont want to pass in `$user` to this directive and we also don't want
       * to make an AJAX call for the user in this directive because its used many times on one page
       *
       * @api private
       * @param {Function} cb
       * @return {Void}
       */
      function _waitForUser(cb) {
        if (get($rootScope, 'user.$')) {
          cb();
          return;
        }
        $timeout(() => _waitFor(cb));
      }
    }
  };
}];
