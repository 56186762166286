
class TopLevelAvailabilityCalendarController {
  canInit: boolean = false;
  role: 'venue' | 'client';
  venues: any[];
  constructor(
    private $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $scope,
    private $seo,
    private $user) { 'ngInject'; }

  $onInit() {
    this.$user.$waitFor('LOGGED_IN', () => {
      this.canInit = true;
    });

    this.$user.$waitFor('NOT_LOGGED_IN', () => {
      this.$location.path(`/login`);
    });
  }

  componentIsReady = () => {
    this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
  }

  changesSaved = () => {
    return this.$scope.$broadcast('CALENDAR_SAVED_CHANGES', { message: 'Your changes have been saved' });
  }
}

export const TopLevelAvailabilityCalendarComponent = {
  template: require('./top-level-availability-calendar.component.jade'),
  controller: TopLevelAvailabilityCalendarController
};
