import { UpdateVenueNoteRequestBody, VenueNotesResponse } from 'server/api/admin/venuenotes/models';

export class VenueNotes {
  BASE_URL: string;
  constructor(
    private API_ROOT: string,
    private $http: ng.IHttpService
  ) {
    this.BASE_URL = `${this.API_ROOT}/admin/venuenotes`;
  }

  public get = (venue: any): ng.IPromise<VenueNotesResponse> => {
    const venueId = venue._id ? venue._id.toString() : venue.toString();
    return this.$http.get(`${this.BASE_URL}/venue/${venueId}`)
      .then((response: { data: VenueNotesResponse }) => response.data);
  }

  public update = ({ venue, note }: { venue: any, note: any }) => {
    const body: UpdateVenueNoteRequestBody = { note };
    const venueId = venue._id ? venue._id.toString() : venue.toString();
    return this.$http.put(`${this.BASE_URL}/venue/${venueId}`, { note })
      .then(response => response.data);
  }
}
