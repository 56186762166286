import { get, includes, filter, toLower } from 'lodash';
/**
 * Toggles visibility for one of our "core" documents: Venues, Spaces, Drinks, or Menus. The directive takes in
 * the related venue, the document for which to toggle visibility, and an array of documents of the same schemaType as
 * the doc to toggle visibility for (except for venue). This allows us to conduct client-side validation so that we don't
 * need to make an AJAX request to see if a user is about to invalidate the last visible document of that type for a visible venue
 *
 * @param {Venue} venue
 * @param {Doc} doc
 * @param {Array} docs, optional (required if not venue)
 */
export default function() {
  return {
    template: require('./toggle-visibility.jade'),
    scope: {
      venue: '=',
      doc: '=',
      docs: '='
    },
    controller: ['$scope', 'unwrapError', 'VisibilityFactory', '$clickOk', function($scope, unwrapError, VisibilityFactory, $clickOk) {

      ///// Listeners
      $scope.$on('TOGGLED_ON',
        ev => _toggle(ev, $scope.venue, $scope.doc));

      $scope.$on('TOGGLED_OFF',
        ev => _toggle(ev, $scope.venue, $scope.doc));

      ///// Functions

      /**
       * Toggle visibility of document
       *
       * @api private
       * @param {Ev} ev
       * @param {Venue} venue
       * @param {Doc} doc
       * @fires `UNDO_TOGGLE` if it errors
       */
      function _toggle(ev, venue, doc) {
        if (ev.stopPropagation) {
          ev.stopPropagation();
        }

        const needsVisibilityCheck = venue.isVisible &&
          doc.isVisible &&
          !includes(['Venue', 'Drink'], doc.schemaType);

        if (needsVisibilityCheck) {
          const numVisible = filter($scope.docs, d => d.isVisible).length;
          if (numVisible < 2) {
            return VisibilityFactory.
              warnUserAboutNumVisibleDocuments(doc);
          }
        }

        VisibilityFactory.confirmHideDoc(doc).
          then(function(data) {
            if (get(data, 'value.cancel')) {
              return;
            }
            return VisibilityFactory.toggleVisibility($scope.venue, $scope.doc).
              then(() => $scope.$broadcast('FINISH_TOGGLE')).
              then(() => $scope.$emit('RECALCULATE_VISIBILITY', { schemaType: $scope.doc.schemaType }));
          }).
          catch(function(error) {
            $scope.$broadcast('UNDO_TOGGLE');
            unwrapError(error);
          });

      }
    }]
  };
}
