import { response } from 'express';
import mongoose from 'mongoose';
import { GetExperienceRequest, GetExperienceResponse, VirtualEventInquiryRequest, ExperienceQueryResponse, ExperienceQueryRequest } from 'server/api/experience/models';
import { RawExperience, VirtualEventInquiry, ExperienceQuery } from 'spc/lib/database/types/virtual-events';

export class VirtualExperience {
  BASE_URL: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${API_ROOT}/experience`;
  }


  public getExperienceById = ({ experienceId }: { experienceId: mongoose.Types.ObjectId | string }): ng.IPromise<GetExperienceResponse> => {
    return this.$http.get(`${ this.BASE_URL }/${experienceId}`)
      .then((res: { data:  GetExperienceResponse}) =>  res.data);
  }

  public requestForVirtualEvent = ({ virtualEventInquiry, experienceId }: { virtualEventInquiry: VirtualEventInquiry, experienceId: mongoose.Types.ObjectId | string }) => {
    return this.$http.post(`${ this.BASE_URL }/${experienceId}/virtual-event-inquiry`, { virtualEventInquiry })
      .then((res: { data: Express.Response }) => {
        return res.data;
    });
  }
  public getAllPublished = ({ query }: {query: ExperienceQuery}): ng.IPromise<ExperienceQueryResponse> => {
    return this.$http.get(`${ this.BASE_URL }`, { params: query })
      .then(( res: { data: ExperienceQueryResponse }) => {
        return res.data;
    });
  }
}
