import {
  CreateFeaturedVenueResponse,
  GetFeaturedVenuesResponse,
  PatchFeaturedVenuesResponse,
  EditFeaturedVenueResponse} from 'lib/server/api/admin/featured-venues/models';


export class FeaturedVenues {
  BASE_URL: string;

  constructor(API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${ API_ROOT }/admin/featured-venues`;
  }

  get = (city): ng.IPromise<GetFeaturedVenuesResponse> => {
    return this.$http.get(`${this.BASE_URL}`, { params: { city } })
      .then((response: { data: GetFeaturedVenuesResponse }) => response.data);
  }

  create = ({ featuredVenue }): ng.IPromise<CreateFeaturedVenueResponse> => {
    return this.$http.post(this.BASE_URL, { featuredVenue })
      .then((response: { data: CreateFeaturedVenueResponse }) => response.data);
  }

  patch = (featuredVenues): ng.IPromise<PatchFeaturedVenuesResponse> => {
    return this.$http.patch(this.BASE_URL, { featuredVenues })
      .then(response => response.data);
  }

  update = ({ featuredVenue }): ng.IPromise<EditFeaturedVenueResponse> => {
    return this.$http.put(`${this.BASE_URL}/${featuredVenue._id}`, { featuredVenue })
      .then(response => response.data);
  }

  remove = ({ featuredVenue }) => {
    return this.$http.delete(`${this.BASE_URL}/${featuredVenue._id}`)
      .then(response => response.data);
  }
}
