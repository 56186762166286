import { each, unset, assign } from 'lodash';
const braintree = require('../../../vendor/braintree');

// SixPlus Deps
import { BrowserDetectorService } from '../../shared/browser-detector.service';

// Constants
const GATEWAY_TEST_URL = `https://api.braintreegateway.com`;

export default ['braintreeHelpers', 'unwrapError', 'browserDetectorService', function (braintreeHelpers, unwrapError, browserDetectorService: BrowserDetectorService) {
  braintree.init = init;
  braintree.destroy = destroy;

  const BRAINTREE_STYLES = {
    '#card-number input': { 'font-size': '24px' }
  };


  /**
   * Initializes braintree and initializes with either drop-in UI or
   * hosted fields (for full PCI compliance)
   *
   * @param {String} id, id of container to drop form into
   * @param {String} token
   * @param {HostedFieldsOptions} hostedFieldsOptions and extra methods
   * @param {extraMethods} extraMethods, params and callback methods that
   *
   * come with braintree SDK
   */
  function init(id, token, hostedFieldsOptions, extraMethods) {
    this.$token = token;
    const ui = hostedFieldsOptions ? 'custom' : 'dropin';
    const options: any = {};

    if (extraMethods) {
      each(extraMethods, function (value, key) {
        options[key] = value;
      });
    }

    if (hostedFieldsOptions) {
      options.id = id;
      hostedFieldsOptions.styles = BRAINTREE_STYLES;
      options.hostedFields = hostedFieldsOptions;
    } else {
      options.container = id;
    }

    this.setup(token, ui, assign({}, options, {
      onReady: function (integration) {
        braintree.$instance = integration;
        if (browserDetectorService.isMicrosoft()) {
          $.get(GATEWAY_TEST_URL, $.noop);
        }
        if (extraMethods.onReady) {
          extraMethods.onReady(integration);
        }
      }
    }));
  }

  /**
   * Destroy current braintree instance for state management purposes
   *
   * @param {Function} cb
   */
  function destroy(cb) {
    if (!this.$instance) {
      return;
    }

    let _instance = this.$instance;
    const _this = this;
    _instance.teardown(function () {
      if (cb) {
        cb();
      }
      _this.$token = null;
      _instance = null;
    });
  }

  return braintree;
}];
