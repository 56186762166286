import { get } from 'lodash';

export function getAttachments ({ conversation, request }) {
  let allAttachments;
  if (get(conversation, 'attachments')) {
    allAttachments = [...conversation.attachments];
  }
  if (request.venue.bookingType && request.contractUrl && request.venue.bookingType === 'Defer_to_Venue' && request.contractUrl.length) {
    const newLink = {
      title: 'Link',
      url: request.contractUrl
    };
    allAttachments.push(newLink);
  }
  return allAttachments;
}
