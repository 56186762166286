import { RawReview } from 'lib/database/types/review';
import { UserService } from 'spc/services/user.service';
import { ApiService } from 'spc/shared/api/api.service';

import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import { ReviewsService } from './reviews.service';

class VenueReviewsController {
  private reviews: RawReview[];
  CRITERIA = ['venueRecommendation', 'service', 'ambience', 'communication', 'food'];
  starsMap: {
    [key: string]: {
      fullStars: number;
      halfStars: number;
      emptyStars: number;
    }
  } = {}  ;
  ui: {
    fullStars: number;
    halfStars: number;
    emptyStars: number;
    score: any;
    scores: any;
    allowEdit: boolean;
  } = {
    fullStars: 0,
    halfStars: 0,
    emptyStars: 0,
    score: 0,
    scores: { },
    allowEdit: false
  };

  clones: { [id: string]: RawReview } = {};

  constructor(
      private $user: UserService,
      private $api: ApiService,
      private unwrapError,
      private reviewsService: ReviewsService
    ) {
    'ngInject';
  }

  $onInit = () => {
    this.ui.allowEdit = this.$user.isAdmin();
  }

  sanitizedClientName = (client) => {
    if (!client) {
      return;
    }
    return `${client.firstName} ${client.profile.name.last[0]}.`;
  }

  displayStars = () => {
    this.ui.scores = this.reviewsService.calculateRatings(this.reviews);

    if (this.reviews && this.reviews.length) {
      this.CRITERIA.forEach(criteria => this.starsMap[criteria] = this.reviewsService.calculateStars(this.ui.scores[criteria]));
      this.reviews.forEach(review => this.starsMap[review._id.toString()] = this.reviewsService.calculateStars(review.venueRecommendation));
    }
  }

  startEditing = (review) => {
    this.clones[review._id] = cloneDeep(Object.assign({ }, review));
  }

  isEditing = (reviewId) => {
    return this.clones[reviewId];
  }

  cancelEditing = (reviewId) => {
    delete this.clones[reviewId];
  }

  updateReview = (reviewId) => {
    return this.$api.Admin.Venues.updateReview({ update: this.clones[reviewId] })
      .then((data) => {
        const updatedReview = data.review;
        const existingReview = this.reviews.find(review => review._id === reviewId);
        Object.assign(existingReview, updatedReview, { client: existingReview.client, proposal: existingReview.proposal });
        this.starsMap[reviewId] = this.reviewsService.calculateStars(existingReview.venueRecommendation);
        this.cancelEditing(reviewId);
      })
      .catch(error => this.unwrapError(error));
  }

  $onChanges = () => {
    this.displayStars();
  }
}

export const venueReviewsComponent = {
  template: require('./venue-reviews.component.jade'),
  controller: VenueReviewsController,
  bindings: {
    reviews: '<'
  }
};
