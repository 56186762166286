
export interface ProposalMetrics {
  getInitialValues(): {
    initialTab: string;
  };
}

export class ProposalMetricsService implements ProposalMetrics {
  constructor(private now) {
    'ngInject';
  }

  public getInitialValues() {
    const self = this;
    return {
      initialTab: 'Monthly'
    };
  }
}
