import * as angular from 'angular';
import 'angular-input-masks';
import { ConciergeForm } from './concierge-form.component';
import ConciergeSubmittedComponent from './concierge-submitted.component';
import { TopLevelConciergeForm } from './top-level-concierge-form.component';
import { ConciergeService } from './concierge.service';
import { neighborhoodModal } from './neighborhood-modal/neighborhood.modal';
import { ExpandAreaComponent } from './neighborhood-modal/expand-area.component';
import { CITY_NEIGHBORHOODS } from './city-neighborhoods';
import { conciergeUserDetailsComponent } from './user-details/concierge-user-details.component';
import googleLocationModal from './google-location-modal/google-location.modal';
import { GoogleLocationService } from './google-location-modal/google-location.service';
import welcomePromoCodeModel from './welcome-promo-code-model/welcome-promo-code.model';

export default angular.module('concierge', ['ui.utils.masks'])
  .component('topLevelConciergeForm', TopLevelConciergeForm)
  .component('conciergeForm', ConciergeForm)
  .component('conciergeSubmitted', ConciergeSubmittedComponent)
  .component('expandArea', ExpandAreaComponent)
  .component('conciergeUserDetails', conciergeUserDetailsComponent)
  .constant('CITY_NEIGHBORHOODS', CITY_NEIGHBORHOODS)
  .service('conciergeService', ConciergeService)
  .service('googleLocationService', GoogleLocationService)
  .factory('googleLocationModal', googleLocationModal)
  .factory('neighborhoodModal', neighborhoodModal)
  .factory('welcomePromoCodeModel', welcomePromoCodeModel)
  .name;
