
/**
 * Fade out the associated element after a given number of milliseconds
 *
 * @property {Number} fadeOutAfter
 */

export const fadeOutAfter = function () {
  return function (scope, element, attrs) {
    handleTimeoutAttrFade(attrs.fadeOutAfter, element);
  };
};



function handleTimeoutAttrFade(attr, element) {
  var timeout = parseInt(attr, 10);
  if (attr && !isNaN(timeout)) {
    setTimeout(function () {
      element.fadeOut().addClass('test-hidden');
    }, timeout);
  }
}
