class TopLevelAdminController {
  constructor(private $rootScope, private $seo) {
   'ngInject';
 }

 componentIsReady = () => {
  this.$seo.set(`Experiences`);
  this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
 }
}

export const topLevelAdminExperienceComponent = {
  template: require('./top-level-admin-experience.component.jade'),
  controller: TopLevelAdminController
};
