import { RawBookingRequest } from 'spc/lib/database/types/booking-request';
import { RawLead } from 'spc/lib/database/types/lead';
import { RawLeadClient } from 'spc/lib/database/types/lead-client';
import { RawBaseUser } from 'spc/lib/database/types/base-user';

interface UI {
  allProposals?: boolean;
  [id: string]: boolean;
}

interface Updates {
  allProposals?: boolean;
  proposals?: {
    [id: string]: boolean;
  };
}

class LeadPermissionsController {
  ui: UI;
  requests: RawBookingRequest[];
  lead: RawLead;
  close: (params: { updatedLeadClients: RawLead, updatedProposals: RawBookingRequest[] }) => void;
  userId: string;
  updates: Updates;
  client: RawLeadClient;
  isPrimary: boolean;
  user: RawBaseUser;
  constructor( private $user, private $api, private unwrapError) {
    'ngInject';
    this.ui = this.setUi();
    this.updates = {
      proposals: {}
    };
    this.user = this.$user.$;
    this.client = this.getUser();
    this.isPrimary = this.isPrimaryUser();
  }

  setUi() {
    const ui = this.requests.reduce((requestPermissions, request) => {
      requestPermissions[request._id.toString()] = this.userIsCollaborator(request);
      return requestPermissions;
    }, {} as UI);

    ui.allProposals =  this.userOnAllProposals();
    return ui;
  }

  getUser() {
    return this.lead.clients.find(client => this.getClientId(client) === this.userId);
  }

  isPrimaryUser() {
    if (this.$user.isAdmin()) {
      return true;
    } else {
      return this.userId === (this.lead.primaryClient as RawBaseUser)._id.toString();
    }
  }

  userIsCollaborator(request) {
    return request.clients.some((client) => {
      const id = this.getClientId(client);
      return id === this.userId;
    });
  }

  userOnAllProposals() {
    const user = this.lead.clients.find((client) => {
      const clientId = this.getClientId(client);
      return clientId === this.userId.toString();
    });
    return user.allProposals;
  }

  getClientId(client) {
    return client.user._id ? client.user._id.toString() : client.user.toString();
  }

  toggleAllProposals() {
    this.ui.allProposals = true;
    this.updates.allProposals = this.ui.allProposals;
  }

  toggleManualProposals() {
    this.ui.allProposals = false;
    this.updates.allProposals = this.ui.allProposals;
  }

  toggleIndividualProposal(request) {
    if (this.ui.allProposals) {
      return;
    }
    this.ui[request._id.toString()] = !this.ui[request._id.toString()];

    if (!this.userIsCollaborator(request)) {
      this.updates.proposals[request._id.toString()] = true;
    } else {
      this.updates.proposals[request._id.toString()] = false;
    }
  }

  submitChanges() {
    this.$api.Leads.updatePermissions({ lead: this.lead, userId: this.userId, options: this.updates })
      .then(res => this.close({ updatedLeadClients: res.leadClients, updatedProposals: res.proposals }))
      .catch(error => this.unwrapError(error));
  }
}

export const LeadPermissionsComponent = {
  template: require('./lead-permissions.component.jade'),
  controller: LeadPermissionsController,
  bindings: {
    close: '&',
    requests: '<',
    lead: '=',
    userId: '<',
    view: '='
  }
};
