import moment from 'moment';
import { cloneDeep, each, get, groupBy, map, keys, merge, sample, set, sortBy, transform } from 'lodash';
import { ApiService } from 'spc/shared/api/api.service';
import { EventResult } from 'shared/interfaces/metrics/event-result.model';
import { MappedEventResult } from './mapped-event-result.model';
import { TimeOptions } from './time-options.model';

// Constants
const DATE_FORMAT = 'YYYY-MM-DD';
export interface ProposalMetricsSearch {

  initialize(): void;

  clearState(): void;

  setStartDate(date: moment.Moment): ProposalMetricsSearchService;

  setEndDate(date: moment.Moment): ProposalMetricsSearchService;
}

export interface MetricsFilters {
  startDate?: string;
  endDate?: string;
}

export interface MetricsResults {
  events: any;
}

/**
 * Stateful Service that keeps track of search results for the Business Tracking Dashboard
 * state has to be manually cleared in the components in which the service is used bc ng1 to ng2 migration
 * sucks for factory pattern usage
 */
export class ProposalMetricsSearchService implements ProposalMetricsSearch {
  filters: MetricsFilters;
  results: MetricsResults;
  timeChunk: string;

  constructor(private now, private $api: ApiService) {
    'ngInject';
  }

  public initialize = () => {

    this.timeChunk = 'Monthly';
    const startDate = this.getStartDate();

    this.filters = {
      startDate,
      endDate: this.getEndDate(startDate)
    };

    this.results = {
      events: []
    };
  }

  public clearState = () => {
    this.filters = {};
  }

  public setStartDate = (momentDate: moment.Moment): ProposalMetricsSearchService => {
    const date = momentDate.format(DATE_FORMAT);
    if (this.filters.endDate && this.filters.endDate < date) {
      this.filters.startDate = this.filters.endDate;
      this.filters.endDate = date;
    } else {
      this.filters.startDate = date;
    }

    return this;
  }

  public getTimeOptions = (): TimeOptions => {
    return {
      chunkStrategy: this.timeChunk,
      startDate: this.filters.startDate,
      endDate: this.filters.endDate
    };
  }

  public setEndDate = (momentDate: moment.Moment): ProposalMetricsSearchService => {
    const date = momentDate.format(DATE_FORMAT);
    if (this.filters.startDate && this.filters.startDate > date) {
      this.filters.endDate = this.filters.startDate;
      this.filters.startDate = date;
    } else {
      this.filters.endDate = date;
    }
    return this;
  }

  public runSearch = () => {
    return this.$api.Admin.Metrics
      .getEvents(this.filters)
      .then((data) => {
        return {
          buckets: this.groupEventsByCity(data.results),
          nps: data.nps
        };
      })
      .then((data) => {
        return {
          data: this.sortBucketsByTime(data.buckets),
          nps: data.nps
        };
      });
  }

  private sortBucketsByTime = (buckets: MappedEventResult): MappedEventResult => {
    return transform(buckets, (acc: MappedEventResult, bucket, city) => {
      set(acc, [`${city}`], sortBy(bucket, b => b.date));
    }, {});
  }

  private groupEventsByCity = (events: EventResult[]): MappedEventResult => {
    return groupBy(events, 'address.city');
  }

  private getStartDate = (): string => {
    return this.now().startOf('year').format(DATE_FORMAT);
  }

  private getEndDate = (startDate: string): string => {
    return moment(startDate).add(1, 'year').format(DATE_FORMAT);
  }
}
