import * as angular from 'angular';

import { SidebarTimeslotCardComponent } from './sidebar-timeslot-card.component';
import { DaysTimeslotGridComponent } from './days-timeslot-grid.component';
import { TimeslotViewService } from './timeslot-view.service';
import { EditTimeslotComponent } from './edit-timeslot.component';
import { TimeslotSidebarComponent } from './timeslot-sidebar.component';

export const TimeslotViewModule = angular.module('TimeslotViewModule', [])
  .component('sidebarTimeslotCard', SidebarTimeslotCardComponent)
  .component('daysTimeslotGrid', DaysTimeslotGridComponent)
  .component('editTimeslot', EditTimeslotComponent)
  .component('timeslotSidebar', TimeslotSidebarComponent)
  .service('timeslotViewService', TimeslotViewService)
  .name;
