import { ApiService } from 'spc/shared/api/api.service';
import { RecommendationService } from 'spc/shared/recommendation.service';

export class RecoTransferService {
  leads: any[];
  constructor(private $api: ApiService, private unwrapError, private recommendationService: RecommendationService, private $user) {
    'ngInject';
    $user.$waitFor('LOGGED_IN', () => {
      this.getUserLeads();
   });
  }

  getUserLeads = () => {
    if (this.$user.isLoggedIn()) {
      this.$api.Leads.getUserLeads({ activeOnly: true })
        .then(response => this.leads = response.leads)
        .catch(error => this.unwrapError(error));
    }
  }

  allowRecoTransfer = (lead) => {
    if (!this.leads || !this.leads.length) {
      return;
    }
    return this.getValidLeads(lead).length;
  }

  getValidLeads = (lead) => {
    if (!this.leads) {
      return;
    }
    return this.leads.filter(_lead => _lead._id.toString() !== lead._id.toString());
  }

  transferReco = ({ lead, recommendation }) => {
    return this.recommendationService.updateRecommendation({ recommendation, changes: { lead: lead._id }})
      .catch(error => this.unwrapError(error));
  }
}

