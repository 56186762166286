import { Admin as AdminRouter } from './admin';
import { AuthRouter } from './auth';
import { RecosRouter } from './recos';
import { CompaniesRouter } from './companies';
import { ConversationRouter } from './conversations';
import { Requests } from './requests';
import { LandingRouter } from './landing';
import { AvailabilityCalendarRouter } from './availability-calendar';
import { MenuRouter } from './menus';

import { BaseUserRouter } from './base-users';
import { AuthorizedClientRouter } from './authorized-clients';
import { FeaturedVenuesRouter } from './featured-venues';
import { PaymentRouter } from './payment';
import { VenueQueryRouter } from './venue-query';
import { LocationsRouter } from './locations';
import { ListsRouter } from './lists';
import { VirtualExperience } from './virtual-experience';
import { MembershipAccount } from './membership-account';

export class ApiService {
  constructor(private $http: ng.IHttpService, private $jwt, private $config) {
    'ngInject';
  }
  private API_ROOT = `${this.$config.basePath.url}/api/v1`;

  public Admin = AdminRouter(this.API_ROOT, this.$http, this.$jwt);
  public AuthorizedClients = new AuthorizedClientRouter(this.API_ROOT, this.$http);
  public Auth = new AuthRouter(this.API_ROOT, this.$http);
  public AvailabilityCalendar = new AvailabilityCalendarRouter(this.API_ROOT, this.$http);
  public Conversations = new ConversationRouter(this.API_ROOT, this.$http);
  public BaseUsers = new BaseUserRouter(this.API_ROOT, this.$http);
  public Companies = new CompaniesRouter(this.API_ROOT, this.$http);
  public Drinks = require('./drinks')(this.API_ROOT, this.$http);
  public FeaturedVenues = new FeaturedVenuesRouter(this.API_ROOT, this.$http);
  public Landing = new LandingRouter(this.API_ROOT, this.$http);
  public Leads = require('./leads')(this.API_ROOT, this.$http);
  public Lists = new ListsRouter(this.API_ROOT, this.$http);
  public Locations = new LocationsRouter(this.API_ROOT, this.$http);
  public Menus = new MenuRouter(this.API_ROOT, this.$http);
  public Payment = new PaymentRouter(this.API_ROOT, this.$http);
  public Recos = new RecosRouter(this.API_ROOT, this.$http);
  public Requests = new Requests(this.API_ROOT, this.$http);
  public Scheduler = require('./scheduler')(this.API_ROOT, this.$http);
  public Search = require('./search')(this.API_ROOT, this.$http);
  public Venues = require('./venues')(this.API_ROOT, this.$http);
  public VenueQueries = new VenueQueryRouter(this.API_ROOT, this.$http);
  public VirtualExperience = new VirtualExperience(this.API_ROOT, this.$http);
  public MembershipAccount = new MembershipAccount(this.API_ROOT, this.$http);
}
