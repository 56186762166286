
/**
 * Reusable tertiary nav bar that emits state change events when links are clicked.
 *
 * Pass in the links you want to appear as an array in the parent controller.
 * Can also receive STATE_CHANGE events from parent controller
*/
module.exports = function() {
  return {
    template: require('./tertiary-nav.jade'),
    scope: {
      links: '='
    },
    link: function(scope, element, attrs) {
      scope.changeState = function(state) {
        scope.$emit('CHANGE_PARENT_STATE', {state: state});
        scope.currentState = state;
      };

      scope.$on('CHANGE_CHILD_STATE', function(event, args) {
        if (args) {
          scope.currentState = args.state
        }
      });

      scope.isActive = function(state) {
        return scope.currentState === state;
      };

      // set first link as state on load
      scope.changeState(scope.links[0].name);
    }
  }
};
