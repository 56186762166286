
export default ['ngDialog', function editListModal(ngDialog) {
  return function (list) {
    return ngDialog.open({
      template: '<edit-list list="$ctrl.list" close="$ctrl.close({ updatedList })"></edit-list>',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal edit-list-module',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.list = list;
        this.close = ({ updatedList }) => {
          $scope.closeThisDialog({ updatedList });
        };
      }]
    }).closePromise;
  };
}];
