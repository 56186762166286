// import { RecoRequestService } from '../reco-request/reco-request.service';
import get from 'lodash/get';

// External Dependencies

export default ['ngDialog', function(ngDialog) {
  return function (list) {
    let updatedList;
    return ngDialog.open({
      template: `<list-venue-search close="$ctrl.close()" update="$ctrl.setUpdate(update)" list="$ctrl.list"></list-venue-search>`,
      className: 'ngdialog-theme-plain',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function ($scope) {
        this.list = list;
        this.setUpdate = (update) => {
          updatedList = update;
        };
        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise.then(() => {
      return Promise.resolve({ list: updatedList });
    });
  };
}];
