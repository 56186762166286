import { queryHelpers } from '../../utils/query';
import { merge } from 'lodash';
import { DBookingRequest } from 'spc/lib/database/types/booking-request';
import { Payment } from 'spc/lib/server/api/payment/models';

interface InitPaymentQueryParams {
  getMethods: boolean;
  conversation: string;
}

export class PaymentRouter {
  constructor(private API_ROOT, private $http) {
    'ngInject';
  }

  initialize = (queryParams: InitPaymentQueryParams) => {
    const query = queryHelpers.makeQueryString(queryParams);
    const url = `${this.API_ROOT}/payment/initialize${query}`;
    return this.$http.get(url);
  }

  submit = (requestId, payment) => {
    return this.$http.post(this.API_ROOT + '/payment/' + requestId + '/deposit', payment);
  }

  balance = ({ requestId, payment, clone, nonce, token, manual, amountCents, extra }) => {
    const paymentId = payment._id ? payment._id.toString() : payment.toString();
    return this.$http.post(`${this.API_ROOT}/payment/${requestId}/balance`, { payment: paymentId, clone, nonce, token, manual, amountCents, options: payment, extra })
      .then(response => response.data);
  }

  intermediate = ({ payment, amountCents, request }: { payment: Payment, amountCents: number, request: DBookingRequest }) => {
    const requestId = request._id ? request._id.toString() : request.toString();
    return this.$http.post(`${this.API_ROOT}/payment/${requestId}/intermediate`, { payment, amountCents })
      .then(response => response.data);
  }

  validatePromoCode = ({ code, venueId }) => {
    return this.$http.post(`${this.API_ROOT}/payment/promo/validation`, { code, venueId })
      .then(response => response.data);
  }

  removeCard = (token) => {
    return this.$http.delete(this.API_ROOT + '/payment/method/' + token);
  }

  addMethod = (nonce, cardParams) => {
    const body = { nonce: nonce };
    merge(body, cardParams);
    return this.$http.post(this.API_ROOT + '/payment/method', body);
  }
}
