class TopLevelAdminUsersController {
  constructor(private $user, private $seo, private $location, private $rootScope) {
    'ngInject';
  }

  $onInit = () => {
    this.$user.$waitFor('LOGGED_IN', () => {
      if (!this.$user.isAdmin()) {
        return this.redirectTo('/404');
      }
      this.componentIsReady();
    });
    this.$user.$waitFor('NOT_LOGGED_IN', () => {
      this.redirectTo('/404');
    });
  }

  redirectTo = (url: string) => {
    return this.$location.path(url);
  }

  componentIsReady = () => {
    this.$seo.set('Users (Admin) Dashboard');
    this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
  }
}

export const TopLevelAdminUsersComponent = {
  controller: TopLevelAdminUsersController,
  template: '<admin-user-dashboard></admin-user-dashboard>'
};
