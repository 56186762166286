import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { RawCompany } from 'spc/lib/database/types/company';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { ApiService } from 'spc/shared/api/api.service';

class UserTransferController {
  users: RawBaseUser[];
  transferMap: { [key: string]: 'pending' | 'success' | 'failure' };
  company: RawCompany;
  destinationCompany: RawCompany;

  ui: {
    companyName: string,
    companyOptions?: RawCompany[],
    transferInProgress?: boolean,
    userCountMap?: { [id: string]: number };
  } = { companyName: null };

  close: () => void;
  constructor(private $api: ApiService, private $clickOk, private unwrapError, private $q) {
    'ngInject';
  }

  $onInit = () => {
    this.createTransferMap();
  }

  transferUsers = () => {
    const showCancel = true;
    this.ui.transferInProgress = true;
    return this.$clickOk('All users will now be transferred to the new company.', showCancel)
      .then((response) => {
        if (get(response, 'value.cancel')) {
          return;
        }
        return this.$q.all(this.users.map(this.transferUser));
      })
      .then(() => {
        this.close();
      })
      .catch((error) => {
        this.ui.transferInProgress = false;
        this.unwrapError(error);
      });

  }

  searchCompanies = () => {
    if (!this.ui.companyName || this.ui.companyName.length < 3) {
      return;
    }

    return this.$api.Admin.Companies.search({ name: this.ui.companyName })
      .then((data) => {
        this.ui.companyOptions = data.companies;
        this.ui.userCountMap = data.userCountMap;
      })
      .catch(error => this.unwrapError(error));
  }

  debouncedSearchCompanies = debounce(this.searchCompanies, 300);

  selectCompany = (company) => {
    this.destinationCompany = company;
    this.ui = { companyName : null };
  }

  transferUser = (user: RawBaseUser) => {
    return this.$api.Admin.Users
      .update({ _id: user._id, company: this.destinationCompany._id })
      .then(() => {
        this.transferMap[user._id.toString()] = 'success';
        return user;
      })
      .catch((error) => {
        this.transferMap[user._id.toString()] = 'failure';
        throw error;
      });
  }

  createTransferMap = () => {
    this.transferMap = {};
    this.users.forEach(user => this.transferMap[user._id.toString()] = 'pending');
  }

}

export const userTransferComponent = {
  template: require('./user-transfer.component.jade'),
  controller: UserTransferController,
  bindings: {
    users: '<',
    company: '<',
    close: '<'
  }
};
