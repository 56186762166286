export const organiseExperienceModal = ['ngDialog', function(ngDialog) {
  return function () {
    return ngDialog.open({
      template: `<organise-experience-component close="$ctrl.close"> </organise-experience-component>`,
      plain: true,
      className: 'ngdialog-theme-plain',
      overlay: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function($scope) {
        this.close = (response) => {
          $scope.closeThisDialog(response);
        };
      }]
    }).closePromise;
  };
}];
