import BookingRequestHelpers from 'common/dist/virtuals/BookingRequest';
// Lodash
import { isNumber, map } from 'lodash';

/**
 * Presentational component that allows a user to select a start and end time, which also
 * selects the timeslot for a request
 */
class SelectTimeHostOutsidePlatformComponent {
  constructor(SelectTimeHostService, RequestTimeFactory) {
    'ngInject';
    this.selectTimeHostService = SelectTimeHostService;
    this.requestTimeFactory = RequestTimeFactory;
  }

  $onInit() {
    const ctrl = this;
    ctrl.state = { addingCustomTimeSlot: false };
    ctrl.loadSlots();
  }

  loadSlots() {
    const ctrl = this;
    if (!ctrl.request.selectedSpace) {
      throw new Error('No selected space');
    }
    ctrl.setTimeSlot(ctrl.request);
    ctrl.timeSlots = map(ctrl.request.selectedSpace.data.timeSlots, slot => {
      slot.isSelected = ctrl.selectedSlot &&
        ctrl.selectedSlot._id.toString() === slot._id.toString();
      slot.times = ctrl.selectTimeHostService.getTimes(ctrl.request, slot);
      return slot;
    });
  }

  /**
   * @desc Sets `$scope.selectedSlot` based on a request's selected time slot
   *
   * @public
   * @param {Request}
   * @sets `ctrl.selectedSlot`
   * @return {Void}
   */
  setTimeSlot(request) {
    const ctrl = this;
    const slotIdx = request.data.timeSlot;
    if (isNumber(slotIdx) && slotIdx !== -1) {
      ctrl.selectedSlot = BookingRequestHelpers.selectedTimeSlot(request);
    }
  }

  /**
   * @desc Determine if a time is between the start and end time in a given slot
   *
   * @public
   * @param {Time} time
   * @param {Slot} slot
   * @return {Boolean}
   */
  isBetweenTimes(time, slot) {
    const ctrl = this;
    return ctrl.requestTimeFactory.isBetweenTimes(ctrl.request, time, slot);
  }

  /**
   * Selects a given time for a time slot, taking care not to mutate the original request inside ctrl component
   * 
   * @public
   * @param {Object} time
   * @return {Void}
   */
  selectTime(time, slot) {
    const ctrl = this;
    ctrl.pickTime({ timeSlots: ctrl.timeSlots, time, slot });
  }

  highlightTime(time, slot) {
    const ctrl = this;
    return ctrl.selectTimeHostService.highlightTime(ctrl.request, time, slot);
  }

  unhighlightTime(time, slot) {
    const ctrl = this;
    return ctrl.selectTimeHostService.unhighlightTime(ctrl.request, time, slot);
  }
}

export default {
  bindToController: true,
  bindings: {
    pickTime: '&',
    request: '<'
  },
  template: require('./select-time-host-outside-platform.jade'),
  controller: SelectTimeHostOutsidePlatformComponent
};
