import match from '../../utils/match';
import { get, filter, toLower, findIndex, find } from 'lodash';

export default ['$api', 'unwrapError', '$venueValidator', '$clickOk', function($api, unwrapError, $venueValidator, $clickOk) {
  const ret = {
    deleteDoc,
    deleteDrink,
    deleteMenu,
    deleteSpace,
    _cancelBecauseLastVisible,
    _confirmDeleteDoc
  };

  return ret;

  function _confirmDeleteDoc(doc) {
    const message = `Are you sure you want to delete this ${toLower(doc.schemaType)}? There's no going back.`;

    return $clickOk(message, true);
  }

  /**
   * Fire off HTTP request to delete a document, based on its `schemaType`
   *
   * @api public
   * @param {Venue} venue
   * @param {Doc} doc
   * @param {Array} docs, list of the rest of docs to see if this is the last one being marked as not visible.
   * @return {Promise}
   */
  function deleteDoc(venue, doc, docs) {
    if (doc.schemaType === 'Venue') {
      throw new Error('Venues cannot be deleted at this time');
    }

    if (venue.isVisible && doc.schemaType !== 'Drink') {
      const numVisibleDocs = filter(docs, d => d.isVisible).length;
      if (numVisibleDocs === 1) {
        const visibleDoc = find(docs, d => d.isVisible);
        if (doc._id.toString() === visibleDoc._id.toString()) {
          return ret._cancelBecauseLastVisible(doc);
        }
      }
    }
    return ret._confirmDeleteDoc(doc)
      .then(function(data) {
        if (get(data, 'value.cancel')) {
          return { cancel: true };
        }

        return match(doc.schemaType, {
          Menu: () => ret.deleteMenu(doc),
          Space: () => ret.deleteSpace(venue, doc),
          Drink: () => ret.deleteDrink(doc)
        });
      });
  }

  /**
   * Delete a space by marking it as isDeleted: true
   *
   * @param {Venue} venue
   * @param {Space}
   * @return {Promise}
   */
  function deleteSpace(venue, space) {
    return $api.Venues.Spaces.delete(venue.slug, space)
      .then(function(response) {
        const spaces = get(response, 'data.spaces');
        venue.data.spaces = spaces;
      });
  }

  /**
   * Delete a menu by marking it as isDeleted: true
   *
   * @param {Menu}
   * @return {Promise}
   */
  function deleteMenu(menu) {
    return $api.Menus.delete(menu);
  }

  /**
   * Delete a drink by marking it as isDeleted: true
   *
   * @param {Drink}
   * @return {Promise}
   */
  function deleteDrink(drink) {
    return $api.Drinks.delete(drink);
  }

  /**
   * Informs user that they must have at least one visible item and
   * returns a promise that resolves with the value { cancel: true }
   *
   * @param {Doc} doc
   * @return {Promise}
   */
  function _cancelBecauseLastVisible(doc) {
    const item = toLower(doc.schemaType);
    return $clickOk(`A published venue must have at least one ${item}.`).then(function() { return { cancel: true }; });
  }
}];
