// NPM Dependencies

import VenueHelpers from 'common/dist/virtuals/Venue';
import ENUMS from 'common/dist/enums';
import { ApiService } from '../../shared/api/api.service';

// SixPlus Dependencies (CommonJS)
const isMongooseDoc = require('../../../../shared/isMongooseDoc');

export default function() {
  return {
    template: require('./request-venue-contact-details.jade'),
    scope: {
      venueParent: '=venue',
      request: '=request'
    },
    controller: ['$scope', '$user', '$api', 'unwrapError', function($scope, $user, $api: ApiService, unwrapError) {
      init();

      function getAuthorizedUsersToDisplay (venue) {
        return $api.Venues.getAuthorizedUsers(venue._id)
          .then(data => data.authorizedUsersToDisplay)
          .catch(error => unwrapError(error));
      }
      // Initialization Function
      function init(v?) {
        const venue = v || $scope.venueParent;
        getAuthorizedUsersToDisplay(venue)
          .then(authUsers => $scope.authorizedUsers = authUsers);

        $scope.displayEmail = () => {
          if ($user.isAdmin() || $user.isHost()) {
            return true;
          }
          return !ENUMS.bookingRequestState.preBookedStates.includes($scope.request.state);
        };
      }
    }]
  };
}
