export default ['ngDialog', function archiveEventModal(ngDialog) {
  return function (lead) {
    return ngDialog.open({
      template: '<archive-event close="$ctrl.close({isArchived, updatedLead})" lead="$ctrl.lead"></archive-event>',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.lead = lead;
        this.close = ({ isArchived, updatedLead }) => {
          $scope.closeThisDialog({ isArchived, updatedLead });
        };
      }]
    }).closePromise;
  };
}];

