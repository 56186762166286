
class AdminSettingsController {
  SEARCH_CITIES = this.ENUMS.acceptableUserCities;
  constructor(
    private $seo,
    private $rootScope,
    private AdminService,
    private $location,
    private $user,
    private ENUMS,
    private editFeaturedVenuesModal,
    private organiseExperienceModal,
    private unwrapError
  ) {
    'ngInject';
    this.$user.allowOnly('Admin', this.componentIsReady);
  }

  componentIsReady = () => {
    this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
  }

  openFeaturedVenueModal = (city) => {
    this.editFeaturedVenuesModal(city);
  }

  organiseExperience = () => {
    return this.organiseExperienceModal();
  }
}

export const AdminSettingsComponent = {
  template: require('./admin-settings.component.jade'),
  controller: AdminSettingsController
};

