import { GetConnectionsResponseBody, GetReviewsResponseBody, GetVenueHistoryResponseBody, CreateNoteResponseBody, UpdateNoteResponseBody } from 'server/api/base-user/models';

import { Note } from 'lib/database/types/venue-note';

interface User {
  profile: {
    city: String;
    name: {
      first: string,
      last: string;
    },
    email: string
  };
}

export class BaseUserRouter {
  BASE_URL: string;
  constructor (private API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${ API_ROOT }/baseuser`;
  }
  create = (user: User, options?): ng.IPromise<any> => {
    return this.$http.post(this.BASE_URL, { user, options })
      .then(response => response.data);
  }

  get = (user: string): ng.IPromise<any> => {
    return this.$http.get(`${this.BASE_URL}/${user}`)
      .then(response => response.data);
  }

  reviews = (): ng.IPromise<GetReviewsResponseBody> => {
    return this.$http.get(`${this.BASE_URL}/reviews`)
      .then(response => response.data as GetReviewsResponseBody);
  }

  teamReviews = (): ng.IPromise<GetReviewsResponseBody> => {
    return this.$http.get(`${this.BASE_URL}/teamReviews`)
      .then(response => response.data as GetReviewsResponseBody);
  }

  getConnections = (userId: string): ng.IPromise<GetConnectionsResponseBody> => {
    return this.$http.get(`${this.BASE_URL}/${userId}/connections`)
      .then(res => res.data as GetConnectionsResponseBody);
  }

  getVenueHistory = (venueId: string): ng.IPromise<GetVenueHistoryResponseBody> => {
    return this.$http.get(`${this.BASE_URL}/history/${venueId}`)
      .then(res => res.data as GetVenueHistoryResponseBody);
  }

  createVenueNote = ({ venueId, note, listId }: { venueId: string, note: Note, listId: string }): ng.IPromise<CreateNoteResponseBody> => {
    return this.$http.put(`${this.BASE_URL}/notes/${venueId}`, { note , listId })
      .then(res => res.data as CreateNoteResponseBody);
  }

  updateVenueNote = ({ venueId, noteId, message, listId }: { venueId: string, noteId: string, message: string, listId: string }): ng.IPromise<UpdateNoteResponseBody>  => {
    return this.$http.put(`${this.BASE_URL}/note/${venueId}/${noteId}`, { message, listId })
      .then(res => res.data as UpdateNoteResponseBody);
  }

  searchByEmail = (email: string): ng.IPromise<GetConnectionsResponseBody> => {
    return this.$http.get(`${this.BASE_URL}/search/${email}`)
      .then(res => res.data as GetConnectionsResponseBody);
  }
}
