import { find, get, keys, set, some, startsWith, cloneDeep } from 'lodash';
import { DrinkRequest } from 'lib/database/types/booking-request';
import DrinkRequestHelpers from 'common/dist/virtuals/DrinkRequest';

// Constants
const MINIMUM_DRINK_DURATION = 30;

module.exports = function() {
  return {
    template: require('./drink-counteroffer.jade'),
    controller: ['$scope', '$counteroffer', '$q', 'unwrapError', 'ENUMS', function($scope, $counteroffer, $q, unwrapError, ENUMS) {
      // Constants
      $scope.MINIMUM_DRINK_DURATION = MINIMUM_DRINK_DURATION;

      $scope.canSaveDrink = canSaveDrink;
      // fns to handle drink state
      $scope.isNewDrink = isNewDrink;
      $scope.isEditingDrink = isEditingDrink;
      $scope.alreadySelectedDrink = alreadySelectedDrink;
      $scope.allowDrinks = ENUMS.bookingRequestState.activeStates.filter(state => state !== 'RECONCILED');
      // these update `$scope.counterClone` and `$scope.clone`
      $scope.handleDuration = handleDuration;
      $scope.setDrink = setDrink;
      $scope.startDrinkEdit = startDrinkEdit;
      $scope.addDrinkDeltas = addDrinkDeltas;
      $scope.setDrinkPrice = setDrinkPrice;
      $scope.addNewDrink = addNewDrink;
      $scope.deleteDrink = deleteDrink;

      // Functions

      /**
       * Determine if a drink is "new" in the eyes of the perso nviewing the page
       * @param {String} path
       * @return {Boolean}
       */
      function isNewDrink(path: string): boolean {
        if (!$scope.delta) {
          return;
        }

        const paths = keys($scope.delta);

        return some(paths, p => startsWith(p, path));
      }

      function setDrink(drink, $index) {
        $scope._drink = drink.$fake
          ? null
          : find($scope.drinks, d => d._id.toString() === get(drink, '_id', '').toString());
        const clone = $scope.counterClone.data.drinks[$index];
        if (!drink.$fake && clone && clone._id.toString() === drink._id.toString()) {
          return;
        }
        let pkg: DrinkRequest;
        if (drink.$fake) {
          pkg = $counteroffer.drinks.initCustomHourlyPackage($scope.counterClone, clone);
        } else {
          DrinkRequestHelpers.initFromDrink(pkg, drink, clone.selectedDurationMinutes, clone.data.numGuests);
        }
        $scope.counterClone.data.drinks[$index] = pkg;
        $scope._costPerGuestDollars = (pkg.costPerGuestCents / 100) || null;
        $scope.setDrinkPrice($index, $scope._costPerGuestDollars);
      }

      function startDrinkEdit(path: string): void {
        $scope.startEdit(path);

        const drink: DrinkRequest = get($scope.counterClone, path);

        $scope._costPerGuestDollars = drink.costPerGuestCents / 100 || null;
        $scope._drink = find($scope.drinks, d => d.data.name === drink.name);
      }

      function canSaveDrink(idx: number) {
        const clone = get($scope.counterClone, `data.drinks`, [])[idx];
        return validateDrinks(clone);
      }

      function validateDrinks(bevs) {
        $scope.guaranteedGuestsSize = parseInt($scope.clone.data.groupSize, 10);
        const canPay = bevs.costPerGuestCents >= 0 || bevs.payAtTheVenue;
        return isFinite(bevs.duration) && bevs.duration > 0 && canPay && bevs.numGuests <= $scope.guaranteedGuestsSize;
      }

      /**
       * @mutates `$scope.counterClone.data.drinks[$index]`
       */

      function handleDuration(durationMinutes, multiplier, $index) {
        const drink =
          get($scope.counterClone, `data.drinks.${ $index }`) as DrinkRequest;

        if (!drink) {
          return;
        }

        durationMinutes = parseInt(durationMinutes, 10);
        const changedDurationMinutes = durationMinutes + (multiplier * 30);
        if (changedDurationMinutes < MINIMUM_DRINK_DURATION || changedDurationMinutes > $scope.clone.data.duration) {
          return;
        }
        set(drink, 'selectedDurationMinutes', changedDurationMinutes);

        DrinkRequestHelpers.computeCostPerGuestCents(drink);

        drink.costPerGuestDollars = drink.costPerGuestCents / 100;
        $scope._costPerGuestDollars = drink.costPerGuestDollars || drink.costPerGuestCents / 100 || null;

      }

      function addDrinkDeltas($index) {
        const drinkPath = 'data.drinks.' + $index;
        const deltaValue = get($scope.counterClone, drinkPath);

        $scope.addDelta(drinkPath, deltaValue).
          then(function() {
            setToFalse(drinkPath);
            $scope.error = '';
          }).
          catch(function(error) {
            $scope.error = get(error, 'data.error.message');
            $scope.loading[drinkPath] = false;
            unwrapError(error);
          });

        function setToFalse(path) {
          $scope.loading[path] = false;
          $scope.editing[path] = false;
          $scope.creatingNewDrink = false;
        }
      }

      /**
       * set drink costPerGuestCents from costPerGuestDollars
       * @mutates `$scope.counterClone.data.drinks[$index]`
       */

      function setDrinkPrice($index, dollars) {
        dollars = Number(dollars);
        const path = `data.drinks.${ $index }`;
        const clone = get($scope.counterClone, path) as DrinkRequest;

        if (!dollars) {
          clone.costPerGuestCents = undefined;
        } else {
          clone.costPerGuestCents = +(parseFloat(dollars) * 100).toFixed(0);
        }
      }

      function isEditingDrink(editing) {
        const paths = keys(editing);

        return some(paths,
          path => startsWith(path, 'data.drinks') && editing[path]);
      }

      function addNewDrink(newDrink) {
        // if theres no drink or it was already selected, just return
        if (!newDrink || alreadySelectedDrink(get(newDrink, 'data.name'))) {
          return;
        }

        // handle presentation state
        $scope.creatingNewDrink = true;
        let drinkRequest;
        if (newDrink.$fake) {
          drinkRequest = $counteroffer.drinks.initCustomHourlyPackage($scope.clone, drinkRequest);
        } else {
          drinkRequest = {};
          DrinkRequestHelpers.initFromDrink(drinkRequest, newDrink, $scope.clone.data.duration, $scope.clone.data.groupSize);
        }

        $scope.counterClone.data.drinks.push(drinkRequest);
        const idx = $scope.counterClone.data.drinks.length - 1;
        const path = `data.drinks.${ idx }`;
        startDrinkEdit(path);
      }

      /**
       * Delete drink
       *
       * @mutates `$scope.counterClone`
       */

      function deleteDrink(path: string) {
        $scope.addDelta(path, null, true).
          then(function() {
            $scope.error = null;
            $scope.creatingNewDrink = false;
          }).
          catch(unwrapError);
      }

      function alreadySelectedDrink(name: string): boolean {
        if (!$scope.drinks || !$scope.counterClone) {
          return;
        }
        return some($scope.counterClone.data.drinks as DrinkRequest[],
          drink => drink && !drink.isDeleted && !drink.isCustom && drink.name === name);
      }

    }]
  };
};
