export const editMenusModal = ['ngDialog', function (ngDialog) {
  return function (scope) {
    return ngDialog.open({
      template: '<edit-menus counter-clone="$ctrl.counterClone" menus="$ctrl.menus" set-menu="$ctrl.setMenu" close="$ctrl.close" add-menu-delta="$ctrl.addMenuDelta" ></edit-menus>',
      className: 'ngdialog-theme-small modal-venue-conversation',
      overlay: true,
      plain: true,
      scope: scope,
      controllerAs: '$ctrl',
      controller: ['$scope', function ($scope) {
        this.counterClone = scope.counterClone;
        this.menus = scope.menus;
        this.setMenu = scope.setMenu;
        this.addMenuDelta = scope.addMenuDelta;
        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];
