import { StateEmitter } from '../utils/StateEmitter';

exports.$controller = function($scope, $routeParams, $user) {
  $scope.conversation = $routeParams.id;

  $scope.stateCtrl = new StateEmitter(['LOADING', 'LOADED'], 'LOADING');

  $scope.$emit('$viewReady', 'SHOW_FOOTER');
};

exports.$inject = ['$scope', '$routeParams', '$user'];
