// Constants
const LOADING_SPINNER_SELECTOR = '.loading-spinner-image'; // The selector for the spinner div
const CONTENT_SELECTOR = '.loading-spinner-container'; // the selector for the content div
const HIDDEN_CLASS = 'spinner-hidden';
let ctrl;
class StatefulLoadingScreenComponent {
  constructor($element, private unwrapError) {
    'ngInject';
    ctrl = this;
    ctrl.$element = $element;
  }

  $onInit() {
    if (!ctrl.stateEmitter) {
      this.unwrapError(new Error('No state emitter passed into loading screen'));
    }
  }

  $postLink() {
    if (ctrl.stateEmitter.$state() === ctrl.loadedState) {
      ctrl.showView();
    } else if (ctrl.stateEmitter.$state() === ctrl.loadingState) {
      ctrl.hideView();
    }

    ctrl.stateEmitter.on(ctrl.loadedState, () => {
      ctrl.showView();
    });
    ctrl.stateEmitter.on(ctrl.loadingState, () => {
      ctrl.hideView();
    });
  }

  $onDestroy() {
    ctrl.stateEmitter._emitter.removeListener(ctrl.loadedState, ctrl.showView);
    ctrl.stateEmitter._emitter.removeListener(ctrl.loadingState, ctrl.hideView);
  }

  /**
   * Shows the spinner and hides the transcluded content inside the container
   *
   * @public
   * @return {Void}
   */
  hideView() {
    ctrl.$element
      .find(LOADING_SPINNER_SELECTOR)
      .show()
      .removeClass(HIDDEN_CLASS);
    ctrl.$element
      .find(CONTENT_SELECTOR)
      .hide()
      .addClass(HIDDEN_CLASS);
  }

  showView() {
    ctrl.$element
      .find(LOADING_SPINNER_SELECTOR)
      .hide()
      .addClass(HIDDEN_CLASS);
    ctrl.$element
      .find(CONTENT_SELECTOR)
      .fadeIn({ duration: 500 })
      .removeClass(HIDDEN_CLASS);
  }
}

export default {
  template: require('./stateful-loading-screen.jade'),
  transclude: true,
  bindToController: true,
  bindings: {
    stateEmitter: '<',
    loadedState: '@',
    loadingState: '@'
  },
  controller: StatefulLoadingScreenComponent
};
