import map from 'lodash/map';
import each from 'lodash/each';

const DEFAULT_STYLES = [
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }]
  }
];


export class RecosMapService {
  google: any;
  map: any;
  markers: any;
  infoWindow: any;
  selectedVenueId: string;
  clicked: boolean;

  constructor(private $window, private $scrollService) {
    'ngInject';
    this.google = this.$window.google;
  }

  init(element, config) {
    this.map = new this.google.maps.Map(element, config);
    this.map.setOptions({ styles: DEFAULT_STYLES });
  }

  drawMarkers = ({ recos, scope }) => {
    each(this.markers, m => m.setMap(null));
    const bounds = new this.google.maps.LatLngBounds();
    const seenVenue = {};
    const squashedRecos = recos.reduce(function (allRecos, currentReco, currentIndex) {
      if (seenVenue[currentReco.venue._id] !== undefined) {
        const thisVenue = allRecos[seenVenue[currentReco.venue._id]];
        thisVenue.allSpaces += `<span4><span2><bubble>${currentIndex + 1}</bubble></span2><span3>${currentReco.space.data.name} <span>${currentReco.space.data.privacy} ${currentReco.space.data.spaceType}</span><br><label>${currentReco.space.data.capacity.seated.max ? 'Seated' : ''}</label> <span1>${currentReco.space.data.capacity.seated.max ? currentReco.space.data.capacity.seated.max : ''}</span1>&nbsp;&nbsp;<label>${currentReco.space.data.capacity.standing.max ? 'Standing' : ''}</label> ${currentReco.space.data.capacity.standing.max ? currentReco.space.data.capacity.standing.max : ''}</span1></span3></span4>`;
      } else {
        seenVenue[currentReco.venue._id] = allRecos.length;
        currentReco.allSpaces = `<span4><span2><bubble>${currentIndex + 1}</bubble></span2><span3>${currentReco.space.data.name} <span>${currentReco.space.data.privacy} ${currentReco.space.data.spaceType}</span><br><label>${currentReco.space.data.capacity.seated.max ? 'Seated' : ''}</label> <span1>${currentReco.space.data.capacity.seated.max ? currentReco.space.data.capacity.seated.max : ''}</span1>&nbsp;&nbsp;<label>${currentReco.space.data.capacity.seated.max ? 'Standing' : ''}</label> <span1>${currentReco.space.data.capacity.standing.max ? currentReco.space.data.capacity.standing.max : ''}</span1></span3></span4>`;
        allRecos.push(currentReco);
      }
      return allRecos;
    }, []);
    this.markers = [];
    this.markers = map(squashedRecos, (reco: { _id: string; venue: { _id: string, data: any } }) => {
      const point = new this.google.maps.LatLng(reco.venue.data.address.coordinates[1], reco.venue.data.address.coordinates[0]);
      const marker = new this.google.maps.Marker({
        map: this.map,
        position: point,
        icon: '//s3.amazonaws.com/sixplus-assets/images/map-marker.png',
        _id: reco.venue._id,
        recoId: reco._id,
      });

      bounds.extend(marker.position);

      this.google.maps.event.addListener(marker, 'click', (ev) => {
        this.clicked = true;
        this.selectedVenueId = reco.venue._id;
        if ($(window).width() > 990) {
          this.$scrollService(`#${reco._id}`, 0);
        }
        this.setInfoWindow(reco, marker, scope, 'click');
        scope.$apply();
      });

      this.google.maps.event.addListener(marker, 'mouseover', (ev) => {
        if (!this.clicked) {
          this.selectedVenueId = reco.venue._id;
          this.setInfoWindow(reco, marker, scope, 'mouseover');
          scope.$apply();
        }
      });

      this.google.maps.event.addListener(marker, 'mouseout', (ev) => {
        if (!this.clicked) {
          this.selectedVenueId = null;
          this.infoWindow.close();
          scope.$apply();
        }
      });

      this.google.maps.event.addListener(marker, 'cardClick', (ev) => {
        this.clicked = true;
        this.selectedVenueId = reco.venue._id;
        this.setInfoWindow(reco, marker, scope, 'click');
      });

      return marker;

    });

    this.map.fitBounds(bounds);
  }

  setInfoWindow = (reco, marker, scope, event) => {
    if (this.infoWindow) {
      this.infoWindow.close();
    }

    const contentString = `
    <div class="reco-map-pin-box">
      <h4>${reco.venue.data.name}</h4>
      <h5>${reco.venue.data.address.line1}</h5>
      <h5><span>${reco.venue.data.address.neighborhood}</span> | ${reco.venue.data.cuisineTypes.join(', ')}</h5>
      <div>${reco.venue.admin.ambiance}</div>
      <hr>
      <h6>Spaces</h6>
      <p>${reco.allSpaces}</p>
    </div>`;
    const disableAutoPan = event === 'click' ? false : true;
    this.infoWindow = new this.google.maps.InfoWindow({ content: contentString, disableAutoPan: disableAutoPan });

    this.google.maps.event.addListener(this.infoWindow, 'closeclick', (ev) => {
      this.selectedVenueId = null;
      this.clicked = false;
      scope.$apply();
    });

    this.infoWindow.open(this.map, marker);
  }

  selectMarker(venueId) {
    this.selectedVenueId = venueId;
    const selectedMarker = this.markers.find(marker => marker._id === venueId);
    this.google.maps.event.trigger(selectedMarker, 'cardClick');
  }
}
