import * as angular from 'angular';
import { topLevelPremiumPageComponent } from './top-level-premium-page.component';
import { premiumPageComponent } from './premium-page.component';
import { premiumPageSubscriptionCardComponent } from './premium-page-subscription-cards.component';
import demoVideoModal from './demo-video/premium-demo-video.modal';
import { SubscriptionCardServices } from '../../typescript/utils/subscriptionCardServices';
import { requstProAccountModalComponent } from './request-pro-account-modal.component';


export const PremiumPageModule = angular.module('premiumPageModule', [])
  .component('topLevelPremiumPage', topLevelPremiumPageComponent )
  .component('premiumPage', premiumPageComponent )
  .component('premiumPageSubscriptionCards', premiumPageSubscriptionCardComponent )
  .component('requestProAccountModal', requstProAccountModalComponent)
  .service('demoVideoModal', demoVideoModal)
  .service('subscriptionCardServices', SubscriptionCardServices)
  .name;
