import { ApiService } from 'spc/shared/api/api.service';
import { cloneDeep, get  } from 'lodash';

import { RawList } from 'spc/lib/database/types/list';
import { RawListVenue } from 'spc/lib/database/types/list-venue';
import ambianceStyleService from '../../services/ambianceStyleService';

class ListOrderController {
    list: RawList[];
    venues: RawListVenue[];
    close: () => void;

    constructor(
        private $api: ApiService,
        private unwrapError,
        private $scope,
        private $cloudinary
    ) {
        'ngInject';
        this.$scope.$cloudinary = this.$cloudinary;
    }

    $onInit = () => {
        this.$scope.list = cloneDeep(this.list);
        this.$scope.venueList = cloneDeep(this.venues);
        this.$scope.ambianceClass = this.ambianceClass;
    }

    ambianceClass(venue) {
        const ambiance = get(venue.venue, 'admin.ambiance');
        return ambianceStyleService(ambiance);
    }


    saveOrder = () => {
        const listId = this.$scope.list._id;
        const update = this.$scope.venueList
            .map((venue, idx) => ({ _id: venue._id, order: idx + 1 }));

        return this.$api.Lists.updateListOrder({ listId, update })
            .then(() => this.close())
            .catch(err => this.unwrapError(err));
    }

}

export const ListOrderComponent = {
    controller: ListOrderController,
    controllerAs: '$ctrl',
    template: require('./list-order-component.jade'),
    bindings: {
        list: '<',
        venues: '<',
        close: '<'
    }
};
