import DeleteFactory from './DeleteFactory';
import VisibilityFactory from './VisibilityFactory';
import DocFactory from './DocFactory';
import PathValidatorFactory from './PathValidatorFactory';

export default {
  DeleteFactory,
  DocFactory,
  PathValidatorFactory,
  VisibilityFactory
};