import { GetLocationsResponse, GetNeighborhoodsResponse, CreateNeighborhoodResponse } from 'lib/server/api/locations/models';


export class LocationsRouter {
  BASE_URL: string;
  constructor(private API_ROOT: string, private $http: ng.IHttpService) {
    this.BASE_URL = `${API_ROOT}/locations`;
  }

  public get = (params?: { topLevelOnly: true }): ng.IPromise<GetLocationsResponse> => {
    if (params && params.topLevelOnly) {
       return this.$http.get(`${this.BASE_URL}?topLevelOnly=true`)
      .then((res: { data: GetLocationsResponse }) => res.data);
    } else {
      return this.$http.get(this.BASE_URL)
        .then((res: { data: GetLocationsResponse }) => res.data);
    }
  }

  public getNeighborhoods = ({ city, state, country }): ng.IPromise<GetNeighborhoodsResponse> => {
    return this.$http.get(`${this.BASE_URL}/neighborhoods`, { params: { city, state, country } })
      .then((res: { data: GetNeighborhoodsResponse }) => res.data);
  }

  public createNeighborhood = ({ city, state, country, neighborhood }): ng.IPromise<CreateNeighborhoodResponse> => {
    return this.$http.post(`${this.BASE_URL}/neighborhood`, { city, state, country, neighborhood })
      .then((res: { data: CreateNeighborhoodResponse }) => res.data);
  }

  public fetchParentCity = ({ city, state, country }) => {
    return this.$http.get(`${this.BASE_URL}/parentCity`, { params: { city, state, country } })
      .then((res => res.data));
  }
}
