import cloneDeep from 'lodash/cloneDeep';
import { RawConversation } from 'spc/lib/database/types/conversation';

export class AttachmentModalServices {
    attachments: any = [];
    ui: {
        canSubmit?: boolean;
        displayedAttachments?: any;
        buttonText?: string;
    } = {};
    conversation: RawConversation;
    constructor() {
        'ngInject';
    }

    public cloneDeepAttachements = () => {
        this.attachments = this.attachments.map((attachment: any) => cloneDeep(attachment));
    }

    public setDisplayedAttachments = (attachments) => {
        this.ui.displayedAttachments = attachments.filter(attachment => !attachment.isDeleted && attachment.title !== 'Link');
        return this.ui.displayedAttachments;
    }

    public setIsNewFalse = () => {
        this.ui.displayedAttachments = this.attachments.map(attachment => Object.assign(attachment, { isNew: false }));
    }

    public setCanSubmit = () => {
        this.ui.canSubmit =  this.conversation
            ? this.attachments.every((attachment: any) => attachment.file.title)
            : this.attachments.every((attachment: any) => attachment.title);
    }

    public setAttachments = (attachments, $scope?, conversation?, request?) => {
        this.attachments = attachments;
        this.conversation = conversation;
        this.cloneDeepAttachements();
        this.setDisplayedAttachments(attachments);
        this.setIsNewFalse();
        this.setCanSubmit();

        $scope.$on('FILE_UPLOADING', () => {
            this.ui.buttonText = 'Uploading...';
          });
        $scope.$on('FILE_UPLOADED', () => {
            this.ui.buttonText = `I'm finished`;
            this.setCanSubmit();
        });
        if (request) {
            this.ui.buttonText = 'SEND TO CLIENT';
            $scope.$on('FILE_UPLOADED', () => {
                this.ui.buttonText = `SEND TO CLIENT`;
                this.setCanSubmit();
            });
        } else {
            this.ui.buttonText = `I'm done`;
        }

        return {
            attachments: this.attachments,
            ui: this.ui
        };
    }
}
