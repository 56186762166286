import * as angular from 'angular';

const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-73132200-1';
const GOOGLE_ANALYTICS_4_TRACKING_ID = 'G-3ZLCVTLDLL';
const DEV_HEAP_TRACKING_ID = '3506487129';
const STAGING_HEAP_TRACKING_ID = '3275754183';
const DEV_MIXPANEL_TRACKING_ID = '1e75bc4b8cf80828a5e793379b1342f5';
const STAGING_MIXPANEL_TRACKING_ID = 'f7215fe17822e3b04bf2bf2889445268';

const DEV_FACEBOOK_APPID = '384840008306264';
const STAGING_FACEBOOK_APPID = '384842951639303';
const ZOPIM_KEY = '3jLaP1K1J2eIRZmRQY0efcCLyxDkhylh';
const VWO_ACCOUNT_ID = '270714';

const m = angular.module('sixplus.config', []);

m.factory('$config', function($window) {
  const isDev = document.location.hostname === 'localhost';
  return {
    facebook: {
      appId: isDev ? DEV_FACEBOOK_APPID : STAGING_FACEBOOK_APPID
    },
    googleAnalytics: {
      trackingId: GOOGLE_ANALYTICS_TRACKING_ID
    },
    googleMaps: {
      apiKey: 'AIzaSyBAsO6OQHRI8_dkVDvKYxlEYPYtHy_sMGs'
    },
    heap: {
      trackingId: isDev ? DEV_HEAP_TRACKING_ID : STAGING_HEAP_TRACKING_ID
    },
    linkedin: {
      clientId: '77x560ijviy8xz'
    },
    mixpanel: {
      trackingId: isDev ? DEV_MIXPANEL_TRACKING_ID : STAGING_MIXPANEL_TRACKING_ID
    },
    zopim: {
      key: ZOPIM_KEY
    },
    vwo: {
      accountId: VWO_ACCOUNT_ID
    },
    fullstory: {
      org: null // intentionally do not set for dev
    },
    pardot: {
      piAId: null,
      piCId: null,
      piHostname: null,
      userFormHandler: 'https://google.com',
      emailFormHandler: 'https://google.com'
    },
    googleAnalytics4TrackingId: {
      trackingId: GOOGLE_ANALYTICS_4_TRACKING_ID
    },
    basePath: {
      url: getbasepath(isDev)
    }
  };
});

const getbasepath = (isDev) => {
  if (isDev) {
    return 'http://localhost:3000';
  }
  return 'https://api.sixplusdemo.com';
};

module.exports = 'sixplus.config';
