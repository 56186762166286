import { DAccount } from 'spc/lib/database/types/accounts';
import { RawBaseUser } from 'spc/lib/database/types/base-user';

const MANAGE_ALL = 'Manage All';

interface Member {
  id: string;
  // other properties...
}

class ManageAccountMembersController {
  user: RawBaseUser;
  account: DAccount;
  members: any[] = [];
  view: 'Manage All';
  selectedUser: string;
  close: (revised: DAccount) => void;
  constructor(private $api, private unwrapError) {
    'ngInject';
  }

  $onInit() {
    const adminId = this.account.admin.id.toString();
    this.members = (this.account.members as Array<any>).filter(member => adminId !== member.id);
    this.view = MANAGE_ALL;
  }

  removeUser(user) {
    const query = { remove: true };
    this.$api.MembershipAccount.updateAccountMembers({ accountId: this.account._id.toString(), user, query })
      .then((res) => {
        this.account = res.account;
        this.members = this.getAccoutMembers(this.account);
        this.close(res);
    })
    .catch(error => this.unwrapError(error));
  }

  getAccoutMembers(account) {
    return account.members.filter(item => item._id.toString() !== account.admin._id.toString());
  }
}

export const ManageAccountMembersComponent = {
  template: require('./manage-account-members.component.jade'),
  controller: ManageAccountMembersController,
  controllerAs: '$ctrl',
  bindings: {
    close: '<',
    account: '<',
    user: '<'
  }
};
