import { every, get, set } from 'lodash';
import AnalyticsService from 'spc/shared/analytics/analytics.service';
import { SpPardotService } from 'spc/shared/sp-pardot/sp-pardot.service';
import { UserService } from 'spc/services/user.service';
import { ApiService } from 'spc/shared/api/api.service';

module.exports = function () {
  return {
    template: require('./registration.jade'),
    scope: {
      isEmbedded: '='
    },
    controller: ['$scope', '$location', '$user', '$api', '$attrs', 'ENUMS', '$timeout', '$injector', 'unwrapError', '$analytics', 'spPardot', 'welcomePromoCodeModel', function ($scope, $location, $user: UserService, $api: ApiService, $attrs, ENUMS, $timeout, $injector, unwrapError, $analytics: AnalyticsService, spPardot: SpPardotService, welcomePromoCodeModel) {
      $scope.cities = ENUMS.acceptableUserCities;

      $scope.user = $user;
      $scope.registration = { };
      $scope.data = { password: '', showPassword: false };
      $scope.userQueryEmail = $location.search().email || undefined;

      $scope.emitEvent = emitEvent;

      function emitEvent(eventName, params) {
        $scope.$emit(eventName, params);
      }

      function determineEvent () {
        const location = spPardot.determineLocation();
        return location ?  `${location} registration` : 'registration page';
      }

      // $scope.$on('IS_MODAL', () => $scope.isModal = true);
      $scope.register = function () {
        $scope.disableButton = true;
        const theUser = $scope.registration;

        if ($scope.wantsNewsletter) {
          theUser.profile.newsletters.push(theUser.profile.city);
        }

        const userData = {
          guest: theUser,
          password: $scope.data.password,
          referrer: $analytics.getReferrerData()
        };

        set(userData, 'guest.company', get(theUser, 'company'));

        $api.Auth.register(userData)
          .then((res) => {
            $analytics.clearReferrerData();
            $scope.user.setUser(res.data.user, res.data.token, res.data.provider);
            spPardot.trackUser({ event: determineEvent() });
            $scope.$emit('REGISTER_SUCCESS', res.data);
            $('iframe').remove();
            welcomePromoCodeModel();
          })
          .catch(function (error) {
            $scope.error = get(error, 'data.error.message');
            $scope.disableButton = false;
            unwrapError(error);
          });
      };

      // Start newsletter input checked off (default to user wanting newsletter)
      $scope.wantsNewsletter = true;
      set($scope.registration, 'profile.newsletters', ['Main']);
      $scope.toggleNewsletter = function () {
        $scope.wantsNewsletter = !$scope.wantsNewsletter;
        const newsletters = $scope.wantsNewsletter ? ['Main'] : [];
        set($scope.registration, 'profile.newsletters', newsletters);
      };

      $scope.findPrimaryCity = (cityValue) => {
        const cityObj = $scope.cities.find(city => city.value === cityValue);
        if (cityObj) {
          return cityObj.name;
        }
      };

      $scope.selectPrimaryCity = function (city) {
        set($scope.registration, 'profile.city', city);

        $scope.primaryCity = $scope.cities[city];
      };

      $scope.selectCompany = (company) => {
        $scope.registration.company = company;
      };

      $scope.verifyEmail = function () {
        $api.Auth.verifyEmail($scope.registration.profile.email)
          .then(function (res) {
            $scope.email = {
              address: res.data.address,
              isValid: res.data.valid
            };
          })
          .catch(function (error) {
            $scope.email = {
              address: error.data.address,
              isValid: error.data.valid
            };
          });
      };

      $scope.hasEnteredAllFields = function () {
        const requiredFields = [
          get($scope.registration, 'profile.name.first'),
          get($scope.registration, 'profile.name.last'),
          get($scope.registration, 'profile.email'),
          $scope.email && $scope.email.isValid,
          $scope.data.password
        ];

        requiredFields.push(get($scope.registration, 'profile.city'));

        return every(requiredFields);
      };

      $scope.getEmail = function () {
        return $scope.email;
      };

      $timeout(function () {
        $scope.$emit('RegistrationController');
      }, 0);
    }]

  };
};
