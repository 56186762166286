// External Dependencies
import { fullName } from 'common/dist/virtuals/User';
import { phoneNumber, budget } from 'common/dist/virtuals/Lead';
import debounce from 'lodash/debounce';
import isNumber from 'lodash/isNumber';
import ENUMS from 'common/dist/enums';
import get from 'lodash/get';
import set from 'lodash/set';

// SixPlus dependencies
import { ToastService } from 'spc/shared/toast.service';
import { LeadSidebarService } from './lead-sidebar.service';

// SixPlus Types
import { RawCompany } from '../../../../database/types/company';
import { RawLead } from '../../../../database/types/lead';
import { RawUser } from 'spc/lib/database/types/user';
import { RawRecommendation } from 'spc/lib/database/types/recommendation';
import { perGuestBudgetsLondon } from '../../../../database/constants/londonConstants';
import { RawConversation } from 'spc/lib/database/types/conversation';
import { RawBookingRequest } from 'spc/lib/database/types/booking-request';
import { CityTzMapperService } from 'spc/shared/cityTZmapper.service';
import moment from 'moment';
import { getCityNameFromValue } from 'spc/utils/getCityDisplayName';

class LeadSidebarController {
  lead: RawLead;
  company: RawCompany;
  lastSession: {
    fullStoryUrl: string;
    createdTime: number;
  };
  client: any;
  clientResults: any;
  cityOptions = ENUMS.acceptableUserCities;
  avOptions: string[] = ENUMS.concierge.avOptions;
  editing: string;
  processing: boolean;
  leadUpdate: ({ lead }: { lead: RawLead }) => any;
  clone: any;
  occasions: string[];
  mealTypes: string[];
  eventStyles: string[];
  privacyTypes: string[];
  perGuestBudgets: string[];
  heardAboutOptions: string[];
  cuisineTypes: string[];
  _hour: number;
  _minutes: number;
  _amPm: string;
  hours: number[];
  minutes: number[];
  amPm: string[];
  existingUser: {
    checked: boolean,
    exists?: boolean
    user?: any,
  } = { checked: false };
  warnings: { duration?: string | null } = { };
  ui: { tab: 'current' | 'history' } = { tab: 'current' };
  ui2: {
    copied: string;
    copiedEmail: string;
  } = { copied: null, copiedEmail: null };
  owners: RawUser[];
  recommendations: RawRecommendation[];
  neighborhoodsMap: { [area: string]: { [city: string]: string[] } };
  getCityNameFromValue = getCityNameFromValue;
  contactPreferanceChecked: boolean;
  eventTypes: string[];


  constructor(private $api,
    private $user,
    private unwrapError,
    private toast: ToastService,
    private leadSidebarService: LeadSidebarService,
    private neighborhoodModal,
    private $location,
    private manageCollaboratorsModal,
    private addCollaboratorsModal,
    private cityTzMapperService: CityTzMapperService,
    private googleLocationModal,
    private $timeout) {
    'ngInject';
  }

  $onInit = () => {
    this.getNeighborhoods();
    this.setData();
    this.editing = null;
    this.eventTypes = ENUMS.eventType.filter(type => type.visible).map(type => type.label);
    this.mealTypes = ENUMS.concierge.mealTypes;
    this.eventStyles = ENUMS.concierge.eventStyles;
    this.privacyTypes = ENUMS.concierge.privacyTypes;
    this.perGuestBudgets = this.lead.request.city === 'London' ? perGuestBudgetsLondon : ENUMS.concierge.perGuestBudgets;
    this.heardAboutOptions = ENUMS.concierge.heardAbout;
    this.cuisineTypes = ENUMS.cuisineTypes;
    this.hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    this.minutes = [0, 15, 30, 45];
    this.amPm = ['AM', 'PM'];
  }

  $onChanges = (changes) => {
    this.setData();
  }

  setData = () => {
    this.client = this.client || this.lead.primaryClient;
    this.company = this.client.company;
    this.setOwner();
    this.client.fullName = fullName(this.client);
    this.lead.fullName = fullName(this.lead);
    this.lead.phoneNumber = phoneNumber(this.lead);
    this.validate();
  }

  setOwner = () => {
    if (this.client.owner) {
      const ownerId = this.client.owner._id ? this.client.owner._id : this.client.owner;
      this.client.owner = this.owners.filter(owner => owner._id === ownerId)[0];
      this.client.owner.name = `${this.client.owner.profile.name.first} ${this.client.owner.profile.name.last}`;
    }
  }

  getMinDate = () => {
    return this.$user.isAdmin() ? this.lead.createdAt : null;
  }

  getLondonBudget = (londonBudget) => {
    return '£' + londonBudget.toLocaleString();
  }

  getNeighborhoods = () => {
    return this.$api.Landing.getLocation()
      .then(data => this.neighborhoodsMap = data.neighborhoodsMap)
      .catch(error => this.unwrapError(error));
  }

  debounceClientSearch = debounce((name) => {
    if (name.length < 3) {
      return;
    }
    this.$api.Admin.Search.allBaseUsers(name)
      .then((response) => {
        this.clientResults = response.data.data;
        this.clientResults.forEach(client => client.fullName = fullName(client));
      })
      .catch(error => this.unwrapError(error));
    }, 300);

  startEditing = (editing) => {
    this.editing = editing;
    this.clone = {
      _id: this.lead._id,
      request: {},
    };
    if (editing === 'start-time') {
      this.mapTime();
    }

    if (editing === 'city') {
      if (!this.neighborhoodsMap[this.lead.request.city]) {
        this.clone.request.city = 'Other';
        this.clone.request.customCity = this.lead.request.city;
      } else {
        this.clone.request.city = this.lead.request.city;
      }
    }

    if (editing === 'contactPreferance') {
      if (!get(this.clone, 'client.profile.contactPreferance')) {
        set(this.clone, 'client.profile.contactPreferance', get(this.client, 'profile.contactPreferance'));
      }
    }

    if (editing === 'isDateFlexible') {
      if (!get(this.clone, 'request.isDateFlexible')) {
        set(this.clone, 'request.isDateFlexible', get(this.lead, 'request.isDateFlexible'));
      }
    }

    if (editing === 'location') {
      this.setGoogleLocation();
    }
  }

  setGoogleLocation = () => {
    const locationData = { ...get(this.lead, 'request.locationDetails'), address: get(this.lead, 'request.location') };
    return this.googleLocationModal({ locationData })
      .then((res) => {
        const { address, ...locationDetails } = get(res.value, 'location');
        set(this.clone, 'request.location', address);
        set(this.clone, 'request.locationDetails', locationDetails);
      })
      .catch((error) => {
        this.unwrapError(error);
      });
  }

  editcontactPreferance = (contactPreferance) => {
    set(this.clone, `client.profile.contactPreferance.${contactPreferance}`, !get(this.clone, `client.profile.contactPreferance.${contactPreferance}`));
    this.contactPreferanceChecked = get(this.clone, 'client.profile.contactPreferance.phoneCall') || get(this.clone, 'client.profile.contactPreferance.textMessage');
  }

  editLeadIsDateFlexible = () => {
    set(this.clone, 'request.isDateFlexible', !get(this.clone, 'request.isDateFlexible'));
  }

  openCollaboratorsModal = () => {
    const requests = this.recommendations.reduce((reqs, recommendation) => {
      if (recommendation.conversation && (recommendation.conversation as RawConversation).request) {
        reqs.push((recommendation.conversation as RawConversation).request);
      }
      return reqs;
    }, []);
    return this.manageCollaboratorsModal({ requests, lead: this.lead })
      .then((res) => {
        if (res.lead) {
          this.lead = res.lead;
        }
        if (res.proposals) {
          this.recommendations = this.mapRequests(res.proposals);
        }
      });
  }

  openAddCollaboratorsModal = () => {
    const user = this.lead.primaryClient;
    return this.addCollaboratorsModal({ user: user, lead: this.lead })
      .then((res) => {
        if (res.lead) {
          this.lead = res.lead;
        }
        if (res.proposals) {
          this.recommendations = this.mapRequests(res.proposals);
        }
      });
  }

    mapRequests = (proposals) => {
    return this.recommendations.map((reco) => {
      if (reco.conversation && (reco.conversation as RawConversation).request) {
        const currentRequest = (reco.conversation as RawConversation).request as RawBookingRequest;
        const updatedRequest = proposals.find(request => request._id.toString() === currentRequest._id.toString());
        if (updatedRequest) {
          (reco.conversation as RawConversation).request = updatedRequest;
        }
      }
      return reco;
    });
  }

  debouncedEmailSearch = debounce((email) => {
    if (email.length < 3) {
      return;
    }
    this.$api.Admin.Search.emails(email)
      .then((response) => {
        this.existingUser = {
          user: response.users.length ? fullName(response.users[0]) : null,
          exists: !!response.users.length,
          checked: true
        };
      })
      .catch((error) => {
        this.toast.badNews(`Invalid Email`, `The email you entered is invalid`);
        this.unwrapError(error);
      });
  }, 1000);

  setClientOwner (owner) {
    return this.$api.Admin.Leads.updateClient({ user: this.client._id.toString(), data: { owner: owner._id } })
      .then((response) => {
        const user = response.user;
        this.lead.primaryClient.owner = user.owner;
        this.setData();
        this.leadUpdate({ lead: this.lead });
        this.toast.goodNews(`Client Updated`, `Client ${ user._id } updated.`);
      })
      .catch((error) => {
        this.toast.badNews(`Client Update Failed`, error);
        this.unwrapError(error);
      });
  }

  setTime = () => {
    this.clone.request.time = this.leadSidebarService.setTime(this);
  }

  mapTime = () => {
    const timeObj = this.leadSidebarService.mapTime(this.lead.request.time);
    this._amPm = timeObj._amPm;
    this._hour = timeObj._hour;
    this._minutes = timeObj._minutes;
  }

  displayHeardAboutFriend = () => {
    return this.lead.heardAbout === `Friend` && this.lead.heardAboutFriend || ( this.clone && this.clone.heardAbout === `Friend`);
  }
  displayHeardAboutRestaurant = () => {
    return this.lead.heardAbout === `Venue Referral` && this.lead.heardAboutRestaurant || (this.clone && this.clone.heardAbout === `Venue Referral`);
  }
  isEditing = (field) => {
    return this.editing === field;
  }

  cancelEditing = () => {
    this.editing = null;
    this.clone = null;
    this.clientResults = null;
  }

  stopEditing = () => {
    this.editing = null;

    if (this.clone.request.durationHours) {
      this.clone.request.duration = this.clone.request.durationHours * 60;
    }

    // Check lead event date or lead event city getting updated.
    const isLeadCityChanged = get(this.clone, 'request.city') ? true : false;
    const isLeadDateChanged =  get(this.clone, 'request.date') ? true : false;

    if (isLeadCityChanged || isLeadDateChanged) {
      const leadCity =  get(this.clone, 'request.city') ||  get(this.lead, 'request.city');

      // Format date to get correct date string.
      const formatDateString = date => date ? moment(date).format('YYYY-MM-DD') : '';

      const cloneDate = formatDateString(get(this.clone, 'request.date'));
      const leadDate = formatDateString(get(this.lead, 'request.date'));

      const getLeadDate = cloneDate || leadDate || '';

      if (leadCity && getLeadDate) {
        const tzMappedDate = this.cityTzMapperService.getCityTZmappedDate({ city: leadCity, date: getLeadDate });
        this.clone.request.date = tzMappedDate || this.lead.request.date;
      }
    }
    return this.$api.Admin.Leads.update(this.clone)
      .then((response) => {
        this.clone = null;
        this.lead = response.lead;
        this.client = response.lead.primaryClient;
        this.setData();
        this.leadUpdate({ lead: this.lead });
        this.toast.goodNews(`Lead Updated`, `Lead ${ this.lead._id } updated.`);
      })
      .catch((error) => {
        this.clone = null;
        this.toast.badNews(`Lead Change Failed`, `There was an error updating the lead`);
        this.unwrapError(error);
      });
  }

  stopEditingClient () {
    this.editing = null;
    return this.$api.Admin.Leads.updateClient({ user: this.client._id.toString(), data: { profile: this.clone.client.profile } })
      .then((response) => {
        this.clone = null;
        const user = response.user;
        this.lead.primaryClient = user;
        this.client = user;
        this.setData();
        this.leadUpdate({ lead: this.lead });
        this.toast.goodNews(`Client Updated`, `Client ${ user._id } updated.`);
      })
      .catch((error) => {
        this.toast.badNews(`Client Update Failed`, error);
        this.unwrapError(error);
      });
  }

  validate = () => {
    this.warnings = {};
    this.checkDuration();
  }

  checkDuration = () => {
    if (isNumber(this.lead.request.duration)) {
      this.warnings.duration = `This value has to be a number.`;
    } else {
      this.warnings.duration = null;
    }
  }

  leadBudget = () => {
    if (get(this.lead, 'request.numGuests.min') || get(this.lead, 'request.numGuests.max')) {
      return budget(this.lead);
    }
  }

  openNeighborhoodModal = () => {

    this.startEditing('neighborhood');
    return this.neighborhoodModal({ selectedArea: this.lead.request.city, currentChoices: this.lead.request.neighborhoods, neighborhoodsMap: this.neighborhoodsMap })
      .then((response) => {
        const currentChoices = get<string[]>(response, 'value.currentChoices', []);
        if (currentChoices) {
          set(this.clone, 'request.neighborhoods', currentChoices);
          return this.stopEditing();
        } else {
          this.cancelEditing();
        }
      })
      .catch(error => this.unwrapError(error));
  }


  getLeadLink = (lead) => {
    let domain = `${this.$location.protocol()}://${this.$location.host()}`;
    if (this.$location.port() !== 443) {
      domain = `${domain}:${this.$location.port()}`;
    }
    const leadId = this.lead._id.toString();
    const link = `${domain}/recos/${leadId}`;
    return link;
  }

  handleCopyClick = (lead) => {
    this.ui2.copied = lead._id.toString();
    this.$timeout(() => this.ui2.copied = null, 1000);
  }

  handleCopyEmailClick = (email) => {
    this.ui2.copiedEmail = email.toString();
    this.$timeout(() => this.ui2.copiedEmail = null, 1000);
  }

  changeClient = (newClient) => {
    this.editing = null;
    this.processing = true;
    this.clientResults = null;

    const oldUserId = this.client ? this.client._id : null;

    const data = {
      leadId: this.lead._id,
      oldUserId,
      newUserId: newClient._id
    };

    this.clone = null;
    return this.$api.Admin.Leads.changeClient(data)
      .then((response) => {
        this.lead = response.lead;
        this.processing = false;
        this.setData();
        this.leadUpdate({ lead: this.lead });
        this.toast.goodNews(`Lead Update Successful`, `There client was changed successfully.`);
      })
      .catch((error) => {
        this.toast.badNews(`Lead Update Failed`, `There was an error changing the lead client`);
        this.unwrapError(error);
      });
  }

  setDate = (date) => {
    this.clone.request.date = date;
  }

  unsetLocationField = () => {
    this.clone = {
      _id: this.lead._id,
      request: {},
    };
    set(this.clone, 'request.location', '');
    set(this.clone, 'request.locationDetails', {});
    this.stopEditing();
  }
}

export const LeadSidebarComponent = {
  template: require('./lead-sidebar.component.jade'),
  bindings: {
    lead: '<',
    lastSession: '<',
    leadUpdate: '&',
    owners: '<',
    recommendations: '<',
    clientOwner: '<'
  },
  controller: LeadSidebarController
};
