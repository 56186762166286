// External Dependencies
export default ['ngDialog', function(ngDialog) {
  return function (events, date) {
    return ngDialog.open({
      template: `<admin-calendar-day-view events=$ctrl.events date=$ctrl.date></admin-calendar-day-view>`,
      className: 'ngdialog-theme-plain info-modal',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', function ($scope) {
        this.events = events;
        this.date = date;
        this.close = (data) => {
          $scope.closeThisDialog(data);
        };
      }]
    }).closePromise;
  };
}];
