const eventFields = [
  // General Information
  { label: 'ID', format: null },
  { label: 'Reservation ID', format: null },
  { label: 'Venue City', format: null },
  { label: 'Venue State', format: null },
  { label: 'Venue Country', format: null },
  { label: 'Owner', format: null },
  { label: 'State', format: null },
  { label: 'Event Date', format: 'date' },
  { label: 'Primary Client', format: null },
  { label: 'Primary Client Role', format: null },
  { label: 'Additional Contact 1 (role)', format: null },
  { label: 'Additional Contact 2 (role)', format: null },
  { label: 'Additional Contact 3 (role)', format: null },
  { label: 'Client Company', format: null },
  { label: 'Accepted By (if different than primary client)', format: null },
  { label: 'Booked Venue', format: null },
  { label: 'Booked Space', format: null },
  { label: 'Event Name', format: null },
  { label: 'Guest Count', format: null },
  { label: 'Referrer', format: null },
  { label: 'Heard From', format: null },
  { label: 'Lead Source', format: null },
  { label: 'Promo Code', format: null},
  { label: 'Created Through', format: null },

  // Venue Taxes and Fees
  { label: 'F&B Tax Rate', format: 'percentage' },
  { label: 'Retail Tax Rate', format: 'percentage' },
  { label: 'Gratuity Rate', format: 'percentage' },
  { label: 'Admin Fee Rate', format: 'percentage' },

  // Event Details - Contracted (state of the contract at the time of balance payment)
  { label: 'Contracted F&B Min', format: 'currency' },
  { label: 'Contracted F&B', format: 'currency' },
  { label: 'Extra to Meet F&B Min', format: 'currency' },
  { label: 'Contracted Room Fee', format: 'currency' },
  { label: 'Room Fee Taxed', format: 'currency' },
  { label: 'Contracted Retail Taxable Add-ons', format: 'currency' },
  { label: 'Contracted Non Taxable Add-ons', format: 'currency' },
  { label: 'Contrated Subtotal',  format: 'currency' },
  { label: 'Contracted Admin Fee', format: 'currency' },
  { label: 'Contracted F&B Tax', format: 'currency' },
  { label: 'Contracted Retail Tax', format: 'currency' },
  { label: 'Contracted Gratuity', format: 'currency' },
  { label: 'Contracted Total', format: 'currency' },

  // Overages and Refunds
  { label: 'O&R F&B', format: 'currency' },
  { label: 'O&R Retail Taxable Add-ons', format: 'currency' },
  { label: 'O&R Non-Tax Add-ons', format: 'currency' },
  { label: 'O&R Subtotal',  format: 'currency' },
  { label: 'O&R Admin Fee', format: 'currency' },
  { label: 'O&R F&B Tax', format: 'currency' },
  { label: 'O&R Retails Tax', format: 'currency' },
  { label: 'O&R Gratuity', format: 'currency' },
  { label: 'O&R Total', format: 'currency' },

  // Total Bookingn Information
  { label: 'Total F&B', format: 'currency' },
  { label: 'Retail Taxable Add-ons', format: 'currency' },
  { label: 'Non Taxable Add-ons', format: 'currency' },
  { label: 'Total Room Fee', format: 'currency' },
  { label: 'Total Subtotal', format: 'currency' },
  { label: 'Total Admin Fee', format: 'currency' },
  { label: 'Total F&B Tax', format: 'currency' },
  { label: 'Total Retail Tax', format: 'currency' },
  { label: 'Total Gratuity', format: 'currency' },
  { label: 'Event Grand Total', format: 'currency' },
  { label: 'Maximum Potential Value', format: 'currency' },

  // Commission & Fees
  { label: 'Success Fee', format: 'currency' },
  { label: 'Success Fee %',  format: 'percentage' },
  { label: 'Processing Fee', format: 'currency' },
  { label: 'Processing %', format: 'percentage' },
  { label: 'Total Fees To SixPlus', format: 'currency' },
  { label: 'Net Payout To Venue', format: 'currency' },

  // Client Payments Summary
  { label: 'Deposit Amount', format: 'currency' },
  { label: 'Deposit Date', format: 'date' },
  { label: 'Deposit Method', format: null },
  { label: 'Deposit Direct to Venue', format: null },
  { label: 'Balance Amount', format: 'currency' },
  { label: 'Balance Date',  format: 'date' },
  { label: 'Balance Method', format: null },
  { label: 'Balance Direct to Venue', format: null },
  { label: 'Intermediate Amount', format: 'currency' },
  { label: 'Intermediate Date',  format: 'date' },
  { label: 'Intermediate Method', format: null },
  { label: 'Intermediate Direct to Venue', format: null },
  { label: 'Overage Amount', format: 'currency' },
  { label: 'Overage Date', format: 'date' },
  { label: 'Overage Pay Method', format: null },
  { label: 'Overage Direct to Venue', format: null },
  { label: 'Refund', format: 'currency' },

  // Venue Payment Summary
  { label: 'First Venue Payment Amount', format: 'currency' },
  { label: 'First Venue Payment Date', format: 'date' },
  { label: 'First Venue Payment Method', format: null },
  { label: 'Second Venue Payment Amount',  format: 'currency' },
  { label: 'Second Venue Payment Date', format: 'date' },
  { label: 'Second Venue Payment Method', format: null },
  { label: 'Third Venue Payment Amount',  format: 'currency' },
  { label: 'Third Venue Payment Date', format: 'date' },
  { label: 'Third Venue Payment Method', format: null },
  { label: 'Closing Venue Payment Amount', format: 'currency' },
  { label: 'Closing Venue Payment Date', format: 'date' },
  { label: 'Closing Venue Payment Method', format: null },
  { label: 'Closing Venue Payment Status', format: null },
  { label: 'Cancellation Fee', format: 'currency'},

//Cancellation Fee
  // Admin
  { label: 'Payment Recipient', format: null },
  { label: 'Payment Method', format: null },
  { label: 'Visible to Client', format: null },
  { label: 'Visible to Venue', format: null },
  { label: 'Owner Commission', format: 'currency' },
  { label: 'Admin Notes', format: null }
];
const dateLabels = eventFields
  .filter(filterByFormat('date'))
  .map(getLabel);
const currencyLabels = eventFields
  .filter(filterByFormat('currency'))
  .map(getLabel);
const percentLabels = eventFields
  .filter(filterByFormat('percentage'))
  .map(getLabel);
const allLabels = eventFields
  .map(getLabel);

function getLabel (field) { return field.label; }
function filterByFormat (format) {
  return function (field) {
    return field.format === format;
  };
}


module.exports = {
  dateLabels: dateLabels,
  currencyLabels: currencyLabels,
  percentLabels: percentLabels,
  allLabels: allLabels,
  eventFields: eventFields
}
