import { DUser } from 'spc/lib/database/types/user';
import { AmountPaid, DateOfPayment, PaymentConversation, ReceiptResponse } from 'spc/lib/server/api/leads/models';
import { ApiService } from 'spc/shared/api/api.service';
import ENUMS from 'common/dist/enums';
import get from 'lodash/get';

interface Link {
  name: string;
}
interface TeamReceiptFilter {
  to?: Date;
  from?: Date;
  city?: string;
}

class ClientReceitsController {
  user: DUser;
  hasAccount: boolean = false;
  links: Link[];
  receipts: ReceiptResponse[];
  filters: TeamReceiptFilter = {};
  cities = ENUMS.acceptableUserCities;
  showFilter: boolean = false;
  loading: boolean = false;

  constructor( private $api: ApiService, private $rootScope, private $seo, private $scope, private $user, private unwrapError, private clientReceiptsRequestAccessModal ) {
    'ngInject';
    $scope.$on('CHANGE_PARENT_STATE', function(event, args) {
      if (args.state === 'Team Receipts') {
        $scope.getTeamReceipt();
      }
      else {
        $scope.getUserReceipt();
      }

        $scope.currentState = args.state;
    });

    $scope.isState = function isState(state) {
      return $scope.currentState === state;
    };
    $scope.getTeamReceipt = (filters) => {
      this.loading = true;
      this.receipts = [];
      return this.$api.Leads.getTeamReceipts({ filters: filters })
        .then((response) => {
          this.loading = false;
          this.receipts = response.receipts;
        })
        .then(() => this.componentIsReady());
    };
    $scope.getUserReceipt = () => {
      this.showFilter = false;
      this.loading = true;
      this.receipts = [];
      this.$api.Leads.getUserReceipts()
        .then((response) => {
          this.loading = false;
          this.receipts = response.receipts;
        })
        .then(() => this.componentIsReady());
    };
  }

  $onInit = () => {
    this.user = !!this.$user.$ ? this.$user.$ : null;
    try {
      if (!this.user) {
        this.$api.Auth.me()
        .then((res) => {
          this.user = res.data.user;
          this.createLinks();
          this.componentIsReady();
        });
      }
      this.createLinks();
    } catch (error) {
      this.unwrapError(error);
    }
  }

  toggleFilter = () => {
   this.showFilter = !this.showFilter;
  }

  createLinks = () => {
    if (get(this.user, 'account')) {
      this.hasAccount = true;
    }
    this.links = [
      { name: 'Your Receipts' }
    ];
    if (this.hasAccount) {
      this.links.push({ name: 'Team Receipts' });
    }
  }

  selectCity = (city) => {
    this.filters.city = city;
  }

  applyFilters = () => {
    this.$scope.getTeamReceipt(this.filters);
  }

  shouldShowRestaurentReceipt = (conversation, type) => {
    const allKeys = conversation.map(obj => Object.keys(obj)[0]);
    return allKeys.includes(type);

  }

  showSlash = (conversation) => {
    const allKeys = conversation.map(obj => Object.keys(obj)[0]);
    if (allKeys.includes('restaurantLink') && allKeys.includes('restaurantUpload')) {
      return true;
    }
    return false;
  }

  clearFilters = () => {
    this.filters = {};
    this.toggleFilter();
    this.$scope.getTeamReceipt(this.filters);
  }

  componentIsReady = () => {
    this.$seo.set('Receipts');
    this.$rootScope.$emit('$viewReady', 'HIDE_FOOTER');
  }

  drawKeyValue = ({ paymentDetails, slug }: { paymentDetails: DateOfPayment | AmountPaid | PaymentConversation, slug: 'key' | 'value' }) => {
    if (slug === 'value') {
      return Object.values(paymentDetails)[0];
    }
    return Object.keys(paymentDetails)[0];
  }

  isOwnerOrCollaborator = (members) => {
    return members.some(member => member === this.user._id);
  }

  openclientReceiptsAccessModal = () => {
    return this.clientReceiptsRequestAccessModal();
  }
}

export const ClientReceitsComponent = {
  controller: ClientReceitsController,
  template: require('./client-receipts.component.jade'),
};
