class StarRatingController {
  value: number;
  label: string;
  map = {
    0.5: 'starhalf',
    1: 'star1',
    1.5: 'star1half',
    2: 'star2',
    2.5: 'star2half',
    3: 'star3',
    3.5: 'star3half',
    4: 'star4',
    4.5: 'star4half',
    5: 'star5'
  };
  constructor(private $timeout) {
    'ngInject';
  }

  private calculateScore = () => {
    if (!this.value) {
      return;
    }

    if (this.value === 5) {
      return 5;
    }

    const score = Math.round(this.value * 2) / 2;

    if (score === 5) {
      return 4.5;
    }

    return score;
  }

  $onInit = (data) => {
    this.$timeout(() => {
      const score = this.calculateScore();

      if (!score) {
        return;
      }
      const id = `${this.label}${this.map[score]}`;

      const doc = document.getElementById(id);
      if (doc) {
        doc.checked = true;
      }
    }, 1);
  }
}

export const starRatingComponent = {
  controller: StarRatingController,
  template: require('./sp-star-rating.component.jade'),
  bindings: {
    value: '=',
    label: '@'
  }
};
