import { RawAccount, DAccount, AccountActionQuery } from '../../../../database/types/accounts';
import { ACCOUNT_STATUS, ACCOUNT_TYPE, ACCOUNT_TIER, SUBSCRIPTION_TERMS } from '../../../../database/constants/Account';
import { ApiService } from 'spc/shared/api/api.service';
import { cloneDeep, debounce, every, get } from 'lodash';
import { DUser } from 'spc/lib/database/types/user';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { DCompany, RawCompany } from 'spc/lib/database/types/company';
import { DBookingRequest } from 'spc/lib/database/types/booking-request';
import { ACCOUNT_MEMBERS_LIMIT } from '../../../../database/constants/Account';

import moment from 'moment';

// Constants
const DATE_FORMAT = 'YYYY-MM-DD';

const newAccount: RawAccount = {
    name: '',
    company: '',
    admin: '',
    members: [],
    status: ACCOUNT_STATUS[0],
    membersLimit: ACCOUNT_MEMBERS_LIMIT,
    accountTier: ACCOUNT_TIER[0],
    accountType: ACCOUNT_TYPE[0],
    subscriptionTerm: SUBSCRIPTION_TERMS[0]
};

class AdminCreateEditAccountController {
    ready: () => void;
    ui: {
        loading: boolean;
        error?: string;
        removing?: boolean;
    } = {
        loading: false,
        removing: false
    };
    member: string;
    members: RawBaseUser[];
    adminName: string;
    companyName: string;
    admin: DUser;
    company: DCompany;
    users: DUser[] = [];
    creatingAccount: boolean;
    showCompany: boolean = false;
    companies: RawCompany[] = [];
    account: RawAccount | DAccount = cloneDeep(newAccount) as RawAccount | DAccount;
    originalAccount: RawAccount;
    accountId: string;
    defaultMembersLimit: number;
    status = ACCOUNT_STATUS;
    accountTier: string;
    accountTiers = ACCOUNT_TIER;
    accountTypes = ACCOUNT_TYPE;
    subscriptionTerms = SUBSCRIPTION_TERMS;
    addingMember: boolean = false;
    editingAccount: boolean = false;
    bookings: DBookingRequest[];
    showBookings: boolean = false;
    showHistory: boolean = false;
    hideEndDate: boolean = false;
    hideMemberLimit: boolean = false;
    errorMessage: string;
    startDate;
    endDate;
    createdAt;

    constructor(
        private $api: ApiService,
        private $seo,
        private unwrapError,
        private $location,
        private $routeParams,
        private $scrollService,
        private $timeout

    ) {
        'ngInject';
    }

    $onInit = () => {
        const accountId = this.$routeParams.id;
        this.defaultMembersLimit = ACCOUNT_MEMBERS_LIMIT;
        if (accountId) {
            this.accountId = accountId;
            this.creatingAccount = false;
            this.getAccount(accountId);
        } else {
            this.creatingAccount = true;
            this.account =  cloneDeep(newAccount);
            this.setStartDate();
            this.setEndDate();
            this.$seo.set('Create Team');
            this.ready();
        }
    }

    isAdmin = (memberId) => {
        if (memberId === this.account.admin._id) {
            return true;
        }
        return false;
    }

    getAccount = (accountId) => {
        this.$api.Admin.Account.getAccountById({ accountId })
            .then((response) => {
                this.originalAccount = response.account;
                this.account = cloneDeep(this.originalAccount) as DAccount;
                this.bookings = response.bookings;
                this.adminName = this.account.admin.fullName;
                this.companyName = this.account.company.name;
                this.defaultMembersLimit = this.account.membersLimit ? this.account.membersLimit : this.account.members.length;
                this.setAccountType();
                this.setStartDate();
                this.setEndDate();
                this.createdAt = moment(new Date(this.account.createdAt)).format(DATE_FORMAT);
                this.showCompany = false;
                this.$seo.set('Edit Team');
                this.ready();
            })
            .catch((error) => {
                this.unwrapError(error);
                this.ready();
                return;
            });
    }

    setStartDate = (date?: any) => {
        if (date) {
            this.startDate = date.format(DATE_FORMAT);
            this.account.startDate = new Date(date).getTime();
        } else if (!this.account.startDate) {
            const createdAt = this.account.createdAt || new Date();
            this.startDate = moment(new Date(createdAt)).format(DATE_FORMAT);
            this.account.startDate = new Date(createdAt).getTime();
        } else {
            this.startDate = moment(new Date(this.account.startDate)).format(DATE_FORMAT);
        }
    }

    setEndDate = (endDate?: moment.Moment) => {
        if (this.account.accountType === 'Complimentary') {
            return;
        }
        if (endDate) {
            const date = endDate.format(DATE_FORMAT);
            if (date <= this.account.startDate) {
                this.account.endDate = '';
                return this.ui.error = 'Invalid Date';
            }
            this.ui.error = '';
            this.endDate = date;
            const d = new Date(date);
            this.account.endDate = d.getTime() + (d.getTimezoneOffset() * 60000);
        } else if (!this.account.endDate) {
            const createdAt = this.account.createdAt || new Date();
            this.endDate = moment(new Date(createdAt)).add(30, 'days').format(DATE_FORMAT);
            this.account.endDate = moment(new Date(createdAt)).add(30, 'days').valueOf();
        } else {
            this.endDate = moment(new Date(this.account.endDate)).format(DATE_FORMAT);
        }
    }

    setAccountType = () => {
        const accountType = this.account.accountType;

        if (accountType === 'Complimentary') {
            this.account.endDate = null;
            this.account.membersLimit = null;
            this.hideEndDate = true;
            this.hideMemberLimit = true;
        } else {
            this.account.endDate = get(this.account, 'endDate', null);
            this.account.membersLimit = get(this.account, 'membersLimit', null);
            this.hideEndDate = false;
            this.hideMemberLimit = false;
        }
    }

    updateDependentFileds = () => {
        const accountTier = this.account.accountTier;
        if (accountTier === 'Free') {
            this.account.accountType = 'Complimentary';
            this.account.subscriptionTerm = 'TBD';
            this.account.endDate = null;
            this.account.membersLimit = null;
            this.hideEndDate = true;
            this.hideMemberLimit = true;
        }
    }

    toggleAccountEdit = () => {
        this.showBookings = false;
        this.showHistory = false;
        return this.editingAccount = !this.editingAccount;
    }

    closeList = ({ slug }: { slug: 'users' | 'companies' | 'members' }) => {
        if (slug === 'users') {
            this.adminName = this.account.admin.fullName;
            return this.users = [];
        } else if (slug === 'companies') {
            this.companyName = this.account.company.name;
            return this.companies = [];
        } else if (slug === 'members') {
            return this.members = [];
        }
    }

    select = ({ slug, payload }: { slug: 'user' | 'company' | 'member', payload: RawCompany | RawBaseUser | any }) => {
        if (slug === 'company') {
            this.account.company = payload;
            this.companyName = payload.name;
            this.companies = [];
        } else if (slug === 'user') {
            this.account.admin = payload;
            this.adminName = payload.fullName;
            this.users = [];
        } else if (slug === 'member') {
            this.updateAccountMembers({ action: 'add', user: payload });
            this.member = '';
            this.members = [];
        }
    }

    handleQuery = (action) => {
        const query: AccountActionQuery = {};
        if (action === 'add') {
            query.add = true;
        } else if (action === 'remove') {
            query.remove = true;
        }
        return query;
    }

    getSearchResult = ({ slug }: { slug: 'user' | 'member' | 'company'}) => {
        if (slug === 'user') {
            this.ui.loading = true;
            const text = this.adminName;
            if (text.length) {
                return this.$api.Admin.Search.allUsers(text)
                .then((response) => {
                    this.users = cloneDeep(response.data.data);
                    this.ui.loading = false;
                }).catch((err) => {
                    this.ui.loading = false;
                    this.unwrapError(err);
                });
            }
        } else if (slug === 'company') {
            const name = this.companyName;
            return this.$api.Companies.searchCompanies(name)
                .then((response) => {
                    this.companies = cloneDeep(response.companies);
                    this.showCompany = !this.companies.length;
                }).catch((err) => {
                    this.unwrapError(err);
                });
        } else if (slug === 'member') {
            this.ui.loading = true;
            const text = this.member;
            if (text.length) {
                return this.$api.Admin.Search.allUsers(text)
                .then((response) => {
                    this.members = cloneDeep(response.data.data);
                    this.ui.loading = false;
                }).catch((err) => {
                    this.ui.loading = false;
                    this.unwrapError(err);
                });
            }
        }
    }

    debounceSearch = debounce(this.getSearchResult, 500);

    addMember = () => {
        this.member = '';
        this.ui.error = '';
        return this.addingMember = true;
    }

    inviteUser = ({ user }) => {
    const query = { add: true };
    this.$api.Admin.Account.updateAccountMembers({ accountId: this.accountId, user, query })
      .then((res) => {
        if (res.account._id) {
            this.account.members = res.account.members;
            this.addingMember = false;
        }
      })
      .catch((error) => {
        if (error.data) {
            this.ui.error = error.data.error.message;
        }
        this.unwrapError(error);
      });
    }

    isOnAccount({ user }) {
        const accountMembers = this.account.members as RawBaseUser[];
        return !user._id || accountMembers.some((u) => {
            return u._id.toString() === user._id.toString();
        });
    }

    createCompany = () => {
        return this.$api.Companies.createCompany({ name: this.companyName })
            .then((data) => {
                this.showCompany = false;
                this.select({ slug: 'company', payload: data.company });
            })
            .catch(error => this.unwrapError(error));
    }

    updateAccountMembers = ({ action, user }: { action: string, user: RawBaseUser}) => {
        this.ui.removing = true;
        const query = this.handleQuery(action);
        this.$api.Admin.Account.updateAccountMembers({ accountId: this.accountId, user, query })
            .then((response) => {
                this.originalAccount = response.account;
                this.bookings = response.bookings;
                this.addingMember = false;
                this.ui.error = '';
                this.ui.removing = false;
                if (!this.editingAccount) {
                    this.account = cloneDeep(this.originalAccount);
                    this.adminName = this.account.admin.fullName;
                }
            })
            .catch((error) => {
                this.resetAccount('addMember');
                if (error.data) {
                    this.ui.error = error.data.error.message;
                }
                this.addingMember = false;
                this.ui.removing = false;
                this.unwrapError(error);
            });
    }

    findBookingUser = (booking) => {
        const accountMembers = this.account.members as RawBaseUser[];
        return accountMembers.find(member => booking.clients.find(client => client.isPrimary && client.user === member._id));
    }

    updateAccount = (updates) => {
        if (updates.accountTier === 'Free') {
            Object.assign(updates, { accountDowngrade: true });
        }
        this.$api.Admin.Account.updateAccount({ accountId: this.accountId, updates })
            .then((response) => {
                this.originalAccount = response.account;
                this.account = cloneDeep(this.originalAccount);
                this.bookings = response.bookings;
                this.adminName = this.account.admin.fullName;
                this.companyName = this.account.company.name;
            })
            .catch((error) => {
                this.resetAccount('editAccount');
                if (error.data) {
                    this.ui.error = error.data.error.message;
                }
                this.unwrapError(error);
            });
    }

    hasAllRequiredFields = () => {
        const requiredFields = [
            get(this.account, 'name'),
            get(this.account, 'admin'),
            get(this.account, 'company'),
            get(this.account, 'accountType'),
            get(this.account, 'subscriptionTerm')
        ];
        if (this.account.accountType !== 'Complimentary') {
            requiredFields.push(get(this.account, 'endDate'));
        }
        if (this.account.accountType !== 'Complimentary') {
            requiredFields.push(get(this.account, 'membersLimit'));
        }

        return every(requiredFields);
    }

    resetAccount = (slug) => {
        const previousState = {
            name: this.originalAccount.name,
            admin: this.originalAccount.admin,
            company: this.originalAccount.company,
            status: this.originalAccount.status,
            members: this.originalAccount.members,
            accountTier: this.originalAccount.accountTier,
            accountType: get(this.originalAccount, 'accountType'),
            subscriptionTerm: get(this.originalAccount, 'subscriptionTerm')
        };

        if (slug === 'editAccount') {
            this.editingAccount = false;
        } else if (slug === 'addMember') {
            this.addingMember = false;
        }
        this.account = { ...this.account, ...previousState };
        this.hideEndDate = this.account.accountType === 'Complimentary';
        this.hideMemberLimit = this.account.accountType === 'Complimentary';
        this.account.endDate = this.hideEndDate ? get(this.originalAccount, 'endDate', 'N/A') : get(this.originalAccount, 'endDate');
        this.users = [];
        this.companies = [];
        this.members = [];
        this.ui.error = '';
        this.adminName = this.originalAccount.admin.fullName;
        this.companyName = this.originalAccount.company.name;
        this.startDate = this.originalAccount.startDate ? moment(new Date(this.originalAccount.startDate)).format(DATE_FORMAT) : moment(new Date(this.originalAccount.createdAt)).format(DATE_FORMAT);
        this.endDate = this.originalAccount.endDate ? moment(new Date(this.originalAccount.endDate)).format(DATE_FORMAT) :  moment(new Date(this.originalAccount.createdAt)).add(30, 'days').format(DATE_FORMAT);
    }

    cancel = (slug) => {
        if (slug === 'create') {
            return this.$location.path('/admin/accounts');
        } else if (slug === 'editAccount' || 'addMember') {
            return this.resetAccount(slug);
        }
    }

    submit = () => {
        this.account.membersLimit = this.account.membersLimit === null ? null : Number(this.account.membersLimit);
        this.account.membersLimit = Number.isNaN(this.account.membersLimit) ? undefined : this.account.membersLimit;

        if (this.creatingAccount) {
            this.account.members.push(this.account.admin['_id']);
            this.$api.Admin.Account.createAccount({ account: this.account })
                .then((response) => {
                    this.$location.path(`/admin/team/${response.account.id}/edit`);
                })
                .catch((error) => {
                    this.account.admin = '';
                    this.account.members = [];
                    if (error.data) {
                        this.ui.error = error.data.error.message;
                    }
                    this.unwrapError(error);
                });
        } else {
            const updates: RawAccount = {
                name: this.account.name,
                company: this.account.company._id,
                status: this.account.status,
                accountTier: this.account.accountTier,
                accountType: this.account.accountType,
                subscriptionTerm: this.account.subscriptionTerm,
                membersLimit: this.account.membersLimit,
                startDate: this.account.startDate,
                endDate: this.account.endDate
            };
            if (this.account.admin._id !== this.originalAccount.admin._id) {
                updates.admin = this.account.admin._id;
            }
            this.ui.error = '';
            this.editingAccount = false;
            this.updateAccount(updates);
        }
    }

    display = ({ tab }: { tab: 'booking' | 'history' }) => {
        if (tab === 'booking') {
            this.showBookings = true;
            this.$timeout(() => {
                this.$scrollService('#bookings', '#bookings', 500);
            }, 0);
        } else if (tab === 'history') {
            this.showHistory = true;
            this.$timeout(() => {
                this.$scrollService('#history', '#history', 800);
            }, 0);
        }
    }

    displayFormattedDate = ({ date }) => {
        if (date === 'N/A') {
            return date;
        }
        return moment(Number(date)).format('MMM DD, YYYY');
    }

}

export const adminCreateEditAccountComponent = {
    template: require('./admin-create-edit-account.component.jade'),
    controller: AdminCreateEditAccountController,
    bindings: {
        ready: '<',
    },
};
