import { get, last, debounce } from 'lodash';
import { RawVenue } from 'lib/database/types/venue/venue';

// constant
import { ANALYTICS_EVENTS } from '../../constants/ENUMS/analyticsEvents';

export default ['$scope', '$timeout', '$api', '$locationSpy', '$location', 'ngDialog', 'ENUMS', '$analytics', 'unwrapError', '$rootScope', function($scope, $timeout, $api, $locationSpy, $location, ngDialog, ENUMS, $analytics, unwrapError, $rootScope) {
  $scope.addNewAdditionalHour = addNewAdditionalHour;
  $scope.addNewCourse = addNewCourse;
  $scope.cancelEdits = cancelEdits;
  $scope.changeAdditionalPrice = changeAdditionalPrice;
  $scope.changePrice = changePrice;
  $scope.enableValidation = enableValidation;
  $scope.last = last;
  $scope.removeCourse = removeCourse;
  $scope.removeNewAdditionalHour = removeNewAdditionalHour;
  $scope.saveDoc = saveDoc;
  $scope.validate = debounce(validate, 300);

  init();

  ///// Public Functions

  /**
   * Initialization function
   */
  function init() {
    $scope.ENUMS = ENUMS;
    $scope.uiModel = {
      additionalHourFees: []
    };
    $scope.errorState = { $count: 0 };

    $scope.doc = $scope.Record;
    if (get($scope.doc, 'data.additionalHourFees.length')) {
      $scope.uiModel.additionalHourFees =
        $scope.doc.data.additionalHourFees.map(function(fee) {
          return { price: (fee.priceInCents / 100).toFixed(2) };
        });
    }

    $scope.uiModel.price = $scope.doc.data.price;
    $scope.uiModel.extraHourFee = $scope.doc.data.extraHourFee;

    $locationSpy.register($scope.doc);
    if (!$scope.doc.data.courses.length) {
      $scope.addNewCourse();
    }

    $rootScope.$emit('$viewReady', 'HIDE_FOOTER');
  }


  /**
   * Validates a specific field in a drink. If there is an error pertaining
   * to that path, the error count for the directive will increase by 1. Otherwise,
   * the error count decreases. The error count must be 0 for the save button
   * to be enabled.
   *
   * @api public
   * @param {String} path, required
   * @param {Boolean} force, overrides shouldValidate if it is false
   *
   */
  function validate(path) {
    $scope.errorState[path] = $scope.errorState[path] || {};
    $api.Drinks.validate(Object.assign($scope.doc, { isVisible: true }))
      .then((data) => {
        const errors = get(data, 'errors.errors');
        const error = errors && errors[path];

        if (error) {
          if (!$scope.errorState[path].error) {
            ++$scope.errorState.$count;
          }
          $scope.errorState[path].error = error;
          return error;
        } else {
          if ($scope.errorState[path].error) {
            --$scope.errorState.$count;
          }

          $scope.errorState[path].error = null;
        }
      })
      .catch(error => unwrapError(error));


  }

  /**
   * Enables validation of a given field/path for a drink. It then validates the path.
   * Validation will not run on a path unless validation is first enabled, unless
   * the override (2nd parameter) is passed into `$scope.validate`.
   *
   * @param {String} path, required
   * @param {Boolean} val
   * @return {Void}
   */
  function enableValidation(path, val) {
    if (val == null) {
      val = true;
    }

    $scope.errorState[path] = $scope.errorState[path] || {};
    $scope.errorState[path].shouldValidate = val;

    $scope.validate(path);
  }

  function changePrice(price) {
    $scope.doc.data.price = price;
    $scope.doc.data.priceInCents = price * 100;
    $scope.validate('data.priceInCents');
  }

  /**
   * Changes the price of an additional hour fee. It ends by validating the field.
   *
   * @param {Number} index
   * @return {Void}
   */
  function changeAdditionalPrice(index) {
    $scope.doc.data.additionalHourFees[index].priceInCents =
      get($scope.uiModel.additionalHourFees[index], 'price') ?
        $scope.uiModel.additionalHourFees[index].price * 100 :
        0;
    $scope.validate('data.additionalHourFees.' + index + '.priceInCents');
  }

  /**
   * Adds an additional hour fee to a drink's array of additional hour fees.
   *
   * @return {Void}
   */
  function addNewAdditionalHour() {
    const price = $scope.doc.data.additionalHourFees.length ?
      last<{ price: number }>($scope.uiModel.additionalHourFees).price :
      $scope.doc.data.price || 0 ;

    $scope.doc.data.additionalHourFees.push({ priceInCents: price * 100 });
    $scope.uiModel.additionalHourFees.push({ price });
  }

  /**
   * Removes an additional hour fee from a drink package's additional hour fees
   * array. This function muates `$scope.doc` and `$scope.uiModel.additionalHourFees`.
   *
   * @param {Number} index
   * @return {Void}
   */
  function removeNewAdditionalHour(index) {
    $scope.doc.data.additionalHourFees.splice(index, 1);
    $scope.uiModel.additionalHourFees.splice(index, 1);
  }

  /**
   * Pushes a new empty object course to a drink's array of courses.
   * This mutates `$scope.doc`
   *
   * @return {Void}
   */
  function addNewCourse() {
    $scope.doc.data.courses.push({});
  }

  /**
   * Removes a course from a drink's array of courses
   *
   * @param {Number} index
   * @return {Void}
   */
  function removeCourse(index) {
    $scope.doc.data.courses.splice(index, 1);
  }

  /**
   * Saves the drink on the backend. It first validates the drink to make sure that
   * if the drink was originally visible and there are now errors, it will prevent
   * the save.
   *
   * @return {Promise}
   */
  function saveDoc() {
    $scope.doc.isVisible = true;
    return $api.Drinks
      .save($scope.doc)
      .then(function(data) {
        const drink = data.data.data;
        $scope.doc = drink;
        $locationSpy.unregister();
        return $api.Venues.get($scope.Slug);
      })
      .then(function(data) {
        const theVenue: RawVenue = get<RawVenue>(data, 'data.data.venue');
        const eventName = ANALYTICS_EVENTS.venueDashboard.savedDrink;
        const eventParams = new $analytics.$Drink($scope.doc, theVenue);
        $analytics.$trackEvent(eventName, eventParams);
        const url = `/user/venues?venue=${ theVenue.slug }`;
        $location.url(url);
      })
      .catch((error) => {
        unwrapError(error);
      });
  }

  /**
   * Cancels edits and changes location
   *
   * @param {String} url
   * @return {Void}
   */
  function cancelEdits(url) {
    $locationSpy.unregister();
    $location.url(url);
  }
}];
