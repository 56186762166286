// SixPlus Dependencies
import { ApiService } from 'spc/shared/api/api.service';
import { ToastService } from 'spc/shared/toast.service';
import { ConciergeVenueSearch } from './concierge-venue-search-modal.component';
import { RecosService } from 'spc/recos/recos.service';
import { ConciergeDashboardService } from './concierge-dashboard.service';
import BookingRequestHelpers from 'common/dist/virtuals/BookingRequest';

// SixPlus Types
import { RawCompany } from '../../../../database/types/company';
import { RawRecommendation } from '../../../../database/types/recommendation';

import { UpdateRecommendationRequestBody } from 'server/api/recos/models';

// External Dependencies
import ENUMS from 'common/dist/enums';
import LeadHelpers from 'common/dist/virtuals/Lead';

// NPM Dependencies
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { RecommendationService } from 'spc/shared/recommendation.service';
import { RawConversation } from 'spc/lib/database/types/conversation';
import { RawLead } from 'spc/lib/database/types/lead';
import { Message } from 'spc/lib/shared/interfaces/message.model';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { RawUser } from 'spc/lib/database/types/user';
import UserHelpers from 'common/dist/virtuals/User';
import { ServerSentEventsService } from 'spc/services/server-sent-events.service';

class ConciergeDashboardController {
  lastSession: {
    fullStoryUrl: string;
    createdTime: moment.Moment;
  };
  lead: RawLead;
  company: RawCompany;
  client: RawBaseUser | RawUser;
  suggestions: any;
  calendarMap: any;
  selectedSpaces: any[];
  leadId: string;
  stagedRecommendations: RawRecommendation[];
  submittedRecommendations: RawRecommendation[];
  recommendationMessageMap: {
    [key: string]: {
      lastVenueMessage: Message;
      lastClientMessage: Message;
      numClientMessages: number;
      numVenueMessage: number;
    }
  };
  messagebody: string;
  emailMessage: string;
  spaceOption: string;
  conciergeTeam: any[];
  ownerName: string;
  clientFirstName: string;
  statuses: string[];
  availabilities: string[];
  changes: any = {};
  associatedRequests: any[];
  editingFbMin: { editing: boolean; recommendation?: string; } = { editing: false };
  creatingProposal: boolean = false;
  openSidebarEdit: boolean;
  editingAvailability: { recommendation?: string };
  recoSpaceUpdates: any = {};
  editing: {
    visibility: boolean
  } = { visibility: false };
  recosListener: EventSource;
  actionsBox: any = {};
  ui: {
    sendingMessage: boolean,
    viewState: 'manage' | 'recommend' | 'message'
  } = {
      sendingMessage: false,
      viewState: 'recommend'
    };
  domain: string;
  clientOwner: string;
  thumbnailUrl: string = ';http://res.cloudinary.com/dbwijvnzn/image/upload/a_exif,c_fill,g_center,q_auto,w_1024,f_auto/v1636875618/Group_28_1_trb7se.png';
  componentIsReady: () => any;

  constructor(
    private $api: ApiService,
    private conciergeVenueSearchModal: ConciergeVenueSearch,
    private unwrapError,
    private $cloudinary,
    private toast: ToastService,
    private recosService: RecosService,
    private conciergeDashboardService: ConciergeDashboardService,
    private $interval,
    private recommendationService: RecommendationService,
    private spPrinter,
    private venueNotesModal,
    private $clickOk,
    private recoOrderModal,
    private $location,
    private serverSentEventsService: ServerSentEventsService,
    private venueAttachmentModal,
    private recoNotesModal
  ) {
    'ngInject';
    this.statuses = ENUMS.leadStatuses;
    this.availabilities = ENUMS.concierge.availabilities;
  }

  $onInit() {
    this.getAllOwners();
    return this.fetchAndSetData()
      .then(() => {
        this.componentIsReady();
        this.setAdminLastTouched();
        this.spaceOption = `SELECT`;
        this.setRecosListener();
        if (this.submittedRecommendations.length) {
          this.ui.viewState = 'manage';
        }
        this.domain = this.setDomain();
      }).catch(error => this.unwrapError(error));
  }

  $onDestroy = () => {
    this.serverSentEventsService.stopTrackingLead(this.recosListener);
  }


  setDomain = () => {
    let domain = `${this.$location.protocol()}://${this.$location.host()}`;
    if (this.$location.port() !== 443) {
      domain = `${domain}:${this.$location.port()}`;
    }
    return domain;
  }

  openVenueSearchModal = () => {
    const recommendations = this.stagedRecommendations.concat(this.submittedRecommendations);
    return this.conciergeVenueSearchModal(recommendations, this.lead)
      .then((response) => {
        if (response.value.cancel || response.value === '$closeButton' || response.value === '$escape') {
          return this.fetchAndSetData();
        }
        this.setData(response.value);
      })
      .catch(error => this.unwrapError(error));
  }

  openRecoNotesModal = (recommendation) => {
    return this.recoNotesModal(recommendation._id)
      .then(this.fetchAndSetData)
      .catch(err => this.unwrapError(err));
  }

  // API Calls
  fetchAndSetData = (): ng.IPromise<any> => {
    return this.$api.Admin.Leads.getOne(this.leadId)
      .then(response => this.setData(response))
      .catch(error => this.unwrapError(error));
  }

  messageHandler = (e) => {
    if (e.data === 'tracking') {
      return;
    }
    this.$api.Recos.getRecos(this.leadId)
      .then((response) => {
        if (response.recommendationMessageMap) {
          this.recommendationMessageMap = response.recommendationMessageMap;
        }
        this.setSubmittedRecommendations(response.recommendations);
        this.lead.messages = response.messages;
        this.lead.state = response.state;
      })
      .catch(error => this.unwrapError(error));
  }

  setAdminLastTouched = () => {
    return this.$api.Admin.Leads.adminLastTouch(this.lead._id.toString())
      .catch(error => this.unwrapError(error));
  }

  setRecosListener = () => {
    this.serverSentEventsService.stopTrackingLead(this.recosListener);
    this.recosListener = this.serverSentEventsService.startTrackingLead(this.lead);
    this.serverSentEventsService.setMessageHandler({
      listener: this.recosListener,
      messageHandler: this.messageHandler
    });

  }

  submitRecommendations = ({ sendEmail }: { sendEmail?: boolean }): ng.IPromise<any> => {
    const recos = this.stagedRecommendations.map(reco => reco._id.toString());
    return this.$api.Admin.Leads.submitRecommendations(this.lead._id.toString(), {
      recommendations: recos,
      sendEmail,
      emailMessage: sendEmail ? this.emailMessage : null
    })
      .then((response) => {
        this.setData(response);
        this.spaceOption = `SELECT`;
        this.toast.goodNews('Recommendations Submitted Successfully', `Your recommendations can now be viewed by the client. If you sent an email, your client has received it.`);
      })
      .catch((error) => {
        this.toast.badNews('Recommendations Not Submitted', `There was an error with submitting your recommendations.`);
        this.unwrapError(error);
      });
  }

  updateStatus = async (status) => {

    const isValid = this.validateStatuses(this.lead.statuses, status);

    if (!isValid) {
      return;
    }

    let promptResponse;
    if (status === 'Booked') {
      promptResponse = await this.$clickOk('You are manually toggling the booked status. This will cancel all outstanding proposals and send cancellation emails to those venues. Click cancel if you do not want to continue.', true);
    }

    if (promptResponse && get(promptResponse, 'value.cancel')) {
      return;
    }

    return this.$api.Admin.Leads.updateStatus(this.lead, status)
      .then((data) => {
        this.lead.statuses = data.statuses;
        this.lead.currentStatus = data.currentStatus;
        this.lead.admin = data.admin;
        this.toast.goodNews(`Status Changed Successfully`, `This lead now is now has the ${this.lead.currentStatus} status`);
      })
      .catch((error) => {
        this.toast.badNews(`Status Change Failed`, `There was an error with changing the lead status.`);
        this.unwrapError(error);
      });
  }

  updateRecommendation = ({ recommendation, changes }: { recommendation: RawRecommendation, changes: UpdateRecommendationRequestBody }): ng.IPromise<any> => {
    return this.$api.Recos.updateRecommendation(recommendation._id.toString(), changes)
      .catch(error => this.unwrapError(error));
  }

  updateAvailability = (recommendation: RawRecommendation): ng.IPromise<any> => {
    const availabilityState = recommendation.lastAvailability.value;
    return this.$api.Admin.Leads.updateAvailability({ leadId: this.lead._id.toString(), recommendationId: recommendation._id.toString(), availabilityState })
      .then(response => recommendation.lastAvailability = response.recommendation.lastAvailability)
      .catch(error => this.unwrapError(error));
  }

  updateCustomFbMin = (recommendation): ng.IPromise<any> => {
    const changes = this.changes;
    return this.updateRecommendation({ recommendation, changes })
      .then((response) => {
        recommendation.customFbMin = response.recommendation.customFbMin;
        this.editingFbMin = { editing: false, recommendation: null };
      });
  }

  sendMessage = (): ng.IPromise<any> => {
    if (!this.messagebody) {
      return;
    }
    const data = {
      message: this.messagebody,
      client: this.lead.primaryClient,
      leadId: this.lead._id.toString()
    };
    this.ui.sendingMessage = true;
    return this.$api.Recos.sendMessage(data)
      .then(this.setData)
      .then(() => {
        this.calculateActivityIndicator();
        this.ui.sendingMessage = false;
      })
      .catch((error) => {
        this.ui.sendingMessage = false;
        this.unwrapError(error);
      });
  }

  clearUnread = (): ng.IPromise<any> => {
    const leanLead = {
      _id: this.lead._id.toString(),
      state: {
        messages: {
          unreadBySixplus: false
        }
      }
    };
    return this.$api.Admin.Leads.update(leanLead)
      .then(response => this.setData(response))
      .catch(error => this.unwrapError(error));
  }


  getAllOwners = (): ng.IPromise<any> => {
    return this.$api.Admin.Leads.getAllOwners()
      .then(response => this.conciergeTeam = response.conciergeTeam)
      .catch(error => this.unwrapError(error));
  }

  archive = (recommendation) => {
    return this.recommendationService.archive(recommendation);
  }

  unarchive = (recommendation) => {
    return this.recommendationService.unarchive(recommendation);
  }

  toggleVisibility = ({ recommendation, isVisible }: { recommendation: RawRecommendation, isVisible: boolean }): ng.IPromise<any> => {
    if (this.editing.visibility) {
      return;
    }
    this.editing.visibility = true;
    const changes = { isVisible, activity: { comments: false, interest: false } };
    return this.updateRecommendation({ recommendation, changes })
      .then((response) => {
        recommendation.isVisible = response.recommendation.isVisible;
        recommendation.activity = response.recommendation.activity;
        if (!recommendation.isVisible) {
          this.lead.recommendations = this.lead.recommendations.filter(reco => reco._id.toString() !== recommendation._id.toString());
          this.setSubmittedRecommendations(this.lead.recommendations);
        }
        this.toggleActionsBox(recommendation._id.toString());
        this.calculateActivityIndicator();
        this.editing.visibility = false;
      })
      .catch(error => this.unwrapError(error));
  }

  markRead = (recommendation: RawRecommendation, key: string) => {
    const path = `activity.${key}`;
    const changes = {};
    changes[path] = false;
    return this.updateRecommendation({ recommendation, changes })
      .then((response) => {
        recommendation.activity = response.recommendation.activity;
        this.calculateActivityIndicator();
      });
  }

  toggleFbMinVisibility = (recommendation: RawRecommendation): ng.IPromise<any> => {
    const changes = { visibleFbMin: !recommendation.visibleFbMin };
    return this.updateRecommendation({ recommendation, changes })
      .then(response => recommendation.visibleFbMin = response.recommendation.visibleFbMin)
      .catch(error => this.unwrapError(error));
  }

  createProposal = (recommendation: RawRecommendation): ng.IPromise<any> => {
    this.creatingProposal = true;
    return this.$api.Admin.Leads.createProposal({
      lead: this.lead._id.toString(), recommendation: recommendation._id.toString(), proposalInfo: {
        visibility: { client: true, venue: true }
      }
    })
      .then((response) => {
        this.creatingProposal = false;
        this.setData(response);
      })
      .catch((error) => {
        this.creatingProposal = false;
        this.unwrapError(error);
      });
  }

  createSilentProposal = ({ recommendation, replaceOriginalConversation }: { recommendation: RawRecommendation, replaceOriginalConversation?: boolean }): ng.IPromise<any> => {
    if (this.creatingProposal === true) {
      return;
    }

    this.creatingProposal = true;
    return this.$api.Admin.Leads.createProposal({
      lead: this.lead._id.toString(),
      recommendation: recommendation._id.toString(),
      proposalInfo: {
        visibility: {
          client: !replaceOriginalConversation,
          venue: !replaceOriginalConversation
        },
        replaceOriginalConversation,
        isSilentRequest: true
      }
    })
      .then((response) => {
        this.creatingProposal = false;
        this.setData(response);
      })
      .catch((error) => {
        this.creatingProposal = false;
        this.unwrapError(error);
      });
  }

  allowReplaceOriginalConversation = (recommendation) => {
    if (this.lead.currentStatus === 'Booked') {
      return;
    }

    if (!recommendation.conversation) {
      return;
    }

    if (get(recommendation, 'admin.wasDirectBooking')) {
      return;
    }

    return true;
  }

  allowCreateSilentProposals = (recommendation) => {
    if (this.lead.currentStatus === 'Booked') {
      return;
    }

    if (recommendation.conversation) {
      return;
    }

    if (get(recommendation, 'admin.wasDirectBooking')) {
      return;
    }

    return true;
  }

  reorderRecos = () => {
    let allRecos = [...this.stagedRecommendations, ...this.submittedRecommendations];
    allRecos = allRecos.filter(reco => reco.archived === false);
    return this.recoOrderModal(allRecos, this.lead._id)
      .then(response => response.value && Array.isArray(response.value) ? this.setSubmittedRecommendations(response.value) : null);
  }

  // Helpers
  toggleSidebar = () => {
    this.openSidebarEdit = !this.openSidebarEdit;
  }

  toggleActionsBox = (recoId) => {
    this.actionsBox[recoId] = !this.actionsBox[recoId];
  }
  setData = (response): void => {
    if (response.suggestions) {
      this.suggestions = response.suggestions;
    }
    if (response.calendars) {
      this.calendarMap = groupBy(response.calendars, (calendar: { space: any }) => calendar.space.toString());
    }

    if (response.lastSession) {
      const { FsUrl, CreatedTime } = response.lastSession;
      this.lastSession = {
        fullStoryUrl: FsUrl,
        createdTime: moment.unix(CreatedTime)
      };
    }

    if (response.recommendationMessageMap) {
      this.recommendationMessageMap = response.recommendationMessageMap;
    }

    this.actionsBox = {};
    this.lead = response.lead;
    const recommendations = this.lead.recommendations;
    this.stagedRecommendations = recommendations.filter(reco => !reco.isRecommended);
    this.setSubmittedRecommendations(recommendations);
    this.lead.messages = this.lead.messages;
    this.messagebody = '';
    this.emailMessage = `Looking forward to helping you find a great venue!`;
    this.client = this.lead.primaryClient as RawBaseUser;
  }

  getClientLink = (reco) => {
    const clientLink = `${this.domain}/client/conversation/${reco.conversation._id.toString()}`;
    if (!UserHelpers.isActualUser(this.client)) {
      return `${clientLink}/preview/`;
    }
    return clientLink;
  }

  getVenueLink = (reco) => {
    return `${this.domain}/venue/conversation/${reco.conversation._id.toString()}`;
  }

  getRecoCity = (reco) => {
    return get(reco, 'venue.data.address.city');
  }

  setSubmittedRecommendations = (recommendations: RawRecommendation[]) => {
    this.submittedRecommendations = recommendations
      .filter(reco => reco.isRecommended)
      .map((reco) => {
        if (reco.space) {
          reco.space.availability = this.conciergeDashboardService.getSpaceAvailability({ space: reco.space, lead: this.lead, calendarMap: this.calendarMap });
          reco.space.timeSlot = this.conciergeDashboardService.getTimeSlotTerms(reco.space, this.lead);

          if (reco.conversation) {
            const space = BookingRequestHelpers.selectedSpace((reco.conversation as RawConversation).request);
            if (space && space._id !== reco.space._id) {
              this.recoSpaceUpdates[reco._id.toString()] = reco.space.data.name;
              reco.space = space;
            }
          }
        }

        reco.clientResponses = reco.clientResponses.reverse();

        return reco;
      });
  }

  calculateActivityIndicator = () => {
    this.lead.activityIndicator = LeadHelpers.createActivityIndicator({ recommendations: this.submittedRecommendations });
  }

  getDisplayUrl = (recommendation): string => {
    return recommendation.space.data.photos.length ? recommendation.space.data.photos[0].url : this.thumbnailUrl;
  }

  getFBMin = (space): number => {
    if (space) {
      return null;
    }
    return this.recosService.getFbMinFromLead({ space, lead: this.lead });
  }

  leadUpdate = (lead: RawLead): void => {
    this.setData({ lead });
  }

  changeSpaceOption = (tab: string) => {
    this.spaceOption = tab;
  }

  changeViewState = (tab: 'manage' | 'recommend' | 'message'): ng.IPromise<any> => {
    this.ui.viewState = tab;
    if (tab === `message`) {
      return this.clearUnread()
        .catch(error => this.unwrapError(error));
    }
  }

  allowProposalCreation = (recommendation: RawRecommendation): boolean => {
    if (recommendation.conversation) {
      return false;
    }

    if (recommendation.isAvailable) {
      return true;
    }
    return false;
  }

  openAttachmentsModal = (reco) => {
    this.venueAttachmentModal(reco)
      .then((data) => {
        if (!data.value.attachments) {
          return;
        }
        const attachments = data.value.attachments;
        this.submittedRecommendations = this.submittedRecommendations.map((recommendation) => {
          if (recommendation._id === reco._id) {
            recommendation.attachments = attachments;
          }
          return recommendation;
        });
      });
  }

  private hasBothStatuses = ({ newStatuses, firstStatusPath, secondStatusPath }) => {
    return get(newStatuses, firstStatusPath) && get(newStatuses, secondStatusPath);
  }


  validateStatuses = (statuses = {}, status) => {
    // This is apparently the best way to deepclone an object.
    const newStatuses = JSON.parse(JSON.stringify(statuses));

    newStatuses[status] = newStatuses[status] || { value: false };
    newStatuses[status].value = !newStatuses[status].value;

    const isBothICGFAndICBF = this.hasBothStatuses({ newStatuses, firstStatusPath: `ICBF.value`, secondStatusPath: `ICGF.value` });


    if (isBothICGFAndICBF) {
      this.toast.badNews(`Mutual Exclusivity Error`, `A lead cannot be both a good fit and a bad fit`);
      return false;
    }

    const isBothKilledAndLost = this.hasBothStatuses({ newStatuses, firstStatusPath: `Killed.value`, secondStatusPath: `Lost.value` });

    if (isBothKilledAndLost) {
      this.toast.badNews(`Mutual Exclusivity Error`, `A lead cannot be both killed and lost.`);
      return false;
    }

    const isBothBookedAndLost = this.hasBothStatuses({ newStatuses, firstStatusPath: `Booked.value`, secondStatusPath: `Lost.value` });

    if (isBothBookedAndLost) {
      this.toast.badNews(`Mutual Exclusivity Error`, `A lead cannot be both booked and lost.`);
      return false;
    }

    const isBothKilledAndBooked = this.hasBothStatuses({ newStatuses, firstStatusPath: `Killed.value`, secondStatusPath: `Booked.value` });

    if (isBothKilledAndBooked) {
      this.toast.badNews(`Mutual Exclusivity Error`, `A lead cannot be both killed and booked.`);
      return false;
    }

    if ((get(newStatuses, `Killed.value`) || get(newStatuses, `Lost.value`)) && !this.lead.admin.closeReason) {
      this.toast.badNews(`Close Reason Required`, 'A lead needs to have a close reason for it to be lost or killed.');
      return false;
    }

    return true;
  }

  editFbMin = (recommendation) => {
    this.editingFbMin = {
      editing: true,
      recommendation: recommendation._id
    };
    this.changes.customFbMin = (recommendation.customFbMin === null || recommendation.customFbMin === undefined) ? this.getFBMin(recommendation.space) : recommendation.customFbMin;
  }

  startEditAvailability = (recommendation) => {
    this.editingAvailability = {
      recommendation: recommendation._id
    };
  }
  displayCreateProposalButton = (recommendation) => {
    return !recommendation.conversation && recommendation.isVisible && recommendation.venue.isVisible;
  }

  isCancelledProposal = (recommendation) => {
    if (recommendation.conversation) {
      return ENUMS.bookingRequestState.cancelledStates.includes(recommendation.conversation.requestState);
    }
  }

  isConfirmedProposal = (recommendation) => {
    if (recommendation.conversation) {
      return ENUMS.bookingRequestState.bookedStates.includes(recommendation.conversation.requestState);
    }
  }

  updateOwner = (ownerName, ownerId) => {
    if (!this.lead.primaryClient['owner']) {
      this.clientOwner = ownerName;
      this.lead.primaryClient['owner'] = ownerId;
    }
  }

  findBookedVenue = (lead) => {
    const bookedReco = lead.recommendations.find(this.isConfirmedProposal);
    return bookedReco && bookedReco.venue.data.name;
  }
}

export const ConciergeDashboardComponent = {
  controller: ConciergeDashboardController,
  template: require('./concierge-dashboard.component.jade'),
  bindings: {
    componentIsReady: '&',
    leadId: '<'
  }
};
