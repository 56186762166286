
export default ['ngDialog', function addVenueModal(ngDialog) {
  return function (venue) {
    ngDialog.open({
      template: '<add-venue-to-list venue="$ctrl.venue" close="$ctrl.close()"></add-venue-to-list>',
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal avm-dialog',
      plain: true,
      overlay: true,
      closeByNavigation: true,
      controller: ['$scope', function ($scope) {
        this.venue = venue;
        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];
