export const editGuestsModal = ['ngDialog', function (ngDialog) {
  return (scope) => {
    return ngDialog.open({
      template: require('./edit-guests.modal.jade'),
      className: 'ngdialog-theme-small modal-venue-conversation',
      overlay: true,
      plain: true,
      scope: scope
    }).closePromise;
  };
}];
