export const menuGridResize = ['$window', '$document', '$timeout', ($window, $document, $timeout) => {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      $window.addEventListener('resize', () => {
        const grid = document.getElementsByClassName('cccp-mm-courses')[0];
        const rowHeight = parseInt($window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'), 10);
        const rowGap = parseInt($window.getComputedStyle(grid).getPropertyValue('grid-row-gap'), 10);
        const rowSpan = Math.ceil((element[0].querySelector('.cccp-course-item').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
        element[0].style.gridRowEnd = 'span ' + rowSpan;
      });

      $timeout(() => {
        const grid = document.getElementsByClassName('cccp-mm-courses')[0];
        const rowHeight = parseInt($window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'), 10);
        const rowGap = parseInt($window.getComputedStyle(grid).getPropertyValue('grid-row-gap'), 10);
        const rowSpan = Math.ceil((element[0].querySelector('.cccp-course-item').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
        element[0].style.gridRowEnd = 'span ' + rowSpan;
      });
    }
  };
}];
