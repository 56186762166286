import BookingRequestHelper from 'common/dist/virtuals/BookingRequest';
import AuthorizedClientHelpers from 'common/dist/virtuals/AuthorizedClient';
import { get, find } from 'lodash';

// constant
import { REFUND_TITLES } from './refundTitles';
import { convertURL } from '../services/convertUrl';
class ReceiptsController {
  handleClick: ({ location }: { location: string }) => any;
  convertUrl: (url) => string;
  selectedPayment: any;
  request: any;
  client: any;
  actualLink: boolean;
  actualUpload: boolean;
  finalReceipt: boolean;
  resolutionTransaction: boolean;
  attachments: any;
  types: string[];

  constructor(private $cloudinary, private $location, private ENUMS, private $scope, private unwrapError) {
    'ngInject';
  }

  $onInit = () => {
    this.convertUrl = convertURL;
    if (this.request.data.payment.attachments.length) {
      const attachments = this.request.data.payment.attachments;
      this.types = attachments.map(attachment => attachment.type).filter((type, index, types) => types.indexOf(type) === index);

      Promise.all(attachments.map((attachment) => {
        if (attachment.type !== 'Direct' && this.$cloudinary.isPDF(attachment)) {
          attachment = this.getPageCount(attachment);
        }
        return attachment;
      }))
        .then((res) => {
          this.attachments = res;
          this.$scope.$apply();
        });
    }
  }

  getPageCount = (attachment) => {
    return this.$cloudinary.getPageCount(attachment)
      .then(res => res)
      .catch(error => this.unwrapError(error));
  }

  $onChanges = () => {
    const location = this.$location.search().receipt;
    this.handleLocation(location);
    this.client = AuthorizedClientHelpers.primaryEventContact(this.request).user;
  }

  handleReceiptClick = ({ payment, finalReceipt, actualLink, actualUpload, resolutionTransaction }) => {
    this.finalReceipt = finalReceipt || false;
    this.actualLink = actualLink || false;
    this.actualUpload = actualUpload || false;
    this.selectedPayment = payment || null;
    this.resolutionTransaction = resolutionTransaction || false;

    if (resolutionTransaction) {
      this.handleClick({ location: `${resolutionTransaction.paymentType.toLowerCase().replace(/\s/g, '-')}-receipt` });
    }

    if (payment && payment.state === 'REFUNDED') {
      const title = REFUND_TITLES[payment.descriptor] || 'intermediate refund';
      this.handleClick({ location: `${title.toLowerCase().replace(/\s/g, '-')}-receipt` });
    } else if (payment) {
      this.handleClick({ location: `${payment.title.toLowerCase()}-receipt` });
    }

    if (finalReceipt) {
      this.handleClick({ location: 'final-receipt' });
    }

    if (actualLink) {
      this.handleClick({ location: 'restaurant-receipt-link' });
    }

    if (actualUpload) {
      this.handleClick({ location: 'restaurant-receipt-upload' });
    }
  }

  eventIsComplete = () => {
    const completeEventStates = this.ENUMS.bookingRequestState.postPaymentStates.concat(this.ENUMS.bookingRequestState.cancelledPostDepositStates);
    return completeEventStates.includes(this.request.state);
  }

  handleLocation = (location) => {
    const depositPayment = BookingRequestHelper.depositPayment(this.request);
    const balancePayment = BookingRequestHelper.balancePayment(this.request);
    const overagePayment = BookingRequestHelper.overagePayment(this.request);
    const intermediatePayment = BookingRequestHelper.intermediatePayment(this.request);

    const schedule = get(this.request, 'data.payment.schedule', []);
    const depositRefund = find(schedule, s => s.descriptor === 'DEPOSIT_REFUNDED_PARTIAL' || s.descriptor === 'DEPOSIT_REFUNDED_FULL');
    const balanceRefund = find(schedule, s => s.descriptor === 'BALANCE_REFUNDED_PARTIAL' || s.descriptor === 'BALANCE_REFUNDED_FULL');
    const intermediateRefund = find(schedule, s => s.title === 'REFUND' && (s.descriptor.includes('INTERMEDIATE_REFUND_FULL') || s.descriptor.includes('INTERMEDIATE_REFUND_PARTIAL')));
    const cancellationRefund = find(schedule, s => s.descriptor === 'CANCELLATION_REFUND' || s.descriptor === 'CANCELLATION_REFUND');
    const reconciliationRefund = find(schedule, s => s.descriptor === 'RECONCILIATION_REFUND' || s.descriptor === 'RECONCILIATION_REFUND');

    if (!location) {
      if (this.eventIsComplete()) {
        this.handleReceiptClick({ payment: null, finalReceipt: true, actualLink: false, actualUpload: false, resolutionTransaction: false });
      } else {
        balancePayment ? this.handleReceiptClick({ payment: balancePayment, finalReceipt: false, actualLink: false, actualUpload: false, resolutionTransaction: false }) : this.handleReceiptClick({ payment: depositPayment, finalReceipt: false, actualLink: false, actualUpload: false, resolutionTransaction: false });
      }
    }

    const selectedPaymentLocations = {
      'deposit-receipt': depositPayment,
      'balance-receipt': balancePayment,
      'overage-receipt': overagePayment,
      'intermediate-receipt': intermediatePayment,
    };

    const selectedRefundLocations = {
      'deposit-refund-receipt': depositRefund,
      'balance-refund-receipt': balanceRefund,
      'intermediate-refund-receipt': intermediateRefund,
      'cancellation-refund-receipt': cancellationRefund,
      'refund-receipt': reconciliationRefund
    };

    this.actualLink = location === 'restaurant-receipt-link';
    this.actualUpload = location === 'restaurant-receipt-upload';
    this.finalReceipt = location === 'final-receipt';
    this.resolutionTransaction = location === 'refund-receipt';
    this.selectedPayment = selectedPaymentLocations[location] || selectedRefundLocations[location] || null;

  }
}

export const allReceiptsComponent = {
  template: require('./receipts.component.jade'),
  controller: ReceiptsController,
  bindings: {
    request: '<',
    handleClick: '&',
    location: '<'
  }
};
