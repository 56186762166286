import * as angular from 'angular';

import ConciergeModule from './concierge/concierge.module';
import HomeModule from './home/home.module';
import CityHomeModule from './city-home/city-home.module';
import SearchModule from './search/search.module';
import { VenueViewModule } from 'spc/venue-view';
import { MessagingModule } from './messaging';
import { EventsDashboardModule } from './events-dashboard/events-dashboard.module';
import { AvailabilityCalendarDashboardModule } from './availability-calendar/availability-calendar-dashboard.module';
import { ConciergeRequestsDashboardModule } from './concierge-requests-dashboard/concierge-requests-dashboard.module';
import { leadAuthenticationComponent } from './lead-login/lead-authentication.component';
import leadAuthenticationModal from './lead-login/lead-authentication.modal';
import { UserConnectionsComponent } from './search/user-search/user-connections.component';


export default angular
  .module('components', [
    HomeModule,
    CityHomeModule,
    ConciergeModule,
    SearchModule,
    VenueViewModule,
    MessagingModule,
    EventsDashboardModule,
    AvailabilityCalendarDashboardModule,
    ConciergeRequestsDashboardModule,
  ])
  .component('leadAuthentication', leadAuthenticationComponent)
  .component('userConnections', UserConnectionsComponent)
  .service('leadAuthenticationModal', leadAuthenticationModal)
  .name;
