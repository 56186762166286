// NPM dependencies
import BookingRequestHelpers from 'common/dist/virtuals/BookingRequest';
import iterateHalfHours from 'common/dist/iterateHalfHours';

// Lodash dependencies
import { forEach, find, findIndex, get, isNil, isNumber, set, unset, map } from 'lodash';

/**
 * Business logic service for select time host component
 */
export class SelectTimeHostService {
  constructor() {
    'ngInject';
  }


  /**
   * Can pick a start or end time, and can also unselect
   * a time or slot based on what the user clicks.
   * 
   * @public
   * @param {Object} request
   * @param {Array} timeSlots
   * @param {Object} time
   * @param {Object} slot
   */
  pickTime(request, timeSlots, time, slot) {
    const service = this;
    const funcName = `selectTimeHostService.pickTime`;
    if (!request) {
      throw new Error(`${funcName} requires a request to be passed in as an argument`);
    }

    if (!timeSlots) {
      throw new Error(`${funcName} requires an array of time slots to be passed in as an argument`);
    }

    if (!time) {
      throw new Error(`${funcName} requires a time to be selected`);
    }

    if (!slot) {
      throw new Error(`${funcName} requires a slot in which a time was selected`);
    }

    // Case 1: Picked time is the end time and it is inside the selected slot

    if (time.isEndTime && slot.isSelected) {
      unset(request, 'data.duration');
      time.isEndTime = false;
      return;
    }

    // Case 2: The slot is not selected OR (there is a start time AND a duration)
    if (!slot.isSelected || (isNumber(request.data.time) && request.data.duration)) {
      service.unselectSelectedSlot(request, timeSlots);
      service.setStartTime(request, time, slot);
      return;
    }

    if (time.isStartTime) {
      service.unselectSelectedSlot(request, timeSlots);
      return;
    } else if (!isNumber(request.data.time)) {
      service.setStartTime(request, time, slot);
    } else if (!get(request, 'data.duration')) {
      const pickedTime = time.time;
      if (pickedTime < request.data.time) {
        const startTime = service.getStartTime(slot.times);
        service.setStartTime(request, time, slot);
        service.setEndTime(request, startTime);
        return;
      }
      service.setEndTime(request, time);
    }
  }

  /**
   * @desc Determines if a given slot is booked for a given request
   * 
   * @public
   * @param {Object} request
   * @param {Object} slot, a time slot
   * @return {Boolean}
   */
  isBooked(request, slot) {
    if (!request) {
      throw new Error('No request passed to SelectTimeHostService.isBooked');
    }
    if (!slot) {
      throw new Error('No time slot passed to SelectTimeHostService.isBooked');
    }
    const requestId = get(slot, 'booking.requestId');
    return requestId && requestId !== request._id.toString();
  }

  /**
   * @desc Sets a given time as the start time in an array of times in a slot
   * 
   * @public
   * @param {Request} request
   * @param {Object} time
   * @param {Object} slot
   * @return {Number|undefined}
   */
  setStartTime(request, time, slot) {
    const service = this;
    request.data.time = time.time;
    slot.isSelected = true;
    time.isStartTime = true;
    if (time.isEndTime) {
      time.isEndTime = false;
    }

    service.setRoomFee(request, slot);
    // get slots from `ctrl.slots` in case there's a new slot that doesnt exist
    // on the static space
    const slots = get(request, 'selectedSpace.data.timeSlots', []);
    if (slots && slots.length) {
      const idx = findIndex(slots,
        s => s._id.toString() === slot._id.toString());
      request.data.timeSlot = idx;
      return idx;
    }
  }

  /**
   * @desc Marks a given time as the end time for an event
   * 
   * @private
   * @param {Object} request
   * @param {Object} time
   * @return {Void}
   */
  setEndTime(request, time) {
    if (!isNumber(request.data.time)) {
      return;
    }

    const durationInMinutes = BookingRequestHelpers
      .getDurationMinsFromStartAndEndTime(request.data.time, time.time);
    set(request, 'data.duration', durationInMinutes);

    if (time.isStartTime) {
      time.isStartTime = false;
    }
    time.isEndTime = true;
  }

  /**
   * @desc Determines if a given time slot is the selected time slot
   * 
   * @public
   * @param {Object} request
   * @param {Object} slot
   * @return {Boolean}
   */
  isSelectedTimeSlot(request, slot) {
    const funcName = 'SelectTimeHostService.isSelectedTimeSlot';
    if (!request) {
      throw new Error(`${funcName} requires a request to be passed in as an argument`);
    }
    if (!slot) {
      throw new Error(`${funcName} requires a time slot to be passed in as an argument`);
    }

    const selected = BookingRequestHelpers.selectedTimeSlot(request);
    return selected && slot._id.toString() === selected._id.toString();
  }

  /**
   * @desc Gets the selected time slot from an array of time slots
   * 
   * @public
   * @param {Object} request
   * @param {Array} timeSlots
   * @return {Object|undefined}
   */
  getSelectedSlot(request, timeSlots) {
    if (isNil(request.data.timeSlot)) {
      return;
    }

    const currentSlot = BookingRequestHelpers.selectedTimeSlot(request);
    if (!currentSlot) {
      return;
    }

    return find(timeSlots,
      slot => slot._id.toString() === currentSlot._id.toString());
  }

  /**
  * Get the start time object from an array of times
  *
  * @api public
  * @param {Array} times
  * @return {Object}
  */
  getStartTime(times) {
    return find(times, time => time.isStartTime);
  }

  /**
   * Get the end time object from an array of times
   *
   * @public
   * @param {Array} times
   * @return {Object}
   */
  getEndTime(times) {
    return find(times, time => time.isEndTime);
  }

  unselectSelectedSlot(request, timeSlots) {
    const service = this;
    const selectedSlot = service.getSelectedSlot(request, timeSlots);
    if (selectedSlot) {
      unset(request, 'data.timeSlot');
      unset(request, 'data.time');
      selectedSlot.isSelected = false;
      const startTime = service.getStartTime(selectedSlot.times);
      if (startTime) {
        startTime.isStartTime = false;
      }

      if (request.data.duration) {
        unset(request, 'data.duration');
        const endTime = service.getEndTime(selectedSlot.times);
        if (endTime) {
          endTime.isEndTime = false;
        }
      }

      //importANT : we need to undo highlight between if we deselect a slot
      forEach(selectedSlot.times,
        t => t.highlightBetween ? unset(t, 'highlightBetween') : '');
    }
  }

  /**
   * @desc Creates a time object that lives in the array of times to be selected
   * 
   * @public
   * @param {Object} request
   * @param {Object} time
   * @param {Slot} slot
   * @return {Object}
   */
  createTimeObject(request, time, slot) {
    const service = this;
    return {
      time: time,
      isEndTime: service.isEndTime(request, time, slot),
      isStartTime: request.data.time === time && slot.isSelected
    };
  }

  /**
   * @desc Finds the index of a given slot from an array of time slots
   * 
   * @public
   * @param {Array} slots
   * @param {Object} slot
   * @return {Number}
   */
  findSlotIdx(slots, slot) {
    return findIndex(slots,
      s => s._id.toString() === slot._id.toString());
  }

  getTimes(request, slot) {
    const service = this;
    return map(iterateHalfHours(slot.from, slot.to), time => service.createTimeObject(request, time, slot));
  }
  /**
   * @desc Determines if a given time object is the end time for an event
   * 
   * @public
   * @param {Object} request
   * @param {Object} time
   * @param {Object} slot
   * @return {Boolean}
   */
  isEndTime(request, time, slot) {
    if (!slot.isSelected) {
      return false;
    }

    let startTime = get(request, 'data.time');
    if (!isNumber(startTime)) {
      return false;
    }

    let duration = get(request, 'data.duration');
    if (!duration) {
      return false;
    }

    const expectedEndTime = BookingRequestHelpers.getEndTime(request);
    return expectedEndTime === time;
  }

  setRoomFee(request, newSlot) {
    if (!request) {
      throw new Error('SelectTimeHostService.setRoomFee requires a request to be passed in as an argument');
    }

    if (!newSlot) {
      throw new Error('SelectTimeHostService.setRoomFee requires a newSlot to be passed in as an argument');
    }
    const newRoomFee = get(newSlot, 'terms.roomFeeCents');
    if (isNumber(newRoomFee)) {
      set(request, 'host.roomFeeCents', newRoomFee);
    } else {
      set(request, 'host.roomFeeCents', undefined);
    }
  }

  /** 
   * Highlights a given time within the array of timeslots that live on the vm
   *
   * @api public
   * @param {Request} request
   * @param {Time} time object
   * @param {Slot} slot
   * @return {Void}
   */
  highlightTime(request, time, slot) {
    const service = this;
    if (slot.isSelected && !get(request, 'data.duration')) {
      service.toggleBetweenTimesHighlight(time, slot);
    }
  }

  /**
   * Unhighlights a given time within the array of timeslots living on the vm
   *
   * @public
   * @param {Request} request
   * @param {Time} time object
   * @param {Slot} slot
   * @return {Void}
   */
  unhighlightTime(request, time, slot) {
    const service = this;
    if (slot.isSelected && !get(request, 'data.duration')) {
      service.toggleBetweenTimesHighlight(time, slot);
    }
  }
  /**
   * @desc Toggles highlighted property of times in a slot's array of times
   * 
   * @public
   * @param {Object} time
   * @param {Object} slot
   * @return {Void}
   */
  toggleBetweenTimesHighlight(time, slot) {
    const startIdx = findIndex(slot.times, t => t.isStartTime);
    const endIdx = findIndex(slot.times, t => t.time === time.time);
    if (startIdx === -1 || endIdx === -1) {
      return;
    }

    for (let i = startIdx + 1; i < endIdx; i++) {
      const t = slot.times[i];
      t.highlightBetween = !t.highlightBetween;
    }
  }
}

