import Quill from 'quill';
const Parchment = Quill.import('parchment');
import { QuillInsert } from '../../../../server/api/conversations/models';
import { VENUE_TEMPLATES } from '../../../../database/constants/venueTemplate';
import { get } from 'lodash';

class QuillEditorController {
  placeholderMessage: string;
  quillInstance: any;
  textInput: { quillFormat: QuillInsert[], message: string};
  sent: boolean;
  registerChanges: () => void;
  msgData: any;
  selected: any;
  templates: any;
  request: any;
  calledFromSubmitRequest: boolean;
  constructor (private $element, private $scope) {
    'ngInject';
  }

  $onInit() {
    this.templates =  this.setTemplates();
    this.createCustomIndent();
    const toolbarOptions = [ [{ header: 1 }, { header: 2 }], ['bold', 'italic', 'underline'], ['link'], [{ list : 'bullet' }, { list: 'ordered' }]];
    const options = {
      debug: 'warn',
      modules: {
        toolbar: toolbarOptions,
      },
      placeholder: this.placeholderMessage || 'Type a message or use a template.',
      readOnly: false,
      theme: 'snow'
      };
    this.quillInstance = new Quill(this.$element.find('.quill-editor')[0], options);
    const tooltip = this.quillInstance.theme.tooltip;
    const input = tooltip.root.querySelector(' input[data-link] ');
    input.dataset.link = 'www.sixplus.com';
    this.quillInstance.on('text-change', () => {
      this.textInput.message = this.quillInstance.getText(0).replace(/\n/gm, ' ').trim();
      this.textInput.quillFormat = this.quillInstance.getContents().ops;
      this.updateLinkAttributes();
      if (this.registerChanges) {
        this.registerChanges();
      }
    });
  }
  updateLinkAttributes() {
    const links = this.$element.find('.quill-editor a');
    links.each((index, link) => {
      const href = link.getAttribute('href');
      if (href && href.startsWith('www.')) {
        link.setAttribute('href', `https://${href}`);
      }
    });
  }

  setTemplates = () => {
    const bookingReqType = get(this.request, 'venue.bookingType');
    if (bookingReqType === 'Defer_to_Venue') {
      return this.calledFromSubmitRequest ? VENUE_TEMPLATES['VENUE_OFFLINE_SUBMIT_REQ'] : VENUE_TEMPLATES['VENUE_OFFLINE_MESSAGE'];
    }
    return this.calledFromSubmitRequest ? VENUE_TEMPLATES['STANDARD_VENUE_SUBMIT_REQ'] : [...VENUE_TEMPLATES['STANDARD_VENUE_MESSAGE'], ...VENUE_TEMPLATES['SEND_VENUE_VIDEO']];
  }

  $onChanges(changes) {
    if (this.templates && get(changes, 'selected')) {
      this.templateSelected();
    }
    if (get(changes, 'sent.currentValue') === true) {
      this.quillInstance.setText('');
    }
  }

  templateSelected() {
    let message = this.templates.find(tem => tem.title === this.selected);
    if (message) {
      message = message.value.replace('CLIENT_FIRST_NAME', get(this.msgData, 'clientFirstName'));
      message = message.replace('DATE_OF_EVENT_REQUEST', get(this.msgData, 'eventRequestDate'));
      message = message.replace('EVENT_SPACE', get(this.msgData, 'SpaceName'));
      message = message.replace('VENUE_NAME', get(this.msgData, 'venueName'));
      message = message.replace('EVENT_IN_DAYS', get(this.msgData, 'eventInDays'));
      message = message.replace('PDM_NAME', get(this.msgData, 'pdm'));
      message = message.replace('MENU_DUE_DATE', get(this.msgData, 'menuDueDate'));
      message = message.replace('GROUP_SIZE', get(this.msgData, 'groupSize'));
    }
    else {
      message = '';
    }
    const indexOfReviewLink = message.indexOf('REVIEW_LINK');
    const value = indexOfReviewLink > 0 ? [{ insert: message.substr(0, indexOfReviewLink) }, { insert: 'here', attributes: { link: this.msgData.link, bold: true, underline: true } }, { insert: message.substr(indexOfReviewLink + 11) }] : [{ insert: message }];
    this.quillInstance.setContents(value);
  }


  createCustomIndent(): void {
    const levels = [1, 2, 3, 4, 5];
    const multiplier = 2;
    const indentStyle = new Parchment.Attributor.Style('indent', 'margin-left', {
      scope: Parchment.Scope.BLOCK,
      whitelist: levels.map(value => `${value * multiplier}rem`),
    });
    Quill.register(indentStyle);
  }
}

export default {
  template: require('./quill-editor.component.jade'),
  controller: QuillEditorController,
  bindings: {
    textInput: '=',
    request: '<',
    sent: '<',
    msgData: '<',
    selected: '<',
    registerChanges: '<',
    calledFromSubmitRequest: '<',
    placeholderMessage: '<'
  }
};
