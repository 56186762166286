import * as angular from 'angular';
import { adminCreateEditAccountComponent } from './admin-create-edit-account.component';
import { adminUserConnectionsComponent } from './get-user-coonections/admin-user-connections.component';
import { topLevelAdminCreateEditAccountComponent } from './top-level-admin-create-edit-account.component';

export const AdminCreateEditAccountModule = angular.module('adminCreateEditAccountModule', [])
    .component('topLevelAdminCreateEditAccount', topLevelAdminCreateEditAccountComponent)
    .component('adminUserConnections', adminUserConnectionsComponent)
    .component('adminCreateEditAccount', adminCreateEditAccountComponent)
    .name;
