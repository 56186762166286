const sendProposalModal = ['ngDialog', function(ngDialog) {
  return function (recommendation) {
    return ngDialog.open({
      template: require('./send-proposal.modal.jade'),
      controllerAs: '$ctrl',
      className: 'ngdialog-theme-small info-modal',
      plain: true,
      overlay: true,
      controller: ['$scope', function ($scope) {
        this.recommendation = recommendation;
        this.selectSendProposal = () => {
          $scope.closeThisDialog('Send Me A Proposal');
        };

        this.selectBuildProposal = () => {
          $scope.closeThisDialog('Build My Proposal');
        };

        this.close = () => {
          $scope.closeThisDialog();
        };
      }]
    }).closePromise;
  };
}];

export default sendProposalModal;
