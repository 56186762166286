import VenueHelpers from 'common/dist/virtuals/Venue';
import { ViewWrapperStateService } from './view-wrapper-state.service';
import { find, get, includes, debounce } from 'lodash';

import { StateEmitter } from 'spc/utils/StateEmitter';
import { ApiService } from '../../../shared/api/api.service';
import { UserService } from '../../../services/user.service';
import ambianceStyleService from '../../../services/ambianceStyleService';

const HIDE_NAVBAR = 'HIDE_NAVBAR';
const SHOW_NAVBAR = 'SHOW_NAVBAR';

const pluralize = require('pluralize');
// Constants
const HIGHLIGHT_CLASS = 'highlight-background';

let ctrl;
const setZoomFlag: boolean = false;
class VenueSearchComponent {
  isPremiumUser: boolean;

  constructor(
    $cloudinary,
    unwrapError,
    ENUMS,
    $window,
    $element,
    $location,
    private $scrollService,
    VenueSearchMapService,
    private VenueSearchFiltersService,
    $scope,
    private obscuredLoginModal,
    $user: UserService,
    private viewWrapperStateService: ViewWrapperStateService,
    private $api: ApiService) {
    'ngInject';
    ctrl = this;
    ctrl.$cloudinary = $cloudinary;
    ctrl.unwrapError = unwrapError;
    ctrl.pluralize = pluralize;
    ctrl.$window = $window;
    ctrl.ENUMS = ENUMS;
    ctrl.scope = $scope;
    ctrl.state = new StateEmitter([HIDE_NAVBAR, SHOW_NAVBAR], SHOW_NAVBAR);
    ctrl.$location = $location;
    ctrl.$element = $element;
    ctrl.mapService = VenueSearchMapService;
    ctrl.filterService = VenueSearchFiltersService;
    ctrl.$user = $user;
    ctrl.submitted = false;
    ctrl.error = false;
    ctrl.handleGuestChange = debounce(ctrl.basicSearch, 300);
  }

  // Lifecycle Hooks

  $onInit() {
    ctrl.$user.isPremiumMember()
      .then((res) => {
        ctrl.isPremiumUser = res;
        const path = ctrl.$location.$$path.split('/')[2];
        const requestedCity = ctrl.ENUMS.acceptableUserCities.find(city => city.slug.toUpperCase() === path.toUpperCase());

        // Check if city is pro/premium and user does not have membership account -> redirecting to upgrade page
        if (requestedCity && requestedCity.premiumCity && !res) {
          return ctrl.$location.url('/upgrade');
        }

        ctrl.search
          .initSearch()
          .then(() => {
            if (ctrl.$window.innerWidth > 991) {
              ctrl.toggleShowMap();
            }
          })
          .catch(ctrl.unwrapError);
      })
      .catch((error) => {
        ctrl.unwrapError(error);
    });

    ctrl.filterService = ctrl.search
      ? this.VenueSearchFiltersService.activate(ctrl.search)
      : null;
    ctrl.stateManager = {
      tab: 'VENUE'
    };

    ctrl.VALID_TIMES = ctrl.search.getValidTimes();

    ctrl.state.on(HIDE_NAVBAR, function () {
      ctrl.showButton = true;
      ctrl.scope.$apply();
    });

    ctrl.state.on(SHOW_NAVBAR, function () {
      ctrl.showButton = false;
      ctrl.scope.$apply();
    });
  }

  getInitialCount(path, value, schema) {
    return ctrl.filterService.getInitialCount(path, value, schema);
  }

  toggleFilterArray(path, value, schema, toPush) {
    return ctrl.filterService
      .toggleFilterArray(path, value, schema, toPush)
      .then(() => ctrl.redrawMarkers())
      .catch(ctrl.unwrapError);
  }

  getCount(path, value, schema) {
    return ctrl.filterService.getCount(path, value, schema);
  }

  isFilterEnabledArray(path, value, schema) {
    return ctrl.filterService.isFilterEnabledArray(path, value, schema);
  }

  getPrice(value) {
    let price = '';
    const currency = ctrl.search.display === 'London' ? '£' : '$';
    while (price.length < value) {
      price +=  currency;
    }
    return price;
  }

  $onDestroy = () => {
    this.viewWrapperStateService.enable();
  }

  // Public Functions

  /**
   * Runs a basic search, and assumes page 0
   *
   * @public
   * @return {Promise}
   */
  basicSearch() {
    ctrl.disableViewTransition();
    ctrl.$location.search('page', null);
    ctrl.search.page = 0;
    ctrl.search.applyFilters()
      .then(ctrl.redrawMarkers)
      .catch(ctrl.unwrapError);
  }

  toggleSkipVisibility() {
    ctrl.search.toggleSkipVisibility();
    ctrl.disableViewTransition();
    ctrl.$location.search('page', null);
    return ctrl.search.setPage(0)
      .then(() => ctrl.search.setInitialCounts())
      .then(ctrl.redrawMarkers)
      .catch(ctrl.unwrapError);
  }

  /**
   * Get venue view url for a venue and preload a space with any availability
   * check queries based on the search filters
   *
   * @public
   * @param {Object} venue
   * @param {String} spaceId
   * @return {String}
   */
  getVenueViewUrl(venue, spaceId) {
    return ctrl.search.getVenueViewUrl(venue, spaceId);
  }


  /**
   * Get the class to style the venue card base on a venue's ambiance
   *
   * @public
   * @param {Venue} venue
   * @return {Object}
   */
  ambianceClass(venue) {
    const ambiance = get(venue, 'admin.ambiance');
    return ambianceStyleService(ambiance);
  }

  /**
   * Change tab view, so that user can view results by venue or space
   *
   * @public
   * @param {String} viewType, `SPACE` || `VENUE`
   * @return {Promise}
   */
  changeTab(viewType) {
    if (!includes(['SPACE', 'VENUE'], viewType)) {
      ctrl.unwrapError(new Error(`Invalid view type ${viewType}`));
    }

    if (ctrl.stateManager.tab && viewType === ctrl.stateManager.tab) {
      return;
    }

    if (!ctrl.$user.isLoggedIn() && !ctrl.search.isUserbot()) {
      ctrl.openLoginModal(viewType);
    }

    ctrl.search.setViewType(viewType);
    ctrl.mapService.setViewType(viewType);
    ctrl.disableViewTransition();
    ctrl.$location.search('page', null);
    return ctrl.search.setPage(0)
      .then(ctrl.redrawMarkers)
      .then(() => {
        ctrl.stateManager.tab = viewType;
      })
      .catch(ctrl.unwrapError);
  }

  openLoginModal = (viewType) => {
    if (viewType === 'SPACE') {
      return this.obscuredLoginModal({
        primaryUser: '',
        lead: '',
        allowClose: ctrl.$user.isLoggedIn()
      });
    }
  }

  /**
   * Redraw markers on map
   *
   */

  /**
   * Set date search filter
   *
   * @public
   * @param {Moment} date
   * @return {Void}
   */
  setDate(date) {
    ctrl.search.setDate(date);
    if (!ctrl.__time) {
      ctrl.__time = find(ctrl.VALID_TIMES, t => t.h === 19 && t.m === 0);
    }
    ctrl.basicSearch();
  }

  /**
   * Set time search filter
   *
   * @public
   * @param {Object} time
   * @return {Void}
   */
  setTime(time) {
    ctrl.search.setTime(time);
    ctrl.basicSearch();
  }

  /**
   * Reset page to page 1 (index 0) and no filters applied
   *
   * @public
   * @return {Void}
   */
  resetPage() {
    ctrl.disableViewTransition();
    ctrl.$location.search('page', null);
    ctrl.submitted = false;
    ctrl.error = false;
    return ctrl.search.reset()
      .then(ctrl.redrawMarkers)
      .catch(ctrl.unwrapError);
  }

  setPage(index) {
    ctrl.disableViewTransition();
    ctrl.scrollToTop();
    ctrl.$location.search('page', index + 1);
    return ctrl.search.setPage(index)
      .then(ctrl.redrawMarkers)
      .catch(ctrl.unwrapError);
  }

  scrollToTop() {
    ctrl.$scrollService('body', 0, 0);
  }

  /**
   * Toggles whether or not map is shown
   *
   * @public
   * @return {Void}
   */
  toggleShowMap() {
    ctrl.search.state.$waitFor('LOADED', () => {
      ctrl.stateManager.showMap = !ctrl.stateManager.showMap;
    });
  }

  redrawMarkers() {
    ctrl.mapService.drawMarkers(ctrl.search.venues);
  }


  /**
   * Submits venue query
   * @public
   **/
  submitVenueQuery(venue:
    {
      name: string,
      city: string,
      userEmail?: string
    } = {
      name: '',
      city: '',
    }) {
    // add a constructor variable to update, toggle using ng-if on form.
    this.$api.VenueQueries.create({
      venueQuery: {
        name: venue.name,
        city: ctrl.search.location,
        email: venue.userEmail
      }
    }).then(function (response) {
      ctrl.submitted = true;
      ctrl.error = false;
    }).catch(function (error) {
      ctrl.error = true;
      ctrl.unwrapError;
    });
  }

}

export default {
  template: require('./search.jade'),
  bindToController: true,
  bindings: {
    isAdmin: '<',
    disableViewTransition: '&',
    search: '<',
  },
  controller: VenueSearchComponent
};
