import { get } from 'lodash';

import { StateEmitter } from '../utils/StateEmitter';

exports.$controller = function($scope, $user, $injector, $seo, ENUMS, $api, unwrapError) {
  $user.$waitFor('LOGGED_IN', function() {
    $scope.user = $user.$;
    $scope.isOauth = $user.isOauth;
    const title = 'My Account';
    const description = ENUMS.metaDescriptions.account;
    $seo.set(title, description);

    const STATES = ['LOADING', 'LOADED'];
    $scope.methodsStateCtrl = new StateEmitter(STATES, 'LOADING');

    $scope.ccStateCtrl = new StateEmitter(STATES, 'LOADED');
    $api.Payment.initialize({getMethods: true}).
    then(function(response) {
      $scope.paymentMethods = get(response, 'data.paymentMethods', []);
      $scope.braintreeToken = get(response, 'data.token');
      $scope.methodsStateCtrl.$state('LOADED');
    }).
    catch(unwrapError);
    $scope.$emit('$viewReady', 'SHOW_FOOTER');
  });

  $user.$waitFor('NOT_LOGGED_IN', function() {
    const $location = $injector.get('$location');
    $location.url('/login?redirect=' + $location.path());
  });
};

exports.$inject = ['$scope', '$user', '$injector', '$seo', 'ENUMS', '$api', 'unwrapError'];

