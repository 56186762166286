// External Deps
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { RawBaseUser } from 'spc/lib/database/types/base-user';

interface StatusMap {
  [index: string]: string[];
}

const VENUE_CUSTOM_TEXTS = {
  proposal: 'Submit Proposal',
  request: 'Send Proposal or Decline Request',
};

const CLIENT_CUSTOM_TEXTS = {
  proposal_request: 'Proposal Sent',
  counteroffer: 'Proposal Viewed'
};

class ConversationStatusMarkerController {
  conversation: any;
  request: any;
  role: string;
  creator: RawBaseUser;

  mobile: boolean;
  state: string;

  constructor(
    private TEXT_MAP,
    private VENUE_GUIDANCE_MAP,
    private CLIENT_GUIDANCE_MAP,
    private STATUS_MAP) {
    'ngInject';
  }

  $onInit() {
    if (this.conversation) {
      this.state = this.conversation.requestState;
    } else if (this.request) {
      this.state = this.request.state;
    }
  }

  getClass = () => {
    return find(Object.keys(this.STATUS_MAP), (key: string) => {
      if (includes(this.STATUS_MAP[key], this.state)) {
        return this.mobile ? `${ key }-outline` : key;
      }
    });
  }

  isPreDepositEvent = () => {
    return ['PROPOSAL', 'REQUEST', 'COUNTEROFFER', 'PRE_DEPOSIT_ALTERATION', 'ACCEPTED_HOST', 'ACCEPTED_GUEST'].includes(this.state);
  }

  displaySubmittedDate = () => {
    const isPreDeposit = this.isPreDepositEvent();
    if (!isPreDeposit) {
      return;
    }

    const history = get(this, 'request.history', []);

    const submittedDate = history.find(event => event.action === 'submitted');

    return get(submittedDate, 'time') || get(this, 'request.createdAt');
  }

  getText = () => {
    const creator = this.creator;
    const roles = get(creator, 'roles', []);

    if (roles.includes('Host') && this.state === 'PROPOSAL') {
      return VENUE_CUSTOM_TEXTS.proposal;
    }

    if (this.role === 'venue' && ['REQUEST', 'COUNTEROFFER'].includes(this.state)) {
      return VENUE_CUSTOM_TEXTS.request = get(this, 'request.venue.bookingType') === 'Defer_to_Venue' ? 'Confirm Availability' : 'Send Proposal or Decline Request';
    }

    if (this.role === 'client' && ['REQUEST', 'PROPOSAL', 'COUNTEROFFER'].includes(this.state)) {
      return this.state === 'COUNTEROFFER' ? CLIENT_CUSTOM_TEXTS.counteroffer : CLIENT_CUSTOM_TEXTS.proposal_request;
    }
    return this.TEXT_MAP[this.state];
  }

  getGuidance = () => {
    if (this.role === 'venue') {
      return this.VENUE_GUIDANCE_MAP[this.state] || 'Message the client as necessary and create a proposal with one click.';
    } else if (this.role === 'client') {
      return this.CLIENT_GUIDANCE_MAP[this.state] || 'Message the venue as necessary.';
    }
  }
}

export const ConversationStatusMarkerComponent = {
  template: require('./conversation-status-marker.component.jade'),
  bindings: {
    conversation: '<',
    request: '<',
    mobile: '<',
    creator: '<',
    role: '@'
  },
  controller: ConversationStatusMarkerController
};
