declare const SIXPLUS_CLIENT_GLOBALS;
import * as angular from 'angular';
export class SpPrinter {
  constructor(
    private $rootScope,
    private $compile,
    private $http,
    private $timeout,
    private $q,
    private unwrapError) {
    'ngInject';
  }

  generatePrintPageHtml = (html: string) => {
    const cssLink = SIXPLUS_CLIENT_GLOBALS.appEnv === 'development' ? '/index.css' : '/index.gz.css';
    return `<!doctype html>
    <html>
      <head>
        <link defer rel="stylesheet" href=${cssLink}>
        <link defer rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
        <link defer href='https://fonts.googleapis.com/css?family=Lato:400,300,700,900', rel='stylesheet', type='text/css' />
        <link defer href="https://fonts.googleapis.com/css?family=Abril+Fatface", type='text/css', rel='stylesheet' />
      </head>
      <body onload="printAndRemove();">
        ${html}
      </body>
    </html>`;
  }

  printHtml = (html: string) => {
    const hiddenFrame = $('<iframe style="visibility: hidden"></iframe>').appendTo('body')[0];
    hiddenFrame.contentWindow.printAndRemove = () => {
      this.$timeout(() => {
        hiddenFrame.contentWindow.print();
        $(hiddenFrame).remove();
      });
    };
    const doc = hiddenFrame.contentWindow.document.open('text/html', 'replace');
    doc.write(this.generatePrintPageHtml(html));
    doc.close();
  }

  printTemplate = (template: string, scope: any) => {

    const render = () => {
      const angularTemplate = angular.element(template);
      const element = this.$compile(angularTemplate)(scope);
      scope.$digest();
      return this.printHtml(element.html());
    };

    if (this.$http.pendingRequests.length) {
      this.$timeout(() => this.printTemplate(template, scope));
    } else {
      this.$timeout(() => render());
    }
  }
}
