import { get, map } from 'lodash';
import { ApiService } from 'spc/shared/api/api.service';
import debounce from 'lodash/debounce';
class VenueAuthorizedUsersController {
  venue: any;
  emailInput: string;
  newAuthUser: {
    title: '',
    preferences: {
      receiveEmails: boolean;
      displayOnProposal: boolean;
      receiveFinanceEmail: boolean;
    };
    user?: {
      profile: {
        email: string,
        city: string;
        name: {
          first: string;
          last: string;
        }
      }
      roles: ['Host']
    },
  };

  ui: {
    addingUser: boolean;
  } = { addingUser: false };
  authorizedUsers: { title: string; user: any; preferences: { receiveEmails: boolean; displayOnProposal: boolean; receiveFinanceEmail: boolean } }[];
  addUserErrors: string;
  constructor(private $api: ApiService, private unwrapError) {
    'ngInject';
  }

  $onInit = () => {
    if (!this.venue) {
      return;
    }
    this.authorizedUsers = this.venue.authorizedUsers;
  }

  verifyEmail = (email: string) => {
    return this.$api.Auth.verifyEmail(email);
  }

  startAddUser = () => {
    this.ui.addingUser = true;
  }

  setDefaultNewUserPreferences = () => {

    this.newAuthUser = {
      title: '',
      preferences: {
        receiveEmails: false,
        displayOnProposal: false,
        receiveFinanceEmail: false
      }
    };
  }

  isAlreadyAuthorizedUser = (user) => {
    return this.authorizedUsers.find(authUser => authUser.user._id.toString() === user._id.toString());
  }

  findClient = () => {
    this.verifyEmail(this.emailInput)
      .then(() => this.$api.Search.findByEmail(this.emailInput))
      .then((data) => {
        this.newAuthUser = null;
        this.addUserErrors = null;
        if (data.user && !data.user.roles.includes('Host')) {
          this.addUserErrors = `This user exists but does not have a 'host' role.`;
          return;
        }

        if (data.user && this.isAlreadyAuthorizedUser(data.user)) {
          this.addUserErrors = 'This user is already an authorized user for this venue';
          return;
        }

        this.setDefaultNewUserPreferences();
        if (data.user && data.user.roles.includes('Host')) {
          this.newAuthUser.user = data.user;
          return;
        }

        this.newAuthUser.user = {
          profile: {
            city: this.venue.data.address.city,
            email: this.emailInput,
            name: {
              first: '',
              last: ''
            }
          },
          roles: ['Host']
        };
      })
      .catch((error) => {
        if (get(error, 'data.address') && !get(error, 'data.valid')) {
          this.addUserErrors = `${error.data.address} is not a valid email address`;
        }
        this.unwrapError(error);
      });
  }

  debouncedFindClient = debounce(this.findClient, 300);

  removeUser = (authUser) => {
    const filteredAuthUsers = this.venue.authorizedUsers.filter(authorizedUser => authorizedUser.user._id.toString() !== authUser.user._id.toString());
    this.venue.authorizedUsers = filteredAuthUsers;
    this.authorizedUsers = filteredAuthUsers;
  }

  createBaseUser = () => {
    if (this.addUserErrors) {
      return;
    }

    return this.$api.BaseUsers.create(this.newAuthUser.user, { venueUser: true, venue: this.venue._id })
      .then(data => this.newAuthUser.user = data.user)
      .then(() => this.addNewAuthUser())
      .catch(error => this.unwrapError(error));
  }

  addNewAuthUser = () => {
    if (this.addUserErrors) {
      return;
    }
    this.authorizedUsers.push(this.newAuthUser);
    this.newAuthUser = null;
    this.ui.addingUser = false;
    this.emailInput = null;
  }

}

export const VenueAuthorizedUserComponent = {
  controller: VenueAuthorizedUsersController,
  template: require('./venue-authorized-users.component.jade'),
  bindings: {
    venue: '<',
    user: '<'
  }
};

