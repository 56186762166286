import get from 'lodash/get';
import set from 'lodash/set';
import UserHelpers from 'common/dist/virtuals/User';
import LeadHelpers from 'common/dist/virtuals/Lead';
import price from 'common/dist/price';
import moment from 'moment';
// Sixplus Types
import { ApiService } from 'spc/shared/api/api.service';
import { SpDownloadService } from 'spc/shared/sp-download.service';


class DownloadLeadsController {
  downloading: boolean = false;
  search: any;
  fields: any[];
  delimiter: string = ', ';


  constructor(private $api: ApiService, private unwrapError, private SpDownloadService: SpDownloadService, private ENUMS, private $location) {
    'ngInject';
    this.fields = [
      '_id',
      { label: 'Name', value: this.getClientName },
      { label: 'Company', value: 'primaryClient.company.name' },
      { label: 'Occasion', value: this.getOccasion },
      { label: 'Event Name', value: lead => get(lead, 'name') },
      { label: 'Total Budget', value: 'request.totalEventBudgetDollars' },
      { label: 'Budget Per Guest', value: 'request.budgetPerGuest' },
      { label: 'Event Date', value: lead => get(lead, 'request.date') ? get(lead, 'request.date').substring(0, 10) : '' },
      { label: 'Due Date', value: this.getDueDate },
      { label: 'Status', value: 'currentStatus' },
      { label: 'Origin', value: 'admin.createdThrough' },
      { label: 'Next To Do', value: 'admin.nextToDo' },
      { label: 'Priority', value: this.getPriority },
      { label: 'Amount', value: this.getAmount },
      { label: 'Venue', value: this.getBookedVenue },
      { label: 'Number of Proposals Sent', value: lead => LeadHelpers.numberOfProposalsSent({ recommendations: lead.recommendations, active: false }) },
      { label: 'Number of Active Proposals', value: lead => lead.bookingRequests.length },
      { label: 'Owner', value: this.getOwnerName },
      { label: 'Assistant', value: this.getAssistantName },
      { label: 'City', value: 'request.city' },
      { label: 'Email', value: 'primaryClient.profile.email' },
      { label: 'Phone', value: 'primaryClient.profile.phone' },
      { label: 'Reco URL', value: this.getUrl },
      { label: 'Min Guests', value: 'request.numGuests.min' },
      { label: 'Max Guests', value: 'request.numGuests.max' },
      { label: 'Meal Type', value: 'request.mealType' },
      { label: 'Start Time', value: this.getTime },
      { label: 'Duration', value: this.getDuration },
      { label: 'Privacy', value: this.getPrivacy },
      { label: 'Cuisine Types', value: this.getCuisineTypes },
      { label: 'Submitted', value: lead => lead.createdAt.substring(0, 10) },
      { label: 'Reason Killed', value: 'admin.closeReason' },
      { label: 'AV Equipment', value: 'request.AV.selected' },
      { label: 'AV Options', value: this.getAVOptions },
      { label: 'Lead Description', value: 'request.additionalInfo' },
      { label: 'Referrer URL', value: 'primaryClient.internal.referrer.url' },
      { label: 'Referrer Service', value: 'primaryClient.internal.referrer.service' },
      { label: 'Heard From', value: 'primaryClient.admin.heardAbout' },
      { label: 'Heard From Details', value: 'primaryClient.admin.heardAboutDetails' },
      { label: 'Adwords Campaign', value: 'primaryClient.internal.referrer.adwords.campaign' },
      { label: 'Keywords', value: lead => get<string[]>(lead, 'primaryClient.internal.referrer.adwords.keywords', []).join(this.delimiter) },
      { label: 'Neighborhoods', value: this.getNeighborhoods },
      { label: 'Number of Recommendations Sent', value: 'recommendations.length' },
      { label: 'Identifier', value: 'identifier' },
    ];
  }

  getPriority = (lead) => {
    return this.ENUMS.concierge.priorityLevels[get(lead, 'admin.priorityValue')];
  }

  getClientName = (lead): string | undefined => {
    const client = get(lead, 'primaryClient');
    if (client) {
     return UserHelpers.fullName(client);
    }
  }

  getOwnerName = (lead): string | undefined => {
    if (lead.owner) {
      return UserHelpers.fullName(lead.owner);
    }
  }

  getAssistantName = (lead): string | undefined => {
    const assistant = get(lead, 'admin.assistant');
    if (assistant) {
      return UserHelpers.fullName(assistant);
    }
  }

  getDuration = (lead): number | undefined => {
    const duration = get<number>(lead, 'request.duration');
    if (duration) {
      return duration / 60;
    }
  }

  getTime = (lead): string | undefined => {
    const time = get(lead, 'request.time');
    if (time) {
      return moment(time, 'HHmm').format('h:mma');
    }
  }

  getPrivacy = (lead): string | undefined => {
    const privacy = get<string[] | string>(lead, 'request.privacy');
    if (privacy) {
      if (Array.isArray(privacy)) {
        return privacy.join(this.delimiter);
      } else {
        return privacy;
      }
    }
  }

  getCuisineTypes = (lead): string | undefined => {
    const cuisineTypes = get<string[] | string>(lead, 'request.preferredCuisineType');
    if (cuisineTypes) {
      if (Array.isArray(cuisineTypes)) {
        return cuisineTypes.join(this.delimiter);
      } else {
        return cuisineTypes;
      }
    }
  }

  getAVOptions = (lead): string | undefined => {
    const avOptions = get<string[]>(lead, 'request.AV.options');
    if (avOptions) {
      return avOptions.join(this.delimiter);
    }
  }

  getNeighborhoods = (lead): string | undefined => {
    const neighborhoods = get<string[]>(lead, 'request.neighborhoods');
    if (neighborhoods) {
      return neighborhoods.join(this.delimiter);
    }
  }

  getAmount = (lead) => {
    if (lead.currentStatus === 'Booked') {
      const bookedRequest = this.getBookedRequest(lead);
      if (bookedRequest) {
        return price.fullPriceBreakdownDollars(bookedRequest, true).total;
      }
    }
  }

  getBookedVenue = (lead) => {
    if (lead.currentStatus === 'Booked') {
      const bookedRequest = this.getBookedRequest(lead);
      if (bookedRequest) {
        return bookedRequest.venue.data.name;
      }
      return lead.admin.alternativeVenue;
    }
  }

  getBookedRequest = (lead) => {
    return lead.bookingRequests.find(request => this.ENUMS.bookingRequestState.bookedStates.includes(request.state));
  }

  getOccasion = (lead) => {
    return get(lead, 'request.customOccasion') || get(lead, 'request.occasion');
  }

  getUrl = (lead) => {
    if (lead.recommendations.length) {
        let domain = `${this.$location.protocol()}://${this.$location.host()}`;
        if (this.$location.port() !== 443) {
          domain = `${domain}:${this.$location.port()}`;
        }
        const link = `${domain}/recos/${lead._id.toString()}`;
        return link;
    }
  }

  getDueDate = (lead) => {
    const date = get(lead, 'admin.dueDate') as String;
    if (date) {
      return date.substring(0, 10);
    }
  }

  downloadLeads = () => {
    this.downloading = true;
    return this.$api.Admin.Leads.download(this.search)
      .then((response) => {
        this.SpDownloadService.exportCSVFile({ data: response.leads, fileTitle: 'events', fields: this.fields });
      })
      .then(() => this.downloading = false)
      .catch((error) => {
        this.downloading = false;
        this.unwrapError(error);
      });
  }
}

export const DownloadLeadsComponent = {
  controller: DownloadLeadsController,
  template: require('./download-leads.component.jade'),
  bindings: {
    search: '<'
  }
};
