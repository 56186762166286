import moment from 'moment';
import time from 'common/dist/time';
export default function () {
  const STARTING_HOUR = 600;
  const ENDING_HOUR = 2400;
  const ONE_HOUR_PIXELS = 50;
  const TIME_BUFFER = 100; // gonna show the time range as +/- 2 hours from the start and end times
  function link($scope, element, attrs) {
    // oneUnit is the pixel value of an hour

    $scope.ConvertTimeToPixels = function (from, to) {
      const fromTime = moment(time.integerToCivilianTime(from), 'HH:mm');
      const toTime = moment(time.integerToCivilianTime(to), 'HH:mm');
      const hours = moment.duration(toTime.diff(fromTime)).asHours();
      return hours * ONE_HOUR_PIXELS;
    };

    $scope.AddAllowedSlot = function (from, to) {
      const $container = $(element).find('.ac-body');
      const $allowedSlot = $('<div></div>');
      $allowedSlot.addClass('allowedSlot');
      const leftPixels = $scope.ConvertTimeToPixels(STARTING_HOUR, from);
      const fromPixels = $scope.ConvertTimeToPixels(from, from);
      const toPixels = $scope.ConvertTimeToPixels(from, to);
      $allowedSlot.css('left', leftPixels + 'px');
      $allowedSlot.css('width', (toPixels - fromPixels) + 'px');
      $allowedSlot.appendTo($container);
    };

    $scope.showTimeRangePicker = () => {
      $('.ac-selected')
        .css('left', $scope.ConvertTimeToPixels(STARTING_HOUR, $scope.selectedTime.from))
        .css('width', $scope.ConvertTimeToPixels($scope.selectedTime.from, $scope.selectedTime.to));

      const leftHour = ($scope.selectedTime.from - TIME_BUFFER > STARTING_HOUR) ? $scope.selectedTime.from - TIME_BUFFER : STARTING_HOUR;
      const rightHour = ($scope.selectedTime.to + TIME_BUFFER) < ENDING_HOUR ? $scope.selectedTime.to + TIME_BUFFER : ENDING_HOUR;

      const left = $scope.ConvertTimeToPixels(leftHour, STARTING_HOUR);
      const width = $scope.ConvertTimeToPixels(leftHour, rightHour);
      $('.ac-strip')
        .css('left', left);
      $('.availability-calendar-br')
        .css('width', width);
    };
    $scope.timeSlots.forEach(slot => $scope.AddAllowedSlot(slot.from, slot.to));
    $scope.showTimeRangePicker();
  }

  return {
    link: link,
    restrict: 'E',
    template: require('./sp-timedisplay.directive.jade'),
    transclude: true,
    replace: true,
    scope: {
      isAvailable: '<',
      timeSlots: '=',
      onTimeslotChanged: '=',
      selectedTime: '='
    },
    id: '='
  };
}
