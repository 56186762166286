// Sixplus Dependencies
import { ApiService } from 'spc/shared/api/api.service';

// NPM Dependencies
import { timeAsInteger, integerToCivilianTime } from 'common/dist/time';
import moment from 'moment';
import get from 'lodash/get';

import { OnDialogClose } from 'spc/shared/interfaces/on-dialog-close';


interface BlockTimeModal {
  space: any;
  times: {
    startTime: number;
    endTime: number;
  };
  note?: string;
  date: string;
  user: string;
  created: string;
}

export const blockTimeModal = ['ngDialog', function (ngDialog) {
  return function ({ space, times, note, date, user, created }: BlockTimeModal): Promise<OnDialogClose> {
    return ngDialog.open({
      template: require('./block-time-modal.component.jade'),
      className: 'ngdialog-theme-small',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      controller: ['$scope', 'unwrapError', '$api', function ($scope, unwrapError, $api: ApiService) {
        this.space = space;
        this.event = { space: this.space._id.toString() };
        this.event.date = date;
        let oldEvent = null;

        if (times) {
          oldEvent = { space: this.event.space, date, times, note };
          this.event.times = {
            startTime: moment(integerToCivilianTime(times.startTime), 'H:mm').toDate(),
            endTime: moment(integerToCivilianTime(times.endTime), 'H:mm').toDate()
          };
          this.event.note = note;
          this.event.user = user;
          this.event.created = created;
          this.hasOldEvent = true;
        }

        this.removeEvent = () => {
          if (!this.hasOldEvent) {
            return;
          }
          return $api.AvailabilityCalendar.modifyEvent({ data: { oldEvent, newEvent: null } })
            .then(response => $scope.closeThisDialog(response))
            .catch(error => this.handleError(error));
        };

        this.save = () => {
          if (!this.event.times.startTime || !this.event.times.endTime) {
            return;
          }

          const startTime = timeAsInteger(moment(this.event.times.startTime));
          const endTime = timeAsInteger(moment(this.event.times.endTime));
          const event = {
            times: { startTime, endTime },
            note: this.event.note,
            date: this.event.date,
            space: this.event.space
          };

          let payload: any = { data: { event } };
          let funcToUse: any = $api.AvailabilityCalendar.markUnavailable;

          if (oldEvent) {
            funcToUse = $api.AvailabilityCalendar.modifyEvent;
            payload = { data: { oldEvent, newEvent: event } };
          }

          return funcToUse(payload)
            .then(response => $scope.closeThisDialog(response))
            .catch(error => this.handleError(error));
        };

        this.handleError = (error) => {
          const conflictError = get(error, 'data.error.conflict');
          if (conflictError) {
            this.error = 'This conflicts with something on the calendar';
            return;
          }
          this.unwrapError(error);
        };

        this.cancel = () => $scope.closeThisDialog({ cancel: true });
      }]
    }).closePromise;
  };
}];
