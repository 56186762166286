import Vimeo from '@vimeo/player';
import _ from 'lodash';

interface Video {
    thumbnail?: string;
    title?: string;
    url?: string;
    videoPlayerType?: string;
    _id?: string;
    videoThumbnail?: string;
  }

class CSPVideoPlayerController {
    close: () => void;
    loading: boolean;
    video: Video;
    venueName: string;
    venueSlug: string;
    venueAddress: any;
    venueVideo: {
        videoPlayerType: string;
        image: string;
        images: [{
            src: string,
            type: string
        }],
        sources: [{
            file: string;
        }]
    };

    constructor(
    ) {
        'ngInject';
    }

    $onInit = () => {
        if (_.isEmpty(this.video)) {
            return;
        }

        const { videoThumbnail, videoPlayerType, url, thumbnail } = this.video;

        this.venueVideo = {
          videoPlayerType: videoPlayerType,
          image: thumbnail ? thumbnail : '',
          images: [{
            src: videoThumbnail ? videoThumbnail : '',
            type: 'video/mp4',
          }],
          sources: [{
            file: url
          }]
        };
        if (this.video.videoPlayerType === 'Vimeo') {
            this.playVideoWithVimeoPlayer();
        } else if (this.video.videoPlayerType === 'JWPlayer') {
            this.playVideoWithJWPlayer();
        }
    }

    public playVideoWithJWPlayer = () => {
        if (!this.venueVideo || this.venueVideo.videoPlayerType !== 'JWPlayer') {
        return;
        }
        setTimeout(() => {
            jwplayer('jwPlayerDiv').setup({
               playlist: [this.venueVideo],
                controls: true,
                autostart: 'viewable',
                mute: true,
                repeat: true,
            });
        }, 500);
    }

    public playVideoWithVimeoPlayer = () => {
        if (!this.venueVideo || this.venueVideo.videoPlayerType !== 'Vimeo') {
            return;
        }

        let data;
        const videoUrl = this.venueVideo.sources[0].file;
        if (videoUrl.includes('https://vimeo.com')) {
            const videoKey = videoUrl.split('/').pop().split('?')[0];
            data = Number(videoKey);
        }
        const options = {
            id: data,
            width: 642,
            loop: true,
            controls: true,
            autoplay: true,
            muted: true,
            title: false
        };
        setTimeout(() => {
            new Vimeo('vimeoPlayerDiv', options);
        }, 500);
    }
    public showVenueVideoContainer = () => {
        if (!_.isEmpty(this.video)) {
        return true;
        }
    }

    public closeVideoModel = () => {
        this.close();
    }

    public openVenueProfilePage = () => {
        const url = `/venue/${this.venueSlug}`;
        window.open(url);
    }
}

export const cspVideoPlayerComponent = {
    controller: CSPVideoPlayerController,
    template: require('./csp-video-player.component.jade'),
    bindings: {
        video: '<',
        close: '<',
        venueSlug: '<',
        venueName: '<',
        venueAddress: '<'
    }
};
