
// SixPlus Types
import { CreateLeadRequestBody, CreateLeadResponse, GetUserLeadsResponse, ValidateResponse, AddUserResponseData, ChangePermissionsResponseBody, GetUserLeadsReceipts } from 'server/api/leads/models';
import { RawLead } from 'spc/lib/database/types/lead';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { RawGroup } from 'spc/lib/database/types/group';
import { AddRecommendationRequestBody, AddRecommendationResponse, Lead } from 'spc/lib/server/api/admin/leads/models';

interface Options {
  allProposals?: boolean;
  proposals?: {
    [id: string]: boolean;
  };
}

module.exports = function (API_ROOT, $http) {
  const ret: any = {};

  ret.create = function ({ lead, client, sendEmail, adminId = null }: CreateLeadRequestBody): ng.IPromise<CreateLeadResponse> {
    return $http.post(API_ROOT + '/leads', { lead, client, sendEmail, adminId });
  };

  ret.track = (lead) => {
    const leadId = lead._id ? lead._id.toString() : lead;
    return new EventSource(`${API_ROOT}/leads/track/${leadId}`);
  };

  ret.getUserLeads = (params: { activeOnly: boolean }): ng.IPromise<GetUserLeadsResponse> => {
    return $http.get(`${API_ROOT}/leads/user`, { params })
      .then(response => response.data);
  };

  ret.getAccountLeads = ({ member, page, leadsPerPage, viewType, searchParams, state, filters }: { member: [], page: number, leadsPerPage: number, viewType: string, searchParams: {}, state: string, filters: { sort: string } }) => {
    return $http.get(`${API_ROOT}/leads/memberLeads`, { params: { member, page, leadsPerPage, viewType, searchParams, state, filters } })
      .then(response => response.data);
  };

  ret.getUserReceipts = (params: { activeOnly: boolean }): ng.IPromise<GetUserLeadsReceipts> => {
    return $http.get(`${API_ROOT}/leads/user/receipts`, { params })
      .then(response => response.data);
  };

  ret.getTeamReceipts = ({ filters }): ng.IPromise<GetUserLeadsReceipts> => {
    return $http.get(`${API_ROOT}/leads/user/teamReceipts`, { params: { filters } })
      .then(response => response.data);
  };

  ret.update = ({ lead }): ng.IPromise<CreateLeadResponse> => {
    return $http.put(`${API_ROOT}/leads/${lead._id}`, { lead })
      .then(response => response.data);
  };

  ret.archive = ({ lead, archiveData }): ng.IPromise<CreateLeadResponse> => {
    return $http.put(`${API_ROOT}/leads/${lead._id}/archive`, { archiveData })
      .then(response => response.data);
  };

  ret.validate = ({ lead, client }): ng.IPromise<ValidateResponse> => {
    return $http.post(`${API_ROOT}/leads/validate`, { lead, client })
      .then(response => response.data);
  };

  ret.addUser = ({ lead, user, addedBy, isNewUser }: { lead: RawLead, user?: RawBaseUser, addedBy?: RawBaseUser, isNewUser?: boolean }): ng.IPromise<AddUserResponseData> => {
    return $http.put(`${API_ROOT}/leads/${lead._id}/users`, { user, addedBy, isNewUser })
      .then(res => res.data);
  };

  ret.addRecommendation = (lead: Lead, data: AddRecommendationRequestBody[]): ng.IPromise<AddRecommendationResponse[]> => {
    return $http.post(`${API_ROOT}/leads/${lead._id}/recommendations`, data)
      .then((response: { data: AddRecommendationResponse[] }) => response.data);
  };

  ret.updatePermissions = ({ lead, userId, options }: {lead: RawLead, userId: string, options: Options }): ng.IPromise<ChangePermissionsResponseBody> => {
    return $http.put(`${API_ROOT}/leads/${lead._id}/users/${userId}`, { options })
      .then(res => res.data);
  };

  ret.removeCollaborator = ({ lead, userId }: { lead: RawLead, userId: string }): ng.IPromise<ChangePermissionsResponseBody> => {
    return $http.delete(`${API_ROOT}/leads/${lead._id}/users/${userId}`)
      .then(res => res.data);
  };

  return ret;
};
