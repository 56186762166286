import { RawList } from 'lib/database/types/list';
import { RawBaseUser } from 'lib/database/types/base-user';

class ArchiveListController {
  close: () => void;
  user: RawBaseUser;
  userId: string;
  loading: boolean;
  test: string;
  archiveLists: RawList[];
  hasArchived: boolean = true;

  constructor(private $api, private $scope, private unwrapError, private $user, private $cloudinary) {
    'ngInject';
    this.loading = true;
  }

  $onInit() {
    this.user = this.$user.$;
    this.userId = this.user._id.toString();
  }

  done = () => {
    this.close();
  }
}

export const ArchiveListComponent = {
  template: require('./archive-list.component.jade'),
  controller: ArchiveListController,
  bindings: {
    close: '&',
    archiveLists: '='
  }
};
